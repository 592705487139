import React from "react";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import { ASIconButton } from "allegro-ui";
import SettingsIcon from "@material-ui/icons/Settings";

import ResizePanel from "react-resize-panel-ts";

import {
  XRGridEditorView,
  XRGridEditorViewLoading,
} from "../organisms/XRGridEditorView";

import { XRRightPane } from "../molecules/XRRightPane";
import { headerOffset, XRBottomPanel } from "../organisms";
import "../../css/customHandle.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { LoadingBox } from "src/opt-editor/components/atoms/OELoddingBox";
import { transparentize } from "polished";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import { NTXerviceImportAndExporter } from "src/newtroid-console/components/organisms/NTXerviceImportAndExporter";
import { ModalIconButton } from "../molecules/ModalButton";
import { XRPipelinePermissionEditor } from "../organisms/XRXervicePermissionEditor";
import { usePipelineEditor } from "src/xerver-room/hooks/usePipelineEditor";
import { defaultPipeline } from "src/xerver-room/models";

const Root = styled.div`
  background: ${(props) => props.theme.bgPrimaryColor};
  width: 100%;
  overflow: hidden;
  color: ${(props) => (props.theme.darkMode ? "#eee" : "#000")};
`;

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 3;
  flex-flow: row nowrap;
  border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};
`;

const Center = styled.div`
  border-left: 2px solid #3a3535;
  border-right: 2px solid ${(props) => props.theme.secondaryBorderColor};
  width: 100%;
  height: calc(100vh - ${headerOffset}px);
  display: flex;
  flex-flow: nowrap column;

  overflow: hidden;
  position: relative;
`;

const EditorArea = styled.div`
  flex-grow: 3;
  display: flex;
  flex-flow: row nowrap;
`;

const PipelineNameArea = styled.div`
  background: ${(props) => transparentize(0.3, props.theme.bgPrimaryColor)};
  padding: 24px 16px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};
  z-index: 99;
`;

const PipelineName = styled.h1`
  font-size: 18px;
  font-weight: bold;
  margin-left: 12px;
  display: flex;
  align-items: center;
`;

const PipelineId = styled.h3`
  margin-left: 13px;
  font-size: 11px;
  margin-top: 8px;
  display: inline-block;
  opacity: 0.6;
`;

const ButtonGroup = styled.div`
  position: absolute;
  z-index: 99;
  margin-left: auto;
  position: absolute;
  right: 24px;
  top: 24px;
  > * {
    margin-left: 8px;
  }
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
`;

const FixedLogArea = styled.div`
  overflow-y: scroll;
`;

const Footer = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;
  display: flex;
`;

const ResizeInner = styled.div`
  flex-grow: 1;
  display: flex;
  min-height: 100px;
  height: 100%;
  flex-flow: column;
  /* overflow-y: auto; */
`;

type Props = {
  pipelineId: string;
  defaultSlotId?: string | null;
};

export const XRPipelineEditorPageTemplate: React.FC<Props> = (props) => {
  const { pipelineId, defaultSlotId } = props;

  const {
    isLoadingPipeline,
    isLoadingSlots,
    cursorPipelineName,
    pipelineX,
    canEditPipeline,
    showInspector,
    handleBackPage,
    handleToggleShowInspector,
  } = usePipelineEditor(pipelineId, { defaultSlotId });

  return (
    <Root>
      <Body>
        <Center>
          {isLoadingPipeline ? (
            <LoadingBox />
          ) : (
            <>
              <PipelineNameArea>
                <BackButton onClick={handleBackPage}>
                  <ArrowBackIosIcon />
                </BackButton>
                <div>
                  <PipelineName>{cursorPipelineName}</PipelineName>

                  <PipelineId>{pipelineId}</PipelineId>
                </div>
              </PipelineNameArea>

              <ButtonGroup>
                <NTXerviceImportAndExporter />
                <ModalIconButton
                  disabled={!canEditPipeline}
                  modalTitle={`Permission Management : ${cursorPipelineName}`}
                  modalComponents={
                    <XRPipelinePermissionEditor
                      pipelineX={pipelineX ?? defaultPipeline}
                    />
                  }
                >
                  <SettingsIcon />
                </ModalIconButton>
                <ASIconButton
                  size="small"
                  kind="secondary"
                  onClick={handleToggleShowInspector}
                >
                  {showInspector ? <FullscreenIcon /> : <FullscreenExitIcon />}
                </ASIconButton>
              </ButtonGroup>

              <EditorArea>
                {isLoadingSlots ? (
                  <LoadingBox />
                ) : (
                  <React.Suspense fallback={<XRGridEditorViewLoading />}>
                    <XRGridEditorView />
                  </React.Suspense>
                )}
              </EditorArea>
              <ResizePanel
                direction="n"
                handleClass={"customHandleH"}
                style={{ height: "300px" }}
              >
                <Footer>
                  <ResizeInner>
                    <FixedLogArea>
                      <XRBottomPanel />
                    </FixedLogArea>
                  </ResizeInner>
                </Footer>
              </ResizePanel>
            </>
          )}
        </Center>

        <XRRightPane />
      </Body>
    </Root>
  );
};
