import { api } from "src/api";

export type DeleteActionType = "asset" | "medican" | "dokodemof";

const deleteAssetOne = async (assetId: string): Promise<[boolean, string]> => {
  try {
    const res = await api(`contractors/-/assets`, {
      method: "delete",
      data: {
        assetId: assetId,
      },
    });
    if (res.status === 200) {
      return [true, ""];
    } else {
      //
      return [false, res.data.message];
    }
  } catch (error: any) {
    if (error.response) {
      return [false, error.response.data.message];
    }
    return [false, `failed delete asset. ${assetId}`];
  }
};

export const deleteDataOne = async (
  actionType: DeleteActionType,
  deleteId: string
): Promise<[boolean, string]> => {
  switch (actionType) {
    case "asset":
      return deleteAssetOne(deleteId);

    default:
      break;
  }

  return [false, "error"];
};
