import React, { useState, useCallback } from "react";
import { ASIconButton } from "allegro-ui";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import * as api from "src/utils";
import { cursorSlotState, selectPipelineState } from "src/opt-editor/recoils";
import { useRecoilValue } from "recoil";
import { Slot } from "src/opt-editor/models";
import { useSnackbar, VariantType } from "notistack";

type Props = {};

const startOptimizer = async (pipelineName: string, slot: Slot) => {
  console.log("start Optimizer");
  if (!slot) {
    console.error("スロットが選択されていません");
    return;
  }
  const res = await api.startOptimizer({
    nodeId: slot.name,
    contractorNo: sessionStorage.contractorNo,
    pipelineName,
  });
  console.log(res);
  return res;
};

export const OEStartLogicButton: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);
  const cursorSlot = useRecoilValue(cursorSlotState);
  const { enqueueSnackbar } = useSnackbar();
  const selectPipeline = useRecoilValue(selectPipelineState);

  const handleClick = useCallback(async () => {
    setLoading(true);
    const res = await startOptimizer(selectPipeline, cursorSlot);
    console.log(res);
    //スナックバーに成否を表示する
    const variant: VariantType =
      res?.data.status === "success" ? "success" : "error";
    console.log(res?.data);
    enqueueSnackbar(String(res?.data.message), { variant });
    setLoading(false);
  }, [cursorSlot, enqueueSnackbar, selectPipeline]);
  return (
    <ASIconButton
      kind="primary"
      size="small"
      onClick={handleClick}
      disabled={loading}
    >
      <PlayArrowIcon />
    </ASIconButton>
  );
};
