import React, { useState, useMemo } from "react";
import { ASButton, ASGraphContainer } from "allegro-ui";
import styled from "styled-components";
import Masonry from "react-masonry-css";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { GraphData, Prediction } from "src/fortress/models";
//
//components
//
import { FTDashBoardWidget } from ".";
import { FTRankingTable } from "../molecules";

import { usePredictions } from "src/fortress/hooks/usePrediction";
import { getConfig } from "src/fortress/config";
//
//our libs
//

import { formatPredictionsGraphArray } from "src/fortress/services/prediction";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { childListPageIndexState } from "src/fortress/recoils/childListState";

import { atenaCodesToScatterCursor } from "src/fortress/recoils/scatterState";
import { cursorChildThumbState } from "src/fortress/recoils/singleChildPageState";

const MasonryGrid = styled(Masonry)`
  display: flex;
  margin-left: -30px;
  margin-top: -30px;
`;

const MasonryItem = styled.div`
  margin-left: 30px;
  margin-top: 24px;
`;

const DataGroup = styled.div`
  text-align: right;
  /* margin-bottom: 30px; */
`;

type Props = {};

//児童詳細画面のai予測の画面についての処理を書いている。

export const FTPredictionPanel: React.FC<Props> = () => {
  const baseDate = useMemo(() => {
    return new Date().getTime();
  }, []);
  const config = getConfig(sessionStorage.contractorNo);

  const [graphOneDatas, setGraphOneDatas] = useState<GraphData[]>([]);
  const [graphTwoDatas, setGraphTwoDatas] = useState<GraphData[]>([]);
  const [onePredictionData] = usePredictions(
    config.aiNames["検査1"],
    "虐待リスク判定AI（高感度）"
  );
  const [twoPredictionData] = usePredictions(
    config.aiNames["検査2"],
    "虐待リスク判定AI（中感度）"
  );
  const setPage = useSetRecoilState(childListPageIndexState);
  const [atenaCodesForCursor, setAtenaCodesForCursor] = useRecoilState(
    atenaCodesToScatterCursor
  );
  const cursorChild = useRecoilValue(cursorChildThumbState);

  const init = useCallback(async () => {
    setPage(1);
  }, [setPage]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useNavigate();

  //aiのグラフに表示するデータをsetする処理

  const graphArr = useCallback(() => {
    // const predictionOneDatas: Prediction[] = [];

    // const predictionTwoDatas: Prediction[] = [];

    // predictions.forEach((data) => {
    //   if (data.value === 1) {
    //     predictionOneDatas.push(data);
    //   } else if (data.value === 2) {
    //     predictionTwoDatas.push(data);
    //   } else {
    //     console.log("プッシュなし");
    //   }
    // });

    // setOnePredictionData(predictionOneDatas);
    // setTwoPredictionData(predictionTwoDatas);
    const gdOne = formatPredictionsGraphArray(baseDate, onePredictionData);
    setGraphOneDatas(gdOne);
    const gdTwo = formatPredictionsGraphArray(baseDate, twoPredictionData);
    setGraphTwoDatas(gdTwo);
  }, [baseDate, onePredictionData, twoPredictionData]);

  useEffect(() => {
    graphArr();
    //// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphArr]);

  //散布図一覧のところに飛ぶ処理を書いている。散布図一覧に飛ぶときに、散布図上でその児童が選ばれている状態にするために、宛名番号を設定している。

  const handleAnalysis = () => {
    setAtenaCodesForCursor([...atenaCodesForCursor, Number(cursorChild.atena)]);
    history("/Analysis");
  };

  return (
    <>
      <DataGroup>
        <ASButton kind="primary" onClick={handleAnalysis}>
          散布図を確認
        </ASButton>
      </DataGroup>
      <MasonryGrid
        breakpointCols={1}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <MasonryItem>
          <FTDashBoardWidget title={"AI予測結果"}>
            <ASGraphContainer
              graphDatas={[
                {
                  id: 1,
                  label: "虐待リスク(第１検査）",
                  color: "3561FF",
                  dataPoints: graphOneDatas,
                },
                {
                  id: 2,
                  label: "虐待リスク(第２検査)",
                  color: "FF3939",
                  dataPoints: graphTwoDatas,
                },
              ]}
              graphHeight={400}
            ></ASGraphContainer>
          </FTDashBoardWidget>
        </MasonryItem>

        <MasonryItem>
          <FTDashBoardWidget title={"検査2の結果に影響したデータ"}>
            <FTRankingTable
              rankingData={twoPredictionData[0]?.topFiveData ?? {}}
              color="#FF5F17"
            />
          </FTDashBoardWidget>
        </MasonryItem>

        <MasonryItem>
          <FTDashBoardWidget title={"検査1の結果に影響したデータ"}>
            <FTRankingTable
              rankingData={onePredictionData[0]?.topFiveData ?? []}
              color="#005FFF"
            />
          </FTDashBoardWidget>
        </MasonryItem>
      </MasonryGrid>
    </>
  );
};
