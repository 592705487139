import * as KinderConfig from "./assesmentKinderContent";
import * as StudentConfig from "./assesmentStudentContent";

//7歳児以上なら就学児童、それ以下なら乳幼児期に場合分けするための設定ファイル。乳幼児期と学齢期で新規アセスメント作成のuiが変わる。

type Age = "乳幼児期" | "学齢期";

export const getConfig = (age: Age) => {
  switch (age) {
    case "乳幼児期":
      return KinderConfig;

    case "学齢期":
      return StudentConfig;

    default:
      return KinderConfig;
  }
};
