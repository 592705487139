const ByteSize: { [key: string]: number } = {
  B: 1,
  kB: 1000,
  KB: 1000,
  kiB: 1024,
  MB: 1000 ** 2,
  MiB: 1024 ** 2,
  GB: 1000 ** 3,
  GiB: 1024 ** 3,
  TB: 1000 ** 4,
  TiB: 1024 ** 4,
};

const byteStrPattern = /^(\d*\.?\d+)(KiB|kB|KB|MiB|MB|GB|TB)?$/;

export const parsePercentageStr = (cpuStr: string): number => {
  try {
    return Number(cpuStr.replace("%", ""));
  } catch (err) {
    return 0;
  }
};

export const numToPercentage = (num: number): string => {
  return `${num}%`;
};

export const parseBytesStr = (byteStr: string): number => {
  const matches = byteStr.match(byteStrPattern);
  if (!matches) {
    // throw new Error("Invalid byte size format", );
    return 0;
  }

  const [, value, unit] = matches;
  const bytes = parseInt(value, 10);

  return bytes * (ByteSize[unit] ?? 1);
};

export const numToBytesSI = (num: number): string => {
  const units = ["B", "KB", "MB", "GB", "TB"]; // バイトの単位

  let i = 0;
  while (num >= 1000 && i < units.length - 1) {
    num /= 1000;
    i++;
  }
  const decimal = num % 1 === 0 ? 0 : 2;
  return `${num.toFixed(decimal)} ${units[i]}`; // 小数点以下2桁までの文字列に変換して単位を付ける
};
