import { url } from "../url";
import { api } from "../http";

export const remove = async (
  contractorName: string,
  collection: "rawdatas" | "topdatas",
  q: any
) => {
  try {
    const res = await api.delete(url.topData(contractorName, collection), {
      params: {
        q: q,
      },
    });
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};
