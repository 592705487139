import React from "react";
import { ARGlobalNav } from "src/allegrobot/components/organisms/ARGlobalNav";
import bg from "src/fortress/assets/img/black-polygonal.jpg";
import { headerOffset } from "src/portal/components/organisms/PTGlobalNav";
import styled from "styled-components";

const Root = styled.div`
  background-size: cover;
  width: 100%;
  min-height: calc(100vh - ${headerOffset}px);
  text-align: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BackImage = styled.div`
  width: 100%;
  min-height: calc(100vh - ${headerOffset}px);
  display: flex;
  position: fixed;
  filter: ${(props) => (props.theme.darkMode ? "invert(0)" : "invert(90%)")};
  background-image: url(${bg});
  background-size: cover;
`;

export type CHLoginPageProps = {
  logo: any;
};

export const CHUserAuthMainViewTemplate: React.FC<CHLoginPageProps> = (
  props
) => {
  return (
    <>
      <ARGlobalNav logo={props.logo} />
      <BackImage />
      <Root>{props.children}</Root>
    </>
  );
};
