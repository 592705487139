import React from "react";
import { Link } from "react-router-dom";
import routes from "../../routes.json";
import { ASButton } from "allegro-ui";
import styled from "styled-components";
import { useAuth } from "src/root/UseAuth";
export const headerOffset = 70;

const Container = styled.div`
  /* width: 100%; */
  height: ${headerOffset}px;
  background: ${(props) => "#000"};
  padding: 0 30px;
  border-bottom: 1px solid #37383a;
  /* position: fixed; */
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 23px;
  font-weight: bold;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
`;

const MenuItem = styled.li`
  color: #fff;
  > a {
    color: #fff;
    text-decoration: none;
    margin-right: 36px;
    font-size: 14px;
    font-weight: 500;
    /* font-weight: bold; */
    &:hover {
      opacity: 0.8;
    }
  }
`;

const MenuGroup = styled.ul`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const Skelton = styled.div`
  clear: both;
  position: relative;
  /* width:100%: */
  /* height: ${headerOffset}px; */
`;

type Props = {};
export const XRGlobalNav: React.FC<Props> = (props) => {
  const auth = useAuth();

  return (
    <>
      <Container>
        {/* <Title>AllegRobot</Title> */}

        <MenuGroup>
          <MenuItem>
            <Link to={routes.HOME}>PIPELINE</Link>
          </MenuItem>
          <MenuItem>
            <Link to={routes.ADMIN_XERVICE_BROWSER}>XERVICE</Link>
          </MenuItem>
          <MenuItem>
            <Link to={routes.OPTX_BROWSER}>OPTX</Link>
          </MenuItem>

          {/* <ASButton
            kind="secondary"
            onClick={() => {
              if (auth?.logout) auth.logout();
            }}
          >
            LOGOUT
          </ASButton> */}
        </MenuGroup>
      </Container>
      <Skelton></Skelton>
    </>
  );
};
