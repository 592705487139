import * as React from "react";
import { useState, useEffect } from "react";
import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { CHTabs } from "../molecules/CHTabs";
import { metricCreatingFormDefinition } from "src/cheetah/definitions/forms/metricCreatingFormDefinition";
import { metricBroweserUi } from "src/cheetah/definitions/listPages/metricBrowserUi";
import { useMyRole } from "src/portal/hooks/useMyRole";
import styled from "styled-components";
import { MDNoDataPlaceHolder } from "src/medican/components/atoms";
import { createNewtZeroAPI } from "src/api";
import { LCLoadingBoard } from "../libs/ASLowCodeBrowser";

interface ICHMetricSinglePageProps {
  itemId: string;
}

const NoAuthorityBlock = styled(MDNoDataPlaceHolder)``;

/**
 *
 * @param param0
 * @returns
 * @deprecated 汎用化する。
 */
const CHMetricSinglePageTemplate: React.FunctionComponent<ICHMetricSinglePageProps> =
  ({ ...props }) => {
    const [initialized, setInitalized] = useState(false);
    const [metricDetail, setMetricDetail] = useState<any>();
    const role = useMyRole();
    const newtzero = createNewtZeroAPI();

    //fetch metric
    useEffect(() => {
      if (!initialized) {
        const query = {
          ...metricBroweserUi.newtQ,
          ...{
            search: {
              _id: props.itemId,
            },
          },
        };
        try {
          newtzero.dataProvider(query).then((resData: any[]) => {
            if (resData && resData.length > 0) {
              setMetricDetail(resData[0]);
            }
          });
        } catch (err) {}

        setInitalized(true);
      }

      return () => {};
    }, [initialized, newtzero, props.itemId]);

    const tabs = [
      {
        label: "編集",
        component: role.canUpdateMetric ? (
          <ASLowCodeFormContainer
            formDefinition={metricCreatingFormDefinition}
            fullWidth
            isUpdate
            defaultValues={
              metricDetail
                ? {
                    name: metricDetail?.value,

                    dataType: metricDetail?.dataType,
                    means: metricDetail?.means[sessionStorage.contractorNo],
                    tags: metricDetail?.tags[sessionStorage.contractorNo],
                    shareLevel: metricDetail.shareLevel,
                  }
                : undefined
            }
          />
        ) : (
          <NoAuthorityBlock>権限がありません。</NoAuthorityBlock>
        ),
      },
    ];
    return (
      <>
        {metricDetail ? (
          <CHTabs tabs={tabs} title={metricDetail.value} />
        ) : (
          <>
            <LCLoadingBoard />
          </>
        )}
      </>
    );
  };

export default CHMetricSinglePageTemplate;
