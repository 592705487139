import * as React from "react";
import { useState } from "react";
import { ASModal, ASButton } from "allegro-ui";

import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { ciVariablesCreatingFormDefinition } from "../../../cheetah/definitions/forms/ciVariablesCreatingFormDefinition";

import { ASLowCodeDataBrowserContainer } from "src/cheetah/components/libs/ASLowCodeBrowser";

import styled from "styled-components";
import { ciVariablesBroweserUi } from "../../../cheetah/definitions/listPages/ciVariablesBrowserUi";
import FTSystemPageTemplate from "./FTSystemPageTemplate";

const Header = styled.div`
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.textColor};
  > *:last-child {
    margin-left: auto;
  }
`;

interface IFTCIVariablesListPageTemplateProps {}

const FTCIVariablesListPageTemplate: React.FunctionComponent<IFTCIVariablesListPageTemplateProps> =
  (props) => {
    const [open, setOpen] = useState(false);

    const handleModalOpen = () => {
      setOpen(true);
    };

    const handleModalClose = () => {
      setOpen(false);
    };
    return (
      <>
        <FTSystemPageTemplate>
          <Header>
            CI/CD 設定一覧
            <ASButton kind="primary" onClick={handleModalOpen}>
              新規作成
            </ASButton>
          </Header>
          <ASLowCodeDataBrowserContainer
            name="ft-ci-variables-list-page"
            uiConf={ciVariablesBroweserUi}
          />
        </FTSystemPageTemplate>

        <ASModal open={open} onClose={handleModalClose}>
          <ASLowCodeFormContainer
            formDefinition={ciVariablesCreatingFormDefinition}
            onClose={handleModalClose}
          />
        </ASModal>
      </>
    );
  };

export default FTCIVariablesListPageTemplate;
