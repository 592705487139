import React, { FC } from "react";
import styled from "styled-components";

type ContractorLogoProps = {
  tiny?: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Title = styled.div`
  /* margin-left: 8px; */
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.7;
  }
  font-size: 15px;
  font-weight: bold;
  padding: 11px;
  background: ${(props) => (props.theme.darkMode ? "#111" : "#fff")};
  border-radius: 4px;
  margin-right: 8px;
  font-weight: 600;
  letter-spacing: 0.7px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
`;

const SubTitle = styled.div.attrs<ContractorLogoProps>(
  (props) => {}
)<ContractorLogoProps>`
  font-size: ${(props) => (props.tiny ? 11 : 18)}px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
  display: flex;
  flex-direction: row;
  letter-spacing: 0.7px;
  font-style: ${(props) => (props.tiny ? "italic" : "")};
  align-items: center;
`;

export const ConsoleText = styled.h1.attrs<ContractorLogoProps>(
  (props) => {}
)<ContractorLogoProps>`
  /* margin-left: 8px; */
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.7;
  }
  > img {
    margin-top: -3px;
    width: 110px;
  }
  margin-left: 3px;

  /* font-size: ${(props) => (props.tiny ? 12 : 18)}px; */
  font-weight: 400;
  /* font-style: italic; */
  /* padding-bottom: 1px; */
`;

type Props = {
  contractorNo?: string;
};

const NZConractorLogo: React.FC<ContractorLogoProps> = (props) => {
  return (
    <SubTitle tiny={props.tiny}>
      {props.tiny ? "by" : ""} NewtZero
      <ConsoleText tiny={props.tiny}>Console</ConsoleText>
    </SubTitle>
  );
};

export const NewtZeroLogo: FC<Props> = (props: Props) => {
  const headerTitle = (props.contractorNo ?? "").toLocaleUpperCase();
  return (
    <Container>
      {props.contractorNo ? (
        <>
          <Title>{`${headerTitle}`}</Title>

          <NZConractorLogo tiny />
        </>
      ) : (
        <NZConractorLogo />
      )}
    </Container>
  );
};

export default NewtZeroLogo;
