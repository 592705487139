import * as React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as api from "src/fortress/api";
import { CHTabs } from "../../molecules/CHTabs";
import { metricCreatingFormDefinition } from "src/cheetah/definitions/forms/metricCreatingFormDefinition";
import { metricBroweserUi } from "src/cheetah/definitions/listPages/metricBrowserUi";
import { useMyRole } from "src/portal/hooks/useMyRole";
import { ASLowCodeFormContainer } from "allegro-low-code-components";

interface ICHMetricSinglePageProps {}

/**
 *
 * @param param0
 * @returns
 * @deprecated TODO:factory化する
 */
const CHMetricSinglePage: React.FunctionComponent<ICHMetricSinglePageProps> = ({
  ...props
}) => {
  const [metricDetail, setMetricDetail] = useState<any>();
  const params = useParams();
  const role = useMyRole();

  //fetch metric
  useEffect(() => {
    console.log(metricBroweserUi.newtQ);
    api
      .newtOut({
        ...metricBroweserUi.newtQ,
        ...{
          search: {
            value: params.itemId,
          },
        },
      })
      .then(([resData, count]) => {
        if (resData && resData.length > 0) {
          console.log(resData[0]);
          setMetricDetail(resData[0]);
        }
      });

    return () => {
      setMetricDetail(undefined);
    };
  }, [params.itemId]);

  const tabs = [
    {
      label: "編集",
      component: !role.canUpdateMetric ? (
        <React.Suspense fallback={<>loading</>}>
          <ASLowCodeFormContainer
            formDefinition={metricCreatingFormDefinition}
            fullWidth
            isUpdate
            defaultValues={
              metricDetail
                ? {
                    name: metricDetail?.name,
                    type: metricDetail?.name.match(/non_numeric_/)
                      ? "文字"
                      : "数値",
                    means: metricDetail?.means[sessionStorage.contractorNo],
                    tags: metricDetail?.tags[sessionStorage.contractorNo],
                    shareLevel: metricDetail.shareLevel,
                  }
                : undefined
            }
          />
        </React.Suspense>
      ) : (
        <></>
      ),
    },
    {
      label: "履歴",
      component: <></>,
    },
  ];
  return <>{metricDetail ? <CHTabs tabs={tabs} title={"aa"} /> : <></>}</>;
};

export default CHMetricSinglePage;
