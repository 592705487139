import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";

type Props = {
  contractorNo: string;
  dataFetcher: (limit: number, skip: number, options?: any) => Promise<any[]>;
  onDelete: (id: string) => Promise<void>;
  canDeleteGroup?: boolean;
};

export const createGroupListBrowserUi = (props: Props): ASBrowserUiConf => {
  return {
    newtQ: props.dataFetcher,
    verticalFilters: [
      {
        label: "Search",
        design: "search",
        selections: [],
        callback: (value: string) => {
          return {
            "contents.rawdata.group_name": { $regex: value },
          };
        },
      },
    ],
    tables: [
      {
        label: "Name",
        component: "LCTextLinkCellComponent",
        data: {
          label: "group_name",
          id: "group_id",
        },
        statics: {
          path: "/settings/groups/{id}",
        },

        options: {},
      },

      {
        label: "Members",
        component: "LCSimpleColComponent",
        data: (data: any) => {
          const users = data.users ?? [];
          return {
            value: users.length,
          };
        },
        options: {},
      },
      {
        label: "Owner",
        component: "LCUserIconComponent",
        data: {
          userNo: "userNo",
        },
        options: {
          dateFormat: "wareki",
          editable: false,
        },
      },
      {
        label: "",
        component: "LCDeleteButtonComponent",
        data: {
          value: "group_id",
        },
        options: {
          dateFormat: "wareki",
          disabled: !props.canDeleteGroup,
          editable: false,
          onSubmit: props.onDelete,
        },
      },
    ],
  };
};
