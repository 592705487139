import React from "react";
import { OEManagementPageTemplate } from "src/opt-editor/components/templates/OEManagementPageTemplate";
import { NTGlobalNav } from "../organisms/NTGlobalNav";

type Props = {};
export const NTManagementPage: React.FC<Props> = (props) => {
  return (
    <div>
      <NTGlobalNav />
      <OEManagementPageTemplate />
    </div>
  );
};
