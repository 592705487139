import { AxiosRequestConfig } from "axios";
import { createContext, useContext, useState } from "react";
import { api } from "src/api";
import { dFurusatoAPIConfig } from "src/dfurusato-pf/api/config";
import { DFNotFoundPage } from "src/dfurusato-pf/components/pages/DFNotFoundPage";

type AxiosState = "success" | "notFound" | "serverError" | "loading";

type AxiosContextProps = {};

const axiosContext = createContext<AxiosContextProps>({});

export const useAxiosProvider = (): AxiosContextProps => {
  return {};
};

export const AxiosProvider: React.FC<{}> = ({ children }) => {
  const auth: AxiosContextProps = useAxiosProvider();
  api.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers.Authorization = sessionStorage.token;
    config.headers["User-Id"] = sessionStorage.userId;
    return config;
  });

  dFurusatoAPIConfig.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers.Authorization = sessionStorage.token;
    config.headers["User-Id"] = sessionStorage.userId;
    return config;
  });

  return <axiosContext.Provider value={auth}>{children}</axiosContext.Provider>;
};

export const useAxiosContext = () => {
  return useContext(axiosContext);
};
