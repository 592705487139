import { medicanAPIConfig } from "./config";

export const getSubjectMany = async (
  limit: number,
  skip: number,
  name?: string,
  alerts?: string[],
  sort?: any
): Promise<any[]> => {
  const res = await medicanAPIConfig.get("/subject", {
    params: { apikey: sessionStorage.token, limit, skip, name, alerts, sort },
  });

  return res.data.message ?? [];
};

export const getSubjectOne = async (
  subjectId: string
): Promise<any | undefined> => {
  const res = await medicanAPIConfig.get(`/subject/${subjectId}`, {
    params: {
      apikey: sessionStorage.token,
    },
  });

  return res.data.message[0];
};

export const saveSubject = async (data: any): Promise<boolean> => {
  console.log({
    params: {
      apikey: sessionStorage.token,
    },
  });
  try {
    const res = await medicanAPIConfig.post("/subject", data, {
      params: {
        apikey: sessionStorage.token,
      },
    });

    return res.status === 200;
  } catch (err) {
    console.error(err);
    return false;
  }
};
