import React from "react";
import CHAssetSinglePageTemplate from "src/cheetah/components/templates/CHAssetSinglePageTemplate";
import { useParams } from "react-router-dom";
import { NZDataOpsViewTemplate } from "../../../templates/NZDataOpsViewTemplate";
import { NZPrivatePageTemplate } from "src/newtzero-console/components/templates/NZPrivatePageTemplate";
import { NZWrapper } from "src/newtzero-console/components/atoms/NZWrapper";

type Props = {};

export const NZAssetSinglePage: React.FunctionComponent<Props> = ({
  ...props
}) => {
  const params = useParams();
  return params.itemId ? (
    <NZPrivatePageTemplate>
      <NZWrapper>
        <CHAssetSinglePageTemplate itemId={params.itemId} />
      </NZWrapper>
    </NZPrivatePageTemplate>
  ) : (
    <>ERROR</>
  );
};
