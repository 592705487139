export const decodeUserNoText = (userId: string): number | null => {
  const string: string = userId;
  const pattern: RegExp = /u35(\d+)/;

  const match: RegExpMatchArray | null = string.match(pattern);
  if (match) {
    const result: string = match[1];
    return Number(result);
  } else return Number(userId);
};
