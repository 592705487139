import React from "react";
import styled from "styled-components";

//スクロールしても途中で固定されるコンポーネント
const y = window.pageYOffset;

const Container = styled.div`
  width: 100%;
  position: sticky;
  top: 0px;
  background: ${(props) => props.theme.bgPrimaryColor};
  z-index: 10;
`;

export const ASFixedHeader: React.FC = ({ children }) => {
  return <Container>{children}</Container>;
};
