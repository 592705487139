import { useRecoilValueLoadable } from "recoil";
import { myProfileState } from "src/root/recoils/userState";
import { NAMainViewTemplate } from "../templates/NAMainViewTemplate";
import { PTSettingNav } from "src/portal/components/templates/PTSettingNav";
import { PTSettingTeamTemplate } from "src/portal/components/templates/PTSettingTeamTemplate";

type Props = {};
export const NASettingTeamPage: React.FC<Props> = (props) => {
  const myProfile = useRecoilValueLoadable(myProfileState);

  switch (myProfile.state) {
    case "hasValue":
      if (myProfile && myProfile.contents)
        return (
          <NAMainViewTemplate>
            <PTSettingNav>
              <PTSettingTeamTemplate />
            </PTSettingNav>
          </NAMainViewTemplate>
        );
      return <>error</>;
    case "loading":
      return <>loading</>;
    case "hasError":
      throw myProfile.contents;
  }
};
