//Libs
import React, { useState, useCallback } from "react";
import { useRecoilValue, useRecoilRefresher_UNSTABLE } from "recoil";

//Components
import { ASButton, ASInputBase, ASModal } from "allegro-ui";
//States
import scienceFolder from "../../recoils/ScienceFolderState";

//Types
import { createScienceFolder } from "src/opt-editor/models/ScienceFSItem";
import { ASFormLabel } from "allegro-low-code-components";
import { ConfirmModalContents } from "src/kinzaza/components";
import styled from "styled-components";

const Container = styled.div`
  padding-bottom: 24px;
`;
type Props = {};

export const OECreateProjectButton: React.FC<Props> = (props) => {
  // createボタンのT/F
  const [showCreateButton, setShowCreateButton] = useState(false);
  // モーダル画面のT/F
  const [showAddModal, setShowAddModal] = useState(false);
  const refreshTreeList = useRecoilRefresher_UNSTABLE(scienceFolder.idList);
  // 入力されたテキストを格納
  const nextFolderId = useRecoilValue(scienceFolder.nextAutoIncrementalId);
  const [inputFolderName, setInputFolderName] = useState("");
  const cursorFolderId = useRecoilValue(scienceFolder.cursorItemIdState);
  // 新規作成処理

  const validateProjectName = (text: string) => {
    return text.length > 0;
  };

  const handleCreateFolder = useCallback(async () => {
    // モーダル画面閉じる
    setShowAddModal(false);

    await scienceFolder.addItem(
      createScienceFolder({ itemId: nextFolderId, name: inputFolderName })
    );
    refreshTreeList();
    // setRequestId((v) => v + 1);
  }, [cursorFolderId, inputFolderName, nextFolderId, refreshTreeList]);

  return (
    <>
      <ASButton kind="primary" onClick={() => setShowAddModal(true)} fullWidth>
        Create Project
      </ASButton>
      <ASModal
        title="新規プロジェクト"
        open={showAddModal}
        onClose={setShowAddModal}
      >
        <ConfirmModalContents
          cancelLabel="Cancel"
          submitLabel="Create"
          submitDisabled={!showCreateButton}
          onCancel={() => setShowAddModal(false)}
          onSubmit={() => handleCreateFolder()}
        >
          <Container>
            <ASFormLabel>プロジェクト名</ASFormLabel>
            <ASInputBase
              key="projectName"
              kind="digicho"
              fullWidth
              onChange={(e: any) => {
                setInputFolderName(() => e.target.value);
                setShowCreateButton(validateProjectName(e.target.value));
              }}
            />
          </Container>
        </ConfirmModalContents>
      </ASModal>
    </>
  );
};
