import { produce } from "immer";

export type Project = {
  formId: string;
  name: string;
  slug: string;
  category: string;
  userName?: string;
  createdBy: string;
  readers: string[];
  writers: string[];

  // 追加情報
  drugCode?: string;
  planCode?: string;
  taskName?: string;
  institutionName?: string;
};

export const defaultProject: Project = {
  formId: "",
  name: "-",
  slug: "",
  userName: "",
  category: "その他",
  createdBy: "-",
  readers: ["*"],
  writers: ["*"],
  drugCode: "",
  planCode: "",
  taskName: "",
  institutionName: "",
};

export const createProject = (data: any): Project => {
  return produce(defaultProject, (draft) => {
    draft.formId = data.formId;
    draft.name = data.name ?? draft.name;
    draft.slug = data.slug ?? draft.slug;
    draft.category = data.category ?? draft.category;
    draft.createdBy = data.createdBy ?? draft.createdBy;
    draft.userName = data.userName ?? draft.userName;
    draft.readers = data.readers ?? draft.readers;
    draft.writers = data.writers ?? draft.writers;
    draft.drugCode = data.drugCode ?? draft.drugCode;
    draft.planCode = data.planCode ?? draft.planCode;
    draft.taskName = data.taskName ?? draft.taskName;
    draft.institutionName = data.institutionName ?? draft.institutionName;
  });
};

export const createProjectFromNewtData = (data: any): Project => {
  const extracted = {
    ...data.contents.rawdata,
    formId: data.contents.optdata.non_numeric_form_id,
    readers: data.readers,
    writers: data.writers,
  };

  return createProject(extracted);
};
