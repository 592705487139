import { atom, selector } from "recoil";
import { createChildList } from "../models";
import * as api from "../api";
import { cursorChildThumbState } from "./singleChildPageState";

//基本的に複数のコンポーネントに跨って利用されているステートはrecoilを利用し、グローバルステートとして保持している。

//虐待予防的支援データに入っている世帯番号から、家族構成のデータを取得するようにしている。
//虐待予防的支援データを中心にしてありとあらゆるデータを取得する設計を採用している。
export const cursorSetaiNum = atom<number | undefined>({
  key: "cursorSetaiNumState",
  default: undefined,
});

export const familyListSelector = selector({
  key: "FamilyListSlector",
  get: async ({ get }) => {
    const setaiNum = get(cursorSetaiNum);
    if (setaiNum) {
      const [resData] = await api.getFamilyList(setaiNum);
      return createChildList(resData);
    } else {
      return [];
    }
  },
});
