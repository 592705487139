import produce from "immer";
import { useEffect, useState, useCallback } from "react";

type SubjectSupport = {
  subjectId: string | number | undefined;
  watch?: boolean;
  memo?: string;
};

const initialSubjectSupport: SubjectSupport = {
  subjectId: undefined,
  watch: false,
  memo: "",
};

export type IFuchucoDataProps = {
  childId: string | number;
  watch?: boolean;
  memo?: string;
  onToggle?: (data: any) => Promise<void>;
  initialize?: () => Promise<any>;
};

export default function useFuchucoData(props: IFuchucoDataProps) {
  const { childId, watch, memo } = props;
  const [supportData, setSelf] = useState<SubjectSupport>({
    ...initialSubjectSupport,
    subjectId: childId,
    watch: watch ?? false,
    memo: memo ?? "",
  });

  const init = useCallback(async () => {
    if (!childId) return;
    if (props.initialize) {
      try {
        const data = await props.initialize();
        setSelf(data);
      } catch (err) {
        console.error(err);
      }
    }
  }, [childId, props]);

  useEffect(() => {
    init();
  }, [childId, init]);

  const postData = useCallback(
    async (data: SubjectSupport) => {
      if (props.onToggle) {
        await props.onToggle(data);
      }
    },
    [props]
  );

  const toggleWatch = useCallback(async () => {
    if (!supportData.subjectId) {
      console.error("subjectId is undefined");
      return;
    }
    const nextSelf = produce(supportData, (draft) => {
      draft.watch = !draft.watch;
    });
    // await postFuchucoData(nextSelf);
    try {
      await postData(nextSelf);
    } catch (err) {}

    //@todo post integration data too.
    setSelf(nextSelf);
  }, [postData, supportData]);

  const writeMemo = useCallback(
    async (text: string) => {
      const nextSelf = produce(supportData, (draft) => {
        draft.memo = text;
      });
      // await postFuchucoData(nextSelf);
      await postData(nextSelf);
      setSelf(nextSelf);
    },
    [postData, supportData]
  );

  //   const [fuchucoData, setFuchucoData] = useRecoilState();
  return { self: supportData, toggleWatch, writeMemo };
}
