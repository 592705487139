import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Table, TableBody, TableCell, TableHead } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

import { User } from "src/opt-editor/models";
import { useItemReaders } from "src/opt-editor/hooks/useItemReaders";
import * as scienceFolderActions from "src/opt-editor/models/ScienceFSItem";
import { useProfileList } from "src/portal/hooks/useProfileList";
import { usePermissionEdit } from "src/opt-editor/hooks/usePermissionEdit";

import { ASButton, ASModal } from "allegro-ui";
import { ASPictureIcon, ASTableRow } from "allegro-ui";
import { OESearchField } from "./OESearchField";

const InviteArea = styled.div`
  padding-top: 24px;
  margin-top: 24px;
  > * {
    margin-top: 8px;
  }
  border-top: 1px solid ${(props) => props.theme.secondaryBorderColor};
`;

const Note = styled.div`
  color: ${(props) => props.theme.cautionColor};
  font-size: ${(props) => props.theme.regularFontSizeMd}px;
`;

const Container = styled.div`
  padding: 30px;
  max-width: 90vw;
  max-height: 90vh;
  overflow: scroll;
  color: ${(props) => props.theme.textColor};
`;

const StyledCell = styled(TableCell)`
  color: ${(props) => props.theme.textColor};
  padding: 10px;
`;

const StyledButton = styled(ASButton)`
  width: 105px;
`;

const TableContainer = styled.div``;

const StyledTableRow = styled(ASTableRow)`
  /* background-color: ${(props) => props.theme.cardColor}; */
  color: ${(props) => props.theme.textColor};
  height: 60px;
  border-radius: 20%;
`;

const ModalHead = styled.div`
  display: flex;
  > h2 {
    font-size: 24px;
  }
  > *:last-child {
    margin-left: auto;
  }
`;

type Props = {
  showChild: any;
  item: scienceFolderActions.ScienceItem;
  onChangePermission: (newItem: scienceFolderActions.ScienceItem) => void;
  onMenuClose: () => void;
};

//@todo Fileを使うのは不本意。SmartFileを使って関数を共通化するのがベター。
// switchで処理を分岐させるべきでない。 inoue.
/**
 * Read/Writeの権限を設定するコンポーネント
 */
export const OEChangeItemPermissionMenu: React.FC<Props> = ({
  item,
  onMenuClose,
  onChangePermission,
  showChild,
}) => {
  // モーダル画面のT/F
  const [showAddModal, setShowAddModal] = useState(false);
  // 入力されたテキストを格納

  // 新規作成処理api

  const [readers, setReaders] = useState<User[]>([]);
  const [openOrClose, setOpenClosed] = useState<string>("Opened");

  const { getUserByEmail } = useProfileList();

  const [matchedUser, setMatchedUser] = useState<User>();

  const { getReaders } = useItemReaders();

  const { removeUser, toReader, toWriter, toPublic, toPrivate } =
    usePermissionEdit(item);
  /**
   * Emailフィールドの入力を管理する。
   * 入力がユーザーのEmailとマッチした場合、マッチユーザーを保存する。
   */
  const onChangeInputEmail = (email: string) => {
    const user = getUserByEmail(email);
    if (user) {
      setMatchedUser(user);
    } else {
      setMatchedUser(undefined);
    }
  };

  useEffect(() => {
    const { userList, status } = getReaders(item);
    setReaders(userList);
    setOpenClosed(status);
  }, [getReaders, item]);

  const showMenuAdd = (event: React.ChangeEvent<{}>) => {
    setShowAddModal(true);
    // 親コンポーネントのmouseX,mouseYにnullセット
    showChild({ mouseX: null, mouseY: null });
    onMenuClose();
  };

  return (
    <>
      <MenuItem onClick={showMenuAdd}>ChangeAuthority</MenuItem>
      <ASModal open={showAddModal} onClose={() => setShowAddModal(false)}>
        <Container>
          <div>
            <ModalHead>
              <h2>
                Configration:{item.name}
                {openOrClose === "Opened" ? (
                  <Note>This Folder/File is opened for this contractor.</Note>
                ) : (
                  <></>
                )}
              </h2>
              {openOrClose === "Opened" ? (
                <ASButton
                  kind="primary"
                  onClick={() => {
                    onChangePermission(toPrivate());
                  }}
                >
                  to Private
                </ASButton>
              ) : (
                <ASButton
                  kind="primary"
                  onClick={() => {
                    onChangePermission(toPublic());
                  }}
                >
                  to Public
                </ASButton>
              )}
            </ModalHead>
            <TableContainer>
              <UserListComponent
                userList={readers}
                onClick={(id) => {
                  console.log(id);
                }}
                item={item}
                onRemove={(userId) =>
                  onChangePermission(removeUser(item, userId))
                }
                toWriter={toWriter}
                toReader={toReader}
                onChangePermission={onChangePermission}
              />
            </TableContainer>
          </div>
          <div>
            <InviteArea>
              <h2>Add member</h2>
              <OESearchField
                placeholder="Search team"
                onChange={(t) => {
                  onChangeInputEmail(t);
                }}
              />
              <h3>
                {matchedUser ? (
                  <>
                    <ASPictureIcon
                      src={matchedUser.avatar}
                      defaultSrc={matchedUser.avatar}
                      size={30}
                    />
                    {matchedUser.username}
                  </>
                ) : (
                  ""
                )}
              </h3>

              <ASButton
                disabled={!matchedUser}
                kind="primary"
                onClick={() => {
                  if (matchedUser)
                    onChangePermission(toReader(String(matchedUser.userNo)));
                }}
              >
                Add
              </ASButton>
            </InviteArea>
          </div>
        </Container>
      </ASModal>
    </>
  );
};

/**
 * ユーザー一覧を描画するコンポーネント
 */
const UserListComponent: React.FC<{
  userList: User[];
  onClick: (userId: string | number) => void;
  onRemove: (userId: string) => void;
  toReader: (userId: string) => void;
  toWriter: (userId: string) => void;
  onChangePermission: any;
  item: scienceFolderActions.ScienceItem;
}> = ({
  userList,
  onClick,
  item,
  onRemove,
  toReader,
  toWriter,
  onChangePermission,
}) => {
  const isWriter = (userId: string) => {
    return item.writers.includes(userId);
  };

  const isReader = (userId: string) => {
    return item.readers.includes(userId);
  };

  return (
    <Table>
      <TableHead>
        <StyledCell></StyledCell>
        <StyledCell>Name</StyledCell>
        <StyledCell>Email</StyledCell>
        <StyledCell>Permission</StyledCell>
        <StyledCell>Edit</StyledCell>
        <StyledCell></StyledCell>
      </TableHead>
      <TableBody>
        {userList.map((user, id) => {
          const src = user.avatar;
          const columns = [user.nickName, user.username];
          const userNo = user.userNo;

          const authorityText = isWriter(String(user.userNo))
            ? "Writer"
            : isReader(String(user.userNo))
            ? "Reader"
            : "--";

          return (
            <StyledTableRow key={`user-list-table-${id}-wrmenu`}>
              <StyledCell>
                <ASPictureIcon src={src} defaultSrc={src} size={30} />
              </StyledCell>
              {columns.map((text, id) => {
                return (
                  <StyledCell key={`user-list-table-cell-${id}${text}`}>
                    {text}
                  </StyledCell>
                );
              })}
              <StyledCell>{authorityText}</StyledCell>
              <StyledCell>
                {authorityText === "Reader" ? (
                  <>
                    <ASButton
                      kind="primary"
                      onClick={() =>
                        onChangePermission(toWriter(String(user.userNo)))
                      }
                    >
                      to Writer
                    </ASButton>
                  </>
                ) : (
                  <>
                    <StyledButton
                      kind="primary"
                      onClick={() =>
                        onChangePermission(toReader(String(user.userNo)))
                      }
                    >
                      to Reader
                    </StyledButton>
                  </>
                )}
              </StyledCell>
              <StyledCell>
                <ASButton
                  kind="secondary"
                  onClick={() =>
                    onChangePermission(onRemove(String(user.userNo)))
                  }
                >
                  Remove
                </ASButton>
              </StyledCell>
            </StyledTableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
