import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { OTTextEditPanel } from "allegro-ui";
import { File } from "../../models";
import { useFileMediator } from "src/opt-editor/hooks/useFileMediator";
import { ScienceFile } from "src/opt-editor/models/ScienceFSItem";

const Container = styled.div``;

type Props = {
  file: ScienceFile;
  onTextChange?: (text: string) => void;
};

type TextData = {
  blocks: Array<{ text: string; type: string }>;
  entityMap: object;
};

export const OEFileEditor: React.FC<Props> = (props) => {
  const complexFileActions = useFileMediator();
  const [fileData, setFileData] = useState<any>();
  const [editable, setEditable] = useState(true);
  const file = props.file;

  const createInitData = () => {
    let parseBlocks: any = [];
    if (!file.contents?.blocks) {
      const text = file.contents;
      const data = text?.split("¥n");
      data?.forEach((text: string) => {
        const block = {
          text: text,
          type: "unstyled",
        };
        parseBlocks.push(block);
      });
    }
    return {
      blocks: file.contents?.blocks ?? parseBlocks,
      entityMap: file.contents?.entityMap ?? {},
    };
  };

  const [initData, setInitData] = useState(createInitData());

  useEffect(() => {
    setInitData(createInitData());
    setEditable(complexFileActions.isWritable(file));
  }, [file]);

  const onSave = async () => {
    const newFile: ScienceFile = {
      ...file,
      contents: fileData,
    };
    const success = await complexFileActions.update(newFile);
    if (success) {
      // actions.getList();
    }
  };

  const onTextChange = (textData: TextData) => {
    setFileData(textData);
  };

  const args = {
    ...props,
    title: "FileEditor",
    defaultContent: initData,
    onTextChange,
    onSave,
    editable,
  };

  return (
    <Container>
      <OTTextEditPanel {...args} />
    </Container>
  );
};
