import React from "react";
import styled from "styled-components";

type Props = {};

const Wrapper = styled.div`
  padding: 30px;
  color: ${(props) => props.theme.secondaryOnText};
`;

export const NZWrapper: React.FC<Props> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};
