import React from "react";
import { NZGlobalNav } from "../organisms/NZGlobalNav";
type Props = {};
export const NZMainViewTemplate: React.FC<Props> = ({ children }) => {
  return (
    <div>
      <NZGlobalNav />
      {children}
    </div>
  );
};
