import { api } from "../http";

type Args = {
  bundleId: string;
  version: string;
  newVersion: string;
  comment?: string;
};

export const commit = async (props: Args) => {
  const { bundleId, version, newVersion, comment } = props;

  try {
    const res = await api.post(`/bundles/${bundleId}/${version}/commit`, {
      version: newVersion,
      comment,
    });

    return res.data;
  } catch (err) {
    console.error(err);
  }
};
