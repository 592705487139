//
// third party libs
//
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
//
//components
//
import { ASIconButton, ASModal } from "allegro-ui";
import { NZSchemaTypeChip } from "src/newtzero-console/components/atoms/NZSchemaTypeChip";
import { MonitorThumb } from "src/kinzaza/models";
import { Menu, MenuItem } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import SyncIcon from "@material-ui/icons/Sync";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
//
//our libs
//

import { useDeleteMonitor, useMonitorThumb } from "src/kinzaza/hooks";
import * as api from "src/kinzaza/api";
import { useRecoilState } from "recoil";
import { monitorListRequestId } from "src/kinzaza/recoils";
import { lighten } from "polished";
import {
  DeleteConfirmModalContents,
  DuplicateMonitorModalContents,
} from "../organisms";

const StyledTableRow = styled(TableRow)`
  /* @todo nth-of-type が効いてない kiitena */
  /* &:nth-of-type(odd) {
    background: ${(props) => lighten(0.05, props.theme.bgPrimaryColor)};
  } */
`;

const StyledCell = styled(TableCell)`
  border-bottom: 1px solid
    ${(props) => (props.theme.darkMode ? "#444" : "#eee")};
`;

const StyledRightCell = styled(StyledCell)`
  text-align: right;
`;

const Loading = styled.div`
  color: ${(props) => (props.theme.darkMode ? "#dedede" : "#2f2f2e")};
`;

const SyncButton = styled(ASIconButton)`
  margin-left: 8px;
`;

const Title = styled.h2`
  text-decoration: none;
  font-size: 14px;
  text-decoration: none;
  color: #ffffff;
  :hover {
    color: #afafaf;
    transition: all 0.2s 0s ease;
  }
`;

const DataCount = styled.span`
  font-size: 14px;
  color: #ffffff;
`;

const Error = styled.div`
  color: ${(props) => props.theme.negativeColor};
`;

type Props = {
  url: string;
  monitor: MonitorThumb;
};
export const KZMonitorWideCard: React.FC<Props> = (props) => {
  const monitor = useMonitorThumb(props.monitor);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = Boolean(anchorEl);
  const history = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const [ModalContents, setModalContents] = React.useState(<></>);
  const { handleDelete } = useDeleteMonitor();
  const [req, setReq] = useRecoilState(monitorListRequestId);

  const handleMenuOpen = (event: React.ChangeEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteModal = (monitor: MonitorThumb) => {
    setModalContents(
      <DeleteConfirmModalContents
        onSubmit={() => {
          handleDelete(monitor);
        }}
        onCancel={() => {
          setShowModal(false);
          handleMenuClose();
        }}
      />
    );
    setShowModal(true);
  };

  const handleDuplicateModal = (monitor: MonitorThumb) => {
    setModalContents(
      <DuplicateMonitorModalContents
        onSubmit={async () => {
          const resData = await api.duplicateMonitor(monitor);
          if (resData.status === "success") {
            setShowModal(false);
            setReq((reqId) => reqId + 1);
            handleMenuClose();
          }
        }}
        onCancel={() => {
          setShowModal(false);
          handleMenuClose();
        }}
      />
    );
    setShowModal(true);
  };

  return (
    <>
      <StyledTableRow>
        <StyledCell>
          <Title
            onClick={() => {
              history(`/monitor/${monitor.id}`);
            }}
          >
            {monitor.name}
          </Title>
          <Error>{monitor.errMsg}</Error>
        </StyledCell>

        <StyledCell>
          <div>
            <NZSchemaTypeChip>{monitor.schemaType}</NZSchemaTypeChip>
          </div>
        </StyledCell>

        <StyledCell>
          <DataCount>
            {monitor.isLoading ? (
              <Loading>please wait...</Loading>
            ) : (
              <span>{monitor.lastDataDate}</span>
            )}
          </DataCount>
        </StyledCell>

        <StyledRightCell>
          {monitor.isLoading ? (
            <Loading>please wait...</Loading>
          ) : (
            <>
              <DataCount>
                {isNaN(monitor.totalCount) ? "No Document" : monitor.totalCount}
              </DataCount>
              <SyncButton
                size="small"
                kind="secondary"
                onClick={() => {
                  monitor.syncCount();
                }}
              >
                <SyncIcon />
              </SyncButton>
            </>
          )}
        </StyledRightCell>

        {/* <StyledCell>
          {monitor.isLoading ? (
            <Loading>please wait...</Loading>
          ) : (
            <DataCount>{monitor.lastCountUpdateDate ?? "--"}</DataCount>
          )}
        </StyledCell> */}
        <StyledCell>
          <ASIconButton
            kind="secondary"
            onClick={handleMenuOpen}
            size={"small"}
          >
            <MoreHorizIcon />
          </ASIconButton>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={() => handleDuplicateModal(monitor)}>
              Duplicate
            </MenuItem>
            <MenuItem
              onClick={() => handleDeleteModal(monitor)}
              style={{ color: "red" }}
            >
              Delete
            </MenuItem>
          </Menu>
        </StyledCell>
      </StyledTableRow>
      <ASModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        {ModalContents}
      </ASModal>
    </>
  );
};
