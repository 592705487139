import { AxiosInstance } from "axios";

export const update = async (
  instance: AxiosInstance,
  props: { contractor: string; optXId: string; data: any }
) => {
  try {
    const res = await instance({
      method: "put",
      url: `/contractors/${props.contractor}/optx/${props.optXId}`,

      data: props.data,
    });
    return res;
  } catch (err) {
    console.error(err);
  }
};
