import React, { useState } from "react";
import { ASCard, ASTabs, ASPictureIcon } from "allegro-ui";
import styled from "styled-components";
import { CardContent, Tab } from "@material-ui/core";
import { OptX } from "src/xerver-room/models";
import { useFileXList } from "src/xerver-room/hooks";
import { XRUpdateOptXForm } from "./XRUpdateOptXForm";
import moment from "moment";
import { XRTextArea } from "../atoms/XRTextArea";
import Markdown from "react-markdown";
// import remarkGfm from "remark-gfm";

const ModalContent = styled.div`
  /* padding: 40px; */
  width: 100%;
  text-align: left;
  min-height: 80vh;
  > h1 {
    margin-top: 8px;
    font-size: 20px;
  }
  @media screen and (min-width: 1024px) {
    min-width: 700px;
  }
`;

const CompactModalcontent = styled.div`
  text-align: left;
  width: 100%;
  > h1 {
    margin-top: 8px;
    font-size: 20px;
  }
`;

const IconTitleBlock = styled.div`
  border-radius: ${(props) => props.theme.borderRadius}px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  > div {
    > h1 {
      font-size: 24px;
      font-weight: bold;
    }
    > p {
      font-size: 14px;
      line-height: 1.6;
      opacity: 0.8;
    }
    &:last-child {
      margin-left: 24px;
    }
  }
`;

const CompactIconTitleBlock = styled(IconTitleBlock)`
  background: none;
  align-items: center;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;

  > h1 {
    margin-left: 8px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    word-break: break-word;
  }
`;

const IconLayout = styled.div`
  float: right;
`;

const IconWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const IconShadowWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  opacity: 0.8;
  > * {
    height: 20px;
  }

  filter: blur(15px);
  /* backdrop-filter: blur(8px); */
`;

const Block = styled.div`
  margin-top: 24px;
  padding-top: 12px;
  border-top: 1px solid #333;
`;

const FileCard = styled(ASCard)`
  margin-top: 4px;
`;

const FileName = styled.h2`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 24px;
`;

const FileText = styled(XRTextArea)`
  font-size: 12px;
  opacity: 0.6;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

const BlockTitle = styled.h2`
  font-size: 14px;
  margin-bottom: 2px;
`;
const BuildLog = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > span {
    margin-right: 8px;
    line-height: 1.5;
    font-size: 13px;
  }

  border-bottom: 1px solid #444;
  padding: 8px 0;
  &:first-child {
    border: none;
  }
  &:last-child {
    border: none;
  }
`;

const Time = styled.span`
  opacity: 0.4;
  font-size: 12px;
`;

const NegativeLogText = styled.h1`
  font-weight: bold;
  color: ${(props) => props.theme.negativeColor};
`;

const PositiveLogText = styled.h1`
  font-weight: bold;
  color: ${(props) => props.theme.positiveColor};
`;

const TabContentContainer = styled.div`
  width: 100%;
`;

const OptXDescription = styled.div`
  min-height: 100px;
  /* max-height: 400px; */
  color: ${(props) => props.theme.textColor};
  overflow-y: scroll;
  margin-top: 24px;
  line-height: 1.5;
  /* opacity: 0.7; */
  font-size: 14px;

  overflow: scroll;
  white-space: pre-wrap;

  > h1 {
    font-size: 28px;
    font-weight: bold;
  }
  > h2 {
    font-size: 24px;
    font-weight: bold;
  }
  > h3 {
    font-size: 20px;
    font-weight: bold;
  }
  > h4 {
    font-size: 18px;
    font-weight: bold;
  }
  > p {
    font-size: 14px;
    line-height: 1.6;
    opacity: 0.8;
  }
`;

type Props = {
  optX: OptX;
  compact?: boolean;
};
export const XROptXDetails: React.FC<Props> = ({ optX, compact = false }) => {
  const { files } = useFileXList(optX);
  const [tabId, setTabId] = useState(0);

  const histories = optX.buildHistory;

  const handleTab = (e: any, newValue: number) => {
    setTabId(newValue);
  };

  const renderBuildLog = (history: any, toNow: boolean = false) => {
    return (
      <BuildLog>
        <span>
          {history.message === "ビルド成功" ? (
            <PositiveLogText>{history.message}</PositiveLogText>
          ) : (
            <NegativeLogText>{history.message}</NegativeLogText>
          )}
        </span>
        <Time>
          {toNow
            ? moment(history.time).toNow()
            : moment(history.time).format("DD/MM/YYYY, h:mm:ss a")}
        </Time>
        {compact ? <></> : <span>{history.ref}</span>}
      </BuildLog>
    );
  };

  const renderTabs = () => {
    switch (tabId) {
      case 0:
        return (
          <>
            <Block>
              <BlockTitle>Description</BlockTitle>
              <OptXDescription>
                <Markdown>{optX.description}</Markdown>
              </OptXDescription>
            </Block>
          </>
        );
      case 1:
        return (
          <Block>
            <BlockTitle>Build history</BlockTitle>
            <Block>
              <BlockTitle>ID</BlockTitle>
              {optX.optXId}
            </Block>
            <Block>
              <BlockTitle>File</BlockTitle>
              {files.length > 0 ? (
                files.map((file: any) => {
                  return (
                    <FileCard key={`filecard-${file.name}`}>
                      <CardContent>
                        <FileName>{file.name}</FileName>
                        <FileText>{file.text}</FileText>
                      </CardContent>
                    </FileCard>
                  );
                })
              ) : (
                <>File not found.</>
              )}
            </Block>
            {histories.length > 0 ? (
              histories.map((history: any) => {
                return renderBuildLog(history);
              })
            ) : (
              <>---</>
            )}
          </Block>
        );
      case 2:
        return <XRUpdateOptXForm optX={optX} />;
    }
  };

  return compact ? (
    <CompactModalcontent>
      <CompactIconTitleBlock>
        <h1>{optX.name}</h1>
        <IconLayout>
          <IconWrapper>
            <ASPictureIcon
              size={70}
              src={optX.thumbnail}
              defaultSrc={optX.thumbnail}
            />
          </IconWrapper>

          <IconShadowWrapper>
            <ASPictureIcon
              size={70}
              src={optX.thumbnail}
              defaultSrc={optX.thumbnail}
            />
          </IconShadowWrapper>
        </IconLayout>
      </CompactIconTitleBlock>
      {/* <Block>
        <BlockTitle>Last build</BlockTitle>
        {histories.length > 0 ? (
          <>{renderBuildLog(histories[0], true)}</>
        ) : (
          <>Build not found.</>
        )}
      </Block> */}
      <></>
    </CompactModalcontent>
  ) : (
    <ModalContent>
      <IconTitleBlock>
        <div>
          <ASPictureIcon
            size={80}
            src={optX.thumbnail}
            defaultSrc={optX.thumbnail}
          />
        </div>

        <div>
          <h1>{optX.name}</h1>
        </div>
      </IconTitleBlock>

      <ASTabs value={tabId} onChange={handleTab}>
        <Tab label="Info" />
        <Tab label="Build History" />
        <Tab label="Settings" />
      </ASTabs>
      <TabContentContainer>{renderTabs()}</TabContentContainer>
    </ModalContent>
  );
};
