import { useCallback, useState } from "react";
import { api, createnXtalAPI } from "src/api";
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "src/root/recoils/userState";
import { createPipeline } from "../models";
import { pipelineListRequestId } from "../recoils";

export const usePipelineList = () => {
  const auth = useRecoilValue(userState);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState();
  const [reqId, setReqId] = useRecoilState(pipelineListRequestId);

  const nxtal = createnXtalAPI();
  const contractor: string =
    typeof auth.contractorNo === "string" ? auth.contractorNo : "";
  const apiKey: string = typeof auth.token === "string" ? auth.token : "";

  const add = useCallback(
    async (name: string) => {
      const item = createPipeline({ name });
      try {
        await nxtal.pipelinex.add({
          contractorNo: auth.contractorNo ?? "",
          xervice: {
            name: item.name,
            readableGroups: item.readableGroups,
            writableGroups: item.writableGroups,
            readableUsers: item.readableUsers,
            writableUsers: item.writableUsers,
          },
        });

        //@todo update list
      } catch (err) {
        console.error(err);
        // setError(err);
      }
      setReqId(reqId + 1);
    },
    [apiKey, contractor, reqId, setReqId]
  );

  // const deletePipeline = useCallback(
  //   async (id: string) => {
  //     try {
  //       const res = await api({
  //         method: "DELETE",
  //         url: `/contractors/${contractor}/pipelinex/${id}`,
  //         params: {
  //           apikey: apiKey,
  //         },
  //       });
  //     } catch (err) {
  //       console.error(err);
  //       // setError(err);
  //     }
  //     setReqId(reqId + 1);
  //   },
  //   [apiKey, contractor, reqId, setReqId]
  // );

  return {
    add,
    // deletePipeline,
    error,
  };
};
