import { produce } from "immer";

export type User = {
  //
  token: string | null;
  pass: string;
  //
  username: string;
  id: string | null;
  birth: string;
  age: string;
  sex: string;
  account: string;
  userNoText: string | null;
  groups: string[];
  password: string;
  email: string;
  nickName: string;
  userNo: string | number;
  roles: {
    name: string;
    id: string;
    permissions: string[];
    roleDisplayNameEN: string;
    roleId: string;
    roleLevel: number;
    roleGroup: string;
  }[];

  contractorNo: string | null;
  authorities: { authorifty: string }[];

  profile: {
    userRealName: string | null;
    apiKey: string;
  };

  zip: string;
  address: string;
  avatar: string;
  phoneNumber: string;
  enable: boolean;
};

export const defaultUser: User = {
  username: "---",
  id: null,
  birth: "---",
  age: "0",
  sex: "men",
  pass: "",
  account: "---",
  userNoText: null,
  userNo: "---",
  groups: [],
  password: "",
  token: null,
  email: "---",
  nickName: "---",
  contractorNo: null,
  authorities: [],
  roles: [],
  profile: {
    userRealName: null,
    apiKey: "",
  },
  zip: "--",
  address: "--",
  phoneNumber: "---",
  avatar: "",
  enable: true,
};

export const createUser = (props: Partial<User>): User => {
  return produce(defaultUser, (draft) => {
    draft.username = props.username ?? draft.username;
    draft.profile = props.profile ?? draft.profile;
    draft.token = props.profile?.apiKey ?? draft.token;
    draft.pass = props.password ?? draft.pass;
    draft.userNoText = props.userNoText ?? draft.userNoText;
    draft.userNo = props.userNo ?? draft.userNo;
    draft.contractorNo = props.contractorNo ?? draft.contractorNo;
    draft.nickName = props.profile?.userRealName
      ? props.profile.userRealName
      : props.username
      ? props.username
      : "不明";
    draft.email = props.email ?? props.username ?? draft.email;
    draft.id = props.id ?? draft.id;
    draft.address = props.address ?? draft.address;
    draft.zip = props.zip ?? draft.zip;
    draft.avatar = props.avatar ?? draft.avatar;
    draft.phoneNumber = props.phoneNumber ?? draft.phoneNumber;
    draft.enable = props.enable ?? true;
    draft.roles = props.roles ?? [];
  });
};

const roleToText = (resData: any) => {
  if (resData.authorities && resData.authorities.length > 0) {
    switch (resData.authorities[0].authority) {
      case "ROLE_ADMIN":
        return "Admin";
      case "ROLE_CHIEF":
        return "Owner";
      case "ROLE_USER":
        return "Member";
      default:
        return "Member";
    }
  } else {
    console.error(resData);
    return "ERROR";
  }
};
