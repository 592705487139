//Libs
import React, { useEffect, useState } from "react";
import styled from "styled-components";

//Components
import { FormControl } from "@material-ui/core";
import { ASInputBase, ASButton } from "allegro-ui";
//States
//Types

import { useSlotActions } from "src/xerver-room/hooks";
import { ASFormLabel } from "allegro-low-code-components";

const Form = styled.div`
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 100%;
  width: 300px;
  display: flex;
  flex-direction: column;
  text-align: left;
  > * {
    margin-top: 24px;
  }
`;

type Props = {
  slotId: string;
  disabled?: boolean;
};

const FormLabel = styled.span`
  display: inline-block;
  margin-bottom: 8px;
`;

export const XRVolumeSettingForm: React.FC<Props> = ({ slotId, disabled }) => {
  const slot = useSlotActions(slotId);
  const { saveVolume } = slot;

  const [formData, setFormData] = useState<string | null>(null);

  useEffect(() => {
    setFormData(slot.volume ?? "");
  }, [slot.volume]);

  return (
    <Form>
      <FormControl key={`volume-set-form`}>
        <ASFormLabel>Volume</ASFormLabel>
        <ASInputBase
          id="id"
          kind="digicho"
          placeholder="/var/allegro/db/mongo-a:/data/db"
          value={formData}
          onChange={(e) => {
            setFormData(e?.target.value);
          }}
          disabled={disabled}
        />
      </FormControl>

      <ASButton
        kind={"secondary"}
        disabled={!formData || disabled}
        onClick={() => {
          if (formData) saveVolume(formData);
        }}
      >
        SAVE
      </ASButton>
    </Form>
  );
};
