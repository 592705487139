import { medicanAPIConfig } from "./config";

// 回答取得API
export const getAnswers = async (
  formId: string,
  targetIds?: string[],
  answerIds?: string[],
  limit?: number,
  skip?: number,
  hasUser?: boolean
) => {
  const url = `/answer`;
  const params = {
    apikey: sessionStorage.token,
    userNoText: sessionStorage.userNoText,
    formId,
    targetIds,
    answerIds,
    limit,
    skip,
    hasUser,
  };

  const res = await medicanAPIConfig.get(url, { params: params });
  return res.data.message;
};

// 回答保存API
export const saveAnswer = async (data: any) => {
  const url = `/answer`;
  const params = {
    apikey: sessionStorage.token,
    userNoText: sessionStorage.userNoText,
  };

  const res = await medicanAPIConfig.put(url, data, { params: params });
  return res;
};

export const getUserAnswer = async (
  targetId: string,
  categories?: string[]
) => {
  const url = `/answer/${targetId}`;
  const params = {
    apikey: sessionStorage.token,
    userNoText: sessionStorage.userNoText,
    categories: categories,
  };

  const res = await medicanAPIConfig.get(url, { params: params });
  return res.data.message ?? [];
};

export const getCategories = async (
  categoryName?: string
): Promise<string[]> => {
  const url = `/category`;
  const params = {
    apikey: sessionStorage.token,
    userNoText: sessionStorage.userNoText,
    categoryName,
  };

  const res = await medicanAPIConfig.get(url, { params: params });
  return res.data.message;
};

export const deleteAnswer = async (
  answerId: string,
  projectId: string,
  contractorNo: string
): Promise<boolean> => {
  const url = `/answer/${answerId}`;
  try {
    const deleteRes = await medicanAPIConfig.delete(url, {
      params: { contractorNo, projectId },
    });
    return deleteRes.status >= 200 && deleteRes.status < 300;
  } catch (err) {
    console.error(err);
    return false;
  }
};
