import { atom, selector } from "recoil";
import { MonitorThumb, createMonitorThumb } from "../models";
import * as api from "../api";

export const monitorListRequestId = atom({
  key: "monitorListRequestId",
  default: 0,
});

export const searchContainerState = atom({
  key: "searchContainerState",
  default: true,
});

export const createModalState = atom({
  key: "createModal",
  default: false,
});

export const deleteModalState = atom({
  key: "deleteModal",
  default: false,
});

export const deleteMonitorDataState = atom<MonitorThumb | undefined>({
  key: "deleteModalData",
  default: undefined,
});

export const duplicateModalState = atom({
  key: "duplicateModal",
  default: false,
});

export const duplicateMonitorDataState = atom<MonitorThumb | undefined>({
  key: "duplicateModalData",
  default: undefined,
});

export const monitorSearchWordState = atom({
  key: "monitorSearchWordState",
  default: "",
});

export const pageId = atom({
  key: "monitorPageIdState",
  default: 1,
});

//　画面サイズによって表示カード数を変える処理(pagination)

export const windowSizePagination = atom({
  key: "windowSizePagination",
  default: 20,
});

export const monitorThumbListState = selector<Array<MonitorThumb>>({
  key: "MonitorThumbList",
  get: async ({ get }) => {
    const page = get(pageId);
    const windowSize = get(windowSizePagination);
    const searchWord = get(monitorSearchWordState);
    const Id = get(monitorListRequestId);
    const limit = windowSize;
    let data = [];
    let dataCount = 0;
    try {
      if (searchWord.length === 0) {
        const [getD, count] = await api.getMonitors(page, limit, {
          "contents.name": -1,
        });

        dataCount = count;
        data = getD;
      } else {
        const [getSD, count] = await api.getMonitors(page, limit, undefined, {
          "contents.rawdata.name": { $regex: searchWord },
        });
        dataCount = count;
        data = getSD;
      }

      const nextList = data.map((data: any) => {
        return createMonitorThumb(
          { ...data.contents.rawdata, id: data.id },
          data._modified ?? data._created,
          data.length,
          dataCount
        );
      });
      return nextList;
    } catch (err) {
      console.log(err);
      return [];
    }
  },
});
