import { ReactNode, useState } from "react";

import { ASButton, ASModal, ASFileDnD } from "allegro-ui";
import styled from "styled-components";
import { UploadLogStatus } from "src/newtroid/models";
import { lighten } from "polished";
import { MDNoDataPlaceHolder } from "src/medican/components/atoms";
import { LogItem } from "src/opt-editor/components/atoms/LogItem";

export type ImportButtonProps = {
  children?: ReactNode;
  title?: string;
  validate?: (file: File) => boolean;
  onSubmit: (files: File[]) => void;
  logs?: UploadLogStatus[];
  onClose?: () => void;
  disabled?: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  height: 350px;
  padding: 20px 40px;
`;

const DropArea = styled(ASFileDnD)`
  width: 100%;
  height: 65%;
  color: ${(props) => props.theme.textColor};
  font-size: 12px;
  border-radius: ${(props) => props.theme.borderRadius}px;
  border: 1.5px dashed;
  border-color: ${(props) => props.theme.textColor};
`;

const StatusContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  height: 25%;
`;

const StatusBody = styled.div`
  padding: 16px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  font-size: 12px;
  color: ${(props) => props.theme.textColor};
  background: ${(props) => lighten(0.05, props.theme.bgPrimaryColor)};
  border-radius: 8px;
  > * {
    margin-bottom: 8px;
  }
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
`;

const NoLogContainer = styled(MDNoDataPlaceHolder)`
  width: 100%;
  height: 100%;
  font-size: 12px;
`;

export const ImportButton = (props: ImportButtonProps) => {
  const { logs } = props;
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleDrop = async (uploaded: File[]) => {
    await props.onSubmit(
      uploaded.filter((file) => {
        const valid = props.validate ? props.validate(file) : true;

        return valid;
      })
    );
  };

  return (
    <>
      <ASButton kind="primary" onClick={handleClick} disabled={props.disabled}>
        {props.children}
      </ASButton>
      <ASModal
        open={show}
        // title={"Upload File"}
        title={props.title}
        onClose={handleClose}
        onAbort={handleClose}
      >
        <Container>
          {/* <ModalTitle>{props.title ? props.title : "Upload File"}</ModalTitle> */}
          <DropArea
            mainText="ここにファイルをドラッグ&ドロップしてください"
            hoverText="ここにファイルをドロップしてください"
            onDrop={(item: { files: File[] }) => handleDrop(item.files)}
          />
          <StatusContainer>
            <StatusBody>
              {logs?.map((log, id) => {
                return (
                  <LogItem
                    key={`log-item-${id}`}
                    status={log.status}
                    name={log.name}
                    message={
                      log.message ?? (log.status === 200 ? "完了" : "失敗")
                    }
                    positive={log.status === 200}
                  />
                );
              })}
              {logs?.length === 0 ? (
                <NoLogContainer>
                  ファイルがアップロードされるとこちらにログが表示されます
                </NoLogContainer>
              ) : (
                <></>
              )}
            </StatusBody>
          </StatusContainer>
        </Container>
      </ASModal>
    </>
  );
};
