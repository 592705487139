import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { FormControl, CardContent } from "@material-ui/core";
import { ASInputBase, ASButton, ASCard } from "allegro-ui";
import { userState } from "src/root/recoils/userState";
import { useRecoilState } from "recoil";
import bg from "src/fortress/assets/img/black-polygonal.jpg";
import { PTGlobalNav } from "src/portal/components/organisms/PTGlobalNav";
import { headerOffset } from "src/portal/components/organisms/PTGlobalNav";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { PTRegisterProfileForm } from "../organisms/PTRegisterProfileForm";
import { PTSignUpPageTemplate } from "src/portal/components/templates/PTSignUpPageTemplate";

const APP = process.env.REACT_APP_APP;

type Props = {};

type FormInputs = {
  //1st
  contractor: string;
  email: string;
  pass: string;
  //2nd
  nickName: string;
  firstName: string;
  lastName: string;
  birth: string;
  //3rd
  company: string | undefined;
  country: string;
  zip: string;
  address1: string;
  address2: string;
};

export const PTProvisionalRegisteredPage: React.FC<Props> = ({}) => {
  // const [email, setEmail] = useState(sessionStorage.getItem("userId") || "");
  // const [password, setPassword] = useState("");

  const [confirmed, setConfirmed] = useState(false);
  const history = useNavigate();
  const theme = useTheme();
  const color = theme.darkMode ? "white" : "black";

  const handleSubmit = () => {};

  return (
    <>
      <PTSignUpPageTemplate
        formTitle={"仮登録完了"}
        formContent={<p>仮登録が完了しました。メールをご確認ください。</p>}
      ></PTSignUpPageTemplate>
    </>
  );
};
