import { AxiosInstance, AxiosResponse } from "axios";
import { createAPIConfig } from "../config/http";
import { NewtQuery } from "../models/newtQuery";
/**
 * 児童を検索する
 * @param {string} word 検索ワード
 * @return {object} レスポンスデータ
 */
export const dataProvider = async (
  instance: AxiosInstance,
  newtQuery: NewtQuery,
  options?: any
): Promise<any[]> => {
  try {
    const res = await instance.post(`/newtout/aggr`, newtQuery, {
      params: {
        newtQuery: false,
        count: options?.count,
        filterByContractor: options?.filterByContractor,
      },
    });

    if (res.status === 200 && Array.isArray(res.data.message?.doc)) {
      return res.data.message.doc ?? [];
    } else {
      return [];
    }
  } catch (err) {
    console.error(err);
    return [];
  }
};

type Args = {
  newtQuery: any;
  limit?: number;
  maxCount?: number;
  batchSize?: number;
  retryCount?: number;
  callback?: (data: any[], err?: any) => void;
};

export const partitionedDataProvider = async (
  instance: AxiosInstance,
  props: Args
) => {
  let count = 0;
  const { limit, newtQuery, callback } = props;

  const batchSize = props.batchSize ?? 16;
  const maxCount = props.maxCount ?? 10000;
  const retryCount = props.retryCount ?? 5;
  const totalPages = limit ? Math.ceil(limit / batchSize) : Infinity;

  while (count < totalPages || count < maxCount) {
    const newtQ = {
      ...newtQuery,
      limit: batchSize,
      skip: count * batchSize,
      primaryKey: newtQuery.primaryKey ?? "id",
    };

    let errCount = 0;
    let data = [];
    while (errCount < retryCount) {
      try {
        data = await dataProvider(instance, newtQ);
        if (data !== undefined) {
          if (callback) {
            callback(data);
          }
          break;
        }
      } catch (err) {
        console.error(err);

        errCount += 1;
      }
    }
  }
};
