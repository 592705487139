import React from "react";
import styled from "styled-components";

import { ASButton, ASCard, ASModal, ASPictureIcon } from "allegro-ui";
import { CardActionArea, CardActions, CardContent } from "@material-ui/core";
import { OptX } from "src/xerver-room/models";
import { useState, useCallback } from "react";
import { darken } from "polished";

const Container = styled.div`
  /* width: 300px; */
  padding: 20px;
`;
const Title = styled.div`
  font-size: 24px;
`;

const Desc = styled.div`
  margin-top: 24px;
`;

type Props = {
  title: string;
  discription: string;
  imgSrc: string;
  onClick?: () => void;
  link: string;
};

type Attrs = {
  borderColor?: string | null;
};

const IconPlaceHolder = styled.div`
  width: 80px;
  height: 80px;
  /* background: ${(props) => props.theme.bgPrimaryColor}; */
`;

const ModalWrapper = styled.div`
  max-height: 80vh;
  overflow: scroll;
`;

const OptXName = styled.h1`
  margin-top: 32px;
  font-weight: bold;
  font-size: 20px;
`;

const OptXId = styled.h6`
  margin-top: 16px;
  opacity: 0.3;
  font-size: 12px;
  /* text-align: right; */
`;

const Creator = styled.h3`
  margin-top: 14px;
  font-size: 14px;
  color: ${(props) => props.theme.primaryColor};
`;

const MenuArea = styled.div`
  display: flex;
  padding: 12px;
`;
const TextArea = styled.p`
  margin-top: 24px;
  line-height: 1.5;
  opacity: 0.7;
  font-size: 14px;
  min-height: 60px;
`;

const AvailableBudge = styled.div`
  display: inline-block;
  padding: 4px 10px;
  font-size: 12px;
  border-radius: 99px;
  margin-left: 8px;
  border: 1px solid
    ${(props) =>
      props.theme.darkMode
        ? props.theme.positiveColor
        : darken(0.4, props.theme.positiveColor)};
  background: ${(props) =>
    props.theme.darkMode
      ? darken(0.4, props.theme.positiveColor)
      : props.theme.positiveColor};
  color: ${(props) =>
    props.theme.darkMode
      ? props.theme.positiveColor
      : props.theme.positiveColor};
`;

const NonAvailableBudge = styled.div`
  display: inline-block;
  margin-left: 8px;
  padding: 4px 10px;
  font-size: 12px;
  border-radius: 99px;
  border: 1px solid
    ${(props) =>
      props.theme.darkMode
        ? props.theme.negativeColor
        : darken(0.6, props.theme.negativeColor)};
  background: ${(props) =>
    props.theme.darkMode
      ? darken(0.6, props.theme.negativeColor)
      : props.theme.negativeColor};
  color: ${(props) =>
    props.theme.darkMode
      ? props.theme.negativeColor
      : props.theme.negativeColor};
`;

const OptXCard = styled(ASCard)`
  margin: 15px;
  min-width: 300px;
  width: 360px;
  /* https://www.joshwcomeau.com/css/designing-shadows/ */
  box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
`;

export const PTAppListItem: React.FC<Props> = ({
  title,
  imgSrc,
  discription,
  link,
}) => {
  return (
    <>
      <OptXCard>
        <CardActionArea
          onClick={() => {
            console.log("on click");
            window.open(link);
          }}
        >
          <CardContent>
            <Container>
              <IconPlaceHolder>
                <ASPictureIcon src={imgSrc} defaultSrc={imgSrc} size={80} />
              </IconPlaceHolder>
              <OptXName>{title}</OptXName>

              <Creator>AllegroSmart, Inc.</Creator>
              {/* <h3> {optX.parentSlotId}</h3> */}
              <TextArea>{discription}</TextArea>
              {/* <OptXId>{optX.optXId}</OptXId> */}
            </Container>
          </CardContent>
        </CardActionArea>
        {/* <CardActions>
          <MenuArea>
            <ASButton
              kind="secondary"
              onClick={() => {
                // handleClose(true);
              }}
            >
              Open
            </ASButton>
          </MenuArea>
        </CardActions> */}
      </OptXCard>
    </>
  );
};
