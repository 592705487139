import React from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";

import { Input } from "src/xerver-room/models";

import { useSlotList } from "src/xerver-room/hooks";
import { useSlotTableRow } from "src/xerver-room/hooks/useSlotTableRow";

import { ASIconButton } from "allegro-ui";
import { TableRow, TableCell } from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";

import { XRMenuSlotButton } from "../atoms/XRMenuSlotButton";
import { XRStartSlotButton, XRStopSlotButton } from "../atoms";
import { XRViewSlotLogButton } from "../organisms/XRViewSlotLogButton";

const ButtonGroup = styled.div`
  width: 100%;
  /* min-height: 380px; */
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin-left: 8px;
  }

  > span {
    width: 35px;
  }

  /* justify-content: space-between; */
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.primaryColor};
  text-decoration: none;
`;

const StyledTableRow = styled(TableRow)`
  background-color: inherit;
`;

const Cell = styled(TableCell)`
  /* background: ${(props) => props.theme.bgPrimaryColor}; */
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
  padding: 6px 6px;
  border-bottom: 1px solid #444;
  min-width: 100px;
  background-color: inherit;
`;

const ServerName = styled.div`
  color: ${(props) => props.theme.descriptionTextColor};
  font-size: ${(props) => props.theme.supportFontSizeMd}px;
`;

const IP = styled.div`
  font-size: ${(props) => props.theme.labelFontSizeMd}px;
`;

const Stoped = styled.div`
  color: ${(props) => props.theme.negativeColor};
`;

const Running = styled.div`
  color: ${(props) => props.theme.positiveColor};
`;

const RunningComponent: React.FC<{ running?: boolean }> = (props) => {
  return (
    <ButtonGroup>
      <span>
        {props.running ? <Running>run</Running> : <Stoped>stop</Stoped>}
      </span>
    </ButtonGroup>
  );
};

type ServerCellProps = {
  serverName: string;
  ip: string;
};

const ServerComponent: React.FC<ServerCellProps> = (props) => {
  return (
    <>
      <IP>{props.ip ?? "---"}</IP>
      <ServerName>{props.serverName ?? "---"}</ServerName>
    </>
  );
};

const SlotActionButtonGroup: React.FC<{ slotId: string; disabled?: boolean }> =
  (props) => {
    const { slotId, disabled } = props;
    const { reloadList } = useSlotList();

    return (
      <ButtonGroup>
        <ASIconButton
          disabled={disabled}
          kind="secondary"
          size="small"
          onClick={reloadList}
        >
          <SyncIcon />
        </ASIconButton>
        <XRStartSlotButton disabled={disabled} slotId={slotId} />
        <XRStopSlotButton disabled={disabled} slotId={slotId} />
        <XRViewSlotLogButton disabled={disabled} slotId={slotId} />
        <XRMenuSlotButton disabled={disabled} slotId={slotId} />
      </ButtonGroup>
    );
  };

const InputGroup: React.FC<{ slotInputs: Input[] }> = (props) => {
  return (
    <>
      {props.slotInputs.map((input, id) => {
        return (
          <div key={`asc-input-group-${input.name}-${id}`}>
            {input.name}: {input.portOutside ?? ""}
          </div>
        );
      })}
    </>
  );
};

type Props = {
  slotId: string;
  serialNum?: number;
  showPipelineId?: boolean;
  showStats?: boolean;
};

const HYPHENS = "---";

export const XRSlotTableRow: React.FC<Props> = (props) => {
  const { slotId, serialNum, showPipelineId, showStats } = props;

  const {
    slot,
    optx,
    pipeline,
    server,
    cpuPercentage,
    memoryUsage,
    diskUsage,
    createdTime,
    canEditPipeline,
    setCursorSlotId,
  } = useSlotTableRow(slotId);

  return (
    <>
      <StyledTableRow>
        {serialNum ? <Cell>{serialNum}</Cell> : null}
        <Cell>
          <StyledLink
            to={`/pipeline/${slot.pipelineId}`}
            onClick={() => setCursorSlotId(slot.slotId)}
          >
            {slot.name ? slot.name : HYPHENS}
          </StyledLink>
        </Cell>

        {showPipelineId ? (
          <Cell>{pipeline.name ? pipeline.name : "---"}</Cell>
        ) : null}
        <Cell>
          <ServerComponent
            ip={slot.serverId ?? HYPHENS}
            serverName={
              server?.contents.rawdata.non_numeric_server_name ?? HYPHENS
            }
          />
        </Cell>
        <Cell>{optx.name ? optx.name : HYPHENS}</Cell>

        {/* Status */}
        <Cell>
          <RunningComponent running={slot.status.desire === "run"} />
        </Cell>

        <Cell>
          <RunningComponent running={slot.status.current === "run"} />
        </Cell>

        {showStats ? <Cell>{cpuPercentage}</Cell> : null}

        {showStats ? <Cell>{memoryUsage}</Cell> : null}

        {showStats ? <Cell>{diskUsage}</Cell> : null}

        {/* IP, Port */}
        <Cell>
          <InputGroup slotInputs={slot.inputs} />
        </Cell>

        {showStats ? <Cell>{createdTime}</Cell> : null}

        <Cell>
          <SlotActionButtonGroup slotId={slotId} disabled={!canEditPipeline} />
        </Cell>
      </StyledTableRow>
    </>
  );
};
