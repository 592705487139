import React from "react";
import styled from "styled-components";
import { ASIconButton } from "../atoms";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AlarmIcon from "@material-ui/icons/Alarm";
import Pagination from "@material-ui/lab/Pagination";
import { darken } from "polished";

type Props = {
  currentIndex: number;
  totalPageCount: number;
  onclick: (index: number) => void;
};

export const ASPageNav = styled(Pagination)`
  .MuiPaginationItem-root {
    font-size: ${(props) => props.theme?.regularFontSizeMd}px;
    color: ${(props) => (props.theme.darkMode ? "#ffffff" : "#000")};
  }
  .MuiPaginationItem-page {
    background-color: ${(props) => props.theme?.secondaryColor};
    &:hover {
      background-color: ${darken(0.1, "white")};
    }
  }
  .Mui-selected {
    color: white;
    background-color: ${(props) => props.theme?.primaryColor};
    &:hover {
      background-color: ${(props) => darken(0.1, props.theme?.primaryColor)};
    }
  }
`;
