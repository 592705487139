import React from "react";
import { slotListState } from "src/xerver-room/recoils";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { XRSlotTableRow } from "../molecules/XRSlotTableRow";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";

const Container = styled.div`
  overflow-y: scroll;
  background-color: ${(props) => props.theme.bgPrimaryColor};
`;

const Cell = styled(TableCell)`
  color: ${(props) => (props.theme.darkMode ? "#eee" : "#000")};
  border-bottom: 1px solid #333;
  font-weight: bold;
  padding: 6px 6px;
  background-color: inherit;
`;

const StyledTable = styled(Table)`
  border-spacing: 0px 0px;
  border-collapse: initial;
  width: 100%;
  table-layout: auto;
  tr > th:first-child,
  tr > td:first-child {
    position: sticky;
    left: 0;
  }

  tr > th:last-child,
  tr > td:last-child {
    position: sticky;
    /* left: 0; */
    right: 0;
  }

  background-color: inherit;

  > thead {
    background-color: inherit;
  }
`;

const StyledTableHead = styled(TableHead)`
  background-color: inherit;
`;

const StyledTableRow = styled(TableRow)`
  background-color: inherit;
`;

const StyledTableBody = styled(TableBody)`
  background-color: inherit;
`;

type Props = {
  showSerialNum?: boolean;
  showPipelineId?: boolean;
  showStats?: boolean;
};

export const XRXerviceBrowser: React.FC<Props> = (props) => {
  const { showSerialNum, showPipelineId, showStats } = props;
  const slots = useRecoilValue(slotListState);

  return (
    <Container>
      <StyledTable>
        <StyledTableHead>
          <StyledTableRow>
            {showSerialNum ? (
              <Cell style={{ minWidth: "50px", width: "50px" }}>No.</Cell>
            ) : null}
            <Cell style={{ minWidth: "250px" }}>Name</Cell>
            {showPipelineId ? (
              <Cell style={{ minWidth: "230px" }}>Xervice</Cell>
            ) : null}
            <Cell style={{ minWidth: "180px" }}>Server / IP</Cell>
            <Cell style={{ minWidth: "250px" }}>OptX</Cell>

            <Cell>desire</Cell>
            <Cell>current</Cell>

            {showStats ? <Cell>CPU</Cell> : null}
            {showStats ? <Cell>MEM</Cell> : null}
            {showStats ? <Cell>DISK I/O</Cell> : null}

            <Cell style={{ minWidth: "150px" }}>Ports</Cell>

            {showStats ? (
              <Cell style={{ minWidth: "150px" }}>Last updated</Cell>
            ) : null}

            <Cell>Actions</Cell>
          </StyledTableRow>
        </StyledTableHead>

        <StyledTableBody>
          {slots.map((slot, id) => {
            const serialNum = showSerialNum ? id + 1 : undefined;
            return (
              <XRSlotTableRow
                key={`slot-table-row-${slot.id}`}
                slotId={slot.slotId}
                serialNum={serialNum}
                showPipelineId={showPipelineId}
                showStats={showStats}
              />
            );
          })}
        </StyledTableBody>
      </StyledTable>
    </Container>
  );
};
