import { Group } from "allegro-api";
import { selectorFamily } from "recoil";
import { createnAuthAPI } from "src/api";

export const groupSingleState = selectorFamily<Group | null, string>({
  key: "groupSingleState",
  get:
    (groupId) =>
    async ({ get }) => {
      const nauth = createnAuthAPI();

      const group = await nauth.group.findOne(groupId);
      return group;
    },
});
