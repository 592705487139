import { saveAs } from "file-saver";
import * as yaml from "yaml";

export type DownloadFileType = "csv" | "json" | "yaml";

export const downloadFile = (file: File) => {
  saveAs(file);
};

export const downloadFileAs = (
  data: any,
  fileName: string,
  type: DownloadFileType
) => {
  if (type === "csv") {
    throw new Error("this type is not supported yet.");
  }

  if (type === "yaml") {
    try {
      // JSONをYAMLに変換
      const yamlData = yaml.stringify(data);

      // Blobを作成してファイルを保存
      const blob = new Blob([yamlData], { type: "application/yaml" });
      saveAs(blob, `${fileName}.yaml`);
    } catch (error) {
      console.error("Error converting and saving file:", error);
    }
  }

  if (type === "json") {
    try {
      const jsonBlob = new Blob([JSON.stringify(data, null, 2)], {
        type: "application/json",
      });

      saveAs(jsonBlob, `${fileName}.json`);
    } catch (error) {
      console.error("Error converting and saving file:", error);
    }
  }
};