import { FormDefinition, createFormDefinition } from "src/medican/models/form";
import { dFurusatoAPIConfig } from "../config";

export const fetchForms = async (formId: string): Promise<FormDefinition[]> => {
  const url = `/app/forms`;
  const params = {
    userNoText: sessionStorage.userNoText,
    formId: formId,
  };
  const res = await dFurusatoAPIConfig.get(url, { params: params });
  return Array.isArray(res)
    ? res.map((data) => createFormDefinition(data))
    : [];
};

export const fetchFormOne = async (formId: string) => {
  const url = `/app/forms/${formId}`;
  const res = await dFurusatoAPIConfig.get(url);
  return res.data.message;
};
