import { ASBrowserUiConf } from "../types/LowCodeUiDefinition";

export const createAutoBrowserUi = (columns: string[]): ASBrowserUiConf => {
  const uiConf = {
    tables: columns.map((col) => {
      return {
        label: col,
        component: "LCSimpleColComponent",
        data: {
          value: col,
        },
        options: {
          dateFormat: "wareki",
          editable: false,
          arrayLimitSize: 3,
          fontSize: 10,
        },
      };
    }),
  };
  return uiConf;
};
