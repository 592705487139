import { api } from "src/api";
import {
  createPersonalArrayDataFromJSON,
  initialPersonalData,
} from "src/fortress/models";
import { PersonalData, createPersonalDataFromJSON } from "src/fortress/models";
import { getConfig } from "../config";
import {
  personalThumbDataQueries,
  queryWithSeachingChildId,
} from "../config/fuchucity/personalThumbDataQueries";

/**
 * 指定したカテゴリの個人情報のサムネイルを取得する。
 * @param atena 宛名番号、個人番号
 * @param categoryName タブのカテゴリ名
 * @returns
 */
export const personalThumbData = async (
  atena: string | number,
  categoryName: string
): Promise<PersonalData[]> => {
  const config = getConfig(sessionStorage.contractorNo);
  const category = personalThumbDataQueries.categories.find((c) => {
    return c.name === categoryName;
  });

  if (!category) {
    console.error("マッチするカテゴリが見つかりません。");
  }
  console.warn("lgwan では 数字にキャストする");
  const query = queryWithSeachingChildId(category?.query, Number(atena));

  const res = await api.get(`/newtout`, {
    params: {
      newtQuery: query,
    },
  });

  const data = res.data.message[0];

  return data;
};
