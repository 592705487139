import { useCallback, useState } from "react";
import { User } from "src/root/models/user";
import { api } from "src/api";
import { useRecoilValue } from "recoil";
import { authState } from "src/root/recoils/userState";

type ProfileUpdatable = {
  name: string;
  email: string;
};

export const useRoleActions = () => {
  const auth = useRecoilValue(authState);
  const updateRole = useCallback(
    async (userNumber: number, newRoleName: string) => {
      //@todo
      const res = await api({
        method: "put",
        url: `/nauth/users/${userNumber}/role`,
        data: {
          roleId: newRoleName,
        },
      });
      return res.status === 200;
    },
    []
  );

  return { updateRole };
};
