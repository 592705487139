import { useEffect, useState } from "react";
import { useSetRecoilState, useRecoilState } from "recoil";
import {
  slotBrowserFilterState,
  slotSearchFieldTextState,
  slotListBrowserPageState,
  slotSearchFilterState,
} from "../recoils";
import { SlotFilterType } from "../types/slotFilterType";

export const useXerviceBrowser = (props: {
  slotFilterType: SlotFilterType;
  defaultServerIp?: string;
  showRunning?: boolean;
}) => {
  const [showRunning, setShowRunning] = useState(props.showRunning);

  const setSlotBrowserFilterState = useSetRecoilState(slotBrowserFilterState);
  const setWord = useSetRecoilState(slotSearchFieldTextState);
  const setSlotSearchFilterState = useSetRecoilState(slotSearchFilterState);

  const [page, setPage] = useRecoilState(slotListBrowserPageState);

  useEffect(() => {
    if (props.slotFilterType) {
      setSlotBrowserFilterState(props.slotFilterType);
    }
  }, [props.slotFilterType, setSlotBrowserFilterState]);

  useEffect(() => {
    if (props.defaultServerIp) {
      setWord(props.defaultServerIp);
    }

    return () => {
      setWord("");
    };
  }, [props.defaultServerIp, setWord]);

  // サーバーの検索ワードを変更する関数
  const handleSearchWord = (newText: string) => {
    setWord(newText);
  };

  // サーバーのページを変更する関数
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  // サーバーの起動中のコンテナのみをフィルタリングするかをトグルする関数
  const handleSwitchRunning = () => {
    setShowRunning(!showRunning);
    if (!showRunning) {
      setSlotSearchFilterState({ status: { desire: "run", current: "run" } });
    } else {
      setSlotSearchFilterState({});
    }
  };

  return {
    page,
    showRunning,
    handleSearchWord,
    handleChangePage,
    handleSwitchRunning,
  };
};
