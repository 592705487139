import React from "react";
import { ASCard, ASIconButton, ASModal, ASPictureIcon } from "allegro-ui";
import { CardActionArea, CardActions, CardContent } from "@material-ui/core";
import styled from "styled-components";
import { OptX } from "src/xerver-room/models";
import { useState, useCallback } from "react";
import { darken } from "polished";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { XROptXDetails } from "./XROptXDetails";
import { XRTextArea } from "../atoms/XRTextArea";

type Attrs = {
  borderColor?: string | null;
};

const OptXCard = styled(ASCard).attrs<Props>(
  (props): Attrs => ({
    borderColor: props.selected
      ? props.theme.primaryColor
      : props.theme.cardColor,
  })
)<{ selected: boolean | undefined } & Attrs>`
  margin: 8px;
  min-width: 300px;
  width: 360px;
  /* https://www.joshwcomeau.com/css/designing-shadows/ */
  box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
  border: ${(props) => `2px solid ${props.borderColor}`};
  /* background: none; */
`;

const HeaderContents = styled.div`
  width: calc(100% - 75px);
`;

const IconPlaceHolder = styled.div`
  position: absolute;
  right: 0;
  top: 24px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 24px;
  background: ${(props) => props.theme.bgPrimaryColor};
`;

const ModalWrapper = styled.div`
  height: 90vh;
  width: 90vw;
  overflow-y: scroll;
  padding: 25px;
`;

const OptXName = styled.h1`
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
  overflow-wrap: break-word;
`;

const OptXId = styled.h6`
  margin-top: 16px;
  opacity: 0.3;
  font-size: 12px;
  /* text-align: right; */
`;

const Creator = styled.h3`
  margin-top: 12px;
  font-size: 12px;
  color: ${(props) => props.theme.primaryColor};
`;

const MenuArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  > *:last-child {
    margin-left: auto;
  }
  padding: 12px;
`;

const AvailableBudge = styled.div`
  display: inline-block;
  padding: 4px 10px;
  font-size: 12px;
  border-radius: 99px;
  margin-left: 8px;
  border: 1px solid
    ${(props) =>
      props.theme.darkMode
        ? props.theme.positiveColor
        : darken(0.4, props.theme.positiveColor)};
  background: ${(props) =>
    props.theme.darkMode
      ? darken(0.4, props.theme.positiveColor)
      : props.theme.positiveColor};
  color: ${(props) =>
    props.theme.darkMode
      ? props.theme.positiveColor
      : props.theme.positiveColor};
`;

const NonAvailableBudge = styled.div`
  display: inline-block;
  margin-left: 8px;
  padding: 4px 10px;
  font-size: 12px;
  border-radius: 99px;
  border: 1px solid
    ${(props) =>
      props.theme.darkMode
        ? props.theme.negativeColor
        : darken(0.6, props.theme.negativeColor)};
  background: ${(props) =>
    props.theme.darkMode
      ? darken(0.6, props.theme.negativeColor)
      : props.theme.negativeColor};
  color: ${(props) =>
    props.theme.darkMode
      ? props.theme.negativeColor
      : props.theme.negativeColor};
`;

type Props = {
  optX: OptX;
  onClick: (optXId: string) => void;
  selected?: boolean;
};

export const XROptXCardItem: React.FC<Props> = ({
  optX,
  onClick,
  selected,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const handleClose = useCallback((b: boolean) => {
    setOpenModal(b);
  }, []);
  return (
    <>
      <OptXCard selected={selected}>
        <CardActionArea onClick={() => onClick(optX.optXId)}>
          <CardContent>
            <IconPlaceHolder>
              <ASPictureIcon
                src={optX.thumbnail}
                defaultSrc={optX.thumbnail}
                size={42}
              />
            </IconPlaceHolder>
            <HeaderContents>
              <OptXName>
                {optX.name}
                {/* {optX.buildHistory[0]?.message === "ビルド成功" ? (
                <AvailableBudge>ビルド成功</AvailableBudge>
              ) : (
                <NonAvailableBudge>利用不可</NonAvailableBudge>
              )} */}
              </OptXName>

              <Creator>by AllegroSmart, Inc.</Creator>
            </HeaderContents>

            <h3> {optX.parentSlotId}</h3>
            <XRTextArea>{optX.description}</XRTextArea>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <MenuArea>
            <OptXId>{optX.optXId}</OptXId>
            <ASIconButton
              kind="secondary"
              size="small"
              onClick={() => {
                handleClose(true);
              }}
            >
              <OpenInNewIcon />
            </ASIconButton>
          </MenuArea>
        </CardActions>
      </OptXCard>

      <ASModal
        open={openModal}
        onClose={() => {
          handleClose(false);
        }}
      >
        <ModalWrapper>
          <XROptXDetails optX={optX}></XROptXDetails>
        </ModalWrapper>
      </ASModal>
    </>
  );
};
