import { format } from "date-fns";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Link = styled.span`
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const RawDataView = styled.div`
  font-size: 11px;
  opacity: 0.3;
`;

const Ms = styled.span`
  font-size: 11px;
`;

export interface ILCUnixTimeCellComponentProps {
  data: {
    value: any;
  };
  options?: {
    hasMs?: boolean;
  };
}

export default function LCUnixTimeCellComponent(
  props: ILCUnixTimeCellComponentProps
) {
  const value = new Date(props.data.value);
  const isTime = !Number.isNaN(value.getTime());

  const readableDate = () => {
    try {
      return format(value, "yyyy/MM/dd HH:mm:ss");
    } catch (err) {
      return "-";
    }
  };

  const milliSeconds = () => {
    try {
      return format(value, ":SSS");
    } catch (err) {
      return "-";
    }
  };

  return (
    <div>
      {isTime ? (
        <span>
          {readableDate()}
          {props.options?.hasMs ? <Ms>{milliSeconds()}ms</Ms> : <></>}
        </span>
      ) : (
        <>-</>
      )}

      {/* <RawDataView>{props.data.value}</RawDataView> */}
    </div>
  );
}
