import { useState } from "react";

import { ASButton, ASIconButton, ASModal } from "allegro-ui";
import styled from "styled-components";
import { DeleteConfirmModalContents } from "src/kinzaza/components";

const StyledIconButton = styled(ASIconButton)`
  width: 30px;
  height: 30px;
  padding: 8px;
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
`;

const NegativeIconButton = styled(StyledIconButton)`
  color: ${(props) => props.theme.textColor};
  background: ${(props) => props.theme.negativeColor};
  &:hover {
    background: ${(props) => props.theme.negativeColor};
    filter: brightness(0.7);
  }
`;

const StyledButton = styled(ASButton)`
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  padding: 8px;
`;

const NegativeButton = styled(StyledButton)`
  color: ${(props) => props.theme.textColor};
  background: ${(props) => props.theme.negativeColor};
  &:hover {
    background: ${(props) => props.theme.negativeColor};
    filter: brightness(0.9);
  }
`;

type Props = {
  kind?: "primary" | "secondary" | "negative";
  disabled?: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
};

export const NZDeleteButton: React.FC<Props> = (props) => {
  const [show, setShow] = useState(false);

  const ButtonComponent =
    props.kind === "negative" ? NegativeButton : StyledButton;

  const handleOpen = () => {
    setShow(true);
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setShow(false);
  };
  return (
    <>
      <ButtonComponent
        kind="secondary"
        fullWidth
        disabled={props.disabled}
        onClick={() => {
          handleOpen();
        }}
      >
        {props.children}
      </ButtonComponent>
      <ASModal
        open={show}
        // title={"Upload File"}
        onClose={handleClose}
        onAbort={handleClose}
      >
        <DeleteConfirmModalContents
          onSubmit={async () => {
            if (props.onSubmit) {
              await props.onSubmit();
            }
          }}
          onCancel={() => {
            handleClose();
          }}
        />
      </ASModal>
    </>
  );
};

export const NZDeleteIconButton: React.FC<Props> = (props) => {
  const [show, setShow] = useState(false);
  const ButtonComponent =
    props.kind === "negative" ? NegativeIconButton : StyledIconButton;

  const handleOpen = () => {
    setShow(true);
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setShow(false);
  };
  return (
    <>
      <ButtonComponent
        kind="secondary"
        disabled={props.disabled}
        onClick={() => {
          handleOpen();
        }}
      >
        {props.children}
      </ButtonComponent>
      <ASModal
        open={show}
        // title={"Upload File"}
        onClose={handleClose}
        onAbort={handleClose}
      >
        <DeleteConfirmModalContents
          onSubmit={async () => {
            if (props.onSubmit) {
              await props.onSubmit();
            }
          }}
          onCancel={() => {
            handleClose();
          }}
        />
      </ASModal>
    </>
  );
};
