import React from "react";

//Components
import { PTSettingsPageTemplate } from "../templates/PTSettingsPageTemplate";
import { PTSettingProfileTemplate } from "../templates/PTSettingProfileTemplate";

//States
//Types

type Props = {};
export const PTSettingProfilePage: React.FC<Props> = (props) => {
  return (
    <PTSettingsPageTemplate>
      <PTSettingProfileTemplate />
    </PTSettingsPageTemplate>
  );
};
