import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { ASButton, ASModal } from "allegro-ui";
import { useGroupList } from "src/portal/hooks/useGroupList";
import { createGroupDefinition } from "src/portal/ui/forms/createGroupDefinition";
import { XRPageTemplate } from "src/xerver-room/components/templates/XRPageTemplate";
import { PTModalContainer, PTButtonGroup } from "../atoms";
import {
  ASLowCodeDataBrowserContainer,
  LCVerticalFiltersContainer,
} from "src/cheetah/components/libs/ASLowCodeBrowser";
import styled from "styled-components";

const SearchContainer = styled.div`
  margin-bottom: 12px;
`;

export type PTSettingGroupTemplateProps = {};

export const PTSettingGroupTemplate: React.FC = (props) => {
  const pageKey = "pt-settings-group-list";
  const {
    browserUi,
    myRole,
    showCreateModal,
    handleOpenCreateModal,
    handleCloseCreateModal,
    handleSubmitCreateButton,
  } = useGroupList();

  return (
    <XRPageTemplate
      title="Group"
      headerComponents={
        <PTButtonGroup>
          <ASButton
            kind="primary"
            disabled={!myRole.canCreateGroup}
            onClick={handleOpenCreateModal}
          >
            New Group
          </ASButton>
        </PTButtonGroup>
      }
    >
      <SearchContainer>
        <LCVerticalFiltersContainer name={pageKey} uiConf={browserUi} />
      </SearchContainer>
      <ASLowCodeDataBrowserContainer name={pageKey} uiConf={browserUi} />
      <ASModal
        title="New Group"
        open={showCreateModal}
        onClose={handleCloseCreateModal}
      >
        <PTModalContainer>
          <ASLowCodeFormContainer
            formDefinition={createGroupDefinition}
            fullWidth
            onSubmit={(data) => {
              handleSubmitCreateButton(data);
            }}
          />
        </PTModalContainer>
      </ASModal>
    </XRPageTemplate>
  );
};
