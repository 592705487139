import { url } from "../url";
import { api } from "../http";

export const remove = async (
  contractorName: string,
  collectionType: string,
  q: any
) => {
  try {
    const res = await api.delete(
      url.dataManage(contractorName, collectionType),
      {
        params: {
          q: q,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};
