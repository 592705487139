import { ASIconButton, ASModal } from "allegro-ui";
import { ReactElement, ReactNode, useState } from "react";
import styled from "styled-components";

const StyledIconButton = styled(ASIconButton)`
  width: 30px;
  height: 30px;
  border-radius: 4px;
  padding: 3px;
  > * {
    font-size: ${(props) => props.theme.labelFontSizeMd}px;
  }
`;

export type ModalIconButtonProps = {
  children: ReactNode;
  disabled?: boolean;
  modalTitle?: string;
  modalComponents: JSX.Element | ReactElement;
  onClose?: () => void;
};

export const ModalIconButton: React.FC<ModalIconButtonProps> = (props) => {
  const [show, setShow] = useState(false);

  const handleOpen = () => {
    setShow(true);
  };
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setShow(false);
  };

  return (
    <>
      <StyledIconButton
        kind="secondary"
        disabled={props.disabled}
        onClick={handleOpen}
      >
        {props.children}
      </StyledIconButton>
      <ASModal title={props.modalTitle} open={show} onClose={handleClose}>
        {props.modalComponents}
      </ASModal>
    </>
  );
};
