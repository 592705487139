import { ASButton } from "allegro-ui";
import { ASTextFormField } from "allegro-low-code-components";

import { useForm } from "react-hook-form";
import { useSlotActions } from "src/xerver-room/hooks";
import styled from "styled-components";
import { createNewtZeroAPI } from "src/api";

const FormContainer = styled.div`
  margin-bottom: 18px;
`;

type Props = {
  slotId: string;
  disabled?: boolean;
  defaultValues?: {
    serverId?: string;
  };
};

export const XRHardwareSetting: React.FC<Props> = (props) => {
  const { slotId, disabled, defaultValues } = props;
  const { serverId } = defaultValues ?? {};

  const slot = useSlotActions(slotId);

  const { control, handleSubmit } = useForm();

  const searchServers = async (name?: string) => {
    const serversNewtQuery = {
      limit: 5,
      skip: 0,
      search: {
        $or: [
          {
            "contents.rawdata.non_numeric_server_name": {
              $regex: name,
            },
          },
          {
            "contents.rawdata.non_numeric_ip1": { $regex: name },
          },
          {
            "contents.rawdata.non_numeric_ip2": { $regex: name },
          },
          {
            "contents.rawdata.non_numeric_ip3": { $regex: name },
          },
        ],
      },
      shape: {
        "contents.rawdata.non_numeric_server_name": "any",
        "contents.rawdata.non_numeric_ip1": "any",
        "contents.rawdata.non_numeric_ip2": "any",
        "contents.rawdata.non_numeric_ip3": "any",
      },
    };

    const newtzero = createNewtZeroAPI();
    const serversData = await newtzero.server.dataProvider(serversNewtQuery);
    return serversData.map((serverData: any) => {
      return {
        label: `${serverData.contents.rawdata.non_numeric_server_name}: ${serverData.contents.rawdata.non_numeric_ip1}`,
        value: serverData.contents.rawdata.non_numeric_ip1,
      };
    });
  };

  const handleHardwareSubmit = (values: any) => {
    const newServerId = values.serverId;
    if (newServerId) {
      slot.updateServerId(newServerId);
    }
  };

  return (
    <form
      onSubmit={handleSubmit((values: any) => handleHardwareSubmit(values))}
    >
      <FormContainer>
        <ASTextFormField
          name="serverId"
          label="ServerId"
          type="text"
          placeholder="入力してください"
          control={control}
          values={searchServers}
          active={serverId ?? ""}
          defaultValue={serverId}
          editable={!disabled}
          design="digicho"
          error={""}
          required
        />
      </FormContainer>

      <ASButton kind="secondary" type="submit" disabled={disabled}>
        APPLY
      </ASButton>
    </form>
  );
};
