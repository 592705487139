import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";

export const assetBroweserUi: ASBrowserUiConf = {
  filterCustomSubmitText: "Apply filter",
  newtQ: {
    collection: "Asset",
    limit: 10,
    primaryKey: "name",
    // match: {
    //   enable: true,
    // },
    shape: {
      keywords: "any",
      className: "any",
      value: "any",
      schemaVersion: "any",
      assetType: "any",
      name: "any",
      userNoText: "any",
      contractorNo: "any",
      tags: "any",
      sharings: "any",
      assetNo: "any",
      id: "any",
      rawQuePartitions: "any",
      txnQuePartitions: "any",
      topQuePartitions: "any",
      timeFieldMetric: "any",
      readers: "any",
      writers: "any",
      _id: "any",
    },
  },
  filterByContractor: false,
  filters: [],
  actions: [
    {
      label: "Create",
      type: "create",
      onValid: (role: any) => {
        try {
          if (role?.permissions?.includes("newtzero.asset.createInContractor"))
            return true;
          else return false;
        } catch (err) {
          console.error(err);
          return false;
        }
      },
    },
    {
      label: "Upload",
      type: "assetUpload",
      onValid: (role: any) => {
        try {
          if (role?.permissions?.includes("newtzero.asset.createInContractor"))
            return true;
          else return false;
        } catch (err) {
          console.error(err);
          return false;
        }
      },
    },
  ],
  verticalFilters: [
    {
      label: "Search",
      design: "search",
      selections: [],
      callback: (value: string) => {
        return {
          $or: [
            { name: { $regex: value } },
            { tags: value },
            { rawQuePartitions: { $regex: value } },
            {
              txnQuePartitions: { $regex: value },
            },
            { topQuePartitions: { $regex: value } },
          ],
        };
      },
    },
  ],
  tables: [
    {
      label: "Asset name",
      component: "LCTextLinkCellComponent",
      data: {
        label: "name",
        id: "id",
      },
      statics: {
        path: "/dataops/assets/{id}",
      },
      options: {
        editable: false,
      },
    },
    {
      label: "SchemaVersion",
      component: "LCSimpleColComponent",
      data: {
        value: "schemaVersion",
      },
      options: {
        editable: false,
      },
    },
    {
      label: "AssetType",
      component: "LCSimpleColComponent",
      data: {
        value: "assetType",
      },
      options: {
        editable: false,
      },
    },

    {
      label: "UserNo",
      component: "LCSimpleColComponent",
      data: {
        value: "userNoText",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },
    {
      label: "Contractor",
      component: "LCSimpleColComponent",
      data: {
        value: "contractorNo",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },
    {
      label: "Effecting Tags",
      component: "LCSimpleColComponent",
      data: {
        value: "tags",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },
    {
      label: "AssetNo",
      component: "LCSimpleColComponent",
      data: {
        value: "assetNo",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },
    {
      label: "ID",
      component: "LCSimpleColComponent",
      data: {
        value: "id",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },
    {
      label: "",
      component: "LCDeleteButtonComponent",
      data: {
        name: "name",
        documentId: "_id",
        assetId: "id",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },
  ],
  pager: { perPage: 50 },
};
