import * as React from "react";

import styled from "styled-components";
import FTSystemNav from "../organisms/FTSystemNav";

const Container = styled.div`
  width: 100%;
  padding: 24px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: ${(props) => props.theme.textColor};
  margin-bottom: 24px;
`;

const StyledFTSystemNav = styled(FTSystemNav)`
  padding-bottom: 24px;
`;

interface IFTSystemPageTemplateProps {}

const FTSystemPageTemplate: React.FunctionComponent<IFTSystemPageTemplateProps> =
  (props) => {
    return (
      <>
        <Container>
          <Title>DevOps</Title>
          <StyledFTSystemNav />
          {props.children}
        </Container>
      </>
    );
  };

export default FTSystemPageTemplate;
