import { AxiosInstance } from "axios";
import { Server, createServerFromNewtData } from "../../models/Server";
import { NewtQuery } from "../../models";

type IServer = Omit<Server, "non_numeric_server_id">;

export const ServerInstance = (instance: AxiosInstance) => {
  const add = async (serverInput: IServer): Promise<Server> => {
    const path = `/newtzero/server`;
    const res = await instance.post(path, serverInput);
    return createServerFromNewtData(res.data.message);
  };

  const findMany = async (query: {
    limit?: number;
    skip?: number;
    serverName?: string;
  }): Promise<Server[]> => {
    const path = `/newtzero/server`;
    try {
      const res = await instance.get(path, { params: query });
      return res.data.message.map((data: any) => {
        return createServerFromNewtData(data);
      });
    } catch (err) {
      console.error(err);
    }

    return [];
  };
  const findOne = async (
    non_numeric_server_id: string
  ): Promise<Server | null> => {
    const path = `/newtzero/server/${non_numeric_server_id}`;
    try {
      const res = await instance.get(path);
      return createServerFromNewtData(res.data.message);
    } catch (err) {
      console.error(err);
    }
    return null;
  };

  const update = async (
    non_numeric_server_id: string,
    serverInput: IServer
  ): Promise<boolean> => {
    const path = `/newtzero/server/${non_numeric_server_id}`;
    try {
      const res = await instance.put(path, serverInput);
      return res.status === 200;
    } catch (err) {
      console.error(err);
    }
    return false;
  };

  const removeOne = async (non_numeric_server_id: string) => {
    const path = `/newtzero/server/${non_numeric_server_id}`;
    try {
      const res = await instance.delete(path);
      return res.status === 200;
    } catch (err) {
      console.error(err);
    }
    return false;
  };

  const dataProvider = async (
    newtQuery: NewtQuery,
    options?: { count?: boolean }
  ): Promise<any[]> => {
    const path = "/newtzero/server/dataprovider";
    try {
      const res = await instance.post(path, newtQuery, { params: options });
      return res.data.message ?? [];
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  return {
    add,
    findMany,
    findOne,
    update,
    removeOne,
    dataProvider,
  };
};
