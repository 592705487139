import { PTSettingGroupTemplate } from "src/portal/components/templates/PTSettingGroupTemplate";
import { NTSettingsPageTemplate } from "../templates/NTSettingsPageTemplate";

export const NTSettingGroupPage: React.FC = () => {
  return (
    <NTSettingsPageTemplate>
      <PTSettingGroupTemplate />
    </NTSettingsPageTemplate>
  );
};
