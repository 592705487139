import "./index.css";
import "./App.css";

import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { StylesProvider } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { AuthProvider, AxiosProvider } from "./root";

import { cursorThemeNameState } from "./recoils/theme";

import favy from "allegro-api";
import { API_URL } from "./utils/apiUrl";

import { getTheme, ASNotifyProvider } from "allegro-ui";

import NewtroidConsoleRoutes from "./newtroid-console/Routes";
import NewtZeroRoutes from "./newtzero-console/Routes";
import NAIRoutes from "./nai-console/Routes";
import CheetahRoutes from "./cheetah/Routes";

// import Routes from "./fortress/Routes";
// import PortalRoutes from "./portal/Routes";
// import OptEditorRoutes from "./opt-editor/Routes";
// import KinzazaRoutes from "./kinzaza/Routes";
// import MercuryRoutes from "./mercury/Routes";
// import XerverRoomRoutes from "./xerver-room/Routes";
// import NewtroidRoutes from "./newtroid/Routes";
// import HoneyFormRoutes from "./honey-form/Routes";
// import MedicanRoutes from "./medican/Routes";
// import DFurusatoRoutes from "./dfurusato-pf/Routes";

export const BaseDocument = () => {
  const [defaultTheme, setTheme] = useRecoilState(cursorThemeNameState);

  let dtheme = defaultTheme;
  const ltheme = localStorage.getItem("allegrofuchufortresstheme");
  const apiUrl = API_URL;
  const match = apiUrl?.match(/(^.*)\/api\/([0-9]).?/);
  const host = Array.isArray(match) ? match[1] : undefined;

  const version = Array.isArray(match) ? Number(match[2]) : 1;

  favy.init({
    apikey: sessionStorage.token,
    contractor: sessionStorage.contractor,
    host,
    version,
  });

  useEffect(() => {
    if (ltheme) {
      setTheme(ltheme);
    } else {
      setTheme(dtheme);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = getTheme(dtheme);

  const APP = process.env.REACT_APP_APP;

  let routes: any = <NewtroidConsoleRoutes />;

  (async () => {
    switch (APP) {
      case "allegrobot":
        routes = (await import("./allegrobot/Routes")).default;
        break;
      // case "newtroid":
      //   routes = <NewtroidRoutes />;
      //   break;
      // case "portal":
      //   routes = <PortalRoutes></PortalRoutes>;
      //   break;
      // case "fortress":
      //   break;
      // case "opt-editor":
      //   routes = <OptEditorRoutes></OptEditorRoutes>;
      //   break;
      // case "kinzaza":
      //   routes = <KinzazaRoutes></KinzazaRoutes>;
      //   break;
      // case "mercury":
      //   routes = <MercuryRoutes></MercuryRoutes>;
      //   break;
      // case "xerver-room":
      //   routes = <XerverRoomRoutes></XerverRoomRoutes>;
      //   break;
      case "cheetah":
        routes = <CheetahRoutes></CheetahRoutes>;
        break;
      case "newtzero-console":
        routes = <NewtZeroRoutes />;
        break;
      case "newtroid-console":
        routes = <NewtroidConsoleRoutes />;
        break;
      // case "honey-form":
      //   routes = <HoneyFormRoutes />;
      //   break;
      // case "medican":
      //   routes = <MedicanRoutes />;
      //   break;
      case "nai-console":
        routes = <NAIRoutes />;
        break;
      // case "dfurusato-pf":
      //   routes = <DFurusatoRoutes />;
      // break;
      default:
        console.error("cannot find app name.");
        console.error(APP);
    }
  })();

  document.body.style.backgroundColor = theme.bgPrimaryColor;

  const GlobalStyle = createGlobalStyle`
  
    body{
      overscroll-behavior-y: none;
    }

    h1,h2,h3,h4,h5,h6,span,div,td,th,table{
      font-family:'NotoSansJP', 'gaiji' !important;
      /* color: ${(props) => (props.theme.darkMode ? "#e4e4e4" : "#000")} */
    }

    *{
      box-sizing: border-box;
    }

    //react-flowの表示を消す。
    .react-flow__attribution{
      display:none;
    }
    
    /* h1,h2,h3,h4,h5,h6,span,div,td,th,table{
      color: ${(props) => (props.theme.darkMode ? "#e4e4e4" : "#000")} 
    } */


    *{
      box-sizing: border-box;
    }
`;

  return (
    <>
      {/* material UIのstyleよりも自作のstyleを優先する */}
      <StylesProvider injectFirst>
        {/* <StaticStyle /> */}
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <DndProvider backend={HTML5Backend}>
            <SnackbarProvider maxSnack={3}>
              <ASNotifyProvider>
                <AuthProvider>
                  <AxiosProvider>{routes}</AxiosProvider>
                </AuthProvider>
              </ASNotifyProvider>
            </SnackbarProvider>
          </DndProvider>
        </ThemeProvider>
      </StylesProvider>
    </>
  );
};
