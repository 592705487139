import styled from "styled-components";
import { Chip } from "@material-ui/core";

const StyledChip = styled(Chip)`
  color: white;
  background: #48aa17;
  border-radius: 5px;
  width: 78px;
`;

export const OEAddChip: React.FC = () => {
  return <StyledChip label="ADD" />;
};
