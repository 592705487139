import React, { useCallback, useEffect, useState } from "react";

import { useMyRole } from "src/portal/hooks/useMyRole";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { useUIContext } from "src/fortress/contexts";
import styled from "styled-components";

import { ASModal, useNotify } from "allegro-ui";

import {
  ASLowCodeFormDefinition,
  ASLowCodeFormContainer,
} from "allegro-low-code-components";

import {
  sortFilterState,
  ASBrowserUiConf,
  LCVerticalFiltersContainer,
  ASLowCodeDataBrowserContainer,
} from "src/cheetah/components/libs/ASLowCodeBrowser";

import CHLowCodeButtonsGroup from "src/cheetah/components/organisms/CHLowCodeButtonsGroup";

const Header = styled.div`
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.textColor};
  > *:last-child {
    margin-left: auto;
  }
`;

const MainArea = styled.div`
  background: ${(props) => props.theme.bgPrimaryColor};
`;

type Props = {
  name: string;
  browserUi: ASBrowserUiConf;
  formUi: ASLowCodeFormDefinition;
};

interface QueryString {
  [key: string]: string;
}

export const NZDataOpsListTemplate: React.FC<Props> = (props) => {
  const { name, browserUi, formUi } = props;
  const [open, setOpen] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [tabId, setTabId] = useState(0);

  const role = useMyRole();
  const { search } = useLocation();
  const history = useNavigate();
  const notify = useNotify();
  const ui = useUIContext();

  const { page } = ui.childList;
  const limit = 20;

  const setSortQuery = useSetRecoilState(sortFilterState(name));

  useEffect(() => {
    const query = search
      .slice(1)
      .split("&")
      .map((str) => [str.split("=")[0], str.split("=")[1]])
      .reduce((acc, a) => {
        acc[a[0]] = a[1];
        return acc;
      }, {} as QueryString);
    if (query.tab) setTabId(Number(query.tab));
  }, [search]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (tabId) {
      params.append("tab", String(tabId));
    } else {
      params.delete("tab");
    }
    history({ search: params.toString() });

    if (tabId === 2) {
      setSortQuery({
        "contents.rawdata.inspectionYmd": -1,
        "contents.rawdata.assetName": 1,
        "contents.rawdata.quePartitionName": -1,
      });
    }
  }, [history, setSortQuery, tabId]);

  //コンポーネントがレンダリングされた直後に、総ページ数をカウントしたいがために実装。

  const didMount = useCallback(async () => {
    notify.dispatch("loading", { isLoading: true });
    // const [count] = await api.getChildList(limit, page);
    const count = 10;
    notify.dispatch("loading", { isLoading: false });
    setTotalPageCount(Math.ceil(count / limit));
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    didMount();
  }, [didMount]);

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <>
      <React.Suspense fallback={<></>}>
        <Header>
          <LCVerticalFiltersContainer
            name={`nt-${name}-list-page`}
            uiConf={browserUi}
          />

          <CHLowCodeButtonsGroup
            uiConf={browserUi}
            role={role}
            createForm={formUi}
          />
        </Header>
        <MainArea>
          <ASLowCodeDataBrowserContainer
            name={`nt-${name}-list-page`}
            uiConf={browserUi}
          />
        </MainArea>
      </React.Suspense>
      <ASModal
        open={open}
        onClose={handleModalClose}
        // hidden={!(0 === tabId)}
      >
        <ASLowCodeFormContainer
          formDefinition={formUi}
          onClose={handleModalClose}
        />
      </ASModal>
    </>
  );
};
