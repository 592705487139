import { AxiosInstance } from "axios";

type Args = {
  userNumber: string;
  roleId: string;
};

export const updateRole = async (instance: AxiosInstance, props: Args) => {
  const res = await instance({
    method: "put",
    url: `/users/${props.userNumber}/role`,
    data: {
      roleId: props.roleId,
    },
  });

  return res;
};
