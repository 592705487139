import styled from "styled-components";
import { ASPictureIcon } from "../atoms";

const CircleIcon = styled.div`
  width: 30px;
  min-width: 30px;
  min-height: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  background-color: green; // TODO
  text-align: center;
  display: grid;
  place-items: center;
  font-size: ${(props) => props.theme.regularFontSizeMd}px;
`;

const InitialText = styled.div`
  margin: 0 auto;
`;

export type ASUserIconProps = {
  userName: string;
  avatar: string;
};

export const ASUserIcon: React.FC<ASUserIconProps> = (props) => {
  const { userName, avatar } = props;
  const initial = userName ? userName[0].toUpperCase() : "";

  return avatar ? (
    <ASPictureIcon src={avatar} defaultSrc={avatar} size={30} />
  ) : (
    <CircleIcon>
      <InitialText>{initial}</InitialText>
    </CircleIcon>
  );
};
