import { APIConfigProps } from "allegro-api/lib/config/http";
import axios, { AxiosInstance } from "axios";
import { fetchServerStatsByNewtQuery } from "./serverStats";
import { fetchContainerStatsByNewtQuery } from "./containerStats";
import { fetchContainerLogsByNewtQuery } from "./containerLog";
import { API_URL } from "src/utils/apiUrl";

type GetPromiseValue<T> = T extends Promise<infer U> ? U : never;

const wrapAsync = <
  T extends unknown[],
  U extends Promise<unknown>,
  K extends GetPromiseValue<U>
>(
  instance: AxiosInstance,
  fn: (instance: AxiosInstance, ...args: T) => Promise<K>
): ((...args: T) => Promise<K>) => {
  const wrapFunc = async (...args: T): Promise<K> => {
    const result = await fn(instance, ...args);
    return result;
  };
  return wrapFunc;
};

const createMonitorApiInstance = (props: APIConfigProps): AxiosInstance =>
  axios.create({
    baseURL: `${props.baseUrl}`,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `${props.apikey}`,
      "User-Id": `${props.userNoText}`,
    },
    timeout: 1200000,
    // timeout: 5000,
  });

export const MonitorAPI = (props: APIConfigProps) => {
  const instance = createMonitorApiInstance(props);

  return {
    fetchServerStatsByNewtQuery: wrapAsync(
      instance,
      fetchServerStatsByNewtQuery
    ),
    fetchContainerStatsByNewtQuery: wrapAsync(
      instance,
      fetchContainerStatsByNewtQuery
    ),
    fetchContainerLogsByNewtQuery: wrapAsync(
      instance,
      fetchContainerLogsByNewtQuery
    ),
  };
};

export const MONITOR_BASE_URL = API_URL + "/monitor";
