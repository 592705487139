//Libs
import React from "react";

//Components
import { NTGlobalNav } from "src/newtroid/components/organisms/NTGlobalNav";
import { PTSettingNav } from "./PTSettingNav";
//States
//Types

type Props = {};

export const PTSettingsPageTemplate: React.FC<Props> = ({ children }) => {
  return (
    <>
      <NTGlobalNav />
      <PTSettingNav>{children}</PTSettingNav>
    </>
  );
};
