import { AxiosInstance } from "axios";

export const getOne = async (
  instance: AxiosInstance,
  contractor: string,
  optXId: string
) => {
  try {
    const res = await instance({
      method: "get",
      url: `/contractors/${contractor}/optx/${optXId}`,
    });

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const getMany = async (
  instance: AxiosInstance,
  contractor: string,
  query: any,
  options?: { count?: boolean; inspect?: boolean }
) => {
  try {
    const res = await instance({
      method: "get",
      url: `/contractors/${contractor}/optx`,
      params: { q: query, options },
    });

    return res;
  } catch (err) {
    console.error(err);
  }
};
