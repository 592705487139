import React, { useEffect, useCallback } from "react";
import { ASSelect } from "allegro-ui";
import {
  slotListState,
  selectSlotState,
  cursorSlotState,
} from "src/opt-editor/recoils/slotState";
import { useSetRecoilState, useRecoilValue } from "recoil";

type Props = {};
export const OESlotSelect: React.FC<Props> = (props) => {
  const slotList = useRecoilValue(slotListState);
  const cursorSlot = useRecoilValue(cursorSlotState);
  const selectSlot = useSetRecoilState(selectSlotState);

  const didMount = useCallback(async () => {
    //0番目のスロットを選択する。
    if (Object.keys(slotList).length > 0)
      selectSlot(slotList[Object.keys(slotList)[0]].name);
  }, [selectSlot, slotList]);

  useEffect(() => {
    didMount();
  }, [didMount]);

  const selections = useCallback(() => {
    const arr = slotList
      ? Object.keys(slotList).map((key) => {
          return {
            value: slotList[key].name,
            label: `[${slotList[key].type}]:${slotList[key].name}`,
          };
        })
      : [];
    return arr;
  }, [slotList]);

  const handleSlot = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const value = String(event.target.value);
    selectSlot(value);
  };

  return (
    <>
      <ASSelect
        label="Node"
        kind="secondary"
        value={cursorSlot ? cursorSlot.name : ""}
        data={selections()}
        onChange={handleSlot}
      ></ASSelect>
    </>
  );
};
