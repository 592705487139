/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";

import styled from "styled-components";

//Components
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import {
  ASButton,
  ASInputBase,
  ASModal,
  ASNoDataPlaceHolder,
  ASTable,
} from "allegro-ui";

import { PTUserListItem } from "../atoms/PTUserListItem";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

//Types
import { ASLowCodeFormContainer } from "allegro-low-code-components";

import { createUserFormDefinition } from "src/portal/ui/forms/createUserFormDefinition";
import { LCLoadingBoard } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { XRPageTemplate } from "src/xerver-room/components/templates/XRPageTemplate";

import { RoleSelection } from "src/newtroid-console/models/RoleSelection";
import { useUserList } from "src/portal/hooks/useUserList";
import { PTButtonGroup, PTModalContainer } from "../atoms";

const Container = styled.div`
  /* padding: 30px; */
`;

const ActionArea = styled.div`
  display: flex;
`;

const InviteHeader = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 60px;
  > h2 {
    margin-top: 16px;
  }

  > *:nth-child(odd) {
    opacity: 0.6;
  }
`;

const InviteForm = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  > h3 {
    margin-bottom: 8px;
  }

  > * {
    margin-bottom: 12px;
  }
`;

const NonAuthorized = styled(ASNoDataPlaceHolder)`
  /* color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
  text-align: center; */
  width: 100%;
  height: 300px;
`;

const ErrorText = styled.div`
  color: ${(props) => props.theme.negativeColor};
`;

type Props = {
  roleGroupName?: string;
  roleSelections?: RoleSelection[];
};

export const PTSettingTeamTemplate: React.FC<Props> = (props) => {
  const { roleGroupName } = props;

  const {
    isLoading,
    myRole,
    roleSelections,
    inviteStep,
    inviteError,
    emailToInvite,
    showCreateModal,
    showInviteModal,
    createUserListItemAttributes,
    validateEmail,
    handleRoleEdit,
    handleChangeInviteEmail,
    handleSubmitInviteEmail,
    handleSubmitCreateUser,
    handleOpenInviteModal,
    handleCloseInviteModal,
    handleOpenCreateModal,
    handleCloseCreateModal,
  } = useUserList({
    roleGroupName,
    roleSelections: props.roleSelections ?? [],
  });

  const renderUserList = useCallback(() => {
    const userListItems = createUserListItemAttributes();

    return userListItems.map((item, i) => {
      const { user, userRole, userRoleName, canEditRole } = item;
      return (
        <PTUserListItem
          key={`pt-user-list-item-${i}`}
          userName={user.profile.userRealName ?? ""}
          src={user.avatar}
          columns={[
            user.profile.userRealName ?? "---",
            user.email,
            user.userNoText ?? "---",
            userRoleName ?? "----",
          ]}
          userId={user.userNoText ?? ""}
          onClick={handleRoleEdit}
          userNum={Number(user.userNo)}
          email={user.username}
          editableRole={canEditRole}
          activated={user.enable}
          role={userRole}
          roleSelections={roleSelections}
        />
      );
    });
  }, [createUserListItemAttributes, handleRoleEdit, roleSelections]);

  const renderStep = () => {
    switch (inviteStep) {
      case 0:
        return (
          <>
            <InviteHeader>
              <MailOutlineIcon fontSize="large" />
              <h2>Invite a member to {sessionStorage.contractorNo}</h2>
            </InviteHeader>
            <InviteForm>
              <h3>by email address</h3>
              <ASInputBase
                kind="secondary"
                placeholder="example@mail.com"
                onChange={(e) => handleChangeInviteEmail(e.target.value)}
              />
              <ASButton kind="primary" onClick={handleSubmitInviteEmail}>
                Invite
              </ASButton>
              <ErrorText>{inviteError}</ErrorText>
            </InviteForm>
          </>
        );

      case 1:
        return (
          <>
            <InviteHeader>
              <MailOutlineIcon fontSize="large" />
              <h2>{emailToInvite}に招待を送りました。</h2>
            </InviteHeader>
            <InviteForm>
              <ASButton kind="primary" onClick={handleCloseInviteModal}>
                Close
              </ASButton>
            </InviteForm>
          </>
        );
      default:
        <></>;
    }
  };

  const renderCreateModal = () => {
    return (
      <ASLowCodeFormContainer
        key="pt-create-user-form"
        formDefinition={createUserFormDefinition}
        onValid={async (data, options) => {
          const email = data.email;
          return validateEmail(email);
        }}
        onSubmit={async (data: any, options: any) => {
          let postData: any = data;
          handleSubmitCreateUser(postData);
        }}
      />
    );
  };

  return (
    <XRPageTemplate
      title="Teams"
      headerComponents={
        <ActionArea>
          <PTButtonGroup>
            <>
              <ASButton
                kind="primary"
                disabled={!myRole.canCreateUser}
                onClick={handleOpenCreateModal}
              >
                Create member
              </ASButton>
              <ASButton
                kind="primary"
                disabled={!myRole.canInviteUser}
                onClick={handleOpenInviteModal}
              >
                Invite member
              </ASButton>
            </>
          </PTButtonGroup>
        </ActionArea>
      }
    >
      <Container>
        {myRole.canReadUsers ? (
          !isLoading ? (
            <TableContainer
              component={ASTable}
              borderRadius={18}
              kind="tertiary"
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Activation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{renderUserList()}</TableBody>
              </Table>
            </TableContainer>
          ) : (
            <NonAuthorized>
              <LCLoadingBoard />
            </NonAuthorized>
          )
        ) : (
          <NonAuthorized>表示する権限がありません。</NonAuthorized>
        )}

        <ASModal open={showInviteModal} onClose={handleCloseInviteModal}>
          <PTModalContainer>{renderStep()}</PTModalContainer>
        </ASModal>
        <ASModal open={showCreateModal} onClose={handleCloseCreateModal}>
          <PTModalContainer>{renderCreateModal()}</PTModalContainer>
        </ASModal>
      </Container>
    </XRPageTemplate>
  );
};
