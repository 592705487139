import { AxiosInstance } from "axios";

type Args = {
  where?: any;
  limit?: number;
  skip?: number;
  serverId?: string;
  pipelineId?: string;
};

export const getMany = async (instance: AxiosInstance, props: Args) => {
  const { pipelineId, serverId, where, limit, skip } = props;
  try {
    const res = await instance.get(`/xervice/`, {
      params: {
        pipelineId,
        serverId,
        limit,
        skip,
        where,
      },
    });
    return res.data ?? [];
  } catch (err) {
    console.error(err);
  }

  return [];
};

export const getOne = async (
  instance: AxiosInstance,
  slotId: string
): Promise<any> => {
  const res = await instance({
    method: "GET",
    url: `/xervice/${slotId}`,
  });
  if (res.status === 200 && res.data) {
    return res.data;
  } else {
    console.error("failed fetch a slot", slotId);
    return null;
  }
};
