//画面最上部にあるパンくずリストのコンポーネント

import React from "react";
import { useRecoilValue } from "recoil";
import { isAllAssesmentState } from "src/fortress/recoils/assessmentState";
import { cursorChildThumbState } from "src/fortress/recoils/singleChildPageState";
import styled from "styled-components";

type Props = {
  kind: number;
};

const Bread = styled.div`
  /* margin-top: 25px; */
  /* margin-left: 8px; */
  margin-bottom: 15px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
  p {
    font-size: ${(props) => props.theme?.regularFontSizeMd}px;
  }
`;

const BreadTwo = styled.div`
  margin-top: 25px;
  margin-left: 32px;
  margin-bottom: 15px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
  p {
    font-size: ${(props) => props.theme?.regularFontSizeMd}px;
  }
`;

export const FTBreadList: React.FC<Props> = (props) => {
  const cursorChild = useRecoilValue(cursorChildThumbState);
  const isAllAssesment = useRecoilValue(isAllAssesmentState);
  const { kind } = props;

  //パンくずリストを反映させる画面によってパンくずリストの文字やレイアウトを微妙に変化させるため。

  const childListBread =
    kind === 1 ? (
      <Bread>
        <p>
          児童一覧 &gt;{" "}
          {process.env.REACT_APP_KAMEI_MODE ? "非表示" : cursorChild.name}(
          {cursorChild.age})
        </p>
      </Bread>
    ) : kind === 2 ? (
      <BreadTwo>
        <p>
          児童一覧 &gt;{" "}
          {process.env.REACT_APP_KAMEI_MODE ? "非表示" : cursorChild.name}(
          {cursorChild.age}) &gt; アセスメント詳細画面
        </p>
      </BreadTwo>
    ) : (
      <Bread>
        <p>
          児童一覧 &gt;{" "}
          {process.env.REACT_APP_KAMEI_MODE ? "非表示" : cursorChild.name}(
          {cursorChild.age}) &gt; 新規アセスメント作成
        </p>
      </Bread>
    );

  const bread = isAllAssesment ? (
    <>
      <BreadTwo>
        <p>
          アセスメント一覧 &gt;{" "}
          {process.env.REACT_APP_KAMEI_MODE ? "非表示" : cursorChild.name}(
          {cursorChild.age}
          )さんのアセスメント詳細画面
        </p>
      </BreadTwo>
    </>
  ) : (
    <>{childListBread}</>
  );

  return <>{bread}</>;
};
