import { ASLowCodeFormDefinition } from "allegro-low-code-components";
import { createNewtZeroAPI } from "src/api";

export const serverCreatingFormDefinition: ASLowCodeFormDefinition = {
  sections: [
    {
      title: "サーバー登録",
      description: "サーバー情報の登録をします。",
      fields: [
        {
          label: "サーバー名",
          name: "non_numeric_server_name",
          type: "text",
          active: "",
          values: undefined,
          required: true,
          errorText: "サーバー名は必須です。",
        },
        {
          label: "IP1",
          name: "non_numeric_ip1",
          type: "text",
          active: "",
          values: undefined,
          required: true,
          errorText: "IPは必須です。",
        },

        {
          label: "IP2",
          name: "non_numeric_ip2",
          type: "text",
          active: "",
          values: undefined,
        },
        {
          label: "IP3",
          name: "non_numeric_ip3",
          type: "text",
          active: "",
          values: undefined,
        },
        {
          label: "公開鍵",
          name: "non_numeric_public_ssh_key",
          type: "text",
          active: "",
          values: undefined,
        },

        {
          label: "タグ",
          name: "non_numeric_tags",
          type: "chip",
          active: [],
          values: undefined,
          required: true,
          errorText: "タグは必須です。",
        },

        {
          label: "nAnsible用設定",
          name: "nAnsible用設定",
          type: "section",
          active: "",
          values: undefined,
        },
        {
          label: "SSH ホスト",
          name: "non_numeric_ssh_host",
          type: "text",
          active: "root@127.0.0.1",
          values: undefined,
          errorText: "入力してください。",
        },
        {
          label: "SSH 秘密鍵",
          name: "non_numeric_ssh_private_key",
          type: "text",
          active: "",
          values: undefined,
          errorText: "入力してください。",
        },
        {
          label: "SSH 公開鍵",
          name: "non_numeric_ssh_public_key",
          type: "text",
          active: "",
          values: undefined,
          errorText: "入力してください。",
        },
        {
          label: "serverId",
          name: "non_numeric_server_id",
          type: "skelton",
          values: undefined,
        },
      ],
    },
  ],
  onSubmit: async (d, options) => {
    //互換性のあるアセットを作成する。
    console.log(d);

    let isSuccessed = false;
    const newtzero = createNewtZeroAPI(2);
    try {
      if (options?.isUpdate) {
        const serverId = d.non_numeric_server_id;
        const res = await newtzero.server.update(serverId, d);
        isSuccessed = res;
      } else {
        const res = await newtzero.server.add(d);
        isSuccessed = res !== null;
      }

      if (isSuccessed) {
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  },
};
