import { useState } from "react";
import { useMyRole } from "./useMyRole";
import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { createnAuthAPI } from "src/api";
import { GroupInput } from "allegro-api";
import { createGroupListBrowserUi } from "../ui/browser/createGroupListBrowserUi";
import { fetchGroupMany } from "../api/fetchGroup";
import { useAuth } from "src/root";

export const useGroupList = () => {
  const myRole = useMyRole();
  const auth = useAuth();

  const nauth = createnAuthAPI();

  const [showCreateModal, setShowCreateModal] = useState(false);

  // nauthのGroup一覧APIをリクエストしてGroup一覧を取得する
  const fetchGroupList = async (limit: number, skip: number, options?: any) => {
    const groups = await fetchGroupMany({
      limit,
      skip,
      matchQ: options?.match,
    });
    return groups;
  };

  // Group削除ボタンが押された際のコールバック関数
  // nauthのグループ削除APIをリクエストする
  const handleDeleteGroup = async (id: string) => {
    const nauth = createnAuthAPI();
    try {
      await nauth.group.deleteOne(id);
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };

  const browserUi: ASBrowserUiConf = createGroupListBrowserUi({
    contractorNo: auth.contractorNo ?? "",
    dataFetcher: fetchGroupList,
    onDelete: handleDeleteGroup,
    canDeleteGroup: myRole.canCreateGroup,
  });

  // group新規作成モーダルを表示する
  const handleOpenCreateModal = () => {
    setShowCreateModal(true);
  };

  // group新規作成モーダルを非表示にする
  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  // Group新規作成ボタンが押された際のコールバック関数
  // nauthのgroup新規作成APIをリクエストする
  const handleSubmitCreateButton = async (groupInput: GroupInput) => {
    try {
      const res = await nauth.group.create(groupInput);
      setShowCreateModal(false);
      if (res) {
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return {
    // permission
    myRole,

    browserUi,
    // modal
    showCreateModal,
    handleOpenCreateModal,
    handleCloseCreateModal,
    handleSubmitCreateButton,
  };
};
