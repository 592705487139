import { darken, lighten } from "polished";
import React from "react";
import styled from "styled-components";

export type ASRequiredProps = {
  requiredMarker?: string;
  oprionalMarker?: string;
  required?: boolean;
};

const Mark = styled.div<{ required?: boolean }>`
  display: inline-block;
  _display: inline;
  color: ${(props) =>
    props.required
      ? props.theme.negativeColor
      : props.theme.darkMode
      ? darken(0.4, props.theme.textColor)
      : lighten(0.4, props.theme.textColor)};
  padding: 4px;
  font-size: 12px;
  margin-left: 2px;
  line-height: 1.3;
`;

export const ASRequiredMark: React.FC<ASRequiredProps> = ({
  required,
  ...props
}) => {
  const mark = props.requiredMarker ?? "required";
  const optional = props.oprionalMarker ?? "optional";

  return <Mark required={required}>{required ? mark : optional}</Mark>;
};
