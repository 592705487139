//Libs
import React from "react";
import styled from "styled-components";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { useRecoilValue } from "recoil";
//Components
import { ASUserIcon } from "allegro-ui";
//States
import { useAuth } from "src/root/UseAuth";

//Types

import { useNavigate } from "react-router-dom";
import { myProfileState } from "src/root/recoils/userState";

type Props = {};

const UserMenu = styled(Button)`
  background-color: ${(props) => props.theme.bgPrimaryColor};
  border: 1px solid ${(props) => props.theme.secondaryBorderColor};
  height: 40px;
  min-width: 130px;
  border-radius: 180px;
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
  padding: 6px 10px;
  text-align: center;
`;

const DownTriangle = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
  margin: 0 auto;
  margin-right: 4px;
`;

const NameWrapper = styled.div`
  margin-left: 8px;
  margin-right: 8px;
`;

export const OEUserButton: React.FC<Props> = (props) => {
  let history = useNavigate();
  const myProfile = useRecoilValue(myProfileState);

  const UserName = () => {
    return myProfile ? (
      <NameWrapper>{myProfile.profile.userRealName}</NameWrapper>
    ) : (
      <></>
    );
  };
  const ArrowIcon = () => {
    return <DownTriangle />;
  };
  const auth = useAuth();

  const [userContextMenu, setUserContextMenu] = React.useState<{
    mouseX: number | null;
    mouseY: number | null;
  }>({ mouseX: null, mouseY: null });
  const handleUserContextMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    // 初期のメニュー非表示
    event.preventDefault();
    setUserContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };
  // メニューを閉じる
  const handleUserContextMenuClose = () => {
    setUserContextMenu({
      mouseX: null,
      mouseY: null,
    });
  };

  const handleClickManagement = () => {
    history("/management");
  };

  const handleSettingPage = () => {
    history("/settings/profile");
  };

  return (
    <>
      <UserMenu
        onClick={(evt) => {
          handleUserContextMenu(evt);
        }}
      >
        <ASUserIcon
          avatar={myProfile?.avatar}
          userName={myProfile.profile.userRealName ?? ""}
        />
        <UserName />
        <ArrowIcon />
      </UserMenu>
      <Menu
        keepMounted
        open={userContextMenu.mouseY !== null}
        onClose={handleUserContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          userContextMenu.mouseY !== null && userContextMenu.mouseX !== null
            ? {
                top: userContextMenu.mouseY,
                left: userContextMenu.mouseX,
              }
            : undefined
        }
      >
        <MenuItem onClick={handleSettingPage}>Settings</MenuItem>
        <MenuItem onClick={handleClickManagement}>Management</MenuItem>
        <MenuItem
          onClick={() => {
            if (auth?.logout) auth.logout();
          }}
        >
          LogOut
        </MenuItem>
      </Menu>
    </>
  );
};
