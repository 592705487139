import styled from "styled-components";
import { NZDataViewer } from "../../../organisms/NZDataViewer";
import { useAssetData } from "src/newtzero-console/hooks/useAssetData";
import { useState } from "react";
import { ASInputBase, ASPageNav } from "allegro-ui";
import React from "react";
import { NZMetricViewer } from "../../../organisms/NZMetricViewer";
import { NZSearchFilter } from "../../../organisms/NZSearchFilter";
import { ImportButton } from "src/newtroid/components/molecules";
import { downloadFile } from "src/utils/download";
import { useCsv } from "src/general/hooks/useCsv";
import { createNewtZeroAPI } from "src/api";

const Root = styled.div`
  /* padding: 24px;
  border: 1px solid #444; */
`;

const SearchArea = styled.div`
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: row;

  > *:last-child {
    margin-left: auto;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MainViewArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50vh;
`;

const DataViewArea = styled.div`
  width: 70%;
  height: 100%;
  align-items: center;
  > * {
    margin-bottom: 8px;
  }
`;

const MetricViewArea = styled.div`
  width: 30%;
  margin-left: 32px;
  height: 50vh;
`;

const Title = styled.div`
  color: ${(props) => props.theme.textColor};
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-right: 12px;
  }
  padding: 16px;
`;

const StyledInput = styled(ASInputBase)`
  width: 60px;
  min-width: 60px;
  height: 35px;
`;

const InputSuffix = styled.div`
  color: ${(props) => props.theme.textColor};
  font-size: ${(props) => props.theme.labelFontSizeMd}px;
`;

type Props = {
  asset: any;
};

export const NZAssetDataViewTemplate: React.FC<Props> = (props) => {
  const { asset } = props;

  const {
    loadablePageCount,
    newtData,
    getTotalNewtQuery,
    metrics,
    limit,
    updateLimit,
    page,
    updatePage,
    totalPage,
    updateSearch,
    logs,
    uploadFiles,
    isLoading,
    deleteData,
  } = useAssetData(asset);

  const csvFile = useCsv();

  const [limitStr, setLimitStr] = useState(limit.toString());

  // useEffect(() => {}, []);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    updatePage(value - 1);
  };

  const handleLimitChange = (newLimitStr: string) => {
    const newLimit = Number(newLimitStr);
    updateLimit(newLimit);
  };

  const handleFilterSubmit = (values: any) => {
    const metricName: string = values.metricName;
    let metricValue = values.metricValue;
    if (metricName) {
      if (metricName.startsWith("numeric_")) {
        metricValue = Number(metricValue);
      }
      const data = Object.fromEntries([
        [`contents.optdata.${metricName}`, metricValue],
      ]);

      updateSearch(data);
    } else {
      updateSearch(undefined);
    }
  };

  const handleDeleteData = async () => {
    await deleteData();
  };

  const handleDownload = async (options: any) => {
    const newtzero = createNewtZeroAPI();
    const newtQuery = getTotalNewtQuery();
    const data = await newtzero.dataProvider(newtQuery);
    const saveData = data.map((d) => {
      return d.contents[options.dataType];
    });
    let file: File = new File([], asset.name);
    switch (options.extension) {
      case "csv":
        const fileNameCsv = `${asset.name}.csv`;
        file = csvFile.toFile(fileNameCsv, saveData);
        break;
      case "json":
        const fileNameJson = `${asset.name}.json`;
        const jsonBytes = new TextEncoder().encode(JSON.stringify(saveData));
        file = new File([jsonBytes], fileNameJson);
    }
    if (file) {
      await downloadFile(file);
    }
  };

  return (
    <Root>
      <SearchArea>
        <div>
          <Title>Search for metric value</Title>
          <NZSearchFilter metrics={metrics} onSubmit={handleFilterSubmit} />
        </div>

        <ButtonGroup>
          <ImportButton
            title="Upload Data"
            logs={logs}
            onSubmit={uploadFiles}
            onClose={() => {
              if (logs.length > 0) {
                window.location.reload();
              }
            }}
          >
            Upload
          </ImportButton>
        </ButtonGroup>
      </SearchArea>
      <MainViewArea>
        <DataViewArea>
          <NZDataViewer
            data={newtData}
            count={loadablePageCount}
            columns={metrics}
            isLoading={isLoading}
            onDelete={handleDeleteData}
            onDownload={handleDownload}
          />
          <Footer>
            <ASPageNav
              count={totalPage}
              page={page + 1}
              onChange={handlePageChange}
            />
            <StyledInput
              kind="secondary"
              title="limit"
              value={limitStr}
              aria-label="limit"
              placeholder="limit"
              onChange={(e: any) => setLimitStr(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && limitStr !== "") {
                  handleLimitChange(limitStr);
                }
              }}
              endAdornment={<InputSuffix>件</InputSuffix>}
            />
          </Footer>
        </DataViewArea>
        <MetricViewArea>
          <NZMetricViewer
            key={"nz-metric-viewer"}
            metrics={metrics}
            isLoading={isLoading}
          />
        </MetricViewArea>
      </MainViewArea>
    </Root>
  );
};
