//児童詳細のAI画面の検査に関わった項目上位のランキングテーブルのコンポーネント

import React from "react";
import styled, { useTheme } from "styled-components";
import { ASSliderVis } from "allegro-ui";
import { AttentionData } from "src/fortress/models";
import { HiroshimaLogo } from "../atoms/logo";

const Table = styled.div`
  width: 100%;
  display: table;
  /* table-layout: fixed; */
  > th,
  td {
    /* width: 50%; */
    /* display: block; */
    padding: 12px;
    font-size: ${(props) => props.theme.regularFontSizeMd}px;
  }
  tr {
    vertical-align: middle;
  }
  th {
    padding: 12px;
    text-align: left;
    font-weight: bold;
    &:last-child {
      text-align: right;
    }
  }
  td {
    &:first-child {
      text-align: left;
    }
  }
`;

type Props = {
  rankingData: AttentionData[];
  color: string;
};

const SliderValue = styled.div`
  display: flex;
  align-items: center;
  > *:last-child {
    margin-left: 16px;
  }
`;
const Flex = styled.div`
  display: flex;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const FlexTop = styled.div`
  display: flex;
  margin-top: 3px;
  margin-bottom: 6px;
  font-weight: bold;
`;

const CellOne = styled.div`
  flex: 1;
  padding-left: 20px;
`;

const CellTwo = styled.div`
  flex: 2;
  text-align: center;
  margin-right: 25px;
`;

const CellThree = styled.div`
  flex: 1;
`;

const CellFour = styled.div`
  flex: 1;
  text-align: center;
  padding-right: 30px;
`;

const CellFive = styled.div`
  flex: 1;
  padding-left: 20px;
`;

const Caption = styled.div`
  font-size: 14px;
  color: #999999;
`;

const Container = styled.div`
  text-align: center;
  font-size: 26px;
  padding-top: 100px;
  padding-bottom: 80px;
  color: #dddddd;
`;

export const FTRankingTable: React.FC<Props> = (props) => {
  const { rankingData, color } = props;

  //テーマによって色を変えるためにこの処理を書く。
  const theme = useTheme();
  const logoColor = theme.darkMode ? "#dddddd" : "#dddddd";

  //ランキングデータが存在しなければ、データがありませんとしっかりと知らせる処理を書く。

  const aiLargeTable =
    rankingData.length > 0 ? (
      <>
        <FlexTop>
          <CellFive>業務</CellFive>
          <CellTwo>項目</CellTwo>
          <CellFour>重要度</CellFour>
        </FlexTop>
        {rankingData.map((data, id) => {
          return (
            <Flex key={`k-${id}-$}d.colName`}>
              <CellOne>
                <div>{data.label}</div>
                <Caption>{data.file}</Caption>
              </CellOne>
              <CellTwo>{data.value}</CellTwo>
              <CellThree>
                <SliderValue>
                  <ASSliderVis value={data.risk} color={color}></ASSliderVis>
                  <div>{data.risk}</div>
                </SliderValue>
              </CellThree>
            </Flex>
          );
        })}
      </>
    ) : (
      <>
        <Container>
          <HiroshimaLogo size={100} color={logoColor} />
          <p>データがありません</p>
        </Container>
      </>
    );

  return (
    <div>
      <Table>{aiLargeTable}</Table>
    </div>
  );
};
