import React, { useState, useEffect, useCallback } from "react";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import styled, { DefaultTheme } from "styled-components";

import { FormLabel } from "@material-ui/core";
import {
  ASSelect,
  ASButton,
  ASInputBase,
  ASCard,
  ASIconButton,
} from "allegro-ui";
import { MonitorThumb, SchemaType, isSchemaType } from "src/kinzaza/models";

import { produce } from "immer";
import {
  cursorMonitorThumbIdState,
  currentMonitor,
  monitorListRequestId,
  dataFetchCountState,
} from "src/kinzaza/recoils";
import { NZSchemaTypeChip } from "src/newtzero-console/components/atoms/NZSchemaTypeChip";
import SettingsIcon from "@material-ui/icons/Settings";

const StyledCard = styled(ASCard)`
  width: 100%;
  height: 550px;
  padding: 20px 20px;
`;

const LeftRightContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  align-items: center;
  > *:last-child {
    margin-left: auto;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 0px;
  width: 100%;
  height: 30px;
  bottom: 20px;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 20px;
  letter-spacing: 0.1rem;
  color: ${(props) => props.theme.textColor};
`;

const EditButton = styled(ASIconButton)`
  width: 35px;
  height: 35px;
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  > * {
    margin-left: 8px;
  }
`;

const NameSchemaContainer = styled.div`
  height: 150px;
`;

const FormContainer = styled.div`
  margin-bottom: 12px;
`;

const StyledFormLabel = styled(FormLabel)`
  margin-bottom: 3px;
  color: ${(props) => props.theme.textColor};
  display: inline-block;
  width: 100%;
`;

const StyledASInputBase = styled(ASInputBase)`
  width: 100%;
  max-height: 280px;
  padding: 12px;
  border-radius: 8px;
`;

const QueryASInputBase = styled(StyledASInputBase)`
  overflow-y: scroll;
  position: relative;
  height: 300px;
  .MuiInputBase-input {
    position: absolute;
    top: 0;
  }
`;

const StyledASSelect = styled(ASSelect)`
  padding: 4px 8px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  > .MuiFormLabel-root {
    color: ${(props) => props.theme.textColor};
  }
  > .MuiInputLabel-root {
    color: ${(props) => props.theme.textColor};
  }
`;

const StyledASButton = styled(ASButton)`
  height: 24px;
`;

const ErrorField = styled.div`
  font-size: 14px;
  margin-top: 4px;
  color: ${(props) => props.theme.negativeColor};
`;

const schemaSelections = [
  { value: "TopData", label: "TopData" },
  { value: "RawData", label: "RawData" },
  { value: "TxnData", label: "TxnData" },
];

type Props = {
  show: boolean;
  onClose?: () => void;
  onSubmit?: (formData: MonitorThumb) => void;
  theme?: DefaultTheme;
};

export const KZQueryForm: React.FC<Props> = (props) => {
  const { onClose } = props;
  const [editMode, setEditMode] = useState(false);
  const [queryErrMsg, setQueryErrMsg] = useState("");

  const [cursorMonitorId] = useRecoilState(cursorMonitorThumbIdState);
  const [monitor, setMonitor] = useRecoilState(currentMonitor);
  const [name, setName] = useState("");
  const [queryStr, setQueryStr] = useState("");
  const [schemaType, setSchemaType] = useState<SchemaType>(monitor.schemaType);
  const [reqId, setReqId] = useRecoilState(monitorListRequestId);

  const dataCount = useRecoilValueLoadable(dataFetchCountState);

  const handleSubmit = useCallback(() => {
    const monitorEdit = produce(monitor, (draft) => {
      draft.name = name;
      draft.query = queryStr;
      draft.schemaType = schemaType;
    });

    setReqId(reqId + 1);
    setMonitor(monitorEdit);
  }, [monitor, name, queryStr, reqId, schemaType, setMonitor, setReqId]);

  const handleEditMode = () => {
    if (!editMode === true) {
      validateQuery(queryStr);
    }
    setEditMode(!editMode);
  };

  const formatQuery = (query: string) => {
    const queryObj = JSON.parse(query);
    return JSON.stringify(queryObj, null, 2);
  };

  const validateQuery = (query: string) => {
    try {
      const queryObj = JSON.parse(query);

      if (!("asset" in queryObj) && !("collection" in queryObj)) {
        setQueryErrMsg("assetかcollection指定してください");
        return;
      }
      if (!("primaryKey" in queryObj)) {
        setQueryErrMsg("primaryKeyを指定してください");
        return;
      }

      if (!("shape" in queryObj)) {
        setQueryErrMsg("shapeを指定してください");
        return;
      }

      if (Object.keys(queryObj.shape).length === 0) {
        setQueryErrMsg("shapeが空です");
        return;
      }
      setQueryErrMsg("");
    } catch (err) {
      setQueryErrMsg("クエリが無効です");
      return;
    }
  };

  const didMount = useCallback(() => {
    setName(monitor.name);
    setQueryStr(
      typeof monitor.query === "string"
        ? monitor.query
        : JSON.stringify(monitor.query, null, 2)
    );
    setSchemaType(monitor.schemaType);
  }, [monitor]);

  useEffect(() => {
    didMount();
  }, [cursorMonitorId, didMount]);

  return (
    <StyledCard>
      <LeftRightContainer>
        <NameSchemaContainer>
          {!editMode ? (
            <>
              <Header>
                <Title>{name}</Title>
                <EditButton kind="primary" onClick={() => handleEditMode()}>
                  <SettingsIcon fontSize="small" />
                </EditButton>
              </Header>
              <FormContainer>
                <NZSchemaTypeChip>{schemaType}</NZSchemaTypeChip>
              </FormContainer>
            </>
          ) : (
            <>
              <FormContainer>
                <StyledFormLabel>名前</StyledFormLabel>
                <StyledASInputBase
                  multiline
                  placeholder="入力してください"
                  fullWidth
                  defaultValue={name}
                  kind="secondary"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </FormContainer>
              <FormContainer>
                <StyledASSelect
                  label={"スキーマ"}
                  data={schemaSelections}
                  value={schemaType}
                  onChange={(e) => {
                    if (isSchemaType(e.target.value)) {
                      setSchemaType(e.target.value);
                    } else {
                      console.log("unvalid");
                    }
                  }}
                ></StyledASSelect>
              </FormContainer>
            </>
          )}
        </NameSchemaContainer>
        <FormContainer>
          <StyledFormLabel>クエリ</StyledFormLabel>
          <QueryASInputBase
            multiline
            placeholder="入力してください"
            // fullWidth
            disabled={!editMode}
            value={queryStr}
            kind="secondary"
            onChange={(e) => {
              setQueryStr(e.target.value);
              validateQuery(e.target.value);
            }}
          />
          {editMode && queryErrMsg !== "" ? (
            <ErrorField>{queryErrMsg}</ErrorField>
          ) : (
            <></>
          )}
        </FormContainer>
        <Footer>
          {!editMode ? (
            <>
              {dataCount.state === "hasError" ? (
                <>
                  <ErrorField>データの取得に失敗しました</ErrorField>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <ButtonGroup>
              <StyledASButton
                kind={"secondary"}
                onClick={() => {
                  if (onClose) onClose();
                  setEditMode(false);
                }}
              >
                Cancel
              </StyledASButton>
              <StyledASButton
                kind={"primary"}
                onClick={() => {
                  setQueryStr(formatQuery(queryStr));
                  // onSubmit();
                }}
              >
                Format
              </StyledASButton>
              <StyledASButton
                kind={"primary"}
                disabled={queryErrMsg !== ""}
                onClick={() => {
                  handleSubmit();
                  if (onClose) onClose();
                  setEditMode(false);
                }}
              >
                Save
              </StyledASButton>
            </ButtonGroup>
          )}
        </Footer>
      </LeftRightContainer>
    </StyledCard>
  );
};
