import { AxiosInstance } from "axios";
import { getOne, getMany } from "./fetch";
import { wrapAsync } from "../../libs/wrapper";
import { save } from "./save";

export const Asset = (instance: AxiosInstance) => {
  return {
    getOne: wrapAsync(instance, getOne),
    getMany: wrapAsync(instance, getMany),
    save: wrapAsync(instance, save),
  };
};
