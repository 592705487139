import produce from "immer";

export type GroupInput = {
  group_name: string;
  description?: string;
};

export type Group = {
  group_id: string;
  group_token: string;
  users: string[];
  created: Date;
  modified: Date;
  userNo: number;
} & GroupInput;

const defaultGroup: Group = {
  group_id: "",
  group_token: "",
  group_name: "",
  users: [],
  created: new Date(),
  modified: new Date(),
  userNo: -1,
};

export const createGroupFromOptimizedData = (data: any) => {
  return produce(defaultGroup, (draft) => {
    draft.group_id = data.contents.rawdata.group_id ?? draft.group_id;
    draft.group_token = data.contents.rawdata.group_token ?? draft.group_token;
    draft.group_name = data.contents.rawdata.group_name ?? draft.group_name;
    draft.description = data.contents.rawdata.description ?? draft.description;
    draft.users = data.contents.rawdata.users ?? draft.users;
    draft.created = data.bases.created ?? draft.created;
    draft.modified = data.bases.modified ?? draft.modified;
    draft.userNo = data.bases.userNo ?? draft.userNo;
  });
};
