import { useState } from "react";
import { useCallback } from "react";
import { MonitorThumb } from "../models";
import * as kinzazaApi from "../api";
import { produce } from "immer";
import { format } from "date-fns";
import { newtOut } from "src/medican/api";

export const useMonitorThumb = (monitor: MonitorThumb) => {
  const [self, setSelf] = useState(monitor);

  const [isLoading, setLoading] = useState(false);
  const [errMsg, setErr] = useState("");

  const updateSelf = useCallback(async (newMonitor: MonitorThumb) => {
    const resData = await kinzazaApi.saveMonitor(newMonitor);
    if (resData) {
      setSelf(resData);
    }
  }, []);

  const syncCount = useCallback(async () => {
    setLoading(true);
    setErr("");
    const { query } = self;

    let parsedQ: any = null;
    try {
      parsedQ = query ? JSON.parse(query) : {};
    } catch (err) {
      console.error(err);
      setErr("クエリが間違っています");
      setLoading(false);
    }

    if (!parsedQ || !parsedQ.shape) {
      return;
    }

    let queryObj = {
      shape: {},
      ...parsedQ,
      order: {
        _modified: -1,
      },
      limit: 1,
      skip: 0,
    };
    queryObj.shape = {
      ...queryObj.shape,
      _modified: "any",
    };

    let dataCount = 0;
    let lastDataDate = "none";
    try {
      const [countRes, count] = await newtOut(queryObj, { count: true });
      const [dataRes, _] = await newtOut(queryObj);

      dataCount = count ?? 0;

      if (dataRes.length > 0) {
        const modified =
          dataRes[0].bases?.modifiedUnixTimeMS ?? dataRes[0]._modified;
        console.log({ modified });
        const latestDate =
          modified && modified > 10 ** 12
            ? new Date(modified)
            : new Date(modified * 1000);
        lastDataDate = format(latestDate, "yyyy/MM/dd HH:mm:ss") ?? "none";
      }
    } catch (err) {
      // topdata APIを使っているとここでエラーが発生する
      console.log(err);
    }

    const nextSelf = produce(self, (draft) => {
      draft.lastDataDate = lastDataDate;
      draft.totalCount = dataCount;
      draft.lastCountUpdateDate = format(new Date(), "yyyy/MM/dd HH:mm:ss");
    });

    setLoading(false);
    await updateSelf(nextSelf);
  }, [self, updateSelf]);

  return { ...self, syncCount, isLoading, errMsg };
};
