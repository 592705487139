import * as authHooks from "./hooks/useAuth";
import * as provider from "./libs/provider";

export { useAuth } from "./hooks/useAuth";
export { AuthProvider } from "./libs/provider";

namespace auth {
  export const useAuth = authHooks.useAuth;
  export const AuthProvider = provider.AuthProvider;
}

export default auth;
