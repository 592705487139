import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Link = styled.span`
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

export interface ILCTextLinkCellComponentProps {
  data: {
    path: string;
    // id: string;
    label: string;
  };
}

const parsePath = (path: string, data: { [key: string]: any }): string => {
  const regex = /{([^}]+)}/g;
  return path.replace(regex, (_, match) => data[match]);
};

export default function LCTextLinkCellComponent(
  props: ILCTextLinkCellComponentProps
) {
  const history = useNavigate();
  const handleLink = () => {
    const path = parsePath(props.data.path, props.data);
    history(path);
  };

  return (
    <div onClick={handleLink}>
      <Link>{props.data.label ?? "--"}</Link>
    </div>
  );
}
