import React, { ReactNode } from "react";
import { useContext, useState } from "react";

export type Word = {
  character: {
    target: string;
    object: string;
    answer: string;

    accountUser: string;
    accountAdmin: string;
  };
  operation: {
    create: string;
    delete: string;
    edit: string;
  };
};

type Props = {
  childList: {
    page: number;
    tabIndex: number;
    handlePage: (page: number) => void;
  };
  logo?: any;
  words?: Word;
};

const defaultValue: Props = {
  childList: {
    page: 1,
    tabIndex: 0,
    handlePage: (page: number) => {},
  },
};

const UIContext = React.createContext<Props>(defaultValue);

type UIContextProviderProps = {
  children: ReactNode;
  logo?: any;
  words?: Word;
};

export const UIContextProvider: React.FC<UIContextProviderProps> = ({
  children,
  words,
  logo,
}) => {
  const [page, setPage] = useState(defaultValue.childList.page);
  const tabIndex = defaultValue.childList.tabIndex;

  const handlePage = (p: number) => {
    setPage(p);
  };

  const value = {
    childList: {
      page,
      tabIndex,
      handlePage,
    },
    logo,
    words,
  };
  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

export const useUIContext = () => {
  return useContext(UIContext);
};
