import { atom, selector } from "recoil";

import {
  DailyThumb,
  createDailyThumbFromTopDataRes,
  SortDailyThumbType,
  createEmptyDailyThumb,
  InitialDailyThumb,
} from "src/kinzaza/models";
import { createNewtZeroAPI } from "src/api";
import { datePickerState, currentMonitor } from ".";
import { endOfDay, startOfDay, sub, format } from "date-fns";
import { authState } from "src/root/recoils/userState";

// データのソート方法
export const dailyThumbListSortState = atom<SortDailyThumbType>({
  key: "dailyThumbListSortState",
  default: "作成日",
});

// DataViewのデータ総件数
export const dataFetchCountState = selector<number>({
  key: "dataFetchCountState",
  get: async ({ get }) => {
    const auth = get(authState);
    const { query } = get(currentMonitor);
    const queryObj = typeof query === "string" ? JSON.parse(query) : query;
    const newtzero = createNewtZeroAPI();
    const countRes = await newtzero.dataProvider(queryObj, { count: "true" });
    const dataCount = countRes[0]?.totalDataCount;
    return dataCount;
  },
});

// グラフの日付の期間
export const fetchingDaysCountState = atom<number>({
  key: "fetchingDaysCountState",
  default: 30,
});

export const makeTimeQuery = (
  start: Date,
  end: Date,
  sortFilter: SortDailyThumbType
) => {
  const timeKey = sortFilter === "作成日" ? "_created" : "_modified";
  const basesTimeKey =
    sortFilter === "作成日"
      ? "bases.createdUnixTimeMS"
      : "bases.modifiedUnixTimeMS";
  return {
    $or: [
      {
        [timeKey]: {
          $gte: start.getTime() / 1000,
          $lte: end.getTime() / 1000,
        },
      },
      {
        [timeKey]: {
          $gte: start.getTime(),
          $lte: end.getTime(),
        },
      },
      {
        [basesTimeKey]: {
          $gte: start.getTime(),
          $lte: end.getTime(),
        },
      },
    ],
  };
};

// グラフデータ
export const monthlyDataCountListState = selector<Array<DailyThumb>>({
  key: "monthlyDataCountListState",
  get: async ({ get }) => {
    const auth = get(authState);
    const { query } = get(currentMonitor);
    const dataFetchCount = get(dataFetchCountState);
    const fetchingDaysCount = get(fetchingDaysCountState);
    const sortFilter = get(dailyThumbListSortState);

    const monitorQuery = typeof query === "string" ? JSON.parse(query) : query;

    const contractor = auth.contractorNo;
    const userId = auth.userId;

    const endDate = get(datePickerState);
    const ed = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    );

    //現在の日にちから３０日間の日付リストを作成する。
    const previousDays: number[] = [];
    for (let i = 0; i < fetchingDaysCount; i++) {
      const beforeDay = sub(ed, { days: i });
      previousDays.push(Number(format(beforeDay, "T")));
    }

    if (dataFetchCount === 0) {
      return previousDays.map((day) => createEmptyDailyThumb(day));
    }

    if (!contractor || !userId) {
      console.error("contractorNo or user is unset ");
      return previousDays.map((day) => createEmptyDailyThumb(day));
    }

    const nextThumbs: DailyThumb[] = [];

    for (let i = 0; i < previousDays.length; i++) {
      //この日の０時を計算する
      const unixTime = previousDays[i];
      const start = startOfDay(unixTime);

      //次の日の０時を計算する。
      const end = endOfDay(unixTime);

      const timeQuery = makeTimeQuery(start, end, sortFilter);
      if (Object.keys(monitorQuery.shape).length === 0) {
        return [];
      }

      let queryObj = {
        search: {},
        ...monitorQuery,
        shape: {
          "bases.createdUnixTimeMS": "any",
          "bases.modifiedUnixTimeMS": "any",
          _created: "any",
          _modified: "any",
        },
        order: {
          _modified: -1,
        },
      };

      queryObj.search = {
        ...queryObj.search,
        ...timeQuery,
      };

      const newtzero = createNewtZeroAPI();
      try {
        // その日のデータこすうを取得
        const countRes = await newtzero.dataProvider(queryObj, {
          count: true,
          newtQuery: false,
        });

        // その日の最新データを取得
        const res = await newtzero.dataProvider({
          ...queryObj,
          limit: 1,
          skip: 0,
        });

        const created = res[0]?.bases?.createdUnixTimeMS ?? res[0]?._created;
        const modified = res[0]?.bases?.modifiedUnixTimeMS ?? res[0]?._modified;

        const createTime: number = created ?? start.getTime();
        const modifiedTime: number = modified ?? start.getTime();
        const startDateTime =
          createTime && createTime > 10 ** 12
            ? new Date(createTime)
            : new Date(createTime * 1000);
        const endDateTime =
          modifiedTime && modifiedTime > 10 ** 12
            ? new Date(modifiedTime)
            : new Date(modifiedTime * 1000);

        const count = countRes[0]?.dataTotalCount ?? 0;
        const timeData = {
          _created: startDateTime,
          _modified: endDateTime,
        };

        const data = createDailyThumbFromTopDataRes({
          startDate: start,
          endDate: end,
          resData: timeData,
          count: count,
        });
        nextThumbs.push(data);
      } catch (err) {
        console.error(err);
      }
    }

    return nextThumbs;
  },
});

export const cursorDailyThumbState = atom<DailyThumb>({
  key: "cursorDailyThumbState",
  default: InitialDailyThumb,
});
