import React from "react";
import styled from "styled-components";

const Block = styled.div`
  max-height: 80vh;
  overflow: scroll;
  padding: 16px;
  line-height: 1.5;
`;

const H1 = styled.h1`
  font-size: 24px;
  font-weight: bold;
  line-height: 2.5;
`;

const H2 = styled.h2`
  margin-top: 24px;
  font-size: 18px;
  font-weight: bold;
  line-height: 2.2;
`;

type Props = {};

export function NTPrivacyPolicy(props: Props) {
  return (
    <Block>
      <H1>プライバシーポリシー</H1>
      <p>
        アレグロスマート株式会社（以下、アレグロスマート）は、お客様により良い商品・サービスをご提供するにあたり、お客様の個人情報を、お客様の大切な資産であると認識し、その保有および利用にあたっては、以下に定めるプライバシーポリシーに基づき個人情報保護に努め、お客様の信頼に応えます。
        <ul>
          <li>運用開始日：2023年9月3日 アレグロスマート株式会社</li>
          <li>代表取締役社長　田渕　鳴利</li>
        </ul>
        アレグロスマートは、お客様の個人情報を安全に保管し、お客様の意思を尊重した利用・取り扱いを行う環境を築き、お客様からの信頼をいただくと共に、その環境の下でお客様にデータの有効利用による社会変革をもたらし、お客さまの所望するデータの有効利用を実現するサービスの数々を提供し続けたいと願っています。
        アレグロスマートは、かかる方針のもと、誠実で公正であるというアレグロスマートの理念に従い、以下の通り個人情報の取り扱いに関するポリシーを定め、収集したお客様に関する個人情報の適切な取り扱いに取り組んでまいります。
      </p>
      <H2>個人情報の取り扱いについて (個人情報の定義)</H2>
      <ul>
        <li>
          1.本ポリシーにおいて「個人情報」とは、個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別できるものまたは個人識別符号(*注釈)が含まれるものをいいます。収集したお客様に関する情報には、単独のままでは特定の個人を識別できない情報もありますが、他の情報と組み合わせることにより、特定の個人を識別できる場合、かかる情報は組み合わせて扱われる範囲においてすべて「個人情報」として扱われます。
          (法令の遵守)
        </li>
        <li>
          2.アレグロスマートは、個人情報を取り扱う際に、個人情報の保護に関する法律その他個人情報保護に関する諸法令に関し個人情報保護委員会および所管官庁が公表するガイドライン類に定められた義務、ならびに本ポリシーを遵守します。
          (利用目的の範囲内での利用)
        </li>
        <li>
          3.アレグロスマートは、あらかじめご本人の同意を得た場合、または法令により認められた場合を除き、あらかじめ特定された利用目的の達成に必要な範囲内でのみ個人情報を取り扱い、およびそのための措置を講じます。
          (個人情報の取得)
        </li>
        <li>
          4.アレグロスマートは、個人情報を取得する際は、あらかじめ取り扱う個人情報の項目、利用目的、お問い合わせ窓口等の必要な情報を明示し、ご本人の同意を得るよう努めます。個人情報に人種・信条等の要配慮個人情報が含まれる場合には、法令により認められた場合を除き、ご本人の同意を得ることなく個人情報を取得しません。第三者から個人情報を取得する場合であって、法令上、第三者提供を受ける際の確認義務および記録作成義務が発生する場合には、これを遵守します。
          (15歳未満のお客様の個人情報)
        </li>
        <li>
          5.アレグロスマートは、15歳未満のお客様に関する個人情報の収集、保管および使用に適用される法令のすべてを遵守するよう努めます。もし、お子様が保護者または後見人の同意なく個人情報をアレグロスマートに提供したことに気付いた場合、保護者または後見人におかれましては、本ポリシーに定める問い合わせ窓口までご連絡ください。
          (安全管理措置)
        </li>
        <li>
          6.アレグロスマートは、個人情報を利用目的の範囲内で正確・完全・最新の内容に保つよう努め、不正なアクセス、漏えい、改ざん、滅失、き損等を防止するため、現時点での技術水準に合わせた必要かつ適切な安全管理措置を講じ、必要に応じて是正してまいります。
          (委託先の監督)
        </li>
        <li>
          7.アレグロスマートは、利用目的の達成に必要な範囲内において、個人情報の取り扱いを他の協力会社または第三者に委託する場合があります。その場合、アレグロスマートの情報セキュリティポリシーの下、適切な安全管理措置を講じます。また、第三者への業務委託に関しては、個人情報の取り扱いにつき、契約等を通じて、しかるべき安全管理が図られるようにします。
          外国にある第三者に業務委託を行う場合であって、法令上、記録作成義務が発生する場合には、これを遵守します。
          (第三者への提供)
        </li>
        <li>
          8.アレグロスマートは、法令により認められた場合を除き、ご本人の同意を得ることなく、第三者に個人情報を提供しません。第三者に個人情報を提供する場合であって、法令上、第三者提供を行う際の記録作成義務が発生する場合には、これを遵守します。
          (ご相談への対応)
        </li>
        <li>
          9.アレグロスマートは、個人情報の開示、訂正、利用（商品やサービスの紹介等）の停止、消去その他の個人情報の取り扱いに関するお問い合わせに対し、法令の規定に基づき、適切に対応いたします。アレグロスマートが提示する窓口までご連絡ください。
          (体制強化、教育等)
        </li>
        <li>
          10.アレグロスマートは、個人情報を適切に取り扱うために、個人情報の管理責任者の設置、内部規程の整備、役員および従業員への教育ならびに適正な内部監査の実施等を通じて、本ポリシーの見直しを含めた社内体制の継続的強化・改善に努めます。
          ＊注釈（個人識別符号は、個人情報保護委員会の政令に準じます。現在の定義には次を含みます：(1)（イ）ＤＮＡを構成する塩基の配列、（ロ）顔の骨格及び皮膚の色並びに目、鼻、口その他の顔の部位の位置及び形状によって定まる容貌、（ハ）虹彩の表面の起伏により形成される線状の模様（二）発声の際の声帯の振動、声門の開閉並びに声道の形状及びその変化、（ホ）歩行の際の姿勢及び両腕の動作、歩幅その他の歩行の態様、（へ）手のひら又は手の甲若しくは指の皮下の静脈の分岐及び端点によって定まるその静脈の形状、（ト）指紋又は掌紋、(2)
          旅券の番号、基礎年金番号、運転免許証の番号、住民票コード及び個人番号、並びに
          (3)
          国民健康保険、後期高齢者医療制度及び介護保険の被保険者証にその発行を受ける者ごとに異なるものとなるように記載された文字、番号、記号その他の符号）。
        </li>
      </ul>
    </Block>
  );
}
