import * as React from "react";

import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { CHTabs } from "../molecules/CHTabs";
import { serverCreatingFormDefinition } from "src/cheetah/definitions/forms/serverCreatingFormDefinition";
import { useServerDetail } from "src/newtroid-console/hooks/useServerDetail";

interface Props {
  itemId: string;
}

const CHServerSinglePageTemplate: React.FunctionComponent<Props> = ({
  ...props
}) => {
  const { serverDetail, canUpdateServer } = useServerDetail();

  const tabs = [
    {
      label: "編集",
      component: (
        <React.Suspense fallback={<>loading</>}>
          <ASLowCodeFormContainer
            formDefinition={serverCreatingFormDefinition}
            editable={canUpdateServer}
            fullWidth
            isUpdate
            defaultValues={serverDetail}
          />
        </React.Suspense>
      ),
    },
    // {
    //   label: "履歴",
    //   component: <>ama</>,
    // },
  ];
  return (
    <>
      {serverDetail ? (
        <CHTabs tabs={tabs} title={serverDetail.non_numeric_server_name} />
      ) : (
        <>loading</>
      )}
    </>
  );
};

export default CHServerSinglePageTemplate;
