import * as React from "react";
import { CircleLayout, Container, LogArea, Title } from "./styles";
import { ManifestV1 } from "src/newtroid-console/models/ManifestV1";
import { useLogArea } from "./hooks/useLogArea";
import { Slot } from "src/xerver-room/models";
import { api, createnXtalAPI } from "src/api";
import { useRecoilValue } from "recoil";
import { cursorPipelineIdState, slotListState } from "src/xerver-room/recoils";
import produce from "immer";
import { handleAxiosError } from "./utils/registerNewSlots";
import { ASButton, ASLoadingCircle } from "allegro-ui";
import styled from "styled-components";
import {
  LinkObject,
  useLinkObjectList,
} from "src/xerver-room/hooks/useLinkObjectList";

const StyledASButton = styled(ASButton)`
  float: right;
`;
export interface INTXerviceImportFormStep2Props {
  manifest?: ManifestV1;
}

const applyUiOffset = (
  obj: Slot | LinkObject,
  uiOffset: number[],
  id: number
) => {
  const modifiedUi = obj.ui
    ? produce(obj.ui, (draft: any) => {
        draft.x += uiOffset[0];
        draft.y += uiOffset[1];
      })
    : {
        x: uiOffset[0] + Math.floor(id / 5) * 400,
        y: uiOffset[1] + (id % 5) * 200,
      };

  return modifiedUi;
};

/**
 * SlotのフィールドをCreateで登録可能なデータだけにフィルターする。
 * @param obj
 * @param contractor
 * @param pipelineId
 * @param uiOffset
 * @param id もしuiがない場合、idに従ってy軸にoffsetします。
 * @returns
 */
function makePostData(
  obj: any,
  contractor: string,
  pipelineId: string,
  uiOffset: number[],
  id: number
) {
  const result: { [key: string]: any } = {};

  const modifiedUi = applyUiOffset(obj, uiOffset, id);

  const shouldFilter: { [key: string]: any } = {
    name: obj.name,
    contractor: contractor,
    pipelineId: pipelineId,
    optXId: obj.optXId,
    ui: modifiedUi,
    environments: obj.environments,
    volume: obj.volume,
  };

  /**
   * null, undefined, 空欄のデータは削除する。
   */
  for (const key in shouldFilter) {
    if (
      shouldFilter[key] !== null &&
      shouldFilter[key] !== undefined &&
      shouldFilter[key] !== "" &&
      key !== "id" &&
      key !== "slotId"
    ) {
      result[key] = shouldFilter[key];
    }
  }

  return result;
}

/**
 * オフセットするx,y値を計算し、取得する。
 * @param slots
 * @returns
 */
const calcXYOffset = (
  currentSlots: Slot[],
  currentLinkObjects: LinkObject[],
  slotsToAdd: Slot[],
  linkObjectsToAdd: LinkObject[]
): number[] => {
  const defaultOffsetX = 500;

  let xMax = 0;
  let yMax = 0;
  for (const slot of currentSlots) {
    if (slot.ui.x > xMax) xMax = slot.ui.x;
    if (slot.ui.y > yMax) yMax = slot.ui.y;
  }

  for (const lo of currentLinkObjects) {
    if (lo.ui.x > xMax) xMax = lo.ui.x;
    if (lo.ui.y > yMax) yMax = lo.ui.y;
  }

  let xMin: number | undefined = undefined;
  let yMin: number | undefined = undefined;
  for (const slot of slotsToAdd) {
    if (!slot.ui) continue;

    if (!xMin) xMin = slot.ui.x;
    if (!yMin) yMin = slot.ui.y;

    if (slot.ui.x < xMin) xMin = slot.ui.x;
    if (slot.ui.y < yMin) yMin = slot.ui.y;
  }

  for (const lo of linkObjectsToAdd) {
    if (!lo.ui) continue;

    if (!xMin) xMin = lo.ui.x;
    if (!yMin) yMin = lo.ui.y;

    if (lo.ui.x < xMin) xMin = lo.ui.x;
    if (lo.ui.y < yMin) yMin = lo.ui.y;
  }

  if (!xMin) xMin = 0;
  if (!yMin) yMin = 0;

  return [xMax - xMin + defaultOffsetX, 0 - yMin];
};

/**
 * Xerviceマニフェストをインポートするためのフォーム
 * フォームの選択をしそのファイルのバリデーションを行います。
 * @param props
 * @returns
 */
export function NTXerviceImportFormStep2(
  props: INTXerviceImportFormStep2Props
) {
  const [isProgress, setIsProgress] = React.useState(true);

  const { logs, addLogLine } = useLogArea();

  const [isCalled, setIsCalled] = React.useState(false);

  const slots = useRecoilValue(slotListState);

  const linkObjectHandler = useLinkObjectList();

  console.log({ slots });

  const nxtalApi = createnXtalAPI();
  const currentPipelineId = useRecoilValue(cursorPipelineIdState);

  /**
   * newtroid xervice マニフェストをインポートします。
   */
  const importManifest = React.useCallback(
    async (manifest: ManifestV1) => {
      if (isCalled) return;
      if (!currentPipelineId) {
        return;
      }
      setIsCalled(true);
      /**
       * マニフェストのバージョンに応じた処理を行う。
       */
      switch (manifest.version) {
        case 1:
          let errorCount = 0;
          console.log({ manifes: manifest.xervices });

          let slotIdMap: { [key: string]: string } = {};

          let linkObjectIdMap: { [key: string]: string } = {};

          const linkObjects = await linkObjectHandler.fetchList();

          const xy = calcXYOffset(
            slots,
            linkObjects,
            manifest.xervices,
            manifest.linkObjects
          );

          /**
           * 各種LinkObjectのインポート
           */
          //LinkObjectを新規作成
          for (const [id, _lo] of manifest.linkObjects.entries()) {
            const linkObject = _lo as LinkObject;
            addLogLine(`${linkObject.linkObjectId}をインポートします。`);

            const pos = applyUiOffset(linkObject, xy, id);
            const postData = {
              ...linkObject,
              pipelineId: currentPipelineId,
              ui: {
                x: pos.x,
                y: pos.y
              },
            };
            const newId = await linkObjectHandler.addForImporter(postData);

            if (newId) linkObjectIdMap[linkObject.linkObjectId] = newId;
          }

          /**
           * 各種Xerviceのインポート
           */
          for (const [id, _slot] of manifest.xervices.entries()) {
            const slot = _slot as Slot;
            /**
             * OptXの存在と権利の確認
             * - OptXがコントラクタ上に存在してない場合はエラーとし、インポート不可能です。
             */
            addLogLine(`${slot.id}のOptX${slot.optXId}の確認しています。`);
            try {
              const contractor = sessionStorage.contractorNo;
              if (contractor && slot.optXId) {
                const optx = await nxtalApi.optx.getOne(
                  contractor,
                  slot.optXId
                );
                if (!optx) {
                  addLogLine(
                    `${slot.id}のOptXが見つかりませんでした。権限がないか、データがありません。`,
                    "negative"
                  );
                } else if (optx) {
                  /**
                   * Xerviceを作成します。
                   */
                  addLogLine(`${slot.id}のインポートを開始します。`);

                  const postData = makePostData(
                    slot,
                    contractor,
                    currentPipelineId,
                    xy,
                    id
                  );

                  /**
                   * Xervice(Slot)を登録
                   */
                  const res = await api({
                    method: "POST",
                    url: `/xervice/`,
                    params: {
                      apikey: sessionStorage.token,
                    },
                    data: postData,
                  });
                  if (res.status === 200) {
                    addLogLine(`${slot.slotId}を作成しました。`, "positive");
                    slotIdMap[slot.slotId] = res.data.slotId;
                  } else {
                    addLogLine(`${slot.id}の作成に失敗しました。。`);
                  }
                }
              } else {
                console.error({ contractor, optx: slot.optXId });
              }
            } catch (error: any) {
              handleAxiosError(error);
            }
          }
          /**
           * 接続情報の更新
           */
          addLogLine(`input/ouputの置き換えを開始します。`);
          for (const [id, _slot] of manifest.xervices.entries()) {
            const slot = _slot as Slot;
            if (!slotIdMap[slot.slotId]) {
              addLogLine(
                `${slot.id}は登録に失敗しています。処理をスキップします。`,
                "warn"
              );
              continue;
            }
            try {
              const idReplacedSlot: Slot = produce(slot, (draft) => {
                draft.outputs.forEach((output, i) => {
                  output.targets.forEach((target, j) => {
                    const oldSlotId = draft.outputs[i].targets[j].slotId;
                    /**
                     * LinkObjectに接続している場合
                     */
                    if (oldSlotId.startsWith("LNK")) {
                      if (linkObjectIdMap[oldSlotId]) {
                        draft.outputs[i].targets[j].slotId =
                          linkObjectIdMap[oldSlotId];
                        addLogLine(
                          `${slot.id}のoutputの置き換えに成功しました。`,
                          "positive"
                        );
                      } else {
                        addLogLine(
                          `${slot.id}のoutputの置き換えに失敗しました。`,
                          "negative"
                        );
                      }
                    } else if (oldSlotId.startsWith("SLT")) {
                      if (slotIdMap[oldSlotId]) {
                        draft.outputs[i].targets[j].slotId =
                          slotIdMap[oldSlotId];
                        addLogLine(
                          `${slot.id}のoutputの置き換えに成功しました。`,
                          "positive"
                        );
                      } else {
                        addLogLine(
                          `${slot.id}のoutputの置き換えに失敗しました。`,
                          "negative"
                        );
                      }
                    } else {
                      addLogLine(
                        `${slot.id}のoutputの置き換えに失敗しました。対応してないoutputIDが存在します。`,
                        "negative"
                      );
                    }
                  });
                });
              });

              /**
               * IOを作成
               */
              const res2 = await api({
                method: "PUT",
                url: `/xervice/${slotIdMap[slot.slotId]}`,
                params: {
                  apikey: sessionStorage.token,
                },
                data: {
                  inputs: slot.inputs,
                  outputs: idReplacedSlot.outputs,
                },
              });

              if (res2.status === 200) {
                addLogLine(`${slot.id}の接続情報作成しました。`, "positive");
              } else {
                addLogLine(`${slot.id}の作成に失敗しました。`, "negative");
              }
            } catch (error) {
              addLogLine(`${slot.id}の更新に失敗しました。`, "negative");
              handleAxiosError(error);
            }
          }
          addLogLine(`すべてのインポート操作が終了しました。`);

          setIsProgress(false);
          break;
        default:
          alert(
            "このマニフェストファイルはサポートされてないバージョンで作成されています。"
          );
      }
    },
    [addLogLine, currentPipelineId, isCalled, nxtalApi.optx, slots]
  );

  React.useEffect(() => {
    if (props.manifest) {
      importManifest(props.manifest);
    }
  }, [importManifest, props.manifest]);

  return (
    <Container>
      <Title>インポート中です。</Title>

      <p>インポートを実行中です。画面を閉じないでください。</p>
      <LogArea>
        {logs}
        <CircleLayout>
          <ASLoadingCircle isLoading={isProgress} />
        </CircleLayout>
      </LogArea>

      {isProgress ? (
        <></>
      ) : (
        <>
          <StyledASButton
            kind="primary"
            onClick={() => window.location.reload()}
          >
            画面をリロード
          </StyledASButton>
        </>
      )}
    </Container>
  );
}
