import { selectorFamily, waitForAll } from "recoil";
import {
  userListPageIndexState,
  userNameSearchWordState,
} from "../atoms/filterState";

import {
  matchFilterState,
  extendMatchFilterState,
  searchFilterState,
  sortFilterState,
  newtQueryBase,
  lowCodeVerticalFilterState,
} from "../atoms/filterState";
import { createNewtZeroAPI } from "src/api";

export const newtDataSelectorState = selectorFamily<any, string>({
  key: "low-code-newtdata-selector",
  get:
    (key) =>
    async ({ get }) => {
      //matchfilterにサーチフィルターを混ぜる。
      const [pageIndex, matchFilter, searchFilter, extendMatchFilter] = get(
        waitForAll([
          userListPageIndexState(key),
          matchFilterState(key),
          searchFilterState(key),
          extendMatchFilterState(key),
        ])
      );

      const lcVerticalFilter = get(lowCodeVerticalFilterState(key));
      const sortQuery = get(sortFilterState(key));
      const ui = get(newtQueryBase(key));

      if (!ui) {
        return [];
      }

      let finalFilter: { search: any; match: any } = {
        search: { $and: [ui?.newtQ.search ?? {}] },
        match: { $and: [ui?.newtQ.match ?? {}] },
      };

      // match
      let and = matchFilter.$and ?? [];

      if (lcVerticalFilter) and = [...and, lcVerticalFilter];

      if (ui && ui.newtQ.match) and = [...and, ui.newtQ.match];

      if (
        Array.isArray(extendMatchFilter.$and) &&
        extendMatchFilter.$and.length > 0
      ) {
        and = [...and, ...extendMatchFilter.$and];
      }
      if (and.length > 0) {
        finalFilter.match["$and"] = [...finalFilter.match?.$and, ...and];
      }

      if (Array.isArray(searchFilter) && searchFilter.length > 0) {
        // finalFilter["$and"].push(searchFilter);
        finalFilter.search.$and = [...finalFilter.search.$and, ...searchFilter];
      }

      const perPage = ui?.pager?.perPage ?? 8;

      if (typeof ui.newtQ === "function") {
        try {
          const nameFilter = get(userNameSearchWordState(key));
          const res = await ui.newtQ(perPage, (pageIndex - 1) * perPage, {
            name: nameFilter,
            sort: sortQuery,
            ...finalFilter,
          });
          return res;
        } catch (err) {}
      } else {
        const q = {
          ...ui?.newtQ,
          ...finalFilter,

          order: sortQuery,
          limit: perPage,
          //pageIndexは1からなので-1する。
          skip: (pageIndex - 1) * perPage,
        };
        try {
          // const [resNewtData, _c] = await api.newtOut(q, { count: false });
          const newtzero = createNewtZeroAPI();
          const resNewtData = await newtzero.dataProvider(q, {
            count: false,
            filterByContractor: ui.filterByContractor,
          });
          return resNewtData;
        } catch (err) {
          return [];
        }
      }
      return [];
    },
});
