import { medicanAPIConfig } from "./config";

export const saveAlertOne = async (subjectId: string) => {
  const url = `/subject/${subjectId}/alert`;
  const res = medicanAPIConfig.post(
    url,
    {},
    { params: { subjectId: subjectId, apikey: sessionStorage.token } }
  );
  return res;
};
