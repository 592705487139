import { ASInputBase } from "allegro-ui";
import { Controller, Control, FieldValues } from "react-hook-form";
import {
  ASDatePickerForm,
  ASFormDesign,
} from "../../models/ASLowCodeFormField";
import { ASFormLabel, ASErrorField, ASRequiredMark } from "../atoms";
import { format } from "date-fns";
import styled from "styled-components";
import { Input } from "@material-ui/core";
import { ASFormFieldBase } from "./ASFormFieldBase";
import moment, { isMoment } from "moment";

const StyledInput = styled(Input)`
  color: ${(props) =>
    props.theme?.darkMode ? "white" : props.theme?.secondaryOnText};
  background-color: ${(props) => props.theme.secondaryColor};
  border-radius: ${(props) => props.theme.borderRadius}px;
  padding: 4px 16px 2px 16px;
  input::-webkit-calendar-picker-indicator {
    filter: invert(${(props) => (props.theme.darkMode ? 1 : 0)});
  }
`;

type ASFormFieldProps = {
  id?: number | string;
  control: Control;
  defaultValue?: string;
  error: any;
  editable?: boolean;
  design?: ASFormDesign;
  supportText?: string;
  requiredMarker?: string;
  optionalMarker?: string;
};

export const ASDatePickerFormField = (
  props: ASFormFieldProps & ASDatePickerForm
) => {
  const {
    id,
    label,
    name,
    required,
    control,
    defaultValue,
    error,
    errorText,
    editable,
    design,
    supportText,
  } = props;

  const formattedDate = defaultValue
    ? moment(defaultValue).format("yyyy-MM-DD")
    : undefined;

  return (
    <div key={`lc-create-date-form-${id}-${name}-${defaultValue}`}>
      <ASFormFieldBase
        error={error}
        errorText={errorText}
        label={label}
        design={design}
        required={required}
        editable={editable}
        supportText={supportText}
        requiredMarker={props.requiredMarker}
        optionalMarker={props.optionalMarker}
      >
        <Controller
          name={name}
          control={control}
          rules={{ required: required }}
          defaultValue={formattedDate}
          render={({ field: { onChange } }) => (
            <ASInputBase
              // disableUnderline={true}
              type="date"
              kind={props.design === "digicho" ? "digicho" : "secondary"}
              readOnly={!(editable ?? true)}
              name={`date-picker-form-${id}`}
              defaultValue={formattedDate}
              onChange={(e: any) => {
                if (e.target.value !== "Invalid Date") {
                  onChange(moment(e.target.value).format("yyyy-MM-DD"));
                }
              }}
            />
          )}
        />
      </ASFormFieldBase>
    </div>
  );
};
