import axios from "axios";
import { useState } from "react";
import { UploadLogStatus } from "src/newtroid/models";

type Props = {
  onUpload: (file: File) => Promise<UploadLogStatus[]>;
};

export const useFileUpload = (props: Props) => {
  const [logs, setLogs] = useState<UploadLogStatus[]>([]);
  const { onUpload } = props;

  const uploadFiles = async (files: File[]) => {
    for (const file of files) {
      await upload(file);
    }
  };

  const upload = async (file: File) => {
    try {
      const res = await onUpload(file);

      setLogs([...logs, ...res]);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setLogs([
          ...logs,
          {
            status: -1,
            name: file.name,
            message: "失敗",
          },
        ]);
      }
    }
  };

  return { logs, uploadFiles, upload };
};
