import React from "react";
import styled from "styled-components";
type Props = { name: string };

const CircleIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #000000; // TODO
  margin-left: 4px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OEInitialIcon: React.FC<Props> = ({ name }) => {
  return name && name.length > 0 ? (
    <CircleIcon>{name[0].toUpperCase()}</CircleIcon>
  ) : (
    <CircleIcon>{""}</CircleIcon>
  );
};
