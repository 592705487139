import { BundleRefenrece } from "src/general/models";
import { api } from "../http";

export type BundleInput = {
  bundleId?: string;
  name: string;
  slug: string;
  version: string;
  comment?: string;
  isDefault?: boolean;
  references: BundleRefenrece[];
  tags: string[];
};

export const save = async (data: BundleInput): Promise<any> => {
  try {
    const res = await api.post("/bundles", data);
    return res.data;
  } catch (err) {}
};
