import styled from "styled-components";

import { XRGridEditorViewLoading } from "../organisms/XRGridEditorView";
import { OESearchTextField } from "src/opt-editor/components";
import { ASPageNav, ASSwitchButton } from "allegro-ui";
import React from "react";
import { XRXerviceBrowser } from "./XRXerviceBrowser";
import { useXerviceBrowser } from "src/xerver-room/hooks/useXerviceBrowser";
import { SlotFilterType } from "src/xerver-room/types/slotFilterType";

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 16px;
  > * {
    margin-right: 30px;
  }

  > *:last-child {
    margin-right: 0;
  }
`;

const PageLayout = styled.div`
  margin: 0 auto;
  margin-top: 30px;
  display: flex;
  > * {
    margin: 0 auto;
  }
`;

const ContainerFilterText = styled.p`
  color: ${(props) => props.theme.textColor};
  font-size: ${(props) => props.theme.labelFontSizeMd}px;
`;

const RunningContainerFilterArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type Props = {
  slotFilterType: SlotFilterType;
  showSerialNum?: boolean;
  showPipelineId?: boolean;
  showRunning?: boolean;
  showStats?: boolean;
  defaultServerIp?: string;
};

export const XRXerviceBrowserContainer: React.FC<Props> = (props) => {
  const { showSerialNum, showPipelineId, showStats, slotFilterType } = props;

  const {
    showRunning,
    page,
    handleChangePage,
    handleSearchWord,
    handleSwitchRunning,
  } = useXerviceBrowser({
    defaultServerIp: props.defaultServerIp,
    slotFilterType,
  });

  return (
    <>
      <SearchBar>
        <OESearchTextField
          defaultValue={props.defaultServerIp}
          placeholder="ContainerName, IP"
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              handleSearchWord(event.target.value);
            }
          }}
          onSubmit={(event: any) => {
            handleSearchWord(event.target.value);
          }}
        />
        <RunningContainerFilterArea>
          <ASSwitchButton checked={showRunning} onClick={handleSwitchRunning} />
          <ContainerFilterText>
            Only show running containers
          </ContainerFilterText>
        </RunningContainerFilterArea>
      </SearchBar>

      <React.Suspense fallback={<XRGridEditorViewLoading />}>
        <>
          <XRXerviceBrowser
            showSerialNum={showSerialNum}
            showPipelineId={showPipelineId}
            showStats={showStats}
          />
          <PageLayout>
            <ASPageNav
              count={10}
              page={page}
              onChange={(_, pageId) => {
                handleChangePage(pageId);
              }}
            />
          </PageLayout>
        </>
      </React.Suspense>
    </>
  );
};
