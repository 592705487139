import { createnAuthAPI } from "src/api";

export const fetchUsersByEmail = async (email: string) => {
  const newtQuery = {
    limit: 8,
    skip: 0,
    shape: {
      userNoText: "any",
      "profile.userRealName": "any",
      userNo: "any",
      email: "any",
    },
    search: {
      email: {
        $regex: email.replace("+", `\\+`),
        $options: "i",
      },
      contractorNo: sessionStorage.contractorNo,
    },
  };

  const nauth = createnAuthAPI();
  try {
    const res = await nauth.getUsersByDataProvider(newtQuery);
    return res;
  } catch (err) {
    console.error(err);
  }
  return [];
};

export const fetchUserByUserNo = async (userNo: number) => {
  const newtQuery = {
    limit: 1,
    skip: 0,
    shape: {
      userNoText: "any",
      "profile.userRealName": "any",
      userNo: "any",
      email: "any",
    },
    search: {
      userNo: userNo,
      contractorNo: sessionStorage.contractorNo,
    },
  };

  const nauth = createnAuthAPI();
  try {
    const res = await nauth.getUsersByDataProvider(newtQuery);
    return res.length > 0 ? res[0] : null;
  } catch (err) {
    console.error(err);
  }
};
