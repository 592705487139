import React, { useEffect, useCallback } from "react";
import { ASSelect } from "allegro-ui";
import {
  pipelineListState,
  selectPipelineState,
  cursorPipelineState,
  fetchPipelineList,
} from "src/opt-editor/recoils/pipelineState";
import { useSetRecoilState, useRecoilState, useRecoilValue } from "recoil";

type Props = {};
export const OEPipelineSelect: React.FC<Props> = (props) => {
  const cursorPipeline = useRecoilValue(cursorPipelineState);
  const selectPipeline = useSetRecoilState(selectPipelineState);

  const [pipelineList, setPipelineList] = useRecoilState(pipelineListState);

  const didMount = useCallback(async () => {
    //パイプラインリストを取得してセットする。
    const data = await fetchPipelineList();
    if (data) {
      setPipelineList(data);
      //0番目のパイプラインを選択する。
      if (data.length > 0) selectPipeline(data[0].name);
    }
  }, [selectPipeline, setPipelineList]);

  useEffect(() => {
    didMount();
  }, [didMount]);

  const selections = useCallback(() => {
    const arr = pipelineList.map((p) => {
      return { value: p.name, label: p.name };
    });
    return arr;
  }, [pipelineList]);

  const handlePipeline = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const value = String(event.target.value);
    selectPipeline(value);
  };

  return (
    <>
      <ASSelect
        label="Pipeline"
        kind="secondary"
        value={cursorPipeline ? cursorPipeline.name : ""}
        data={selections()}
        onChange={handlePipeline}
      ></ASSelect>
    </>
  );
};
