//左サイドバーの具体的な実装を記載する。

//

import React from "react";
import styled from "styled-components";
import Drawer from "@material-ui/core/Drawer";
import { ASIconButton, ASModal, ASButton } from "allegro-ui";
import { useAuth } from "src/root/UseAuth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

//mui
import Select from "@material-ui/core/Select";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import ScatterPlotOutlinedIcon from "@material-ui/icons/ScatterPlotOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import { useSetRecoilState } from "recoil";
import {
  cursorChildPlotState,
  filterQueryState,
} from "src/fortress/recoils/childListState";
import AssessmentIcon from "@material-ui/icons/Assessment";

export type ASSelectProps = {
  siteName: string;
  prefecture: string;
  city: string;
};

export const StyledSelect = styled(Select)`
  color: red;
  font-size: 1em;
`;

export const StyledDrawer = styled(Drawer)`
  width: 110px;
  flex-shrink: 0;
  .MuiDrawer-paper {
    background: ${(props) => props.theme.bgPrimaryColor};
  }
  .MuiDrawer-paperAnchorDockedLeft {
    /* border: none; */
  }
`;

const TextContainer = styled.div`
  /* background-color: ${(props) => props.theme.secondaryColor}; */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  padding-bottom: 140px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
`;

const Inner = styled.div`
  width: 110px;
`;

const MenuContainer = styled.div`
  /* background-color: ${(props) => props.theme.secondaryColor}; */
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  padding-left: 15px;
  padding-bottom: 24px;
  width: 80px;
  > * {
    margin-top: 12px;
  }
`;

const VerticalText = styled.div`
  writing-mode: vertical-rl;
  letter-spacing: 3;
  margin: 0 auto;
`;

const Title = styled.h1`
  vertical-align: baseline;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 9px;
  margin: 0;
  margin-top: 24px;
  margin-bottom: 12px;
  color: ${(props) => props.theme.secondaryOnText};
`;

const SubHeader = styled.h2`
  vertical-align: baseline;
  margin: 0;
  margin-top: 12px;
  font-size: 16px;
  letter-spacing: 9px;
  color: ${(props) => props.theme.secondaryOnText};
`;

const Buttons = styled.div`
  position: relative;
  top: 150px;
  display: flex;
  justify-content: space-around;
  bottom: 48px;
`;

export const FTGrobalNav: React.FC<ASSelectProps> = ({
  siteName = "サイト名",
  prefecture = "県",
  city = "市",
}) => {
  const auth = useAuth();
  const [show, setShow] = useState(false);
  const setFilter = useSetRecoilState(filterQueryState);
  const setPlot = useSetRecoilState(cursorChildPlotState);

  const handleMain = () => {
    history("/");
    setFilter({});
    setPlot([]);
  };

  const history = useNavigate();
  const logoutConfirm = (
    <div>
      <h3>ログアウトしますか？</h3>
      <Buttons>
        <ASButton
          onClick={() => {
            if (auth.logout) auth.logout();
          }}
          kind={"primary"}
        >
          はい
        </ASButton>
        <ASButton
          onClick={() => {
            setShow(false);
          }}
          kind={"primary"}
        >
          いいえ
        </ASButton>
      </Buttons>
    </div>
  );

  return (
    <StyledDrawer variant="permanent" anchor="left">
      <Inner>
        <TextContainer>
          <VerticalText>
            <Title>{siteName}</Title>
          </VerticalText>
          <VerticalText>
            <SubHeader>{prefecture}</SubHeader>
          </VerticalText>
          <VerticalText>
            <SubHeader>{city}</SubHeader>
          </VerticalText>
        </TextContainer>

        <MenuContainer>
          <ASIconButton onClick={handleMain}>
            <ListAltOutlinedIcon />
          </ASIconButton>
          <ASIconButton onClick={() => history("/analysis")}>
            <ScatterPlotOutlinedIcon />
          </ASIconButton>
          <ASIconButton onClick={() => history("/allassesment")}>
            <AssessmentIcon />
          </ASIconButton>
          <ASIconButton onClick={() => history("/setting")}>
            <SettingsIcon />
          </ASIconButton>
          <ASIconButton onClick={() => setShow(true)} kind={"secondary"}>
            <MeetingRoomIcon fontSize="small" />
          </ASIconButton>
          <ASModal open={show} onClose={setShow}>
            {logoutConfirm}
          </ASModal>
        </MenuContainer>
      </Inner>
    </StyledDrawer>
  );
};
