import { useRecoilValueLoadable } from "recoil";
import { decodeUserNoText } from "src/fortress/utils/userNoEdit";
import { userIconState } from "../recoils/selectors/userIconState";

export const useUserIconComponent = (userNo: string | number) => {
  const userNumber =
    typeof userNo === "string" ? decodeUserNoText(userNo) : userNo;

  const userIconLoadable = useRecoilValueLoadable(
    userIconState(String(userNumber))
  );

  const user =
    userIconLoadable.state === "hasValue" ? userIconLoadable.contents : null;

  const avatar = user?.avatar ?? user?.profile?.avatar;
  const userRealName = user?.profile?.userRealName ?? "";

  const isLoading = userIconLoadable.state === "loading";
  return {
    userNumber,
    userIconLoadable,
    user,
    userRealName,
    avatar,
    isLoading,
  };
};
