import { atom, selector } from "recoil";
import { CheckBoxField } from "../../models/filterModel";

export const checkBoxFieldsState = atom<CheckBoxField[]>({
  key: "LCFilterCheckBoxFilterFieldGroup",
  default: [],
});

export const currentFilterQuery = selector({
  key: "LCFilterCurrentFilterQuery",
  get: () => {},
});
