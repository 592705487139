import * as React from "react";
import styled from "styled-components";
import {
  FilterUIConf,
  ASBrowserUiConf,
} from "../../../types/LowCodeUiDefinition";
import { useSetRecoilState } from "recoil";
import { userListPageIndexState } from "../../../recoils/atoms/filterState";
import { lowCodeVerticalFilterState } from "../../../recoils/atoms/filterState";
import { LCSearchField } from "../components/LCSearchField";
import { OESearchTextField } from "src/opt-editor/components";

const VerticalLayout = styled.div`
  display: flex;
  align-items: center;
`;

interface ILCVerticalFiltersContainerProps {
  name: string;
  uiConf: ASBrowserUiConf;
}

export const LCVerticalFiltersContainer: React.FunctionComponent<ILCVerticalFiltersContainerProps> =
  (props) => {
    const setPageIndex = useSetRecoilState(
      userListPageIndexState(props.name ?? "")
    );
    const setSearchFilter = useSetRecoilState(
      lowCodeVerticalFilterState(props.name ?? "")
    );
    const [word, setWord] = React.useState<string>("");

    const submitFilter = (callback: any) => {
      if (word === "") {
        setSearchFilter(undefined);
      } else {
        const hoge = callback(word);
        setSearchFilter(hoge);
      }

      setPageIndex(1);
    };

    const renderFilters = () => {
      const filters = props.uiConf.verticalFilters;
      if (!filters) return <></>;
      return filters.map((filter: FilterUIConf, id: number) => {
        switch (filter.design) {
          case "search":
            return (
              <VerticalLayout key={`lc-vertical-fileter-container-${id}`}>
                <OESearchTextField
                  placeholder="Search"
                  onChange={(event) => {
                    const pWord = event.target.value;
                    setWord(pWord);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      submitFilter(filter.callback);
                    }
                  }}
                  onSubmit={() => {
                    submitFilter(filter.callback);
                  }}
                />
              </VerticalLayout>
            );
          default:
            return <></>;
        }
      });
    };
    return <>{renderFilters()}</>;
  };
