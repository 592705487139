import { useState, useEffect, useCallback } from "react";

import produce from "immer";
import { Log } from "../models/Log";
import { Slot } from "../models";
import { MONITOR_BASE_URL, MonitorAPI } from "../api/monitor-api";
import { useAuth } from "src/root";

const defaultLogNewtQuery = {
  asset: ["ContainerLog"],
  limit: 300,
  skip: 0,
  order: {
    "contents.rawdata.timestamp": -1,
  },
  search: {},
  shape: {
    "contents.rawdata": "any",
    _created: "any",
  },
};

export const useLogTail = (slot?: Slot) => {
  const auth = useAuth();
  const [cursorSlotIds, setCursorSlotIds] = useState<string[]>([]);
  const [logs, setLogs] = useState<Log[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const makeQuery = useCallback(() => {
    return produce(defaultLogNewtQuery, (draft) => {
      draft.search = {
        "contents.rawdata.container": { $regex: slot?.slotId },
      };
    });
  }, [slot?.slotId]);

  const updateLog = useCallback(async () => {
    setIsLoading(true);
    try {
      const newtQuery = makeQuery();
      const monitorAPI = MonitorAPI({
        baseUrl: MONITOR_BASE_URL,
        apikey: auth.token ?? "",
      });
      const resLog = await monitorAPI.fetchContainerLogsByNewtQuery(newtQuery);

      const newLogs: Log[] = resLog.map((logData) => {
        return {
          log: logData.contents.rawdata.log,
          time: logData.contents.rawdata.timestamp,
          stream: logData.contents.rawdata.stream,
          xerviceId: slot?.pipelineId ?? "",
          xerviceName: slot?.name ?? "",
        };
      });
      console.log({ resLog });

      setLogs(newLogs);
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  }, [auth.token, makeQuery, slot?.name, slot?.pipelineId]);

  useEffect(() => {
    if (slot) {
      setCursorSlotIds([slot.slotId]);
    }
    updateLog();

    return () => {};
  }, [slot]);

  const clearLogs = () => {
    setLogs([]);
  };

  const resyncLog = async () => {
    await updateLog();
  };

  return { cursorSlotIds, logs, resyncLog, clearLogs, isLoading };
};
