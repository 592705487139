import produce from "immer";
import moment from "moment";

export const BUNDLE_ASSET_TAGS = [
  "BundleService",
  "bundle",
  "key:contents.rawdata.bundleId",
  "key:contents.rawdata.version",
  "SendToTopData",
];

export type Bundle = {
  bundleId: string;
  slug: string;
  name: string;
  version: string;
  references: BundleRefenrece[];
  comment?: string;
  isDefault?: boolean;
};

export type BundleRefenrece = {
  name: string;
  slug: string;
  kind: "minio" | "RawData" | "TopData" | "TxnData" | "xervice";
  object: {
    assetId?: string;
    assetNo?: string;
    assetTags?: string[];
    xerviceId?: string;
  };
};

export const defaultBundle: Bundle = {
  bundleId: "",
  slug: "",
  name: "",
  version: "latest",
  references: [],
  isDefault: true,
};

export const defaultBundleReference: BundleRefenrece = {
  name: "",
  slug: "",
  kind: "TopData",
  object: {},
};

export const createBundleFromNewtQuery = (data: any) => {
  return produce(defaultBundle, (draft) => {
    draft.name = data.contents.rawdata.name ?? defaultBundle.name;
    draft.slug = data.contents.rawdata.slug ?? defaultBundle.slug;
    draft.version = data.contents.rawdata.version ?? defaultBundle.version;
    const referencesObj: any[] =
      data.contents.rawdata.references ?? defaultBundle.references;
    draft.references = referencesObj.map((referenceObj: any) => {
      return createBundleReference(referenceObj);
    });
    draft.comment = data.contents.rawdata.comment;
    draft.isDefault =
      data.contents.rawdata.isDefault !== undefined
        ? data.contents.rawdata.isDefault
        : draft.isDefault;

    draft.bundleId = data.contents.rawdata.bundleId ?? draft.bundleId;
  });
};

export const createBundle = (data: any) => {
  return produce(defaultBundle, (draft) => {
    draft.bundleId = data.bundleId ?? draft.bundleId;
    draft.name = data.name ?? defaultBundle.name;
    draft.slug = data.slug ?? defaultBundle.slug;
    draft.version = data.version ?? defaultBundle.version;
    const referencesObj: any[] = data.references ?? defaultBundle.references;
    draft.references = referencesObj.map((referenceObj: any) => {
      return createBundleReference(referenceObj);
    });
    draft.comment = data.comment;
    draft.isDefault =
      data.isDefault !== undefined ? data.isDefault : draft.isDefault;
  });
};

export const createBundleReference = (data: any): BundleRefenrece => {
  return produce(defaultBundleReference, (draft) => {
    draft.name = data.name ?? defaultBundleReference.name;
    draft.slug = data.slug ?? defaultBundleReference.slug;
    draft.kind = data.kind ?? defaultBundleReference.kind ?? "TopData";
    draft.object = data.object ?? defaultBundleReference.object;
  });
};
