import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import { ASIconButton } from "allegro-ui";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { ASLowCodeDataBrowserContainer } from "src/cheetah/components/libs/ASLowCodeBrowser";

import {
  categoriesSelector,
  formsFilterSelector,
} from "src/fortress/recoils/formState";

import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const Title = styled.p`
  font-size: 30px;
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
`;

const BackButton = styled(ASIconButton)`
  width: 45px;
  height: 45px;
  margin-right: 20px;
`;

const Flex = styled.div`
  display: flex;
  margin-top: 30px;
  margin-bottom: 20px;
  align-items: center;
  margin-left: 5px;
`;

type Props = {};

//全員分のアセスメント一覧を表している。

export const AllAssesmentPanel: React.FC<Props> = () => {
  const history = useNavigate();

  const categories = useRecoilValueLoadable(categoriesSelector);
  const formsFilter = useRecoilValueLoadable(formsFilterSelector);

  useEffect(() => {}, [categories.contents, formsFilter.state]);

  const createFormListASBrowserUiConf = (): ASBrowserUiConf => {
    return {
      newtQ: {
        asset: ["症例登録システム"],
        primaryKey: "id",
        search: {
          "bases.tags": {
            $elemMatch: {
              $regex: "PHR:Record:(.*)",
            },
          },
        },
        shape: {
          "bases.tags": "any",
          "bases.userNo": "any",
          _created: "any",
        },
      },
      filters: [
        {
          label: "カテゴリ",
          design: "checkbox",
          selections:
            categories.state === "hasValue"
              ? categories.contents.map((category: string) => {
                  return {
                    label: category,
                    value: category,
                  };
                })
              : [],
          callback: (value: string) => {},
        },
        {
          label: "フォーム",
          design: "checkbox",
          selections:
            formsFilter.state === "hasValue"
              ? formsFilter.contents.map((form: string) => {
                  return {
                    label: form,
                    value: form,
                  };
                })
              : [],
          callback: (value: string) => {},
        },
      ],
      tables: [
        {
          label: "UserNo",
          component: "LCSimpleColComponent",
          data: { value: "bases.userNo" },
          options: {},
          statics: {},
        },
        {
          label: "フォーム名",
          component: "LCSimpleColComponent",
          data: extractFormName,
          options: {},
          statics: {},
        },
        {
          label: "作成日",
          component: "LCUnixTimeCellComponent",
          data: {
            value: "_created",
          },
          options: {
            // dateFormat: "wareki",
          },
          statics: {},
        },
      ],
      pager: { perPage: 50 },
    };
  };

  const extractFormName = (data: any) => {
    const asset: string[] = data.bases.tags ?? [];
    const extAsset = asset.filter((tag) => {
      return new RegExp(`PHR:Record:(.*)`).test(tag);
    });

    return {
      value: (extAsset[0] ?? "").replace(`PHR:Record:`, ""),
    };
  };

  //前ページに遷移
  const back = () => {
    history(-1);
  };

  return (
    <Root>
      <Flex>
        <BackButton size="small" onClick={back}>
          <ArrowBackIcon fontSize="inherit" />
        </BackButton>
        <Header>
          <Title>リスクアセスメント一覧</Title>
        </Header>
      </Flex>
      {/*<Body>
        <StyledGrid container spacing={3}>
          <Grid item xs={4}>
            <FilterCard>
              <FTFormFilter />
            </FilterCard>
          </Grid>
          <Grid item xs={8}>
            {formsData.state === "hasValue" && formsData.contents ? (
              <FTAllAssesmentList
                assessmentList={arrangeFormsData(formsData.contents)}
              />
            ) : (
              <No>
                <p>アセスメントデータが存在しません</p>
              </No>
            )}
          </Grid>
        </StyledGrid>
      </Body> */}
      <ASLowCodeDataBrowserContainer
        name="all-assessment-panel"
        uiConf={createFormListASBrowserUiConf()}
      />
    </Root>
  );
};
