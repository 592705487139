//Libs
import React from "react";
import styled from "styled-components";

import { LogoWithContractor } from "../atoms/NTHeaderLogo";
import { CHHeaderNav } from "src/cheetah/components/organisms/CHHeaderNav";
import { NTGlobalNavConf } from "src/newtroid-console/config/globalNavConf";
import { useAuth } from "src/root";

//Types

export const headerOffset = 56;

const Skelton = styled.div`
  clear: both;
  position: relative;
`;

type Props = {};
export const NTGlobalNav: React.FC<Props> = (props) => {
  const auth = useAuth();

  return (
    <>
      <CHHeaderNav
        logo={
          auth.contractorNo ? (
            <LogoWithContractor contractorNo={auth.contractorNo} />
          ) : (
            <LogoWithContractor />
          )
        }
        conf={NTGlobalNavConf}
      />
      <Skelton></Skelton>
    </>
  );
};
