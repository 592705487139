import { useEffect, useState } from "react";
import * as React from "react";
import styled from "styled-components";
import { ASChip } from "allegro-ui";
import { darken, lighten } from "polished";

const Title = styled.h2`
  font-size: 13px;
  color: #656565;
  line-height: 1.56;
  width: 100px;
`;

const Typo = styled.div`
  font-size: 10px;
`;

export const DisabledChip = styled(ASChip)`
  color: #909090;
  background-color: ${(props) =>
    props.theme.darkMode ? darken(0.5, "#555") : lighten(0.52, "#555")};
`;

interface ILCSecretCellComponentProps {
  data: {
    value: any;
  };
}

const LCSecretCellComponent: React.FC<ILCSecretCellComponentProps> = (
  props
) => {
  return <>{props.data.value ? <Typo>●●●●●●●●</Typo> : "-"}</>;
};

export default LCSecretCellComponent;
