import React from "react";
import styled from "styled-components";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { ASTable } from "allegro-ui";
import { UserList } from "../../models";
import { sampleUserList } from "../../models";
import { OEUserListItem } from "../organisms/OEUserListItem";
import { OEPageTemplate } from "../templates";
import { useUserList } from "src/opt-editor/hooks";
import { useProfileList } from "src/portal/hooks/useProfileList";

const Head = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 30px 0px 30px;
`;

const BodyCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 50px 30px 50px 30px;
  height: 100vh;
`;

const Title = styled.h1`
  font-size: 30px;
`;

const ManagementView = styled(Paper)`
  width: 100%;
  padding: 20px;
  background-color: ${(props) => props.theme.bgPrimaryColor};
  overflow: scroll;
`;

const MainPageFooter = styled.div`
  margin-top: 20px;
`;

export const OEManagementPageTemplate: React.FC = () => {
  const allUserList: UserList[] = [...sampleUserList];

  const { userList } = useProfileList();

  const handleSearchText = (event: any) => {
    const seachText = event?.target?.value;
    if (seachText.length > 0) {
      const filterUserList: UserList[] = allUserList.filter(
        (user) =>
          user.userNoText.indexOf(seachText) !== -1 ||
          user.username.indexOf(seachText) !== -1
      );
      // setUserList(filterUserList);
    } else {
      // setUserList(allUserList);
    }
  };

  return (
    <OEPageTemplate>
      <BodyCenter>
        <Head>
          <Title>Management</Title>
          {/* <OESearchTextField onChange={handleSearchText} /> */}
        </Head>
        <ManagementView>
          <TableContainer component={ASTable} borderRadius={18} kind="tertiary">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>ID</TableCell>

                  {/* <TableCell>日付</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {userList.map((user, id) => {
                  return (
                    <OEUserListItem
                      user={user}
                      key={`oe-user-list-item-${id}`}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </ManagementView>
        <MainPageFooter></MainPageFooter>
      </BodyCenter>
    </OEPageTemplate>
  );
};
