//年齢対応ひょうは、2022年4月1日を過ぎるとともに、一つ西暦を繰り上げるものとする
//児童一覧画面におけるフィルターの選択肢をここに記載するものとする。
import { dateOf18YearsAgo } from "../time";

let year = 2021;

const lastday = new Date(`${year + 1}/4/2`);
const today = new Date();

if (today === lastday) {
  year++;
}

export const filters = {};

//年齢でフィルターかけるときの選択肢

export const ageSelections = [
  { value: "すべて", label: "すべて" },
  { value: "未就学児", label: "未就学児" },
  { value: "小学生", label: "小学生" },
  { value: "中学生", label: "中学生" },
];

export const ageFilterQueries: { [key: string]: any } = {
  未就学児: {
    "contents.optdata.numeric_birth_date": { $gte: Number(`${year - 6}0402`) },
  },
  小学生: {
    "contents.optdata.numeric_birth_date": {
      $gte: Number(`${year - 12}0402`),
      $lt: Number(`${year - 6}0401`),
    },
  },
  中学生: {
    "contents.optdata.numeric_birth_date": {
      $gte: Number(`${year - 15}0402`),
      $lt: Number(`${year - 12}0401`),
    },
  },
  すべて: {
    "contents.optdata.numeric_birth_date": { $gte: Number(dateOf18YearsAgo) },
  },
};

//職員評価でフィルターをかけるときの選択肢

export const evalSelections = [
  { value: "すべて", label: "すべて" },
  { value: "虐待あり", label: "虐待あり" },
  { value: "虐待の危惧あり", label: "虐待の危惧あり" },
  { value: "要経過観察", label: "要経過観察" },
  { value: "気になる", label: "気になる" },
  { value: "問題なし", label: "問題なし" },
];
export const evalFilterQueries: { [key: string]: any } = {
  すべて: {
    "contents.optdata.non_numeric_abuse_risk_evaluation_by_staff": "",
  },
  虐待あり: {
    "contents.optdata.non_numeric_abuse_risk_evaluation_by_staff": "虐待あり",
  },
  虐待の危惧あり: {
    "contents.optdata.non_numeric_abuse_risk_evaluation_by_staff":
      "虐待の危惧あり",
  },
  要経過観察: {
    "contents.optdata.non_numeric_abuse_risk_evaluation_by_staff": "要経過観察",
  },
  気になる: {
    "contents.optdata.non_numeric_abuse_risk_evaluation_by_staff": "気になる",
  },
  問題なし: {
    "contents.optdata.non_numeric_abuse_risk_evaluation_by_staff": "問題なし",
  },
};

//ウォッチかそうでないかのフィルター

export const watchSelections = [
  { value: "すべて", label: "すべて" },
  { value: "ウォッチ中", label: "ウォッチ中" },
];
export const watchFilterQueries: { [key: string]: any } = {
  すべて: {
    "contents.optdata.non_numeric_is_watch": "",
  },
  ウォッチ中: {
    "contents.optdata.non_numeric_is_watch": 1,
  },
};

//リスクのフィルター

export const riskSelections = [
  { value: "すべて", label: "すべて" },
  { value: "検査1 50%以下", label: "検査1 50%以下" },
  { value: "検査1 50%以上", label: "検査1 50%以上" },
  { value: "検査2 50%以下", label: "検査2 50%以下" },
  { value: "検査2 50%以上", label: "検査2 50%以上" },
];
export const riskFilterQueries: { [key: string]: any } = {
  すべて: {
    "contents.optdata.numeric_prediction1_value": "",
  },
  "検査1 50%以下": {
    "contents.optdata.numeric_prediction1_value": { $lte: 0.5 },
  },
  "検査1 50%以上": {
    "contents.optdata.numeric_prediction1_value": { $gte: 0.5 },
  },
  "検査2 50%以下": {
    "contents.optdata.numeric_prediction2_value": { $lte: 0.5 },
  },
  "検査2 50%以上": {
    "contents.optdata.numeric_prediction2_value": { $gte: 0.5 },
  },
};

//データグループでのフィルター

export const dataGroupSelections = [
  { value: "すべて", label: "すべて" },
  { value: "高-A", label: "高-A" },
  { value: "高-B", label: "高-B" },
  { value: "中-A", label: "中-A" },
  { value: "中-B", label: "中-B" },
  { value: "低-A", label: "低-A" },
  { value: "低-B", label: "低-B" },
];
export const dataGroupFilterQueries: { [key: string]: any } = {
  すべて: { "contents.optdata.non_numeric_datagroup_label": "" },
  "高-A": { "contents.optdata.non_numeric_datagroup_label": "高-A" },
  "高-B": { "contents.optdata.non_numeric_datagroup_label": "高-B" },
  "中-A": { "contents.optdata.non_numeric_datagroup_label": "中-A" },
  "中-B": { "contents.optdata.non_numeric_datagroup_label": "中-B" },
  "低-A": { "contents.optdata.non_numeric_datagroup_label": "低-A" },
  "低-B": { "contents.optdata.non_numeric_datagroup_label": "低-B" },
};

//ソートの選択肢を書いている。

export const orderSelections = [
  { value: "デフォルト", label: "デフォルト" },
  { value: "生年月日が早い順", label: "生年月日が早い順" },
  { value: "生年月日が遅い順", label: "生年月日が遅い順" },
  { value: "検査1のリスクが高い順", label: "検査1のリスクが高い順" },
  { value: "検査2のリスクが高い順", label: "検査2のリスクが高い順" },
  { value: "データグループ:高リスク順", label: "データグループ:高リスク順" },
];
export const sortQueries: { [key: string]: any } = {
  デフォルト: { "contents.rawdata.宛名番号": 1 },
  生年月日が早い順: { "contents.optdata.numeric_birth_date": 1 },
  生年月日が遅い順: { "contents.optdata.numeric_birth_date": -1 },
  検査1のリスクが高い順: { "contents.optdata.numeric_prediction1_value": -1 },
  検査2のリスクが高い順: { "contents.optdata.numeric_prediction2_value": -1 },
  "データグループ:高リスク順": {
    "contents.optdata.non_numeric_datagroup_id": 1,
  },
};
