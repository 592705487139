import React, { useState } from "react";
import styled from "styled-components";
import * as api from "src/utils";
import { cursorSlotState, selectPipelineState } from "src/opt-editor/recoils";
import { useRecoilValue } from "recoil";
import { useEffect } from "react";

const colors: { [key: string]: string } = {
  online: "#33ff33",
  running: "#33ff33",
  stopped: "#ff3333",
  error: "#ff7700",
};

const ColorDot = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 20px;
  background-color: #999;
  margin-right: 4px;
`;

type Props = {};
export const OEStatusChip: React.FC<Props> = (props) => {
  const [statusText, setStatusText] = useState("none");
  const [memUsage, setMemUsage] = useState(0);
  const cursorSlot = useRecoilValue(cursorSlotState);
  const selectPipeline = useRecoilValue(selectPipelineState);
  const [time, updateTime] = useState(Date.now());

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      updateTime(Date.now());
      if (cursorSlot) {
        const res = await api.fetchLogicStatus(
          sessionStorage.contractorNo,
          selectPipeline,
          cursorSlot.name,
          sessionStorage.token
        );
        console.log(res);
        if (res) {
          setStatusText(res.data.data.status ?? "none");
          setMemUsage(res.data.data.monit.memory / 1000000 ?? -1);
        } else {
          setStatusText("none");
          setMemUsage(0);
        }
      }
    }, 5000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [time, cursorSlot, selectPipeline]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <h4>
        <ColorDot style={{ backgroundColor: colors[statusText] ?? "" }} />
        {statusText}
      </h4>
      <h4>mem:{memUsage}mb</h4>
    </>
  );
};
