import useAxios from "axios-hooks";
import { url } from "../url";

export const useGet = (contractorName: string, userId: string) => {
  const [{ data, loading, error }, fetch] = useAxios(
    {
      url: url.topData(contractorName, userId),
      method: "GET",
    },
    { manual: true }
  );

  const exec = (query: any, options?: any) => {
    fetch({
      params: { q: query, options },
    });
  };

  return { data, loading, error, exec };
};
