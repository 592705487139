import React, { useState, useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { usePipeline } from "src/opt-editor/contexts";
import { fetchSystemLogs } from "src/utils";
import { useRecoilValue } from "recoil";
import { cursorSlotState, selectPipelineState } from "src/opt-editor/recoils";
import { ASButton } from "allegro-ui";

const Container = styled.div`
  width: 100%;
  height: 100%;
  white-space: "pre-line";
  color: ${(props) => props.theme.primaryColor};
`;

const LogDate = styled.span`
  color: #999;
  opacity: 0.6;
  font-size: 12px;
  min-width: 100px;
  white-space: nowrap;
`;

const LogWrap = styled.div`
  display: flex;
`;

const PreInline = styled.pre`
  display: inline-block;
  white-space: pre-wrap;
`;

export const OELogPlot: React.FC = () => {
  const logLimitSize = 2000;
  const pipeline = usePipeline();
  const cursorSlot = useRecoilValue(cursorSlotState);
  const [logsArr, setLogsArr] = useState<string[]>([]);
  const [dateArr, setDateArr] = useState<string[]>([]);

  const selectPipeline = useRecoilValue(selectPipelineState);
  const [dateOffsetStr, setDateOffsetStr] = useState<string | undefined>();

  const [time, updateTime] = useState(Date.now());

  const ref = useRef<HTMLDivElement>(null);
  // このコールバックを呼び出して ref.current.scrollIntoView() を呼び出してスクロール
  const scrollToBottomOfList = React.useCallback(() => {
    if (ref.current) {
      ref!.current!.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    } else {
      console.log("erro");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      updateTime(Date.now());
      if (cursorSlot) {
        const res = await fetchSystemLogs({
          contractorNo: sessionStorage.contractorNo,
          pipelineName: selectPipeline,
          nodeId: cursorSlot.name,
          apikey: sessionStorage.token,
          offset: dateOffsetStr,
        });

        const regex =
          /(?<date>[0-9]{4}-[0-9]{2}-[0-9]{2}T(?:[0-9]{2}:){2}(?:[0-9]{2})):(?<log>\s*.*)/g;
        const matches = res?.data.stdout.matchAll(regex);
        if (matches) {
          for (const match of matches) {
            setDateOffsetStr(match.groups?.date);
            dateArr.push(`[${match.groups.date}]`);
            logsArr.push(`${match.groups.log}`);

            scrollToBottomOfList();
          }
          if (logsArr.length > logLimitSize) {
            const d = dateArr.slice(dateArr.length - logLimitSize);
            const l = logsArr.slice(logsArr.length - logLimitSize);
            setDateArr(d);
            setLogsArr(l);
          } else {
            setDateArr([...dateArr]);
            setLogsArr([...logsArr]);
          }
        }
      }
    }, 5000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    time,
    pipeline,
    cursorSlot,
    selectPipeline,
    dateOffsetStr,
    logsArr,
    dateArr,
    scrollToBottomOfList,
  ]); // eslint-disable-lne react-hooks/exhaustive-deps
  // if (pipeline.fetchSystemLogs) pipeline.fetchSystemLogs();
  // useEffect(() => {
  //   // clearTimeout(timer);
  //   // loop();
  // }, [pipeline]);

  useEffect(() => {}, [dateArr, logsArr]);

  return (
    <Container
    // dangerouslySetInnerHTML={{
    //   __html: String(logs),
    // }}
    >
      {logsArr.map((str, id) => {
        console.log(str);
        return (
          <div key={`log-row-${id}`}>
            <LogWrap>
              <LogDate> {dateArr[id]}</LogDate>
              <PreInline>{str}</PreInline>
            </LogWrap>
          </div>
        );
      })}
      <div id="bottom-of-list" ref={ref}></div>
      <ASButton
        kind="secondary"
        onClick={() => {
          setLogsArr([]);
          setDateArr([]);
          setDateOffsetStr(undefined);
        }}
      >
        clear log
      </ASButton>
    </Container>
  );
};
