import React from "react";

import { NTMainViewTemplate } from "../templates/NTMainViewTemplate";
import { XRPipelineListPageTemplate } from "src/xerver-room/components/templates/XRPipelineListPageTemplate";

type Props = {};

export const NTPipelineListPage: React.FC<Props> = (props) => {
  return (
    <NTMainViewTemplate>
      <XRPipelineListPageTemplate />
    </NTMainViewTemplate>
  );
};
