import React, { FC } from "react";

type Props = {
  size?: number;
  color?: string;
};

export const HiroshimaLogo: FC<Props> = (props: Props) => {
  return (
    <svg
      width={props.size || 64}
      height={props.size || 64}
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Group</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group">
          <rect
            id="Rectangle"
            x="0"
            y="0"
            width={props.size || 64}
            height={props.size || 64}
          ></rect>
          <path
            d="M33.9336435,0.780902522 C14.6933036,-0.942796084 -6.79414865,21.7105326 5.65342893,46.3968546 C18.1010065,71.0831765 57.3987512,67.4841656 63.5770083,37.1674305 C54.5664642,52.7618737 34.7372372,57.7782824 20.0761788,40.913061 C38.3932366,52.1192783 57.5581612,37.1674305 57.5581612,18.123185 C55.036871,35.7715994 34.5027863,36.2059475 27.8510463,27.1333865 C21.1993063,18.0608256 23.1601932,5.3656675 33.9336435,0.780902522 Z"
            id="Path"
            fill={props.color}
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default HiroshimaLogo;
