import React from "react";
import styled from "styled-components";

import { CHTabs } from "src/cheetah/components/molecules/CHTabs";
import { XRXerviceBrowserContainer } from "../organisms/XRXerviceBrowserContainer";
import { XRServerStatsViewer } from "../organisms/XRServerStatsViewer";
import { useInsightViewer } from "src/xerver-room/hooks/useInsightViewer";
import { XRPageTemplate } from "./XRPageTemplate";
import { INSPECTOR_PAGE_KEY as INSIGHT_PAGE_KEY } from "src/xerver-room/hooks/useServerStatsViewer";
import { useRecoilValue } from "recoil";
import { slotSearchFieldTextState } from "src/xerver-room/recoils";

const Root = styled.div``;

type Props = {
  defaultServerIp?: string;
};

export const XRInsightPageTemplate: React.FC<Props> = (props) => {
  const slotSearchText = useRecoilValue(slotSearchFieldTextState);
  const tabs: any[] = [
    {
      label: "Containers",
      component: (
        <XRXerviceBrowserContainer
          slotFilterType="All"
          showPipelineId
          showStats
          defaultServerIp={props.defaultServerIp ?? slotSearchText}
        />
      ),
      filters: {},
    },
    {
      label: "Servers",
      component: <XRServerStatsViewer />,
    },
  ];

  const { defaultTabId, changeTabId } = useInsightViewer(tabs);

  return (
    <Root>
      <XRPageTemplate title="Insight">
        <CHTabs
          id={INSIGHT_PAGE_KEY}
          tabs={tabs}
          defaultId={defaultTabId}
          handleChange={changeTabId}
          disableBack
        />
      </XRPageTemplate>
    </Root>
  );
};
