//左サイドバーの具体的な実装を記載する。

//

import React from "react";
import styled from "styled-components";
import Drawer from "@material-ui/core/Drawer";
import { NavLink, useLocation } from "react-router-dom";
//mui
import { MDUserButton } from "../molecules/MDUserButton";

import { darken } from "polished";
import { GlobalNavConfig } from "src/medican/config/globalNav";
import { useMyRole } from "src/portal/hooks/useMyRole";
import { MDGlobalNavProps } from "./MDGrobalNav";
import { useUIContext } from "src/fortress/contexts";
import { useAuth } from "src/root";

export const StyledDrawer = styled(Drawer)`
  flex-shrink: 0;
  .MuiDrawer-paper {
    background: ${(props) => props.theme.bgPrimaryColor};
  }
  .MuiDrawer-paperAnchorDockedLeft {
  }
  position: sticky;
  z-index: 1000;
`;

const TextContainer = styled.div`
  padding: 24px 8px;

  display: flex;
  flex-direction: column;
  width: 100%;
  > img {
    display: block;
    max-width: 140px;
    /* margin: 0 auto; */
    margin-bottom: 2px;
  }
  align-items: start;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
`;

const MenuContainer = styled.div`
  > h3 {
    font-size: 12px;
    margin-left: 8px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  border-top: 1px solid #d8d8d8;
  padding-top: 36px;
  bottom: 0px;
  width: 100%;
  padding-bottom: 24px;
`;

const TitleLayout = styled.div`
  /* writing-mode: vertical-rl; */
  letter-spacing: 1;
  text-align: center;
  > span {
    opacity: 0.3;
    font-size: 12px;
  }
`;

const containerWidth = 265;
const containerMargin = 16;
const Root = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  width: ${containerWidth + containerMargin * 2}px;
  height: 100vh;
  padding: ${containerMargin}px;
`;

const Container = styled.div`
  padding: 16px;
  height: 100%;
  background: ${(props) => props.theme.secondaryColor};
  display: flex;
  flex-direction: column;
  /* margin: 16px; */
  border-radius: 24px;
  width: ${containerWidth}px;
  /* height: calc(100vh - 32px); */
  > *:last-child {
    margin-top: auto;
  }
`;

const MenuLabel = styled.span`
  width: 100%;
  margin-left: 16px;
  font-size: 16px;
`;

const AuthCard = styled.div<{ isAdmin: boolean }>`
  padding: 24px;
  border-radius: ${(props) => props.theme.borderRadiusLg}px;
  margin-bottom: 24px;
  background: ${(props) =>
    props.isAdmin ? "black" : props.theme.cautionColor};
  > h1 {
    font-weight: bold;
    font-size: 18px;
    color: #fff;
  }
  > p {
    margin-top: 12px;
    font-size: 12px;
    line-height: 1.5;
    color: #fff;
  }
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;

  a {
    margin-bottom: 2px;
    padding: 8px;
    display: inline-block;
    color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
    text-decoration: none;
    width: 100%;
    align-items: center;
    font-weight: 500;
    vertical-align: middle; /* 上下中央寄せ */
    > * {
      vertical-align: middle; /* 上下中央寄せ */
    }
  }
  a:hover {
    color: ${(props) => darken(0.2, props.theme.primaryColor)};
    transition-property: all;
    transition-duration: 0.3s;
    /* イージングを指定 */
    transition-timing-function: linear;
  }
  a.active {
    color: ${(props) => props.theme.primaryOnText};
    background: ${(props) => props.theme.primaryColor};
    border-radius: ${(props) => props.theme.borderRadiusSm}px;
    transition-property: all;
    transition-duration: 0.3s;
    /* イージングを指定 */
    transition-timing-function: linear;
  }
`;

export const MDGlobalNavDesktopItem: React.FC<GlobalNavConfig> = (props) => {
  const Icon = props.icons;
  const location = useLocation();

  return (
    <NavLink
      to={props.link}
      className={({ isActive, isPending }) =>
        isPending
          ? "pending"
          : isActive || (props.isRoot && location.pathname === "/")
          ? "active"
          : ""
      }
    >
      {props.icons ? <Icon /> : null}

      <MenuLabel>{props.title}</MenuLabel>
    </NavLink>
  );
};

export const MDGrobalNavDesktop: React.FC<MDGlobalNavProps> = ({
  siteName = "サイト名",
  logo,
  subtitle,
  globalNavs,
}) => {
  const auth = useAuth();
  const role = useMyRole();
  const ui = useUIContext();
  return (
    <Root>
      <Container>
        <TextContainer>
          {logo ? <img src={logo} alt={siteName} /> : null}
          <TitleLayout>
            <span>{subtitle}</span>
          </TitleLayout>
        </TextContainer>

        <MenuContainer>
          <h3>MENU</h3>
          {globalNavs.map((conf, idx) => {
            return (
              <Nav key={`global-nav-desktop-${conf.title}-${idx}`}>
                <MDGlobalNavDesktopItem {...conf} />
              </Nav>
            );
          })}
        </MenuContainer>
        <div>
          <AuthCard isAdmin={role.isAdmin}>
            <h1>
              {role.isAdmin
                ? `${ui.words?.character.accountAdmin}`
                : `${ui.words?.character.accountUser}`}
            </h1>
            <p>
              {role.isAdmin
                ? `${ui.words?.character.accountAdmin}でログインしています。${ui.words?.character.answer}の承認を行います。`
                : `${ui.words?.character.accountUser}でログインしています。${ui.words?.character.answer}の登録を行います。`}
            </p>
          </AuthCard>

          <React.Suspense fallback={<>...</>}>
            {auth.token ? <MDUserButton showName /> : null}
          </React.Suspense>
        </div>
      </Container>
    </Root>
  );
};
