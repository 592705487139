import { produce } from "immer";

export type Activity = {
  activityId: string;
  type: "commit" | "update" | "add" | "delete";
  date: string; //ISO
  title: string;
  projectID: string | number;
  projectName: string;
  message: string;
  userNoText: string;
};

export const defaultActivity: Activity = {
  activityId: "uuid-has-not-set",
  type: "add",
  date: new Date().toDateString(),
  title: "-title-",
  projectID: 1,
  projectName: "-project-",
  message: "-message-",
  userNoText: "",
};

export const createActivity = (resData: any): Activity => {
  const a = produce(defaultActivity, (draft) => {
    draft.activityId = resData.activityId ?? draft.activityId;
    draft.date = resData.date;
    draft.title = resData.title;
    draft.message = resData.message;
    draft.projectName = resData.projectName;
    draft.userNoText = resData.userNoText;
    draft.type = resData.type;
  });
  return a;
};

export const sampleActivities: Array<Activity> = [
  {
    activityId: "",
    type: "commit",
    date: new Date().toISOString(),
    title: "MNISTを使って見る.py",
    projectID: "1",
    projectName: "データサイエンス入門コース",
    message: "完成した",
    userNoText: "u350008",
  },
  {
    activityId: "",
    type: "update",
    date: new Date().toISOString(),
    title: "MNISTを使って見る.py",
    projectID: "1",
    projectName: "データサイエンス入門コース",
    message: "ファイルを更新しました",
    userNoText: "u350008",
  },
  {
    activityId: "",
    type: "update",
    date: new Date().toISOString(),
    title: "MNISTを使って見る.py",
    projectID: "1",
    projectName: "データサイエンス入門コース",
    message: "ファイルを更新しました",
    userNoText: "u350008",
  },
  {
    activityId: "",
    type: "add",
    date: new Date().toISOString(),
    title: "MNISTを使って見る.py",
    projectID: "1",
    projectName: "データサイエンス入門コース",
    message: "新規作成",
    userNoText: "u350008",
  },
  {
    activityId: "",
    type: "delete",
    date: new Date().toISOString(),
    title: "MNISTを使って見る.py",
    projectID: "1",
    projectName: "データサイエンス入門コース",
    message: "削除しました",
    userNoText: "u350008",
  },
  {
    activityId: "",
    type: "add",
    date: new Date().toISOString(),
    title: "MNISTを使って見る.py",
    projectID: "1",
    projectName: "データサイエンス入門コース",
    message: "新規作成",
    userNoText: "u350008",
  },
];
