import { IconButton, IconButtonProps } from "@material-ui/core";
import styled from "styled-components";

export type ASIconButtonProps = Omit<IconButtonProps, "color"> & {
  kind?: "positive" | "negative" | "stay" | "white";
  mainColor?: string;
};

//iconボタンの色をallegro指定の色にするように改変

export const FTAIArrow = styled(IconButton).attrs<ASIconButtonProps>(
  (props) => {
    const fillcolor = (() => {
      switch (props.kind) {
        case "positive":
          return "#0099FF";
        case "negative":
          return "#FF0000";
        case "stay":
          return "#00BB00";
        default:
          return "#FFFF88";
      }
    })();

    const darkfillcolor = (() => {
      switch (props.kind) {
        case "positive":
          return "yellow";
        case "negative":
          return "yellow";
        case "stay":
          return "white";
        default:
          return "#FFFFFF";
      }
    })();

    return { fillcolor, darkfillcolor };
  }
)<ASIconButtonProps & { darkfillcolor?: string; fillcolor?: string }>`
  color: ${(props) =>
    props.theme.darkMode ? props.darkfillcolor : props.fillcolor};
`;
