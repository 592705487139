import { InputBase } from "@material-ui/core";
import styled from "styled-components";

export type ASInputBaseProps = {
  textColor?: string;
  isdesktop?: boolean | "true" | "false";
  kind?:
    | "primary"
    | "secondary"
    | "invert"
    | "invertSecondary"
    | "white"
    | "skelton"
    | "outline"
    | "search"
    | "digicho";
};

//@todo neumorphism対応
export const ASInputBase = styled(InputBase).attrs<ASInputBaseProps>(
  ({ isdesktop, ...props }) => {
    const bgcolor = (() => {
      switch (props.kind) {
        case "primary":
          return props.theme.primaryColor;
        case "secondary":
          return props.theme.secondaryColor;
        case "invert":
          return "none";
        case "invertSecondary":
          return "none";
        case "white":
          return "#fff";
        case "search":
          return props.theme.secondaryColor;
        case "outline":
          return "none";
        case "skelton":
          //todo
          // https://react.carbondesignsystem.com/?path=/story/button--skeleton
          break;
        case "digicho":
          return props.theme.secondaryColor;
        default:
          return props.theme.primaryColor;
      }
    })();
    return { bgcolor };
  }
)<ASInputBaseProps & { bgcolor?: string }>`
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  padding: 8px 16px 8px 16px;
  background-color: ${(props) => props.bgcolor};
  /* box-sizing: border-box; */

  min-height: ${(props) =>
    props.kind === "digicho"
      ? props.isdesktop === true || props.isdesktop === "true"
        ? 56
        : 56
      : 36}px;

  //kind = invertならボーダーを描画する
  outline: ${(props) =>
    props.kind === "invert" ||
    props.kind === "invertSecondary" ||
    props.kind === "outline" ||
    props.kind === "digicho"
      ? `1px solid`
      : "none"};
  outline-color: ${(props) => {
    switch (props.kind) {
      case "invert":
        return props.theme.primaryColor;
      case "invertSecondary":
        return props.theme.secondaryColor;
      case "outline":
        return props.theme.darkMode ? "#5f5f5f" : "#d0d0d0";
      case "digicho":
        return props.theme.borderColorField;
      default:
        return "none";
    }
  }};

  &.Mui-focused {
    outline-color: ${(props) => props.theme.borderColorFocused};
    outline-width: 2px;
    outline-offset: -2px;
  }

  &.Mui-error {
    outline-color: ${(props) => props.theme.negativeColor};
    outline-width: 2px;
    outline-offset: -2px;
  }

  &.Mui-disabled {
    outline-color: ${(props) => props.theme.borderColorDisabled};
  }

  min-width: 160px;

  //Selectの▼アイコン
  .MuiSelect-icon {
    margin-right: 8px;
    color: ${(props) => {
      switch (props.kind) {
        case "primary":
          return props.theme?.primaryOnText;
        case "secondary":
          return props.theme?.darkMode ? "white" : props.theme?.secondaryOnText;
        case "invert":
          return props.theme?.secondaryOnText;
        case "search":
          return props.theme?.secondaryOnText;
        case "digicho":
          return props.theme?.darkMode ? "white" : props.theme?.secondaryOnText;
        default:
          return props.theme?.primaryOnText;
      }
    }};
  }
  .MuiInputBase-input {
    //文字色
    color: ${(props) => {
      switch (props.kind) {
        case "primary":
          return props.theme?.primaryOnText;
        case "secondary":
          return props.theme?.darkMode ? "white" : props.theme?.secondaryOnText;
        case "invert":
          return props.theme?.secondaryOnText;
        case "white":
          return "#000";
        case "search":
          return props.theme?.darkMode ? "white" : "black";
        case "outline":
          return props.theme?.darkMode ? "#fff" : "#000";
        case "digicho":
          return props.theme?.darkMode ? "white" : props.theme?.secondaryOnText;
        default:
          return props.theme?.primaryOnText;
      }
    }};
    opacity: ${(props) => (props.disabled ? 0.4 : 1)};
    //文字サイズ
    font-size: ${(props) => props.theme.regularFontSizeMd || 14}px;
  }
`;
