import { ASFileDnD } from "allegro-ui";
import { lighten } from "polished";
import { UploadLogStatus } from "src/newtroid/models";
import styled from "styled-components";
import { MDNoDataPlaceHolder } from "../atoms";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 300px;
  align-items: center;
`;

const DropArea = styled(ASFileDnD)`
  width: 100%;
  height: 75%;
  /* margin-bottom: 2%; */
  color: ${(props) => props.theme.textColor};
  font-size: 12px;
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  border: 1px dashed;
  border-color: ${(props) => props.theme.primaryColor};
`;

const StatusContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 30%;
`;

const StatusBody = styled.div`
  padding: 16px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  color: ${(props) => props.theme.textColor};
  background: ${(props) => lighten(0.05, props.theme.bgPrimaryColor)};
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  border: 1px solid ${(props) => props.theme.secondaryBorderColor};
  > * {
    margin-bottom: 8px;
  }
`;

const LogItem = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  > *:last-child {
    margin-left: auto;
  }
`;

const SuccessText = styled.div`
  color: ${(props) => props.theme.positiveColor};
`;

const FailedText = styled.div`
  color: ${(props) => props.theme.negativeColor};
`;

const NoLogContainer = styled(MDNoDataPlaceHolder)`
  width: 100%;
  height: 100%;
  font-size: 12px;
`;

type Props = {
  validate?: (file: File) => boolean;
  onSubmit: (files: File[]) => void;
  logs?: UploadLogStatus[];
  onClose?: () => void;
};

export const MDFileDropContainer: React.FC<Props> = (props) => {
  const { logs } = props;

  const handleDrop = async (uploaded: File[]) => {
    await props.onSubmit(
      uploaded.filter((file) => {
        const valid = props.validate ? props.validate(file) : true;

        return valid;
      })
    );
  };
  return (
    <Container>
      <DropArea
        onDrop={(item: { files: File[] }) => handleDrop(item.files)}
        mainText="ここにファイルをドラッグ&ドロップしてください"
        hoverText="ここにファイルをドロップしてください"
      ></DropArea>
      <StatusContainer>
        <StatusBody>
          {logs?.map((log) => {
            if (log.status === 200) {
              return (
                <LogItem>
                  <SuccessText>{log.name} </SuccessText>
                  <SuccessText>完了</SuccessText>
                </LogItem>
              );
            } else {
              return (
                <LogItem>
                  <FailedText>{log.name} </FailedText>
                  <FailedText>{log.message ?? "失敗"}</FailedText>
                </LogItem>
              );
            }
          })}
          {logs?.length === 0 ? (
            <NoLogContainer>
              ファイルがアップロードされるとこちらにログが表示されます
            </NoLogContainer>
          ) : (
            <></>
          )}
        </StatusBody>
      </StatusContainer>
    </Container>
  );
};
