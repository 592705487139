import { useEffect, useCallback, useState } from "react";
import {
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import { userListPageIndexState } from "../recoils/atoms/filterState";
import { ASBrowserUiConf } from "../types/LowCodeUiDefinition";
import { newtQueryBase } from "../recoils/atoms/filterState";

import { newtDataSelectorState } from "../recoils/selectors/newtDataSelector";

export type useDataBrowserProps = {
  key?: string;
  ui: ASBrowserUiConf;
};

export const useDataBrowser = (props: useDataBrowserProps) => {
  const { ui } = props;

  const key = props.key ?? "unknown-page";
  const [totalPageCount, setTotalPageCount] = useState(
    ui.pager?.totalCount ?? 1
  );
  const [pageIndex, setPageIndex] = useRecoilState(userListPageIndexState(key));
  const setNewtQueryBase = useSetRecoilState(newtQueryBase(key));
  const newtData = useRecoilValueLoadable(newtDataSelectorState(key));

  //bad implementation
  //サーチは汎用化できていない。

  useEffect(() => {
    setNewtQueryBase(ui);
  }, [setNewtQueryBase, ui]);

  const handlePage: any = useCallback(
    (e: any, page: number) => {
      setPageIndex(page);
    },
    [setPageIndex]
  );

  const hasData =
    newtData.state === "hasValue" && Array.isArray(newtData.contents);
  const hasNextPage =
    hasData &&
    props.ui.pager?.perPage &&
    newtData.contents.length >= props.ui.pager?.perPage;

  const loadablePageCount =
    totalPageCount > 1
      ? totalPageCount
      : hasNextPage
      ? pageIndex + 1
      : pageIndex;

  return {
    loadablePageCount,
    newtData,
    pageIndex,
    handlePage,
  };
};
