import { Tabs } from "@material-ui/core";
import styled from "styled-components";

export const ASClassicTabs = styled(Tabs)`
  .MuiButtonBase-root {
  }

  .MuiTab-root {
    color: ${(props) => props.theme.descriptionTextColor};
    background-color: inherit;
    min-width: 100px;
    /* width: 100px; */
    min-height: 40px;
    height: 40px;
  }
  .MuiTab-wrapper {
    text-transform: none;
  }
  min-height: 40px;
  height: 40px;
  border-radius: 0px;
  .Mui-selected {
    color: ${(props) => props.theme.textColor};
    background: ${(props) => props.theme.bgTertiaryColor};
    border-radius: ${(props) => props.theme.borderRadiusMd}px
      ${(props) => props.theme.borderRadiusMd}px 0px 0px;
    border-top: 1px solid ${(props) => props.theme.secondaryBorderColor};
    border-left: 1px solid ${(props) => props.theme.secondaryBorderColor};
    border-right: 1px solid ${(props) => props.theme.secondaryBorderColor};
  }
  z-index: 3;

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabs-scroller {
  }
`;
