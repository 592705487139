import { useState } from "react";
import { decodeUserNoText } from "src/fortress/utils/userNoEdit";
import { useRoleActions } from "./useRoleActions";

export const useRoleEditor = (userId: string) => {
  const { updateRole } = useRoleActions();
  const [roleId, setRoleId] = useState<string>("");

  const handleSelectRole = (e: any) => {
    setRoleId(e.target.value);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const handleUpdateSelectedRole = async () => {
    const userNumber = decodeUserNoText(userId);
    if (!userNumber) {
      return console.error("invalid user number.");
    }
    try {
      const ok = await updateRole(userNumber, roleId);
      if (ok) {
        alert("success to update role.");
        refreshPage();
      } else {
        alert("failed to update role.");
      }
    } catch (err) {
      alert("failed to update role.");
    }
  };

  return {
    handleSelectRole,
    handleUpdateSelectedRole,
  };
};
