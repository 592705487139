import { Navigate, Route } from "react-router-dom";
import { useAuth } from "src/root";
import { CHLoginPage } from "./pages/CHLoginPage";
import { CHSignUpPage } from "./pages/CHSignUpPage";
import React, { Component, ReactElement } from "react";
import { UserAuthPath } from "./models/userAuthRoutes";
import { CHConfirmEmailPage } from "./pages/CHConfirmEmailPage";
import { CHPrepareResetPasswordPage } from "./pages/CHPrepareResetPasswordPage";
import { CHRescuePasswordPage } from "./pages/CHRescuePasswordPage";
import { CHProvisionalRegisteredPage } from "./pages/CHProvisionalRegisteredPage";
import {
  NTNewtroidTermsOfUse,
  NTPrivacyPolicy,
} from "src/newtroid/components/molecules";

type UserAuthRoutesProps = {
  logo: any;
  appName: string;
  routes: UserAuthPath;
  privacyPolicyComponent?: ReactElement;
  termsOfSeviceComponent?: ReactElement;
};

type Props = {
  homePath: string;
};

const AuthRoute: React.FC<Props> = (props) => {
  const auth = useAuth();

  return (
    <>
      {auth.token && sessionStorage.token ? (
        <Navigate to={props.homePath} replace />
      ) : (
        props.children
      )}
    </>
  );
};

export const createUserAuthRoutes = (props: UserAuthRoutesProps) => {
  const { logo, appName, routes } = props;

  const authComponents: { [key: string]: { path: string; Component: any } } = {
    login: { path: routes.login, Component: CHLoginPage },
    signUp: { path: routes.signUp, Component: CHSignUpPage },
    confirmEmail: { path: routes.confirmEmail, Component: CHConfirmEmailPage },
    provisionEmail: {
      path: routes.provisionEmail,
      Component: CHProvisionalRegisteredPage,
    },
    forgotPassword: {
      path: routes.forgotPassword,
      Component: CHPrepareResetPasswordPage,
    },
    rescuePassword: {
      path: routes.rescuePassword,
      Component: CHRescuePasswordPage,
    },
  };

  const privacyPolicyComponent = props.privacyPolicyComponent ?? (
    <NTPrivacyPolicy />
  );
  const termsOfSeviceCompoennt = props.termsOfSeviceComponent ?? (
    <NTNewtroidTermsOfUse />
  );

  return (
    <>
      <Route
        key={`auth-page-${authComponents.login.path}`}
        path={authComponents.login.path}
        element={
          <AuthRoute homePath={routes.home}>
            <authComponents.login.Component
              logo={logo}
              appName={appName}
              routes={routes}
              privacyPolicyComponent={privacyPolicyComponent}
              termsOfServiceComponent={termsOfSeviceCompoennt}
            />
          </AuthRoute>
        }
      />
      <Route
        key={`auth-page-${authComponents.signUp.path}`}
        path={authComponents.signUp.path}
        element={
          <AuthRoute homePath={routes.home}>
            <authComponents.signUp.Component
              logo={logo}
              appName={appName}
              routes={routes}
              privacyPolicyComponent={privacyPolicyComponent}
              termsOfServiceComponent={termsOfSeviceCompoennt}
            />
          </AuthRoute>
        }
      />
      <Route
        key={`auth-page-${authComponents.confirmEmail.path}`}
        path={authComponents.confirmEmail.path}
        element={
          <AuthRoute homePath={routes.home}>
            <authComponents.confirmEmail.Component
              logo={logo}
              appName={appName}
              routes={routes}
            />
          </AuthRoute>
        }
      />
      <Route
        key={`auth-page-${authComponents.provisionEmail.path}`}
        path={authComponents.provisionEmail.path}
        element={
          <AuthRoute homePath={routes.home}>
            <authComponents.provisionEmail.Component
              logo={logo}
              appName={appName}
              routes={routes}
            />
          </AuthRoute>
        }
      />
      <Route
        key={`auth-page-${authComponents.forgotPassword.path}`}
        path={authComponents.forgotPassword.path}
        element={
          <AuthRoute homePath={routes.home}>
            <authComponents.forgotPassword.Component
              logo={logo}
              appName={appName}
              routes={routes}
            />
          </AuthRoute>
        }
      />

      <Route
        key={`auth-page-${authComponents.rescuePassword.path}`}
        path={authComponents.rescuePassword.path}
        element={
          <AuthRoute homePath={routes.home}>
            <authComponents.rescuePassword.Component
              logo={logo}
              appName={appName}
              routes={routes}
            />
          </AuthRoute>
        }
      />
    </>
  );
};
