import React, { useCallback, useEffect, useState } from "react";
import { XRSettingBlock } from "./XRSettingBlock";
import { ASButton, ASFormLabel, ASInputBase } from "allegro-ui";
import {
  ASMultilineTextFormField,
  ASTextFormField,
} from "allegro-low-code-components";
import { useForm } from "react-hook-form";
import { useLinkObjectList } from "src/xerver-room/hooks/useLinkObjectList";
import { useRecoilValue } from "recoil";
import { cursorLinkObjectIdState } from "src/xerver-room/recoils/linkObjectState";
import { cursorPipelineIdState } from "src/xerver-room/recoils";
import styled from "styled-components";
import { NZDeleteButton } from "src/newtzero-console/components/molecules/NZDeleteButton";
import DeleteIcon from "@material-ui/icons/Delete";

const Container = styled.div`
  padding: 24px 0;
`;

const ButtonLayout = styled.div`
  margin-top: 24px;
`;

type Props = {
  linkObjectId: string;
  pipelineId?: string;
};

export default function XRLinkObjectEditForm({}: Props) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm();
  const cursorLinkObjectId = useRecoilValue(cursorLinkObjectIdState);
  const { fetchOne, updateOne } = useLinkObjectList();
  const cursorPipelineId = useRecoilValue(cursorPipelineIdState);
  const [currentLinkObject, setCurrentLinkObject] = useState<any>();
  //   const handleSubmit = () => {};
  const linkObjectHandler = useLinkObjectList();

  const updateThisStates = useCallback(async () => {
    const lo = await fetchOne(cursorLinkObjectId);
    setCurrentLinkObject(lo);
  }, [cursorLinkObjectId, fetchOne]);

  useEffect(() => {
    updateThisStates();
    return () => {
      setCurrentLinkObject(undefined);
    };
  }, [updateThisStates]);

  useEffect(() => {
    if (!currentLinkObject) return;
    setValue("name", currentLinkObject.name);
    setValue("url", currentLinkObject.url);
  }, [currentLinkObject, setValue]);

  return (
    <Container>
      <form
        onSubmit={handleSubmit(async (values: any) => {
          console.log({ values, currentLinkObject });
          await updateOne(currentLinkObject.linkObjectId, {
            ...currentLinkObject,
            ...values,
            pipelineId: cursorPipelineId,
          });
          window.location.reload();
        })}
      >
        <XRSettingBlock title={"LinkObject Settings"}>
          <ASTextFormField
            error={""}
            supportText=""
            active={""}
            control={control}
            // values={(name?: string): ReferenceData[] => {
            //   let metrics: string[] = props.metrics ?? [];
            //   if (name) {
            //     metrics = metrics.filter((metric) => metric.includes(name));
            //   }
            //   console.log({ metrics, name });

            //   return metrics.map((metric) => {
            //     return {
            //       label: metric,
            //       value: metric,
            //     };
            //   });
            // }}
            name={"name"}
            label={"Name"}
            placeholder="Please write name here."
            editable
            type={"text"}
            defaultValue={currentLinkObject ? currentLinkObject.name : ""}
            // inputOnly
            design="digicho"
          />
          <ASTextFormField
            error={""}
            supportText="接続されたOptXにURLを環境変数として渡します。"
            active={""}
            control={control}
            // values={(name?: string): ReferenceData[] => {
            //   let metrics: string[] = props.metrics ?? [];
            //   if (name) {
            //     metrics = metrics.filter((metric) => metric.includes(name));
            //   }
            //   console.log({ metrics, name });

            //   return metrics.map((metric) => {
            //     return {
            //       label: metric,
            //       value: metric,
            //     };
            //   });
            // }}
            name={"url"}
            label={"URL"}
            placeholder="https://google.com/api/1"
            editable
            type={"text"}
            // inputOnly
            defaultValue={currentLinkObject ? currentLinkObject.url : ""}
            design="digicho"
          />

          {/* <ASMultilineTextFormField
            // defaultValue={previewData}
            type="text"
            label="Description"
            name="preview"
            error={""}
            editable={true}
            design="digicho"
            supportText=""
            control={control}
            required={false}
          /> */}

          <ButtonLayout>
            <ASButton
              kind={"secondary"}
              type="submit"
              fullWidth
              // disabled={!formData || disabled}
              onClick={() => {
                //   if (formData) saveVolume(formData);
              }}
            >
              SAVE
            </ASButton>
          </ButtonLayout>
        </XRSettingBlock>
        <XRSettingBlock title={"Danger Zone"}>
          <NZDeleteButton
            kind="negative"
            // fullWidth
            // disabled={!formData || disabled}
            onSubmit={async () => {
              await linkObjectHandler.remove(cursorLinkObjectId);
              window.location.reload();
              // slot.deleteSlot(cursorSlot);
            }}
          >
            <DeleteIcon /> DELETE
          </NZDeleteButton>
        </XRSettingBlock>
      </form>
    </Container>
  );
}
