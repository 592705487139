//Libs
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { atom, useRecoilState } from "recoil";

//Components
import { FormControl } from "@material-ui/core";
import { ASInputBase, ASButton, ASIconButton } from "allegro-ui";
//States
//Types
import { Environments } from "src/xerver-room/models";
import { useSlotActions } from "src/xerver-room/hooks";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import produce from "immer";
import { ASFormLabel } from "allegro-low-code-components";

const Form = styled.div`
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 100%;
  width: 300px;
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow-wrap: break-word;
  > * {
    margin-top: 24px;
  }
`;

type Props = {
  slotId: string;
  disabled: boolean;
};

const StyledFormLabel = styled(ASFormLabel)`
  margin-bottom: 4px;
  &.Mui-focused {
    color: ${(props) => props.theme.textColor};
  }
  .Mui-disabled {
    color: ${(props) => props.theme.disabledColor};
  }
`;

const StyledInputBase = styled(ASInputBase)`
  &.Mui-disabled {
    /* outline-color: ${(props) => props.theme.disabledColor}; */
  }
`;

const EnvironmentClipBoard = atom<Environments>({
  key: "EnvironmentClipBoard",
  default: {},
});

export const XREnvironmentSettingForm: React.FC<Props> = ({
  slotId,
  disabled,
}) => {
  const slot = useSlotActions(slotId);
  const { saveEnvironments } = slot;

  const [formData, setFormData] = useState<Environments>({});
  const [envClip, setEnvClip] = useRecoilState(EnvironmentClipBoard);

  //init form
  useEffect(() => {
    setFormData(slot.environments);
  }, [slot.environments, slotId]);

  const copyEnv = () => {
    setEnvClip(formData);
  };

  const pasteEnv = () => {
    const result = produce(formData, (draft) => {
      Object.keys(draft).forEach((key) => {
        if (envClip[key]) draft[key] = envClip[key];
      });
    });
    setFormData(result);
  };

  return (
    <>
      <ASIconButton
        size="small"
        kind="secondary"
        onClick={() => {
          copyEnv();
        }}
      >
        <FileCopyOutlinedIcon />
      </ASIconButton>
      <ASIconButton
        size="small"
        kind="secondary"
        onClick={() => {
          pasteEnv();
        }}
      >
        <AssignmentTurnedInOutlinedIcon />
      </ASIconButton>
      <Form>
        {Object.keys(slot.environments).map((key, i) => {
          const value = (formData[key] !== null && formData[key] !== undefined)  ? String(formData[key]) : undefined
          return (
            <FormControl key={`env-set-form-${i}`}>
              <StyledFormLabel disabled={disabled}>{key}</StyledFormLabel>
              <StyledInputBase
                disabled={disabled}
                id="id"
                kind="digicho"
                placeholder="入力してください"
                value={value}
                onChange={(e) => {
                  setFormData((f) => {
                    return { ...f, [key]: e?.target.value };
                  });
                }}
              />
            </FormControl>
          );
        })}
        <ASButton
          kind={"secondary"}
          onClick={() => {
            saveEnvironments(formData);
          }}
          disabled={disabled}
        >
          SAVE
        </ASButton>
        <ASButton
          kind="secondary"
          onClick={() => {
            if (slot.optXId) slot.setOptX(slot.optXId);
          }}
          disabled={disabled}
        >
          Reload
        </ASButton>
      </Form>
    </>
  );
};
