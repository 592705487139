//Libs

import React from "react";
import { PTSettingsPageTemplate } from "../templates/PTSettingsPageTemplate";

import { PTSettingTeamTemplate } from "../templates/PTSettingTeamTemplate";

type Props = {};
export const PTSettingTeamPage: React.FC<Props> = (props) => {
  return (
    <>
      <PTSettingsPageTemplate>
        <PTSettingTeamTemplate />
      </PTSettingsPageTemplate>
    </>
  );
};
