import React from "react";
import styled from "styled-components";
import { FTCheckBox } from "./FTCheckBox";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { ASIconButton } from "allegro-ui";

type Props = {
  question: string;
  answers: string[];
  category: 1 | 2 | 3 | 4;
};

const Question = styled.div`
  font-size: 20px;
  padding-top: 13px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const Form = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`;

const FlexTitle = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 18px;
  margin-top: 24px;
  margin-bottom: 8px;
`;

//新規アセスメント「作成」画面のチェックボックス欄のコンポーネントの一部

export const FTCheckForm: React.FC<Props> = (props) => {
  const { question, answers, category } = props;

  return (
    <Form>
      <FlexTitle>
        <Question>{question}</Question>
      </FlexTitle>
      {answers.map((ans, id) => {
        return (
          <Flex key={String(id)}>
            <FTCheckBox
              answers={answers}
              id={id}
              question={question}
              category={category}
            ></FTCheckBox>
            <div>{ans}</div>
          </Flex>
        );
      })}
    </Form>
  );
};
