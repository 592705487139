import React from "react";
import Modal, { ModalProps } from "@material-ui/core/Modal";
import styled, { DefaultTheme } from "styled-components";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

type StyledModalProps = {
  theme: DefaultTheme;
};

const StyledModal = styled(Modal).attrs<StyledModalProps>(
  (props) => {}
)<StyledModalProps>`
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid ${(props) => props.theme.secondaryBorderColor};
`;

type StyledDivProps = {
  theme: DefaultTheme;
};

const StyledIconButton = styled(IconButton)`
  width: 25px;
  height: 25px;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 56px;
  background: ${(props) => props.theme.bgPrimaryColor};
  border-radius: ${(props) => props.theme?.borderRadiusLg}px
    ${(props) => props.theme?.borderRadiusLg}px 0px 0px;
  border-bottom: 1px solid ${(props) => props.theme.tertiaryBorderColor};
  padding: 0 25px;
`;

const Title = styled.div`
  width: 100%;
  font-size: ${(props) => props.theme.regularFontSizeLg}px;
  color: ${(props) => props.theme.textColor};
  font-weight: bold;
`;

const StyledDiv = styled.div.attrs<StyledDivProps>(
  (props) => {}
)<StyledDivProps>`
  position: absolute;
  color: ${(props) => props.theme.textColor};
  background-color: ${(props) => props.theme?.bgPrimaryColor};
  min-width: 300px;
  min-height: 200px;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: ${(props) => props.theme?.borderRadiusLg}px;
  border: 1px solid ${(props) => props.theme?.secondaryBorderColor};
`;

export type ASModalProps = ModalProps & {
  kind?: "primary" | "secondary";
  content?: JSX.Element;
};

export const ASModal: React.FC<ASModalProps> = ({
  children,
  kind = "primary",
  onClose,
  ...props
}) => {
  const body = (
    <StyledDiv>
      <Header>
        <Title>{props.title}</Title>
        <StyledIconButton
          onClick={(e) => {
            if (e && onClose) onClose(false, "backdropClick");
          }}
          color="inherit"
        >
          <CloseIcon style={{ width: "20px", height: "20px" }} />
        </StyledIconButton>
      </Header>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </div>
    </StyledDiv>
  );

  return <StyledModal {...props} children={body} />;
};
