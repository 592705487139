import { api } from "src/api";

type UpsertArg = {
  name: string;
  text: string;
};

export const useFileXActions = () => {
  const update: (fileId: string, arg: UpsertArg) => Promise<boolean> = async (
    fileId,
    arg
  ) => {
    const res = await api({
      method: "PUT",
      url: `/contractors/playground/optx/opt_220322yZzjkSg4w0d5efCu/file/${fileId}`,
      params: {
        apikey:
          "9A600E8D9340F520F9A6B350EF821D2B40B5EE6A59B3D7CD21E261E3974B57B8",
        "": "",
      },
      headers: { "Content-Type": "application/json" },
      data: arg,
    });
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  };

  const add: (
    optXId: string,
    arg: UpsertArg,
    upsert?: "true" | "false"
  ) => Promise<boolean> = async (optXId, arg, upsert = "false") => {
    const res = await api({
      method: "POST",
      url: `/contractors/${sessionStorage.contractorNo}/optx/${optXId}/file`,
      params: {
        apikey: sessionStorage.token,
        upsert: String(upsert),
      },
      headers: { "Content-Type": "application/json" },
      data: arg,
    });
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  };

  const upsert = (optXId: string, arg: UpsertArg) => {
    return add(optXId, arg, "true");
  };

  const remove = async (fileId: string) => {
    const res = await api({
      method: "PUT",
      url: `/contractors/${sessionStorage.contractorNo}/optx/opt_220322yZzjkSg4w0d5efCu/file/${fileId}`,
      params: {
        apikey: sessionStorage.token,
      },
      headers: { "Content-Type": "application/json" },
    });
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  };

  return { update, add, remove, upsert };
};
