import styled from "styled-components";
import logo from "src/assets/newtroid-logo-small-capital-ver.svg";

import React, { FC } from "react";

type ContractorLogoProps = {
  tiny?: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-left: 3px;
  }
`;

const Title = styled.div`
  /* margin-left: 8px; */
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.7;
  }
  font-size: 15px;
  font-weight: bold;
  padding: 11px;
  background: ${(props) => (props.theme.darkMode ? "#111" : "#fff")};
  border-radius: 4px;
  margin-right: 8px;
  font-weight: 600;
  letter-spacing: 0.7px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
`;

const LogoContainer = styled.div.attrs<ContractorLogoProps>(
  (props) => {}
)<ContractorLogoProps>`
  font-size: ${(props) => (props.tiny ? 11 : 18)}px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
  display: flex;
  flex-direction: row;
  letter-spacing: 0.7px;
  font-style: ${(props) => (props.tiny ? "italic" : "")};
  align-items: center;

  > img {
    width: ${(props) => (props.tiny ? 60 : 110)}px;
    margin-top: -3px;
  }

  > * {
    margin-right: 3px;
  }
`;

export const ConsoleLogo = styled.h1.attrs<ContractorLogoProps>(
  (props) => {}
)<ContractorLogoProps>`
  /* margin-left: 8px; */
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.7;
  }
  > img {
    margin-top: -3px;
    width: 110px;
  }

  font-weight: 400;
`;

type Props = {
  contractorNo?: string;
  // subcomponent: React.ReactElement;
};

export const NTHeaderLogo: React.FC<ContractorLogoProps> = (props) => {
  const width = props.tiny ? 70 : 110;
  return (
    <LogoContainer tiny={props.tiny}>
      <div>{props.tiny ? "by" : ""} </div>
      <img src={logo} alt="Newtroid" />
      <ConsoleLogo tiny={props.tiny}>Console</ConsoleLogo>
    </LogoContainer>
  );
};

export const LogoWithContractor: FC<Props> = (props: Props) => {
  const headerTitle = (props.contractorNo ?? "").toLocaleUpperCase();

  return (
    <Container>
      {props.contractorNo ? (
        <>
          <Title>{`${headerTitle}`}</Title>
          <NTHeaderLogo tiny />
        </>
      ) : (
        <NTHeaderLogo />
      )}
    </Container>
  );
};

// export default NewtroidLogo;
