import React from "react";
import styled from "styled-components";
import Masonry from "react-masonry-css";
import { PersonalData } from "src/fortress/models";

const MasonryGrid = styled(Masonry)`
  display: flex;
  margin-left: -30px;
  margin-top: -30px;
`;

type Props = {
  data: PersonalData[];
};

export const FTCustomDataPanel: React.FC<Props> = ({ data }) => {
  return (
    <MasonryGrid
      breakpointCols={2}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    ></MasonryGrid>
  );
};
