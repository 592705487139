import React, { useState } from "react";
import { ASButton, ASModal } from "allegro-ui";
import { Box } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { useRecoilValue, useRecoilRefresher_UNSTABLE } from "recoil";
import styled from "styled-components";
import scienceFolder from "../../recoils/ScienceFolderState";
import { useFileMediator } from "src/opt-editor/hooks/useFileMediator";
import scienceFile from "src/opt-editor/recoils/ScienceFileState";
import { DeleteConfirmModalContents } from "src/kinzaza/components";

const NegativeMenuItem = styled(MenuItem)`
  color: ${(props) => props.theme.negativeColor};
`;

type Props = {
  showChild: any;
  isDeleteFile?: boolean;
  onMenuClose: () => void;
};

export const OEDeleteRightClickMenu: React.FC<Props> = (props) => {
  // モーダル画面のT/F
  const [showModal, setShowModal] = useState(false);
  // 削除処理api
  const cursorFolderId = useRecoilValue(scienceFolder.cursorItemIdState);
  const refreshTreeList = useRecoilRefresher_UNSTABLE(scienceFolder.idList);

  const coursorFileId = useRecoilValue(scienceFile.cursorItemIdState);
  const fileComplexActions = useFileMediator();
  // const folderActions = useFolderActions();
  const MSG_DELETE_CONFIRM = `選択した${
    props.isDeleteFile ? "ファイル" : "フォルダ"
  }を削除しますか？`;

  const handleDelete = async () => {
    console.log(cursorFolderId);
    const deleteId = props.isDeleteFile ? coursorFileId : cursorFolderId;

    if (props.isDeleteFile) {
      console.log("delete file", deleteId);
      await fileComplexActions.remove(deleteId);
      setShowModal(false);
    } else {
      if (Number(deleteId) >= 0) {
        //@todo TODO scienceFolder.deleteの実装、呼び出し
        await scienceFolder.deleteItem(deleteId);
        refreshTreeList();
      } else {
        console.error("IDが見つかりません", deleteId);
      }
      setShowModal(false);
    }
  };

  const handleMenu = () => {
    setShowModal(true);
    props.showChild({ mouseX: null, mouseY: null });
    props.onMenuClose();
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <NegativeMenuItem onClick={handleMenu}>Delete</NegativeMenuItem>
      <ASModal open={showModal} onClose={handleClose}>
        <DeleteConfirmModalContents
          onCancel={handleClose}
          onSubmit={() => handleDelete()}
        >
          {MSG_DELETE_CONFIRM}
        </DeleteConfirmModalContents>
      </ASModal>
      <Box sx={{ mb: 1 }}></Box>
    </>
  );
};
