import React from "react";
import { NAMainViewTemplate } from "../templates/NAMainViewTemplate";
import { NAAIManagement } from "../organisms/NAAIManagement";

export const NAAISingleManagementPage: React.FC = () => {
  return (
    <NAMainViewTemplate>
      <NAAIManagement />
    </NAMainViewTemplate>
  );
};
