import { api } from "../../api/http";
/**
 * 児童を検索する
 * @param {string} word 検索ワード
 * @return {object} レスポンスデータ
 */
export const dataRemove = async (
  collection: string,
  ids: string[]
): Promise<[boolean, string | undefined]> => {
  try {
    const res = await api(`/streamgate`, {
      method: "delete",
      params: {},
      data: {
        collection,
        dataIds: ids,
      },
    });
    //TODO エラーハンドル
    console.log("[DELETE]/streamgate", res.status);
    console.log(res.data);

    return [true, undefined];
  } catch (err) {
    console.error(err);
    return [false, "failed delete."];
  }
};


/**
 * 推奨。newtQueryベースでデータを削除可能.
 * @param assetId 
 * @param searchOptions 
 * @returns 
 */
export const dataDelete = async (assetId: string, searchOptions?: any) => {
  const res = await api({
    method: "DELETE",
    url: `/streamgate/deletedata`,

    data:{
      assetId:assetId,
      search: {...searchOptions}
    },
    headers: { "Content-Type": "application/json" },
  });
  console.log({ status: res.status });

  return res.status >= 200 && res.status < 300;
};


export const dataRemove__DEPRECATED = async (
  assetTags: string[],
  searchOptions?: any
) => {
  const res = await api({
    method: "DELETE",
    url: `/contractors/${sessionStorage.contractorNo}/datamanage/topdatas`,
    params: {
      apikey: sessionStorage.token,
      q: {
        where: {
          // $and: tags.map((tag: string) => {
          //   return { "bases.tags": tag };
          // }),

          $and: assetTags.map((tag: string) => {
            return { "bases.tags": tag };
          }),
          ...searchOptions,
        },
      },
    },
    headers: { "Content-Type": "application/json" },
  });

  console.log({ status: res.status });

  return res.status >= 200 && res.status < 300;
};
