import { FormLabel } from "@material-ui/core";
import styled from "styled-components";

export const ASFormLabel = styled(FormLabel)`
  margin-bottom: 2px;
  display: inline-block;
  font-size: ${(props) => props.theme.regularFontSize ?? 14}px;
  width: 100%;
  line-height: 1.5em;
  color: ${(props) => props.theme.textColor};

  &.MuiFormLabel-root {
    color: ${(props) => props.theme.textColor};
    &.Mui-disabled {
      color: ${(props) => props.theme.disabledColor};
    }
  }
`;
