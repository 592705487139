import styled, { DefaultTheme } from "styled-components";
import { Chip, ChipProps } from "@material-ui/core";
import { defaultTheme, isTheme } from "../themes";
import { darken, lighten } from "polished";

//Omit< T, 'propA' | 'propsB' ,,,>Tから特定のプロパティを除外した型
export type ASChipProps = Omit<ChipProps, "kind"> & {
  kind?: "negative" | "caution" | "positive" | "disabled";
  design?: "highcontrast" | "onecolor";
  theme: DefaultTheme;
  small?: boolean | "true" | "false";
};

const defaultProps: ASChipProps = {
  kind: "positive",
  design: "highcontrast",
  theme: defaultTheme,
  small: false,
};

type Attrs = {
  border_color: string;
  text_color: string;
  bg_color: string;
};

type StyledProps = ASChipProps & Attrs;

export const ASChip = styled(Chip).attrs(
  (props: ASChipProps = defaultProps): Attrs => {
    //使用する色を決定する
    const border_color: string = (() => {
      if (!isTheme(props.theme))
        throw new Error("invalid theme object were given.");
      switch (props.kind) {
        case "negative":
          return props.theme.negativeColor;
        case "caution":
          return props.theme.cautionColor;
        case "positive":
          return props.theme.positiveColor;
        case "disabled":
          return "#999";
        default:
          return props.theme.primaryColor;
      }
    })();

    //文字の色、背景色を指定されたデザインに設定する
    const text_color: string = (() => {
      switch (props.design) {
        case "highcontrast":
          return "#fff";
        case "onecolor":
          return border_color;
        default:
          return "#fff";
      }
    })();
    const bg_color: string = (() => {
      switch (props.design) {
        case "highcontrast":
          return border_color;
        case "onecolor":
          return lighten(0.32, border_color);
        default:
          return border_color;
      }
    })();
    return { border_color, text_color, bg_color };
  }
)<StyledProps>`
  font-size: ${(props) => props.theme?.regularFontSizeMd}px;
  height: ${(props) => (props.small ? 20 : 30)}px;
  padding: 0;

  background-color: ${(props) =>
    props.theme.darkMode
      ? darken(0.35, props.theme.primaryColor)
      : props.bg_color};
  color: ${(props) =>
    props.theme.darkMode ? props.theme.primaryColor : props.text_color};
  border: 1px solid ${(props) => props.border_color};
`;
