//Libs
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
// import routes from "../../routes.json";

//Components
import { Link } from "react-router-dom";
import logo from "src/assets/newtroid-logo-small-capital-ver.svg";
import { OEUserButton } from "src/opt-editor/components";

//States
import { useRecoilValue } from "recoil";
import { authState } from "src/root/recoils/userState";

import routes from "src/newtroid/routes.json";

//Types

const APP = process.env.REACT_APP_APP;
export const headerOffset = 56;

const Divider = styled.div`
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
  opacity: 0.3;
  margin-left: 16px;
  margin-right: 16px;
`;

const Container = styled.div`
  /* width: 100%; */
  height: ${headerOffset}px;
  background: ${(props) => props.theme.bgPrimaryColor};
  /* padding: 0 30px; */
  /* border-bottom: 1px solid #37383a; */
  /* position: fixed; */
  display: flex;
  align-items: center;
`;

const AppBar = styled.div`
  width: 100%;
  padding: 0 16px;
  position: fixed;
  z-index: 999;
  height: ${headerOffset}px;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.bgSecondaryColor};
  border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};
`;

const LogoAndTitle = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  /* margin-left: 8px; */
  cursor: pointer;
  > * {
    display: inline;
  }
  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.7;
  }
  font-size: 18px;
  > img {
    margin-top: -3px;
    width: 110px;
  }

  font-weight: 400;
  letter-spacing: 1px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
`;

const MenuItem = styled.li`
  color: #fff;
  list-style-type: none;
  > a {
    color: #fff;
    text-decoration: none;
    margin-right: 13px;
    margin-left: 13px;
    font-size: 14px;
    font-weight: 500;

    /* font-weight: bold; */
    &:hover {
      opacity: 0.8;
    }
  }
`;

const MenuGroup = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const Skelton = styled.div`
  clear: both;
  position: relative;
  /* width:100%: */
  /* height: ${headerOffset}px; */
`;

type Props = {};
export const NTGlobalNav: React.FC<Props> = (props) => {
  const auth = useRecoilValue(authState);
  const history = useNavigate();
  // const color = theme.darkMode ? "white" : "black";

  const handleLogoClick = () => {
    history("/");
  };

  return (
    <>
      <Container>
        <AppBar>
          <LogoAndTitle onClick={handleLogoClick}>
            {/* <Logo size={28} /> */}
            <Title>
              <img src={logo} alt="Newtroid" /> Console
            </Title>
          </LogoAndTitle>
          {auth.token ? (
            <MenuGroup>
              <MenuItem>
                <Link to={routes.KNOWLEDGE_ROOT}>Knowledge</Link>
              </MenuItem>

              {/* <MenuItem>
                <a
                  href={`${https}://${auth.contractorNo}.${domain}/admin/xervice`}
                >
                  Xervice
                </a>
              </MenuItem> */}
              <Divider>|</Divider>
              <MenuItem>
                <Link to={routes.PIPELINES}>Xervice</Link>
              </MenuItem>
              <MenuItem>
                <Link to={routes.OPTX_BROWSER}>OptX</Link>
              </MenuItem>
              <MenuItem>
                <Link to={routes.ADMIN_XERVICE_BROWSER}>Insight</Link>
              </MenuItem>
              <Divider>|</Divider>
              <MenuItem>
                <Link to={routes.DEVOPS}>Infrastructure</Link>
              </MenuItem>
              <Divider>|</Divider>
              <MenuItem>
                <Link to={routes.DATAOPS}>DataControl</Link>
              </MenuItem>
              <MenuItem>
                <Link to={routes.LAKEHOUSE}>DataView</Link>
              </MenuItem>
              <React.Suspense fallback={<>...</>}>
                {auth.token ? <OEUserButton /> : <></>}
              </React.Suspense>
            </MenuGroup>
          ) : (
            <></>
          )}
        </AppBar>
      </Container>
      <Skelton></Skelton>
    </>
  );
};
