import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { shapeToSeireki } from "../../utils/time";
import LensOutlinedIcon from "@material-ui/icons/LensOutlined";

const Name = styled.span`
  font-weight: bold;
  font-size: 18px;
  margin-left: 24px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  position: relative;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
  cursor: pointer;
  :hover {
    opacity: 0.8;
    .dot {
      /* left: -26px; */
      /* width: 12px; */
      /* height: 18px; */
      transition: all 0.1s 0s ease-in-out;
    }
  }
`;

const ChildName = styled.div`
  max-width: 250px; /* 省略せずに表示するサイズを指定 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
  letter-spacing: 1.4px;
`;

const Age = styled.span`
  position: relative;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: 6px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
`;

const Birth = styled.span`
  /* font-weight: bold; */
  position: relative;
  font-size: 14px;
  margin-left: 24px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
  letter-spacing: 0.3px;
`;

const Caption = styled.div`
  color: #999;
  font-size: 14px;
`;

const Blueicon = styled.div`
  width: 6px;
  height: 36px;
  background-color: #4f6fff;
  /* border: 2px solid ${(props) => props.theme.bgPrimaryColor}; */
  /* color: #4f6fff; */
  border-radius: 4px;
  margin-left: 10px;
  position: absolute;
  left: -32px;
  top: 0px;
`;

const Pinkicon = styled(Blueicon)`
  /* width: 8px;
  height: 8px; */
  background-color: #f84fa4;
  /* color: #f84fa4; */
  /* border-radius: 50%;
  margin-left: 10px; */
  /* position: absolute; */
  /* left: -24px; */
  /* top: 4px; */
`;

const DeleteBudge = styled.div`
  background: #000;
  color: #fff;
  border-radius: 8px;
  font-size: 13px;
  padding: 0px 5px;
  margin-left: 2px;
  min-width: 54px;
  text-align: center;
`;

var calcAge = function (birthday?: Date) {
  try {
    if (!birthday || Number.isNaN(birthday.getTime())) {
      return -1;
    }

    var today = new Date();
    var dStr =
      "" +
      today.getFullYear() +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      ("0" + today.getDate()).slice(-2);
    return (
      today.getFullYear() -
      birthday.getFullYear() -
      Number(
        today.getMonth() < birthday.getMonth() ||
          (today.getMonth() === birthday.getMonth() &&
            today.getDate() < birthday.getDate())
      )
    );
  } catch (err) {
    console.error(err);
  }
};

export interface ILCHumanNameThumbComponentProps {
  data: {
    userNo: string | number;
    name: string | 0;
    nameSub: string;
    birth: number;
    sex: "男性" | "女性";
    atena: string;
    deleteCode: number;
  };
  options: {
    path: string;
  };
}

const parsePath = (path: string, data: { [key: string]: any }): string => {
  const regex = /{([^}]+)}/g;
  return path.replace(regex, (_, match) => data[match]);
};

export default function LCHumanNameThumbComponent(
  props: ILCHumanNameThumbComponentProps
) {
  const history = useNavigate();

  const male = ["男性", "male"];

  const handleClick = () => {
    const path = parsePath(props.options.path, props.data);
    history(path);
  };

  const birth = new Date(props.data.birth);
  const age = calcAge(birth);

  return (
    <div onClick={() => handleClick()}>
      <Name>
        {male.includes(props.data.sex) ? (
          <Blueicon className="dot" />
        ) : (
          <Pinkicon className="dot" />
        )}

        <ChildName>
          {props.data.name !== 0 ? props.data.name : props.data.nameSub}
        </ChildName>
        <Age>{age && age >= 0 ? `(${age})` : null} </Age>
        {props.data.deleteCode === 2 ? <DeleteBudge>消除者</DeleteBudge> : null}
      </Name>
      <Birth>{props.data.birth ? shapeToSeireki(birth) : null}</Birth>
      {/* <h2>{props.data.atena}</h2> */}
    </div>
  );
}
