import React from "react";
import { NAAISingleTemplate } from "../templates";
import {
  CHTabComponentProps,
  CHTabs,
} from "src/cheetah/components/molecules/CHTabs";

export const NAAIOperation: React.FC = () => {
  const tabs: CHTabComponentProps[] = [
    {
      label: "Trail",
      component: <></>,
    },
    {
      label: "Version Evaluation",
      component: <></>,
    },
  ];
  return (
    <>
      <NAAISingleTemplate>
        <CHTabs tabs={tabs} disableBack />
      </NAAISingleTemplate>
    </>
  );
};
