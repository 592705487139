import React from "react";
import Select, { SelectProps } from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import styled, { DefaultTheme } from "styled-components";
import { ASInputBase } from "./ASInputBase";

export type ASSelectItem = {
  value: string;
  label: string;
  disabled?: boolean;
};

export type ASSelectProps = SelectProps & {
  theme?: DefaultTheme;
  primary?: boolean;
  label?: string;
  default?: string;
  kind?: "primary" | "secondary" | "digicho";
  data: ASSelectItem[];
  placeHolder?: string;
};

const StyledLabel = styled(InputLabel)`
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
`;

export const ASSelect: React.FC<ASSelectProps> = (props) => {
  const {
    theme,
    primary = false,
    label = "",
    data,
    kind = "primary",
    onChange,
    placeHolder,
  } = props;

  const selections = data.map((d) => {
    return (
      <MenuItem key={`k${d.value}`} value={d.value} disabled={d.disabled}>
        {d.label}
      </MenuItem>
    );
  });

  return (
    <>
      <StyledLabel id="label">{label}</StyledLabel>
      <Select
        {...props}
        labelId="label"
        id="select"
        displayEmpty
        onChange={onChange}
        input={<ASInputBase kind={kind} />}
      >
        <MenuItem value="" disabled>
          {placeHolder ?? "Select"} {label}
        </MenuItem>
        {selections}
      </Select>
    </>
  );
};
