import { AxiosInstance } from "axios";
import { getMany, getOne } from "./select";
import { wrapAsync } from "../../libs/wrapper";
import { fetchFiles, fetchOptXConf } from "./fetchFile";
import { update } from "./update";
import { create } from "./create";
import { deleteOne } from "./delete";

export const OptX = (instance: AxiosInstance) => {
  return {
    getOne: wrapAsync(instance, getOne),
    getMany: wrapAsync(instance, getMany),
    fetchFiles: wrapAsync(instance, fetchFiles),
    fetchOptXConf: wrapAsync(instance, fetchOptXConf),
    update: wrapAsync(instance, update),
    create: wrapAsync(instance, create),
    remove: wrapAsync(instance, deleteOne),
  };
};
