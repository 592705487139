type Authorities = {
  authority: string;
};

type Profile = {
  userRealName: string;
  lang: string;
};

export type UserList = {
  authorities: Array<Authorities>;
  _id: string;
  className: string;
  userNo: number;
  debugMemo: string;
  userNoText: string;
  username: string;
  avatar_id: string;
  groups: any[];
  profile: Profile;
  contractorNo: string;
  lastUpdated: string;
  _created: number;
  _modified: number;
  lastUpdatedFileName: string; // TODO 定義仕様にないがUI表示項目あり
};

export const sampleUserList: Array<UserList> = [
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25a",
    className: "models.User",
    userNo: 38,
    debugMemo: "",
    userNoText: "u3500038",
    username: "kiichi.yamada@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "CNNの実装.py",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25b",
    className: "models.User",
    userNo: 39,
    debugMemo: "",
    userNoText: "u4500039",
    username: "taro@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "手書き文字認識の実施.py",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25c",
    className: "models.User",
    userNo: 40,
    debugMemo: "",
    userNoText: "u5500040",
    username: "jiro@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "softmax関数について.py",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 41,
    debugMemo: "",
    userNoText: "u6500041",
    username: "hanakok@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "Pythonのインストール方法.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 42,
    debugMemo: "",
    userNoText: "u7500042",
    username: "sabu@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "プログラム入門.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 43,
    debugMemo: "",
    userNoText: "u8500043",
    username: "taka@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "コーディングルール.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 44,
    debugMemo: "",
    userNoText: "u9500044",
    username: "yuuta@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "セキュリティについて.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 45,
    debugMemo: "",
    userNoText: "u1000023",
    username: "kirari@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "AI学習.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 46,
    debugMemo: "",
    userNoText: "u2200333",
    username: "takumi@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "計算式検討.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 47,
    debugMemo: "",
    userNoText: "u2000041",
    username: "mizuki@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "電気信号.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 48,
    debugMemo: "",
    userNoText: "u3000049",
    username: "souta@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "抵抗とオーム.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 49,
    debugMemo: "",
    userNoText: "u7700044",
    username: "ryouko@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "アプリの作り方.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 50,
    debugMemo: "",
    userNoText: "u6590041",
    username: "kijima@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "光の研究.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 51,
    debugMemo: "",
    userNoText: "u2230023",
    username: "shiori@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "心理学.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 52,
    debugMemo: "",
    userNoText: "u9900041",
    username: "chiko@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "インストール手順.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 53,
    debugMemo: "",
    userNoText: "u7700041",
    username: "shige@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "基礎学習.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 54,
    debugMemo: "",
    userNoText: "u5500043",
    username: "ryouta@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "自動認識について.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 55,
    debugMemo: "",
    userNoText: "u8800341",
    username: "midori@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "型について.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 56,
    debugMemo: "",
    userNoText: "u7903041",
    username: "bandou@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "光と音.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 57,
    debugMemo: "",
    userNoText: "u4900051",
    username: "kizuna@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "振動と振幅.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 58,
    debugMemo: "",
    userNoText: "u7230045",
    username: "syhoko@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "行動心理学.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 59,
    debugMemo: "",
    userNoText: "u8880048",
    username: "eiko@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "音楽の基礎.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 60,
    debugMemo: "",
    userNoText: "u5500051",
    username: "chihiro@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "動物との共存.md",
  },
  {
    authorities: [
      {
        authority: "ROLE_CHIEF",
      },
    ],
    _id: "61921ff5e4b0d1148988e25d",
    className: "models.User",
    userNo: 61,
    debugMemo: "",
    userNoText: "u2220022",
    username: "jyunya@allegrosmart.com",
    avatar_id: "d93n2ifj29j394$MJFDS9842jk3",
    groups: ["61975372e4b0d114898a6158"],
    profile: {
      userRealName: "kiipapa",
      lang: "en",
    },
    contractorNo: "playground",
    lastUpdated: "2022-04-20T08:14:28.434Z",
    _created: 1636966389233,
    _modified: 1650442468434,
    lastUpdatedFileName: "風と地形.md",
  },
];
