import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { slotSearchFilterState } from "../recoils";
import { CHTabComponentProps } from "src/cheetah/components/molecules/CHTabs";

export const useInsightViewer = (
  tabs: (CHTabComponentProps & { filters: any })[]
) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const defaultTabId = Number(params.get("tab")) ?? 0;
  const navigate = useNavigate();

  const [filters, setFilters] = useRecoilState(slotSearchFilterState);

  const changeTabId = (newValue: number) => {
    const params = new URLSearchParams();
    params.append("tab", newValue.toString());
    navigate({ search: params.toString() }, { replace: true });

    if (tabs[newValue].filters) {
      setFilters(tabs[newValue].filters);
    }
  };

  return { defaultTabId, filters, changeTabId };
};
