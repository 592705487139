import {
  atom,
  useRecoilState,
  useRecoilValue,
  useRecoilCallback,
} from "recoil";
import * as api from "../api";
import { searchChild, searchChild__UNSTABLE } from "../api";
import { useEffect } from "react";
import { ChildList, createChildList } from "src/fortress/models";
import { defaultPlotData, PlotDatas } from "../hooks/useScatterPlot";

//基本的に複数のコンポーネントに跨って利用されているステートはrecoilを利用し、グローバルステートとして保持している。

type PageNavSettingsState = {
  pageTotalCount: number;
  perPage: number;
};

type FilterQueryState = {
  [key: string]: any;
};

type SortQueryState = {
  [key: string]: any;
};

export const defaultPageNavSettingsState: PageNavSettingsState = {
  pageTotalCount: 1,
  perPage: 20,
};

const defaultFilterQueryState: FilterQueryState = {
  "contents.optdata.non_numeric_abuse_risk_evaluation_by_staff": "",
  "contents.optdata.non_numeric_datagroup_label": "",
  "contents.optdata.numeric_prediction1_value": "",
  "contents.optdata.numeric_prediction2_value": "",
  "contents.optdata.non_numeric_action_status": "",
};
const defaultSortQueryState: SortQueryState = {
  "contents.rawdata.宛名番号": 1,
};

//ページネーションの際の総ページ数と一ページあたりのデータ数をグローバルステートとして保持することで、動的に変更できるようにしている。

export const pageNavSettingsState = atom<PageNavSettingsState>({
  key: "PageNavSettingsState",
  default: defaultPageNavSettingsState,
});

//児童一覧画面において、フィルターする際のクエリの内容をグローバルステートとして保持している。

export const filterQueryState = atom<FilterQueryState>({
  key: "FilterQueryState",
  default: defaultFilterQueryState,
});

//児童一覧画面において、ソートする際のクエリの内容をグローバルステートとして保持している。

export const sortQueryState = atom<SortQueryState>({
  key: "SortQueryState",
  default: defaultSortQueryState,
});

//児童一覧画面において、何ページ目を見ているかをグローバルステートとして保持しているようにしている。

export const childListPageIndexState = atom<number>({
  key: "ChildListPageIndexState",
  default: 1,
});

//児童一覧のデータを保持している。

export const childListState = atom<ChildList>({
  key: "ChildListState",
  default: [],
});

//児童一覧画面において、児童名などを検索した言葉をグローバルステートとして保持する。

export const childSearchWordState = atom<string | null>({
  key: "ChildSerachWordState",
  default: null,
});

export const cursorChildPlotState = atom<PlotDatas>({
  key: "cursorChildPlotState",
  default: [defaultPlotData],
});

export const filterPeriodState = atom<
  "1year" | "halfOfYear" | "2year" | "3year"
>({
  key: "filterPeriodState",
  default: "halfOfYear",
});

// 児童名が検索された時、フィルターやソートをかけられた時、ページ遷移した時などに適切な児童一覧のデータを取得するように処理を書いている。

export const useChildList = () => {
  const pageIndex = useRecoilValue(childListPageIndexState);
  const [childList] = useRecoilState(childListState);
  const filterQuery = useRecoilValue(filterQueryState);
  const sortQuery = useRecoilValue(sortQueryState);
  const childSearchWord = useRecoilValue(childSearchWordState);

  const init = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const pageNavSettings = await snapshot.getPromise(pageNavSettingsState);

        if (!childSearchWord) {
          //児童一覧画面の検索に文字が入ってない場合、児童全員分のデータを取得する。
          const [resdata, count] = await api.getChildList(
            pageNavSettings.perPage,
            pageIndex,
            filterQuery,
            sortQuery
          );

          console.log(resdata);
          //取得したデータをfortressで使いやすいようにデータ整形をやっている。createChildList関数はいわゆるORMのような役割を担う。
          const nextChildList = createChildList(resdata);
          set(childListState, []);
          set(childListState, nextChildList);
          set(pageNavSettingsState, {
            ...pageNavSettings,
            pageTotalCount: Math.ceil(count / pageNavSettings.perPage),
          });
        } else {
          //児童一覧画面の検索に文字が入っている場合、検索された児童のデータ
          const [resdata, count] = await searchChild__UNSTABLE(
            childSearchWord,
            pageNavSettings.perPage,
            pageIndex
          );
          //取得したデータをfortressで使いやすいようにデータ整形をやっている。createChildList関数はいわゆるORMのような役割を担う。
          const nextChildList = createChildList(resdata);
          set(childListState, []);
          set(childListState, nextChildList);
          set(pageNavSettingsState, {
            ...pageNavSettings,
            pageTotalCount: Math.ceil(count / pageNavSettings.perPage),
          });
        }
      },
    [pageIndex, filterQuery, childSearchWord, sortQuery]
  );

  // レンダリングされた瞬間児童一覧のデータを表示させたいため。
  useEffect(() => {
    init();
  }, [init]);

  return childList;
};
