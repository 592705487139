import { useCallback, useState } from "react";
import { User } from "src/root/models/user";
import { api } from "src/api";
import { useRecoilState } from "recoil";
import { AuthState, authState } from "src/root/recoils/userState";

type ProfileUpdatable = {
  userRealName?: string;
  email?: string;
  password?: string;
  refreshApikey?: string;
  phoneNumber?: string;
  zip?: string;
  address?: string;
};

export const useProfileActions = (user: User) => {
  const [auth, setAuth] = useRecoilState(authState);

  const updateAvatar = useCallback(async (base64data: any) => {
    console.log(base64data);
    const res = await api({
      method: "put",
      url: `/nauth/myaccount`,
      data: {
        avatar: base64data,
      },
    });
    if (res.status === 200) {
      setAuth({ ...auth, avatar: base64data });
      return true;
    }
  }, []);

  const updateProfile = useCallback(
    async (body: ProfileUpdatable) => {
      const res = await api({
        method: "put",
        url: `/nauth/myaccount`,
        data: {
          ...body,
        },
      });
      if (res.status === 200) {
        const authData: Partial<AuthState> = {
          userRealName: body.userRealName ?? auth.userRealName,
          email: body.email ?? auth.email,
          zipcode: body.zip ?? auth.zipcode,
          phoneNumber: body.phoneNumber ?? auth.phoneNumber,
          address: body.address ?? auth.address,
        };
        setAuth({
          ...auth,
          ...authData,
        });
        return true;
      }
    },
    [auth, setAuth]
  );

  const updatePassword = useCallback(() => {}, []);

  return { updateAvatar, updateProfile, updatePassword };
};
