import { format } from "date-fns";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Link = styled.span`
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const RawDataView = styled.div`
  margin-top: 4px;
  font-size: 12px;
  color: ${(props) => props.theme.positiveColor};
`;

export interface ILCPercentageCellComponentProps {
  data: {
    num: any;
    max: any;
  };
}

function roundDecimal(value: number, n: number) {
  return Math.round(value * Math.pow(10, n)) / Math.pow(10, n);
}
export default function LCPercentageCellComponent(
  props: ILCPercentageCellComponentProps
) {
  const percentage = () => {
    const p = roundDecimal(
      (Number(props.data.num ?? 0) / Number(props.data.max)) * 100,
      2
    );
    return isNaN(p) ? 0 : p;
  };

  return props.data.num !== undefined ? (
    <div>
      <span>
        {props.data.num ?? 0}/{props.data.max}
      </span>
      <RawDataView>{percentage() ?? 0}%</RawDataView>
    </div>
  ) : (
    <>0</>
  );
}
