import React, { useState } from "react";
import { ASButton, ASInputBase, ASModal } from "allegro-ui";
import MenuItem from "@material-ui/core/MenuItem";
import scienceFile from "src/opt-editor/recoils/ScienceFileState";
import { useRecoilValue } from "recoil";
import { useFileMediator } from "src/opt-editor/hooks/useFileMediator";
import styled from "styled-components";
import { ScienceFile } from "src/opt-editor/models/ScienceFSItem";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 250px;
  width: 350px;
  padding: 24px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FormInput = styled.div`
  > * {
    margin-left: 8px;
  }
`;

type Props = {
  onMenuClose: () => void;
};
export const OEFileRenameMenu: React.FC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const cursorFileId = useRecoilValue(scienceFile.cursorItemIdState);
  const fileMediator = useFileMediator();
  const file = useRecoilValue(scienceFile.item(cursorFileId));
  const handleClose = () => {
    setShowModal(false);
  };

  const openModal = (event: React.ChangeEvent<{}>) => {
    setShowModal(true);
    // 親コンポーネントのmouseX,mouseYにnullセット
    props.onMenuClose();
  };

  const handleSubmit = async () => {
    const newFile: ScienceFile = {
      ...file,
      name,
    };
    const success = await fileMediator.update(newFile);
  };

  return (
    <>
      <MenuItem onClick={openModal}>Rename</MenuItem>

      <ASModal open={showModal} onClose={handleClose}>
        <Container>
          <Title>{file.name}</Title>
          <FormInput>
            名前
            <ASInputBase
              kind="outline"
              onChange={(e) => {
                setName(e.target.value);
              }}
            ></ASInputBase>
          </FormInput>
          <ButtonGroup>
            <ASButton
              kind="primary"
              onClick={() => {
                handleSubmit();
              }}
            >
              Apply
            </ASButton>
          </ButtonGroup>
        </Container>
      </ASModal>
    </>
  );
};
