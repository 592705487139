import { useAuth } from "src/root";

const parseGroupsStr = (groupsStr: string): string[] => {
  try {
    return JSON.parse(groupsStr);
  } catch (err) {
    return [];
  }
};

// グループの権限管理のためのhooks
export const useMyGroups = () => {
  const auth = useAuth();
  const groups = auth.groups ? parseGroupsStr(auth.groups) : [];

  // ユーザーが指定したグループに所属しているか判定する
  const isBelongToGroup = (groupId: string) => {
    return groups.includes(groupId);
  };

  return {
    groups,
    isBelongToGroup,
  };
};
