import moment from "moment";
import format from "date-fns/format";

//unixタイムを和暦に変換しないとuiに表示しても読むことができないため、変換コードをここに書いている。

export const shapeDateToWareki = (date: Date | number): string => {
  const locale = "ja-JP-u-ca-japanese";

  if (typeof date === "number") {
    try {
      const str = String(date);
      const yyyymmddArr: any = str.match(/([0-9]{4})([0-9]{2})([0-9]{2})/);
      const d = new Date(yyyymmddArr[1], yyyymmddArr[2] - 1, yyyymmddArr[3]);
      return d.toLocaleDateString(locale, {
        era: "long",
      });
    } catch {
      return "No Assesment";
    }
  } else {
    try {
      const newDate = new Date(date);
      return newDate.toLocaleString(locale, {
        era: "long",
      });
    } catch {
      return "No Assesment";
    }
  }
};

export const shapeToSeireki = (date: Date | number | string): string => {
  if (typeof date === "number") {
    try {
      const str = String(date);
      const yyyymmddArr: any = str.match(/([0-9]{4})([0-9]{2})([0-9]{2})/);
      const dated = new Date(
        yyyymmddArr[1],
        yyyymmddArr[2] - 1,
        yyyymmddArr[3]
      );
      const d = format(dated, "yyyy/MM/dd");
      return d.toString();
    } catch {
      return String(date);
    }
  } else if (typeof date === "string") {
    try {
      const dated = new Date(date);
      const d = format(dated, "yyyy/MM/dd");
      return d.toString();
    } catch {
      return date;
    }
  } else {
    try {
      const newDate = new Date(date);
      return format(newDate, "yyyy/MM/dd").toString();
    } catch {
      return "invalid date format";
    }
  }
};

export const urlDate = (dt: any) => {
  const date = dt.split("/");
  const newDate = date.join("*");
  return newDate;
};

export const unUrlDate = (dt: any) => {
  const date = dt.split("*");
  const newDate = date.join("/");
  return newDate;
};

//今現在からちょうど18年前を算出している。

export const dateOf18YearsAgo = moment(new Date())
  .subtract(18, "years")
  .format("YYYYMMDD");
