import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

const Alert: React.FC<AlertProps> = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Root = styled.div`
  position: absolute;
  z-index: 1;
  display: inline-block;
  right: 24px;
  bottom: 24px;
  color: ${(props) => props.theme.textColor};
  .MuiCircularProgress-colorPrimary {
    color: ${(props) => props.theme.primaryColor};
  }
`;

type Props = {
  isLoading: boolean;
};

export const ASLoadingCircle: React.FC<Props> = ({ isLoading }) => {
  return <Root>{isLoading ? <CircularProgress /> : <></>}</Root>;
};
