import React from "react";
import { NZSettingPageTemplate } from "../templates/NZSettingsPageTemplate";
import { PTSettingGroupSingleTemplate } from "src/portal/components/templates/PTSettingGroupSingleTemplate";
import { useParams } from "react-router-dom";

export const NZSettingGroupSinglePage: React.FC = () => {
  const params = useParams();
  const groupId = params.groupId ?? "";
  return (
    <NZSettingPageTemplate>
      <PTSettingGroupSingleTemplate groupId={groupId} />
    </NZSettingPageTemplate>
  );
};
