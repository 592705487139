import { ASCard } from "allegro-ui";
import React from "react";
import { Summary } from "src/nai-console/models/Summary";
import styled from "styled-components";

const Container = styled(ASCard)`
  width: 280px;
  min-height: 77vh;
  padding: 16px;
`;

const Block = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};
  padding: 24px 0px;
  &:last-child {
    border: none;
    /* margin-top: auto; */
  }
`;

const IDContainer = styled.div`
  color: ${(props) => props.theme.descriptionTextColor};
  /* font-size: ${(props) => props.theme.regularFontSize}px; */
  font-size: 12px;
  overflow-wrap: break-word;
`;

const Name = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
`;

const BlockTitle = styled.div`
  font-size: ${(props) => props.theme.regularFontSizeLg}px;
  font-weight: bold;
  padding-bottom: 12px;
`;

type Props = {
  summary: Summary;
};

export const NASummaryCard: React.FC<Props> = (props) => {
  const { summary } = props;

  return (
    <Container>
      <Block>
        <Name>{summary.name}</Name>
        <IDContainer>ID: {summary.id}</IDContainer>
      </Block>
      {summary.blocks.map((block, id) => {
        return (
          <Block key={`na-summary-card-block-${block.title}-${id}`}>
            <BlockTitle>{block.title}</BlockTitle>
            <div>{block.contents}</div>
          </Block>
        );
      })}
    </Container>
  );
};
