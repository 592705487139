import iconv from "iconv-lite";

type ParseOptions = {
  replaceKeys?: any;
  headerRow?: number;
  startColumn?: string;
};

export const useCsv = () => {
  const parseFile = async (file: File) => {
    const buffer = await file.arrayBuffer();
    const fileStr = new TextDecoder().decode(buffer);
    return fileStr;
  };

  const toObject = (fileStr: string, options?: ParseOptions) => {
    const rows = fileStr.split("\n");
    const headers: string[] = rows[0].split(",");

    const objects = [];
    for (let i = 1; i < rows.length; i++) {
      const currentRow = rows[i].split(",");
      if (currentRow.length !== headers.length) continue; // ヘッダーと同じ数の列を持つ行のみを処理

      let obj: any = {};
      for (let j = 0; j < headers.length; j++) {
        const key = headers[j];
        if (key) obj[key] = currentRow[j];
      }
      objects.push(obj);
    }
    return objects;
  };

  const formatString = (value: string) => {
    return `"${value.replace(/\"/g, '""')}"`;
  };

  const toString = (
    objects: any[],
    options?: { headers?: string[]; defaultValue?: string }
  ): string => {
    const headers = options?.headers ?? Object.keys(objects[0]);
    const defaultValue = options?.defaultValue ?? "";

    const csvContent = [
      headers.map((header) => formatString(header)).join(","), // ヘッダー行をCSVに追加
      ...objects.map((obj) =>
        headers
          .map((header) =>
            obj[header] !== undefined
              ? formatString(String(obj[header]))
              : defaultValue
          )
          .join(",")
      ), // データ行をCSVに追加
    ].join("\n");

    return csvContent;
  };

  const toFile = (
    fileName: string,
    objects: any[],
    options?: { headers?: string[]; defaultValue?: string }
  ): File => {
    const fileStr = toString(objects, options);
    const buffer = iconv.encode(fileStr, "windows-31j");
    const file = new File([buffer], fileName, {
      type: "text/csv;charset=utf-8;",
    });
    return file;
  };

  return {
    parseFile,
    toObject,
    toFile,
  };
};
