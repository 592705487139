import React, { useState, useCallback } from "react";
import { ASModal, ASButton } from "allegro-ui";
import { CardContent } from "@material-ui/core";
import styled from "styled-components";
import { XROptXBrowser } from ".";

const ButtonGroup = styled.div`
  height: 40px;
  margin-top: 23px;
  text-align: right;
  > * {
    margin-right: 12px;
  }
`;

const StyledWrapper = styled(CardContent)`
  width: 90vw;
  height: 90vh;
`;

const Body = styled.div`
  height: calc(85vh - 63px);
  overflow: scroll;
`;

type Props = {
  show: boolean;
  onSubmit: (slectedOptXIds: string[]) => void;
  onClose?: any;
};
export const XROptXSelectModal: React.FC<Props> = ({
  show,
  onSubmit,
  onClose,
}) => {
  const [selected, setSelected] = useState<string[]>([]);

  const handleSelections = useCallback((ids: string[]) => {
    setSelected(ids);
  }, []);

  return (
    <ASModal open={show} onClose={() => onClose()}>
      <StyledWrapper>
        <Body>
          <XROptXBrowser selectable onChangeSelections={handleSelections} />
        </Body>

        <ButtonGroup>
          <ASButton kind="secondary" onClick={() => onClose()}>
            CANCEL
          </ASButton>
          <ASButton kind="primary" onClick={() => onSubmit(selected)}>
            CONFIRM
          </ASButton>
        </ButtonGroup>
      </StyledWrapper>
    </ASModal>
  );
};
