import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useRecoilValue, useRecoilState } from "recoil";
import { List, ListItem, ListItemText } from "@material-ui/core";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import {
  cursorFileIdState,
  cursorSplitFileIdState,
  activeLogicPanel,
  filterdFileListState,
} from "src/opt-editor/recoils";
import { File } from "src/opt-editor/models";
import Menu from "@material-ui/core/Menu";
import { OECreateFileRightClickMenu, OEDeleteRightClickMenu } from "../atoms";
import scienceFolder from "../../recoils/ScienceFolderState";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  /* background: #333; */
  height: 100%;
  white-space: "pre-line";
  color: ${(props) => props.theme.primaryColor};
`;

const FileTile = styled.div`
  /* height: 115%; */
  width: 100%;

  color: #ffffff;
`;

const TopArea = styled.div`
  padding: 16px;
  display: "flex";
  flexdirection: "column";
`;

const MiddleArea = styled(List)`
  height: 100%;
`;

const StyledListItem = styled(ListItem)`
  width: 100%;
  border-bottom: 1px solid #3a3a3a;
  font-size: 14px;
`;

const StyledListItemText = styled(ListItemText)`
  display: -webkit-box;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
`;

const NotFound = styled.div`
  padding: 16px;
`;

const StyledFileIcon = styled(InsertDriveFileIcon).attrs<Props>((props) => ({
  color: props.selected ? props.theme.primaryColor : "white",
}))<Props>`
  color: ${(props) => props.color};
`;

const StyledFileName = styled(ListItemText).attrs<Props>((props) => ({
  color: props.selected ? props.theme.primaryColor : "white",
}))<Props>`
  color: ${(props) => props.color};
`;

type Props = {
  selected?: boolean;
};

/**
 * @deperecated 削除予定
 */
export const OEFileList: React.FC = () => {
  const currentFolderId = useRecoilValue(scienceFolder.cursorItemIdState);
  const fileList = useRecoilValue(filterdFileListState);
  const folder = useRecoilState(scienceFolder.item(currentFolderId));
  const [cursorFileId, setCursorFileId] = useRecoilState(cursorFileIdState);
  const [cursorSplitFileId, setCursorSplitFileId] = useRecoilState(
    cursorSplitFileIdState
  );

  const [showModal, setShowModal] = useState(false);

  const [activeFileId, setActiveFileId] = useState(cursorFileId);
  const activePanel = useRecoilValue(activeLogicPanel);
  let history = useNavigate();
  const overviewLimit = 50;

  useEffect(() => {
    setActiveFileId(activePanel === "left" ? cursorFileId : cursorSplitFileId);
  }, [activePanel, cursorFileId, cursorSplitFileId]);

  // 右クリックイベントハンドラ
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number | null;
    mouseY: number | null;
  }>({ mouseX: null, mouseY: null });
  // 右クリックフォルダ選択
  const handleContextMenu = (event: React.MouseEvent) => {
    // 初期のメニュー非表示
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };
  const handleRightClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
  };

  // 右クリックメニューを閉じる
  const handleClose = () => {
    setContextMenu({
      mouseX: null,
      mouseY: null,
    });
    setShowModal(false);
  };

  // ファイル上右クリックイベントハンドラ
  const [fileContextMenu, setFileContextMenu] = React.useState<{
    mouseX: number | null;
    mouseY: number | null;
  }>({ mouseX: null, mouseY: null });
  // 右クリックファイル選択
  const handleFileContextMenu = (event: React.MouseEvent, id: number) => {
    handleSelect(id);
    event.stopPropagation();
    // 初期のメニュー非表示
    event.preventDefault();
    setFileContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleFileRightClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
  };

  // 右クリックメニューを閉じる
  const handleFileContextMenuClose = () => {
    setFileContextMenu({
      mouseX: null,
      mouseY: null,
    });
  };

  const handleSelect = (id: number) => {
    const fileId = id;
    setActiveFileId(fileId);
    history("/");
    if (activePanel === "right") {
      setCursorSplitFileId(fileId);
    } else {
      setCursorFileId(fileId);
    }
    return;
  };

  const overviewText = useCallback((data: any) => {
    const ellipsis = "...";
    let text = "";
    // console.log(data);
    if (typeof data === "string") return data;
    if (data?.blocks) {
      data?.blocks?.forEach((block: any) => {
        text += block.text;
      });
    } else {
      text = data;
    }
    return text?.length > overviewLimit
      ? text?.slice(0, overviewLimit) + ellipsis
      : text;
  }, []);

  const renderFileList = (fileList: File[]) => {
    return fileList.length > 0 ? (
      fileList.map((file: File) => {
        console.log(file);
        return (
          <StyledListItem
            button
            key={file.fileId}
            onClick={() => handleSelect(file.fileId)}
            onContextMenu={(evt) => {
              handleFileContextMenu(evt, file.fileId);
            }}
          >
            <div onContextMenu={handleFileRightClick}>
              <FileTile
                onContextMenu={(evt) => {
                  handleFileContextMenu(evt, file.fileId);
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <StyledFileIcon
                    fontSize="small"
                    selected={activeFileId == file.fileId}
                  />
                  <StyledFileName
                    primary={file.name}
                    selected={activeFileId == file.fileId}
                  />
                </div>
                <div>
                  <StyledListItemText
                    style={{ color: "#6c6a6a" }}
                    primary={overviewText(file.data)}
                  />
                </div>
              </FileTile>
            </div>
            <Menu
              keepMounted
              open={fileContextMenu.mouseY !== null}
              onClose={handleFileContextMenuClose}
              anchorReference="anchorPosition"
              anchorPosition={
                fileContextMenu.mouseY !== null &&
                fileContextMenu.mouseX !== null
                  ? {
                      top: fileContextMenu.mouseY,
                      left: fileContextMenu.mouseX,
                    }
                  : undefined
              }
            >
              <OEDeleteRightClickMenu
                showChild={setFileContextMenu}
                isDeleteFile
                onMenuClose={handleFileContextMenuClose}
              />
            </Menu>
          </StyledListItem>
        );
      })
    ) : (
      <NotFound>File not found.</NotFound>
    );
  };

  return (
    <React.Suspense fallback={null}>
      <Container>
        {/* TODO: folder info component(atoms) */}
        {/* TODO: style => use allegro-ui component */}
        <TopArea style={{}}>
          <p style={{ color: "#FFFFFF" }}>{folder[0]?.name}</p>

          <p style={{ fontSize: "12px", color: "#6c6a6a", marginTop: "20px" }}>
            {`${fileList?.length ?? 0}個のドキュメント`}
          </p>
          <div style={{ display: "flex" }}>
            <p style={{ fontSize: "12px", color: "#6c6a6a" }}>以下のすべて/</p>
            {/* <p style={{ fontSize: "12px" }}>このフォルダのみ</p> */}
          </div>
        </TopArea>

        <MiddleArea onContextMenu={handleContextMenu}>
          {renderFileList(fileList ?? [])}
        </MiddleArea>

        <Menu
          keepMounted
          open={contextMenu.mouseY !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu.mouseY !== null && contextMenu.mouseX !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <OECreateFileRightClickMenu
            onMenuClose={handleFileContextMenuClose}
          />
        </Menu>
      </Container>
    </React.Suspense>
  );
};
