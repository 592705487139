import { atom } from "recoil";

export const cursorLinkObjectIdState = atom({
  key: "CursorLinkObjectIdState",
  default: "",
});

// export const linkObjectIdsState = selectorFamily<string[], string>({
//   key: "LinkObjectIds",
//   get:
//     (pipelineId) =>
//     async ({ get }) => {
//       //@todo url の環境変数化
//       //@todo 件数の指定(limit)
//       const reqId = get(linkObjectRequestId);

//       const nxtal = createnXtalAPI();
//       const params = {
//         apikey: sessionStorage.token,
//         pipelineId: pipelineId,
//       };
//       const linkObjects = await nxtal.xervice.getSlots(params);

//       if (Array.isArray(linkObjects)) {
//         const result = linkObjects.map((data: any) => {
//           return data.linkObjectId;
//         });
//         return result;
//       }
//       return [];
//     },
// });
