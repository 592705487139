import {
  ChangeEventHandler,
  FormEventHandler,
  KeyboardEventHandler,
} from "react";
import SearchIcon from "@material-ui/icons/Search";

import { Box } from "@material-ui/core";
import styled from "styled-components";
import { ASInputBase } from "allegro-ui";

type Props = {
  defaultValue?: string;
  placeholder?: string;
  onChange?:
    | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  onSubmit?: FormEventHandler<HTMLDivElement> | undefined;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

const StyledBox = styled(Box)`
  display: flex;
  width: 250px;
  border: 1px solid;
  border-color: ${(props) => props.theme.secondaryBorderColor};
  border-radius: 16px;
  background-color: ${(props) => props.theme.secondaryColor};
  align-items: center;
  justify-content: center;
  padding: 1px 10px;
  flex-direction: row;
`;

const StyledInputBase = styled(ASInputBase)`
  height: 40px;
  min-height: 40px;
`;

export const OESearchTextField = (props: Props) => {
  return (
    <StyledBox>
      <SearchIcon style={{ margin: "3px 7px" }} />
      <StyledInputBase
        id="input-with-sx"
        kind="secondary"
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onSubmit={props.onSubmit}
        onKeyDown={props.onKeyDown}
      />
    </StyledBox>
  );
};
