export type Input = {
  name: string;
  url?: string;
  portInside: number;
  portOutside?: number;
  type?: "http" | "tcp";
  usePrivateGateway: boolean;
  customDomain?: string;
};

/**
 * Inputのエイリアス
 */
export type Expose = Input;

export type Target = {
  slotId: string;
  name: string;
};

export type Output = {
  name: string;
  targets: Target[];
  type?: "http" | "tcp";
};

export const defaultInput: Input = {
  name: "default",
  portInside: 80,
  portOutside: undefined,
  usePrivateGateway: false,
  customDomain: undefined,
};

export const defaultOutput: Output = {
  name: "default",
  targets: [],
};
