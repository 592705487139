import React from "react";
import { Grid } from "@material-ui/core";
import { KZMonitorCard } from "../molecules";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import AppsIcon from "@material-ui/icons/Apps";
import ViewListIcon from "@material-ui/icons/ViewList";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { createModalState, monitorSearchWordState } from "src/kinzaza/recoils";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { KZMonitorWideCardLight } from "../molecules/KZMonitorWideCardLight";
import { KZMonitorWideCard } from "../molecules/KZMonitorWideCard";
import { ASModal } from "allegro-ui";
import { AddMonitorModal } from "../../../kinzaza/components";
import { MonitorThumb } from "../../../kinzaza/models";
import FTSearchField from "src/fortress/components/atoms/FTSeachField";
import { MDNoDataPlaceHolder } from "src/medican/components/atoms";
import { LCSearchField } from "src/cheetah/components/libs/ASLowCodeBrowser";

type Props = {
  monitors: Array<MonitorThumb>;
  handleCreate: (formdata: { monitorName: string }) => void;
  url: string;
};

const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > *:last-child {
    margin-left: auto;
  }
  margin-bottom: 25px;
`;

const StyledButtonGroup = styled(ToggleButtonGroup)`
  background: ${(props) => props.theme.cardColor};
  height: 40px;
`;

const PleaseReload = styled(MDNoDataPlaceHolder)`
  /* padding: 24px; */
  height: 450px;
`;

const StyledToggleButton = styled(ToggleButton)`
  color: ${(props) => (props.theme.darkMode ? "#FFF" : "#000")};
  :hover {
    background: ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.bgPrimaryColor};
  }
  &.Mui-selected {
    background: ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.bgPrimaryColor};
    :hover {
      background: ${(props) => props.theme.primaryColor};
      color: ${(props) => props.theme.bgPrimaryColor};
    }
  }
  &.Mui-disabled {
    color: ${(props) => (props.theme.darkMode ? "#777" : "#555")};
  }
`;

const StyledCell = styled(TableCell)`
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
`;

type CardStyleType = "table" | "card" | "light";

const cardStyleState = atom<CardStyleType>({
  key: "cardStyleState",
  default: "table",
});

export const MonitorList: React.FC<Props> = (props) => {
  const { monitors, handleCreate, url } = props;

  const [cardStyle, setCardStyle] = useRecoilState(cardStyleState);

  const handleCardStyle = (event: any, newCardStyle: CardStyleType) => {
    console.log("handleChange", cardStyle, newCardStyle);
    if (newCardStyle && cardStyle !== newCardStyle) {
      setCardStyle(newCardStyle);
    }
  };
  const setSearchWord = useSetRecoilState(monitorSearchWordState);

  const [showCreateModal, setShowCreateModal] =
    useRecoilState(createModalState);

  const MonitorGroup = () => {
    if (monitors.length === 0) {
      return (
        <PleaseReload>
          データがありません。リロードをお試しください。
        </PleaseReload>
      );
    }

    switch (cardStyle) {
      case "light":
        return monitors.map((monitor, id) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={`${monitor.name}-${id}`}
            >
              <KZMonitorWideCardLight
                monitor={monitor}
                url={url}
              ></KZMonitorWideCardLight>
            </Grid>
          );
        });
      case "table":
        const rows = monitors.map((monitor, id) => {
          return (
            <KZMonitorWideCard
              monitor={monitor}
              url={url}
              key={`monitor-card-${id}`}
            ></KZMonitorWideCard>
          );
        });
        return (
          <Table>
            <TableHead>
              <TableRow>
                <StyledCell>アセット</StyledCell>
                <StyledCell>データタイプ</StyledCell>
                <StyledCell>データ最終更新日</StyledCell>
                <StyledCell>データカウント</StyledCell>

                {/* <StyledCell>カウント更新日</StyledCell> */}
                <StyledCell></StyledCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        );

      case "card":
        return monitors.map((monitor, id) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={`${monitor.name}-${id}`}
            >
              <KZMonitorCard monitor={monitor} url={url}></KZMonitorCard>
            </Grid>
          );
        });
      default:
        return <></>;
    }
  };

  return (
    <>
      <Head>
        <LCSearchField
          onChange={() => {}}
          onSubmit={(text: string) => {
            setSearchWord(text);
          }}
        />
        <StyledButtonGroup
          value={cardStyle}
          exclusive
          onChange={handleCardStyle}
          aria-label="text alignment"
        >
          <StyledToggleButton value="table" aria-label="use table ui">
            <ViewListIcon />
          </StyledToggleButton>

          <StyledToggleButton value="card" aria-label="use card ui">
            <AppsIcon />
          </StyledToggleButton>
        </StyledButtonGroup>
      </Head>

      <Grid container spacing={3}>
        {MonitorGroup()}
      </Grid>
      <ASModal open={showCreateModal} onClose={() => setShowCreateModal(false)}>
        <AddMonitorModal onSubmit={handleCreate}></AddMonitorModal>
      </ASModal>
    </>
  );
};
