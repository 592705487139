import { shapeDateToWareki, shapeToSeireki } from "../utils/time";

export type AbuseCategory =
  | "登録なし"
  | "要保護"
  | "要支援"
  | "特定妊婦"
  | "登録なし";

export type HowToEvaluate =
  | "データから"
  | "家庭訪問で"
  | "相談業務で"
  | "学校で"
  | "その他";

export type EvaluationByStaff =
  | "問題なし"
  | "気になる"
  | "要経過観察"
  | "虐待の危惧あり"
  | "虐待あり"
  | "未評価";

export type SupportCategory = "支援中" | "虐待対応業務中" | "未入力" | "終結";

export type smallObj = { [key: string]: boolean };

export type AssesObject = { [key: string]: smallObj };

export type FilterCheckData = { [key: string]: any };

export const defaultTwoFilterData = {
  子どもの様子: {},
  親の様子: {},
  家族の様子: {},
  その他所見: {},
};

export type RiskAssessmentFormData = {
  フォーム作成者: string;
  児童名: string;
  年齢: number;
  宛名番号: string;
  評価方法: HowToEvaluate;
  職員評価: EvaluationByStaff;
  職員評価ID: number;
  今後の対応方針: SupportCategory;
  支援状況ID: number;
  要対協登録状況: AbuseCategory;
  アセスメント内容: string;
  フォームID: string;
  子どもの様子: AssesObject;
  親の様子: AssesObject;
  家族の様子: AssesObject;
  その他所見: AssesObject;
  "親子関係（母）": string;
  "親子関係（父）": string;
  ownerNo?: string;
};

export const defaultRiskAssessmentFormData: RiskAssessmentFormData = {
  フォーム作成者: "",
  児童名: "",
  年齢: -1,
  宛名番号: "",
  評価方法: "データから",
  職員評価: "未評価",
  職員評価ID: 5,
  今後の対応方針: "未入力",
  支援状況ID: 3,
  要対協登録状況: "登録なし",
  アセスメント内容: "",
  フォームID: shapeToSeireki(new Date()),
  子どもの様子: {},
  親の様子: {},
  家族の様子: {},
  その他所見: {},
  "親子関係（母）": "わからない",
  "親子関係（父）": "わからない",
};
