import { getSubjectMany, getSubjectOne, saveSubject } from "./subject";
import { saveAlertOne } from "./alert";
import {
  getAssets,
  getAssetOne,
  createAnswerAssets,
  deleteAnswerAsset,
  saveProject,
} from "./asset";
import {
  getAnswers,
  saveAnswer,
  getUserAnswer,
  getCategories,
  deleteAnswer,
} from "./answer";
import { getFormDefinition, saveFormDefinition } from "./formDefinition";
import { updateIntegratedData } from "./updateIntegratedData";
import { registApproval } from "./approval";

export const medicanAPI = {
  saveSubject,
  getSubjectMany,
  getSubjectOne,
  saveAlertOne,
  getAssets,
  getAssetOne,
  saveProject,
  createAnswerAssets,
  deleteAnswerAsset,
  getAnswers,
  saveAnswer,
  getUserAnswer,
  getCategories,
  getFormDefinition,
  saveFormDefinition,
  updateIntegratedData,
  registApproval,
  deleteAnswer,
};
