import { CardContent } from "@material-ui/core";
import { ASCard } from "allegro-ui";
import styled from "styled-components";

const Content = styled.div``;

const StyledCard = styled(ASCard)`
  /* background: #000; */

  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 6px 6px rgba(0, 0, 0, 0.16);
  width: 500px;
  min-height: 350px;
  /* height: 550px; */
`;

const Body = styled.div``;

const Title = styled.h1`
  margin: 18px 0px;
  font-size: 24px;
  font-weight: 100;
  font-weight: bold;
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
`;

const RightPanel = styled.div`
  position: sticky;
  top: 10vh;
  padding-top: 48px;
  display: flex;
  flex-direction: column;

  padding-bottom: 48px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 12px 28px;
  background: ${(props) => props.theme.bgPrimaryColor};
  font-size: 10px;
  color: ${(props) => props.theme.textColor};
  /* text-align: right; */
`;

type Props = {
  formContent?: any;
  formTitle?: string;
};

export const PTUserAuthComponent: React.FC<Props> = (props) => {
  const { formContent, formTitle } = props;

  return (
    <>
      <RightPanel>
        <StyledCard>
          <CardContent>
            <Content>
              <Title>{formTitle}</Title>
              <Body>{formContent}</Body>
            </Content>
          </CardContent>
        </StyledCard>
        {props.children}
      </RightPanel>

      <Footer></Footer>
    </>
  );
};
