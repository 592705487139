import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ASTable } from "allegro-ui";
import { RiskAssessmentFormData } from "src/fortress/models/RiskAssessment";
import { FTAssesmentListItem } from "./FTAssessmentListItem";
import styled from "styled-components";
import { HiroshimaLogo } from "../atoms/logo";
import { useTheme } from "styled-components";

type Props = {
  assessmentList: RiskAssessmentFormData[];
};

const No = styled.div`
  text-align: center;
  margin-top: 150px;
  font-size: 40px;
  color: #dddddd;
`;

export const FTAssesmentList: React.FC<Props> = (props) => {
  const { assessmentList } = props;

  const theme = useTheme();
  const color = theme.darkMode ? "#dddddd" : "#dddddd";

  const tableBody =
    assessmentList.length > 0 ? (
      <>
        <TableContainer component={ASTable} borderRadius={18} kind="tertiary">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>作成日時</TableCell>
                <TableCell align="left">職員評価</TableCell>
                <TableCell align="left">要対協登録状況</TableCell>
                <TableCell align="left">作成者</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assessmentList.map(
                (assessment: RiskAssessmentFormData, index) => (
                  <FTAssesmentListItem
                    assessmentFormData={assessment}
                    key={String(index)}
                  />
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    ) : (
      <>
        <No>
          {/* <HiroshimaLogo size={150} color={color} /> */}
          <p>アセスメントデータが存在しません</p>
        </No>
      </>
    );

  return <>{tableBody}</>;
};
