import produce from "immer";

export type PipelineXInput = {
  id?: string;
  name: string;
  slots?: string[];
  readableGroups?: string[];
  writableGroups?: string[];
  readableUsers?: string[];
  writableUsers?: string[];
  createdBy?: string;
};

export const defaultPipelineXInput: PipelineXInput = {
  name: "No Name",

  readableGroups: ["*"],
  writableGroups: ["*"],
  readableUsers: ["*"],
  writableUsers: ["*"],
};

export const createPipelineXInput = (props: Partial<PipelineXInput>) => {
  return produce(defaultPipelineXInput, (draft) => {
    draft.name =
      typeof props.name === "string" && props.name !== ""
        ? props.name
        : draft.name;

    draft.readableGroups = props.readableGroups ?? draft.readableGroups;
    draft.readableUsers = props.readableUsers ?? draft.readableUsers;
    draft.writableGroups = props.writableGroups ?? draft.writableGroups;
    draft.writableUsers = props.writableUsers ?? draft.writableUsers;
  });
};
