import { useEffect, useState } from "react";
import * as React from "react";
import styled from "styled-components";
import { ASChip } from "allegro-ui";
import { darken, lighten } from "polished";

const Title = styled.h2`
  font-size: 13px;
  color: #656565;
  line-height: 1.56;
  width: 80px;
`;

export const DisabledChip = styled(ASChip)`
  color: #909090;
  background-color: ${(props) =>
    props.theme.darkMode ? darken(0.5, "#555") : lighten(0.52, "#555")};
`;

const FlexBox = styled.div`
  display: flex;
  > * {
    margin-top: 8px;
  }
`;

interface ILCAIResultCellComponentProps {
  data: {
    ai1: any[];
    ai2: any[];
  };
}

const colors: { [key: string]: any } = {
  問題なし: "positive",
  気になる: "caution",
  用経過観察: "negative",
  虐待の危惧あり: "negative",
  虐待あり: "negative",
  身体的虐待: "negative",
  精神的虐待: "negative",
  要支援: "negative",
};

const getColor = (num: number) => {
  if (num > 80) return colors["虐待あり"];
  if (num > 50) return colors["気になる"];
  else return colors["問題なし"];
};

const LCAIResultCellComponent: React.FC<ILCAIResultCellComponentProps> = (
  props
) => {
  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);
  const ai1 = props.data.ai1;
  const ai2 = props.data.ai2;

  useEffect(() => {
    if (Array.isArray(ai1) && ai1.length > 0) {
      const latestAi1 = props.data.ai1.slice(-1)[0];
      console.log(latestAi1);
      setValue1(
        Math.floor(latestAi1.contents.rawdata.推論結果数値.虐待あり * 100)
      );
    }
    if (Array.isArray(ai2) && ai2.length > 0) {
      const latestAi2 = props.data.ai2.slice(-1)[0];
      setValue2(
        Math.floor(latestAi2.contents.rawdata.推論結果数値.虐待あり * 100)
      );
    }
  }, [ai1, ai2, props.data.ai1, props.data.ai2]);

  return (
    <>
      <FlexBox>
        <Title>検査1</Title>
        <ASChip
          design="onecolor"
          kind={getColor(value1)}
          label={String(value1)}
          onDelete={undefined}
          small
        ></ASChip>
      </FlexBox>
      <FlexBox>
        <Title>検査2</Title>
        <ASChip
          design="onecolor"
          kind={getColor(value2)}
          label={String(value2)}
          onDelete={undefined}
          small
        ></ASChip>
      </FlexBox>

      {/* <Title>日付</Title>
      <span></span> */}
    </>
  );
};

export default LCAIResultCellComponent;
