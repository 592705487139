//Libs
import styled from "styled-components";
//Components
import { OEOptXControllWidget, OEFSView } from "../molecules";
import { OEHelpButton, OEUserButton, OELogo } from "../atoms";
import { OECreateProjectButton } from "../atoms";
import React from "react";
import { LoadingBox } from "../atoms/OELoddingBox";
import { OEFileListPerformanced } from "../molecules/OEFileListPerformanced";
import { headerOffset } from "src/allegrobot/components/organisms/ARGlobalNav";

const TwoColNavBox = styled.div`
  height: calc(100vh - ${headerOffset}px);
  display: flex;
  flex-direction: row;
`;

const MainPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 100%;
  padding-top: 16px;
  border-right: 1px solid ${(props) => props.theme.secondaryBorderColor};
`;

const MainTopArea = styled.div`
  padding: 16px 16px;
  border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};
`;

const SubPanel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 260px;
  border-right: 1px solid ${(props) => props.theme.secondaryBorderColor};
  background: ${(props) => props.theme.bgPrimaryColor};
`;

const Box = styled.div`
  height: 100%;
  width: 100%;
`;

const PBox = styled.div`
  padding: 16px;
`;

const BottomBox = styled.div`
  margin-top: auto;
  border-top: 1px solid ${(props) => props.theme.secondaryBorderColor};
`;

const ButtonGroup = styled.div`
  /* margin-top: 50px; */
  > * {
    margin-bottom: 20px;

    &:last-child {
      margin: 0;
    }
  }
`;

type Props = {
  show?: boolean;
};

export const OELeftPanel: React.FC<Props> = (props) => {
  const { show } = props;
  return (
    <>
      {show ? (
        <TwoColNavBox>
          <MainPanel>
            <MainTopArea>
              <OELogo />
              <ButtonGroup>
                <React.Suspense fallback={<LoadingBox />}>
                  <OECreateProjectButton />
                </React.Suspense>
              </ButtonGroup>
            </MainTopArea>
            <React.Suspense fallback={<LoadingBox />}>
              <OEFSView />
            </React.Suspense>

            <BottomBox>
              <PBox>
                {/* <OEUserButton /> */}
                <OEHelpButton />
              </PBox>
            </BottomBox>
          </MainPanel>

          <SubPanel>
            <React.Suspense fallback={<LoadingBox />}>
              <OEFileListPerformanced />
            </React.Suspense>
            <BottomBox>
              <React.Suspense fallback={<LoadingBox />}>
                <OEOptXControllWidget />
              </React.Suspense>
            </BottomBox>
          </SubPanel>
        </TwoColNavBox>
      ) : (
        <></>
      )}
    </>
  );
};
