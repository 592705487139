import { useRecoilState, useRecoilValueLoadable } from "recoil";
import {
  cursorBundleIdState,
  cursorBundleState,
  cursorBundleVersionState,
  cursorBundleVersionsState,
} from "../recoils/cursorBundleState";
import { bundles } from "src/api";

export const useBundle = () => {
  const [bundleId, setCursorBundleId] = useRecoilState(cursorBundleIdState);
  const [version, setCursorBundleVersion] = useRecoilState(
    cursorBundleVersionState
  );

  const cursorBundle = useRecoilValueLoadable(cursorBundleState);
  const cursorBundleVersionsLoadable = useRecoilValueLoadable(
    cursorBundleVersionsState
  );

  const setBundleId = (bundleId: string) => {
    setCursorBundleId(bundleId);
  };

  const setVersion = (version: string) => {
    setCursorBundleVersion(version);
  };

  const commit = async (newVersion: string, comment?: string) => {
    if (bundleId && version && newVersion) {
      const res = await bundles.commit({
        bundleId,
        version,
        newVersion,
        comment,
      });
      if (res.status === "success") {
        window.location.reload();
      }
    }
  };

  return {
    bundleId,
    version,
    bundleLoadable: cursorBundle,
    bundleVersionsLoadable: cursorBundleVersionsLoadable,
    setBundleId,
    setVersion,
    commit,
  };
};
