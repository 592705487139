import { AxiosInstance, AxiosResponse } from "axios";
import { APIConfigProps } from "../config/http";
import { createNewtZeroConfig } from "../config/newtzero";
import { dataProvider } from "./newtOut";
import { streamGateByAssetId } from "./streamGate";
import { wrapAsync } from "../libs/wrapper";
import { Asset } from "./asset";
import { Metric } from "./metric";
import { Bundle } from "./bundles";
import { RemoveDataProps, removeData, removeDataByIds } from "./removeData";
import { NewtQuery } from "../models/newtQuery";
import { AssetInputProps } from "../models/AssetInput";
import { BundleInput } from "./bundles/save";
import { BundleCommitProps } from "./bundles/commit";
import { BundeleDataSourceProps } from "./bundles/fetchData";
import { AlertJob } from "./alertjob";
import { ServerInstance } from "./server";

export interface INewtZero {
  dataProvider: (newtQuery: NewtQuery, options?: any) => Promise<any[]>;
  streamGate: (
    assetId: string,
    data: any,
    options: {
      upsert?: boolean | undefined;
      upsertTags?: boolean | undefined;
      sendToTopData?: boolean | undefined;
      sendToRawData?: boolean | undefined;
      sendToTxnData?: boolean | undefined;
    }
  ) => Promise<any>;
  removeData: (props: RemoveDataProps) => Promise<boolean>;
  removeDataByIds: (
    collection: string,
    ids: string[]
  ) => Promise<[boolean, string | undefined]>;
  asset: {
    getOne: () => Promise<any>;
    getMany: () => Promise<any>;
    save: (
      assetInput: AssetInputProps,
      upsert?: boolean | undefined
    ) => Promise<AxiosResponse<any, any>>;
  };
  metric: {
    save: (
      metricName: string,
      data: {
        means: string[];
        dataType: "number" | "string" | "date" | "any";
        tags: string[];
        shareLevel: "private" | "public";
      },
      params?:
        | {
            upsert?: boolean | undefined;
            upsertTags?: boolean | undefined;
            mergeMeans?: boolean | undefined;
          }
        | undefined
    ) => Promise<any>;
  };
  bundle: {
    save: (data: BundleInput) => Promise<any>;
    fetchData: (props: BundeleDataSourceProps) => Promise<any>;
    commit: (props: BundleCommitProps) => Promise<any>;
  };
  alertJob: ReturnType<typeof AlertJob>;
  server: ReturnType<typeof ServerInstance>;
}

const _replaceApiVersionUrl = (input: string, newNumber: number) => {
  const result = input.replace(/\/api\/\d+/, `/api/${newNumber}`);
  return result;
};

export const NewtZero = (props: APIConfigProps): INewtZero => {
  const instanceForV1 = createNewtZeroConfig(props);
  const instanceForV2 = createNewtZeroConfig(props);

  if (instanceForV2.defaults.baseURL) {
    instanceForV2.defaults.baseURL = _replaceApiVersionUrl(
      instanceForV2.defaults.baseURL,
      2
    );
  } else {
    console.error("err");
  }

  const asset = Asset(instanceForV1);
  const metric = Metric(instanceForV1);
  const bundle = Bundle(instanceForV1);
  const alertJob = AlertJob(instanceForV2, 2);
  const server = ServerInstance(instanceForV2);

  return {
    dataProvider: wrapAsync(instanceForV1, dataProvider),
    streamGate: wrapAsync(instanceForV1, streamGateByAssetId),
    removeData: wrapAsync(instanceForV1, removeData),
    removeDataByIds: wrapAsync(instanceForV1, removeDataByIds),
    asset,
    metric,
    bundle,
    alertJob,
    server,
  };
};
