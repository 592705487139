import { nAIBrowserUi } from "src/nai-console/config/browserUi/nAIBrowserUi";
import { createNAIFormUi } from "src/nai-console/config/formUi/createNAIFormUi";
import { NZDataOpsListTemplate } from "src/newtzero-console/components/templates/NZDataOpsListTemplate";

type Props = {};

export const NAAIListTemplate: React.FC<Props> = (props) => {
  return (
    <>
      <NZDataOpsListTemplate
        name={"na-ai-list"}
        browserUi={nAIBrowserUi}
        formUi={createNAIFormUi}
      />
    </>
  );
};
