import React from "react";
import styled from "styled-components";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ASLoadingCircle } from "../atoms";

type Props = {};
const Container = styled.div`
  position: fixed;
  z-index: 999;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  > * {
    margin: 0 auto;
  }
  /* padding: 20px; */
  text-align: center;
  color: ${(props) => props.theme.textColor};
  .MuiCircularProgress-colorPrimary {
    color: ${(props) => props.theme.primaryColor};
  }
`;
export const ASLoadingBox: React.FC<Props> = (props) => {
  return (
    <Container>
      <ASLoadingCircle isLoading />
    </Container>
  );
};
