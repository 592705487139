import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { FormControl, CardContent } from "@material-ui/core";
import { ASInputBase, ASButton, ASCard } from "allegro-ui";
import { authState, userState } from "src/root/recoils/userState";
import { useRecoilState, useRecoilValue } from "recoil";
import bg from "src/fortress/assets/img/black-polygonal.jpg";
import { PTGlobalNav } from "src/portal/components/organisms/PTGlobalNav";
import { headerOffset } from "src/portal/components/organisms/PTGlobalNav";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { PTAppListItem } from "../molecules/PTAppListItem";
import robotPng1 from "src/assets/robotIcons/Robot_Avatars_1.png";
import robotPng2 from "src/assets/robotIcons/Robot_Avatars_2.png";
import robotPng3 from "src/assets/robotIcons/Robot_Avatars_3.png";
import robotPng4 from "src/assets/robotIcons/Robot_Avatars_4.png";
import robotPng5 from "src/assets/robotIcons/Robot_Avatars_5.png";
import robotPng6 from "src/assets/robotIcons/Robot_Avatars_6.png";

import { useSignUpActions } from "src/portal/hooks/useSignUpActions";

const APP = process.env.REACT_APP_APP;
const Logo = require(`src/${APP}/components/atoms/logo`).default;
const title = process.env.REACT_APP_TITLE;

const Root = styled.div`
  background-image: url(${bg});
  background-size: cover;
  width: 100%;
  height: calc(100vh - ${headerOffset}px);
  text-align: center;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  padding: 48px 48px;
`;

const HeaderArea = styled.div`
  margin-top: 48px;
  color: ${(props) => (props.theme.darkMode ? "#999" : "black")};
  text-align: center;
  margin-bottom: 36px;
`;

const Title = styled.h1`
  text-align: center;

  font-size: 48px;
  font-weight: 100;
  margin-bottom: 24px;
  font-weight: bold;
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  padding: 12px 28px;
  background: ${(props) => props.theme.bgSecondaryColor};
  font-size: 10px;
  color: ${(props) => props.theme.textColor};
  /* text-align: right; */
`;

const SignUpNote = styled.div`
  color: ${(props) => props.theme.textColor};
  padding: 23px 0;
  &::before {
    top: 9px;
    /* border-bottom: 1px solid #fff; */
    position: absolute;
    left: 0;
    width: 100%;
    content: "";
  }
`;

const AppListContainer = styled.div`
  padding: 0 64px;
  width: 100%;
  max-width: 1700px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;

  > * {
    margin: 15px;
  }
`;

const Container = styled.div`
  position: relative;
  padding-bottom: 48px;
  min-height: 100vh;
`;

type Props = {};

export const PTAppListPage: React.FC<Props> = ({}) => {
  // const [email, setEmail] = useState(sessionStorage.getItem("userId") || "");
  // const [password, setPassword] = useState("");

  const auth = useRecoilValue(authState);

  const [confirmed, setConfirmed] = useState(false);
  const { signUpConfirm } = useSignUpActions();
  const history = useNavigate();
  const theme = useTheme();
  const color = theme.darkMode ? "white" : "black";

  const handleSubmit = async (formData: any) => {
    console.log("registering...");
    const ok = await signUpConfirm(formData);
    console.log(ok);
    if (ok) {
      setConfirmed(true);
    } else {
      alert("失敗しました。入力に誤りがないか確認してください。");
    }
  };

  const https = "https";

  return (
    <Container>
      <PTGlobalNav />
      <HeaderArea>
        <Title>AlleFavo</Title>
        <p>Find tools to improve your workflow</p>
      </HeaderArea>

      <AppListContainer>
        <PTAppListItem
          title={"SmartScience"}
          discription={"Sharing knowledges."}
          imgSrc={robotPng1}
          link={`${https}://${auth.contractorNo}.newtroid.io/science`}
          //@todo 変数化
        />
        <PTAppListItem
          title={"Annotator"}
          discription={"Annotation tools for deep learning."}
          imgSrc={robotPng2}
          link={`${https}://${auth.contractorNo}.newtroid.io/annotation`}
        />

        <PTAppListItem
          title={"Pipeline"}
          discription={"ML Ops pipeline"}
          imgSrc={robotPng3}
          link={`${https}://${auth.contractorNo}.newtroid.io/xerver-room`}
        />
        <PTAppListItem
          title={"AllegroLakeHouse"}
          discription={"Big data store (LakeHouse)"}
          imgSrc={robotPng4}
          link={`${https}://${auth.contractorNo}.newtroid.io/kinzaza`}
        />
        {/* <PTAppListItem
          title={"AllegroUI"}
          discription={"ローコードUIツールセット"}
          imgSrc={""}
          link={'http://newtroid.io/science'}
        /> */}
        {/* <PTAppListItem
          title={"AllegroAPI"}
          discription={"everything as API."}
          imgSrc={robotPng5}
          link={`https://jolly-hoover-09127b.netlify.app/`}
        /> */}
        <PTAppListItem
          title={"OptimizerX"}
          discription={"Sharing our logics."}
          imgSrc={robotPng6}
          link={`${https}://${auth.contractorNo}.newtroid.io/xerver-room/optx`}
        />
      </AppListContainer>

      <Footer>© AllegroSmart, Inc.</Footer>
    </Container>
  );
};
