import { ASLowCodeFormDefinition } from "allegro-low-code-components";

export const createGroupDefinition: ASLowCodeFormDefinition = {
  buttonOptions: {
    submitText: "Create",
    updateText: "Update",
  },
  sections: [
    {
      // title: "New Group",
      description: "",
      fields: [
        {
          label: "Name",
          name: "group_name",
          type: "text",
          required: true,
          errorText: "Input this field.",
        },
        {
          label: "Description",
          name: "description",
          type: "multiline",
          required: false,
          values: undefined,
        },
      ],
    },
  ],
};
