import { atom, selector } from "recoil";
import { getServers } from "../api/server";
import produce from "immer";
import {
  ServerStats,
  createServerStatsFromNewtData,
} from "../models/ServerStats";
import { MONITOR_BASE_URL, MonitorAPI } from "../api/monitor-api";
import { authState } from "src/root/recoils/userState";

export const FETCH_BEFORE_HOURS = 6;
export const FETCH_BEFORE_MILISECONDS = FETCH_BEFORE_HOURS * 60 * 60 * 1000;

export const serverListState = selector<any[]>({
  key: "serverListState",
  get: async ({ get }) => {
    const auth = get(authState);
    if (!auth.contractorNo) {
      return [];
    }
    try {
      const serverData = await getServers(auth.contractorNo);
      return serverData;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
});

export const serverRequestIdState = atom<number>({
  key: "serverRequestIdState",
  default: 0,
});

const defaultStatsNewtQuery = {
  asset: ["ServerStats"],
  limit: 10000,
  skip: 0,
  order: {
    "contents.rawdata.message.unixtime": -1,
  },
  search: {},
  shape: {
    "contents.rawdata": "any",
    _created: "any",
  },
};

export const serverStatsState = selector<ServerStats[]>({
  key: "serverStatsState",
  get: async ({ get }) => {
    const servers = get(serverListState);
    const auth = get(authState);

    if (!auth.token) {
      return [];
    }

    get(serverRequestIdState);
    const dateBeforeHours = Date.now() - FETCH_BEFORE_MILISECONDS;
    const newtQuery = produce(defaultStatsNewtQuery, (draft) => {
      draft.limit = FETCH_BEFORE_HOURS * 60 * servers.length;
      draft.search = {
        "contents.rawdata.message.unixtime": {
          $gt: dateBeforeHours,
        },

        "contents.rawdata.message.serverId": {
          $in: servers.map(
            (server) => server.contents.rawdata.non_numeric_server_name
          ),
        },
      };
    });
    try {
      const monitorAPI = MonitorAPI({
        baseUrl: MONITOR_BASE_URL,
        apikey: auth.token,
      });
      const resData = await monitorAPI.fetchServerStatsByNewtQuery(newtQuery);
      if (Array.isArray(resData)) {
        const newStats: ServerStats[] = resData.map((d) => {
          const stats = createServerStatsFromNewtData(d);
          return stats;
        });

        return newStats;
      }
    } catch (err) {
      console.error(err);
    }
    return [];
  },
});
