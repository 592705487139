import React from "react";
import { PipelineProvider, NotifyProvider } from "src/opt-editor/contexts";
import { SnackbarProvider } from "notistack";
import { OEPageTemplate } from "./OEPageTemplate";
import { OEPanesView } from "../organisms/OEPanesView";

//コンテキストを注入
export const OEOptEditorPageTemplate: React.FC = () => {
  return (
    <OEPageTemplate leftPanelShow>
      <NotifyProvider>
        <SnackbarProvider maxSnack={3}>
          <PipelineProvider>
            <OEPanesView />
          </PipelineProvider>
        </SnackbarProvider>
      </NotifyProvider>
    </OEPageTemplate>
  );
};
