import React from "react";

import { ASIconButton } from "allegro-ui";
import styled from "styled-components";

// アイコン
import AddIcon from "@material-ui/icons/Add";
import CodeIcon from "@material-ui/icons/Code";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { OELogicSelect, OESaveLogicButton, OEDeployButton } from "../atoms";

const EditorMenuBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  > * {
    margin-left: 8px;
  }
`;

const LeftContainer = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  .MuiInputBase-root {
    flex: 1;
    margin-left: 8px;
  }
`;

const RightContainer = styled.div`
  margin-right: auto;
  > * {
    margin-left: 8px;
  }
`;

export const OEEditorMenuBar: React.FC = () => {
  return (
    <EditorMenuBar>
      <LeftContainer>
        <OELogicSelect />
      </LeftContainer>
      <RightContainer>
        <ASIconButton kind="secondary" size="small" disabled={true}>
          <AddIcon />
        </ASIconButton>
        <ASIconButton kind="secondary" size="small" disabled={true}>
          <InsertDriveFileIcon />
        </ASIconButton>
        <ASIconButton kind="secondary" size="small" disabled={true}>
          <CodeIcon />
        </ASIconButton>
        <OESaveLogicButton />

        <OEDeployButton />
      </RightContainer>
    </EditorMenuBar>
  );
};
