import { AxiosInstance } from "axios";

type Args = {
  inviteEmail: string;
  customInviteURL?: string;
};

export const invite = async (instance: AxiosInstance, data: Args) => {
  const res = await instance({
    method: "post",
    url: `/invite`,
    data: data,
  });

  return res;
};
