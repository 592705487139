import React from "react";
import { useNotify } from "allegro-ui";
import { useAuth } from "../root/UseAuth";
import { FTLoginPageTemplate } from "./FTLoginPageTemplate";
import { useSnackbar } from "notistack";

export const LoginPage: React.FC = () => {
  //@todo ローディング表示
  //const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();

  const handleSubmit = async (
    email: string,
    password: string,
    contractor: string
  ) => {
    notify.dispatch("loading", { isLoading: true });
    const res = auth.login
      ? await auth.login(email, password, contractor)
      : null;
    console.log(res);
    notify.dispatch("loading", {
      isLoading: false,
    });
    if (res instanceof Error) {
      enqueueSnackbar(`ログインに失敗しました。error:${res.message}`, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <FTLoginPageTemplate onSubmit={handleSubmit}></FTLoginPageTemplate>
    </>
  );
};
