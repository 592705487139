import * as React from "react";
import { ASFixedHeader, ASIconButton } from "allegro-ui";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
  padding-top: 12px;
  align-items: center;
  /* height: 62px; */
`;

const Title = styled.h1`
  font-size: 24px;
  /* padding-top: 24px; */
  /* width: 100%; */
  /* text-align: center; */
  font-weight: bold;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
`;

const BackButton = styled(ASIconButton)`
  width: 35px;
  height: 35px;
  margin-right: 16px;
`;

interface IMDStickyHeaderProps {
  title: string;
  backPage?: boolean;
  headerContents?: React.ReactNode;
}

const MDStickyHeader: React.FunctionComponent<IMDStickyHeaderProps> = (
  props
) => {
  const history = useNavigate();

  const back = () => {
    history(-1);
  };

  return (
    <ASFixedHeader>
      <Header>
        {props.backPage ? (
          <BackButton size="small" onClick={back} disabled={!props.backPage}>
            <ArrowBackIcon fontSize="inherit" />
          </BackButton>
        ) : (
          <></>
        )}

        <Title>{props.title}</Title>
        {props.headerContents}
      </Header>
      {/* フィルターとソートのコンポーネント */}
    </ASFixedHeader>
  );
};

export default MDStickyHeader;
