import React from "react";
import styled from "styled-components";

import { NZGlobalNav } from "../organisms/NZGlobalNav";
import { NZTitle } from "../atoms/NZTitle";
import { ASIconButton } from "allegro-ui";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  padding: 0px 30px;
  padding-top: 24px;
  color: ${(props) => props.theme.secondaryOnText};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-bottom: 18px;
  }
`;

const StyledButton = styled(ASIconButton)`
  margin-right: 8px;
`;

type Props = {
  title?: string;
  withBackButton?: boolean;
};

export const NZPrivatePageTemplate: React.FC<Props> = (props) => {
  const history = useNavigate();
  const backPage = () => {
    history(-1);
  };

  return (
    <>
      <div>
        <NZGlobalNav />
        {props.title ? (
          <Wrapper>
            <Header>
              {props.withBackButton ? (
                <StyledButton
                  size="small"
                  kind="white"
                  type="submit"
                  onClick={() => backPage()}
                >
                  <ArrowBack />
                </StyledButton>
              ) : null}
              <NZTitle>{props.title}</NZTitle>
            </Header>
          </Wrapper>
        ) : (
          <></>
        )}

        <>{props.children}</>
      </div>
    </>
  );
};
