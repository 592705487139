import React from "react";
import { ConfirmModalContents } from "../molecules";

type Props = {
  onSubmit: () => void;
  onCancel: () => void;
};

//モニターを複製するためのモーダルを表示するコンポーネント。

export const DuplicateMonitorModalContents: React.FC<Props> = (props) => {
  const { onSubmit, onCancel } = props;
  return (
    <ConfirmModalContents onSubmit={onSubmit} onCancel={onCancel}>
      もう一つ同じモニターカードを作成しますか？
    </ConfirmModalContents>
  );
};
