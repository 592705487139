import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";

export const ciVariablesBroweserUi: ASBrowserUiConf = {
  newtQ: {
    collection: "TopData",
    asset: ["CICDVariables"],
    limit: 10,
    primaryKey: "value",
    // match: {
    //   enable: true,
    // },
    shape: {
      "contents.rawdata": "any",

      _id: "any",
    },
  },
  filters: [],
  tables: [
    {
      label: "Contractor",
      component: "LCSimpleColComponent",
      data: {
        value: "non_numeric_contractor",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },
    {
      label: "Server name",
      component: "LCSimpleColComponent",
      data: {
        value: "non_numeric_server_name",
      },
      options: {
        editable: false,
      },
    },
    {
      label: "Category",
      component: "LCSimpleColComponent",
      data: {
        value: "non_numeric_category",
      },
      options: {
        editable: false,
      },
    },
    {
      label: "Key",
      component: "LCTextLinkCellComponent",
      data: {
        label: "non_numeric_key",
        id: "_id",
      },
      statics: {
        path: "/devops/ci-vars",
      },
      options: {
        editable: false,
      },
    },
    {
      label: "Value",
      component: "LCSimpleColComponent",
      data: {
        value: "non_numeric_value",
      },
      options: {
        editable: false,
      },
    },

    {
      label: "",
      component: "LCDeleteButtonComponent",
      data: {
        name: "name",
        documentId: "_id",
        serverVariableId: "_id",
      },
      options: {
        editable: false,
      },
    },
  ],
  pager: { perPage: 50 },
};
