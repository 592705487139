import styled from "styled-components";

export const MDNoDataPlaceHolder = styled.div`
  width: 100%;
  text-align: center;
  display: grid;
  place-items: center;
  height: 300px;
  border-radius: ${(props) => props.theme.borderRadius}px;
  color: ${(props) => props.theme.placeHolderColor};
`;
