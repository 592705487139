import React, { useCallback, useState } from "react";
import { ASInputBase, ASModal } from "allegro-ui";
import { Box } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { useFileMediator } from "src/opt-editor/hooks/useFileMediator";
import scienceFile from "src/opt-editor/recoils/ScienceFileState";
import { ASFormLabel } from "allegro-low-code-components";
import styled from "styled-components";
import { ConfirmModalContents } from "src/newtzero-console/components/molecules";

type Props = {
  onMenuClose: () => void;
};

const StyledBox = styled(Box)`
  display: block;
`;

export const OECreateFileRightClickMenu: React.FC<Props> = (props) => {
  // createボタンのT/F
  const [showCreateButton, setShowCreateButton] = useState(true);
  // モーダル画面のT/F
  const [showModal, setShowModal] = useState(false);
  // 入力されたテキストを格納
  // const nextFileId = useRecoilValue(scienceFile.nextAutoIncrementalId);

  const [inputFileName, setInputFileName] = useState("");
  // 新規作成処理api
  const fileCompexActions = useFileMediator();

  const handleCreateFile = useCallback(async () => {
    // モーダル画面閉じる
    const nextFileId = scienceFile.getUniqueId();
    await fileCompexActions.add(nextFileId, inputFileName);
    setShowModal(false);
  }, [fileCompexActions, inputFileName]);

  const openModal = (event: React.ChangeEvent<{}>) => {
    setShowModal(true);
    const validFileName = validateFileName(inputFileName);
    setShowCreateButton(validFileName);
    // 親コンポーネントのmouseX,mouseYにnullセット
    props.onMenuClose();
  };

  const validateFileName = (fileName: string) => {
    return fileName.length > 0;
  };

  return (
    <>
      <MenuItem onClick={openModal}>新しいファイル</MenuItem>
      <ASModal
        open={showModal}
        title={"ファイル新規作成"}
        onClose={setShowModal}
      >
        <ConfirmModalContents
          cancelLabel="Cancel"
          submitLabel="Create"
          submitDisabled={!showCreateButton}
          onCancel={() => {
            setShowModal(false);
          }}
          onSubmit={() => handleCreateFile()}
        >
          <StyledBox>
            <ASFormLabel>ファイル名</ASFormLabel>
            <ASInputBase
              kind="secondary"
              onChange={(e: any) => {
                setInputFileName(e.target.value);
                setShowCreateButton(validateFileName(e.target.value));
              }}
            />
          </StyledBox>
        </ConfirmModalContents>
      </ASModal>
    </>
  );
};
