import React, { useState } from "react";
import styled from "styled-components";

import { DropTargetMonitor, useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { CircularProgress } from "@material-ui/core";

const Container = styled("div")`
  display: flex;
  padding: 2rem;
  color: ${(props) => props.theme.secondaryOnText};
  background-color: ${(props) => props.theme.bgPrimaryColor};
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  justify-content: center;
  align-items: center;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${(props) => props.theme.primaryColor};
`;

export type ASFileDnDProps = {
  mainText?: string;
  hoverText?: string;
  onDrop: (item: { files: File[] }) => void;
  className?: string;
};

export const ASFileDnD: React.FC<ASFileDnDProps> = (props: ASFileDnDProps) => {
  const { mainText, hoverText, onDrop, className } = props;
  const [isLoading, setIsLoading] = useState(false);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      async drop(item: any, monitor: DropTargetMonitor) {
        if (onDrop) {
          setIsLoading(true);
          await onDrop(item);
          setIsLoading(false);
        }
      },
      canDrop(item: any) {
        return true;
      },
      hover(item: any) {},
      collect: (monitor: DropTargetMonitor) => {
        const item = monitor.getItem() as any;
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [props]
  );

  const isActive = canDrop && isOver;
  return (
    <Container ref={drop} className={className}>
      {isLoading ? (
        <StyledCircularProgress />
      ) : (
        <>
          {isActive
            ? hoverText ?? "Release to drop"
            : mainText ?? "Drag file here"}
        </>
      )}
    </Container>
  );
};
