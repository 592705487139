import React from "react";
import { TableCell } from "@material-ui/core";
import { DailyThumb } from "src/kinzaza/models";
import { format } from "date-fns";
import styled from "styled-components";
import { ASTableRow } from "allegro-ui";

type Props = {
  id: string;
  dailyThumb: DailyThumb;
  onClick: (unixTime: number) => void;
};

const StyledTableRow = styled(ASTableRow)`
  :hover {
    background-color: ${(props) => props.theme.primaryColor};
    > * {
      color: #111;
    }
  }
`;

export const KZDailyTableRow: React.FC<Props> = ({
  id,
  dailyThumb,
  onClick,
}) => {
  const dateTime = id === "作成日" ? dailyThumb.created : dailyThumb.modified;

  const date = dateTime ? format(dateTime, "yyyy/MM/dd HH:mm:ss") : "-----";

  return dailyThumb ? (
    <StyledTableRow
      onClick={() => {
        onClick(Number(dateTime) ?? 0);
      }}
    >
      <TableCell>{date}</TableCell>
      <TableCell>{dailyThumb.dataCount ?? 0}件</TableCell>
    </StyledTableRow>
  ) : (
    <div>データがありません</div>
  );
};
