import { ASCard } from "allegro-ui";
import { MDNoDataPlaceHolder } from "src/medican/components/atoms";
import { MDJsonCard } from "src/medican/components/molecules/MDJsonCard";
import { FormValues } from "src/medican/models/FormAnswer";
import styled from "styled-components";

const StyledCard = styled(ASCard)`
  background: ${(props) => props.theme.cardColor};
  border: 1px solid ${(props) => props.theme.secondaryBorderColor};
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  margin: 12px;
`;

type Props = { data: any };

export const NZDocumentViewer: React.FC<Props> = (props) => {
  const { data } = props;

  return (
    <>
      {data.map((d: any) => {
        const formValues: FormValues[] = [{ id: d._id, title: "", values: d }];
        return (
          <StyledCard>
            {formValues.length > 0 &&
            Object.keys(formValues[0].values).length > 0 ? (
              <MDJsonCard formValues={formValues} columns={1} />
            ) : (
              <MDNoDataPlaceHolder>データがありません</MDNoDataPlaceHolder>
            )}
          </StyledCard>
        );
      })}
    </>
  );
};
