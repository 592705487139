import React from "react";
import styled from "styled-components";

type Props = {
  src: any;
  defaultSrc: any;
  size?: number;
};

const Wrapper = styled.div<Props>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background: ${(props) =>
    props.theme.darkMode ? "#090909" : props.theme.placeHolderColor};
  background-image: url(${(props) => props.src ?? props.defaultSrc});
  background-size: cover;
  background-position: center;
  border-radius: 999px;
  margin: 0 auto;
  content: "";
`;

export const ASPictureIcon: React.FC<Props> = (props) => {
  return (
    <Wrapper
      src={props.src}
      defaultSrc={props.defaultSrc}
      size={props.size ?? 50}
    />
  );
};
