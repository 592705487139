import { AxiosInstance } from "axios";
import { PipelineXInput } from "../../models/PipelineX";

export const PipelineX = (instance: AxiosInstance) => {
  const add = async (props: {
    contractorNo: string;
    xervice: PipelineXInput;
  }) => {
    const path = `/contractors/${props.contractorNo}/pipelinex`;
    return await instance.post(path, props.xervice);
  };

  const update = async (props: {
    contractorNo: string;
    pipelineId: string;
    xervice: PipelineXInput;
  }) => {
    const path = `/contractors/${props.contractorNo}/pipelinex/${props.pipelineId}`;
    return await instance.put(path, props.xervice);
  };

  return { add, update };
};
