import { Switch } from "@material-ui/core";
import styled from "styled-components";

export const ASSwitchButton = styled(Switch)`
  & .MuiSwitch-switchBase.Mui-checked {
    color: ${(props) => props.theme.primaryColor};
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${(props) => props.theme.primaryColor};
    /* opacity: 0.; */
  }
`;
