import { ASCard } from "allegro-ui";
import styled from "styled-components";

export const FormContainer = styled(ASCard)`
  /* padding: 24px; */
  display: flex;
  width: 100%;
  background: none;

  margin-top: 0;
  /* margin-bottom: 24px; */
  /* flex-wrap: wrap; */
  position: relative;
  flex-direction: column;
  min-height: 72px;
  min-width: 250px;
  max-width: 260px;
  /* max-height: 900px; */
  padding: 12px;
  overflow: scroll;
  > * {
    margin-top: 14px;
  }

  > *:first-child {
    margin-top: 0px;
  }
  /* border: 1px solid ${(props) => props.theme.secondaryBorderColor}; */
  /* border: 1px solid ${(props) => props.theme.cautionColor}; */
`;

export const FlexBlock = styled.div`
  /* min-width: 220px; */
`;

export const IconLayout = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
`;
