export const API_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_BASE_URL
    : window.location.protocol +
      "//" +
      window.location.host +
      process.env.REACT_APP_API_BASE_URL;

export const BROWSER_URL =
  window.location.protocol +
  "//" +
  window.location.host +
  (process.env.PUBLIC_URL === "/" ? "" : process.env.PUBLIC_URL);

export const APP_NAME = process.env.REACT_APP_APP;
