//Libs
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
// import routes from "../../routes.json";

//Components
import { Link } from "react-router-dom";
import { OEUserButton } from "src/opt-editor/components";

//States
import { useRecoilValue } from "recoil";
import { authState } from "src/root/recoils/userState";

import routes from "src/allegrobot/routes.json";

//Types

const APP = process.env.REACT_APP_APP;
const Logo = require(`src/${APP}/components/atoms/logo`).default;
export const headerOffset = 56;

const Container = styled.div`
  /* width: 100%; */
  height: ${headerOffset}px;
  background: ${(props) => props.theme.bgPrimaryColor};
  /* padding: 0 30px; */
  /* border-bottom: 1px solid #37383a; */
  /* position: fixed; */
  display: flex;
  align-items: center;
`;

const AppBar = styled.div`
  width: 100%;
  padding: 0 16px;
  position: fixed;
  z-index: 999;
  height: ${headerOffset}px;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.bgSecondaryColor};
  border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};
`;

const LogoAndTitle = styled.div`
  display: flex;
  align-items: center;
  > img {
    margin-top: 4px;
    width: 128px;
  }
`;

const Title = styled.h1`
  margin-left: 8px;
  font-size: 18px;
  > img {
    margin-top: 4px;
    width: 128px;
  }

  font-weight: 400;
  letter-spacing: 1px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
`;

const MenuItem = styled.li`
  color: #fff;
  list-style-type: none;
  > a {
    color: #fff;
    text-decoration: none;
    margin-right: 36px;
    font-size: 14px;
    font-weight: 500;

    /* font-weight: bold; */
    &:hover {
      opacity: 0.8;
    }
  }
`;

const MenuGroup = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const Skelton = styled.div`
  clear: both;
  position: relative;
  /* width:100%: */
  /* height: ${headerOffset}px; */
`;

type Props = {
  logo?: any;
};
export const ARGlobalNav: React.FC<Props> = (props) => {
  const auth = useRecoilValue(authState);
  const history = useNavigate();
  // const color = theme.darkMode ? "white" : "black";

  const handleLogoClick = () => {
    history("/");
  };

  const https = "https";
  const domain = process.env.REACT_APP_SMARTHUB_HOST;

  return (
    <>
      <Container>
        <AppBar>
          <LogoAndTitle onClick={handleLogoClick}>
            {props.logo ? props.logo : <Logo size={28} />}
            {/* <Title>
              <img src={logo} alt="AllegRobot" />
            </Title> */}
          </LogoAndTitle>
          {auth.token ? (
            <MenuGroup>
              <MenuItem>
                <Link to={routes.SCIENCE}>SmartScience</Link>
              </MenuItem>

              <MenuItem>
                <Link to={routes.SMARTHUB}>SmartHub</Link>
                {/* <a
                  href={`${https}://${auth.contractorNo}.${domain}/pipelinedefs`}
                  target="_blank"
                  rel="noreferrer"
                >
                  SmartHub
                </a> */}
              </MenuItem>

              <MenuItem>
                <Link to={routes.PIPELINES}>Pipeline</Link>
              </MenuItem>

              <MenuItem>
                <Link to={routes.LAKEHOUSE}>Lakehouse</Link>
              </MenuItem>
              {/* <MenuItem>
                <a
                  href={`${https}://${auth.contractorNo}.${domain}/newtroid/xervice`}
                >
                  Xervice
                </a>
              </MenuItem> */}
              <MenuItem>
                <Link to={routes.OPTX_BROWSER}>OptX</Link>
              </MenuItem>
              <React.Suspense fallback={<>...</>}>
                {auth.token ? <OEUserButton /> : <></>}
              </React.Suspense>
            </MenuGroup>
          ) : (
            <></>
          )}
        </AppBar>
      </Container>
      <Skelton></Skelton>
    </>
  );
};
