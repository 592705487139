// ダミーデータが欲しいときはbases.tagsを虐待予防的支援データにして、模擬データが欲しいときはbases.tagsをtest-dataにして調整してください
// { "bases.tags": "test-data" }
// tags: ["hiroshimaFuchucity", "fuchucoRev2", "test-data"]

/**
 * @deprecated
 */
export const config = {
  defaultQueries: {
    fetch: {
      childList: {
        where: {
          $and: [
            { "bases.tags": "hiroshimaFuchucity" },
            { "bases.tags": "fuchucoRev2" },
            { "bases.tags": "宛名" },
            // { "bases.tags": "虐待予防的支援業務" },
          ],
        },
      },
      personalDatas: {
        where: {
          $and: [
            { "bases.tags": "hiroshimaFuchucity" },
            { "bases.tags": "fuchucoRev2" },
            { "bases.tags": "統合行政データ" },
          ],
        },
      },
      familyDatas: {
        where: {
          $and: [
            { "bases.tags": "hiroshimaFuchucity" },
            { "bases.tags": "fuchucoRev2" },
            { "bases.tags": "宛名" },
          ],
        },
      },
      welfareDatas: {
        where: {
          $and: [
            { "bases.tags": "hiroshimaFuchucity" },
            { "bases.tags": "fuchucoRev2" },
            { "bases.tags": "生活保護関係業務" },
          ],
        },
      },
      aiPredictions: {
        where: {
          $and: [
            { "bases.tags": "hiroshimaFuchucity" },
            { "bases.tags": "TabNet推論結果" },
            { "bases.tags": "fuchuco_rev3" },
          ],
        },
      },
      scatterPlots: {
        where: {
          $and: [
            { "bases.tags": "hiroshimaFuchucity" },
            { "bases.tags": "虐待予防的支援データグループグラフ用" },
            { "bases.tags": "fuchuco_rev3" },
          ],
        },
      },
      assesments: {
        where: {
          $and: [
            { "bases.tags": "hiroshimaFuchucity" },
            { "bases.tags": "fuchucoRev2" },
            { "bases.tags": "虐待予防的支援業務リスクアセスメント" },
          ],
        },
      },
    },
    post: {
      assesment: {
        tags: [
          "hiroshimaFuchucity",
          "fuchucoRev2",
          "虐待予防的支援業務リスクアセスメント",
        ],
      },
      yobouShien: {
        tags: ["hiroshimaFuchucity", "fuchucoRev2", "虐待予防的支援業務"],
      },
    },
    delete: {
      assesment: {
        where: {
          $and: [
            { "bases.tags": "hiroshimaFuchucity" },
            { "bases.tags": "fuchucoRev2" },
            { "bases.tags": "虐待予防的支援業務リスクアセスメント" },
          ],
        },
      },
    },
  },
  personalData: {
    child: {
      dataLinkFileNames: [
        "宛名",
        "ひとり親医療費助成",
        "児童手当",
        "児童扶養手当",
        "幼児教育無償化",
        "子育て支援",
        "身体障害者手帳台帳",
        "療育手帳交付台帳",
        "精神保健福祉手帳台帳",
        "自立支援医療（精神通院）業務",
        "４か月児健診業務",
        "乳児健診業務",
        "１０か月児健診業務",
        "１歳６か月児健診業務",
        "２歳児歯科健診業務",
        "３歳児健診業務",
        "定期予防接種業務",
        "こんにちは赤ちゃん訪問業務",
        "すくすく相談業務",
        "幼児相談業務",
      ],
    },
    parent: {
      datalinkFileNames: [
        "生活保護関係業務",
        "宛名",
        "身体障害者手帳台帳",
        "療育手帳交付台帳",
        "精神保健福祉手帳台帳",
        "自立支援医療（精神通院）業務",
        "乳幼児医療費助成",
      ],
    },
  },
  aiNames: {
    検査1: "汎用TabNet",
    検査2: "汎用TabNet",
  },
};
