import produce from "immer";

export type Server = {
  non_numeric_server_id?: string;
  non_numeric_server_name: string;
  non_numeric_ip1: string;
  non_numeric_ip2?: string;
  non_numeric_ip3?: string;
  non_numeric_public_ssh_key?: string;
  non_numeric_tags: string[];
  non_numeric_ssh_host?: string;
  non_numeric_ssh_private_key?: string;
  non_numeric_ssh_public_key?: string;
};

const defaultServer: Server = {
  non_numeric_server_id: "",
  non_numeric_server_name: "",
  non_numeric_ip1: "",
  non_numeric_tags: [],
};

export const createServerFromNewtData = (data: any) => {
  return produce(defaultServer, (draft) => {
    const rawdata = data.contents.rawdata;
    draft.non_numeric_server_id =
      rawdata.non_numeric_server_id ?? draft.non_numeric_server_id;
    draft.non_numeric_server_name =
      rawdata.non_numeric_server_name ?? draft.non_numeric_server_name;
    draft.non_numeric_ip1 = rawdata.non_numeric_ip1 ?? draft.non_numeric_ip1;
    draft.non_numeric_ip2 = rawdata.non_numeric_ip2 ?? draft.non_numeric_ip2;
    draft.non_numeric_ip3 = rawdata.non_numeric_ip3 ?? draft.non_numeric_ip3;
    draft.non_numeric_public_ssh_key =
      rawdata.non_numeric_public_ssh_key ?? draft.non_numeric_public_ssh_key;
    draft.non_numeric_tags = rawdata.non_numeric_tags ?? draft.non_numeric_tags;
    draft.non_numeric_ssh_host =
      rawdata.non_numeric_ssh_host ?? draft.non_numeric_ssh_host;
    draft.non_numeric_ssh_private_key =
      rawdata.non_numeric_ssh_private_key ?? draft.non_numeric_ssh_private_key;
    draft.non_numeric_ssh_public_key =
      rawdata.non_numeric_ssh_public_key ?? draft.non_numeric_ssh_public_key;
  });
};
