import { atom, selector, useRecoilValue } from "recoil";
import { useCallback } from "react";
import * as api from "src/utils";
import { Slot, initialSlot, Slots } from "../models";
import { cursorPipelineState, useCursorPipeline } from "./pipelineState";
import { produce } from "immer";
import { useCursorOptLogic } from "./optLogicState";

export const slotListState = selector<Slots>({
  key: "slotList",
  get: ({ get }) => {
    const cursorPipeline = get(cursorPipelineState);
    const slots = createSlotsFromPipeline(cursorPipeline);
    return slots;
  },
});

export const selectSlotState = atom<string>({
  key: "selectSlotState",
  default: "",
});

export const cursorSlotState = selector<Slot>({
  key: "cursorSlot",
  get: ({ get }) => {
    const list = get(slotListState);
    const slotName = get(selectSlotState);
    return list[slotName];
  },
});

export const useDeployToSlot = () => {
  const cursorSlot = useRecoilValue(cursorSlotState);
  const cursorPipeline = useCursorPipeline();
  const cursorOptLogic = useCursorOptLogic();
  const deploy = useCallback(async () => {
    const res = await api.deploy({
      contractorNo: sessionStorage.contractorNo,
      pipeline: cursorPipeline,
      slot: cursorSlot,
      optLogic: cursorOptLogic,
    });
    return res;
  }, [cursorOptLogic, cursorPipeline, cursorSlot]);
  return deploy;
};

const createSlotsFromPipeline = (p: any): Slots => {
  const { contents } = p;
  const parsedContents =
    typeof contents === "string" && contents !== ""
      ? JSON.parse(String(contents))
      : contents;

  if (!parsedContents) {
    console.log("invalid argments were given.");
    return {};
  }

  let nodes: any = null;

  // //Contents,nodesがJavaでは配列でWrapされている e.g contents:[nodes:{ ~~ }]
  if (parsedContents.length > 0) {
    nodes = parsedContents[0].nodes;
  } else {
    nodes = parsedContents.nodes;
  }

  const slots: Slots = {};
  if (nodes && Object.keys(nodes).length > 0) {
    Object.keys(nodes).map((key) => {
      return (slots[key] = produce(initialSlot, (draft) => {
        const n = nodes[key];
        draft.name = n.name;
        draft.type = n.type;
        draft.col = n.col;
        draft.row = n.row;
        draft.description = n.description;
        draft.lines = n.lines;
        draft.logic = n.logic;
        draft.logicType = n.logicType;
      }));
    });
  }

  return slots;
};
