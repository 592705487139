import { api } from "src/api";
import { Bundle, createBundle } from "src/general/models";

export const findBundle = async (
  bundleId: string,
  version: string
): Promise<Bundle> => {
  const res = await api.get(`/bundles/${bundleId}/${version}`);
  return createBundle(res.data.message[0]);
};
