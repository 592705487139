import React, { useState } from "react";

import { ASModal, ASIconButton } from "allegro-ui";
import { Menu, MenuItem } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useSlotActions } from "src/xerver-room/hooks";
import { DeleteConfirmModalContents } from "src/kinzaza/components";
import { XRStatsViewer } from "../organisms/XRDockerStatsViewer";

type Props = {
  slotId: string;
  disabled?: boolean;
};
export const XRMenuSlotButton: React.FC<Props> = ({ slotId, disabled }) => {
  const slot = useSlotActions(slotId);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStatsModal, setShowStatsModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenuOpen = (event: React.ChangeEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteModalOpen = () => {
    setShowDeleteModal(true);
    setAnchorEl(null);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const handleStatsModalOpen = () => {
    setShowStatsModal(true);
    setAnchorEl(null);
  };

  const handleStatsModalClose = () => {
    setShowStatsModal(false);
  };

  const handleDelete = () => {
    slot.deleteSlot(slot);
    setShowDeleteModal(false);
  };
  return (
    <>
      <ASIconButton
        kind="secondary"
        size="small"
        disabled={disabled}
        onClick={handleMenuOpen}
      >
        <MoreHorizIcon />
      </ASIconButton>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => handleStatsModalOpen()}>Stats</MenuItem>
        <MenuItem onClick={() => handleDeleteModalOpen()}>Delete</MenuItem>
      </Menu>
      {/* Slot削除モーダル */}
      <ASModal open={showDeleteModal} onClose={() => handleDeleteModalClose()}>
        <DeleteConfirmModalContents
          onSubmit={() => handleDelete()}
          onCancel={handleDeleteModalClose}
        />
      </ASModal>

      {/* Slot CPUリソースモーダル */}
      <ASModal
        title={`Stats ${slot.name}`}
        open={showStatsModal}
        onClose={() => handleStatsModalClose()}
      >
        <XRStatsViewer slot={slot} />
      </ASModal>
    </>
  );
};
