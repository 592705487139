import { PipelineXInput } from "allegro-api";
import { produce } from "immer";

export type PipelineX = {
  id: string;
  name: string;
  createdBy: string;
  slots: Array<String>;
} & PipelineXInput;

export const defaultPipeline: PipelineX = {
  id: "nothing",
  name: "No Name",
  slots: [],
  createdBy: "unknown",
  readableUsers: ["*"],
  writableUsers: ["*"],
  readableGroups: [],
  writableGroups: [],
};

export const createPipeline = (props: Partial<PipelineX>) => {
  return produce(defaultPipeline, (draft) => {
    draft.id =
      typeof props.id === "string" && props.id !== "" ? props.id : draft.id;
    draft.name =
      typeof props.name === "string" && props.name !== ""
        ? props.name
        : draft.name;

    draft.createdBy = props.createdBy ?? "unknown";

    draft.slots = [];

    draft.readableUsers = props.readableUsers ?? draft.readableUsers;
    draft.writableUsers = props.writableUsers ?? draft.writableUsers;

    draft.readableGroups = props.readableGroups ?? draft.readableGroups;
    draft.writableGroups = props.writableGroups ?? draft.writableGroups;
  });
};
