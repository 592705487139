import * as React from "react";
import { CHTabs } from "../molecules/CHTabs";
import { assetCreatingFormDefinition } from "src/newtzero-console/components/lowcode/browser/asset/assetCreatingFormDefinition";
import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { NZAssetDataViewTemplate } from "src/newtzero-console/components/lowcode/browser/templates/NZAssetDataViewTemplate";
import { useAssetDetail } from "src/cheetah/hooks/useAssetDetail";
import { DFNotFoundPage } from "src/dfurusato-pf/components/pages/DFNotFoundPage";

interface ICHAssetSinglePageProps {
  itemId: string;
}

/**
 *
 * @param param0
 * @returns
 * @deprecated 汎用化する。
 */
const CHAssetSinglePageTemplate: React.FunctionComponent<ICHAssetSinglePageProps> =
  ({ ...props }) => {
    const { isLoading, assetDetail } = useAssetDetail(props.itemId);

    const tabs = [
      {
        label: "Data",
        component: <NZAssetDataViewTemplate asset={assetDetail} />,
      },
      {
        label: "Setting",
        component: (
          <React.Suspense fallback={<>loading</>}>
            <ASLowCodeFormContainer
              formDefinition={assetCreatingFormDefinition}
              fullWidth
              isUpdate
              columns={2}
              defaultValues={{
                ...assetDetail,
                readablePublicAssetData: assetDetail?.readablePublicAssetData
                  ? "public"
                  : "private",
              }}
              // onSubmit={(data, options) => {
              //   if (assetCreatingFormDefinition.onSubmit) {
              //     assetCreatingFormDefinition.onSubmit(data, options as any);
              //   }
              // }}
            />
          </React.Suspense>
        ),
      },
    ];
    return (
      <>
        {!isLoading ? (
          assetDetail ? (
            <CHTabs tabs={tabs} title={assetDetail?.name ?? "---"} />
          ) : (
            <DFNotFoundPage />
          )
        ) : (
          <>loading</>
        )}
      </>
    );
  };

export default CHAssetSinglePageTemplate;
