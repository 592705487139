import { FormControl } from "@material-ui/core";
import { ASButton, ASInputBase } from "allegro-ui";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 100%;
  width: 400px;
  display: flex;
  flex-direction: column;
  text-align: left;
  > * {
    margin-top: 24px;
  }
  /* background: red; */
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.primaryColor};
  text-align: center;
`;

const StyledButton = styled(ASButton)`
  margin-top: 36px;
`;

const StyledSpan = styled.div`
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
  margin-bottom: 8px;
`;

type Props = {
  onSubmit: (email: string, password: string, contractor: string) => void;
};

export const PTLoginForm: React.FC<Props> = (props) => {
  const { onSubmit } = props;
  const [contractor, setContractor] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <Container>
      <FormControl>
        <StyledSpan>コントラクタ</StyledSpan>
        <ASInputBase
          id="id"
          kind="outline"
          placeholder="入力してください"
          defaultValue=""
          onChange={(e) => {
            setContractor(e.target.value);
          }}
        />
      </FormControl>

      <FormControl>
        <StyledSpan>メールアドレス</StyledSpan>
        <ASInputBase
          id="email"
          kind="outline"
          placeholder="入力してください"
          defaultValue=""
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </FormControl>
      <FormControl>
        <StyledSpan>パスワード</StyledSpan>
        <ASInputBase
          id="password"
          margin="dense"
          defaultValue=""
          kind="outline"
          placeholder="入力してください"
          required={true}
          type="password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          onKeyPress={(event: any) => {
            if (event.key === "Enter") {
              onSubmit(email, password, contractor);
            }
          }}
        />
      </FormControl>
      <StyledButton
        kind="primary"
        onClick={(e: any) => {
          onSubmit(email, password, contractor);
        }}
        size="large"
      >
        ログイン
      </StyledButton>
      <StyledLink to="/prepare-reset-password">
        パスワードをお忘れですか？
      </StyledLink>
    </Container>
  );
};
