//基本的に複数のコンポーネントに跨って利用されているステートはrecoilを利用し、グローバルステートとして保持している。

import { atom, atomFamily, selector } from "recoil";
import { ChildListItem, initialChildListItem } from "../models";

import * as api from "../api";
import { getConfig } from "../utils/assesmentId/switch";

export const tabIdState = atomFamily<number, string | number>({
  key: "singleChildPageState",
  default: 0,
});

//児童詳細画面において、その人の宛名番号をcursorChildIdStateとして保持しておく。
//これをもとにその児童のデータを取得する。

export const cursorChildIdState = atom({
  key: "CursorChildIdState",
  default: "",
});

//児童詳細画面の上部の不足データの表示をするか否かをグローバルステートとして保持しときたかったため。

export const crossChangeState = atom<boolean>({
  key: "CrossChange",
  default: false,
});

//アセスメントを投稿した瞬間に虐待予防的支援データのUIを再レンダリングして最新のものにするため。

export const fireCountState = atom<number>({
  key: "FireCountState",
  default: 0,
});

//cursorChildIdStateの宛名番号から、その子どもの虐待予防的支援データを取得している。
//今後この値は、ありとあらゆるコンポーネントで利用されるので、selectorでグローバルステートとして保持した。

export const cursorChildThumbState = selector({
  key: "CursorChildThumbState",

  get: async ({ get }) => {
    const cursorChildId = get(cursorChildIdState);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const fireCount = get(fireCountState);
    if (cursorChildId) {
      const [data] = await api.newtOut({
        asset: ["宛名"],
        primaryKey: "contents.optdata.numeric_resident_register_code",
        order: {
          "contents.optdata.numeric_resident_register_code": 1,
        },
        search: {
          "contents.optdata.numeric_resident_register_code":
            Number(cursorChildId),
        },
        limit: 1,
        skip: 0,

        shape: {
          "contents.optdata": "any",
        },
      });
      const childJson = Array.isArray(data) ? data[0] : data;

      if (!childJson) return initialChildListItem;

      const cursorChild = new ChildListItem(childJson);
      return cursorChild;
    } else {
      return initialChildListItem;
    }
  },
});

//新規アセスメント作成で、学齢期と乳幼児期で出すUIを変えないといけないので実装

export const cursorConfigState = selector({
  key: "CursorConfigState",

  get: async ({ get }) => {
    const cursorChildThumb = get(cursorChildThumbState);
    if (cursorChildThumb.age >= 7) {
      return getConfig("学齢期");
    } else {
      return getConfig("乳幼児期");
    }
  },
});
