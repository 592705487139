import React, { useEffect, useRef, useState } from "react";
import {
  References,
  ReferenceData,
  getReferenceValue,
  createReference,
} from "../../models/DynamicReference";
import { ASInputBase } from "allegro-ui";
import styled from "styled-components";
import { ReferenceMenu } from "./ReferenceMenu";

const StyledInputBase = styled(ASInputBase)`
  line-height: 1.7;
  letter-spacing: 0.04em;
  resize: auto;
`;
type Props = {
  name: string;
  editable?: boolean;
  defaultValue?: string;
  values?: References;
  placeholder?: string;
  prefix?: string;
  suffix?: string;
  design?: "digicho" | "default";
  isdesktop?: boolean;
  multiline?: boolean;
  onChange: (text: string) => void;
};

export const ASTextFormFieldComponent: React.FC<Props> = (props) => {
  const {
    name,
    editable,
    defaultValue,
    values,
    placeholder,
    prefix,
    suffix,
    design,
    multiline,
    isdesktop = true,
    onChange,
  } = props;
  const anchorRef = useRef(null);

  const [text, setText] = useState<string>(defaultValue ?? "");
  const [options, setOptions] = useState<ReferenceData[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {}, []);

  const _values = typeof values === "string" ? createReference(values) : values;

  const handleChange = async (event: any) => {
    const newText: string = event.target.value;
    if (_values) {
      const newOptions = await getReferenceValue(_values, newText);

      if (newOptions && newOptions.length > 0) {
        setOptions(newOptions);
        setOpen(true);
      }
    }

    onChange(newText);
  };

  return (
    <>
      <div ref={anchorRef}>
        <StyledInputBase
          value={text}
          key={`as-text-form-component-${name}-${defaultValue}`}
          multiline={multiline}
          minRows={3}
          maxRows={8}
          aria-expanded
          placeholder={placeholder ?? "入力してください"}
          isdesktop={isdesktop ? "true" : "false"}
          fullWidth
          disabled={!(editable ?? false)}
          kind={design === "digicho" ? "digicho" : "secondary"}
          onChange={(e) => {
            setText(e.target.value);
            handleChange(e);
          }}
          onFocus={() => {
            setOpen(true);
          }}
          onBlur={() => {
            setOpen(false);
          }}
        />

        <ReferenceMenu
          open={open}
          anchorEl={anchorRef.current}
          options={options}
          onClick={(value) => {
            setText(value);
            onChange(value);
            setOpen(false);
          }}
        />
      </div>
    </>
  );
};
