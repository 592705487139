import * as React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as api from "src/fortress/api";
import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { CHTabs } from "../../molecules/CHTabs";
import { assetCreatingFormDefinition } from "src/newtzero-console/components/lowcode/browser/asset/assetCreatingFormDefinition";
import { assetBroweserUi } from "src/newtzero-console/components/lowcode/browser/asset/assetBrowserUi";
import { NZTitle } from "src/newtzero-console/components/atoms/NZTitle";

interface ICHAssetSinglePageProps {}

const CHAssetSinglePage: React.FunctionComponent<ICHAssetSinglePageProps> = ({
  ...props
}) => {
  const [assetDetail, setMetricDetail] = useState<any>();
  const params = useParams();

  //fetch asset
  useEffect(() => {
    console.log(assetBroweserUi.newtQ);

    api
      .newtOut({
        ...assetBroweserUi.newtQ,
        ...{
          search: {
            name: params.itemId,
          },
        },
      })
      .then(([resData, count]) => {
        if (resData && resData.length > 0) {
          console.log(resData[0]);
          setMetricDetail(resData[0]);
        }
      });

    return () => {
      setMetricDetail(undefined);
    };
  }, [params.itemId]);

  const tabs = [
    {
      label: "編集",
      component: (
        <React.Suspense fallback={<>loading</>}>
          <ASLowCodeFormContainer
            formDefinition={assetCreatingFormDefinition}
            fullWidth
            isUpdate
            defaultValues={
              assetDetail
                ? {
                    name: assetDetail?.name,
                    sharings: assetDetail?.sharings,
                    tags: assetDetail?.tags,
                  }
                : undefined
            }
          />
        </React.Suspense>
      ),
    },
    // {
    //   label: "履歴",
    //   component: <>ama</>,
    // },
  ];
  return (
    <>
      {assetDetail ? (
        <CHTabs
          tabs={tabs}
          headerComponent={<NZTitle>{assetDetail.name}</NZTitle>}
        />
      ) : (
        <>loading</>
      )}
    </>
  );
};

export default CHAssetSinglePage;
