import * as React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as api from "src/fortress/api";
import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { CHTabs } from "../../molecules/CHTabs";
import { serverCreatingFormDefinition } from "src/cheetah/definitions/forms/serverCreatingFormDefinition";
import { createServerBroweserUi } from "src/cheetah/definitions/listPages/serverBrowserUi";
import { useServerDetail } from "src/newtroid-console/hooks/useServerDetail";

interface ICHServerSinglePageProps {}

/**
 *
 * @param param0
 * @returns
 * @deprecated TODO:factory化する
 */
const CHServerSinglePage: React.FunctionComponent<ICHServerSinglePageProps> = ({
  ...props
}) => {
  const { serverDetail, canUpdateServer } = useServerDetail();

  const tabs = [
    {
      label: "編集",
      component: (
        <React.Suspense fallback={<>loading</>}>
          <ASLowCodeFormContainer
            formDefinition={serverCreatingFormDefinition}
            editable={canUpdateServer}
            fullWidth
            isUpdate
            defaultValues={
              serverDetail
                ? {
                    name: serverDetail?.contents.rawdata
                      .non_numeric_server_name,
                    IP1: serverDetail?.contents.rawdata.non_numeric_ip1,
                    IP2: serverDetail?.contents.rawdata.non_numeric_ip2,
                    IP3: serverDetail?.contents.rawdata.non_numeric_ip3,
                    公開鍵:
                      serverDetail?.contents.rawdata.non_numeric_public_ssh_key,
                    タグ: serverDetail?.contents.rawdata.non_numeric_tags,
                  }
                : undefined
            }
          />
        </React.Suspense>
      ),
    },
    {
      label: "履歴",
      component: <>ama</>,
    },
  ];
  return (
    <>
      {serverDetail ? (
        <CHTabs tabs={tabs} title={serverDetail.name} />
      ) : (
        <>loading</>
      )}
    </>
  );
};

export default CHServerSinglePage;
