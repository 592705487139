import { ASButton } from "allegro-ui";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface IFTSystemNavProps {}

const ButtonGroup = styled.div`
  > * {
    margin-left: 8px;
    &:first-child {
      margin-left: 0px;
    }
  }
`;

/**
 *
 * @param props
 * @returns
 * @deprecated TODO: factory化する
 */
const FTSystemNav: React.FunctionComponent<IFTSystemNavProps> = (props) => {
  const history = useNavigate();
  const handleLink = (path: string) => {
    history(path);
  };
  return (
    <>
      <ButtonGroup>
        <ASButton
          kind={"secondary"}
          onClick={() => {
            handleLink("assets");
          }}
        >
          アセット
        </ASButton>
        <ASButton
          kind={"secondary"}
          onClick={() => {
            handleLink("metrics");
          }}
        >
          メトリック
        </ASButton>
        <ASButton
          kind={"secondary"}
          onClick={() => {
            handleLink("servers");
          }}
        >
          サーバー
        </ASButton>
        <ASButton
          kind={"secondary"}
          onClick={() => {
            handleLink("cicdconfigs");
          }}
        >
          CI/CD設定
        </ASButton>
      </ButtonGroup>
    </>
  );
};

export default FTSystemNav;
