import favy from "allegro-api";
import {
  atom,
  AtomEffect,
  atomFamily,
  DefaultValue,
  RecoilState,
  RecoilValueReadOnly,
  selector,
  useRecoilCallback,
  useSetRecoilState,
  waitForAll,
} from "recoil";
import { v4 as uuidv4 } from "uuid";

type TopDataBrowserFactoryState<T, Filter> = {
  item: (param: string | number) => RecoilState<T>;
  itemList: RecoilValueReadOnly<T[]>;
  requestIdState: RecoilState<number>;
  filteredItemList?: RecoilValueReadOnly<T[]>;
  filterState: RecoilState<Filter | null>;
  cursorItemIdState: RecoilState<number | string>;
  nextAutoIncrementalId: RecoilValueReadOnly<number>;
  idList: RecoilValueReadOnly<Array<string | number>>;
  getUniqueId: () => string;
  currentPage?: number;
  skip?: number;
  limit?: number;
  setSkip?: () => void;
  setLimit?: () => void;
  useItemList: () => any;
  addItem: (data: T) => void;
  update?: (data: T) => void;
  deleteItem: (itemId: string | number) => void;
  useRefresh: () => {
    refresh: () => void;
  };
  filter?: Filter;
};

/**
 * 指定したトップデータをブラウジングするすべての機能を提供します。
 */
export const topDataStateFactory = <T, Filter>(
  name: string,
  defaultValue: T,
  tags: string[],
  primaryKey: string,
  factory: (resData: any) => T
): TopDataBrowserFactoryState<T, Filter> => {
  const api = favy.topdata<T>(tags, primaryKey, factory);

  // const { itemDBSyncEffect } = effectFactory(api);

  const fetchIds = async (
    filter?: Filter,
    options?: { limit?: number }
  ): Promise<Array<string | number>> => {
    const limit = options?.limit ?? 999;
    const data = await api.getMany(limit, 0, filter ?? {});

    if (data && data.length > 0)
      return data.map((d) => {
        const obj = d as any;
        return obj[primaryKey] ?? "no keey";
      });
    else return [];
  };

  const fetchById = async (id: string | number): Promise<T | DefaultValue> => {
    if (id) {
      const partOfWhere = {
        [`contents.rawdata.${primaryKey}`]: id,
      };

      const item = await fetchOne(partOfWhere, 10);

      return item;
    } else {
      console.error("invalid id were given", id);
      return new DefaultValue();
    }
  };

  const idListDBSyncEffect: AtomEffect<Array<string | number>> = ({
    setSelf,
    getPromise,
    trigger,
  }) => {
    if (trigger === "get") {
      setSelf(fetchIds());
    }
  };

  const cursorItemIdState = atom<number | string>({
    key: `${name}CursorIDState`,
    default: 0,
  });

  const requestIdState = atom({ key: `${name}RequestIDState`, default: 0 });

  const nextAutoIncrementalId = selector<number>({
    key: `${name}NextAutoIncrementIdState`,
    get: async ({ get }) => {
      //idが最大のtopdataを取得する。
      let ids = get(idList);
      if (ids.length > 0) {
        const lastItem = await api.getOne(
          {},
          { "contents.rawdata.itemId": -1 }
        );
        try {
          if (lastItem) {
            const asAny = lastItem as any;
            const num: number = asAny[primaryKey];
            return num + 1;
          } else {
            return 1;
          }
        } catch (err) {
          console.log(err);

          return 1;
        }
      } else return 1;
    },
  });

  const getUniqueId = () => {
    // return "";
    return uuidv4();
  };

  const addItem = async (newItem: T, reflesh: boolean = false) => {
    await api.post(newItem);
  };

  const deleteItem = async (itemId: number | string) => {
    await api.remove(itemId);
  };

  // const deleteItemSelector = selector<number>({
  //   key: `${name}deleteItem`,
  //   get: ({ get }) => {
  //     console.log("non implementation get method.");
  //   },
  //   set: async ({ set }, itemId) => {

  //     await api.remove(itemId);
  //   },
  // });

  const fetchOne = async (
    partOfWhere: any,
    retry: number = 0
  ): Promise<any> => {
    let result = null;
    try {
      result = await api.getOne(partOfWhere);
      return result;
    } catch (err) {
      if (retry > 0) {
        console.log("retry", retry, partOfWhere);
        return fetchOne(partOfWhere, retry - 1);
      }
    }
  };

  const itemDBSyncEffect: (itemId: string | number) => AtomEffect<T> =
    (itemId) =>
    ({ setSelf, getPromise, getLoadable, trigger, onSet }) => {
      if (trigger === "get") {
        if (itemId) {
          setSelf(fetchById(itemId));
        }
      } else {
      }

      if (trigger === "set") {
      }

      onSet(async (newItem: T, _, isReset) => {
        // const ids = getLoadable(idList);
        const anylized = newItem as { [key: string]: any };
        if (anylized.readers && anylized.writers) {
          await api.post(newItem, anylized.readers, anylized.writers);
        } else {
          console.log("not found readers, writers");
          await fetchOne(newItem, 10);
        }
        const partOfWhere = {
          [`contents.rawdata.${primaryKey}`]: anylized[primaryKey],
        };
        const result = await api.getOne(partOfWhere);
        if (!result) {
          console.error("EROR");
        }
        if (result) {
          setSelf(result);
        }
      });
    };

  const filterState = atom<Filter | null>({
    key: `${name}FilterStatee`,
    default: null,
  });

  // const skipState = atom({
  //   key: `${name}SkipState`,
  //   default: {},
  // });

  const limitState = atom<number>({
    key: `${name}LimitState`,
    default: 200,
  });

  const item = atomFamily<T, string | number>({
    key: `${name}State-uniq`,
    default: defaultValue,
    effects: (itemId: string | number) => [itemDBSyncEffect(itemId)],
  });

  const idList = selector<Array<number | string>>({
    key: `${name}IdListState`,
    get: async ({ get }) => {
      get(requestIdState); //マニュアルリロード対応
      const filter = get(filterState);
      const limit = get(limitState);

      if (filter && Object.keys(filter).length > 0) {
        const ids = await fetchIds(filter, { limit: limit });
        return ids;
      } else {
        return [];
      }
    },
  });

  const useRefresh = () => {
    const setRequestId = useSetRecoilState(requestIdState);

    const refresh = () => {
      setRequestId((id) => {
        return ++id;
      });
    };
    return { refresh };
  };

  /**
   * @deperecated itemListが機能しない場合
   */
  const useItemList = () => {
    const getItems = useRecoilCallback(
      ({ snapshot }) =>
        async () => {
          let items: any[] = [];

          const ids = await snapshot.getPromise(idList);

          for (const id of ids) {
            // const i = await snapshot.getPromise(item(id));
            const i = await fetchById(id);
            items.push(i);
          }

          return items;
          // setItems(items)
        },
      [idList]
    );

    return { getItems };
    // return items;
  };

  const itemList = selector<T[]>({
    key: `${name}ItemListStatee`,
    get: async ({ get }) => {
      get(requestIdState);
      // console.log(requestId);
      const ids = get(idList);

      const items = get(
        waitForAll(
          ids.map((id) => {
            return item(id);
          })
        )
      );

      return items;
    },
  });

  return {
    item,
    addItem,
    deleteItem,
    idList,
    itemList,
    filterState,
    cursorItemIdState,
    nextAutoIncrementalId,
    requestIdState,
    getUniqueId,
    useRefresh,
    useItemList,
    // filteredItemList,
  };
};
