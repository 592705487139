import { UploadLogStatus } from "src/newtroid/models";
import styled from "styled-components";

const LogContainer = styled.div`
  display: flex;
  flex-direction: row;
  > *:last-child {
    margin-left: auto;
  }
`;

const LogText = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
`;

const LogDetail = styled.div`
  text-align: right;
`;

const SuccessText = styled.div`
  color: ${(props) => props.theme.positiveColor};
`;

const FailedText = styled.div`
  color: ${(props) => props.theme.negativeColor};
`;

type LogItemProps = UploadLogStatus & {
  positive?: boolean;
};

export const LogItem: React.FC<LogItemProps> = (props) => {
  const Text = props.positive ? SuccessText : FailedText;
  return (
    <Text>
      <LogContainer>
        <LogText>{props.name}</LogText>
        <LogDetail>{props.message}</LogDetail>
      </LogContainer>
    </Text>
  );
};
