import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import moment from "moment";
//Libs
//Components
import SyncIcon from "@material-ui/icons/Sync";
import AlbumIcon from "@material-ui/icons/Album";
import { ASModal, ASIconButton, ASButton } from "allegro-ui";
import { XROptXBrowser } from "src/xerver-room/components";
import scienceFolder from "../../recoils/ScienceFolderState";
import { useCodeBoxMediator } from "src/opt-editor/hooks/useCodeBoxMediator";
import { useOptX } from "src/xerver-room/hooks";

//States
//Types

const ButtonGroup = styled.div`
  margin-top: 23px;
  text-align: right;
  padding-bottom: 24px;
  > * {
    margin-right: 12px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  > *:last-child {
    margin-left: auto;
  }
`;

const HistoryBlock = styled.div`
  border-top: 1px solid ${(props) => props.theme.secondaryBorderColor};
  padding-bottom: 16px;
  padding-top: 16px;
  display: flex;
  > *:last-child {
    margin-left: auto;
  }
`;

const BuildDate = styled.div`
  margin-top: 8px;
  opacity: 0.5;
  font-size: 12px;
`;

type Props = {
  onSelect: (optXId: string) => void;
};
export const OEOptXSelectButton: React.FC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const cursorFolderId = useRecoilValue(scienceFolder.cursorItemIdState);
  const cursorCodeBox = useRecoilValue(scienceFolder.item(cursorFolderId));
  const { updateCodeBox } = useCodeBoxMediator();
  const [selectedOptXId, setSelectedOptXId] = useState("");
  const optX = useOptX(cursorCodeBox.contents?.optXId);
  const [name, setName] = useState("");
  const [buildDate, setBuildDate] = useState("");
  const [buildStatus, setBuildStatus] = useState("");

  const onSubmit = () => {
    if (selectedOptXId)
      updateCodeBox({
        contents: {
          shouldInstant: true,
          xerverId: "",
          optXId: selectedOptXId,
        },
      });
    setShowModal(false);
  };

  const reloadHistory = () => {
    optX.refresh();
  };

  useEffect(() => {
    if (optX && optX.name) {
      setName(optX.name);
    }
    if (optX && optX.buildHistory && optX.buildHistory.length > 0) {
      const lastHistory: any = optX.buildHistory[0];
      setBuildDate(moment(lastHistory.time).calendar());
      setBuildStatus(lastHistory.message);
    }
  }, [optX]);

  return (
    <>
      <Container>
        <span>{name}</span>
        {/* <div>{optX.buildHistory ? optX.buildHistory[0] : <>No build</>}</div> */}
        <ASIconButton onClick={() => setShowModal(true)} size="small">
          <AlbumIcon />
        </ASIconButton>
      </Container>
      <HistoryBlock>
        <div>
          {buildStatus}
          <BuildDate>{buildDate}</BuildDate>
        </div>

        <ASIconButton
          kind="secondary"
          onClick={() => reloadHistory()}
          size="small"
        >
          <SyncIcon />
        </ASIconButton>
      </HistoryBlock>
      <ASModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <div>
          <XROptXBrowser
            selectable
            maxSelectable={1}
            onChangeSelections={(ids) => {
              setSelectedOptXId(ids[0]);
            }}
          />

          <ButtonGroup>
            <ASButton kind="secondary" onClick={() => setShowModal(false)}>
              CANCEL
            </ASButton>
            <ASButton kind="primary" onClick={() => onSubmit()}>
              CONFIRM
            </ASButton>
          </ButtonGroup>
        </div>
      </ASModal>
    </>
  );
};
