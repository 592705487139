import produce from "immer";

export type UserAuthPath = {
  home: string;
  login: string;
  signUp: string;
  confirmEmail: string;
  provisionEmail: string;
  forgotPassword: string;
  rescuePassword: string;
  terms: string;
  policy: string;
};

export const defaultUserAuthPath: UserAuthPath = {
  home: "/",
  login: "/login",
  signUp: "/signup",
  forgotPassword: "/prepare-reset-password",
  rescuePassword: "/password/rescue",
  confirmEmail: "/confirm-signup",
  provisionEmail: "/provision-signup",
  terms: "/",
  policy: "/",
};

export const createUserAuthPath = (
  data: Partial<UserAuthPath>
): UserAuthPath => {
  return produce(defaultUserAuthPath, (draft) => {
    draft.home = data.home ?? draft.home;
    draft.login = data.login ?? draft.login;
    draft.signUp = data.signUp ?? draft.signUp;
    draft.confirmEmail = data.confirmEmail ?? draft.confirmEmail;
    draft.provisionEmail = data.provisionEmail ?? draft.provisionEmail;
    draft.forgotPassword = data.forgotPassword ?? draft.forgotPassword;
    draft.rescuePassword = data.rescuePassword ?? draft.rescuePassword;
    draft.terms = data.terms ?? draft.terms;
    draft.policy = data.policy ?? draft.policy;
  });
};
