import { ASButton, ASIconButton, ASModal } from "allegro-ui";
import * as React from "react";
import { useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import * as api from "src/medican/api";
import styled from "styled-components";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { medicanAPI } from "src/medican/api/medicanAPI";
import dokodemoFAPI from "src/dfurusato-pf/api";
import { deleteDataOne } from "../../utils/deleteData";
import {
  NegativeLog,
  PositiveLog,
} from "src/newtroid-console/components/molecules/NTXerviceImportModalContent/styles";

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  width: 300px;
  height: 200px;
  > p {
    font-size: ${(props) => props.theme.regularFontSize}px;
    line-height: 1.4;
    width: 100%;
    word-break: break-all;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  > * {
    margin-right: 8px;
  }
`;

const ButtonGroup = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  > * {
    margin-left: 8px;
  }
`;

const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  color: ${(props) => props.theme.negativeColor};
`;

const StyledIconButton = styled(ASIconButton)`
  &:disabled {
  }
`;

interface ILCDeleteButtonComponentProps {
  data: {
    value: any;
    name: string;
    documentId: string;
    assetId?: string;
    metricId?: string;
    serverId?: string;
    serverVariableId?: string;
    answerId?: string;
    projectId?: string;
    params?: any;
  };
  options?: {
    kind?: "medican" | "dokodemof";
    disabled?: boolean;
    onSubmit: (value: any) => void;
  };
}

const LCDeleteButtonComponent: React.FunctionComponent<ILCDeleteButtonComponentProps> =
  (props) => {
    const [open, setOpen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const [negativeMessage, setNegativeMessage] = useState("");
    const [positiveMessage, setPositiveMessage] = useState("");

    const handleDelete = React.useCallback(async () => {
      let table = "TopData";

      if (props.options?.onSubmit && props.data.value) {
        await props.options.onSubmit(props.data.value);
      } else if (props.data.assetId) {
        table = "machine";
        const [ok, errorMessage] = await deleteDataOne(
          "asset",
          props.data.assetId
        );
        if (ok) {
          setPositiveMessage("success");
          setIsSuccess(true);
        } else {
          setNegativeMessage(errorMessage);
        }
        return;
      } else if (props.data.metricId) {
        table = "metric";
      } else if (props.data.serverId || props.data.serverVariableId) {
        table = "TopData";
      } else if (props.data.answerId && props.data.projectId) {
        switch (props.options?.kind ?? "") {
          case "medican":
            const delelteRes = await medicanAPI.deleteAnswer(
              props.data.answerId,
              props.data.projectId,
              sessionStorage.contractorNo
            );
            return delelteRes
              ? window.location.reload()
              : alert(
                  "削除に失敗しました。もう一度お試しいただくか管理者にお問い合わせください。"
                );
          case "dokodemof":
            const params = props.data.params;
            const delRes = await dokodemoFAPI.data.deleteOne(
              props.data.projectId,
              props.data.answerId,
              params
            );
            return delRes
              ? window.location.reload()
              : alert(
                  "削除に失敗しました。もう一度お試しいただくか管理者にお問い合わせください。"
                );
        }
      }

      try {
        const [ok, errMsg] = await api.dataRemove(table, [
          props.data.documentId,
        ]);

        ok
          ? window.location.reload()
          : alert(
              "削除に失敗しました。もう一度お試しいただくか管理者にお問い合わせください。"
            );
      } catch (err) {
        console.error(err);
      }
    }, [
      props.data.answerId,
      props.data.assetId,
      props.data.documentId,
      props.data.metricId,
      props.data.params,
      props.data.projectId,
      props.data.serverId,
      props.data.serverVariableId,
      props.data.value,
      props.options,
    ]);

    React.useEffect(() => {
      if (!open && positiveMessage) {
        window.location.reload();
      }
    }, [open, positiveMessage]);

    React.useEffect(() => {
      return () => {
        setIsSuccess(false);
        setPositiveMessage("");
        setNegativeMessage("");
      };
    }, []);

    return (
      <>
        <StyledIconButton
          size="small"
          kind="secondary"
          onClick={() => {
            setOpen(true);
          }}
          disabled={props.options?.disabled}
        >
          <DeleteIcon />
        </StyledIconButton>

        <ASModal open={open} onClose={() => setOpen(false)}>
          <ModalContent>
            <ModalHeader>
              <StyledErrorOutlineIcon fontSize="medium" />
              <p>Do you want to delete it ?</p>
            </ModalHeader>
            <p>{props.data.name}</p>

            <PositiveLog>{positiveMessage}</PositiveLog>
            <NegativeLog>{negativeMessage}</NegativeLog>

            {isSuccess ? (
              <ButtonGroup>
                <ASButton kind="secondary" onClick={() => setOpen(false)}>
                  Close
                </ASButton>
              </ButtonGroup>
            ) : (
              <ButtonGroup>
                <ASButton kind="secondary" onClick={() => setOpen(false)}>
                  Cancel
                </ASButton>
                <ASButton kind="primary" onClick={handleDelete}>
                  Delete
                </ASButton>
              </ButtonGroup>
            )}
          </ModalContent>
        </ASModal>
      </>
    );
  };

export default LCDeleteButtonComponent;
