import { AxiosInstance } from "axios";

type Args = {
  contractor: string;
  email: string;
  customResetPasswordUrl?: string;
};

export const rescuePassword = async (instance: AxiosInstance, props: Args) => {
  const res = await instance({
    method: "POST",
    url: "/password",
    params: props,
  });

  return res;
};

type ConfirmRescuePasswordProps = {
  resetToken: string;
  password: string;
};

export const confirmRescuePassword = async (
  instance: AxiosInstance,
  props: ConfirmRescuePasswordProps
) => {
  const res = await instance({
    method: "PUT",
    url: "/password",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      resetToken: props.resetToken,
      newPassword: props.password,
    },
  });

  return res;
};
