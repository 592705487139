//Libs
import React from "react";
import styled from "styled-components";

//Components
import { NZGlobalNav } from "../organisms/NZGlobalNav";
import { PTSettingNav } from "src/portal/components/templates/PTSettingNav";
//States
//Types

const CenterContents = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  max-width: 1400px;
`;

type Props = {};

export const NZSettingPageTemplate: React.FC<Props> = ({ children }) => {
  return (
    <>
      <NZGlobalNav />
      <CenterContents>
        <PTSettingNav>{children}</PTSettingNav>
      </CenterContents>
    </>
  );
};
