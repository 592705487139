import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { ASIconButton } from "allegro-ui";
import React from "react";
import styled from "styled-components";
import { CheckBlock } from "../atoms";

type Props = {
  data: any;
};

const Question = styled.div`
  margin-top: 16px;
  /* margin-bottom: 30px; */
  font-size: 18px;
  font-weight: bold;
`;

const Flex = styled.div`
  display: flex;
`;

//アセスメント詳細画面の、一つの質問群を表すコンポーネント

export const CheckShowTable: React.FC<Props> = (props) => {
  const { data } = props;

  return (
    <>
      {Object.keys(data).map((d, id) => {
        return (
          <div key={String(id)}>
            <Flex>
              <Question>{d}</Question>
            </Flex>

            <CheckBlock data={data[d]}></CheckBlock>
          </div>
        );
      })}
    </>
  );
};
