import { produce } from "immer";
import { v4 as uuidv4 } from "uuid";

export const MONITOR_ASSET_TAGS = ["monitor", "kinzaza", "DataViewMonitors"];

export type SchemaType =
  | "RawData"
  | "TopData"
  | "TxnData"
  | "HistoryData"
  | null;

export function isSchemaType(str: any): str is SchemaType {
  return (
    str === "RawData" ||
    str === "TopData" ||
    str === "TxnData" ||
    str === "HistoryData"
  );
}

export type MonitorThumb = {
  id?: string | undefined;
  uuid: string;
  tags: string[];
  name: string;
  query: any;
  totalCount: number;
  monitorTotalCount: number;
  lastCountUpdateDate: string; //iso
  schemaType: SchemaType;
  lastDataDate: string;
  version?: number;
  _created: number;
};

export const defaultMonitorThumb: MonitorThumb = {
  tags: MONITOR_ASSET_TAGS,
  uuid: uuidv4(),
  name: "No Name",
  query: { asset: [], primaryKey: "id", shape: { id: "any" } },
  totalCount: 0,
  lastCountUpdateDate: "none",
  lastDataDate: "none",
  monitorTotalCount: 0,
  schemaType: "RawData",
  _created: -1,
  version: 1,
};

export const createMonitorThumb = (
  props: Partial<MonitorThumb>,
  created?: number,
  count?: number,
  monitorCount?: number
) => {
  return produce(defaultMonitorThumb, (draft) => {
    draft.id = props.id;
    draft.uuid = props.uuid ?? draft.uuid;
    draft.name =
      typeof props.name === "string" && props.name !== ""
        ? props.name
        : draft.name;
    draft.query = props.query ?? draft.query;
    draft.totalCount =
      typeof props.totalCount === "number"
        ? props.totalCount
        : draft.totalCount;
    draft.lastCountUpdateDate =
      typeof props.lastCountUpdateDate === "string"
        ? props.lastCountUpdateDate
        : draft.lastCountUpdateDate;
    draft.lastDataDate = props.lastDataDate ?? "none";
    draft.schemaType = isSchemaType(props.schemaType)
      ? props.schemaType
      : draft.schemaType;
    draft._created = typeof created === "number" ? created : draft._created;
    draft.totalCount =
      Number.isInteger(count) && count ? count : draft.totalCount;
    draft.monitorTotalCount = monitorCount ?? 1;
    draft.version = 2;
  });
};
