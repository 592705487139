import { parseBytesStr, parsePercentageStr } from "../utils/parseStats";

export type ContainerStats = {
  name: string;
  cpuUsage: number;
  memoryUsage: number;
  diskUsage: number;
  created: Date;
};

export const defaultContainerStats: ContainerStats = {
  name: "",
  cpuUsage: 0,
  memoryUsage: 0,
  diskUsage: 0,
  created: new Date(),
};

export const createContainerStatsFromNewtData = (data: any): ContainerStats => {
  const message = data.contents.rawdata.message;
  const name = message.container_name;
  const cpuPercentage = parsePercentageStr(message.container_cpu_percentage);
  const memoryPercentage = parsePercentageStr(message.container_mem_percentage);
  const diskUsage = parseBytesStr(message.container_block_io_kb_sum);
  const created = new Date(message.unixtime);

  return {
    name,
    cpuUsage: cpuPercentage,
    memoryUsage: memoryPercentage,
    diskUsage,
    created,
  };
};
