import { AxiosInstance } from "axios";

type ProfileProps = {
  userRealName?: string;
  email?: string;
  password?: string;
  refreshApikey?: string;
  phoneNumber?: string;
  zip?: string;
  address?: string;
};

export const updateProfile = async (
  instance: AxiosInstance,
  data: ProfileProps
) => {
  const res = await instance({
    method: "put",
    url: `/myaccount`,
    data: data,
  });

  return res;
};

export const updateAvatar = async (instance: AxiosInstance, avatar: any) => {
  const res = await instance({
    method: "put",
    url: `/myaccount`,
    data: {
      avatar: avatar,
    },
  });

  return res;
};
