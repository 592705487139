import { useCallback, useState } from "react";
import { api, url } from "src/api";
import { useAuth } from "src/root";
import { useEffect } from "react";
import { format } from "date-fns";

export const useTotalDataCount = (
  schemaType: string | null,
  query: { where: any } | null
) => {
  const [count, setCount] = useState(0);
  const [lastCreated, setLastCreated] = useState<string | "none">("none");

  const auth = useAuth();

  const schemaMap: { [key: string]: string } = {
    TopData: "topData",
    RawData: "rawData",
    TxnData: "txnData",
  };
  const urlType = schemaMap[schemaType ?? "RawData"];
  const reqUrl = url.dataProvider();

  const contractor: string =
    typeof sessionStorage.contractorNo === "string"
      ? sessionStorage.contractorNo
      : "";
  const userId: string = typeof auth.userId === "string" ? auth.userId : "";

  const init = useCallback(async () => {
    const queryCountObj = {
      shape: {},
      ...query,
    };

    try {
      const countRes = await api({
        method: "POST",
        url: reqUrl,
        params: {
          apikey: sessionStorage.token,
          count: true,
          newtQuery: false,
        },
        data: {
          ...queryCountObj,
        },
      });

      let queryObj = {
        shape: {},
        ...query,
      };
      if ("shape" in queryObj) {
        queryObj.shape = {
          ...queryObj.shape,
          _modified: "any",
        };
      }

      const res = await api({
        method: "POST",
        url: reqUrl,
        params: {
          apikey: sessionStorage.token,
          newtQuery: false,
        },
        data: {
          ...queryObj,
          order: {
            _modified: -1,
          },
        },
      });

      const modified = res.data.message.doc[0]?._modified
        ? format(res.data.message.doc[0]?._modified, "yyyy/MM/dd HH:mm:ss")
        : "none";

      setCount(countRes.data.message.doc[0]?.dataTotalCount ?? 0);
      setLastCreated(modified);
    } catch (err) {
      // TODO クエリがtopdataの物だとエラーが起きる模様
      console.error(err);
    }
  }, [query, reqUrl, contractor, userId]);

  useEffect(() => {
    init();
  }, [init]);

  return [count, lastCreated];
};
