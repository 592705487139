import { CircularProgress } from "@material-ui/core";
import { ASLoadingCircle } from "allegro-ui";
import styled from "styled-components";

type Props = {};

const Container = styled.div`
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  > * {
    margin: 0 auto;
  }
  text-align: center;
  color: ${(props) => props.theme.textColor};
  .MuiCircularProgress-colorPrimary {
    color: ${(props) => props.theme.primaryColor};
  }
`;

export const NZLoadingContainer: React.FC<Props> = (props) => {
  return (
    <Container>
      <CircularProgress />
    </Container>
  );
};
