import { CardContent } from "@material-ui/core";
import favy from "allegro-api";
import { ASCard } from "allegro-ui";
import * as React from "react";
import { Link } from "react-router-dom";
import { PTUserAuthComponent } from "src/portal/components/organisms/PTUserAuthComponent";
import styled from "styled-components";
import { CHUserAuthMainViewTemplate } from "../templates/CHUserAuthMainView";
import { UserAuthPath } from "../models/userAuthRoutes";
import { PTRescuePasswordForm } from "src/portal/components/organisms/PTRescuePasswordForm";
import { useResetPassword } from "src/cheetah/hooks/useResetPassword";
import { LCLoadingBoard } from "../../libs/ASLowCodeBrowser";
import { useSnackbar } from "notistack";

const Container = styled.div`
  > * {
    margin-top: 24px;
  }
  > p {
    line-height: 1.5;
  }
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.primaryColor};
`;

interface IRescuePasswordPageProps {
  logo: React.Component;
  appName: string;
  routes: UserAuthPath;
}

export const CHRescuePasswordPage: React.FunctionComponent<IRescuePasswordPageProps> =
  (props) => {
    const { logo, appName, routes } = props;

    const { isSent, isLoading, resetPassword } = useResetPassword();
    const { enqueueSnackbar } = useSnackbar();

    return (
      <>
        <CHUserAuthMainViewTemplate logo={logo}>
          {isSent ? (
            <PTUserAuthComponent
              formTitle="パスワードの再設定"
              formContent={
                <ASCard>
                  <CardContent>
                    <Container>
                      <p>
                        パスワードを設定しました。
                        <br />
                        <StyledLink to={`${routes.home}`}>
                          ログインへ戻る
                        </StyledLink>
                      </p>
                    </Container>
                  </CardContent>
                </ASCard>
              }
            ></PTUserAuthComponent>
          ) : (
            <PTUserAuthComponent
              formTitle="新しいパスワードの設定"
              formContent={
                <div>
                  {isLoading ? (
                    <LCLoadingBoard />
                  ) : (
                    <PTRescuePasswordForm
                      onSubmit={async (newPassword, passwordConfirm) => {
                        const errMsg = await resetPassword(
                          newPassword,
                          passwordConfirm
                        );

                        if (errMsg) {
                          enqueueSnackbar(`${errMsg}`, {
                            variant: "error",
                          });
                        } else {
                          enqueueSnackbar(`成功しました。`, {
                            variant: "success",
                          });
                        }
                        return errMsg;
                      }}
                    />
                  )}
                </div>
              }
            />
          )}
        </CHUserAuthMainViewTemplate>
      </>
    );
  };
