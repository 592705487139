import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ASButton, ASSelect } from "allegro-ui";
import { useRecoilState, useRecoilValue } from "recoil";
import { OEXerviceSelector } from "../atoms/OEXerviceSelector";
import scienceFolder from "../../recoils/ScienceFolderState";
import { OEOptXSelectButton } from "../atoms/OEOptXSelectButton";
import { OECommitCodeBoxButton } from "../atoms/OECommitCodeBoxButton";
import { useInstantSlot } from "src/opt-editor/hooks/useInstantSlot";
import { OEToggleLogButton } from "../atoms";

const Container = styled.div`
  padding: 24px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

type Props = {};

const Title = styled.h2`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 24px;
`;

const NegativeBadge = styled.div`
  padding: 8px;
  background: ${(props) => props.theme.negativeColor};
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const OEOptXControllWidget: React.FC<Props> = (props) => {
  const currentFolderId = useRecoilValue(scienceFolder.cursorItemIdState);
  const cursorCodeBox = useRecoilValue(scienceFolder.item(currentFolderId));
  const [folderType, setFolderType] = useState("");
  const actions = useInstantSlot();

  // useEffect(() => {
  //   if (currentFolderId >= 0) {
  //     setFolderType(folder.type);
  //   }
  // }, [currentFolderId]);

  const InvalidOptXIndicator = () => (
    <NegativeBadge>
      {/* 設定されたOptXが見つかりません。OptXの参照を設定し直してください。  */}
      ERROR:OptX not found. Please set OptX to get relation with this CodeBox.
    </NegativeBadge>
  );

  const InvalidXerviceIndicator = () => (
    <NegativeBadge>
      {/* 設定されたOptXが見つかりません。OptXの参照を設定し直してください。  */}
      WARNINGN:Server to running space is not specified. Using instant space.
    </NegativeBadge>
  );

  const handleStart = () => {
    actions.requestStart();
  };

  const handleStop = () => {
    actions.requestStop();
  };

  return (
    <Container>
      {cursorCodeBox.type === "CodeBox" && (
        <>
          <Title>Code Runner</Title>

          {cursorCodeBox.contents?.optXId ? null : <InvalidOptXIndicator />}
          {/* {cursorCodeBox.contents?.xerverId ? null : (
            <InvalidXerviceIndicator />
          )} */}

          <FlexContainer>
            {/* <OEXerviceSelector onSelect={() => {}} /> */}
            <OEOptXSelectButton
              onSelect={(optXId) => {
                console.log("select", optXId);
              }}
            />
            <OECommitCodeBoxButton />
            <RowFlexContainer>
              {/* <ASButton
                style={{ flex: 1, margin: "3px" }}
                kind={"primary"}
                disabled
              >
                CONNECT
              </ASButton> */}
              <ASButton
                style={{ flex: 1, margin: "3px" }}
                kind={"secondary"}
                onClick={handleStop}
              >
                STOP
              </ASButton>
              <ASButton
                style={{ flex: 1, margin: "3px" }}
                kind={"primary"}
                onClick={handleStart}
              >
                START
              </ASButton>
            </RowFlexContainer>
            <OEToggleLogButton />
          </FlexContainer>
        </>
      )}
    </Container>
  );
};
