import { useEffect, useState } from "react";
import * as React from "react";
import styled from "styled-components";
import { ASChip } from "allegro-ui";
import { darken, lighten } from "polished";

export const DisabledChip = styled(ASChip)`
  color: #909090;
  background-color: ${(props) =>
    props.theme.darkMode ? darken(0.5, "#555") : lighten(0.52, "#555")};
`;

const FlexBox = styled.div`
  display: flex;
  > * {
    margin-top: 8px;
  }
`;

interface ILCAIResultCellComponentProps {
  data: {
    value?: number;
  };
}

const colors: { [key: string]: any } = {
  問題なし: "positive",
  気になる: "caution",
  虐待あり: "negative",
};

const getColor = (num: number) => {
  if (num > 80) return colors["問題なし"];
  if (num > 0) return colors["気になる"];
  else return colors["虐待あり"];
};

const LCNumberChipCellComponent: React.FC<ILCAIResultCellComponentProps> = (
  props
) => {
  const value1 = props.data.value ?? 0;

  return (
    <>
      <FlexBox>
        {typeof value1 === "number" ? (
          <ASChip
            design="onecolor"
            kind={getColor(value1)}
            label={String(value1)}
            small
          ></ASChip>
        ) : (
          <>-</>
        )}
      </FlexBox>

      {/* <Title>日付</Title>
      <span></span> */}
    </>
  );
};

export default LCNumberChipCellComponent;
