import { BundleRefenrece } from "../../models/Bundle";
import { AxiosInstance } from "axios";

export type BundleInput = {
  bundleId?: string;
  name: string;
  slug: string;
  version: string;
  comment?: string;
  isDefault?: boolean;
  references: BundleRefenrece[];
  tags: string[];
};

export const save = async (
  instance: AxiosInstance,
  data: BundleInput
): Promise<any> => {
  try {
    const res = await instance.post("/bundles", data);
    return res.data;
  } catch (err) {
    console.error(err);
  }
};
