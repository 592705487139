import { ASButton, ASButtonGroup, ASLoadingCircle } from "allegro-ui";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useRecoilValueLoadable } from "recoil";
import { Button } from "@material-ui/core";

import { cursorPipelineState } from "src/xerver-room/recoils";
import yaml from "yaml";
import {
  Container,
  Title,
  ButtonContainer,
  CircleLayout,
  LogArea,
} from "./styles";
import { ManifestV1 } from "src/newtroid-console/models/ManifestV1";
import { useLogArea } from "./hooks/useLogArea";

export interface INTXerviceImportFormStep1Props {
  onCancel: () => void;
  onImport: (data: any) => void;
}

/**
 * Xerviceマニフェストをインポートするためのフォーム
 * フォームの選択をしそのファイルのバリデーションを行います。
 * @param props
 * @returns
 */
export function NTXerviceImportFormStep1(
  props: INTXerviceImportFormStep1Props
) {
  const { handleSubmit } = useForm();

  const { logs, clearLogs, addLogLine } = useLogArea();

  const [isProgress, setIsProgress] = React.useState(false);

  const [canNext, setCanNext] = React.useState(false);

  const cursorPipeline = useRecoilValueLoadable(cursorPipelineState);

  const [manifest, setManifest] = React.useState<ManifestV1>();

  const pipelineName =
    cursorPipeline.state === "hasValue"
      ? cursorPipeline.contents?.name
      : "xerviceManifest";

  const handleFile = (files: FileList | null) => {
    clearLogs();
    if (files && files.length > 1) {
      alert("ファイルは1ファイルずつインポートしてください。");
    } else if (files && files.length === 1) {
      const file = files[0];
      const extension = file.name.split(".").at(-1) ?? "";
      const reader = new FileReader();

      switch (extension) {
        case "json":
          //   const json = await parseJSON(file);
          setIsProgress(true);
          reader.readAsText(file);
          reader.onload = (e: any) => {
            const content = e.target.result;
            console.log("ファイルの内容:", content);

            try {
              // YAMLをJavaScriptのオブジェクトに変換
              const jsonObject = JSON.parse(content);
              console.log("JSONオブジェクト:", jsonObject);
              addLogLine(`選択されたファイル:${file.name}`);
              validManifest(jsonObject);

              setManifest(jsonObject);
              // setYamlData(yamlObject);
            } catch (error) {
              console.error("YAMLの解析に失敗しました:", error);
            }
          };
          break;
        case "yaml":
          setIsProgress(true);
          reader.readAsText(file);
          reader.onload = (e: any) => {
            const content = e.target.result;
            try {
              // YAMLをJavaScriptのオブジェクトに変換
              const yamlObject = yaml.parse(content);
              addLogLine(`選択されたファイル:${file.name}`);
              validManifest(yamlObject);

              setManifest(yamlObject);
              // setYamlData(yamlObject);
            } catch (error) {
              console.error("YAMLの解析に失敗しました:", error);
            }
          };

          break;

        default:
          break;
      }
    }
    setIsProgress(false);
    setCanNext(true);
  };

  const handleImport = (values: any) => {
    console.log(values);

    props.onImport(manifest);
  };

  const validManifest = (manifest: Partial<ManifestV1>) => {
    const { version, xervices } = manifest;
    switch (version) {
      case 1:
        for (const x of xervices) {
          if (!x.ui) {
            addLogLine(
              `WARN: Slot ${x.slotId}にはUI情報がありません。`,
              "warn"
            );
          }

          const optxId = x.optxId;
          try {
            /**
             * Slotに設定されているOptXが利用可能なOptXか検査する。
             */
          } catch (error) {
            console.error(error);
          }
          //   if (x.ip) {
          //     addLogLine(
          //       `WARN: Slot ${x.slotId}のIP ${x.ip}が設定されています。利用可能なIPか確認してください。`
          //     );
          //   }
        }
        break;
      default:
        alert("サポートしてないデータです。");
    }
  };

  return (
    <Container>
      <Title>{pipelineName}のImport</Title>

      <p>構築されたXerviceをインポートします。</p>

      <form onSubmit={handleSubmit((values: any) => handleImport(values))}>
        <input
          accept=".json, .yaml, .yml"
          style={{ display: "none" }}
          id="contained-button-file"
          multiple
          type="file"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setIsProgress(true);
            setCanNext(false);
            handleFile(e.target.files);
          }}
        ></input>
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span">
            Select file
          </Button>
        </label>

        <LogArea>
          {logs}
          <CircleLayout>
            <ASLoadingCircle isLoading={isProgress} />
          </CircleLayout>
        </LogArea>

        <ButtonContainer>
          <ASButtonGroup>
            <ASButton kind="secondary" onClick={() => props.onCancel()}>
              Cancel
            </ASButton>
            <ASButton disabled={!canNext} kind="primary" type="submit">
              Import
            </ASButton>
          </ASButtonGroup>
        </ButtonContainer>
      </form>
    </Container>
  );
}
