import React from "react";

import { Controller, Control, FieldValues } from "react-hook-form";
import { ASRadioForm } from "../../models/ASLowCodeFormField";
import { ASFormLabel, ASErrorField } from "../atoms";
import { ASRadioFormFieldComponent } from "./ASRadioFormFieldComponent";
import { ASRequiredMark } from "../atoms/ASRequiredMark";
import { ASFormFieldBase } from "./ASFormFieldBase";

type ASFormFieldProps = {
  id?: number | string;
  control: Control;
  defaultValue?: string;
  error: any;
  editable?: boolean;
  design?: "digicho" | "default";
  supportText?: string;
  requiredMarker?: string;
  optionalMarker?: string;
};

export const ASRadioFormField = (props: ASFormFieldProps & ASRadioForm) => {
  const {
    id,
    label,
    name,
    required,
    active,
    values,
    control,
    defaultValue,
    error,
    errorText,
    editable,
    design,
    supportText,
  } = props;

  return (
    <div key={`lc-create-data-form-${id}-${name}`}>
      <ASFormFieldBase
        error={error !== undefined}
        errorText={errorText}
        label={label}
        design={design}
        required={required}
        editable={editable}
        supportText={supportText}
        requiredMarker={props.requiredMarker}
        optionalMarker={props.optionalMarker}
      >
        <Controller
          key={`lc-create-radio-form-${id}-${name}-${values}-${defaultValue}`}
          name={name}
          control={control}
          rules={{
            // required: required,
            validate: (data) => {
              if (data === undefined) {
                return errorText;
              }
              return true;
            },
          }}
          defaultValue={defaultValue ?? active}
          render={({ field: { onChange } }) => (
            <>
              <ASRadioFormFieldComponent
                name={name}
                active={defaultValue ?? active}
                values={values}
                editable={editable}
                onChange={(active: string) => {
                  onChange(active);
                }}
              />
            </>
          )}
        />
      </ASFormFieldBase>
    </div>
  );
};
