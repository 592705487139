import { ASLowCodeFormDefinition } from "allegro-low-code-components";
import { fetchUsersByEmail } from "src/portal/api/fetchUser";

export const editPipelineUserPermissionFormDefinition: ASLowCodeFormDefinition =
  {
    sections: [
      {
        fields: [
          {
            label: "Readable Users",
            name: "readableUsers",
            type: "chip",
            values: async (name) => {
              const users = await fetchUsersByEmail(name ?? "");
              return users.map((user) => {
                return {
                  label: user.email,
                  value: user.userNoText,
                };
              });
            },
          },
          {
            label: "Writable Users",
            name: "writableUsers",
            type: "chip",
            values: async (name) => {
              const users = await fetchUsersByEmail(name ?? "");
              return users.map((user) => {
                return {
                  label: user.email,
                  value: user.userNoText,
                };
              });
            },
          },
        ],
      },
    ],
  };
