import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  ASGraph,
  ASGraphProps,
  ASGraphLabel,
  ASGraphLabelProps,
  GraphData,
} from "../atoms";
import styled from "styled-components";

const StyledPaper = styled(Paper)`
  background-color: ${(props) => props.theme.cardColor};
`;

export type ASGraphContainer = {
  graphDatas: GraphData[];
  graphHeight: number;
};

export const ASGraphContainer: React.FC<ASGraphProps> = (props) => {
  const [targetPoint, setTargetPoint] = useState<number>();

  const graphClickCallback = (cursorX: number) => {
    setTargetPoint(cursorX);
  };

  const graphProps: ASGraphProps = {
    graphDatas: props.graphDatas,
    graphHeight: props.graphHeight,
    graphClickCallback: graphClickCallback,
  };

  props.graphDatas.map((graphData) => {
    graphData.color = (() => {
      switch (graphData.id) {
        case 1:
          return "#ee0000";
        case 2:
          return "#9d49ca";
        case 3:
          return "#3e60f3";
        case 4:
          return "#65d154";
        case 5:
          return "#f7c447";
        default:
          return "#606060";
      }
    })();
  });
  return (
    <div>
      <StyledPaper
        elevation={0}
        style={{
          paddingTop: 16,
          paddingRight: 16,
          paddingBottom: 24,
          paddingLeft: 16,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ASGraph {...graphProps} />
          </Grid>
          {props.graphDatas.map((graphData) => {
            const targetPrediction =
              targetPoint !== undefined
                ? targetPoint
                : graphData.dataPoints.length - 1;
            const point = graphData.dataPoints[targetPrediction]?.y ?? 0;
            const labelProps: ASGraphLabelProps = {
              label: graphData.label,
              point: point,
              color: graphData.color,
            };
            return (
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={graphData.id}>
                <ASGraphLabel {...labelProps} />
              </Grid>
            );
          })}
        </Grid>
      </StyledPaper>
    </div>
  );
};
