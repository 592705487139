import { useState } from "react";

import { ASFormLabel, ASInputBase, ASButton } from "allegro-ui";
import styled from "styled-components";

type Props = {
  defaultValue?: string;
  onSubmit?: (text: string) => void;
  disabled?: boolean;
};

const TextContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

export const XRNameSettingForm: React.FC<Props> = (props) => {
  const { defaultValue, disabled, onSubmit } = props;

  const [name, setName] = useState<string | undefined>(defaultValue);

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  return (
    <>
      <ASFormLabel disabled={disabled}>Name</ASFormLabel>
      <TextContainer>
        <ASInputBase
          kind="digicho"
          placeholder="入力してください"
          value={name}
          disabled={disabled}
          onChange={handleNameChange}
        />
      </TextContainer>

      <ASButton
        kind="secondary"
        disabled={disabled}
        onClick={() => {
          if (onSubmit && name) {
            onSubmit(name);
          }
        }}
      >
        APPLY
      </ASButton>
    </>
  );
};
