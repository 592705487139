import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { ASTabs } from "allegro-ui";
import { Tab, Box, Typography } from "@material-ui/core";
import { OELogPlot } from "../molecules";

const FlexContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TabWrapper = styled.div<{ tabHeight: number }>`
  height: ${(params) => params.tabHeight}px;
  overflow: "auto";
`;

const FlexTabPanel = styled.div`
  > * {
    overflow: scroll;
  }
`;

const StyledTabs = styled(ASTabs)`
  height: auto;
  border: 0px none;
  min-height: 32px;
  .MuiTab-root {
    min-height: 32px;
    padding: 5px 12px;
  }
  .MuiTabs-root {
    min-height: 32px;
  }
`;

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <FlexTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </FlexTabPanel>
  );
}

export const OELogAndMetric: React.FC = () => {
  const [value, setValue] = useState(0);
  const [tabHeight, setTabHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const metric = "";

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  // リサイズ時
  useEffect(() => {
    window.addEventListener("resize", () => {
      setTabHeight(0);
    });
  }, []);
  // リサイズ時に上記のuseEffect実行後、refがリサイズするのでそれに伴ってタブの高さを再設定。（初期化も伴っている。）
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (ref.current !== null) setTabHeight(ref.current.clientHeight);
    });
    if (ref.current !== null) resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <FlexContainer>
      <StyledTabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
      >
        <Tab label="SYSTEM LOG" {...a11yProps(0)} />
        <Tab label="METRIC" {...a11yProps(1)} />
      </StyledTabs>
      <div style={{ flex: 1 }}>
        <div style={{ height: "100%", overflow: "auto" }} ref={ref}>
          <TabWrapper tabHeight={tabHeight}>
            <TabPanel value={value} index={0}>
              <OELogPlot />
            </TabPanel>
          </TabWrapper>
        </div>
      </div>
      <TabPanel value={value} index={1}>
        {metric}
      </TabPanel>
    </FlexContainer>
  );
};
