import styled from "styled-components";
import { Chip } from "@material-ui/core";

const StyledChip = styled(Chip)`
  color: white;
  background: #ef454a;
  border-radius: 5px;
  width: 78px;
`;

export const OECommitChip: React.FC = () => {
  return <StyledChip label="COMMIT" />;
};
