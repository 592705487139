import { ASTextFormField, ReferenceData } from "allegro-low-code-components";
import { id } from "date-fns/locale";
import { useForm } from "react-hook-form";
import { Form } from "react-router-dom";
import styled from "styled-components";

import SearchIcon from "@material-ui/icons/Search";
import { ASIconButton } from "allegro-ui";

const InputMetricBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > *:first-child {
    width: 240px;
  }
  > * {
    width: 180px;
    margin-right: 12px;
  }
`;

const StyledIconButton = styled(ASIconButton)`
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  width: 40px;
  height: 40px;
`;

type Props = {
  metrics?: string[];
  onSubmit?: (data: any) => void;
};

export const NZSearchFilter: React.FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  return (
    <>
      <Form>
        <InputMetricBlock>
          <ASTextFormField
            error={""}
            supportText=""
            active={""}
            control={control}
            values={(name?: string): ReferenceData[] => {
              let metrics: string[] = props.metrics ?? [];
              if (name) {
                metrics = metrics.filter((metric) => metric.includes(name));
              }
              console.log({ metrics, name });

              return metrics.map((metric) => {
                return {
                  label: metric,
                  value: metric,
                };
              });
            }}
            name={"metricName"}
            label={""}
            placeholder="metric name"
            editable
            type={"text"}
            inputOnly
            design="digicho"
          />
          <ASTextFormField
            error={""}
            supportText=""
            active={""}
            control={control}
            name={"metricValue"}
            label={""}
            editable
            type={"text"}
            inputOnly
            placeholder="value"
            design="digicho"
          />
          <StyledIconButton
            kind="secondary"
            onClick={handleSubmit((values: any) => {
              console.log(values);
              if (props.onSubmit) {
                props.onSubmit(values);
              }
            })}
          >
            <SearchIcon />
          </StyledIconButton>
        </InputMetricBlock>
      </Form>
    </>
  );
};
