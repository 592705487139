import { Axios, AxiosInstance } from "axios";

export type FetchFilesProps = { contractorNo: string; optXId: string };

export const fetchFiles = async (
  instance: AxiosInstance,
  args: FetchFilesProps
) => {
  const res = await instance({
    method: "get",
    url: `/contractors/${args.contractorNo}/optx/${args.optXId}/file`,
  });

  return res;
};

export const fetchOptXConf = async (
  instance: AxiosInstance,
  args: FetchFilesProps
) => {
  const res = await instance.get(
    `/contractors/${args.contractorNo}/optx/${args.optXId}/file/optx-conf.json`,
    {
      params: {
        apikey: sessionStorage.token,
      },
    }
  );
  if (res.data && res.data.length > 0) {
    return JSON.parse(res.data[0].text);
  } else {
    console.error("optx-conf.json does not exist.");
  }
};
