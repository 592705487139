//Libs
import favy from "allegro-api";
import { useState, useCallback, useEffect } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { api } from "src/api";

//Components
//States

//Types
import { User, createUser } from "src/root/models/user";
import { myProfileState } from "src/root/recoils/userState";
import {
  ScienceFile,
  ScienceFolder,
  ScienceItem,
} from "../models/ScienceFSItem";
import { File } from "../models";

/**
 * @deperecated useProfileListに移行する
 */
export const useItemReaders = () => {
  // const [item, setitem] = useState(item);

  const [userList, setUserList] = useState<User[]>([]);
  const myProfile = useRecoilValueLoadable(myProfileState);

  const init = useCallback(async () => {
    if (myProfile.state === "hasValue" && myProfile.contents) {
      const res = await api({
        method: "GET",
        url: `/nauth/users`,
        params: {
          limit: 500,
          skip: 0,
          withDisabled: false,
        },
      });
      const users: User[] = res.data.map((d: any) => {
        return createUser(d);
      });
      setUserList(users);
    }
  }, [myProfile.contents, myProfile.state]);

  const getReaders = useCallback(
    (item: ScienceItem) => {
      if (item.readers.length === 0) {
        console.error("reader is not set.");
        return { userList, status: "Opened" };
      }
      if (item.readers.includes("*")) {
        return { userList, status: "Opened" };
      }
      const readers = userList.filter((user) => {
        return item.readers.includes(String(user.userNo));
      });
      return { userList: readers, status: "Closed" };
    },
    [userList]
  );

  const getUserById = (userNoText: string): User | null => {
    const filterd = userList.filter((u: User) => {
      return u.userNoText === userNoText;
    });
    if (filterd.length > 0) {
      return filterd[0];
    } else return null;
  };

  useEffect(() => {
    init();
  }, [init]);

  return { userList, getUserById, getReaders };
};
