import produce from "immer";

export type ServerStats = {
  name: string;
  cpuUsage: number;
  memoryUsage: number;
  diskUsage: number;
  created: Date;
};

export const defaultServerStats: ServerStats = {
  name: "",
  cpuUsage: 0,
  memoryUsage: 0,
  diskUsage: 0,
  created: new Date(),
};

export const createServerStats = (data: any) => {
  return produce(defaultServerStats, (draft) => {
    draft.name = data.name ?? draft.name;
    draft.cpuUsage = data.cpuUsage ?? draft.cpuUsage;
    draft.memoryUsage = data.memoryUsage ?? draft.memoryUsage;
    draft.diskUsage = data.diskUsage ?? draft.diskUsage;
    draft.created = data.created ?? draft.created;
  });
};

export const createServerStatsFromNewtData = (data: any) => {
  return produce(defaultServerStats, (draft) => {
    draft.name = data.contents.rawdata.message.serverId ?? draft.name;
    draft.cpuUsage = data.contents.rawdata.message.cpuUsage ?? draft.cpuUsage;
    draft.memoryUsage =
      data.contents.rawdata.message.memoryUsage ?? draft.memoryUsage;

    const diskUsages = data.contents.rawdata.message.diskUsage ?? [];
    const filteredDiskUsage = diskUsages.find((diskUsage: any) => {
      return diskUsage.device === "/";
    });
    draft.diskUsage = filteredDiskUsage.used ?? 0;
    draft.created = data.contents.rawdata.message?.unixtime
      ? new Date(data.contents.rawdata.message.unixtime)
      : draft.created;
  });
};
