import React from "react";
import { useState } from "react";
import {
  XYPlot,
  FlexibleWidthXYPlot,
  LineSeries,
  AreaSeries,
  HorizontalGridLines,
  XAxis,
  YAxis,
  LineSeriesProps,
  LineMarkSeriesPoint,
  RVNearestXEventHandler,
  VerticalGridLines,
  DiscreteColorLegend,
} from "react-vis";
import styled, { useTheme } from "styled-components";

const Title = styled.div`
  /* padding-left: 50px; */
  font-size: ${(props) => props.theme.labelFontSizeMd}px;
  color: ${(props) => props.theme.textColor};
  width: 100%;
  text-align: center;
`;

const StyledDiscreteColorLegend = styled(DiscreteColorLegend)`
  font-size: ${(props) => props.theme.labelFontSizeMd}px;
  color: ${(props) => props.theme.textColor};

  /* 横並びにする */
  display: flex;
  flex-direction: row;

  /* 各アイテム間のスペース */
  & > div {
    margin-right: 10px;
  }
`;

const graphColorPallete = ["orange", "skyblue", "green", "yellow"];

export interface Coordinate {
  x: string | number | Date;
  y: number;
}

export interface GraphData {
  id: number;
  label: string;
  color?: string;
  strokeWidth?: number;
  dataPoints: Coordinate[];
}

export type ASGraphProps = {
  title?: string;
  xType?: "linear" | "time" | "ordinal";
  graphDatas: GraphData[];
  graphHeight: number;
  xsteps?: number;
  ysteps?: number;
  graphClickCallback?: (cursorX: number) => void;
  xLabel?: string;
  yLabel?: string;
  yAxisMargin?: number;
  yAxisFormat?: (tick: number) => string;
  xAxisFormat?: (tick: number | Date | string) => string;
  xTickSize?: number;
  yTickSize?: number;
  disabledXAxis?: boolean;
  disabledYAxis?: boolean;
  disabledLegend?: boolean;
  yRange?: [number | null, number | null];
  xRange?: [Date | number | null, Date | number | null];
};

export const ASGraph: React.FC<ASGraphProps> = (props) => {
  const _onClick = () => {
    if (pointX !== undefined && props.graphClickCallback !== undefined) {
      props.graphClickCallback(pointX);
    }
  };

  const _onLeave = () => {
    setPointX(undefined);
  };

  const _onNearestX: RVNearestXEventHandler<LineMarkSeriesPoint> = (
    data,
    { index }
  ) => {
    if (xType === "time") {
      const date = new Date(data.x).getTime();
      setPointX(date);
    } else {
      setPointX(index);
    }
  };

  const xTickSize = props.xTickSize ?? 10;
  const yTickSize = props.yTickSize ?? 10;
  const yAxisMargin = props.yAxisMargin ?? 50;
  const xType = props.xType ?? "linear";
  const xsteps = props.xsteps;
  const ysteps = props.ysteps;
  const xRange = props.xRange;
  const minY = props.yRange && props.yRange[0] ? props.yRange[0] : 0;
  const maxY =
    props.yRange && props.yRange[1]
      ? props.yRange[1]
      : Math.max(
          ...props.graphDatas
            .map((graphData) =>
              graphData.dataPoints.map((prediction) =>
                Math.ceil(prediction.y * 1.1)
              )
            )
            .flat(),
          1
        );
  const yRange = [minY, maxY];

  const [pointX, setPointX] = useState<number>();
  // const [maxY, setMaxY] = useState<number>(1);
  const theme = useTheme();

  // 現在マウスカーソルを合わせている日付の位置X
  const currentYAxisDatas =
    pointX !== undefined
      ? [
          {
            // x: props.graphDatas[0].predictions[pointX].date,
            x: pointX,
            y: 0,
          },
          {
            // x: props.graphDatas[0].predictions[pointX].date,
            x: pointX,
            y: maxY,
          },
        ]
      : undefined;

  return (
    <div key={`as-graph-${Math.random()}`}>
      <Title>{props.title}</Title>
      <FlexibleWidthXYPlot
        key={`as-graph-${Math.random()}`}
        margin={{ left: yAxisMargin }}
        xDomain={xRange}
        yDomain={yRange}
        xType={xType}
        height={props.graphHeight}
        onClick={_onClick}
        onMouseLeave={_onLeave}
        style={{
          fontSize: "12px",
          fill: "#aaa",
        }}
      >
        {!props.disabledXAxis ? (
          <XAxis
            tickSize={xTickSize}
            tickTotal={xsteps}
            tickValues={xsteps === 2 ? xRange : undefined}
            title={props.xLabel}
            tickFormat={props.xAxisFormat}
            style={{ fontSize: `${xTickSize}px` }}
          />
        ) : null}

        {!props.disabledYAxis ? (
          <YAxis
            title={props.yLabel}
            tickSize={yTickSize}
            tickTotal={ysteps}
            tickValues={ysteps === 2 ? yRange : undefined}
            tickFormat={props.yAxisFormat}
            style={{ fontSize: `${yTickSize}px` }}
          />
        ) : null}

        <HorizontalGridLines style={{ stroke: theme.secondaryBorderColor }} />
        <VerticalGridLines style={{ stroke: theme.secondaryBorderColor }} />

        <LineSeries
          key={`${Math.random()}`}
          data={currentYAxisDatas as any[]}
        />
        {props.graphDatas.map((graphData, index) => {
          const points: any[] = graphData.dataPoints.map((prediction, idx) => {
            const x = xType === "linear" ? idx : prediction.x;
            return {
              // x: prediction.x,
              x: x,
              y: prediction.y,
            };
          });

          const color =
            graphData.color === "primary"
              ? theme?.primaryColor
              : graphData.color ??
                graphColorPallete[index % graphColorPallete.length];

          // 総合リスクの場合は太線にする
          const strokeWidth = graphData.strokeWidth ?? 1;
          //TODO lintにsizeが怒られている。
          return (
            <LineSeries
              onNearestX={_onNearestX}
              color={color}
              curve={"curveMonotoneX"}
              style={{
                strokeLinejoin: "round",
                strokeWidth: strokeWidth,
                fill: "none",
              }}
              data={points}
              key={`${graphData.label}-${graphData.id}-${Math.random()}`}
            />
          );
        })}
      </FlexibleWidthXYPlot>
      {!props.disabledLegend ? (
        <StyledDiscreteColorLegend
          items={props.graphDatas.map((graphData, index) => {
            const color =
              graphData.color === "primary"
                ? theme?.primaryColor
                : graphData.color ??
                  graphColorPallete[index % graphColorPallete.length];

            return {
              title: graphData.label,
              color: color,
            };
          })}
          orientation="horizontal"
        />
      ) : null}
    </div>
  );
};
