import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";

export const metricBroweserUi: ASBrowserUiConf = {
  newtQ: {
    collection: "Metric",
    limit: 10,
    primaryKey: "value",
    match: {
      //#TODO metricは論理削除ではない。
      // enable: true,
    },
    shape: {
      tags: "any",
      createdBy: "any",
      value: "any",
      name: "any",
      means: "any",
      shareLevel: "any",
      dataType: "any",
      version: "any",
      serialNo: "any",
      keywords: "any",
      referableContractorNos: "any",
      _id: "any",
    },
  },
  filters: [],
  actions: [
    {
      label: "Create",
      type: "create",
      onValid: (role: any) => {
        try {
          if (
            role.permissions &&
            role.permissions.includes("newtzero.metric.createInContractor")
          )
            return true;
          else return false;
        } catch (err) {
          console.error(err);
          return false;
        }
      },
    },
    {
      label: "Upload",
      type: "metricUpload",
      onValid: (role: any) => {
        try {
          if (
            role.permissions &&
            role.permissions.includes("newtzero.metric.createInContractor")
          )
            return true;
          else return false;
        } catch (err) {
          console.error(err);
          return false;
        }
      },
    },
  ],
  verticalFilters: [
    {
      label: "Search",
      design: "search",
      selections: [],
      callback: (value: string) => {
        const contractor = sessionStorage.contractorNo;
        const meansWithContractor = "means." + contractor;
        const tagsWithContractor = "tags." + contractor;
        return {
          $or: [
            {
              [meansWithContractor]: {
                $regex: value,
                $options: "i",
              },
            },
            {
              [tagsWithContractor]: {
                $regex: value,
                $options: "i",
              },
            },
            {
              value: {
                $regex: value,
                $options: "i",
              },
            },
            {
              name: {
                $regex: value,
                $options: "i",
              },
            },
          ],
        };
      },
    },
  ],
  tables: [
    {
      label: "Metric name",
      component: "LCTextLinkCellComponent",
      data: {
        label: "value",
        id: "_id",
      },
      statics: {
        path: "/dataops/metrics/{id}",
      },
      options: {
        editable: false,
      },
    },

    {
      label: "Data Type",
      component: "LCSimpleColComponent",
      data: { value: "dataType" },
      options: {},
    },

    {
      label: "Means",
      component: "LCSimpleColComponent",
      data: (data) => {
        return { value: data.means[sessionStorage.contractorNo] };
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },

    {
      label: "Share",
      component: "LCSimpleColComponent",
      data: {
        value: "shareLevel",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },

    {
      label: "Tags",
      component: "LCSimpleColComponent",
      data: (data: any) => {
        const tags = data.tags[sessionStorage.contractorNo];
        return {
          value: tags,
        };
      },
      options: {
        editable: false,
      },
    },

    {
      label: "",
      component: "LCDeleteButtonComponent",
      data: {
        name: "value",
        documentId: "_id",
        metricId: "_id",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },
  ],
  pager: { perPage: 50 },
};
