import React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import styled, { DefaultTheme } from "styled-components";

export type ASTextFieldProps = TextFieldProps & {
  theme?: DefaultTheme;
  primary?: boolean;
  label?: string;
  kind?: "primary" | "secondary";
  data?: Array<{
    value: string;
    label: string;
  }>;
  onChange?: (value: string) => void;
};

export const ASTextField = styled(TextField).attrs<ASTextFieldProps>(
  (props) => {
    const fillColor = (() => {
      switch (props.kind) {
        case "primary":
          return props.theme?.primaryColor;
        case "secondary":
          return props.theme?.secondaryColor;
        default:
          return props.theme?.primaryColor;
      }
    })();

    return { fillColor };
  }
)<ASTextFieldProps & { fillColor?: string }>`
  box-shadow: none;
  background-color: ${(props) => props.fillColor};
  border-radius: 15px;
  width: 80px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
  &:hover {
    background-color: ${(props) => props.fillColor};
    filter: brightness(0.9);
  }
`;
