import { selector, atom, atomFamily, DefaultValue } from "recoil";
import { createnXtalAPI } from "src/api";
import { createOptX, OptX } from "../models";
import { userState } from "src/root/recoils/userState";

export const optxFetchCountOfARequest = 40;

export const cursorOptXIdState = atom<string | undefined>({
  key: "CursorOptXId",
  default: undefined,
});

export const optXSearchWordState = atom<string | undefined>({
  key: "optXSearchWordState",
  default: "",
});

export const optXListPageState = atom<number>({
  key: "optXListPageState",
  default: 1,
});

export const optXState = atomFamily<OptX | null, string>({
  key: "optXState",
  default: null,
});

export const optXCountState = atom<number>({
  key: "OptXCountState",
  default: 0,
});

export const optXIdListState = atom<string[]>({
  key: "OptXIdListState",
  default: [],
});

export const requestOptXListIdState = atom<number>({
  key: "requestOptXListIdState",
  default: 0,
});

export const optXListState = selector<[Array<OptX>, number]>({
  key: "OptXListState",
  get: async ({ get }) => {
    const auth = get(userState);
    const searchWord = get(optXSearchWordState);
    const page = get(optXListPageState);
    get(requestOptXListIdState);
    const contractor: string =
      typeof auth.contractorNo === "string" ? auth.contractorNo : "";

    let q = {};
    if (searchWord && searchWord !== "") {
      q = {
        where: {
          name: { $regex: searchWord, $options: "i" },
        },
      };
    }
    const nxtal = createnXtalAPI();
    const res = await nxtal.optx.getMany(
      contractor,
      {
        ...q,
        limit: optxFetchCountOfARequest,
        skip: optxFetchCountOfARequest * (page - 1),
      },
      { count: true }
    );

    const count = Number(res?.headers["x-total-count"]) ?? 0;

    const nextList =
      res?.data.map((data: any) => {
        return createOptX(data);
      }) ?? [];
    return [nextList, count];
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      // キャッシュクリア用のお約束
      return newValue;
    } else {
      // atomにキャッシュとして保存する
      const [newOptXList, count] = newValue;
      for (const value of newOptXList) {
        const id = value.optXId;
        set(optXState(id), value);
      }

      const idList = newOptXList.map((value) => {
        return value.optXId;
      });

      set(optXCountState, count);
      set(optXIdListState, idList);
    }
  },
});

export const optXListSelector = selector<Array<OptX>>({
  key: "optXListSelector",
  get: ({ get }) => {
    const optXIdList = get(optXIdListState);
    return optXIdList
      .map((optXId) => {
        return get(optXState(optXId));
      })
      .filter((optx) => optx) as OptX[];
  },
});
