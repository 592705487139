//
// third party libs
//
import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
//
//components
//
import { ASCard, ASIconButton, ASModal } from "allegro-ui";
import { NZSchemaTypeChip } from "src/newtzero-console/components/atoms/NZSchemaTypeChip";
import { MonitorThumb } from "src/kinzaza/models";
import { CardContent, Menu, MenuItem } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ReactJson from "react-json-view";

//
//our libs
//
import { useDeleteMonitor, useTotalDataCount } from "src/kinzaza/hooks";
import { queryUtils } from "src/kinzaza/utils/queryUtils";
import {
  DeleteConfirmModalContents,
  DuplicateMonitorModalContents,
} from "../../../kinzaza/components";
import * as api from "src/kinzaza/api";
import { monitorListRequestId } from "src/kinzaza/recoils";
import { useRecoilState } from "recoil";

const StyledCard = styled(CardContent)`
  height: 380px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  text-decoration: none;
`;

const CardHead = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
    text-decoration: none;
    color: #ffffff;
    :hover {
      color: #afafaf;
      transition: all 0.2s 0s ease;
    }
  }
`;

const Caption = styled.div`
  margin-top: 8px;
  font-size: 13px;
  color: #8d8d8d;
`;

const DataCount = styled.span`
  font-size: 32px;
  font-weight: bold;
`;

const CardMid = styled.div`
  font-size: 14px;
  margin: 16px 0;
  overflow-y: scroll;
`;

const CardBottom = styled.div`
  display: flex;
  justify-content: space-between;
`;

type Props = {
  url: string;
  monitor: MonitorThumb;
  theme?: DefaultTheme;
};
export const KZMonitorCard: React.FC<Props> = (props) => {
  const { monitor } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const history = useNavigate();
  const { handleDelete } = useDeleteMonitor();

  const [showModal, setShowModal] = React.useState(false);
  const [ModalContents, setModalContents] = React.useState(<></>);
  const [req, setReq] = useRecoilState(monitorListRequestId);

  const queryObj = queryUtils.toObject(monitor.query);

  const [count, lastCreated] = useTotalDataCount(
    monitor.schemaType,
    queryObj ?? null
  );

  const handleMenuOpen = (event: React.ChangeEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteModal = (monitor: MonitorThumb) => {
    setModalContents(
      <DeleteConfirmModalContents
        onSubmit={() => {
          handleDelete(monitor);
        }}
        onCancel={() => {
          setShowModal(false);
          handleMenuClose();
        }}
      />
    );
    setShowModal(true);
    console.log(monitor);
    console.log(ModalContents);
    console.log(showModal);
  };

  const handleDuplicateModal = (monitor: MonitorThumb) => {
    setModalContents(
      <DuplicateMonitorModalContents
        onSubmit={async () => {
          console.log(monitor);
          const resData = await api.duplicateMonitor(monitor);
          if (resData.status === "success") {
            setShowModal(false);
            setReq(req + 1);
            handleMenuClose();
          }
        }}
        onCancel={() => {
          setShowModal(false);
          handleMenuClose();
        }}
      />
    );
    setShowModal(true);
    console.log(monitor);
  };

  return (
    <>
      <ASCard corner={22}>
        <StyledCard>
          <CardHead>
            <div>
              <Title
                onClick={() => {
                  history(`/monitor/${monitor.id}`);
                }}
              >
                <h1>{monitor.name}</h1>
              </Title>
              <Caption>
                <span>{lastCreated}</span>
              </Caption>
            </div>
            <div>
              <ASIconButton kind="secondary" onClick={handleMenuOpen}>
                <MoreHorizIcon />
              </ASIconButton>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem onClick={() => handleDuplicateModal(monitor)}>
                  Duplicate
                </MenuItem>
                <MenuItem
                  onClick={() => handleDeleteModal(monitor)}
                  style={{ color: "red" }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </div>
          </CardHead>

          <CardMid>
            <ReactJson
              name={false}
              displayDataTypes={false}
              displayObjectSize={false}
              src={queryObj ?? {}}
              theme={{
                base00: "#2A2B2D",
                base01: "#505050",
                base02: "#505050",
                base03: "#505050",
                base04: "#37507e",
                base05: "#cfcfcf",
                base06: "#cfcfcf",
                base07: "#cfcfcf",
                base08: "#cfcfcf",
                base09: props.theme?.primaryColor ?? "#1ED760",
                base0A: props.theme?.primaryColor ?? "#1ED760",
                base0B: props.theme?.primaryColor ?? "#1ED760",
                base0C: props.theme?.primaryColor ?? "#1ED760",
                base0D: props.theme?.primaryColor ?? "#1ED760",
                base0E: props.theme?.primaryColor ?? "#1ED760",
                base0F: props.theme?.primaryColor ?? "#1ED760",
              }}
              enableClipboard={false}
              // displayArrayKey={false}
              // typeScript に対応してない https://github.com/mac-s-g/react-json-view/pull/348
            />
          </CardMid>
          <CardBottom>
            <NZSchemaTypeChip>{monitor.schemaType}</NZSchemaTypeChip>
            <div>
              <DataCount>
                {typeof count === "number" ? count.toLocaleString() : "--"}
              </DataCount>
            </div>
          </CardBottom>
        </StyledCard>
      </ASCard>
      <ASModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        {ModalContents}
      </ASModal>
    </>
  );
};
