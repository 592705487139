import React, { useState } from "react";
import styled from "styled-components";
import { ASButton, ASInputBase } from "allegro-ui";
import { useOptXActions } from "src/xerver-room/hooks";
import { ASFormLabel } from "allegro-low-code-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 270px;
  width: 450px;
  padding: 24px;
  h1 {
    font-size: 24px;
    font-weight: bold;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;

const FormInput = styled.div``;

const StyledFormLabel = styled(ASFormLabel)`
  margin-bottom: 8px;
`;

type Props = {};

export const XRCreateOptXForm: React.FC<Props> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [optxName, setOptXName] = useState("");

  const { add } = useOptXActions();
  return (
    <Container>
      <FormInput>
        <StyledFormLabel>OptX Name</StyledFormLabel>
        <ASInputBase
          fullWidth
          kind="digicho"
          onChange={(e) => {
            setOptXName(e.target.value);
          }}
        ></ASInputBase>
      </FormInput>

      <ButtonGroup>
        <ASButton
          kind={"primary"}
          onClick={async () => {
            await add(optxName);
          }}
        >
          Create
        </ASButton>
      </ButtonGroup>
    </Container>
  );
};
