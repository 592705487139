import { ASLowCodeFormDefinition } from "allegro-low-code-components";
import { api, bundles } from "src/api";
import { nAITemplate } from "src/nai-console/models";

export const createNAIFormUi: ASLowCodeFormDefinition = {
  sections: [
    {
      title: "",
      description: "",
      fields: [
        {
          label: "AI Name",
          name: "name",
          type: "text",
          required: true,
          errorText: "nameは必須です。",
        },

        {
          label: "slug",
          name: "slug",
          type: "text",
          required: true,
          errorText: "slugは必須です。",
        },
        {
          label: "Version",
          name: "version",
          type: "text",
          required: true,
          active: "latest",
          errorText: "versionは必須です。",
        },
        {
          label: "Tags",
          name: "tags",
          type: "chip",
          required: false,
        },
      ],
    },
  ],
  onSubmit: async (data, options) => {
    const bundleInput = nAITemplate(data);
    const res = await bundles.save(bundleInput);
    console.log({ res });
    if (res.status === "success") {
      console.log("ok");
      window.location.reload();
    }
  },
};
