import React, { useEffect } from "react";
import { ASButton } from "allegro-ui";
import styled from "styled-components";
import { FTAssesmentList } from "../molecules";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { cursorChildIdState } from "src/fortress/recoils/singleChildPageState";
import { useRiskAssesmentList } from "src/fortress/hooks/useRiskAssessmentList";
import { useParams } from "react-router-dom";

const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const Right = styled.div`
  margin-left: auto;
`;

const Title = styled.p`
  font-size: 23px;
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
`;

type Props = {};

//児童一覧のアセスメント一覧画面のコンポーネントを表している。

export const FTAssesmentPanel: React.FC<Props> = () => {
  const history = useNavigate();
  const [cursorChildId, setCurosrChildId] = useRecoilState(cursorChildIdState);
  const { assessmentList } = useRiskAssesmentList();
  const params = useParams();

  //新規アセスメント作成の画面に飛ぶ処理
  const handleAdd = () => {
    history(`/child/${cursorChildId}/addassessment`);
  };

  //urlから、その児童の宛名番号を取得する処理をしている。

  useEffect(() => {
    if (params.atena) setCurosrChildId(params.atena);
  }, [params.atena, setCurosrChildId]);

  return (
    <div>
      <Header>
        <Title>リスクアセスメント一覧</Title>
        <Right>
          <ASButton kind="primary" onClick={handleAdd}>
            新規作成する
          </ASButton>
        </Right>
      </Header>
      <FTAssesmentList assessmentList={assessmentList} />
    </div>
  );
};
