//Libs
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
//Components
import {
  MDGlobalNavDesktopItem,
  Nav,
} from "src/medican/components/organisms/MDGrobalNavDesktop";
import { SettingNavConf } from "src/cheetah/models/SettingNavConf";

//States
//Types

const CenterContents = styled.div`
  /* display: flex; */
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  max-width: 1400px;
`;

const LeftMenu = styled.div`
  color: ${(props) => (props.theme.darkMode ? "white" : "#222")};
  background: ${(props) => props.theme.bgPrimaryColor};
  max-width: 350px;
`;

const MenuGroup = styled.ul`
  > * {
    margin-bottom: 10px;
  }
  margin-bottom: 24px;
  border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};
`;

const MenuHeader = styled.h2`
  font-size: 12px;
  opacity: 0.6;
  margin-left: 8px;
`;

type Props = {
  conf: SettingNavConf;
};

export const CHSettingNav: React.FC<Props> = (props) => {
  const { conf } = props;

  return (
    <CenterContents>
      <LeftMenu>
        {conf.sections.map((section, id) => {
          return (
            <MenuGroup key={`ch-setting-nav-section-${section.title}-${id}`}>
              <MenuHeader>{section.title}</MenuHeader>
              <Nav>
                {section.navigations.map((nav, idx) => {
                  return (
                    <MDGlobalNavDesktopItem
                      key={`ch-setting-nav-item-${nav.title}-${idx}`}
                      title={nav.title}
                      link={nav.link}
                      icons={nav.icon}
                      isRoot={nav.isRoot}
                    />
                  );
                })}
              </Nav>
            </MenuGroup>
          );
        })}
      </LeftMenu>
    </CenterContents>
  );
};
