import React from "react";
import { NTSettingsPageTemplate } from "../templates/NTSettingsPageTemplate";

//Components
import { PTSettingTeamTemplate } from "src/portal/components/templates/PTSettingTeamTemplate";
import { roleSelections } from "src/newtroid-console/config/roles";

type Props = {};
export const NTSettingTeamPage: React.FC<Props> = (props) => {
  return (
    <>
      <NTSettingsPageTemplate>
        <PTSettingTeamTemplate roleSelections={roleSelections} />
      </NTSettingsPageTemplate>
    </>
  );
};
