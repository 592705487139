import { ButtonGroup } from "@material-ui/core";
import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  > div {
    margin-left: auto;
    > * {
      margin-left: 8px;
    }
  }
`;

export interface IASButtonGroupProps {
  children: any;
}

export function ASButtonGroup(props: IASButtonGroupProps) {
  return (
    <Container>
      <div>{props.children}</div>
    </Container>
  );
}
