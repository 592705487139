import { Button, ButtonProps } from "@material-ui/core";
import styled from "styled-components";

export type ASButtonProps = Omit<ButtonProps, "color"> & {
  textcolor?: string;
  kind?: "primary" | "secondary" | "invert" | "white";
  mainColor?: string;
  component?: string;
};

export const ASButton = styled(Button).attrs<ASButtonProps>((props) => {
  const fillcolor = (() => {
    switch (props.kind) {
      case "primary":
        return props.theme?.primaryColor;
      case "secondary":
        return props.theme?.secondaryColor;
      case "invert":
        return "#fff";
      case "white":
        return "#fff";
      default:
        return props.mainColor;
    }
  })();

  const defaulttextcolor = (() => {
    switch (props.kind) {
      case "primary":
        return props.theme?.primaryOnText;
      case "secondary":
        return props.theme?.secondaryOnText;
      case "invert":
        return "#fff";
      case "white":
        return props.theme?.primaryColor;
      default:
        return props.mainColor;
    }
  })();

  return { fillcolor, defaulttextcolor };
})<ASButtonProps & { fillcolor?: string; defaulttextcolor?: string }>`
  border-radius: ${(props) => props.theme?.borderRadiusLg}px;
  text-transform: none;
  /* padding: 4px 24px; */
  /* height: 56px; */
  > * {
    padding: 0px 8px;
  }

  /* font-size: ${(props) => props.theme?.regularFontSizeMd}px; */
  color: ${(props) =>
    props.textcolor ? props.textcolor : props.defaulttextcolor};
  background-color: ${(props) => props.fillcolor};
  &:hover {
    background-color: ${(props) => props.fillcolor};
    filter: brightness(0.9);
  }
  &:disabled {
    background-color: ${(props) => props.theme.disabledColor};
    color: ${(props) => props.defaulttextcolor};
    opacity: 0.4;
  }
`;
