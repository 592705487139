import React from "react";
import styled from "styled-components";
import { CardContent } from "@material-ui/core";
import { ASCard } from "allegro-ui";
import { PTGlobalNav } from "src/portal/components/organisms/PTGlobalNav";
import bg from "src/fortress/assets/img/black-polygonal.jpg";
import { headerOffset } from "src/portal/components/organisms/PTGlobalNav";
import { ARGlobalNav } from "src/allegrobot/components/organisms/ARGlobalNav";
import { PTUserAuthComponent } from "../organisms/PTUserAuthComponent";

const Root = styled.div`
  background-image: url(${bg});
  background-size: cover;
  width: 100%;
  min-height: calc(100vh - ${headerOffset}px);
  text-align: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

type Props = {
  formContent?: any;
  formTitle?: string;
};

export const PTSignUpPageTemplate: React.FC<Props> = ({
  children,

  formTitle,
  formContent,
}) => {
  return (
    <>
      <PTGlobalNav />
      <Root>
        <PTUserAuthComponent formContent={formContent} formTitle={formTitle}>
          {children}
        </PTUserAuthComponent>
      </Root>
    </>
  );
};
