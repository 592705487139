import { useState } from "react";
import { createnAuthAPI } from "src/api";

export const useResetPassword = () => {
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const nauth = createnAuthAPI();

  const resetPassword = async (
    newPassword: string,
    passwordConfirm: string
  ): Promise<string | undefined> => {
    setIsLoading(true);

    const queryParameters = new URLSearchParams(window.location.search);
    const resetToken = queryParameters.get("reset_token");
    if (!newPassword || !passwordConfirm) {
      return "パスワードを入力してください。";
    }
    if (newPassword !== passwordConfirm) {
      return "パスワードが一致しません。";
    } else if (newPassword.length < 4) {
      return "パスワードが短すぎます。";
    }

    if (!resetToken) {
      return "リセットトークンを指定してください。";
    }

    try {
      const res = await nauth.confirmRescuePassword({
        resetToken,
        password: newPassword,
      });

      if (res.status === 200) {
        //送信完了ページに
        setIsSent(true);
        return "";
      }
    } catch (err: any) {
      console.error(err);
      if (err.response) return err.response.data.message ?? "失敗しました。";
    }
    setIsLoading(false);
  };

  return {
    isSent,
    isLoading,
    resetPassword,
  };
};
