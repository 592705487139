import React, { useState, useEffect } from "react";
import { XRPipelineEditorPageTemplate } from "src/xerver-room/components/templates/XRPipelineEditorPageTemplate";
import { useParams, useLocation } from "react-router-dom";
import { NTMainViewTemplate } from "../templates/NTMainViewTemplate";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

type Params = {
  pipelineId: string;
};

type Props = {};
export const NTPipelineEditorPage: React.FC<Props> = (props) => {
  const { pipelineId } = useParams<Params>();
  const location = useLocation().search;
  const query = new URLSearchParams(location);
  const slotId = query.get("slotId");

  const { height, width } = useWindowDimensions();

  return (
    <NTMainViewTemplate>
      <XRPipelineEditorPageTemplate
        pipelineId={pipelineId ?? ""}
        defaultSlotId={slotId}
      />
    </NTMainViewTemplate>
  );
};
