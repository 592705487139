import { AxiosInstance } from "axios";

export const fetchContainerStatsByNewtQuery = async (
  instance: AxiosInstance,
  newtQuery: any,
  options?: any
) => {
  try {
    const res = await instance.post(
      "/container-stats/dataprovider",
      newtQuery,
      {
        params: options,
      }
    );
    return res.data.message;
  } catch (err) {
    console.error(err);
    return [];
  }
};
