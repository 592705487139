import { Controller, Control } from "react-hook-form";
import {
  ASFormDesign,
  ASTextFormField as ASTextFormFieldtype,
} from "../../models/ASLowCodeFormField";
import { ASTextFormFieldComponent } from "./ASTextFormFieldComponent";
import { ASFormFieldBase } from "./ASFormFieldBase";

type ASFormFieldProps = {
  id?: number | string;
  control: Control;
  defaultValue?: string;
  error: any;
  editable?: boolean;
  active?: string;
  design?: ASFormDesign;
  supportText?: string;
  requiredMarker?: string;
  optionalMarker?: string;
  inputOnly?: boolean;
  onChange?: (text: string) => void;
};

export const ASTextFormField = (
  props: ASFormFieldProps & ASTextFormFieldtype
) => {
  const {
    id,
    label,
    name,
    required,
    control,
    active,
    values,
    editable,
    error,
    errorText,
    design,
    supportText,
    inputOnly,
    placeholder,
  } = props;
  const defaultValue =
    props.defaultValue && props.defaultValue !== ""
      ? props.defaultValue
      : active;

  return (
    <div key={`lc-create-data-form-${id}-${name}`}>
      {inputOnly ? (
        <Controller
          key={`as-text-form-controller-${id}-${label}-${defaultValue}`}
          name={name}
          control={control}
          rules={{ required: required }}
          defaultValue={defaultValue ?? active ?? undefined}
          render={({ field: { onChange } }) => (
            <ASTextFormFieldComponent
              name={name}
              design={design}
              values={values}
              defaultValue={defaultValue}
              editable={editable}
              onChange={(text) => {
                if (props.onChange) {
                  props.onChange(text);
                }
                onChange(text);
              }}
              placeholder={placeholder}
            />
          )}
        />
      ) : (
        <ASFormFieldBase
          error={error !== undefined}
          errorText={errorText}
          label={label}
          design={design}
          required={required}
          editable={editable}
          supportText={supportText}
          requiredMarker={props.requiredMarker}
          optionalMarker={props.optionalMarker}
        >
          <Controller
            key={`as-text-form-controller-${id}-${label}-${defaultValue}`}
            name={name}
            control={control}
            rules={{ required: required }}
            defaultValue={defaultValue ?? active ?? undefined}
            render={({ field: { onChange } }) => (
              <ASTextFormFieldComponent
                name={name}
                design={design}
                values={values}
                defaultValue={defaultValue}
                editable={editable}
                onChange={(text) => {
                  onChange(text);
                }}
                placeholder={placeholder}
              />
            )}
          />
        </ASFormFieldBase>
      )}
    </div>
  );
};
