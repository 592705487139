import { BundleInput } from "src/api/bundles";
import { nAIBundleReferences } from "../config/consts";

type nAITemplateProps = {
  bundleId?: string;
  name: string;
  slug: string;
  version: string;
  tags?: string[];
};

export const nAITemplate = (props: nAITemplateProps): BundleInput => {
  const { bundleId, name, slug, version, tags } = props;
  return {
    bundleId,
    name,
    slug,
    version,
    tags: tags ?? [],
    references: Object.values(nAIBundleReferences),
  };
};
