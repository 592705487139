import { FormDefinition, createFormDefinition } from "src/medican/models/form";
import { medicanAPIConfig } from "./config";

export const getFormDefinition = async (
  formId: string
): Promise<FormDefinition | undefined> => {
  const url = `/definition`;
  const params = {
    apikey: sessionStorage.token,
    userNoText: sessionStorage.userNoText,
    formId: formId,
  };
  const res = await medicanAPIConfig.get(url, { params: params });
  return Array.isArray(res.data.message) && res.data.message.length > 0
    ? createFormDefinition(res.data.message[0])
    : undefined;
};

export const saveFormDefinition = async (data: any) => {
  const url = `/definition`;
  const params = {
    apikey: sessionStorage.token,
    userNoText: sessionStorage.userNoText,
  };
  const res = await medicanAPIConfig.post(url, data, { params: params });
  return res;
};
