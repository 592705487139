import { ASLowCodeFormDefinition } from "allegro-low-code-components";

export const commitNAIFormConf: ASLowCodeFormDefinition = {
  sections: [
    {
      title: "",
      fields: [
        {
          label: "Version",
          name: "newVersion",
          supportText:
            "最新のバージョンから複製して新規バージョンを作成します。",
          type: "text",
          required: true,
          errorText: "Versionは必須です。",
        },
        {
          label: "Comment",
          name: "comment",
          type: "text",
          required: false,
        },
      ],
    },
  ],
};
