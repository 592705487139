//Libs
import React, { useState } from "react";
//Components
import { ASModal, ASButton } from "allegro-ui";
//States
//Types
//Libs
import { XRLogViewer } from "src/xerver-room/components";

type Props = {};

export const OEToggleLogButton: React.FC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const toggleLog = () => {
    setShowModal(true);
  };

  return (
    <>
      <ASButton
        style={{ flex: 1, margin: "3px" }}
        kind={"primary"}
        onClick={toggleLog}
      >
        LOG
      </ASButton>
      <ASModal open={showModal} onClose={() => setShowModal(false)}>
        <XRLogViewer />
      </ASModal>
    </>
  );
};
