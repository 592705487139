export const url: { [key: string]: any } = {
  dataManage: (contractorId: string, collectionType: string) =>
    `/contractors/${contractorId}/datamanage/${collectionType.toLowerCase()}`,
  topData: (contractorId: string, userNo: string = "xxx") =>
    `/contractors/${contractorId}/users/xxxx/topdatas`,
  rawData: (contractorId: string, userNo: string = "xxx") =>
    `/contractors/${contractorId}/users/xxxx/rawdatas`,
  txnData: (contractorId: string, userNo: string = "xxx") =>
    `/contractors/${contractorId}/users/xxxx/txndatas`,
  topData__UNSTABLE: (contractorId: string, userNo: string) =>
    `/contractors/${contractorId}/topdata`,
  rawData__UNSTABLE: (contractorId: string, userNo: string) =>
    `/contractors/${contractorId}/rawdata`,
  txnData__UNSTABLE: (contractorId: string, userNo: string) =>
    `/contractors/${contractorId}/txndata`,
  historyData: () => "",
  pipelineX: (contractorId: string) => `/contractors/${contractorId}/pipelinex`,
  dataProvider: () => `/newtout/aggr`,
};
