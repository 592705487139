import React, { useState } from "react";
import { ASIconButton } from "allegro-ui";
import StopIcon from "@material-ui/icons/Stop";
import { useCallback } from "react";
import * as api from "src/utils";
import { cursorSlotState, selectPipelineState } from "src/opt-editor/recoils";
import { useRecoilValue } from "recoil";
import { Slot } from "src/opt-editor/models";
import { useSnackbar, VariantType } from "notistack";

type Props = {};

const stopOptimizer = async (slot: Slot, pipelineName: string) => {
  console.log("stop Optimizer");

  const res = await api.stopOptimizer({
    nodeId: slot.name,
    contractorNo: sessionStorage.contractorNo,
    pipelineName: pipelineName,
  });

  return res;
};

export const OEStopLogicButton: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);
  const cursorSlot = useRecoilValue(cursorSlotState);
  const selectPipeline = useRecoilValue(selectPipelineState);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = useCallback(async () => {
    setLoading(true);
    const res = await stopOptimizer(cursorSlot, selectPipeline);

    //スナックバーに成否を表示する
    const variant: VariantType =
      res?.data.status === "success" ? "success" : "error";
    console.log(res?.data);
    enqueueSnackbar(String(res?.data.message), { variant });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursorSlot, enqueueSnackbar]);
  return (
    <ASIconButton
      kind="secondary"
      size="small"
      onClick={handleClick}
      disabled={loading || !cursorSlot}
    >
      <StopIcon />
    </ASIconButton>
  );
};
