import { AxiosInstance } from "axios";

export type BundleCommitProps = {
  bundleId: string;
  version: string;
  newVersion: string;
  comment?: string;
};

export const commit = async (
  instance: AxiosInstance,
  props: BundleCommitProps
) => {
  const { bundleId, version, newVersion, comment } = props;

  try {
    const res = await instance.post(`/bundles/${bundleId}/${version}/commit`, {
      version: newVersion,
      comment,
    });

    return res.data;
  } catch (err) {
    console.error(err);
  }
};
