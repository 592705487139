export const roleSelections = [
  {
    label: "ContractorAdmin",
    value: "serviceAdmin",
    roleLevel: 1,
  },
  {
    label: "InformationSystemAdmin",
    value: "informationSystemAdmin",
    roleLevel: 2,
  },
  {
    label: "Admin",
    value: "localGovernment",
    roleLevel: 3,
  },
  {
    label: "SubAdmin",
    value: "intermediateCorporate",
    roleLevel: 4,
  },
  {
    label: "Stuff",
    value: "stuff",
    roleLevel: 8,
  },
  {
    label: "Guest",
    value: "guest",
    roleLevel: 9,
  },
];
