import React, { createContext, useContext, useState } from "react";
import {
  PipelineDef,
  initialPipelineDef,
  Slot,
  Slots,
  initialSlot,
} from "../models";
import { produce, enableMapSet } from "immer";
import { useEffect } from "react";

type ContextProps = {
  pipelines: PipelineDef[];
  cursorPipeline: PipelineDef;
  slots: Slots;
  cursorSlot: Slot;
  logs: string;
  deploy: (opt: PipelineDef) => void;

  fetchSystemLogs: () => void;
};

enableMapSet();

const PipelineContext = createContext<Partial<ContextProps>>({});

const createSlotsFromPipeline = (p: any): Slots => {
  const { contents } = p;
  const parsedContents =
    typeof contents === "string" && contents !== ""
      ? JSON.parse(String(contents))
      : contents;

  if (!parsedContents) {
    console.log("invalid argments were given.");
    return {};
  }

  let nodes: any = null;

  // //Contents,nodesがJavaでは配列でWrapされている e.g contents:[nodes:{ ~~ }]
  if (parsedContents.length > 0) {
    nodes = parsedContents[0].nodes;
  } else {
    nodes = parsedContents.nodes;
  }

  const slots: Slots = {};
  if (nodes && Object.keys(nodes).length > 0) {
    Object.keys(nodes).map((key) => {
      return (slots[key] = produce(initialSlot, (draft) => {
        const n = nodes[key];
        draft.name = n.name;
        draft.type = n.type;
        draft.col = n.col;
        draft.row = n.row;
        draft.description = n.description;
        draft.lines = n.lines;
        draft.logic = n.logic;
        draft.logicType = n.logicType;
      }));
    });
  }

  return slots;
};

export const PipelineProvider: React.FC<{}> = ({ children }) => {
  const [pipelines] = useState<PipelineDef[]>([]);
  const [cursorPipeline, setCursorPipeline] =
    useState<PipelineDef>(initialPipelineDef);
  const [slots, setSlots] = useState<Slots>({});
  const [cursorSlot, setCursorSlot] = useState(initialSlot);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [logs, setLogs] = useState("");

  // カーソルパイプラインが変更された際に、カーソルスロットも更新する。
  useEffect(() => {
    const nextSlots: Slots = createSlotsFromPipeline(cursorPipeline);
    setSlots(nextSlots);
    const nextCursorSlot = nextSlots[Object.keys(nextSlots)[0]];
    setCursorSlot(nextCursorSlot);
  }, [cursorPipeline]);

  useEffect(() => {
    if (pipelines.length > 0) setCursorPipeline(pipelines[0]);
  }, [pipelines]);

  useEffect(() => {}, [cursorSlot]);

  const value = {
    pipelines,
    cursorPipeline,

    slots,
    cursorSlot,

    logs,
  };
  return (
    <PipelineContext.Provider value={value}>
      {children}
    </PipelineContext.Provider>
  );
};

export const usePipeline = () => {
  return useContext(PipelineContext);
};
