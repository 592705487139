import { ASInputBase, ASButton } from "allegro-ui";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  text-align: left;
  padding: 0 16px;
  > * {
    margin-top: 24px;
  }
  > p {
    line-height: 1.5;
  }
`;

const SubmitButton = styled(ASButton)`
  width: 100%;
`;
const StyledLink = styled(Link)`
  color: ${(props) => props.theme.primaryColor};
`;

const ErrorText = styled.p`
  color: ${(props) => props.theme.negativeColor};
`;

type Props = {
  onSubmit: (
    password: string,
    passwordConfirm: string
  ) => string | undefined | Promise<string | undefined>;
};

export const PTRescuePasswordForm: React.FC<Props> = (props) => {
  const [newPassword, setNewPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] =
    React.useState<string | null>(null);
  const [errMsg, setErrMsg] = React.useState("");

  return (
    <Container>
      <p>
        新しいパスワードを入力してください。
        <br />
        確認のためにもう一度入力して下さい。
      </p>

      <ASInputBase
        fullWidth
        id="email"
        type="password"
        kind="digicho"
        placeholder="新しいパスワード"
        defaultValue=""
        onChange={(e) => {
          setNewPassword(e.target.value);
        }}
      ></ASInputBase>

      <ASInputBase
        fullWidth
        type="password"
        id="email"
        kind="digicho"
        placeholder="パスワードの確認"
        defaultValue=""
        onChange={(e) => {
          setPasswordConfirm(e.target.value);
        }}
      ></ASInputBase>

      <SubmitButton
        kind="primary"
        onClick={async () => {
          const newErrMsg = await props.onSubmit(
            newPassword,
            passwordConfirm ?? ""
          );
          if (newErrMsg) {
            setErrMsg(newErrMsg);
          }
        }}
      >
        パスワードを更新する
      </SubmitButton>

      {errMsg ? <ErrorText>{errMsg}</ErrorText> : null}
    </Container>
  );
};
