import {
  atom,
  selector,
  useRecoilState,
  useSetRecoilState,
  useRecoilValue,
  DefaultValue,
} from "recoil";
import * as api from "src/utils";
import { OptLogic, initialOptLogic } from "../models";
import { produce } from "immer";
import { useTextEditor } from ".";
import { useCallback, useEffect } from "react";

const optLogicListState = atom<OptLogic[]>({
  key: "astraOptLogicList",
  default: [],
});

//id
export const selectOptLogicState = atom<string>({
  key: "selectOptlogic",
  default: "",
});

export const cursorOptLogicState = selector<OptLogic>({
  key: "curosrOptLogic",
  get: ({ get }) => {
    const list: OptLogic[] = get(optLogicListState);
    const logicId = get(selectOptLogicState);
    return list.find((o) => o._id === logicId) ?? initialOptLogic;
  },
  set: ({ set, get }, newOptLogic) => {
    if (newOptLogic instanceof DefaultValue) return;
    const list: OptLogic[] = get(optLogicListState);
    const logicId = get(selectOptLogicState);
    const nextList = list.map((opt) => {
      return opt._id === logicId ? newOptLogic : opt;
    });
    set(optLogicListState, nextList);
  },
});

export const showedSplitPanel = atom<boolean>({
  key: "showedSplitPanel",
  default: false,
});

export const activeLogicPanel = atom<string>({
  key: "activeLogicPanel",
  default: "left",
});

export const fetchOptLogicList = async () => {
  console.log("start fetch optLogics");
  const res = await api.fetchOptLogics();
  if (res.data.length > 0) {
    const validData = res?.data.map((d: OptLogic) => {
      // console.log(d);
      return produce(initialOptLogic, (draft) => {
        draft.name = d.name;
        draft.templateid = d.templateid;
        draft.contents = d.contents;
        draft.note = d.note;
        draft.id = d.id ? d.id : d._id;
        draft._id = d._id;
      });
    });
    return validData;
  }
};

export const useOptLogicList = () => {
  const [optLogicList, setList] = useRecoilState(optLogicListState);
  const selectOptLogic = useSetRecoilState(selectOptLogicState);

  const didMount = useCallback(async () => {
    const data = await fetchOptLogicList();

    if (data && data.length > 0) {
      setList(data);
      selectOptLogic(data[0]._id);
    }
  }, [selectOptLogic, setList]);

  useEffect(() => {
    didMount();
  }, [didMount]);

  return [optLogicList];
};

export const useCursorOptLogic = () => useRecoilValue(cursorOptLogicState);

export const useSaveScript = () => {
  const [cursorOptLogic, setCursorOptLogic] =
    useRecoilState(cursorOptLogicState); //set
  const { script } = useTextEditor();
  const save = useCallback(async () => {
    const modifiedLogic = produce(cursorOptLogic, (draft) => {
      draft.contents = script;
    });
    const res = await api.updateLogic(modifiedLogic);

    if (res && res.status === 200) {
      setCursorOptLogic(modifiedLogic);
    }

    return res;
  }, [cursorOptLogic, script, setCursorOptLogic]);
  return save;
};
