import React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { ASSelect } from "allegro-ui";
import {
  useChildListFilter,
  useChildListSort,
} from "src/fortress/hooks/userChildListViewer";

import {
  ageSelections,
  evalSelections,
  watchSelections,
  riskSelections,
  dataGroupSelections,
  orderSelections,
} from "src/fortress/utils/filterQuery/filterConfig";

const StyledGrid = styled(Grid)`
  padding: 30px;
  border: 1px solid #c4c4c4;
  border-radius: 15px;
`;

type Props = {};

//児童一覧画面における、フィルターとソートの画面を表している。

export const FTSortList: React.FC<Props> = (props) => {
  const { handleFilter } = useChildListFilter();
  const { handleSort } = useChildListSort();

  return (
    <StyledGrid container direction="row" alignItems="center" spacing={1}>
      <Grid item xs={2}>
        <ASSelect
          name="age"
          kind="secondary"
          label="年代"
          data={ageSelections}
          defaultValue={ageSelections[0].value}
          onChange={handleFilter}
        ></ASSelect>
      </Grid>
      <Grid item xs={2}>
        <ASSelect
          name="riskEvalByStaff"
          kind="secondary"
          label="職員評価"
          data={evalSelections}
          defaultValue={evalSelections[0].value}
          onChange={handleFilter}
        ></ASSelect>
      </Grid>
      <Grid item xs={2}>
        <ASSelect
          name="watch"
          kind="secondary"
          label="ウォッチリスト"
          data={watchSelections}
          defaultValue={watchSelections[0].value}
          onChange={handleFilter}
        ></ASSelect>
      </Grid>
      <Grid item xs={2}>
        <ASSelect
          name="riskValue"
          kind="secondary"
          label="リスク値"
          data={riskSelections}
          defaultValue={riskSelections[0].value}
          onChange={handleFilter}
        ></ASSelect>
      </Grid>
      <Grid item xs={2}>
        <ASSelect
          name="dataGroup"
          kind="secondary"
          label="データグループ"
          data={dataGroupSelections}
          defaultValue={dataGroupSelections[0].value}
          onChange={handleFilter}
        ></ASSelect>
      </Grid>
      <Grid item xs={2}>
        <ASSelect
          name="sort"
          kind="secondary"
          label="並び順"
          data={orderSelections}
          defaultValue={orderSelections[0].value}
          onChange={handleSort}
        ></ASSelect>
      </Grid>
    </StyledGrid>
  );
};
