import { ASPageNav } from "allegro-ui";
import * as React from "react";
import styled from "styled-components";

interface ILCPagerComponentProps {
  count: number;
  page: number;
  onChange: any;
}

const Container = styled.div`
  /* position: absolute; */
  display: flex;
  justify-content: center;

  padding: 12px;
  border-radius: 24px 24px 0px 24px;
  right: 0;
  bottom: 0px;
  background: ${(props) => props.theme.bgPrimaryColor};
`;

const LCPagerComponent: React.FunctionComponent<ILCPagerComponentProps> = (
  props
) => {
  return (
    <Container>
      <ASPageNav
        count={props.count}
        page={props.page}
        onChange={props.onChange}
      />
    </Container>
  );
};

export default LCPagerComponent;
