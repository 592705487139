import React from "react";
import { useState, useEffect } from "react";
import {
  XYPlot,
  FlexibleWidthXYPlot,
  AreaSeries,
  HorizontalGridLines,
  VerticalGridLines,
  VerticalBarSeries,
  XAxis,
  YAxis,
  LabelSeries,
  LineMarkSeriesPoint,
  RVNearestXEventHandler,
} from "react-vis";
import { useTheme } from "styled-components";

import { ASGraph, ASGraphProps, GraphData, Coordinate } from "./ASGraph";

const formatNum = (num: number) => {
  return num > 1000 ? `${num / 1000}k` : num.toString();
};

export type ASBarGraphProps = ASGraphProps & {
  barWidth?: number;
};

export const ASBarGraph: React.FC<ASBarGraphProps> = (props) => {
  const _onClick = () => {
    if (pointX !== undefined && props.graphClickCallback !== undefined) {
      props.graphClickCallback(pointX);
    }
  };

  // const _onLeave = () => {
  //   setPointX(undefined);
  // };

  // const _onNearestX: RVNearestXEventHandler<LineMarkSeriesPoint> = (
  //   data,
  //   { index }
  // ) => {
  //   setPointX(index);
  // };

  const [pointX, setPointX] = useState<number>();
  // const [maxY, setMaxY] = useState<number>(1);
  const theme = useTheme();

  // useEffect(() => {}, []);
  const barWidth = props.barWidth ?? 0.5;
  const xTickSize = props.xTickSize ?? 10;
  const yTickSize = props.yTickSize ?? 10;
  const yAxisMargin = props.yAxisMargin ?? 50;
  const xType = props.xType ?? "linear";
  const xsteps = props.xsteps;
  const ysteps = props.ysteps;
  const xRange = props.xRange;
  const minY = props.yRange && props.yRange[0] ? props.yRange[0] : 0;
  const maxY =
    props.yRange && props.yRange[1]
      ? props.yRange[1]
      : Math.max(
          ...props.graphDatas
            .map((graphData) =>
              graphData.dataPoints.map((prediction) =>
                Math.ceil(prediction.y * 1.1)
              )
            )
            .flat(),
          1
        );

  // 現在マウスカーソルを合わせている日付の位置X
  const currentYAxisDatas =
    pointX !== undefined
      ? [
          {
            x: props.graphDatas[0].dataPoints[pointX].x,
            y: 0,
          },
          {
            x: props.graphDatas[0].dataPoints[pointX].x,
            y: maxY,
          },
        ]
      : undefined;

  return (
    <FlexibleWidthXYPlot
      key={`as-graph-${Math.random()}`}
      margin={{ left: yAxisMargin }}
      xDomain={props.xRange}
      yDomain={[minY, maxY]}
      xType={xType}
      height={props.graphHeight}
      onClick={_onClick}
      // onMouseLeave={_onLeave}
      style={{
        fontSize: "12px",
        fill: "#aaa",
      }}
    >
      <HorizontalGridLines
        style={{ stroke: theme.darkMode ? "#333" : "#ebebeb" }}
      />
      <XAxis
        tickSize={xTickSize}
        tickTotal={xsteps}
        tickValues={xsteps === 2 ? xRange : undefined}
        title={props.xLabel}
        tickFormat={(tick) => {
          tick = Number(tick);
          const point = props.graphDatas[0]?.dataPoints[tick];
          const xValue = point?.x ?? "";
          return String(props.xAxisFormat ? props.xAxisFormat(xValue) : xValue);
        }}
        style={{ fontSize: `${xTickSize}px` }}
      />
      <YAxis tickFormat={(tick) => formatNum(tick)} />

      {/* <VerticalBarSeries barWidth={100} data={currentYAxisDatas as any[]} /> */}

      {props.graphDatas.map((graphData, id) => {
        const points: any[] = graphData.dataPoints.map((prediction, index) => {
          return {
            x: index,
            y: prediction.y,
          };
        });
        // 総合リスクの場合は太線にする
        const strokeWidth = graphData.id == 1 ? 3 : 1;
        //TODO lintにsizeが怒られている。
        return (
          <VerticalBarSeries
            barWidth={barWidth}
            // onNearestX={_onNearestX}
            color={graphData.color}
            style={{
              strokeLinejoin: "round",
              strokeWidth: strokeWidth,
              // fill: "none",
            }}
            data={points}
            key={graphData.id}
          />
        );
      })}
    </FlexibleWidthXYPlot>
  );
};
