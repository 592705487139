import React, { useState } from "react";
import styled from "styled-components";
import { ASInputBase, ASButton } from "allegro-ui";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  width: 400px;
  padding: 24px;
  h1 {
    font-size: 32px;
    font-weight: bold;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FormInput = styled.div`
  > * {
    margin-left: 8px;
  }
`;

type Props = {
  onSubmit: (formdata: { monitorName: string }) => void;
};

//モニター一覧画面において、モニター追加用のモーダルコンポーネント。

export const AddMonitorModal: React.FC<Props> = (props) => {
  const [monitorName, setMonitorName] = useState("");
  const { onSubmit } = props;
  return (
    <Container>
      <h1>新規モニター作成</h1>
      <FormInput>
        名前
        <ASInputBase
          kind="secondary"
          onChange={(e) => {
            setMonitorName(e.target.value);
          }}
        ></ASInputBase>
      </FormInput>

      <ButtonGroup>
        <ASButton
          kind={"primary"}
          onClick={() => onSubmit({ monitorName: monitorName })}
        >
          作成する
        </ASButton>
      </ButtonGroup>
    </Container>
  );
};
