import * as React from "react";
import { useState } from "react";

import { ASModal, ASButton } from "allegro-ui";

import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { assetCreatingFormDefinition } from "../../../newtzero-console/components/lowcode/browser/asset/assetCreatingFormDefinition";

import { ASLowCodeDataBrowserContainer } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { assetBroweserUi } from "../../../newtzero-console/components/lowcode/browser/asset/assetBrowserUi";
import styled from "styled-components";
import FTSystemPageTemplate from "./FTSystemPageTemplate";

const Header = styled.div`
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.textColor};
  > *:last-child {
    margin-left: auto;
  }
`;
interface IFTAssetListPageTemplateProps {}

const FTAssetListPageTemplate: React.FunctionComponent<IFTAssetListPageTemplateProps> =
  (props) => {
    const [open, setOpen] = useState(false);

    const handleModalOpen = () => {
      setOpen(true);
    };

    const handleModalClose = () => {
      setOpen(false);
    };
    return (
      <>
        <FTSystemPageTemplate>
          <Header>
            アセット一覧
            <ASButton kind="primary" onClick={handleModalOpen}>
              新規作成
            </ASButton>
          </Header>

          <ASLowCodeDataBrowserContainer
            name="ft-asset-list-page"
            uiConf={assetBroweserUi}
          />
        </FTSystemPageTemplate>

        <ASModal open={open} onClose={handleModalClose}>
          <ASLowCodeFormContainer
            formDefinition={assetCreatingFormDefinition}
            onClose={handleModalClose}
          />
        </ASModal>
      </>
    );
  };

export default FTAssetListPageTemplate;
