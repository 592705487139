import styled from "styled-components";
import { Chip } from "@material-ui/core";

const StyledChip = styled(Chip)`
  color: white;
  background: #6846a5;
  border-radius: 5px;
  width: 78px;
`;

export const OEUpdateChip: React.FC = () => {
  return <StyledChip label="UPDATE" />;
};
