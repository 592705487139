import React, { Component, ReactElement } from "react";
import { CHUserAuthMainViewTemplate } from "../templates/CHUserAuthMainView";
import { PTUserAuthComponent } from "src/portal/components/organisms/PTUserAuthComponent";
import { PTSignUpForm } from "src/portal/components/molecules/PTSignUpForm";
import { useNavigate } from "react-router-dom";
import { useSignUpActions } from "src/portal/hooks/useSignUpActions";
import styled from "styled-components";
import routes from "src/portal/routes.json";
import { ASButton } from "allegro-ui";
import CHTermsAndPrivacyCaption from "../../molecules/CHTermsAndPrivacyCaption";

const SignUpNote = styled.div`
  color: ${(props) => props.theme.textColor};
  padding: 23px 0;
  &::before {
    top: 9px;
    /* border-bottom: 1px solid #fff; */
    position: absolute;
    left: 0;
    width: 100%;
    content: "";
  }
`;

const SignUpRegister = styled.div`
  padding: 0 64px;
`;

export type CHSignUpPageProps = {
  logo: any;
  appName: string;
  copyRight?: string;
  loginPath?: string;
  passwordResetPath?: string;
  privacyPolicyComponent: ReactElement;
  termsOfSeviceCompoennt: ReactElement;
};

export const CHSignUpPage: React.FC<CHSignUpPageProps> = (props) => {
  const { signUp } = useSignUpActions();
  const history = useNavigate();

  const handleSubmit = async (email: string, contractor: string) => {
    if (contractor && email) {
      const { success, error } = await signUp(contractor, email);
      if (success) {
        history(routes.PROVISIONEMAIL);
      } else if (error) {
        alert(error);
      } else {
        alert("登録に失敗しました。しばらく経ってからもう一度お試しください。");
      }
    } else {
      alert("入力してください");
    }
  };
  return (
    <>
      <CHUserAuthMainViewTemplate logo={props.logo}>
        <PTUserAuthComponent
          formTitle={"会員登録"}
          formContent={<PTSignUpForm onSubmit={handleSubmit} />}
        >
          <CHTermsAndPrivacyCaption
            termsOfSeviceComponent={props.termsOfSeviceCompoennt}
            privacyPolicyComponent={props.privacyPolicyComponent}
          />
          <div>
            <SignUpNote>アカウントをお持ちの方</SignUpNote>
            <SignUpRegister>
              <ASButton
                kind="secondary"
                size="large"
                fullWidth
                onClick={() => {
                  history(props.loginPath ?? "/login");
                }}
              >
                ログイン
              </ASButton>
            </SignUpRegister>
          </div>
        </PTUserAuthComponent>
      </CHUserAuthMainViewTemplate>
    </>
  );
};
