export const roleSelections = [
  {
    label: "ContractorAdmin",
    value: "contractorAdmin",
    roleLevel: 1,
  },
  {
    label: "InformationSystemAdmin",
    value: "informationSystemAdmin",
    roleLevel: 2,
  },
  {
    label: "Admin",
    value: "admin",
    roleLevel: 3,
  },
  {
    label: "SubAdmin",
    value: "subAdmin",
    roleLevel: 4,
  },
  {
    label: "Staff",
    value: "staff",
    roleLevel: 8,
  },
  {
    label: "Guest",
    value: "guest",
    roleLevel: 9,
  },
];
