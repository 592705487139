import React, { useCallback } from "react";
import { useRecoilState } from "recoil";
import { ChildListItem } from "../../models";
import { FTChildListItem } from "../molecules";
import styled from "styled-components";
import {
  childListPageIndexState,
  pageNavSettingsState,
  useChildList,
} from "../../recoils/childListState";
import { ASPageNav } from "allegro-ui";
import { Question } from "../atoms";

const StyledTable = styled.div``;

const TableHeader = styled.div`
  display: flex;
  margin-bottom: 15px;
  padding-top: 10px;
`;

const CellOne = styled.div`
  flex: 0.7;
`;

const CellTwo = styled.div`
  flex: 2;
  margin-right: 30px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
`;

const CellThree = styled.div`
  flex: 2;
  text-align: center;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
`;

const CellFour = styled.div`
  flex: 4;
  margin-right: 15px;
`;

const Page = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

type Props = {};

export const FTChildList: React.FC<Props> = ({ children, ...props }) => {
  const childList = useChildList();
  const [pageSettings] = useRecoilState(pageNavSettingsState);
  const [pageIndex, setPageIndex] = useRecoilState(childListPageIndexState);

  //ページを移動するときの処理をするため

  const handlePage = useCallback(
    (e: any, page: number) => {
      setPageIndex(page);
    },
    [setPageIndex]
  );

  return (
    <>
      <StyledTable>
        <TableHeader>
          <CellOne></CellOne>
          <CellTwo>名前</CellTwo>
          <CellThree>介入状況</CellThree>
          <CellThree>職員評価</CellThree>
          <CellThree>AI評価</CellThree>
          <CellFour></CellFour>
        </TableHeader>
        {childList.map((child: ChildListItem) => (
          <FTChildListItem
            child={child}
            key={`k-${child.atena}${child.name}`}
          />
        ))}
      </StyledTable>
      <Page>
        <ASPageNav
          count={pageSettings.pageTotalCount}
          page={pageIndex}
          onChange={handlePage}
        />
      </Page>
      <Question />
    </>
  );
};
