import React, { useEffect } from "react";
import { XRXerviceBrowser } from "../organisms/XRXerviceBrowser";
import { XRMainViewTemplate } from "../templates/XRMainViewTemplate";
import { useSetRecoilState } from "recoil";
import { slotBrowserFilterState } from "src/xerver-room/recoils";
import styled from "styled-components";

type Props = {};

const BrowserContainer = styled.div`
  padding: 30px;
`;

export const XRAdminXerviceBroserPage: React.FC<Props> = (props) => {
  const setSlotBrowserFilterState = useSetRecoilState(slotBrowserFilterState);
  //init
  useEffect(() => {
    setSlotBrowserFilterState("All");
  }, [setSlotBrowserFilterState]);
  return (
    <XRMainViewTemplate>
      <BrowserContainer>
        <XRXerviceBrowser showPipelineId />
      </BrowserContainer>
    </XRMainViewTemplate>
  );
};
