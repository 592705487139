import React from "react";

import { Controller, Control, FieldValues } from "react-hook-form";
import { ASChipsForm, ASFormDesign } from "../../models/ASLowCodeFormField";
import { ASFormLabel, ASErrorField, ASRequiredMark } from "../atoms";
import { ASChipsFormFieldComponent } from "./ASChipsFormFieldComponent";
import { ASFormFieldBase } from "./ASFormFieldBase";

type ASFormFieldProps = {
  id?: number | string;
  control: Control;
  defaultValue?: string[];
  error: any;
  editable?: boolean;
  design?: ASFormDesign;
  supportText?: string;
  requiredMarker?: string;
  optionalMarker?: string;
};

export const ASChipsFormField = (props: ASFormFieldProps & ASChipsForm) => {
  const {
    id,
    label,
    name,
    required,
    active,
    values,
    control,
    defaultValue,
    error,
    errorText,
    editable,
    design,
    supportText,
  } = props;
  return (
    <div key={`as-form-chips-${id}-${name}`}>
      <ASFormFieldBase
        error={error}
        errorText={errorText}
        label={label}
        design={design}
        required={required}
        editable={editable}
        supportText={supportText}
        requiredMarker={props.requiredMarker}
        optionalMarker={props.optionalMarker}
      >
        <Controller
          key={`lc-create-data-form-${id}`}
          name={name}
          control={control}
          rules={{ required: required }}
          defaultValue={defaultValue ?? active}
          render={({ field: { onChange } }) => (
            <>
              <ASChipsFormFieldComponent
                label={label}
                active={defaultValue ?? active}
                values={values}
                editable={editable}
                onChange={(active: string[]) => {
                  if (editable) {
                    onChange(active);
                  }
                }}
              />
            </>
          )}
        />
      </ASFormFieldBase>
    </div>
  );
};
