import React, { useState, useCallback } from "react";
import { ASEasyTable, ASSelect } from "allegro-ui";
import { FTDashBoardWidget } from ".";
import styled from "styled-components";
import Masonry from "react-masonry-css";
import { PersonalData } from "src/fortress/models";

const MasonryGrid = styled(Masonry)`
  display: flex;
  margin-left: -30px;
  margin-top: -30px;
`;

const MasonryItem = styled.div`
  margin-left: 30px;
  margin-top: 30px;
`;

const CaptionGroup = styled.div`
  margin-top: 14px;
  color: #aaa;
  font-size: 10px;
  padding: 8px 0 0 0;
  border-top: 1px solid #eee;
  span {
    display: block;
    line-height: 1;
    overflow-wrap: break-word;
  }
`;

const SelectBox = styled.div`
  /* text-align: right; */
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  personalDatas: PersonalData[];
};

//児童詳細画面の本人データのコンポーネント。
//住民記録、生活保護、乳児健診、3歳児検診など、合計27個のデータが存在するが、そのデータは日々府中市職員の人びとがexcelで入力したものをsmartEdgeサーバーに登録されたものである。
//そのデータをallegrobotを介してこのUIに出力している。

export const FTKihonDataPanel: React.FC<Props> = ({ personalDatas }) => {
  const [filterPersonalData, setFilterPersonalData] = useState<
    Array<PersonalData>
  >([]);

  //27個のデータのうちどのデータを見たいかを選ぶことができるようにしている。

  const handlePersonalSelect = useCallback(
    (event: any) => {
      const value = event.target.value;

      if (value === "すべて") {
        setFilterPersonalData(personalDatas);
      } else {
        personalDatas.forEach((d) => {
          if (d.fileName === value) {
            setFilterPersonalData([d]);
          }
        });
        const pd = personalDatas.filter((d) => d.fileName === value);
        setFilterPersonalData(pd);
      }
    },
    [personalDatas]
  );

  const selections = (() => {
    let result = [];
    const fileNames = personalDatas
      .filter((pd) => {
        return pd.data.length > 0;
      })
      .map((pd) => {
        return pd.fileName;
      })
      .filter((x, i, self) => {
        //重複を削除する
        return self.indexOf(x) === i;
      });
    result = fileNames.map((fileName) => {
      return { value: fileName, label: fileName };
    });
    result.unshift({ value: "すべて", label: "すべて" });
    return result;
  })();

  const nextPersonalDatas =
    filterPersonalData.length > 0 ? filterPersonalData : personalDatas;

  //格子状のuiとして出力する。

  const widgets =
    nextPersonalDatas.length > 0 ? (
      nextPersonalDatas.map((personalData: PersonalData, id) => {
        return (
          <MasonryItem key={String(id)}>
            <FTDashBoardWidget
              title={personalData.label}
              children={
                <>
                  <ASEasyTable
                    key={`k-table-${personalData.label}${id}`}
                    tableData={personalData.data}
                  ></ASEasyTable>
                  <CaptionGroup>
                    <span>{personalData.lastUpdate}</span>
                    <span>{personalData.tags}</span>
                  </CaptionGroup>
                </>
              }
            ></FTDashBoardWidget>
          </MasonryItem>
        );
      })
    ) : (
      <></>
    );

  const masonry =
    nextPersonalDatas.length > 4 ? (
      <MasonryGrid
        breakpointCols={2}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {widgets}
      </MasonryGrid>
    ) : (
      <MasonryGrid
        breakpointCols={1}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {widgets}
      </MasonryGrid>
    );

  return (
    <>
      <SelectBox>
        <ASSelect
          kind="secondary"
          label=""
          data={selections}
          defaultValue={"すべて"}
          onClick={handlePersonalSelect}
        ></ASSelect>
      </SelectBox>
      {masonry}
    </>
  );
};
