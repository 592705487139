//Libs
import React from "react";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";

//Components
import { OEUserButton } from "src/opt-editor/components";

//States

import { GlobalNavConfig } from "src/medican/config/globalNav";
import { SettingNavConf } from "src/cheetah/models/SettingNavConf";
import { useHeaderNav } from "src/cheetah/hooks/useHeaderNav";

//Types

export const headerOffset = 56;

const Container = styled.div`
  height: ${headerOffset}px;
  background: ${(props) => props.theme.bgPrimaryColor};
  display: flex;
  align-items: center;
`;

const AppBar = styled.div`
  width: 100%;
  padding: 0 24px;
  position: fixed;
  z-index: 999;
  height: ${headerOffset}px;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.bgSecondaryColor};
  border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};
`;

const LogoAndTitle = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  > * {
    margin-left: 30px;
  }
`;

const MenuGroup = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-left: 8px;
  }
`;

const Skelton = styled.div`
  clear: both;
  position: relative;
  /* width:100%: */
  /* height: ${headerOffset}px; */
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  a {
    padding: 8px;
    display: inline-block;
    color: ${(props) => props.theme.textColor};
    text-decoration: none;
    align-items: center;
    /* font-weight: 500; */
    vertical-align: middle; /* 上下中央寄せ */
    > * {
      vertical-align: middle; /* 上下中央寄せ */
    }
  }
  a:hover {
    filter: 
    transition-property: all;
    transition-duration: 0.3s;
    /* イージングを指定 */
    transition-timing-function: linear;
  }
  a.active {
    color: ${(props) => props.theme.primaryColor};
    /* background: ${(props) => props.theme.primaryColor}; */
    /* border-radius: ${(props) => props.theme.borderRadiusSm}px; */
    transition-property: all;
    transition-duration: 0.3s;
    /* イージングを指定 */
    transition-timing-function: linear;
  }
`;

const MenuLabel = styled.span`
  font-size: ${(props) => props.theme.labelFontSizeLg}px;
`;

type Props = {
  logo?: React.ReactElement;
  conf: SettingNavConf;
};

export const CHHeaderNavItem: React.FC<GlobalNavConfig> = (props) => {
  const Icon = props.icons;
  const location = useLocation();

  return (
    <NavLink
      to={props.link}
      className={({ isActive, isPending }) =>
        isPending
          ? "pending"
          : isActive || (props.isRoot && location.pathname === "/")
          ? "active"
          : ""
      }
    >
      {props.icons ? <Icon /> : null}

      <MenuLabel>{props.title}</MenuLabel>
    </NavLink>
  );
};

export const CHHeaderNav: React.FC<Props> = (props) => {
  const { auth, handleLogoClick } = useHeaderNav();

  return (
    <>
      <Container>
        <AppBar>
          <LogoAndTitle onClick={handleLogoClick}>{props.logo}</LogoAndTitle>
          {auth.token ? (
            <HeaderContainer>
              {props.conf.sections.map((section, index) => {
                return (
                  <MenuGroup key={`header-group-${section.title}-${index}`}>
                    {section.navigations.map((nav, idx) => {
                      return (
                        <Nav key={`header-nav-${nav.title}-${idx}`}>
                          <CHHeaderNavItem
                            title={nav.title}
                            link={nav.link}
                            isRoot={nav.isRoot}
                          />
                        </Nav>
                      );
                    })}
                  </MenuGroup>
                );
              })}

              <React.Suspense fallback={<>...</>}>
                {auth.token ? <OEUserButton /> : <></>}
              </React.Suspense>
            </HeaderContainer>
          ) : (
            <></>
          )}
        </AppBar>
      </Container>
      <Skelton></Skelton>
    </>
  );
};
