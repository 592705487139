export const queryWithSeachingChildId = (query: any, id: any): any => {
  return {
    ...query,
    search: { "contents.optdata.numeric_resident_register_code": id },
  };
};

export const personalThumbDataQueries = {
  categories: [
    {
      name: "胎児期等",
      query: {
        asset: ["宛名"],
        limit: 20,
        primaryKey: "contents.optdata.numeric_resident_register_code",
        shape: {
          "contents.optdata.numeric_resident_register_code": "any",
          "contents.rawdata.生年月日": "any",
          "contents.rawdata.性別区分ｺｰﾄﾞ短名称": "any",
          "contents.rawdata.氏名ｶﾅ": "any",
          "contents.rawdata.世帯番号": "any",
          妊娠届出業務: {
            asset: ["妊娠届出業務"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 20, //１つ以上のデータがある可能性あり

            order: { "contents.rawdata.届出日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.届出日": "any",
              "contents.rawdata.受診日": "any",
              "contents.rawdata.総合判定（妊娠届）": "any",
            },
          },
          妊婦健診業務: {
            asset: ["妊婦健診業務"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 20,

            order: { "contents.rawdata.受診日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.受診日": "any",
              "contents.rawdata.判定": "any",
            },
          },
          産婦健診業務: {
            asset: ["産婦健診業務"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 20,

            order: { "contents.rawdata.受診日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.受診日": "any",
              "contents.rawdata.受診結果": "any",
            },
          },
        },
      },
    },
    {
      name: "乳幼児期",
      query: {
        asset: ["宛名"],
        limit: 20,
        primaryKey: "contents.optdata.numeric_resident_register_code",
        shape: {
          "contents.optdata.numeric_resident_register_code": "any",
          "contents.rawdata.生年月日": "any",
          "contents.rawdata.性別区分ｺｰﾄﾞ短名称": "any",
          "contents.rawdata.氏名ｶﾅ": "any",
          "contents.rawdata.世帯番号": "any",
          幼児教育無償化: {
            asset: ["幼児教育無償化"],
            relationKey:
              "contents.optdata.numeric_resident_register_code_child",
            limit: 20,

            order: { _created: 1 },
            shape: {
              id: "any",
              "contents.rawdata.無償化事業所名称": "any",
            },
          },
          子育て支援: {
            asset: ["子育て支援"],
            relationKey:
              "contents.optdata.numeric_resident_register_code_child",
            limit: 200,

            order: { "contents.rawdata.実施期間開始年月日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.事業所名称": "any",
              "contents.rawdata.実施期間開始年月日": "any",
              "contents.rawdata.実施期間終了年月日": "any",
              "bases.tags": "any",
            },
          },
          こんにちは赤ちゃん訪問業務: {
            asset: ["こんにちは赤ちゃん訪問業務"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 1,
            order: { _created: 1 },
            shape: {
              id: "any",
              "contents.rawdata.訪問日": "any",
              "contents.rawdata.総合判定（赤ちゃん訪問）": "any",
            },
          },
          母子保健基本業務: {
            asset: ["母子保健基本業務"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 1,

            order: { "contents.rawdata.更新日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.在胎期間": "any",
              "contents.rawdata.母の年齢": "any",
              "contents.rawdata.体重（出生時）": "any",
            },
          },
          "４か月児健診業務": {
            asset: ["４か月児健診業務"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 1,

            order: { "contents.rawdata.受診日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.受診日": "any",
              "contents.rawdata.総合判定": "any",
            },
          },
          乳児健診業務: {
            asset: ["乳児健診業務"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 1,

            order: { "contents.rawdata.受診日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.受診日": "any",
              "contents.rawdata.総合判定": "any",
            },
          },
          "１０か月児健診業務": {
            asset: ["１０か月児健診業務"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 1,

            order: { "contents.rawdata.受診日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.受診日": "any",
              "contents.rawdata.総合判定": "any",
            },
          },
          "１歳６か月児健診業務": {
            asset: ["１歳６か月児健診業務"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 10,
            order: { "contents.rawdata.受診日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.受診日": "any",
              "contents.rawdata.総合判定": "any",
            },
          },
          "２歳児歯科健診業務": {
            asset: ["２歳児歯科健診業務"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 1,

            order: { "contents.rawdata.受診日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.受診日": "any",
              "contents.rawdata.指導事項（歯科）": "any",
              "contents.rawdata.総合判定（精神発達）": "any",
            },
          },
          "３歳児健診業務": {
            asset: ["３歳児健診業務"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 1,

            order: { "contents.rawdata.受診日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.受診日": "any",
              "contents.rawdata.総合判定": "any",
            },
          },
        },
      },
    },
    {
      name: "相談",
      query: {
        asset: ["宛名"],
        limit: 20,
        primaryKey: "contents.optdata.numeric_resident_register_code",
        shape: {
          "contents.optdata.numeric_resident_register_code": "any",
          "contents.rawdata.生年月日": "any",
          "contents.rawdata.性別区分ｺｰﾄﾞ短名称": "any",
          "contents.rawdata.氏名ｶﾅ": "any",
          "contents.rawdata.世帯番号": "any",
          すくすく相談業務: {
            asset: ["すくすく相談業務"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 20,

            order: { "contents.rawdata.相談日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.相談日": "any",
              "contents.rawdata.判定": "any",
            },
          },
          幼児相談業務: {
            asset: ["幼児相談業務"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 20,

            order: { "contents.rawdata.利用日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.利用日": "any",
              "contents.rawdata.判定": "any",
            },
          },
          妊娠期支援業務: {
            asset: ["妊娠期支援業務"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 1,

            order: { "contents.rawdata.相談日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.相談日": "any",
              "contents.rawdata.総合判定": "any",
            },
          },
        },
      },
    },
    {
      name: "予防接種",
      query: {
        asset: ["宛名"],
        limit: 1,
        primaryKey: "contents.optdata.numeric_resident_register_code",
        shape: {
          "contents.optdata.numeric_resident_register_code": "any",
          "contents.rawdata.生年月日": "any",
          "contents.rawdata.性別区分ｺｰﾄﾞ短名称": "any",
          "contents.rawdata.氏名ｶﾅ": "any",
          "contents.rawdata.世帯番号": "any",
          定期予防接種業務: {
            asset: ["定期予防接種業務"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 100,
            order: { _created: 1 },
            shape: {
              id: "any",
              "contents.rawdata.接種・予診日": "any",
              "contents.rawdata.接種回数": "any",
              "contents.rawdata.接種コード": "any",
            },
          },
        },
      },
    },
    {
      name: "手当・助成",
      query: {
        asset: ["宛名"],
        limit: 20,
        primaryKey: "contents.optdata.numeric_resident_register_code",
        shape: {
          "contents.optdata.numeric_resident_register_code": "any",
          "contents.rawdata.生年月日": "any",
          "contents.rawdata.性別区分ｺｰﾄﾞ短名称": "any",
          "contents.rawdata.氏名ｶﾅ": "any",
          "contents.rawdata.世帯番号": "any",
          ひとり親医療費助成: {
            asset: ["ひとり親医療費助成"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 20,

            order: { "contents.rawdata.資格認定年月日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.資格認定年月日": "any",
              "contents.rawdata.資格取得年月日": "any",
              "contents.rawdata.資格喪失年月日": "any",
            },
          },
          児童手当: {
            asset: ["児童手当"],
            relationKey:
              "contents.optdata.numeric_resident_register_code_child",
            limit: 20,

            order: { "contents.rawdata.認定年月日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.認定年月日": "any",
              "contents.rawdata.年度": "any",
              "contents.rawdata.消滅年月日": "any",
            },
          },
          児童扶養手当: {
            asset: ["児童扶養手当"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 20,

            order: { "contents.rawdata.認定日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.認定日": "any",
              "contents.rawdata.喪失日": "any",
              "contents.rawdata.非該当年月日": "any",
            },
          },
          乳幼児医療費助成: {
            asset: ["乳幼児医療費助成"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 20,
            order: { "contents.optdata.non_numeric_grant_date": -1 },
            shape: {
              id: "any",
              "contents.rawdata.資格取得年月日": "any",
              "contents.rawdata.資格満了年月日入院": "any",
              "contents.rawdata.資格満了年月日外来": "any",
            },
          },
          生活保護関係業務: {
            asset: ["生活保護関係業務"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 20,

            order: { "contents.rawdata.開始日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.開始日": "any",
              "contents.rawdata.廃止日": "any",
              "contents.rawdata.増員日": "any",
              "contents.rawdata.減員日": "any",
            },
          },
          "自立支援医療（精神通院）": {
            asset: ["自立支援医療（精神通院）業務"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 20,

            order: { "contents.rawdata.有効期間開始": -1 },
            shape: {
              id: "any",
              "contents.rawdata.有効期間開始": "any",
              "contents.rawdata.有効期間終了": "any",
            },
          },
        },
      },
    },
    {
      name: "障害",
      query: {
        asset: ["宛名"],
        limit: 20,
        primaryKey: "contents.optdata.numeric_resident_register_code",
        shape: {
          "contents.optdata.numeric_resident_register_code": "any",
          "contents.rawdata.生年月日": "any",
          "contents.rawdata.性別区分ｺｰﾄﾞ短名称": "any",
          "contents.rawdata.氏名ｶﾅ": "any",
          "contents.rawdata.世帯番号": "any",
          "障がい者福祉（身障手帳）": {
            asset: ["身体障害者手帳台帳"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 20,

            order: { "contents.rawdata.交付年月日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.交付年月日": "any",
              "contents.rawdata.等級": "any",
              "contents.rawdata.部位名": "any",
              "contents.rawdata.障害名": "any",
              "contents.rawdata.原因名": "any",
            },
          },
          "障がい者福祉（療育手帳）": {
            asset: ["療育手帳交付台帳"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 20,

            order: { "contents.rawdata.交付年月日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.交付年月日": "any",
              "contents.rawdata.障害程度": "any",
            },
          },
          "障がい者福祉（精神手帳）": {
            asset: ["精神保健福祉手帳台帳"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 20,

            order: { "contents.rawdata.交付年月日": -1 },
            shape: {
              id: "any",
              "contents.rawdata.交付年月日": "any",
              "contents.rawdata.等級": "any",
              "contents.rawdata.有効期間終了": "any",
            },
          },
        },
      },
    },
    {
      name: "住基",
      query: {
        asset: ["宛名"],
        limit: 20,
        primaryKey: "contents.optdata.numeric_resident_register_code",
        shape: {
          "contents.optdata.numeric_resident_register_code": "any",
          "contents.rawdata.生年月日": "any",
          "contents.rawdata.性別区分ｺｰﾄﾞ短名称": "any",
          "contents.rawdata.氏名ｶﾅ": "any",
          "contents.rawdata.世帯番号": "any",
          宛名: {
            asset: ["宛名"],
            relationKey: "contents.optdata.numeric_resident_register_code",
            limit: 1,

            order: { "contents.rawdata.異動日": -1 },
            shape: {
              "contents.optdata": "any",
              "contents.rawdata.続柄名称漢字": "any",
              "contents.rawdata.異動事由ｺｰﾄﾞｺｰﾄﾞ短名称": "any",
              "contents.rawdata.異動届出日": "any",
              "contents.rawdata.増減事由ｺｰﾄﾞｺｰﾄﾞ短名称": "any",
              "contents.rawdata.住民区分ｺｰﾄﾞ短名称": "any",
              "contents.rawdata.異動日": "any",
              "contents.rawadata.増減異動日": "any",
            },
          },
        },
      },
    },
  ],
};

export const personalThumbDataCategories =
  personalThumbDataQueries.categories.map((category) => {
    return category.name;
  });
