import { api } from "src/api";

type Props = {
  contractorNo?: string | undefined;
  limit?: number;
  page?: number;
};

export const fetchPipelines = async ({
  contractorNo = undefined,
  limit = 100,
  page = 1,
}: Props) => {
  try {
    const res = await api.get(`/contractors/${contractorNo}/pipelines`, {
      params: {
        q: {
          select: ["*"],
          where: {
            enable: true,
          },
        },
      },
    });
    return res;
  } catch (err) {
    console.error(err);
    return null;
  }
};

//
// オプティマイザーを起動する
//
export const startOptimizer = async ({
  nodeId,
  contractorNo,
  pipelineName,
}: {
  nodeId: string;
  contractorNo: string | undefined;
  pipelineName: string;
}) => {
  try {
    //開始APIを呼ぶ
    const res = await api({
      method: "POST",
      url: `/processes/${nodeId}/start`,
      data: {
        contractorNo,
        pipelineName,
        nodeId,
      },
    });
    return res;
  } catch (err) {
    console.error(err);
    return null;
  }
};

//
//
//
export const stopOptimizer = async ({
  nodeId,
  contractorNo,
  pipelineName,
}: {
  nodeId: string;
  contractorNo: string | undefined;
  pipelineName: string;
}) => {
  try {
    console.log(nodeId);
    console.log(contractorNo);
    console.log(pipelineName);
    const res = await api.delete(`/processes/${nodeId}`, {
      params: {
        contractorNo,
        pipelineName,
        nodeId,
      },

      //@todo クエリにcontractorNo, pipelineId, nodeId, pipelineName
    });
    return res;
  } catch (err) {
    console.error(err);
    return null;
  }
};
