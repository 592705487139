import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { FormControl, CardContent } from "@material-ui/core";
import { ASInputBase, ASButton, ASCard } from "allegro-ui";
import { userState } from "src/root/recoils/userState";
import { useRecoilState } from "recoil";
import bg from "src/fortress/assets/img/black-polygonal.jpg";
import { PTGlobalNav } from "src/portal/components/organisms/PTGlobalNav";
import { headerOffset } from "src/portal/components/organisms/PTGlobalNav";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { PTSignUpPageTemplate } from "src/portal/components/templates/PTSignUpPageTemplate";
import { useSignUpActions } from "src/portal/hooks/useSignUpActions";
import routes from "../portal/routes.json";
import { PTSignUpForm } from "src/portal/components/molecules/PTSignUpForm";
import CHTermsAndPrivacyCaption from "src/cheetah/components/molecules/CHTermsAndPrivacyCaption";

const SignUpNote = styled.div`
  color: ${(props) => props.theme.textColor};
  padding: 23px 0;
  &::before {
    top: 9px;
    /* border-bottom: 1px solid #fff; */
    position: absolute;
    left: 0;
    width: 100%;
    content: "";
  }
`;

const SignUpRegister = styled.div`
  padding: 0 64px;
`;

type Props = {};

export const SignUpPage: React.FC<Props> = ({}) => {
  // const [email, setEmail] = useState(sessionStorage.getItem("userId") || "");
  // const [password, setPassword] = useState("");

  const { signUp } = useSignUpActions();
  const history = useNavigate();

  const handleSubmit = async (email: string, contractor: string) => {
    if (contractor && email) {
      const { success, error } = await signUp(contractor, email);
      if (success) {
        history(routes.PROVISIONEMAIL);
      } else if (error) {
        alert(error);
      } else {
        alert("登録に失敗しました。しばらく経ってからもう一度お試しください。");
      }
    } else {
      alert("入力してください");
    }
  };

  const renderForm = () => {
    return <PTSignUpForm onSubmit={handleSubmit} />;
  };

  return (
    <>
      <PTSignUpPageTemplate formTitle="会員登録" formContent={renderForm()}>
        <CHTermsAndPrivacyCaption />
        <div>
          <SignUpNote>アカウントをお持ちの方</SignUpNote>
          <SignUpRegister>
            <ASButton
              kind="secondary"
              size="large"
              fullWidth
              onClick={() => {
                history("/login");
              }}
            >
              ログイン
            </ASButton>
          </SignUpRegister>
        </div>
      </PTSignUpPageTemplate>
    </>
  );
};
