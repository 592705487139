import { selectorFamily } from "recoil";
import { createnAuthAPI } from "src/api";

export const userIconState = selectorFamily<any, string>({
  key: "ASLowCode/userIconState",
  get:
    (userNo: string) =>
    async ({ get }) => {
      const nauth = createnAuthAPI();
      const userRes = await nauth.getUsersByDataProvider({
        limit: 1,
        search: {
          userNo: Number(userNo),
        },
        shape: {
          userNo: "any",
          "profile.userRealName": "any",
          "profile.avatar": "any",
          avatar: "any",
        },
      });
      const user = userRes[0];
      return user;
    },
});
