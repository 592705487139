import React from "react";
import styled from "styled-components";
import {
  ASValidationError,
  ASLowCodeFormField,
  ASLowCodeFormDefinition,
  ASFormFieldType,
} from "../../models/ASLowCodeFormField";
import { FormLabel, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { ASButton } from "allegro-ui";
import { ASFormLabel } from "../atoms";
import {
  ASTextFormField,
  ASSelectionFormField,
  ASCheckBoxFormField,
  ASChipsFormField,
  ASRadioFormField,
  ASDatePickerFormField,
  ASSectionFormField,
  ASMultilineTextFormField,
  ASNumberFormField,
} from "../molecules";
import { ASSecretFormField } from "../molecules/ASSecretFormField";
import { useAuth } from "allegro-api-hooks";

interface ASLowCodeFormContainerProps {
  key?: string | number;
  kind?: "digicho" | "secondary";
  formDefinition: ASLowCodeFormDefinition;
  fullWidth?: boolean;
  buttonsOptions?: any;
  isUpdate?: boolean;
  isApproval?: boolean;
  defaultValues?: { [key: string]: any };
  editable?: boolean;
  columns?: number;
  requiredMarker?: string;
  optionalMarker?: string;
  onClose?: () => void;
  onValid?: (
    formData: any,
    options?: {
      type?: "submit" | "update" | "approval";
      isUpdate?: boolean;
    }
  ) => Promise<ASValidationError> | ASValidationError;
  onSubmit?: (
    formData: any,
    options?: { isUpdate?: boolean; approval?: boolean },
    callback?: (errorMessage: string) => void
  ) => any;
}

const marginBase = 8;
const FormContainer = styled.div<{ fullWidth?: boolean }>`
  /* border: 1px solid ${(props) => props.theme.secondaryBorderColor}; */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.fullWidth ? "100%" : "80%")};
  height: 100%;
  max-width: ${(props) => (props.fullWidth ? "100%" : "700px")};
  overflow-y: scroll;
  overflow-x: hidden;
  /* padding: 24px; */
`;

const NegativeMessage = styled.span`
  color: ${(props) => props.theme.negativeColor};
`;

const FieldContainer = styled.div`
  /* > * {
    margin-top: ${marginBase * 4}px;
  } */
`;

const SectionTitle = styled.div`
  font-size: 24px;
  color: ${(props) => props.theme.textColor};
  margin-bottom: ${marginBase * 2}px;
  padding: 0 2px;
`;

const SectionDescription = styled.div`
  padding: 0 2px;
  font-size: 14px;
  color: ${(props) => props.theme.descriptionTextColor};
  margin-bottom: ${marginBase * 2}px;
`;

const SubsectionContainer = styled.div`
  margin-top: ${marginBase * 2}px;
  margin-bottom: ${marginBase * 1}px;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;

  > *:last-child {
    margin-left: auto;
  }
  float: right;
`;

const FooterRightBox = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    margin-left: ${marginBase * 2}px;
  }
`;

const StyledGridItem = styled(Grid)`
  /* margin-bottom: 12px; */
`;

const ApproveButton = styled(ASButton)`
  background: ${(props) => props.theme.positiveColor};
  color: white;
  width: 120px;
  height: 40px;
  &:hover {
    background: ${(props) => props.theme.positiveColor};
    filter: brightness(0.9);
  }
`;

const DisApproveButton = styled(ASButton)`
  background: white;
  color: ${(props) => props.theme.negativeColor};
  border-color: ${(props) => props.theme.negativeColor};
  border: 1px solid;
  width: 120px;
  height: 40px;
`;

const Indent = styled.div`
  padding: 0 2px;
  /* margin-top: 20px; */
  margin-bottom: ${marginBase * 2}px;
`;

const formFieldComponents: { [key: string]: any } = {
  text: ASTextFormField,
  selection: ASSelectionFormField,
  checkbox: ASCheckBoxFormField,
  radio: ASRadioFormField,
  secret: ASSecretFormField,
  chip: ASChipsFormField,
  date: ASDatePickerFormField,
  section: ASSectionFormField,
  multiline: ASMultilineTextFormField,
  number: ASNumberFormField,
};

export const ASLowCodeFormContainer: React.FunctionComponent<ASLowCodeFormContainerProps> =
  ({ formDefinition, onClose, ...props }) => {
    const { sections, buttonOptions } = formDefinition;
    const onSubmit = props.onSubmit ?? formDefinition.onSubmit;
    const onValid = props.onValid ?? formDefinition.onValid;
    const columns = props.columns ?? 1;
    const cellSizeX = columns === 2 ? 6 : 12;
    const gridSpacing = 2;
    const editable = props.editable ?? true;

    const [values, setValues] = useState(props.defaultValues);

    const {
      register,
      handleSubmit,
      control,
      watch,
      formState: { errors },
      setValue,
    } = useForm();

    const { contractorName, roles } = useAuth();

    useEffect(() => {
      setValues(props.defaultValues);
    }, [sections, props.defaultValues]);

    const [validMessage, setValidMessage] = useState<string | undefined>();

    const handleSubmitButton = async (
      data: any,
      options?: { isUpdate?: boolean; approval?: boolean }
    ) => {
      if (onValid) {
        const valid = await onValid(data, options);

        if (!valid.error && !valid.message) {
          if (onSubmit) {
            const resData = await onSubmit(data, options);

            if (resData && resData.message) {
              setValidMessage(resData.message);
              console.error("invalid form data.", valid);
            }
          }
        } else {
          setValidMessage(valid.message);
          console.error("invalid form data.", valid);
        }
      } else {
        if (onSubmit) {
          const resData = await onSubmit(data, options);
          if (resData && resData.message) {
            setValidMessage(resData.message);
          }
        }
      }
    };

    const renderFields = (
      fields: ASLowCodeFormField[],
      defaultValues?: { [key: string]: any }
    ) => {
      const key = props.key ?? "as-lc-form-container";
      return (
        <FieldContainer key={key}>
          <Grid container spacing={gridSpacing}>
            {fields.map((f, idx) => {
              switch (f.type) {
                case "skelton":
                  const value = defaultValues
                    ? defaultValues[f.name]
                    : f.active;
                  setValue(f.name, value);
                  // return <SkeltonText>{f.name}</SkeltonText>;
                  return <div key={`as-lowcode-form-skelton-${f.label}`}></div>;
                case "section":
                  return (
                    <Grid
                      key={`as-lowcode-form-section-${f.label}`}
                      item
                      xs={12}
                    >
                      <SubsectionContainer>
                        <ASSectionFormField
                          key={`as-form-field-${key}-${idx}`}
                          title={f.label}
                        />
                      </SubsectionContainer>
                    </Grid>
                  );
                default:
                  const Component: any = formFieldComponents[f.type];

                  return (
                    // TODO xsはnumber型ではないがGridサイズを動的にするにはnumber型が良い
                    <StyledGridItem
                      key={`as-form-field-grid-${key}-${idx}`}
                      item
                      xs={cellSizeX}
                    >
                      <Indent>
                        {f.type in formFieldComponents ? (
                          <Component
                            key={`as-form-field-${key}-${idx}`}
                            design={props.kind ?? "digicho"}
                            control={control}
                            id={`${key}-${idx}`}
                            defaultValue={
                              defaultValues ? defaultValues[f.name] : undefined
                            }
                            requiredMarker={props.requiredMarker}
                            optionalMarker={props.optionalMarker}
                            editable={editable}
                            error={errors[f.name]}
                            active={
                              defaultValues ? defaultValues[f.name] : undefined
                            }
                            {...f}
                          />
                        ) : (
                          <div key={`lc-create-data-form-${key}-${idx}`}>
                            {f.name}
                          </div>
                        )}
                      </Indent>
                    </StyledGridItem>
                  );
              }
            })}
          </Grid>
        </FieldContainer>
      );
    };

    const renderApproveButtons = () => {
      return (
        <FooterRightBox>
          <DisApproveButton
            onClick={handleSubmit((values: any) =>
              handleSubmitButton(values, { approval: false })
            )}
          >
            {props.buttonsOptions?.rejectText ?? "否認"}
          </DisApproveButton>
          <ApproveButton
            onClick={handleSubmit((values: any) =>
              handleSubmitButton(values, { approval: true })
            )}
          >
            {props.buttonsOptions?.approveText ?? "承認"}
          </ApproveButton>
        </FooterRightBox>
      );
    };

    const renderButtons = () => {
      return (
        <FooterRightBox>
          {onClose ? (
            <ASButton kind="secondary" onClick={onClose}>
              {buttonOptions?.cancelText ??
                props.buttonsOptions?.cancelText ??
                "キャンセル"}
            </ASButton>
          ) : (
            <></>
          )}
          {props.isUpdate ? (
            <ASButton
              kind="primary"
              onClick={handleSubmit(
                async (values: any) =>
                  await handleSubmitButton(values, { isUpdate: true })
              )}
            >
              {buttonOptions?.updateText ??
                props.buttonsOptions?.updateText ??
                "更新"}
            </ASButton>
          ) : (
            <ASButton
              kind="primary"
              onClick={handleSubmit((values: any) => {
                handleSubmitButton(values);
              })}
            >
              {buttonOptions?.submitText ??
                props.buttonsOptions?.submitText ??
                "送信"}
            </ASButton>
          )}
        </FooterRightBox>
      );
    };

    const renderSections = () => {
      const key = props.key ?? "as-lc-form-container";
      return sections.map((section, id) => (
        <FormContainer
          key={`section-lc-create-data-form-container-${key}-${id}`}
          fullWidth={props.fullWidth}
        >
          {section.title ? <SectionTitle>{section.title}</SectionTitle> : null}
          {section.description ? (
            <SectionDescription>{section.description}</SectionDescription>
          ) : null}

          <form
            onSubmit={handleSubmit((values: any) => handleSubmitButton(values))}
          >
            {renderFields(section.fields, values)}
            <Footer>
              <NegativeMessage>{validMessage}</NegativeMessage>

              {editable ? (
                props.isApproval ? (
                  renderApproveButtons()
                ) : (
                  renderButtons()
                )
              ) : (
                <></>
              )}
            </Footer>
          </form>
        </FormContainer>
      ));
    };

    return <>{renderSections()}</>;
  };
