import React, { useState, useEffect, useRef } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { oneDark } from "@codemirror/theme-one-dark";
import { javascript } from "@codemirror/lang-javascript";
import { File } from "src/opt-editor/models";

// import "codemirror/keymap/sublime";
// import "codemirror/theme/dracula.css";
// import "codemirror/mode/javascript/javascript";

import { useTextEditor } from "src/opt-editor/recoils";
import { ScienceFile } from "src/opt-editor/models/ScienceFSItem";
import { ASButton } from "allegro-ui";
import { useFileMediator } from "src/opt-editor/hooks/useFileMediator";
import styled from "styled-components";
import scienceFile from "src/opt-editor/recoils/ScienceFileState";

const Container = styled.div`
  position: relative;
`;

const SaveButton = styled(ASButton)`
  position: absolute;
  right: 24px;
  top: 4px;
  z-index: 999;
`;

type Props = {
  file: ScienceFile;
};

export const OECodeEditor: React.FC<Props> = (props, ref) => {
  const [inputText, setInputText] = useState("");
  const [script, setScript] = useState("");
  const file = props.file;
  const fileMediator = useFileMediator();
  const { refresh } = scienceFile.useRefresh();

  useEffect(() => {
    setScript(file.contents);
  }, [file, setScript]);

  //editorはこの中で更新されない。参照になってない。
  function handleChange(value: any, change: any) {
    console.log("value", value);
    setInputText(value);
  }

  const onSave = async () => {
    const newFile: ScienceFile = {
      ...file,
      contents: inputText,
    };
    const success = await fileMediator.update(newFile);
  };

  return (
    <Container>
      <SaveButton
        kind="primary"
        textcolor="#fff"
        style={{ width: "78px", height: "36px" }}
        onClick={() => {
          onSave();
        }}
      >
        SAVE
      </SaveButton>
      <CodeMirror
        value={String(script ?? "")}
        theme={oneDark}
        extensions={[javascript({ jsx: true })]}
        onChange={handleChange}
        // ref={myRef}
      />
    </Container>
  );
};
