import React from "react";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import {
  TableBody,
  Table,
  TableContainer,
  ButtonGroup,
} from "@material-ui/core";
import { ASButton, ASCard, ASTable } from "allegro-ui";
import { KZDailyTableRow } from "src/kinzaza/components";
import styled from "styled-components";

import { DailyThumb, isSortDailyThumbType } from "src/kinzaza/models";
import {
  currentMonitor,
  dailyThumbListSortState,
  monthlyDataCountListState,
} from "src/kinzaza/recoils";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

const StyledCard = styled(ASCard)`
  padding: 12px;
  height: 960px;
`;

const StyledDiv = styled(PerfectScrollbar)`
  background-color: #222;
  border-radius: 8px;
  padding: 4px;
  height: 850px;
  /* overflow-y: scroll; */
`;

const ButtonContainer = styled.div`
  text-align: center;
  padding-top: 13px;
  padding-bottom: 20px;
  width: 100%;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  width: 100%;
`;

const StyledASButton = styled(ASButton)`
  width: 100%;
`;

const WhiteBox = styled.div`
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
`;

type Props = {
  onClick?: (dailyThumb: DailyThumb) => void;
};

export const KZDailyThumbList: React.FC<Props> = (props) => {
  const [sortKey, setSortId] = useRecoilState(dailyThumbListSortState);
  const monitor = useRecoilValue(currentMonitor);
  const dailyThumbsLoadable = useRecoilValueLoadable(monthlyDataCountListState);

  const handleClick = (sortType: string) => {
    if (isSortDailyThumbType(sortType)) {
      setSortId(sortType);
    }
  };

  const color =
    sortKey === "作成日" ? (
      <>
        <StyledButtonGroup>
          <StyledASButton
            kind="primary"
            onClick={() => {
              handleClick("作成日");
            }}
          >
            作成日
          </StyledASButton>
          <StyledASButton
            kind="secondary"
            onClick={() => {
              handleClick("更新日");
            }}
          >
            更新日
          </StyledASButton>
        </StyledButtonGroup>
      </>
    ) : (
      <>
        <StyledButtonGroup>
          <StyledASButton
            kind="secondary"
            onClick={() => {
              handleClick("作成日");
            }}
          >
            作成日
          </StyledASButton>
          <StyledASButton
            kind="primary"
            onClick={() => {
              handleClick("更新日");
            }}
          >
            更新日
          </StyledASButton>
        </StyledButtonGroup>
      </>
    );

  const Buttons =
    monitor.schemaType === "TopData" ? (
      <ButtonContainer>{color}</ButtonContainer>
    ) : (
      <></>
    );

  const dailyThumbs =
    dailyThumbsLoadable.state === "hasValue"
      ? dailyThumbsLoadable.contents
      : [];

  return (
    <StyledCard>
      <WhiteBox>{Buttons}</WhiteBox>
      <StyledDiv>
        <TableContainer component={ASTable}>
          <Table aria-label="simple table">
            <TableBody>
              {dailyThumbs.map((dailyThumb, id) => {
                return (
                  <KZDailyTableRow
                    id={sortKey}
                    dailyThumb={dailyThumb}
                    onClick={(unixTime: number) => {
                      if (props.onClick) {
                        props.onClick(dailyThumb);
                      }
                    }}
                    key={`daily-table-row-${id}`}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledDiv>
    </StyledCard>
  );
};
