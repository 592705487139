import { DefaultTheme } from "styled-components";

export type { DefaultTheme };

export const colorSchemas = {
  digicho: {
    sumi1200: "#000000",
    sumi1100: "#080808",
    sumi1000: "#111111",
    sumi900: "#1A1A1C",
    sumi800: "#414143",
    sumi700: "#626264",
    sumi600: "#757578",
    sumi500: "#949497",
    sumi400: "#B4B4B7",
    sumi300: "#d8d8dB",
    sumi200: "#E8E8EB",
    sumi100: "#F1F1F4",
    sumi50: "#F8F8FB",
  },
};
// sumi900: "#1A1A1C",
// sumi800: "#414143",
// sumi700: "#626264",
// sumi600: "#757578",
// sumi500: "#949497",
// sumi400: "#B4B4B7",
// sumi300: "#d8d8dB",
// sumi200: "#E8E8EB",
// sumi100: "#F1F1F4",
// sumi50: "#F8F8FB",

export const defaultTheme: DefaultTheme = {
  darkMode: false,
  design: "flat",
  regularFontSize: 14,
  regularFontSizeMd: 14,
  regularFontSizeLg: 16,
  labelFontSizeLg: 14,
  labelFontSizeMd: 12,
  supportFontSizeLg: 12,
  supportFontSizeMd: 10,
  buttonFontSize: 16,
  primaryColor: "#FCCC03",
  primaryOnText: "#000000",
  secondaryColor: "#444",
  tertiaryColor: "#000",
  secondaryOnText: "#FFFFFF",
  negativeColor: "#FF4848",
  cautionColor: "#FFAE28",
  positiveColor: "#48B32B",
  borderRadius: 6,
  borderRadiusSm: 4,
  borderRadiusMd: 8,
  borderRadiusLg: 12,
  primaryBorderColor: "#fff",
  secondaryBorderColor: "#999",
  tertiaryBorderColor: "#333",
  borderColorField: "#1A1A1C",
  borderColorDivider: "#d8d8db",
  borderColorFocused: "#D18D0F",
  borderColorDisabled: "#949497",
  bgPrimaryColor: "#F2F2F5",
  bgSecondaryColor: "#F2F2F5",
  bgTertiaryColor: "#F2F2F5",
  cardColor: "#ffffff",
  selectedColor: "#FFFFFF",
  selectedOnText: "#000000",
  textColor: "#cfcfcf",
  disabledColor: "#b4b4b7",
  descriptionTextColor: "#757578",
  placeHolderColor: "#949497",
};

export const fuchuchoTheme: DefaultTheme = {
  ...defaultTheme,
  design: "flat",
  regularFontSizeMd: 14,

  bgPrimaryColor: "#F1F1F4",
  bgSecondaryColor: "#FFFFFF",
  bgTertiaryColor: "#F8F8FB",

  primaryColor: "#3e47f4",
  primaryOnText: "#ffffff",
  secondaryColor: "#FFFFFF",
  secondaryOnText: "#000",
  borderColorField: "#B4B4B7",
  primaryBorderColor: "#6d6d6d",
  secondaryBorderColor: "#cecece",
  tertiaryColor: "#000000",
  borderRadius: 24,
  selectedColor: "#3e47f4",
  selectedOnText: "#ffffff",
  disabledColor: "#b6b6b9",
  textColor: "#1A1A1C",
};

export const neumorphismTheme: DefaultTheme = {
  ...defaultTheme,
  design: "neumorphism",
  regularFontSizeMd: 16,
};

export const flatDarkTheme: DefaultTheme = {
  ...defaultTheme,
  darkMode: true,
  design: "flat",
  primaryOnText: "#FFFFFF",
  cardColor: "#333333",
};

export const alleFavoDarkTheme: DefaultTheme = {
  ...defaultTheme,
  darkMode: true,
  bgPrimaryColor: "#1E1F21",
  bgSecondaryColor: "#2E2E30",
  primaryColor: "#FACB03",
  primaryOnText: "#000000",
  secondaryColor: "#454545",
  secondaryOnText: "#FACB03",
  design: "flat",
  regularFontSizeMd: 14,
  cardColor: "black",
  selectedColor: "#FACB03",
  selectedOnText: "#000000",
  primaryBorderColor: "#979797",
  secondaryBorderColor: "#4A4A4A",
};

export const allegnaTheme: DefaultTheme = {
  ...defaultTheme,
  darkMode: true,
  bgPrimaryColor: "#1E1F21",
  bgSecondaryColor: "#2E2E30",
  primaryColor: "#FFE311",
  primaryOnText: "#2A2B2D",
  secondaryColor: "#4e4e4e",
  secondaryOnText: "#252628",
  design: "flat",
  regularFontSizeMd: 14,
  cardColor: "#2A2B2D",
  borderRadius: 8,
  selectedColor: "#FFE311",
  selectedOnText: "#000000",
};

export const darknessTheme: DefaultTheme = {
  ...defaultTheme,
  darkMode: true,
  bgPrimaryColor: "#000000",
  bgSecondaryColor: "#2E2E30",
  primaryColor: "#efff11",
  primaryOnText: "#2A2B2D",
  secondaryColor: "#4e4e4e",
  secondaryOnText: "#fcfcfc",
  design: "flat",
  regularFontSizeMd: 14,
  cardColor: "#1b1c1d",
  borderRadius: 18,
  selectedColor: "#efff11",
  selectedOnText: "#333333",
};

export const allegrobotClassicTheme: DefaultTheme = {
  ...defaultTheme,
  darkMode: true,
  bgPrimaryColor: "#222222",
  bgSecondaryColor: "#2E2E30",
  primaryColor: "#2A9FD6",
  primaryOnText: "#dddddd",
  secondaryColor: "#4e4e4e",
  secondaryOnText: "#fcfcfc",
  design: "flat",
  regularFontSizeMd: 14,
  cardColor: "#2C2C2C",
  borderRadius: 18,
  selectedColor: "#2A9FD6",
  selectedOnText: "#333333",
};

export const allegrobotNeoTheme: DefaultTheme = {
  ...defaultTheme,
  darkMode: true,
  bgPrimaryColor: "#0d0d0d",
  bgSecondaryColor: "#2E2E30",
  primaryColor: "#d2b70d",
  primaryOnText: "#141414",
  secondaryColor: "#4e4e4e",
  secondaryOnText: "#fcfcfc",
  design: "flat",
  regularFontSizeMd: 14,
  cardColor: "#2A2A2A",
  borderRadius: 2,
  selectedColor: "#d2b70d",
  selectedOnText: "#333333",
};

export const digichoLightTheme: DefaultTheme = {
  ...defaultTheme,
  design: "flat",
  regularFontSizeMd: 14,
  bgPrimaryColor: "#F1F1F4",
  bgSecondaryColor: "#FFFFFF",
  bgTertiaryColor: "#F8F8FB",
  cardColor: "#FFFFFF",
  primaryColor: "#0017C1",
  primaryOnText: "#ffffff",
  secondaryColor: "#FFFFFF",
  secondaryOnText: "#1a1a1c",
  primaryBorderColor: "#6d6d6d",
  secondaryBorderColor: "#cecece",
  tertiaryColor: "#1a1a1c",
  borderRadius: 8,
  borderRadiusSm: 8,
  borderRadiusMd: 16,
  borderRadiusLg: 24,
  selectedColor: "#0017C1",
  selectedOnText: "#ffffff",
  negativeColor: "#EC0000",
  cautionColor: "#C16800",
  positiveColor: "#259D63",
  disabledColor: "#949497",
};

export const spotifyTheme: DefaultTheme = {
  ...defaultTheme,
  darkMode: true,
  bgPrimaryColor: "#171717",
  bgSecondaryColor: "#2E2E30",
  bgTertiaryColor: "#414143",
  primaryColor: "#1ED760",
  primaryOnText: "#141414",
  secondaryColor: "#323232",
  secondaryOnText: "#fcfcfc",
  design: "flat",
  regularFontSizeMd: 14,
  cardColor: "#2A2A2A",
  borderRadius: 24,
  selectedColor: "#1ED760",
  selectedOnText: "#333333",
  primaryBorderColor: "#979797",
  secondaryBorderColor: "#4A4A4A",
  negativeColor: "#FF4B36",
  cautionColor: "#D18D0F",
  positiveColor: "#259D63",
  textColor: "#cfcfcf",
  descriptionTextColor: "#B4B4B7",
  placeHolderColor: "#949497",
  disabledColor: "#757578",
  borderColorField: "#cfcfcf",
  borderColorDisabled: "#757578",
};

export const grapeTheme: DefaultTheme = {
  ...defaultTheme,
  darkMode: true,
  bgPrimaryColor: colorSchemas.digicho.sumi1000,
  bgSecondaryColor: colorSchemas.digicho.sumi900,
  bgTertiaryColor: colorSchemas.digicho.sumi1100,

  primaryColor: "#aa56f8",
  primaryOnText: "#dcdcdc",
  secondaryColor: "#323232",
  secondaryOnText: "#fcfcfc",
  design: "flat",
  regularFontSizeMd: 14,
  cardColor: colorSchemas.digicho.sumi900,
  borderRadius: 24,
  selectedColor: "#aa56f8",
  selectedOnText: "#333333",
  primaryBorderColor: colorSchemas.digicho.sumi800,
  secondaryBorderColor: colorSchemas.digicho.sumi700,
};

export const digichoTheme: DefaultTheme = {
  ...defaultTheme,
  darkMode: true,
  bgPrimaryColor: "#1A1A1C",
  bgSecondaryColor: "#626264",
  primaryColor: "#7096F8",
  primaryOnText: "#141414",
  secondaryColor: "#323232",
  secondaryOnText: "#fcfcfc",
  design: "flat",
  regularFontSizeMd: 14,
  cardColor: "#414143",
  borderRadius: 24,
  selectedColor: "#7096F8",
  selectedOnText: "#333333",
  primaryBorderColor: "#979797",
  secondaryBorderColor: "#4A4A4A",
  negativeColor: "#FF4B36",
  cautionColor: "#D18D0F",
  positiveColor: "#259D63",
};

export const themes: { [name: string]: DefaultTheme } = {
  defaultTheme,
  fuchuchoTheme,
  neumorphismTheme,
  flatDarkTheme,
  alleFavoDarkTheme,
  allegnaTheme,
  darknessTheme,
  allegrobotClassicTheme,
  allegrobotNeoTheme,
  spotifyTheme,
  grapeTheme,
  digichoLightTheme,
  digichoTheme,
};

//theme_nameは'Theme'抜きで指定
//例: defaultTheme -> default
export const getTheme = (theme_name: string): DefaultTheme => {
  if (!themes[`${theme_name}Theme`]) {
    console.error(`[${theme_name}] is undefined theme name.`);
  }
  return themes[`${theme_name}Theme`];
};

export const isTheme = (arg: DefaultTheme): arg is DefaultTheme => {
  return (
    typeof arg.darkMode === "boolean" &&
    typeof arg.primaryColor === "string" &&
    typeof arg.secondaryColor === "string" &&
    typeof arg.negativeColor === "string" &&
    typeof arg.cautionColor === "string" &&
    typeof arg.positiveColor === "string"
  );
};
