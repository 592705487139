import { useCallback } from "react";
import { User } from "src/root/models/user";
import { api } from "src/api";
import { BROWSER_URL } from "src/utils/apiUrl";

const CUSTOM_INVITE_URL = `${BROWSER_URL}/confirm-signup`;

export const useInviteActions = (user: User) => {
  const inviteNewMember = useCallback(async (inviteEmail: string) => {
    try {
      const res = await api({
        method: "post",
        url: `/nauth/invite`,
        data: {
          email: inviteEmail,
          customInviteUrl: CUSTOM_INVITE_URL,
        },
      });
      return res.status === 200;
    } catch (err) {
      return false;
    }
  }, []);

  return { inviteNewMember };
};
