import { NZDataOpsListTemplate } from "../templates/NZDataOpsListTemplate";
import { metricBroweserUi } from "src/cheetah/definitions/listPages/metricBrowserUi";
import { metricCreatingFormDefinition } from "src/cheetah/definitions/forms/metricCreatingFormDefinition";
import { NZDataOpsViewTemplate } from "../templates/NZDataOpsViewTemplate";

export const NZMetricListPage: React.FC = () => {
  return (
    <NZDataOpsViewTemplate title="Metrics">
      <NZDataOpsListTemplate
        name="Metrics"
        browserUi={metricBroweserUi}
        formUi={metricCreatingFormDefinition}
      />
    </NZDataOpsViewTemplate>
  );
};
