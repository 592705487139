import React, { useCallback, useEffect, useState } from "react";
import {
  getBezierPath,
  getEdgeCenter,
  EdgeProps,
  Edge,
} from "react-flow-renderer";
import styled from "styled-components";
import { useSlotActions } from "src/xerver-room/hooks";
import { useMyRole } from "src/portal/hooks/useMyRole";
import { useRecoilValue } from "recoil";
import { cursorPipelineIdState } from "src/xerver-room/recoils";

const StyledForeIgnobject = styled.foreignObject`
  width: 40px;
  height: 40px;
  button {
    display: none;
  }

  &:hover {
    button {
      display: block;
    }
    > body {
      > div {
        display: none;
      }
    }
  }
  > body {
    background: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
  }
`;

const Wrapper = styled.div`
  &:hover {
    /* button {
      display: hidden;
    } */
  }
`;

const Dot = styled.div<{ fillColor: string }>`
  contents: "";
  color: #d8d8d8;
  border-radius: 100px;
  width: 6px;
  height: 6px;
  background: #86e47d;
  background: ${(props) => props.fillColor};
  /* border: 1px solid #86e47d; */
  content: "";
`;

const StyledButton = styled.button`
  background: #1d1c1c;
  color: #d8d8d8;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  border: 2px solid #969696;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  line-height: 1;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
`;

const foreignObjectSize = 40;

type EdgeData = {
  slotId: string;
};

export type HttpEdgeProps = Edge<EdgeData>;

export const XRHttpEdge: React.FC<EdgeProps<EdgeData>> = ({
  id,
  target,
  sourceX,
  sourceY,
  targetX,
  sourceHandleId,
  targetHandleId,
  targetY,
  sourcePosition,
  targetPosition,
  selected,
  style,
  data,
}) => {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const defaultData: EdgeData = {
    slotId: "",
  };

  const { removeConnection } = useSlotActions(data?.slotId ?? "");
  const cursorPipelineId = useRecoilValue(cursorPipelineIdState);
  const role = useMyRole();
  const [canEditPipeline, setCanEditPipeline] = useState(false);

  const udpatePipelineEditableState = useCallback(async () => {
    const ok = await role.canUpdatePipeline(cursorPipelineId);
    setCanEditPipeline(ok);
  }, [cursorPipelineId, role]);
  // const { removeEdge } = useGridEditor();

  useEffect(() => {
    udpatePipelineEditableState();
  }, [udpatePipelineEditableState]);

  const onEdgeClick = (evt: any, id: string) => {
    evt.stopPropagation();
    // removeEdge(id);
    removeConnection(sourceHandleId ?? "", targetHandleId ?? "", target);
  };

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        style={style}
        // markerEnd={markerEnd}
      />
      <StyledForeIgnobject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
      >
        <body>
          {canEditPipeline ? (
            <>
              <Dot fillColor={style?.stroke ?? "#f00"}></Dot>
              <StyledButton onClick={(event) => onEdgeClick(event, id)}>
                ×
              </StyledButton>
            </>
          ) : null}
        </body>
      </StyledForeIgnobject>
    </>
  );
};
