//不足データを表すためのコンポーネント児童詳細ページの上部に現れる。

import styled from "styled-components";
import React from "react";
import { ASCard, ASIconButton } from "allegro-ui";
import CancelIcon from "@material-ui/icons/Cancel";

//3rd party components
import { CardContent } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

//recoils
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  crossChangeState,
  cursorChildThumbState,
} from "../../recoils/singleChildPageState";

type Props = {};

const Container = styled.div`
  margin-bottom: 24px;
  color: ${(props) => props.theme.negativeColor};
`;

const FlexBox = styled.div`
  display: flex;
  line-height: 1.57;
`;

const NegativeText = styled.span`
  color: ${(props) => props.theme.negativeColor};
`;

const Title = styled.h4`
  margin-bottom: 12px;
`;

const Bold = styled.span`
  font-weight: bold;
  color: ${(props) => props.theme.negativeColor};
`;

const StyledButton = styled.div`
  text-align: right;
  margin-bottom: -32px;
  margin-right: -15px;
`;

export const FTNegativeNotifier: React.FC<Props> = (props) => {
  const cursorChildThumb = useRecoilValue(cursorChildThumbState);
  const setCross = useSetRecoilState(crossChangeState);
  const { hasDataShortage, dataShortage } = cursorChildThumb;

  const crossDelete = () => {
    setCross(false);
  };

  const shortages =
    Array.isArray(dataShortage) && dataShortage.length > 0
      ? dataShortage.map((d: string) => {
          return <Bold key={String(d)}>{d}　</Bold>;
        })
      : null;

  return hasDataShortage && shortages ? (
    <Container>
      <StyledButton>
        <ASIconButton kind={"inherit"} onClick={crossDelete}>
          <CancelIcon></CancelIcon>
        </ASIconButton>
      </StyledButton>
      <ASCard>
        <CardContent>
          <FlexBox>
            <div>
              <NegativeText>
                <WarningIcon />
              </NegativeText>
            </div>
            <div>
              <Title>
                <NegativeText>
                  この児童のAI解析を正確に行うためには以下のデータが必要です
                </NegativeText>
              </Title>
              {shortages}
            </div>
          </FlexBox>
        </CardContent>
      </ASCard>
    </Container>
  ) : (
    <></>
  );
};
