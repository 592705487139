import { AxiosInstance } from "axios";

export type GetPromiseValue<T> = T extends Promise<infer U> ? U : never;

export const wrapAsync = <
  T extends unknown[],
  U extends Promise<unknown>,
  K extends GetPromiseValue<U>
>(
  instance: AxiosInstance,
  fn: (instance: AxiosInstance, ...args: T) => Promise<K>
): ((...args: T) => Promise<K>) => {
  const wrapFunc = async (...args: T): Promise<K> => {
    const result = await fn(instance, ...args);
    return result;
  };
  return wrapFunc;
};
