import { config as fuchucityConfig } from "./hiroshima_fuchucity";
import { config as fuchutownConfig } from "./hiroshima_fuchutown";

export const getConfig = (contractor: string): typeof fuchucityConfig => {
  switch (contractor) {
    case "hiroshima_fuchucity":
      return fuchucityConfig;

    case "hiroshima_fuchutown":
      return fuchutownConfig;

    default:
      return fuchucityConfig;
  }
};
