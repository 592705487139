import * as React from "react";
import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { atom, useRecoilState } from "recoil";

import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel, Tooltip } from "@material-ui/core";

import {
  FilterUIConf,
  ASBrowserUiConf,
} from "../../../types/LowCodeUiDefinition";
import { matchFilterState } from "../../../recoils/atoms/filterState";
import { ASButton, ASModal } from "allegro-ui";
import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { createLabelFormUi } from "src/nai-console/config/formUi/createLabelFormUi";

const CategoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;

  margin-top: 24px;
  font-size: 14px;
  margin-bottom: 12px;

  > *:last-child {
    margin-left: auto;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  /* min-height: 44px; */
  /* padding: 8px 0; */
  > * {
    line-height: 1.2;
  }

  .MuiFormControlLabel-label {
    margin-left: 8px;
    font-size: 14px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
  }

  margin-bottom: 12px;
`;

const FilterContainer = styled.div`
  /* height: 30vh;
  overflow-y: scroll; */
  width: 100%;
`;

const StyledCheckBox = styled(Checkbox)`
  color: ${(props) => props.theme.textColor};
  &.Mui-checked {
    color: ${(props) => props.theme.primaryColor};
  }

  &.MuiCheckbox-root {
    padding: 1px;
    margin-left: 8px;
  }
`;

const StyledSubmit = styled.input`
  cursor: pointer;
  font-size: 14px;
  &:hover {
    opacity: 0.9;
  }
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: ${(props) => props.theme.primaryColor};
  /* width: 100%; */
  margin: 12px 0;
  padding: 10px 24px;
  color: ${(props) => props.theme.primaryOnText};
  border: none;
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
`;

const makeQuery = (filterState: any, ui: ASBrowserUiConf) => {
  let result: { $and: any[] } = {
    $and: [],
  };
  const categoryNames = Object.keys(filterState);

  //each category
  for (const categoryName of categoryNames) {
    let orQ: { $or: any[] } = { $or: [] };
    const uiFilter = ui.filters?.find(
      (filter) => filter.label === categoryName
    );

    //each checkbox
    for (const inputName of Object.keys(filterState[categoryName] ?? {})) {
      if (filterState[categoryName][inputName]) {
        //if checked,

        const q = uiFilter?.callback(inputName);

        if (q) result.$and.push(q.$match);
      }
    }

    if (orQ.$or.length > 0) result.$and.push(orQ);
  }

  return result;
};

const toControllerField = (selections: any): { [key: string]: boolean } => {
  let field: { [key: string]: any } = {};
  for (const selection of selections) {
    field[selection.label] = selection.default ?? false;
  }

  return field;
};

const makeDefaultFormData = async (filterUiConf: FilterUIConf[]) => {
  const filterState: { [key: string]: any } = {};

  for (const filter of filterUiConf) {
    if (Array.isArray(filter.selections)) {
      filterState[filter.label] = toControllerField(filter.selections);
    } else {
      const values = await filter.selections();
      filterState[filter.label] = toControllerField(values);
    }
  }
  return filterState;
};

const globalFilterState = atom<any>({
  key: "globalFilterState_medican",
  default: undefined,
});

interface ILCFilterFormProps {
  name: string;
  uiConf: ASBrowserUiConf;
}

const LCFilterForm: React.FunctionComponent<ILCFilterFormProps> = (props) => {
  const { control, handleSubmit } = useForm();

  const filters = props.uiConf.filters;

  const [showForm, setShowForm] = useState(false);
  const [gFilterState, setGFilterState] = useRecoilState(globalFilterState);
  const [matchFilter, setMatchFilter] = useRecoilState(
    matchFilterState(props.name)
  );

  const onSubmit = (data: any) => {
    setGFilterState(data);
  };

  useEffect(() => {
    if (gFilterState) {
      const query = makeQuery(gFilterState, props.uiConf);
      setMatchFilter(query);
    }
  }, [gFilterState, props.uiConf, setMatchFilter]);

  const init = useCallback(async () => {
    const value = await makeDefaultFormData(filters ?? []);
    setGFilterState(value);
  }, [filters, setGFilterState]);

  useEffect(() => {
    init();
  }, [filters, init, setGFilterState]);

  const renderFields = () => {
    if (!gFilterState) return <></>;
    return filters?.map((filter: FilterUIConf, id: number) => {
      switch (filter.design) {
        case "checkbox":
          return (
            <Controller
              key={`lc-filter-form-cb-${id}-${filter.label}`}
              name={filter.label}
              control={control}
              defaultValue={gFilterState[filter.label]}
              render={({ field }) => {
                if (!field) return <></>;
                const cbs = Object.entries(field.value ?? {}).map(
                  ([key, value]) => {
                    return (
                      <Tooltip
                        key={`checkbox-tooltip-${key}`}
                        title={key}
                        placement="right"
                      >
                        <StyledFormControlLabel
                          key={`checkbox-${filter.label}-${key}`}
                          control={
                            <StyledCheckBox
                              checked={
                                typeof value == "boolean" ? value : false
                              }
                              onChange={(e: any) => {
                                field.onChange({
                                  ...field.value,
                                  [key]: e.target.checked,
                                });
                              }}
                              name={key}
                            />
                          }
                          label={key}
                        />
                      </Tooltip>
                    );
                  }
                );
                return (
                  <div>
                    <CategoryHeader>
                      <div>{filter.label}</div>
                      {filter.action?.type === "create" ? (
                        <>
                          <ASButton
                            kind="primary"
                            onClick={() => {
                              setShowForm(true);
                            }}
                          >
                            {filter.action.label}
                          </ASButton>
                          <ASModal
                            open={showForm}
                            onClose={() => {
                              setShowForm(false);
                            }}
                          >
                            <ASLowCodeFormContainer
                              formDefinition={createLabelFormUi}
                              onSubmit={async (data, options) => {
                                if (createLabelFormUi.onSubmit) {
                                  try {
                                    await createLabelFormUi.onSubmit(
                                      data,
                                      options
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }
                              }}
                            />
                          </ASModal>
                        </>
                      ) : (
                        <div></div>
                      )}
                    </CategoryHeader>
                    {cbs}
                  </div>
                );
              }}
            ></Controller>
          );

        default:
          return <div>{filter.label} is error</div>;
      }
    });
  };

  return (
    <>
      {filters ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FilterContainer>{renderFields()}</FilterContainer>
          <StyledSubmit
            type="submit"
            value={props.uiConf.filterCustomSubmitText ?? "適用"}
          />
        </form>
      ) : (
        <></>
      )}
    </>
  );
};

export default LCFilterForm;
