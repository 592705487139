import { atom, selector } from "recoil";
import * as api from "../api";

export const cursorCategoryState = atom<string | undefined>({
  key: "cursorCategoryState",
  default: undefined,
});

export const cursorFormState = atom<string[]>({
  key: "cursorFormState",
  default: [],
});

export const categoriesSelector = selector<string[]>({
  key: "categoriesSelectorState",
  get: async ({ get }) => {
    const [data, _] = await api.newtOut({
      asset: ["症例登録システム"],
      primaryKey: "bases.tags",
      search: {
        "bases.tags": {
          $elemMatch: {
            $regex: `PHR:Category:*`,
          },
        },
      },
      shape: {
        "bases.tags": "any",
      },
      limit: 100,
      skip: 0,
    });

    const categories: string[] = data.map((d: any) => {
      const asset: string[] = d.bases.tags;
      const extAsset = asset.filter((tag) => {
        return new RegExp(`PHR:Category:(.*)`).test(tag);
      });

      return extAsset[0].replace(`PHR:Category:`, "");
    });
    return categories.filter(
      (category, idx) => categories.indexOf(category) === idx
    );
  },
});

export const formsFilterSelector = selector<string[]>({
  key: "formsFilterSelectorState",
  get: async ({ get }) => {
    const category = get(cursorCategoryState);
    if (category) {
      const [data, _] = await api.newtOut({
        asset: ["症例登録システム", `PHR:Category:${category}`],
        search: {
          "bases.tags": {
            $elemMatch: {
              $regex: "PHR:Record:(.*)",
            },
          },
        },
        primaryKey: "bases.tags",
        shape: {
          "bases.tags": "any",
          "contents.optdata.non_numeric_answered_by": "any",
        },
        limit: 100,
        skip: 0,
      });
      const forms = data.map((d: any) => {
        const asset: string[] = d.bases.tags ?? [];
        const extAsset = asset.filter((tag) => {
          return new RegExp(`PHR:Record:(.*)`).test(tag);
        });
        return extAsset[0].replace(`PHR:Record:`, "");
      });

      return forms.filter(
        (formName: string, idx: number) => forms.indexOf(formName) === idx
      );
    }

    return [];
  },
});

export const formsSelector = selector({
  key: "formsSelectorState",
  get: async ({ get }) => {
    const category = get(cursorCategoryState);
    const forms = get(cursorFormState);

    if (category && forms.length > 0) {
      const [data, _] = await api.newtOut({
        asset: ["症例登録システム", `PHR:Category:${category}`],
        search: {
          "bases.tags": {
            $in: forms.map((form) => `PHR:Record:${form}`),
          },
        },
        primaryKey: "id",
        shape: {
          "bases.tags": "any",
          "contents.optdata.non_numeric_answered_by": "any",
          _created: "any",
        },
        limit: 20,
        skip: 0,
      });
      return data;
    }
  },
});
