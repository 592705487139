import { api } from "src/api";

import { PersonalData, createPersonalDataFromJSON } from "src/fortress/models";

import { personalDetailDataQueryFinder } from "../config/fuchucity/personalDetailDataQueries";

/**
 * 指定したカテゴリの個人情報のサムネイルを取得する。
 * @param atena 宛名番号、個人番号
 * @param fileName 業務ファイル名、アセットに登録してあるタグを使用してください。
 * @param index 1対多データは何番めのデータを使用するかindexを指定する。default 0
 * @returns
 */
export const personalDetailData = async (
  atena: string | number,
  fileName: string,
  index: number = 0 //1対多データはindexを指定する。
): Promise<PersonalData[]> => {
  let query = personalDetailDataQueryFinder.byName(fileName);

  console.warn("lgwan では 数字にキャストする");
  query = personalDetailDataQueryFinder.setChildId(query, Number(atena));

  console.log(query);
  const res = await api(`/newtout/aggr`, {
    method: "post",
    data: query,
    params: {
      newtQuery: { data: "useing body" },
    },
  });

  const data = res.data.message.doc[index];
  return data;

  //ここまで
};

/**
 * 指定したカテゴリの個人情報のサムネイルを取得する。
 * @param atena 宛名番号、個人番号
 * @param fileName 業務ファイル名、アセットに登録してあるタグを使用してください。
 * @param index 1対多データは何番めのデータを使用するかindexを指定する。default 0
 * @returns
 */
export const personalDetailDataByHash = async (
  atena: string | number,
  fileName: string,
  hash: string
): Promise<PersonalData | undefined> => {
  let query = personalDetailDataQueryFinder.byName(fileName);

  console.warn("lgwan では 数字にキャストする");
  query = personalDetailDataQueryFinder.setChildId(query, Number(atena));

  console.log(query);
  const res = await api(`/newtout/aggr`, {
    method: "post",
    data: query,
    params: {
      newtQuery: { data: "useing body" },
    },
  });
  let data: PersonalData | undefined = undefined;

  console.log("#######");
  console.log(res.data.message.doc);

  res.data.message.doc.map((d: any) => {
    if (d.id === hash) data = d;
  });
  console.log({ data });
  return data;

  //ここまで
};
