import React, { useState } from "react";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { ASButton, ASIconButton } from "allegro-ui";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ViewColumnOutlinedIcon from "@material-ui/icons/ViewColumnOutlined";
import ViewWeekOutlinedIcon from "@material-ui/icons/ViewWeekOutlined";
import SyncAltOutlinedIcon from "@material-ui/icons/SyncAltOutlined";
import ImportExportOutlinedIcon from "@material-ui/icons/ImportExportOutlined";
import { parsePersonalDataVelue } from "src/fortress/utils/dataShape";

const Container = styled.div`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
`;

const Tr = styled.tr`
  /* padding: 24px; */
`;

const Td = styled.td`
  padding: 3px 24px;
  background: ${(props) => props.theme.cardColor};

  &:first-child {
    padding-left: 24px;
    border-top-left-radius: ${(props) =>
      Math.min(24, props.theme.borderRadius)}px;
    border-bottom-left-radius: ${(props) =>
      Math.min(24, props.theme.borderRadius)}px;
  }
  &:last-child {
    padding-right: 24px;
    border-top-right-radius: ${(props) =>
      Math.min(24, props.theme.borderRadius)}px;
    border-bottom-right-radius: ${(props) =>
      Math.min(24, props.theme.borderRadius)}px;
  }
`;
const THead = styled.thead`
  td {
    padding-left: 24px;
    font-weight: bold;
  }
`;
const TdCenter = styled(Td)`
  text-align: center;
`;

const TdIcon = styled(Td)`
  /* text-align: right; */
  /* width: 24px; */
`;

const TdBold = styled(Td)`
  font-weight: 700;
`;

const TdRight = styled(Td)`
  text-align: right;
`;

const TBody = styled.tbody`
  width: 100%;

  &::before {
    content: "";
    display: block;
    height: 4px;
  }
`;

const IconHeadTd = styled.td`
  width: 94px;
`;

const TitleArea = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.secondaryBorderColor};
  padding-top: 24px;
  > h1 {
    font-size: 18px;
    font-weight: bold;
    margin-left: 24px;
    margin-bottom: 12px;
    /* margin-top: 24px; */
  }
`;

const FlexBox = styled.div`
  display: flex;
  width: 100%;
  overflow-x: scroll;
  > * {
    min-width: 100px;
    flex: 0 0 80px;
  }
`;

const NoDataRow = styled.div`
  background: ${(props) => props.theme.cardColor};
  opacity: 0.4;
  border-radius: ${(props) => Math.min(24, props.theme.borderRadius)}px;
  padding: 8px 24px;
`;

const HorizontalTD = styled.tr`
  display: flex;
  flex-direction: column;
`;

const ButtonGroup = styled.div`
  margin-left: auto;
  > * {
    margin-left: 12px;
  }
`;

export interface IFTTopDataTableViewerProps {
  topdataArray: any[];
  title: string;
  onClick: (dataHash: string) => void;
}

export function FTTopDataTableViewer(props: IFTTopDataTableViewerProps) {
  const topdataArray = props.topdataArray;
  const [layoutMode, setLayoutMode] =
    useState<"vertical" | "horizontal" | string>("vertical");
  const handleLayout = (
    event: React.MouseEvent<HTMLElement>,
    newLayout: string | null
  ) => {
    if (newLayout) setLayoutMode(newLayout);
  };

  console.log(topdataArray);

  return (
    <Container>
      <TitleArea>
        <h1>{props.title}</h1>
        {/* <ButtonGroup>
          <ToggleButtonGroup
            value={layoutMode}
            exclusive
            onChange={handleLayout}
            aria-label="text alignment"
          >
            <ToggleButton value="vertical" aria-label="left aligned">
              <ImportExportOutlinedIcon />
            </ToggleButton>

            <ToggleButton value="horizontal" aria-label="justified">
              <SyncAltOutlinedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </ButtonGroup> */}
      </TitleArea>
      {topdataArray.length === 0 ? (
        <NoDataRow>データがありません</NoDataRow>
      ) : layoutMode === "vertical" ? (
        <Table>
          <THead>
            <tr>
              {Object.entries(topdataArray[0].contents.rawdata).map(
                ([key, value], id) => {
                  return (
                    <td key={`topdatatableviewer-td-${key}-${value}-${id}`}>
                      {key}
                    </td>
                  );
                }
              )}

              <IconHeadTd></IconHeadTd>
            </tr>
          </THead>
          {topdataArray.map((topdata, index) => {
            console.log({ topdata });
            return (
              <TBody key={`topdatatableviewer-tbody-${index}`}>
                {/* 空白のあるデータを表示しない。 */}
                {topdata.contents.rawdata[
                  Object.keys(topdata.contents.rawdata)[0]
                ] !== "" ? (
                  <Tr>
                    {Object.entries(topdata.contents.rawdata).map(
                      ([key, value], id) => {
                        return (
                          <Td key={`topdatatableviewer-tr-${id}`}>
                            {parsePersonalDataVelue(value)}
                          </Td>
                        );
                      }
                    )}

                    <TdIcon>
                      <ASButton onClick={() => props.onClick(topdata.id)}>
                        <OpenInNewIcon />
                      </ASButton>
                    </TdIcon>
                  </Tr>
                ) : (
                  <></>
                )}
              </TBody>
            );
          })}
        </Table>
      ) : (
        <FlexBox>
          <div>
            {Object.entries(topdataArray[0].contents.rawdata).map(
              ([key, value], id) => {
                return (
                  <div key={`topdatatableviewer-contents-1-${id}`}> {key}</div>
                );
              }
            )}
          </div>

          {topdataArray.map((topdata, index) => {
            return (
              <div>
                <div>
                  {Object.entries(topdata.contents.rawdata).map(
                    ([key, value], id) => {
                      return (
                        <div key={`topdatatableviewer-contents-2-${id}`}>
                          {" "}
                          {value}
                        </div>
                      );
                    }
                  )}

                  <TdIcon>
                    <ASButton onClick={() => props.onClick(topdata.id)}>
                      <OpenInNewIcon />
                    </ASButton>
                  </TdIcon>
                </div>
              </div>
            );
          })}
        </FlexBox>
      )}
    </Container>
  );
}
