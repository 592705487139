import * as React from "react";
import { useState } from "react";
import { ASModal } from "allegro-ui";

import {
  ASLowCodeFormContainer,
  ASLowCodeFormDefinition,
} from "allegro-low-code-components";
import {
  ASBrowserUiConf,
  ASLowCodeDataBrowserContainer,
} from "src/cheetah/components/libs/ASLowCodeBrowser";

import styled from "styled-components";
import FTSystemPageTemplate from "src/fortress/components/templates/FTSystemPageTemplate";

const Header = styled.div`
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.textColor};
  > *:last-child {
    margin-left: auto;
  }
`;
interface ICHListPageTemplateProps {
  title: string;
  ui: ASBrowserUiConf;
  formDefinition: ASLowCodeFormDefinition;
  role?: { [key: string]: string };
}

const CHListPageTemplate: React.FunctionComponent<ICHListPageTemplateProps> = (
  props
) => {
  const [open, setOpen] = useState(false);

  const handleModalClose = () => {
    setOpen(false);
  };
  return (
    <>
      <FTSystemPageTemplate>
        <Header>{props.title}</Header>

        <ASLowCodeDataBrowserContainer name="ch-list-page" uiConf={props.ui} />
      </FTSystemPageTemplate>

      <ASModal open={open} onClose={handleModalClose}>
        <ASLowCodeFormContainer
          formDefinition={props.formDefinition}
          onClose={handleModalClose}
        />
      </ASModal>
    </>
  );
};

export default CHListPageTemplate;
