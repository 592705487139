import { ASCard, ASGraph } from "allegro-ui";
import React from "react";
import { useServerStatsViewer } from "src/xerver-room/hooks/useServerStatsViewer";
import styled from "styled-components";
import { LCLoadingBoard } from "src/cheetah/components/libs/ASLowCodeBrowser";
import {
  numToBytesSI,
  numToPercentage,
} from "src/xerver-room/utils/parseStats";
import { Grid } from "@material-ui/core";
import { format } from "date-fns";

const Container = styled.div`
  > * {
    margin-bottom: 25px;
  }
`;

const LoadContainer = styled.div`
  height: 300px;
`;

const GraphRow = styled.div`
  padding: 1px;
`;

const GraphHeader = styled.div`
  padding-left: 0px;
  margin-bottom: 12px;
`;

const GraphContainer = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    flex: 1;
  }
`;

const GraphCard = styled(ASCard)`
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  background: ${(props) => props.theme.cardColor};
  padding: 25px 30px;
  &:hover {
    background: ${(props) => props.theme.cardColor};
    filter: brightness(0.9);
  }
`;

const GRAPH_MARGIN_Y = 45;
const GRAPH_HEIGHT = 200;
const GRAPH_STEPS_X = 2;
const GRAPH_STEPS_Y = 4;
const GRAPH_TICK_SIZE = 7;

export const XRServerStatsViewer: React.FC = () => {
  const {
    allGraphData,
    dummyGraphData,
    minDate,
    maxDate,
    isLoading,
    navigateToContainersPage,
  } = useServerStatsViewer();

  return (
    <Container>
      {!isLoading() ? (
        <Grid container spacing={2}>
          {Object.entries(allGraphData).map(([serverName, allGraph], idx) => {
            return (
              <Grid item key={`grid-graph-${idx}`} xs={6}>
                <GraphCard>
                  <GraphRow
                    onMouseDown={() => {
                      navigateToContainersPage(serverName);
                    }}
                  >
                    <GraphHeader>{serverName}</GraphHeader>
                    <GraphContainer>
                      <ASGraph
                        key={`server-stats-cpu-${serverName}`}
                        title="CPU"
                        xType={allGraph.cpu ? "time" : "linear"}
                        disabledXAxis={!allGraph.cpu}
                        disabledLegend
                        yAxisFormat={numToPercentage}
                        xAxisFormat={(tick: any) =>
                          format(new Date(tick), "H:00")
                        }
                        xTickSize={GRAPH_TICK_SIZE}
                        yTickSize={GRAPH_TICK_SIZE}
                        xsteps={GRAPH_STEPS_X}
                        ysteps={GRAPH_STEPS_Y}
                        yAxisMargin={GRAPH_MARGIN_Y}
                        graphHeight={GRAPH_HEIGHT}
                        graphDatas={
                          allGraph.cpu ? [allGraph.cpu] : dummyGraphData
                        }
                        yRange={[0, 100]}
                        xRange={[minDate, maxDate]}
                      />
                      <ASGraph
                        key={`server-stats-mem-${serverName}`}
                        title="MEM"
                        xType={allGraph.memory ? "time" : "linear"}
                        disabledXAxis={!allGraph.memory}
                        disabledLegend
                        yAxisFormat={numToPercentage}
                        xAxisFormat={(tick: any) =>
                          format(new Date(tick), "H:00")
                        }
                        xsteps={GRAPH_STEPS_X}
                        xTickSize={GRAPH_TICK_SIZE}
                        yTickSize={GRAPH_TICK_SIZE}
                        ysteps={GRAPH_STEPS_Y}
                        yAxisMargin={GRAPH_MARGIN_Y}
                        graphHeight={GRAPH_HEIGHT}
                        graphDatas={
                          allGraph.memory ? [allGraph.memory] : dummyGraphData
                        }
                        yRange={[0, 100]}
                        xRange={[minDate, maxDate]}
                      />
                      <ASGraph
                        key={`server-stats-disk-${serverName}`}
                        title="DISK"
                        xType={allGraph.disk ? "time" : "linear"}
                        disabledXAxis={!allGraph.disk}
                        disabledLegend
                        yAxisFormat={numToBytesSI}
                        graphDatas={
                          allGraph.disk ? [allGraph.disk] : dummyGraphData
                        }
                        xAxisFormat={(tick: any) =>
                          format(new Date(tick), "H:00")
                        }
                        xsteps={GRAPH_STEPS_X}
                        xTickSize={GRAPH_TICK_SIZE}
                        yTickSize={GRAPH_TICK_SIZE}
                        ysteps={GRAPH_STEPS_Y}
                        yAxisMargin={GRAPH_MARGIN_Y}
                        graphHeight={GRAPH_HEIGHT}
                        yRange={[0, null]}
                        xRange={[minDate, maxDate]}
                      />
                    </GraphContainer>
                  </GraphRow>
                </GraphCard>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <LoadContainer>
          <LCLoadingBoard />
        </LoadContainer>
      )}
    </Container>
  );
};
