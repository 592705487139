import { Slot } from "src/xerver-room/models";
import { useLogArea } from "../hooks/useLogArea";

export const handleAxiosError = (error: any) => {
  /**
   * エラーハンドリング
   */
  //   console.log("hoge");
  if (error.response) {
    // サーバーからのレスポンスがある場合
    console.log("レスポンスコード:", error.response.status);
    console.log("レスポンスボディ:", error.response.data);
  } else if (error.request) {
    // リクエストが行われたがレスポンスがない場合
    console.log("リクエストが行われましたが、レスポンスがありません");
  } else {
    // リクエストがすら行われなかった場合
    console.log("リクエストが行われませんでした:", error.message);
  }
};
