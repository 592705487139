import { AxiosInstance } from "axios";

export const deActivateUser = async (
  instance: AxiosInstance,
  email: string,
  userNum: number
): Promise<[boolean, string | undefined]> => {
  if (!email || !userNum) {
    console.error("arg is null.");
  }

  const res = await instance.request({
    method: "DELETE",
    url: `/users/${userNum}/activate`,
    data: { email },
  });

  if (res.status === 200) {
    return [true, undefined];
  } else {
    return [false, "something occured."];
  }
};

export const reActivateUser = async (
  instance: AxiosInstance,
  email: string,
  userNum: number
): Promise<[boolean, string | undefined]> => {
  if (!email || !userNum) {
    console.error("arg is null.");
  }

  const res = await instance.request({
    method: "PUT",
    url: `/users/${userNum}/activate`,
    data: { email },
  });

  if (res.status === 200) {
    return [true, undefined];
  } else {
    return [false, "something occured."];
  }
};
