import React from "react";
import styled from "styled-components";
import { NAGlobalNav } from "../organisms";

const Root = styled.div``;

const Container = styled.div`
  padding: 25px 40px;
`;

type Props = {};

export const NAMainViewTemplate: React.FC<Props> = (props) => {
  return (
    <Root>
      <NAGlobalNav />
      <Container>{props.children}</Container>
    </Root>
  );
};
