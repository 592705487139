import React from "react";
import {
  Routes as Switch,
  Route,
  Navigate,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import routes from "./routes.json";

import { useAuth, PrivateRoute, LoginPage } from "../root";

import { appDefinition, CheetahDefinition } from "./definitions/appDefinition";

export const createListRoutes = (def: CheetahDefinition) => {
  return def.primaryMenu.map((menu, id) => {
    return (
      <Route
        path={menu.path}
        key={`list-cheetah-routes-${id}`}
        element={
          <PrivateRoute>
            <menu.pageComponent />
          </PrivateRoute>
        }
      />
    );
  });
};

export const createSingleRoutes = (def: CheetahDefinition) => {
  return def.primaryMenu.map((menu, id) => {
    return (
      <Route
        key={`single-cheetah-route-${id}`}
        path={`${menu.path}/:itemId`}
        element={
          <PrivateRoute>
            <menu.singlePageComponent />
          </PrivateRoute>
        }
      />
    );
  });
};

const Routes: React.FC = () => {
  const auth = useAuth();
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path={routes.LOGIN}
          element={
            auth.token ? <Navigate to={routes.HOME} replace /> : <LoginPage />
          }
        />
        {createSingleRoutes(appDefinition)}
        {createListRoutes(appDefinition)}
      </>
    )
  );
  //pathは最短マッチされるのでHOMEは一番下にする。
  return <RouterProvider router={router} />;
};

export default Routes;
