import {
  ASLowCodeDataBrowserContainer,
  LCVerticalFiltersContainer,
} from "src/cheetah/components/libs/ASLowCodeBrowser";
import { useGroupMembers } from "src/portal/hooks/useGroupMembers";
import { useGroupSingle } from "src/portal/hooks/useGroupSingle";
import { XRPageTemplate } from "src/xerver-room/components/templates/XRPageTemplate";
import { PTModalContainer, PTButtonGroup } from "../atoms";
import { ASButton, ASIconButton, ASModal } from "allegro-ui";
import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { inviteMemberToGroupDefinition } from "src/portal/ui/forms/inviteMemberToGroupDefinition";
import SettingsOutlined from "@material-ui/icons/SettingsOutlined";
import styled from "styled-components";
import { createGroupDefinition } from "src/portal/ui/forms/createGroupDefinition";

const SubTitle = styled.div`
  margin-bottom: 12px;
  font-weight: bold;
`;

const StyledIconButton = styled(ASIconButton)`
  width: 36.5px;
  height: 36.5px;
  font-size: ${(props) => props.theme.regularFontSize}px;
`;

const GroupDescription = styled.div`
  margin-bottom: 36px;
  font-size: ${(props) => props.theme.regularFontSizeMd}px;
  color: ${(props) => props.theme.textColor};
  line-height: 1.7;
  letter-spacing: 0.04em;
  white-space: pre-wrap;
  max-height: 40vh;
  overflow-y: scroll;
`;

const SearchContainer = styled.div`
  margin-bottom: 12px;
`;

const StyledModalContainer = styled(PTModalContainer)`
  min-height: 32vh;
`;

export type PTSettingGroupSingleTemplateProps = {
  groupId: string;
};

export const PTSettingGroupSingleTemplate: React.FC<PTSettingGroupSingleTemplateProps> =
  (props) => {
    const pageKey = "pt-group-members";
    const { groupId } = props;
    const {
      group,
      myRole,
      showUpdateModal,
      updateGroup,
      handleOpenUpdateModal,
      handleCloseUpdateModal,
    } = useGroupSingle(groupId);

    const {
      browserUi,
      showInviteMemberModal,
      handleOpenInviteModal,
      handleCloseInviteModal,
      handleAddMemberToGroupByEmail,
    } = useGroupMembers(groupId);

    return (
      <XRPageTemplate
        title={group?.group_name ?? "---"}
        withBackButton
        headerComponents={
          <PTButtonGroup>
            <ASButton
              kind="primary"
              onClick={handleOpenInviteModal}
              disabled={!myRole.canInviteGroup}
            >
              Invite Member
            </ASButton>
            <StyledIconButton
              kind="secondary"
              onClick={handleOpenUpdateModal}
              disabled={!myRole.canUpdateGroup}
            >
              <SettingsOutlined />
            </StyledIconButton>
          </PTButtonGroup>
        }
      >
        <SubTitle>Group ID</SubTitle>
        <GroupDescription>{group?.group_id ?? "--"}</GroupDescription>
        <SubTitle>Description</SubTitle>
        <GroupDescription>{group?.description ?? "--"}</GroupDescription>
        <SubTitle>Members</SubTitle>

        <SearchContainer>
          <LCVerticalFiltersContainer uiConf={browserUi} name={pageKey} />
        </SearchContainer>
        <ASLowCodeDataBrowserContainer name={pageKey} uiConf={browserUi} />

        <ASModal
          title="Edit Group"
          open={showUpdateModal}
          onClose={handleCloseUpdateModal}
        >
          <PTModalContainer>
            <ASLowCodeFormContainer
              isUpdate
              fullWidth
              defaultValues={group ?? {}}
              formDefinition={createGroupDefinition}
              onSubmit={async (data) => {
                await updateGroup(data);
              }}
            />
          </PTModalContainer>
        </ASModal>
        <ASModal
          title={`Invite a member to ${group?.group_name}`}
          open={showInviteMemberModal}
          onClose={handleCloseInviteModal}
        >
          <StyledModalContainer>
            <ASLowCodeFormContainer
              key={`pt-setting-invite-group`}
              formDefinition={inviteMemberToGroupDefinition}
              fullWidth
              onSubmit={async (data) => {
                const email = data.email;
                await handleAddMemberToGroupByEmail(email);
              }}
            />
          </StyledModalContainer>
        </ASModal>
      </XRPageTemplate>
    );
  };
