import { ASFormLabel } from "allegro-low-code-components";
import { ASButton, ASIconButton, ASModal, ASSelect } from "allegro-ui";
import { useState } from "react";
import styled from "styled-components";

const StyledIconButton = styled(ASIconButton)`
  width: 30px;
  height: 30px;
  padding: 8px;
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
`;

const NegativeIconButton = styled(StyledIconButton)`
  color: ${(props) => props.theme.textColor};
  background: ${(props) => props.theme.negativeColor};
  &:hover {
    background: ${(props) => props.theme.negativeColor};
    filter: brightness(0.7);
  }
`;

const StyledButton = styled(ASButton)`
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  padding: 8px;
`;

const NegativeButton = styled(StyledButton)`
  color: ${(props) => props.theme.textColor};
  background: ${(props) => props.theme.negativeColor};
  &:hover {
    background: ${(props) => props.theme.negativeColor};
    filter: brightness(0.9);
  }
`;

const ModalContainer = styled.div`
  padding: 25px;
  width: 400px;
  height: 228px;

  display: flex;
  flex-direction: column;

  > *:last-child {
    margin-bottom: 0px;
    margin-top: auto;
  }
`;

const extensionSelections: { label: string; value: string }[] = [
  { label: "CSV", value: "csv" },
  { label: "JSON", value: "json" },
];

type Props = {
  kind?: "primary" | "secondary" | "negative";
  disabled?: boolean;
  onSubmit?: (props: { extensions: "json" | "csv" }) => Promise<void>;
  onClose?: () => void;
};

export const NZDownloadButton: React.FC<Props> = (props) => {
  const ButtonComponent =
    props.kind === "negative" ? NegativeButton : StyledButton;

  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [extensions, setExtension] = useState<"csv" | "json">("json");

  return (
    <>
      <ButtonComponent
        kind="secondary"
        disabled={props.disabled}
        onClick={async () => {
          if (props.onSubmit) {
            await props.onSubmit({ extensions });
          }
        }}
      >
        {props.children}
      </ButtonComponent>
      <ASModal
        title="Download File"
        open={showDownloadModal}
        onClose={() => {
          setShowDownloadModal(false);
        }}
      >
        <ModalContainer>
          <ASFormLabel title="Extensions" style={{ marginBottom: "8px" }} />
          <ASSelect
            value={extensions}
            kind="digicho"
            data={extensionSelections}
            onChange={(e) => {
              const newValue: any = String(e.target.value);
              setExtension(newValue);
            }}
            style={{ marginBottom: "24px" }}
          />
          <ASButton
            kind="primary"
            onClick={async () => {
              if (props.onSubmit) {
                await props.onSubmit({ extensions });
                setShowDownloadModal(false);
              }
            }}
          >
            Download
          </ASButton>
        </ModalContainer>
      </ASModal>
    </>
  );
};

export const NZDownloadIconButton: React.FC<Props> = (props) => {
  const ButtonComponent =
    props.kind === "negative" ? NegativeIconButton : StyledIconButton;

  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [extensions, setExtension] = useState<"csv" | "json">("json");

  return (
    <>
      <ButtonComponent
        kind="secondary"
        disabled={props.disabled}
        onClick={() => {
          setShowDownloadModal(true);
        }}
      >
        {props.children}
      </ButtonComponent>
      <ASModal
        title="Download File"
        open={showDownloadModal}
        onClose={() => {
          setShowDownloadModal(false);
        }}
      >
        <ModalContainer>
          <ASFormLabel style={{ marginBottom: "8px" }}>Extensions</ASFormLabel>
          <ASSelect
            value={extensions}
            kind="digicho"
            data={extensionSelections}
            onChange={(e) => {
              const newValue: any = String(e.target.value);
              setExtension(newValue);
            }}
            style={{ marginBottom: "24px" }}
          />
          <ASButton
            kind="primary"
            onClick={async () => {
              if (props.onSubmit) {
                await props.onSubmit({ extensions });
                setShowDownloadModal(false);
              }
            }}
          >
            Download
          </ASButton>
        </ModalContainer>
      </ASModal>
    </>
  );
};
