import React from "react";
import { NZSettingPageTemplate } from "../templates/NZSettingsPageTemplate";
import { PTSettingTeamTemplate } from "src/portal/components/templates/PTSettingTeamTemplate";
import { roleSelections } from "src/newtzero-console/config/role";

type Props = {};
export const PTSettingTeamPage: React.FC<Props> = (props) => {
  return (
    <>
      <NZSettingPageTemplate>
        <PTSettingTeamTemplate roleSelections={roleSelections} />
      </NZSettingPageTemplate>
    </>
  );
};
