import axios, { AxiosInstance, AxiosResponse } from "axios";

export type AuthProps = {
  contractor: string;
  email: string;
  password: string;
};

export const auth = async (
  instance: AxiosInstance,
  props: AuthProps
): Promise<AxiosResponse<any>> => {
  const res = await instance.get("/auth", {
    params: props,
  });

  return res;
};
