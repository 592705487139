import * as topData from "./topData";
import * as dataManage from "./dataManage";
import { save, commit, fetchData } from "./bundles";

export * from "./http";
export * from "./url";
export * from "./fileGate/fileGate";
export * from "./dataFetcher/dataFetcher";

export const bundles = {
  save,
  commit,
  fetchData,
};

const favyx = {
  topData,
  dataManage,
};

export default favyx;
