import React from "react";
import { NAMainViewTemplate } from "../templates/NAMainViewTemplate";
import { NAAIOperation } from "../organisms";

export const NAAISingleOperationPage: React.FC = () => {
  return (
    <NAMainViewTemplate>
      <NAAIOperation />
    </NAMainViewTemplate>
  );
};
