import React from "react";
import { OEActivityListPageTemplate } from "src/opt-editor/components/templates/OEActivitiyListPageTemplate";
import { NTGlobalNav } from "../organisms/NTGlobalNav";

type Props = {};
export const NTActivityPage: React.FC<Props> = (props) => {
  return (
    <div>
      <NTGlobalNav />
      <OEActivityListPageTemplate />
    </div>
  );
};
