//Libs
import React, { useCallback, useEffect, useState } from "react";
import { PTSettingsPageTemplate } from "../templates/PTSettingsPageTemplate";

//Components

//States

import { PTSettingSecretsTemplate } from "../templates/PTSettingSecretsTemplate";
//Types

type Props = {};
export const PTSettingSecretsPage: React.FC<Props> = (props) => {
  return (
    <>
      <PTSettingsPageTemplate>
        <PTSettingSecretsTemplate />
      </PTSettingsPageTemplate>
    </>
  );
};
