import { ASButton, ASModal, ASSelect, ASSelectItem } from "allegro-ui";
import React, { useState } from "react";
import { decodeUserNoText } from "src/fortress/utils/userNoEdit";
import { RoleSelection } from "src/newtroid-console/models/RoleSelection";
import { useRoleActions } from "src/portal/hooks/useRoleActions";
import { useRoleEditor } from "src/portal/hooks/useRoleEditor";
import styled from "styled-components";

const Contents = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 24px;
`;

const ButtonGroup = styled.div`
  width: 100%;
  margin-top: 48px;

  > * {
    float: right;
    margin-left: 8px;
  }
`;

type Props = {
  show: boolean;
  onClose?: () => void;
  userId: string;
  role?: any;
  roleSelectItems?: ASSelectItem[];
};

export const PTRoleEditModal: React.FC<Props> = (props) => {
  const { show, onClose, userId, role } = props;
  const roleSelectItems = props.roleSelectItems ?? [];
  const { handleSelectRole, handleUpdateSelectedRole } = useRoleEditor(userId);

  return (
    <ASModal open={show} onClose={onClose}>
      <Contents>
        <Title>Change the role of {userId}.</Title>
        <ASSelect
          kind="secondary"
          label="Role"
          data={roleSelectItems}
          onChange={handleSelectRole}
          defaultValue={role?.id}
        />
        <ButtonGroup>
          <ASButton kind="primary" onClick={handleUpdateSelectedRole}>
            Apply
          </ASButton>
          <ASButton kind="secondary" onClick={onClose}>
            Cancel
          </ASButton>
        </ButtonGroup>
      </Contents>
    </ASModal>
  );
};
