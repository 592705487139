//Libs
import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { ASPictureIcon, ASPictureSelectButton } from "allegro-ui";
import React from "react";
import { useSettingProfile } from "src/portal/hooks/useSettingProfile";

import { editProfileFormDefinition } from "src/portal/ui/forms/editProfileFormDefinition";
import { XRPageTemplate } from "src/xerver-room/components/templates/XRPageTemplate";

import styled from "styled-components";

const Container = styled.div`
  color: ${(props) => (props.theme.darkMode ? "white" : "#222")};
  display: flex;
  width: 100%;
`;

const MainCol = styled.div``;

const SubCol = styled.div`
  margin-left: 35px;
`;

const ContractorContainer = styled.div`
  display: inline;
  margin-left: auto;
  text-align: center;
  vertical-align: text-bottom;
  color: ${(props) => props.theme.textColor};
  padding: 4px;
`;

const PictureContainer = styled.div`
  margin-bottom: 35px;
`;

const ProfileIcon = styled.div`
  width: 250px;
  height: 250px;
  margin-top: 24px;
  border-radius: 990px;
  overflow: hidden;
  display: flex;
  > * {
    width: 100%;
  }
`;

type Props = {};
export const PTSettingProfileTemplate: React.FC<Props> = (props) => {
  const { myProfile, handleUpdateProfile, handleUpdateAvatar } =
    useSettingProfile();

  return (
    <XRPageTemplate
      title="Profile"
      headerComponents={
        <>
          <ContractorContainer>
            Contractor: {sessionStorage.contractorNo}
          </ContractorContainer>
        </>
      }
    >
      {myProfile.state === "hasValue" && myProfile.contents ? (
        <Container>
          <MainCol>
            <ASLowCodeFormContainer
              formDefinition={editProfileFormDefinition}
              fullWidth
              onSubmit={handleUpdateProfile}
              defaultValues={{
                ...myProfile.contents,
                userRealName: myProfile.contents.profile.userRealName,
              }}
            />
          </MainCol>
          <SubCol>
            <PictureContainer>
              <h3>Profile picture</h3>
              <ProfileIcon>
                <ASPictureIcon
                  src={myProfile.contents.avatar}
                  defaultSrc={myProfile.contents.avatar}
                  size={250}
                />
              </ProfileIcon>
              <ASPictureSelectButton onChange={handleUpdateAvatar} />
            </PictureContainer>

            <ContractorContainer>
              ID: {myProfile.contents.userNoText}
            </ContractorContainer>
            <div></div>
          </SubCol>
        </Container>
      ) : myProfile.state === "loading" ? (
        <>loading</>
      ) : (
        <>error</>
      )}
    </XRPageTemplate>
  );
};
