import React from "react";
import { useContext, useState } from "react";

type Props = {
  //@todo create a model.
  cursorChild: any;
  handleCursorChild: (data: any) => void;
};

const defaultValue: Props = {
  cursorChild: {},
  handleCursorChild: (data: any) => {},
};

const DataContext = React.createContext<Props>(defaultValue);

type DataContextProviderProps = {
  children?: React.ReactNode;
};

export const DataContextProvider: React.FC<DataContextProviderProps> = ({
  children,
}) => {
  const [cursorChild, setCursorChild] = useState(defaultValue.cursorChild);

  const handleCursorChild = (data: any) => {
    setCursorChild(data);
  };

  const value = {
    cursorChild,
    handleCursorChild,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export const useDataContext = () => {
  return useContext(DataContext);
};
