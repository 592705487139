import { CardContent } from "@material-ui/core";
import favy from "allegro-api";
import { ASCard } from "allegro-ui";
import * as React from "react";
import styled from "styled-components";
import { UserAuthPath } from "../models/userAuthRoutes";
import { PTUserAuthComponent } from "src/portal/components/organisms/PTUserAuthComponent";
import { PTPrepareResetPasswordForm } from "src/portal/components/organisms/PTPrepareResetPasswordForm";
import { CHUserAuthMainViewTemplate } from "../templates/CHUserAuthMainView";
import { BROWSER_URL } from "src/utils/apiUrl";
import { createnAuthAPI } from "src/api";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { LCLoadingBoard } from "../../libs/ASLowCodeBrowser";

const Container = styled.div`
  text-align: left;
  > * {
    margin-top: 24px;
  }
  > *:first-child {
    margin-top: 0px;
  }
  > p {
    line-height: 1.5;
  }
`;

const StyledCard = styled(ASCard)``;

const StyledCardContent = styled(CardContent)`
  width: 100%;
  height: 100%;
`;

interface ICHPrepareResetPasswordPageProps {
  logo: React.Component;
  appName: string;
  routes: UserAuthPath;
}

export const CHPrepareResetPasswordPage: React.FunctionComponent<ICHPrepareResetPasswordPageProps> =
  (props) => {
    const { logo, appName, routes } = props;
    const [isSent, setIsSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const nauth = createnAuthAPI();

    const handleSubmit = async (email: string, contractor: string) => {
      try {
        setIsLoading(true);
        const customResetPasswordUrl = `${BROWSER_URL}/password/rescue`;

        try {
          const res = await nauth.rescuePassword({
            contractor,
            email,
            customResetPasswordUrl: customResetPasswordUrl,
          });

          if (res.status === 200) {
            //送信完了ページに
            setIsSent(true);
            enqueueSnackbar(`メールの送信に成功しました。`, {
              variant: "success",
            });
          } else {
            enqueueSnackbar(`メールの送信に失敗しました。`, {
              variant: "error",
            });

            console.log(res);
          }
        } catch (err: any) {
          console.error(err);
          if (err.response) {
            const message =
              err.response?.status < 500
                ? `コントラクタかメールアドレスが間違っている可能性があります。`
                : `メールの送信に失敗しました。`;
            enqueueSnackbar(message, {
              variant: "error",
            });
          }
        }

        setIsLoading(false);
      } catch (err: any) {
        console.error(err);
      }
    };
    return (
      <>
        <CHUserAuthMainViewTemplate logo={logo}>
          {isSent ? (
            <PTUserAuthComponent
              formTitle="パスワードの再設定"
              formContent={
                <StyledCard>
                  <StyledCardContent>
                    <Container>
                      送信が完了しました。メールをご確認ください。
                    </Container>
                  </StyledCardContent>
                </StyledCard>
              }
            ></PTUserAuthComponent>
          ) : (
            <PTUserAuthComponent
              formTitle="パスワードの再設定"
              formContent={
                <StyledCard>
                  <StyledCardContent>
                    {isLoading ? (
                      <LCLoadingBoard />
                    ) : (
                      <PTPrepareResetPasswordForm onSubmit={handleSubmit} />
                    )}
                  </StyledCardContent>
                </StyledCard>
              }
            ></PTUserAuthComponent>
          )}
        </CHUserAuthMainViewTemplate>
      </>
    );
  };
