//Libs
//Components
//States
//Types

import { defaultInput, defaultOutput, Input, Output } from "./IO";

type ConnectorType =
  | "http"
  | "tcp"
  | "pubsub__NOT_SUPPORTED_YET"
  | "grpc__NOT_SUPPORTED_YET";

export type InputConf = Input & {
  type: ConnectorType;
};

export type OutputConf = Output & {
  type: ConnectorType;
};

export const defaultInputConf: InputConf = {
  ...defaultInput,
  type: "http",
};

export const defaultOutputConf: OutputConf = {
  ...defaultOutput,
  type: "http",
};

export type OptXConfFile = {
  version: number;
  io: {
    inputs: InputConf[];
    outputs: OutputConf[];
  };

  environments: string[];
};

export const defaultOptXConf: OptXConfFile = {
  version: 1,
  io: {
    inputs: [defaultInputConf],
    outputs: [defaultOutputConf],
  },
  environments: [],
};
