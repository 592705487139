import axios, { AxiosInstance } from "axios";
import { DEFAULT_HOST, API_VERSION } from "./statics";

type InitProps = {
  apikey: string;
  contractor: string;
  host?: string;
  version: number;
};

let axiosInstance: null | AxiosInstance = null;

export let apiVersion = API_VERSION;

export const init = ({
  apikey,
  contractor,
  host = DEFAULT_HOST,
  version,
}: InitProps): AxiosInstance => {
  apiVersion = version;
  axiosInstance = axios.create({
    baseURL: `${host}`,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `${apikey}`,
      "User-Id": `${sessionStorage.userNoText}`,
    },
    timeout: 1200000,
  });
  return axiosInstance;
};

export const instance = () => axiosInstance;
