import { atom } from "recoil";

type State = {
  date: number;
  limit: number;
  offset: number;
  json: any;
  count: number;
};

const defaultState: State = {
  date: 0,
  limit: 1,
  offset: 0,
  json: {},
  count: 0,
};

export const JsonViewState = atom<State>({
  key: "JsonViewState",
  default: defaultState,
});
