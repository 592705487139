import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { FormControl, CardContent } from "@material-ui/core";
import { ASInputBase, ASButton, ASCard } from "allegro-ui";
import { userState } from "src/root/recoils/userState";
import { useRecoilState } from "recoil";
import bg from "src/fortress/assets/img/black-polygonal.jpg";
import { PTGlobalNav } from "src/portal/components/organisms/PTGlobalNav";
import { headerOffset } from "src/portal/components/organisms/PTGlobalNav";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { PTRegisterProfileForm } from "../organisms/PTRegisterProfileForm";
import { PTSignUpPageTemplate } from "src/portal/components/templates/PTSignUpPageTemplate";
import { useSignUpActions } from "src/portal/hooks/useSignUpActions";

const APP = process.env.REACT_APP_APP;
const Logo = require(`src/${APP}/components/atoms/logo`).default;
const title = process.env.REACT_APP_TITLE;

const Root = styled.div`
  background-image: url(${bg});
  background-size: cover;
  width: 100%;
  height: calc(100vh - ${headerOffset}px);
  text-align: center;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  padding: 48px 48px;
`;

const StyledCard = styled(ASCard)`
  /* background: #000; */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 6px 6px rgba(0, 0, 0, 0.16);
`;

const Title = styled.h1`
  margin-top: 18px;
  font-size: 24px;
  font-weight: 100;
  margin-bottom: 36px;
  font-weight: bold;
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
`;

const Caption = styled.div`
  padding-bottom: 12px;
  padding: 30px;
  border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};
  font-size: 12px;
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 12px 28px;
  background: ${(props) => props.theme.bgPrimaryColor};
  font-size: 10px;
  color: ${(props) => props.theme.textColor};
  /* text-align: right; */
`;

const SignUpNote = styled.div`
  color: ${(props) => props.theme.textColor};
  padding: 23px 0;
  &::before {
    top: 9px;
    /* border-bottom: 1px solid #fff; */
    position: absolute;
    left: 0;
    width: 100%;
    content: "";
  }
`;

const SignUpRegister = styled.div`
  padding: 0 64px;
`;

type Props = {};

type FormInputs = {
  //1st
  contractor: string;
  email: string;
  pass: string;
  //2nd
  nickName: string;
  firstName: string;
  lastName: string;
  birth: string;
  //3rd
  company: string | undefined;
  country: string;
  zip: string;
  address1: string;
  address2: string;
};

export const PTConfirmEmailPage: React.FC<Props> = ({}) => {
  // const [email, setEmail] = useState(sessionStorage.getItem("userId") || "");
  // const [password, setPassword] = useState("");

  const [confirmed, setConfirmed] = useState(false);
  const { signUpConfirm } = useSignUpActions();
  const history = useNavigate();
  const theme = useTheme();
  const color = theme.darkMode ? "white" : "black";

  const handleSubmit = async (formData: any) => {
    console.log("registering...");
    const ok = await signUpConfirm(formData);
    console.log(ok);
    if (ok) {
      setConfirmed(true);
    } else {
      alert("失敗しました。入力に誤りがないか確認してください。");
    }
  };

  return (
    <>
      <PTSignUpPageTemplate
        formTitle={confirmed ? "本登録完了" : "本登録"}
        formContent={
          confirmed ? (
            <p>本登録が完了しました。</p>
          ) : (
            <PTRegisterProfileForm onSubmit={handleSubmit} />
          )
        }
      >
        {confirmed ? (
          <div>
            <SignUpNote></SignUpNote>
            <SignUpRegister>
              <ASButton
                kind="secondary"
                size="large"
                fullWidth
                onClick={() => {
                  history("/login");
                }}
              >
                ログインへ進む
              </ASButton>
            </SignUpRegister>
          </div>
        ) : (
          <></>
        )}
      </PTSignUpPageTemplate>
    </>
  );
};
