import { useCallback, useEffect, useState } from "react";
import * as api from "src/fortress/api";
import { personalThumbDataCategories } from "../config/fuchucity/personalThumbDataQueries";
import { useRecoilValue } from "recoil";
import { cursorChildIdState } from "src/fortress/recoils/singleChildPageState";

export const usePersonalThumbData = () => {
  const childId = useRecoilValue(cursorChildIdState);
  const [data, _setData] = useState<any>();
  const [cursorCategoryName, _setCursorCategory] = useState<string>(
    personalThumbDataCategories[0]
  );

  //宛名またはカテゴリが変更されたらデータを更新する。
  const categories = personalThumbDataCategories;
  const _updateData = useCallback(async () => {
    //データフェッチ開始

    const resData = await api.personalThumbData(childId, cursorCategoryName);
    //データフェッチ終了

    _setData(resData);
    // _setData(resData);
  }, [cursorCategoryName, childId]);

  useEffect(() => {
    _updateData();
  }, [_updateData]);

  const setCategory = (nextCategory: string) => {
    _setCursorCategory(nextCategory);
  };

  return { data, setCategory, categories, cursorCategoryName };
};
