import { AxiosInstance } from "axios";

/**
 * 
 * @deprecated Assetをタグで指定するのは2024/05/06以降は非推奨
 * @param instance 
 * @param assetTags 
 * @param data 
 * @param upsert 
 * @param upsertTags 
 * @param sendToTopData 
 * @param sendToRawData 
 * @param sendToTxnData 
 * @returns 
 */
export const streamGateByTags = async (
  instance: AxiosInstance,
  assetTags: string[],
  data: any,
  upsert: boolean = true,
  upsertTags: boolean = true,
  sendToTopData: boolean = true,
  sendToRawData: boolean = false,
  sendToTxnData: boolean = false
) => {
  try {
    const res = await instance({
      method: "POST",
      url: `/streamgate`,
      params: {
        apikey: sessionStorage.token,
        upsert: upsert,
        upsertTags: upsertTags,
        assetTags: assetTags,
        sendToTopData: sendToTopData,
        sendToRawData: sendToRawData,
        sendToTxnData: sendToTxnData,
      },
      data: data,
    });
    return res;
  } catch (err) {
    console.error(err);
  }
};





/**
 * 
 * @param instance 
 * @param assetTags 
 * @param data 
 * @param upsert 
 * @param upsertTags 
 * @param sendToTopData 
 * @param sendToRawData 
 * @param sendToTxnData 
 * @returns 
 */
export const streamGateByAssetId = async (
  instance: AxiosInstance,
  assetId:string,
  data: any,

  {upsert =true, upsertTags=true, sendToTopData = true, sendToRawData = false, sendToTxnData = false}:{
    upsert?: boolean ;
    upsertTags?: boolean ;
    sendToTopData?: boolean ;
    sendToRawData?: boolean ;
    sendToTxnData?: boolean ;
  },

) => {
  try {
    const res = await instance({
      method: "POST",
      url: `/streamgate`,
      params: {
        apikey: sessionStorage.token,
        upsert: upsert,
        upsertTags: upsertTags,
        assetId: assetId,
        sendToTopData: sendToTopData,
        sendToRawData: sendToRawData,
        sendToTxnData: sendToTxnData,
      },
      data: data,
    });
    return res;
  } catch (err) {
    console.error(err);
  }
};
