import { useState } from "react";
import { Asset, UploadLogStatus } from "../models";
import * as xlsx from "xlsx";
import { api } from "src/api";
import { useFileUpload } from "src/opt-editor/hooks/useFileUpload";

const sendAsset = async (data: any) => {
  try {
    const res = await api({
      method: "PUT",
      url: `/contractors/${sessionStorage.contractorNo}/assets`,
      params: {
        apikey: sessionStorage.token,
        upsert: true,
      },
      data: data,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

const parseJSON = async (file: File) => {
  console.log(file.name);
  const buffer = await file.arrayBuffer();
  const fileStr = new TextDecoder().decode(buffer);
  return JSON.parse(fileStr);
};

const excel2Json = async (file: xlsx.WorkBook) => {
  const sheetNames = file.SheetNames;
  const assetSheet = file.Sheets[sheetNames[0]];

  const tagPattern = new RegExp("タグ[0-9]*");

  let assets: Asset[] = [];
  if (assetSheet) {
    const assetSheetJson: { [key: string]: any }[] =
      xlsx.utils.sheet_to_json(assetSheet);
    for (const asset of assetSheetJson) {
      const tagKeys = Object.keys(asset).filter((key) => {
        return tagPattern.test(key);
      });

      const tags = tagKeys.map((key) => {
        return asset[key];
      });

      const assetName = asset["アセット名"];

      if (!(assetName && tags.length > 0)) {
        continue;
      }

      assets.push({
        name: assetName,
        tags,
        id: "",
        userNoText: sessionStorage.userNoText,
      });
    }
  }

  return assets;
};

export const assetFileUpload = async (
  file: File
): Promise<UploadLogStatus[]> => {
  let results: any[] = [];
  const extensions = file.name.split(".").at(-1);
  let data: Asset[] | Asset = [];
  switch (extensions) {
    case "json":
      data = await parseJSON(file);
      break;
    case "xlsx":
      const buffer = await file.arrayBuffer();
      const workbook = xlsx.read(buffer, { type: "buffer" });
      data = await excel2Json(workbook);
  }

  if (Array.isArray(data)) {
    for (const d of data) {
      const res = await sendAsset(d);
      results.push({
        status: res?.status ?? -1,
        name: d.name,
      });
    }
  } else {
    const res = await sendAsset(data);
    results.push({
      status: res?.status ?? -1,
      name: data.name,
    });
  }

  return results;
};

export const useAssetUpload = () => {
  const { logs, uploadFiles, upload } = useFileUpload({
    onUpload: assetFileUpload,
  });

  return { logs, upload, uploadFiles };
};
