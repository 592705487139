import { useCallback, useState } from "react";

import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil";

import {
  cursorPipelineIdState,
  slotIdsState,
  slotRequestId,
  slotState,
} from "../recoils";

import { useEffect } from "react";
import { Node, Edge } from "react-flow-renderer";
import { gridSize, gridOffset } from "../components/organisms/XRGridEditorView";
import { CustomNode } from "../components/atoms/XRCustomNode";
import { Slot } from "../models";
import { EdgesState, requestEdgesIdState } from "../recoils/edges";
import { HttpEdgeProps } from "../components/atoms/XRHttpEdge";
import {
  LinkObject,
  useLinkObjectList,
  defaultLinkObject,
} from "./useLinkObjectList";
import produce from "immer";

// type NodeData = {

// };

// type CustomNode = Node<NodeData>;

// export const nodeTypes = {
//   slot: XRCustomNode,
// };

export const httpEdge = (
  s: Slot,
  sourceIOName: string,
  id: string,
  targetSlotId: string,
  targetIOName: string
): HttpEdgeProps => {
  return {
    id: id,
    source: s.slotId ?? "",
    target: targetSlotId ?? "",
    // type: "step",
    style: {
      stroke: s.status.current === "run" ? "#86e47d" : "#f0f0f0",
    },
    animated: true,
    sourceHandle: sourceIOName,
    targetHandle: targetIOName,
    labelStyle: { fill: "#86e47d", fontWeight: 700 },
    type: "httpedge",
    data: { slotId: s.slotId },
  };
};

/**
 *
 * @param slot
 * @returns
 * @TODO slotNodeと共通化する。
 */
export const linkObjectNode = (lo: LinkObject): CustomNode => {
  const immerLinkObject = produce(defaultLinkObject, (draft) => {
    draft.linkObjectId = lo.linkObjectId ?? draft.linkObjectId;
    draft.name = lo.name ?? draft.name;
    draft.pipelineId = lo.pipelineId ?? draft.pipelineId;
    draft.url = lo.url ?? draft.url;
    draft.ui = lo.ui ?? draft.ui;
  });

  const result: CustomNode = {
    id: immerLinkObject.linkObjectId,
    type: "linkObject",
    draggable: true,
    // dragHandle: ".custom-drag-handle",
    data: {
      linkObject: immerLinkObject,
      // label: <XRCustomNode slot={slot}></XRCustomNode>,
    },
    position: {
      x: immerLinkObject.ui.x ?? gridSize * 15 - gridOffset,
      y: immerLinkObject.ui.y ?? gridSize - gridOffset,
    },
  };
  return result;
};

export const slotNode = (slot: Slot): CustomNode => {
  const result: CustomNode = {
    id: slot.slotId,
    type: "slot",
    draggable: true,
    // dragHandle: ".custom-drag-handle",
    data: {
      slot: slot,
      // label: <XRCustomNode slot={slot}></XRCustomNode>,
    },
    position: {
      x: slot.ui.x ?? gridSize * 15 - gridOffset,
      y: slot.ui.y ?? gridSize - gridOffset,
    },
  };
  return result;
};

export const useGrid = () => {
  // const slotList = useRecoilValue(slotListState);
  const [cursorPipelineId, setCursorPipelineId] = useRecoilState(
    cursorPipelineIdState
  );
  const slotIds = useRecoilValue(slotIdsState(cursorPipelineId));
  const requestEdgesId = useRecoilValue(requestEdgesIdState);
  const slotReqId = useRecoilValue(slotRequestId);
  const [nodes, setNodes] = useState<Node[]>([]);
  // const [linkNodes, setLinkNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useRecoilState(EdgesState);

  const linkObject = useLinkObjectList();

  const makeSlotsFromLinkObjects = useCallback(async () => {
    const list = await linkObject.getList();
    const nextLikNodes = list.map((lo) => {
      return linkObjectNode(lo);
    });
    return nextLikNodes;
    // setLinkNodes([...nextLikNodes]);
  }, [linkObject]);

  // useEffect(() => {
  //   makeSlotsFromLinkObjects();
  // }, []); //@TODO makeSlotFromLinkObjectsを[]内に入れてもループしないようにする。

  /**
   * slotIdからnodeを作成する
   */
  const node = useRecoilCallback(({ snapshot }) => {
    return async (slotId: string) => {
      const slot: Slot = await snapshot.getPromise(slotState(slotId));
      const result = slotNode(slot);
      return result;
    };
  }, []);

  const initNodes = useCallback(async () => {
    const result = [];
    for (let i = 0, len = slotIds.length; i < len; ++i) {
      result.push(await node(slotIds[i]));
    }

    const linkNodes = await makeSlotsFromLinkObjects();

    setNodes([...result, ...linkNodes]);
  }, [node, slotIds]);

  /**
   * Nodeを作成する
   */
  useEffect(() => {
    initNodes();
  }, [initNodes, slotReqId]);

  useEffect(() => {
    return () => {
      setCursorPipelineId("");
    };
  }, [setCursorPipelineId]);

  //スロットのエッジを返却する
  const edgesOfSlot = useRecoilCallback(({ snapshot }) => {
    return async (slotId: string, id: number) => {
      const slot: Slot = await snapshot.getPromise(slotState(slotId));

      //各Outputに対し繰り返す

      let result: HttpEdgeProps[] = [];
      if (slot.outputs.length === 0 || !slot.outputs) {
        console.log("no output");
        return result;
      }
      for (const output of slot.outputs) {
        if (!output.targets || output.targets.length === 0) {
          console.log("no target");
        } else {
          const edges: HttpEdgeProps[] = output.targets.map((target) => {
            return httpEdge(
              slot,
              output.name,
              `edge-${slotId}-${output.name}-${target.slotId}-${target.name}` ??
                "undefined-id",
              target.slotId,
              target.name
            );
          });
          result = [...result, ...edges];
        }
      }

      return result;
    };
  }, []);

  const initEdges = useCallback(async () => {
    let result: HttpEdgeProps[] = [];

    //各スロットに繰り返す
    for (let i = 0, len = slotIds.length; i < len; ++i) {
      const edges = await edgesOfSlot(slotIds[i], i);
      result = [...result, ...edges];
    }
    setEdges(result);
  }, [edgesOfSlot, setEdges, slotIds]);

  /**
   * Edgeを作成する
   */
  useEffect(() => {
    initEdges();
  }, [initEdges, requestEdgesId]);

  const removeEdge = async (edgeId: string) => {
    const newEdges = edges.filter((e: Edge) => {
      if (e.id !== edgeId) {
        return true;
      } else {
        return false;
      }
    });

    setEdges(newEdges);
  };

  return {
    nodes,
    // linkNodes,
    edges,
    removeEdge,
  };
};
