import React from "react";
import styled from "styled-components";

import { NZGlobalNav } from "../organisms/NZGlobalNav";
import { NZTitle } from "../atoms/NZTitle";
import { ASIconButton } from "allegro-ui";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { useNavigate } from "react-router-dom";
import { NZPrivatePageTemplate } from "./NZPrivatePageTemplate";

const Wrapper = styled.div`
  padding: 30px;
  color: ${(props) => props.theme.secondaryOnText};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-bottom: 18px;
  }
`;

const StyledButton = styled(ASIconButton)`
  margin-right: 8px;
`;

export const NZDataOpsViewTemplate: React.FC<{
  title?: string;
  withBackButton?: boolean;
}> = (props) => {
  const history = useNavigate();
  const backPage = () => {
    history(-1);
  };

  return (
    <NZPrivatePageTemplate title={props.title}>
      <Wrapper>
        <>{props.children}</>
      </Wrapper>
    </NZPrivatePageTemplate>
  );
};
