//
//モニター一覧のコンポーネント。
//例えば、虐待予防的支援業務というモニターを選んだら、tagが虐待予防的支援業務のデータを詳しく見ることができる。
//このkinzazaはデータ管理ツールである。
//

//
//third party libs
//
import React, { useEffect } from "react";
import styled from "styled-components";
//
//components
//
import { ASIconButton, ASPageNav } from "allegro-ui";
import { MonitorList } from "../organisms";
import AddIcon from "@material-ui/icons/Add";
//
//other libs
//
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  createModalState,
  monitorListRequestId,
  pageId,
  windowSizePagination,
} from "src/kinzaza/recoils";
import { useHomePage } from "src/kinzaza/hooks";
import { LoadingBox } from "src/opt-editor/components/atoms/OELoddingBox";
import { ASLowCodeDataBrowserContainer } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { alertJobBrowserUi } from "src/cheetah/definitions/listPages/alertJobBrowserUi";

const Root = styled.div`
  position: relative;
  background: ${(props) => props.theme.bgPrimaryColor};
  min-height: 100%;
  width: 100%;
  color: ${(props) => (props.theme.darkMode ? "#eee" : "#000")};
  /* padding: 24px; */
  display: flex;
  flex-direction: column;
`;

const AddIconButton = styled(ASIconButton)`
  border-radius: 100px;
`;

const Main = styled.div`
  /* flex: 1; */
  /* overflow-y: scroll; */
  /* padding-top: 30px; */
`;

const MainPageFooter = styled.div`
  margin-top: 20px;
`;
const RightBottomContent = styled.div`
  position: absolute;
  bottom: 15px;
  right: 24px;
`;

const StyledPagination = styled.div`
  display: flex;
  justify-content: center;
`;

type Props = {};

export const NZAlertJobBrowserTemplate: React.FC<Props> = (props) => {
  const setLimit = useSetRecoilState(windowSizePagination);

  const { monitors, handleCreate, pageCount, isLoading } = useHomePage();

  const [page, setPage] = useRecoilState(pageId);
  const setReqId = useSetRecoilState(monitorListRequestId);

  const setShowCreateModal = useSetRecoilState(createModalState);

  //モニター作成用モーダルを開く処理。
  const handleCreateModalOpen = () => {
    setShowCreateModal(true);
  };

  useEffect(() => {}, []);

  //モニター一覧のページネーションをする関数
  const hundlePage = (event: any, _page: number) => {
    console.log(_page);
    setPage((prev) => _page);
    setReqId((prev) => prev + 1);
  };

  //画面サイズによってページネーションの表示数を変える処理
  useEffect(() => {
    if (window.matchMedia("(max-width: 768px)").matches) {
      setLimit(5);
    } else if (window.matchMedia("(max-width: 992px)").matches) {
      setLimit(10);
    } else if (window.matchMedia("(max-width: 1200px)").matches) {
      setLimit(15);
    } else {
      setLimit(20);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Root>
        <ASLowCodeDataBrowserContainer
          name={`nz-alertjob-browser`}
          uiConf={alertJobBrowserUi}
        />
      </Root>
    </>
  );
};
