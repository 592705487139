//Libs
import React, { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
//Components
import { FormControl, CardContent } from "@material-ui/core";
import { ASInputBase, ASButton, ASCard } from "allegro-ui";
import { useLocation } from "react-router-dom";
import { userState } from "src/root/recoils/userState";
import { useRecoilState } from "recoil";
import bg from "src/fortress/assets/img/black-polygonal.jpg";
import { PTGlobalNav } from "src/portal/components/organisms/PTGlobalNav";
import { headerOffset } from "src/portal/components/organisms/PTGlobalNav";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
//States
//Types

const APP = process.env.REACT_APP_APP;
const Logo = require(`src/${APP}/components/atoms/logo`).default;
const title = process.env.REACT_APP_TITLE;

type ActionTypes =
  | "contractor"
  | "userRealName"
  | "confirmNumber"
  | "email"
  | "password"
  | "phoneNum"
  | "address";

type Action = {
  type: ActionTypes;
  payload: string | number;
};

type Props = {
  onSubmit: (formData: any) => void;
};

type URLQuery = {
  contractor?: string;
  email?: string;
};

const Form = styled.div`
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 100%;
  width: 400px;
  display: flex;
  flex-direction: column;
  text-align: left;
  > * {
    margin-top: 24px;
  }
`;
const StyledButton = styled(ASButton)`
  margin-top: 36px;
`;

const StyledSpan = styled.div`
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
  margin-bottom: 8px;
`;

const ForgotPassword = styled.a`
  font-size: 14px;
  color: ${(props) => props.theme.primaryColor};
`;

export const PTRegisterProfileForm: React.FC<Props> = (props) => {
  const [step, setStep] = useState(0);
  const [form, setForm] = useState<any>({});

  const search = useLocation().search;

  const query = new URLSearchParams(search);

  useEffect(() => {
    setForm({
      email: query.get("email"),
      contractor: query.get("contractor"),
    });
  }, []);

  const maxStep = 1;

  const handleNext = () => {
    setStep((s) => (s + 1 < maxStep ? s + 1 : maxStep));
  };

  const handlePreview = () => {
    setStep((s) => (s - 1 >= 0 ? s - 1 : 0));
  };

  const setEmail = (e: any) => {};

  const handleChange = (action: Action) => {
    setForm({
      ...form,
      [action.type]: action.payload,
    });
  };

  const handleSubmit = async () => {
    props.onSubmit(form);
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <Form>
            <FormControl>
              <StyledSpan>確認コード</StyledSpan>
              <ASInputBase
                id="confirmNo"
                kind="outline"
                placeholder="入力してください"
                defaultValue=""
                onChange={(e) => {
                  handleChange({
                    type: "confirmNumber",
                    payload: e.target.value,
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <StyledSpan>コントラクタ</StyledSpan>
              <ASInputBase
                id="contractor"
                kind="outline"
                placeholder="入力してください"
                defaultValue={query.get("contractor") ?? ""}
                onChange={(e) => {
                  handleChange({
                    type: "contractor",
                    payload: e.target.value,
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <StyledSpan>メールアドレス</StyledSpan>
              <ASInputBase
                id="email"
                kind="outline"
                placeholder="入力してください"
                defaultValue={query.get("email") ?? ""}
                onChange={(e) => {
                  handleChange({
                    type: "email",
                    payload: e.target.value,
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <StyledSpan>パスワード</StyledSpan>
              <ASInputBase
                id="password"
                type="password"
                kind="outline"
                placeholder="入力してください"
                defaultValue=""
                onChange={(e) => {
                  handleChange({
                    type: "password",
                    payload: e.target.value,
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <StyledSpan>フルネーム</StyledSpan>
              <ASInputBase
                id="userRealName"
                kind="outline"
                placeholder="入力してください"
                defaultValue=""
                onChange={(e) => {
                  handleChange({
                    type: "userRealName",
                    payload: e.target.value,
                  });
                }}
              />
            </FormControl>
            <FormControl>
              <StyledSpan>電話番号</StyledSpan>
              <ASInputBase
                id="phoneNum"
                kind="outline"
                placeholder="入力してください"
                defaultValue=""
                onChange={(e) => {
                  handleChange({
                    type: "phoneNum",
                    payload: e.target.value,
                  });
                }}
              />
            </FormControl>
            <FormControl>
              <StyledSpan>住所</StyledSpan>
              <ASInputBase
                id="address"
                kind="outline"
                placeholder="入力してください"
                defaultValue=""
                onChange={(e) => {
                  handleChange({
                    type: "address",
                    payload: e.target.value,
                  });
                }}
              />
            </FormControl>

            <StyledButton
              kind="primary"
              onClick={(e: any) => {
                handleSubmit();
              }}
              size="large"
            >
              登録
            </StyledButton>
          </Form>
        );
      case 1:
        return (
          <Form>
            <StyledButton
              kind="secondary"
              onClick={(e: any) => {
                handlePreview();
              }}
              size="large"
            >
              前へ
            </StyledButton>
          </Form>
        );
      default:
        <></>;
    }
  };
  return <>{renderStep()}</>;
};
