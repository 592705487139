import React from "react";
import styled from "styled-components";

interface MeterProps {
  readonly value: number;
  readonly color?: string;
}

export type ASSliderVisProps = {
  value: number;
  color?: string;
};

const Container = styled.div`
  position: relative;
  width: 70px;
  height: 15px;
`;

const Meter = styled.div<MeterProps>`
  border-radius: ${(props) => props.theme.borderRadius}px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 15px;
  background: #e0e0e0;
  &::before {
    content: "";
    width: ${({ value }) => value * 100}%;
    height: 15px;
    background: ${(props) => (props.theme.darkMode ? "#FFCC00" : props.color)};
    border-radius: ${(props) => props.theme.borderRadius}px;
    position: absolute;
    left: 0;
    top: 0;
  }
`;
export const ASSliderVis: React.FC<ASSliderVisProps> = (props) => {
  const { value, color } = props;
  return (
    <Container>
      <Meter value={value} color={color} />
    </Container>
  );
};
