import React from "react";

import { FormControlLabel, Radio } from "@material-ui/core";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  ReferenceData,
  createReference,
  getReferenceValue,
} from "../../models/DynamicReference";

function stringToBoolean(value: string | null | undefined): boolean {
  // null、undefined は false
  if (!value) {
    return false;
  }
  // 小文字にして判定
  return value.toLowerCase() === "true" || value === "1";
}

const StyledFormLabel = styled(FormControlLabel)`
  color: ${(props) => props.theme.textColor};
  font-size: ${(props) => props.theme.regularFontSizeMd};
  .MuiFormControlLabel-label {
    color: ${(props) => props.theme.textColor};
    font-size: ${(props) => props.theme.regularFontSizeMd};
  }

  .Mui-disabled {
    color: ${(props) => props.theme.disabledColor};
  }
`;

const StyledRadio = styled(Radio)`
  color: ${(props) => props.theme.primaryColor};

  &.Mui-checked {
    color: ${(props) => props.theme.primaryColor};
  }

  &.Mui-disabled {
    color: ${(props) => props.theme.disabledColor};
  }

  &.MuiCheckbox-root {
    padding: 1px;
    margin-left: 8px;
  }
`;

interface ILCCheckboxFieldComponentProps {
  name: string;
  active: any;
  values: any;
  editable?: boolean;
  onChange: (active: any) => void;
}

export const ASRadioFormFieldComponent: React.FunctionComponent<ILCCheckboxFieldComponentProps> =
  ({ active, onChange, ...props }) => {
    const [initialized, setInitialized] = useState(false);
    const [value, setValue] = useState<any>(active);
    const [selections, setSelections] = useState<ReferenceData[]>([]);
    const editable = props.editable ?? true;
    const values =
      typeof props.values === "string"
        ? createReference(props.values)
        : props.values;
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = ["true", "false"].includes(e.target.value)
        ? stringToBoolean(e.target.value)
        : e.target.value;

      if (value === val) {
        setValue(undefined);
        onChange(undefined);
        return;
      } else if (editable) {
        setValue(val);
        onChange(val);
      }
    };

    const init = async () => {
      const selections = await getReferenceValue(values);
      if (selections.length > 0) {
        setSelections(selections);
      }
      setInitialized(true);
    };

    useEffect(() => {
      if (!initialized) init();
    }, []);

    return (
      <>
        {selections.length > 0 ? (
          selections.map((selection, id) => {
            return (
              <StyledFormLabel
                key={`radio-${selection.label}-${selection.value}-${id}`}
                control={
                  <StyledRadio
                    checked={value === selection.value}
                    value={selection.value}
                    disabled={!editable}
                    name={selection.label}
                    onChange={handleChange}
                  />
                }
                label={selection.label}
                disabled={!editable}
              />
            );
          })
        ) : (
          <>Loading...</>
        )}
      </>
    );
  };
