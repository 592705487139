import styled from "styled-components";

import { Activity } from "../../models";

import { ASTableRow } from "allegro-ui";
import { darken, TableCell } from "@material-ui/core";
import { OEAddChip, OEUpdateChip, OECommitChip, OEDeleteChip } from "../atoms";

type Props = {
  activity: Activity;
};

const StyledTableRow = styled(ASTableRow)`
  /* background-color: ${(props) => props.theme.secondaryColor}; */
  background: #303030;
  height: 60px;
  /* border-radius: ${(props) => props.theme.borderRadius}; */
  border-radius: 20%;
`;

const Project = styled.div`
  font-size: 8px;
  color: ${darken("#fff", 0.3)};
`;

export const OEActivityListItem: React.FC<Props> = (props) => {
  const { type, title, projectID, projectName, date, message } = props.activity;

  //一つの部品（列）を押すと、そのアセスメント詳細画面に飛ぶことを表している。

  const handleRowClick = () => {};

  const chip = () => {
    switch (type) {
      case "add":
        return <OEAddChip />;
      case "delete":
        return <OEDeleteChip />;
      case "commit":
        return <OECommitChip />;
      case "update":
        return <OEUpdateChip />;
    }
  };
  return (
    <StyledTableRow onClick={() => handleRowClick()}>
      <TableCell>{chip()}</TableCell>
      <TableCell>
        <Project>{projectName}</Project>
        {title}
      </TableCell>
      <TableCell>{date}</TableCell>
      <TableCell>{message}</TableCell>
    </StyledTableRow>
  );
};
