import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  ASIconButton,
  ASSwitchButton,
  ASTableRow,
  ASUserIcon,
} from "allegro-ui";
import { TableCell } from "@material-ui/core";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import favy from "allegro-api";
import { PTRoleEditModal } from "../organisms/PTRoleEditModal";
import { RoleSelection } from "src/newtroid-console/models/RoleSelection";

type Props = {
  src: string;
  userName: string;
  columns: string[];
  userId: string;
  activated: boolean;
  editableRole?: boolean;
  email: string;
  userNum: number;
  onClick?: (userId: string) => void;
  role?: any;
  roleSelections?: RoleSelection[];
};

const StyledTableRow = styled(ASTableRow)`
  /* background-color: ${(props) => props.theme.cardColor}; */
  color: ${(props) => props.theme.textColor};
  height: 60px;
  border-radius: 20%;
  > td {
    padding: 2px;
  }
`;

export const PTUserListItem: React.FC<Props> = ({
  src,
  columns,
  userName,
  userId,
  onClick,
  activated,
  editableRole,
  userNum,
  email,
  role,
  roleSelections,
}) => {
  const history = useNavigate();

  const [showRoleEdit, setShowRoleEdit] = useState(false);

  const handleActivate = async () => {
    if (activated) {
      await favy.deActivateUser(email, userNum);
      window.location.reload();
    } else {
      await favy.reActivateUser(email, userNum);
      window.location.reload();
    }
  };

  return (
    <StyledTableRow>
      <TableCell>
        <ASUserIcon avatar={src} userName={userName} />
      </TableCell>

      {columns.map((text, id) => {
        return <TableCell key={`user-list-table-cell-${id}`}>{text}</TableCell>;
      })}
      <TableCell>
        <ASIconButton
          disabled={!editableRole}
          kind="secondary"
          onClick={() => {
            setShowRoleEdit(true);
            if (onClick) onClick(userId);
          }}
        >
          <SettingsOutlinedIcon />
        </ASIconButton>
      </TableCell>
      <TableCell>
        <ASSwitchButton
          checked={activated}
          onChange={handleActivate}
          name="checkedA"
        ></ASSwitchButton>
      </TableCell>
      <PTRoleEditModal
        show={showRoleEdit}
        onClose={() => setShowRoleEdit(false)}
        userId={userId}
        role={role}
        roleSelectItems={roleSelections}
      />
    </StyledTableRow>
  );
};
