import { ASLowCodeFormDefinition } from "allegro-low-code-components";

export const editProfileFormDefinition: ASLowCodeFormDefinition = {
  sections: [
    {
      title: "",
      fields: [
        {
          label: "Name",
          name: "userRealName",
          type: "text",
          required: true,
          active: "",
          values: undefined,
          errorText: "This is required form.",
          placeholder: "name",
        },
        {
          label: "Email",
          name: "email",
          type: "text",
          required: true,
          active: "",
          values: undefined,
          placeholder: "email",
          errorText: "This is required form.",
        },
        {
          label: "Phone Number",
          name: "phoneNumber",
          type: "text",
          required: true,
          active: "",
          values: undefined,
          placeholder: "e.g. 090-1111-2222",
          errorText: "This is required form.",
        },
        {
          label: "Zip",
          name: "zip",
          type: "text",
          required: true,
          active: "",
          values: undefined,
          placeholder: "e.g. 730-0051",
          errorText: "This is required form.",
        },
        {
          label: "Address",
          name: "address",
          type: "text",
          required: true,
          active: "",
          values: undefined,
          placeholder: "e.g. 8-3-1 bldg 11F Otemachi Naka-ku Hiroshima, Japan",
          errorText: "This is required form.",
        },
      ],
    },
  ],
  buttonOptions: {
    submitText: "Update profile",
  },
};
