import * as React from "react";
import { IconButton } from "@material-ui/core";
import { ASInputBase } from "allegro-ui";
import SearchIcon from "@material-ui/icons/Search";
import styled from "styled-components";

const Search = styled.div`
  background: ${(props) => props.theme.secondaryColor};
  margin-left: auto;
  position: relative;
  border-radius: ${(props) => props.theme.borderRadius}px;
  padding: 0 8px;
  min-height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  > *:last-child {
    margin-left: auto;
  }
`;

interface IFTSearchFieldProps {
  onChange: any;
  onSubmit: any;
}

const FTSearchField: React.FunctionComponent<IFTSearchFieldProps> = (props) => {
  const [word, setWord] = React.useState<string>("");

  return (
    <>
      <Search>
        <ASInputBase
          kind="secondary"
          placeholder="Search"
          onChange={(event) => {
            props.onChange(event.target.value);
            setWord(event.target.value);
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              // エンターキー押下時の処理
              props.onSubmit(word);
            }
          }}
        />
        <IconButton
          size="small"
          type="submit"
          aria-label="search"
          onClick={() => {
            props.onSubmit(word);
          }}
        >
          <SearchIcon />
        </IconButton>
      </Search>
    </>
  );
};

export default FTSearchField;
