import { useState, useEffect, useCallback } from "react";
import { api } from "src/api/http";

export type Query = {
  select: string[];
  where: { [key: string]: any };
  limit?: number;
  skip?: number;
  orderby?: { [key: string]: any };
};

export type PlotData = {
  numeric_resident_register_code: number;
  numeric_prediction1_value: number;
  numeric_prediction2_value: number;
  non_numeric_datagroup_id: number;
  non_numeric_percentage_group: number;
  numeric_group_coord_x: number;
  numeric_group_coord_y: number;
  numeric_abused_record: number;
};

export type PlotDatas = PlotData[];

export const defaultPlotData: PlotData = {
  numeric_resident_register_code: -999999,
  numeric_prediction1_value: -100,
  numeric_prediction2_value: -100,
  non_numeric_datagroup_id: -100,
  non_numeric_percentage_group: -100,
  numeric_group_coord_x: -100,
  numeric_group_coord_y: -100,
  numeric_abused_record: -100,
};

class TopDataAutoIterator {
  isEnd: boolean;
  fetchSubs: Array<(data: PlotDatas) => void>;
  endSubs: Array<() => void>;
  limit: number;
  currentPage: number;
  query: Query;
  totalCount: number;
  constructor(query: Query) {
    this.isEnd = false;
    this.fetchSubs = [];
    this.endSubs = [];
    this.limit = 1;
    this.currentPage = 0;
    this.query = query;
    this.totalCount = 0;
  }

  on(message: string, callback: any) {
    switch (message) {
      case "data":
        this.fetchSubs.push(callback);
        break;
      case "end":
        this.endSubs.push(callback);
        break;
      default:
        console.error("invalid message type");
        break;
    }
  }

  offAll() {
    this.fetchSubs = [];
    this.endSubs = [];
  }

  _hasNext() {
    return this.currentPage * this.limit < this.totalCount;
  }

  startFetch() {
    console.log("start fetch");
    this._iterate();
  }

  async _iterate() {
    console.log("loop");

    const res = await api({
      method: "GET",
      url: `/contractors/${sessionStorage.contractorNo}/users/${sessionStorage.userNoText}/topdatas`,
      params: {
        q: {
          ...this.query,
          skip: this.currentPage * this.limit,
          limit: this.limit,
        },
      },
    });

    this.totalCount = res.headers["x-total-count"];
    if (res.status === 200 && res.data[0]) {
      this.currentPage += 1;
      this.fetchSubs.forEach((fn) => {
        fn(res.data[0]);
      });

      if (this._hasNext()) {
        this._iterate();
      } else {
        this.endSubs.forEach((fn) => {
          fn();
        });
      }
    }
  }
}

/**
 * 散布図
 */
export const useScatterPlot = (q: Query) => {
  const [data, setData] = useState<PlotDatas>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [iterator, setIterator] = useState(new TopDataAutoIterator(q));

  const upadteData = useCallback(
    (d) => {
      console.log("iterating");
      const plotArr: PlotData[] = d.contents.rawdata["データプロット"].map(
        (rawPlot: {
          X座標: number;
          Y座標: number;
          リスクパーセンテージ: number;
          虐待記録: number;
          宛名番号: number;
          データグループID: number;
        }) => {
          return {
            numeric_resident_register_code: rawPlot["宛名番号"],
            numeric_prediction1_value: rawPlot["リスクパーセンテージ"],
            numeric_prediction2_value: 0,
            non_numeric_datagroup_id: rawPlot["データグループID"],
            non_numeric_percentage_group: rawPlot["データグループID"],
            numeric_group_coord_x: rawPlot["X座標"] / 100,
            numeric_group_coord_y: rawPlot["Y座標"] / 100,
            numeric_abused_record: rawPlot["虐待記録"],
          };
        }
      );
      console.log([...data, ...plotArr]);
      setData([...data, ...plotArr]);
    },

    [data]
  );

  useEffect(() => {
    iterator.on("data", (d: any) => {
      upadteData(d);
    });

    return () => {
      iterator.offAll();
    };
  }, [iterator, upadteData]);

  useEffect(() => {
    iterator.startFetch();
  }, [iterator]);

  return [data];
};
