import React from "react";
import { useNavigate } from "react-router-dom";
import { ChildListItem, PersonalData } from "../../models";
import { TableCell } from "@material-ui/core";
import { ASIconButton, ASTableRow } from "allegro-ui";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useSetRecoilState } from "recoil";
import { tabIdState } from "src/fortress/recoils/singleChildPageState";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {
  familyPersonalAgeState,
  familyPersonalNameState,
  ModalDataState,
  ModalState,
} from "src/fortress/recoils/familyPersonalState";
import * as api from "../../api";
import { getConfig } from "src/fortress/config";
import styled from "styled-components";
import { shapeToSeireki } from "src/fortress/utils/time";

type Props = {
  familyData: ChildListItem;
};

const StyledASTableRow = styled(ASTableRow)<{ disabled: boolean }>`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const BoldText = styled.span`
  font-weight: bold;
`;

export const FTFamilyListItem: React.FC<Props> = (props) => {
  const { familyData } = props;
  const config = getConfig(sessionStorage.contractorNo);
  const history = useNavigate();
  const setTabId = useSetRecoilState(tabIdState("ft-family-list-item"));
  const setOpen = useSetRecoilState(ModalState);
  const setName = useSetRecoilState(familyPersonalNameState);
  const setAge = useSetRecoilState(familyPersonalAgeState);
  const setData = useSetRecoilState(ModalDataState);
  let showData: PersonalData[] = [];

  console.log(familyData);

  //家族ページにおいて、20歳以上の個人詳細ページを開くためにこの処理を書いている。

  const handleModalOpen = async () => {
    setOpen(true);
    setName(familyData.name);
    setAge(familyData.age);
    const res = await api.personalData(familyData.atena);
    const showDataTitle = config.personalData.parent.datalinkFileNames;
    res.forEach((d) => {
      if (showDataTitle.includes(d.label)) {
        showData.push(d);
      }
    });
    setData(showData);
  };

  //家族ページにおいて、20歳未満の個人詳細ページを開くためにこの処理を書いている。

  const handleClick = () => {
    setTabId(0);
    history(`/child/${familyData.atena}`);
  };

  const fowardIcon =
    familyData.age <= 999 ? (
      <ArrowForwardIosIcon fontSize="small" onClick={handleClick} />
    ) : (
      <OpenInNewIcon fontSize="small" onClick={handleModalOpen} />
    );

  const isDisable = (): boolean => {
    if (familyData.deleteCode === 1) {
      return true;
    } else if (
      familyData.updateReason === "転入通知" ||
      familyData.updateReason === "転出" ||
      familyData.updateReason === "死亡"
    ) {
      return true;
    }
    return false;
  };

  return (
    <StyledASTableRow disabled={isDisable()}>
      <TableCell>
        <BoldText>
          {process.env.REACT_APP_KAMEI_MODE ? "非表示" : familyData.name}(
          {familyData.age})
        </BoldText>
      </TableCell>

      <TableCell>
        <BoldText>{shapeToSeireki(Number(familyData.birthday))}</BoldText>
      </TableCell>
      <TableCell>{familyData.deleteCode}</TableCell>

      <TableCell>{familyData.relationship}</TableCell>

      <TableCell>{familyData.statusLabel}</TableCell>

      <TableCell align="left" padding="checkbox">
        <ASIconButton kind="white">{fowardIcon}</ASIconButton>
      </TableCell>
    </StyledASTableRow>
  );
};
