import { defaultScienceFolder, ScienceFolder } from "./ScienceFSItem";
const LTT = require("list-to-tree");

/**
 * DBから取得したデータを木構造の表示用に定義
 */
export const defaultTreeModelData: TreeModel = {
  ...defaultScienceFolder,
  child: [],
};

export type TreeModel = ScienceFolder & {
  child?: TreeModel[];
};

/**
 * DBから取得したデータを表示用にする
 * folderId：Number（DB格納）→ String(表示用)
 */
// export const createTreeModel = (props: Partial<Folder>) => {
//   return produce(defaultTreeModelData, (draft: any) => {
//     // if (props.folderType === "Project") {
//     draft.id =
//       typeof props.folderId === "number" && props.folderId !== 0
//         ? // typeof props.folderId === "number" && props.folderId !== 0
//           String(props.folderId)
//         : props.folderId;
//     draft.name =
//       typeof props.name === "string" && props.name !== ""
//         ? props.name
//         : draft.name;
//     if (props.folderType === "Project") {
//       draft.children = [];
//     } else if (props.folderType === "Folder") {
//       draft.parent = props.parent;
//     }
//     draft.folderType = props.folderType;
//     // }
//   });
// };

/**
 * projectに構造をset
 * parentに値がないものからchildren[]にセットしていく
 */
export const insertTreeModel = (props: any[]) => {
  const resultList: any[] = [];
  // const noHaveChild: any[] = [];
  // 子がいないfolder（末端）から処理
  // parentの値一覧取得
  // プロジェクトはresultListにセット
  var parentArray: string[] = [];
  props.forEach((e) => {
    if (typeof e.parent === "string") {
      parentArray.push(e.parent);
    } else {
      resultList.push(e);
    }
  });
  // プロジェクト、かつparentの値一覧にないフォルダを取得
  // parentArray((e) => {});

  // resultList = props.filter((e) => {
  //   if (typeof e.parent === "string") {
  //   }
  // });
  //
  // const result = props.filter((e) => {
  //   if (typeof e.parent === "string") {
  //   }
  //   // parentなし→project
  //   else {
  //     result;
  //   }
  //   return e.parent;
  // });

  // if (props) {
  // } else {
  // }
  // return produce(defaultTreeModelData, (draft: any) => {
  //   // if (props.folderType === "Folder") {
  //   draft.id =
  //     typeof props.folderId === "string" && props.folderId !== ""
  //       ? props.folderId
  //       : draft.folderId;
  //   draft.name =
  //     typeof props.name === "string" && props.name !== ""
  //       ? props.name
  //       : draft.name;
  //   draft.children = [];
  //   // }
  // });
};

export const toTree = (folderList: ScienceFolder[]): TreeModel[] => {
  const ltt = new LTT(folderList, {
    key_id: "itemId",
    key_parent: "parent",
  });

  const result = ltt.GetTree();
  return result;
};
