//アセスメント作成画面における選択肢のデータをここに保持しておく。データが変わればここを変更するといいようにする。
//これは未就学児のデータである。

export const EvalSelections = [
  {
    value: "未評価",
    label: "未評価",
  },
  {
    value: "問題なし",
    label: "問題なし",
  },
  {
    value: "気になる",
    label: "気になる",
  },
  {
    value: "要経過観察",
    label: "要経過観察",
  },
  {
    value: "虐待の危惧あり",
    label: "虐待の危惧あり",
  },
  {
    value: "虐待あり",
    label: "虐待あり",
  },
];

export const StatusSelections = [
  {
    value: "なし",
    label: "なし",
  },
  {
    value: "終結",
    label: "終結",
  },
  {
    value: "虐待対応業務中",
    label: "虐待対応業務中",
  },
  {
    value: "支援中",
    label: "支援中",
  },
  {
    value: "未評価",
    label: "未評価",
  },
];

export const KakuninSelections = [
  {
    value: "データから",
    label: "データから",
  },
  {
    value: "家庭訪問で",
    label: "家庭訪問で",
  },
  {
    value: "相談で",
    label: "相談で",
  },
  {
    value: "学校で",
    label: "学校で",
  },

  {
    value: "未評価",
    label: "未評価",
  },
];

export const GyakutaiKindsSelections = [
  {
    value: "未入力",
    label: "未入力",
  },
  {
    value: "要保護",
    label: "要保護",
  },
  {
    value: "要支援",
    label: "要支援",
  },
  {
    value: "特定妊婦",
    label: "特定妊婦",
  },
  {
    value: "登録なし",
    label: "登録なし",
  },
];

//以下、チェックボックス欄

//子どもの様子

export const healthState = [
  "不定愁訴、反復する腹痛、便通などの体調不良を訴える。",
  "夜驚、悪夢、不眠がある。",
];

export const unstableMental = [
  "警戒心が強く、音や振動に過剰に反応し、手を挙げただけで顔や頭をかばう。",
  "過度に緊張し、担任教諭、保育士等と視線が合わせられない。",
  "大人の顔色を伺ったり、接触をさけようとしたりする。",
];

export const indifference = [
  "表情が乏しく、受け答えが少ない。",
  "ボーっとしている、急に気力がなくなる。",
];

export const aggressive = [
  "落ち着きがなく、過度に乱暴だったり、弱い者に対して暴力をふるったりする。",
  "他者とうまく関われず、ささいなことでもすぐにカッとなるなど乱暴な言動が見られる。",
  "激しいかんしゃくをおこしたり、かみついたりするなど攻撃的である。",
];

export const isolation = ["友達と一緒に遊べなかったり、孤立しがちである。"];

export const worryBehavior = [
  "担任教諭、保育士等を独占したがる、用事がなくてもそばに近づいてこようとするなど、過度のスキンシップを求める。",
  "不自然に子どもが保護者と密着している。",
  "必要以上に丁寧な言葉遣いやあいさつをする。",
  "繰り返し嘘をつく、空想的な言動が増える。",
  "自暴自棄な言動がある。",
];

export const antiSocietyBehavior = [];

export const parentBehavior = [
  "保護者の顔色を窺う、意図を察知した行動をする。",
  "保護者といるとおどおどし、落ち着きがない。",
  "保護者がいると必要以上に気を遣い緊張しているが、保護者が離れると安心して表情が明るくなる。",
];

export const hygieneAppearance = [
  "からだや衣服の不潔感、髪を洗っていないなどの汚れ、におい、垢の付着、爪が伸びている等がある。",
  "季節にそぐわない服装をしている。",
  "衣服が破れたり、汚れている。",
  "虫歯の治療が行われていない。",
];

export const eatSituation = [
  "食べ物への執着が強く、過度に食べる。",
  "極端な食欲不振が見られる。",
  "友達に食べ物をねだることがよくある。",
];

export const goKinder = [
  "理由がはっきりしない欠席・遅刻・早退が多い。",
  "連絡がない欠席を繰り返す。",
];

//保護者の様子

export const dealChild = [
  "理想の押しつけや年齢不相応な要求がある。",
  "発達にそぐわない厳しいしつけや行動制限をしている。",
  "「かわいくない」「にくい」など差別的な発言がある。",
  "子どもの発達等に無関心であったり、育児について拒否的な発言がある。",
  "子どもに対して、繰り返し馬鹿にしてからかう、ことあるごとに激しく叱ったり、ののしったりする。",
];

export const differenceSibling = [
  "きょうだいに対しての差別的な言動や特定の子どもに対して拒否的な態度をとる。",
  "きょうだいで服装や持ち物などに差が見られる。",
];

export const stateHeart = [
  "精神科への受診歴、相談歴がある。（精神障害者保健福祉手帳の有無は問わない）",
  "アルコール依存（過去も含む）や薬物の使用歴がある。",
  "子育てに関する強い不安がある。",
  "保護者自身の必要な治療行為を拒否する。",
];

export const parentWorryBehavior = [
  "些細なことでも激しく怒るなど、感情や行動のコントロールができない。",
  "被害者意識が強く、事実と異なった思い込みがある。",
  "他児の保護者との対立が頻回にある。",
];

export const kinderInvolvment = [
  "長期にわたる欠席が続き、訪問しても子どもに会わせようとしない。",
  "欠席の理由や子どもに関する状況の説明に不自然なところがある。",
  "行事への不参加、連絡をとることが困難である。",
];

//家族、家庭の状況

export const notGoodParent = [
  "夫婦間の口論、言い争いがある。",
  "絶え間なくけんかがあったり、家族（同居者間の暴力）不和がある。",
];

export const livingState = [
  "家中ゴミだらけ、異臭、シラミがわく、放置された多数の動物が飼育されている。",
  "理由のわからない頻繁な転居がある。",
];

export const supportState = [
  "近隣との付き合いを拒否する。",
  "必要な支援機関や地域の社会資源からの関わりや支援を拒む。",
];

export const other = [
  "保護者の離職の長期化、頻繁な借金の取り立て等、経済的な困窮を抱えている。",
  "未熟児、障害、慢性疾患、発育や発達の遅れ（やせ、低身長、歩行や言葉の遅れ等）が見られる。",
  "親族以外の同居人の存在、不安定な婚姻状況（結婚、離婚を繰り返す等）",
  "養育の見通しもないままの無計画な出産による多子",
  "被虐待歴、愛されなかった思い等、何らかの心的外傷を抱えている。",
  "知識不足、家事・育児能力の不足",
  "親族や友人などの養育支援者が近くにいない。",
  "予期しない妊娠・出産、祝福されない妊娠・出産",
  "10代の妊娠、親としての心構えが整う前の出産",
];

//以下雛形

export const defaultCheckedChildData = {
  "子どもの健康状態はどうですか？": {
    "夜驚、悪夢、不眠がある。": false,
    "不定愁訴、反復する腹痛、便通などの体調不良を訴える。": false,
  },
  "子どもの精神状態は安定していますか？": {
    "警戒心が強く、音や振動に過剰に反応し、手を挙げただけで顔や頭をかばう。":
      false,
    "過度に緊張し、担任教諭、保育士等と視線が合わせられない。": false,
    "大人の顔色を伺ったり、接触をさけようとしたりする。": false,
  },
  "子どもの普段の様子から気力を感じることはできますか？": {
    "表情が乏しく、受け答えが少ない。": false,
    "ボーっとしている、急に気力がなくなる。": false,
  },
  "割と攻撃的になることがありますか？": {
    "落ち着きがなく、過度に乱暴だったり、弱い者に対して暴力をふるったりする。":
      false,
    "他者とうまく関われず、ささいなことでもすぐにカッとなるなど乱暴な言動が見られる。":
      false,
    "激しいかんしゃくをおこしたり、かみついたりするなど攻撃的である。": false,
  },
  "子どもは割と孤立しがちですか？": {
    "友達と一緒に遊べなかったり、孤立しがちである。": false,
  },
  "気になる、または心配になる行動とかはありますか？": {
    "担任教諭、保育士等を独占したがる、用事がなくてもそばに近づいてこようとするなど、過度のスキンシップを求める。":
      false,
    "不自然に子どもが保護者と密着している。": false,
    "必要以上に丁寧な言葉遣いやあいさつをする。": false,
    "繰り返し嘘をつく、空想的な言動が増える。": false,
    "自暴自棄な言動がある。": false,
  },
  "子どもに反社会的な素振りとかを確認することができますか？": {},
  "子どもの保護者への態度はどのようなものですか？": {
    "保護者の顔色を窺う、意図を察知した行動をする。": false,
    "保護者といるとおどおどし、落ち着きがない。": false,
    "保護者がいると必要以上に気を遣い緊張しているが、保護者が離れると安心して表情が明るくなる。":
      false,
  },
  "子どもの身なりや精神状態はどうですか？": {
    "からだや衣服の不潔感、髪を洗っていないなどの汚れ、におい、垢の付着、爪が伸びている等がある。":
      false,
    "季節にそぐわない服装をしている。": false,
    "衣服が破れたり、汚れている。": false,
    "虫歯の治療が行われていない。": false,
  },
  "子どもの食事の様子はどうですか？": {
    "食べ物への執着が強く、過度に食べる。": false,
    "極端な食欲不振が見られる。": false,
    "友達に食べ物をねだることがよくある。": false,
  },
  "子どもの登校状況等とかはどうですか？": {
    "理由がはっきりしない欠席・遅刻・早退が多い。": false,
    "連絡がない欠席を繰り返す。": false,
  },
};

export const defaultCheckedParentData = {
  "子どもと上手に関わっていますか？": {
    "理想の押しつけや年齢不相応な要求がある。": false,
    "発達にそぐわない厳しいしつけや行動制限をしている。": false,
    "「かわいくない」「にくい」など差別的な発言がある。": false,
    "子どもの発達等に無関心であったり、育児について拒否的な発言がある。": false,
    "子どもに対して、繰り返し馬鹿にしてからかう、ことあるごとに激しく叱ったり、ののしったりする。":
      false,
  },
  "保護者は兄弟間で差別をしていないですか？": {
    "きょうだいに対しての差別的な言動や特定の子どもに対して拒否的な態度をとる。":
      false,
    "きょうだいで服装や持ち物などに差が見られる。": false,
  },
  "保護者の心身の状態はどうですか？": {
    "精神科への受診歴、相談歴がある。（精神障害者保健福祉手帳の有無は問わない）":
      false,
    "アルコール依存（過去も含む）や薬物の使用歴がある。": false,
    "子育てに関する強い不安がある。": false,
    "保護者自身の必要な治療行為を拒否する。": false,
  },
  "保護者に関して何か気になる行動がありますか？": {
    "些細なことでも激しく怒るなど、感情や行動のコントロールができない。": false,
    "被害者意識が強く、事実と異なった思い込みがある。": false,
    "他児の保護者との対立が頻回にある。": false,
  },
  "保護者は普段どのように学校と関わっていますか？": {
    "長期にわたる欠席が続き、訪問しても子どもに会わせようとしない。": false,
    "欠席の理由や子どもに関する状況の説明に不自然なところがある。": false,
    "行事への不参加、連絡をとることが困難である。": false,
  },
};

export const defaultCheckedFamilyData = {
  "家族の間で暴力や不和がありますか？": {
    "夫婦間の口論、言い争いがある。": false,
    "絶え間なくけんかがあったり、家族（同居者間の暴力）不和がある。": false,
  },
  "住居の状態はどうですか？": {
    "家中ゴミだらけ、異臭、シラミがわく、放置された多数の動物が飼育されている。":
      false,
    "理由のわからない頻繁な転居がある。": false,
  },
  "周りからの助けとかを拒否したりしますか？": {
    "近隣との付き合いを拒否する。": false,
    "必要な支援機関や地域の社会資源からの関わりや支援を拒む。": false,
  },
};

export const defaultCheckedOtherData = {
  "以下、当てはまるものを全て選択してください。": {
    "保護者の離職の長期化、頻繁な借金の取り立て等、経済的な困窮を抱えている。":
      false,
    "未熟児、障害、慢性疾患、発育や発達の遅れ（やせ、低身長、歩行や言葉の遅れ等）が見られる。":
      false,
    "親族以外の同居人の存在、不安定な婚姻状況（結婚、離婚を繰り返す等）": false,
    養育の見通しもないままの無計画な出産による多子: false,
    "被虐待歴、愛されなかった思い等、何らかの心的外傷を抱えている。": false,
    "知識不足、家事・育児能力の不足": false,
    "親族や友人などの養育支援者が近くにいない。": false,
    "予期しない妊娠・出産、祝福されない妊娠・出産": false,
    "10代の妊娠、親としての心構えが整う前の出産": false,
  },
};
