export type OptLogic = {
  className?: string;
  optimizerEntryNo?: string;
  contractorNo?: string;
  createdUserNo?: string;
  createdUserId?: string;
  templateid: string;
  note: string;
  name: string;
  created?: Date;
  updated?: Date;
  type?: string;
  enable?: Boolean;
  version?: any;
  contents: string;
  contentsForExport?: string;
  template?: string;
  fileObjs?: string;
  items?: Array<any>;
  createdBy?: string;
  updatedBy?: string;
  created_timestamp?: Date;
  id: string | null;
  _id: string | null;
};

export const initialOptLogic: OptLogic = {
  name: "",
  templateid: "",
  contents: 'console.log("Hello World.")',
  note: "OPTIMIZE EDITORで作成",
  type: "Lite",
  id: "",
  _id: null,
};
