import React from "react";
import styled from "styled-components";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ASTable } from "allegro-ui";

import { useRecoilValue } from "recoil";
import { familyListSelector } from "../../recoils/familyListState";
import { FTFamilyListItem } from "../molecules";
import { KihonFamilyDataPanelContainer } from "src/fortress/containers";
import { WelfareModal } from "../molecules/WelfareModal";
import {
  ModalDataState,
  ModalState,
} from "src/fortress/recoils/familyPersonalState";

const StyledBasicTitle = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
  font-size: 20px;
  font-weight: bold;
  margin-left: 7px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
`;

type Props = {};

//児童詳細画面の家族のコンポーネント

export const FTFamilyDataPanel: React.FC<Props> = () => {
  const familyList = useRecoilValue(familyListSelector);
  const open = useRecoilValue(ModalState);
  const data = useRecoilValue(ModalDataState);

  const showData = !open ? (
    <>
      <StyledBasicTitle>
        <p>基本世帯データ</p>
      </StyledBasicTitle>

      <TableContainer component={ASTable} borderRadius={18} kind="tertiary">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>名前</TableCell>
              <TableCell align="left">生年月日</TableCell>

              <TableCell align="left">消除コード</TableCell>

              <TableCell>続柄</TableCell>
              <TableCell>増減事由</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {familyList.map((person, id) => (
              <FTFamilyListItem familyData={person} key={String(id)} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <KihonFamilyDataPanelContainer /> */}
    </>
  ) : (
    <WelfareModal personalData={data} />
  );

  return <>{showData}</>;
};
