import { api } from "../../api/http";
/**
 * 児童を検索する
 * @param {string} word 検索ワード
 * @return {object} レスポンスデータ
 */
export const dataRemove = async (
  collection: string,
  ids: string[]
): Promise<any> => {
  try {
    const res = await api(
      `/contractors/${sessionStorage.contractorNo}/datagate`,
      {
        method: "delete",
        params: {
          apikey: sessionStorage.token,
        },
        data: {
          collection,
          dataIds: ids,
        },
      }
    );
    //TODO エラーハンドル
    console.log("[DELETE]/datagate", res.status);
    console.log(res.data);

    return res.data;
  } catch (err) {
    console.error(err);
    return [[], 0];
  }
};
