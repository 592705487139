import { AxiosInstance } from "axios";
import {
  GroupInput,
  Group,
  createGroupFromOptimizedData,
} from "../../models/Group";

export default (instance: AxiosInstance) => {
  const create = async (groupInput: GroupInput): Promise<Group | undefined> => {
    const path = "/group";
    try {
      const res = await instance.post(path, groupInput);
      const group = createGroupFromOptimizedData(res.data.message);
      return group;
    } catch (err) {
      return undefined;
    }
  };

  const findOne = async (groupId: string): Promise<Group | null> => {
    const path = `/group/${groupId}`;

    try {
      const res = await instance.get(path);
      const group = createGroupFromOptimizedData(res.data.message);
      return group;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const findMany = async (): Promise<Group[]> => {
    const path = "/group";
    try {
      const res = await instance.get(path);
      const groups = res.data.message.map((data: any) =>
        createGroupFromOptimizedData(data)
      );
      return groups;
    } catch (err) {
      return [];
    }
  };

  const dataProvider = async (
    newtQuery: any,
    options?: { count?: boolean }
  ): Promise<any[]> => {
    const path = "/group/dataprovider";

    try {
      const res = await instance.post(path, newtQuery, {
        params: options,
      });

      return res.data.message;
    } catch (err) {
      return [];
    }
  };

  const update = async (
    groupId: string,
    groupInput: GroupInput
  ): Promise<boolean> => {
    const path = `/group/${groupId}`;
    try {
      const res = await instance.put(path, groupInput);
      return res.status === 200;
    } catch (err) {
      return false;
    }
  };

  const deleteOne = async (groupId: string): Promise<boolean> => {
    const path = `/group/${groupId}`;
    try {
      const res = await instance.delete(path);
      return res.status === 200;
    } catch (err) {
      return false;
    }
  };

  const inviteMember = async (props: {
    groupId: string;
    userNumber: number;
    email: string;
  }): Promise<boolean> => {
    const { groupId, userNumber, email } = props;
    const path = `/group/${groupId}/member`;
    try {
      const res = await instance.put(path, { userNumber, email });
      if (res.status === 200) {
        return true;
      }
    } catch (err) {
      console.error(err);
    }
    return false;
  };

  const excludeMember = async (props: {
    groupId: string;
    userNumber: number;
    email: string;
  }): Promise<boolean> => {
    const { groupId, userNumber, email } = props;
    const path = `/group/${groupId}/member`;
    try {
      const res = await instance.delete(path, { data: { userNumber, email } });
      if (res.status === 200) {
        return true;
      }
    } catch (err) {
      console.error(err);
    }
    return false;
  };

  return {
    create,
    findOne,
    findMany,
    dataProvider,
    update,
    deleteOne,
    inviteMember,
    excludeMember,
  };
};
