import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import {
  createModalState,
  monitorListRequestId,
  windowSizePagination,
} from "src/kinzaza/recoils";
import * as api from "../api";
import { createMonitorThumb } from "../models";
import { monitorThumbListState } from "../recoils/monitorThumbListState";
import { v4 as uuidv4 } from "uuid";

export const useHomePage = () => {
  const foldersLoadable = useRecoilValueLoadable(monitorThumbListState);
  const [reqId, setReqId] = useRecoilState(monitorListRequestId);
  const limit = useRecoilValue(windowSizePagination);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(foldersLoadable.state === "loading");
  }, [foldersLoadable.state]);

  const _createMonitor = async (name: string) => {
    const item = createMonitorThumb({ name });
    const data = {
      ...item,
      uuid: uuidv4(),
    };
    const res = await api.postMonitor(data);

    setReqId(reqId + 1);
  };

  const monitors =
    foldersLoadable.state === "hasValue" ? foldersLoadable.contents : [];

  const monitorsCount = monitors[0]?.monitorTotalCount;

  const pageCount = Math.ceil(monitorsCount / limit);

  const [showCreateModal, setShowCreateModal] =
    useRecoilState(createModalState);

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };

  const handleCreate = (formdata: { monitorName: string }) => {
    _createMonitor(formdata.monitorName);
    handleCreateModalClose();
  };

  return {
    monitors,
    showCreateModal,
    setShowCreateModal,
    handleCreate,
    pageCount,
    isLoading,
  };
};
