import useAxios from "axios-hooks";
import { url } from "../url";

export const usePost = (contractorName: string, userId: string) => {
  const [{ data, loading, error }, fetch] = useAxios(
    {
      url: url.topData(contractorName, userId),
      method: "POST",
    },
    { manual: true }
  );

  const exec = (body: any) => {
    fetch({
      data: { body },
    });
  };

  return { data, loading, error, exec };
};
