import styled from "styled-components";
export const Container = styled.div`
  width: 600px;
  padding: 24px;
  line-height: 1.7;
  > * {
    margin-bottom: 16px;
  }

  > form {
    > * {
      margin-bottom: 24px;
    }
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  line-height: 1.6;
`;

export const ButtonContainer = styled.div`
  margin-top: 24px;
  position: relative;
`;

export const PositiveLog = styled.div`
  color: ${(props) => props.theme.positiveColor};
`;

export const NegativeLog = styled.div`
  color: ${(props) => props.theme.negativeColor};
`;

export const WarnLog = styled.div`
  color: ${(props) => props.theme.cautionColor};
`;

export const LogArea = styled.div`
  margin-top: 12px;
  font-size: 12px;
  line-height: 1.7;
  height: 400px;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.bgSecondaryColor};
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  padding: 16px;
  position: relative;
`;

export const CircleLayout = styled.div`
  position: absolute;
  right: -6px;
  bottom: -8px;
`;