import React, { useEffect, useState } from "react";
import { useJsonView } from "src/kinzaza/hooks";
// import "codemirror/theme/yonce.css";
import { TextField } from "@material-ui/core";
import { ASCard, ASIconButton } from "allegro-ui";
import styled, { DefaultTheme } from "styled-components";
import ReactJson from "react-json-view";
import PerfectScrollbar from "react-perfect-scrollbar";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import "react-perfect-scrollbar/dist/css/styles.css";
import { DailyThumb } from "src/kinzaza/models";

const Container = styled(ASCard)`
  height: 960px;
  padding: 12px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const JsonContainer = styled.div`
  background-color: #222;
  border-radius: 8px;
  padding: 4px;
  height: 850px;
  /* overflow-y: scroll; */
  font-size: 16px;
`;

const WhiteBox = styled.div`
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 30px;
  height: 60px;

  > * {
    margin-right: 12px;
  }
  > *:last-child {
    margin-left: auto;
  }
  /* background-color: ${(props) => props.theme.bgPrimaryColor}; */
`;

const StyledASIconButton = styled(ASIconButton)`
  :disabled {
    color: ${(props) => props.theme.bgPrimaryColor};
    background: ${(props) => props.theme.primaryBorderColor};
    opacity: 0.1;
  }
`;

type Props = {
  theme?: DefaultTheme;
  dailyThumb?: DailyThumb;
};

export const JsonViewer: React.FC<Props> = (props) => {
  const { jsonView, setLimit, setOffset } = useJsonView();

  const { offset, limit, count, json } = jsonView;

  const [limitStr, setLimitStr] = useState(limit.toString());
  const [offsetStr, setOffsetStr] = useState(offset.toString());

  useEffect(() => {}, [jsonView]);

  return (
    <Container>
      <WhiteBox>
        <StyledASIconButton
          size="small"
          kind={"primary"}
          disabled={offset - limit < 0}
          onClick={() => {
            offset - limit > 0 ? setOffset(offset - limit) : setOffset(0);
          }}
        >
          <NavigateBeforeIcon />
        </StyledASIconButton>
        <div>
          {limit === 1 ? offset + 1 : `${offset + 1}-${offset + limit}`}/{" "}
          {count}
        </div>
        <StyledASIconButton
          size="small"
          kind={"primary"}
          disabled={offset + limit >= count}
          onClick={() => {
            if (offset + 2 * limit <= count) {
              // setLimit(limit);
              setOffset(offset + limit);
            } else {
              setOffset(offset + limit);
              // setLimit(count - offset);
            }
          }}
        >
          <NavigateNextIcon />
        </StyledASIconButton>

        <FormContainer>
          <TextField
            key="limit"
            label="limit"
            variant="filled"
            value={limitStr}
            type="number"
            // defaultValue={jsonView.limit}
            onChange={(e) => {
              setLimitStr(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && limitStr !== "") {
                setLimit(Number(limitStr));
              }
            }}
            onBlur={() => {
              if (limitStr !== "") {
                setLimit(Number(limitStr));
              }
            }}
            InputProps={{
              style: {
                color: "#cfcfcf",
                background: "#2A2B2D",
                border: "3px solid #222",

                maxWidth: 100,
                textAlign: "center",
              },
            }}
            InputLabelProps={{
              style: {
                color: "#cfcfcf",
              },
            }}
          ></TextField>
          <TextField
            label="skip"
            variant="filled"
            value={offsetStr}
            type="number"
            // defaultValue={jsonView.offset}
            onChange={(e) => {
              setOffsetStr(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && limitStr !== "") {
                setOffset(Number(limitStr));
              }
            }}
            onBlur={() => {
              if (limitStr !== "") {
                setOffset(Number(limitStr));
              }
            }}
            InputProps={{
              style: {
                color: "#cfcfcf",
                background: "#2A2B2D",
                border: "3px solid #222",

                maxWidth: 100,
                textAlign: "center",
              },
            }}
            InputLabelProps={{
              style: {
                color: "#cfcfcf",
              },
            }}
          ></TextField>
        </FormContainer>
      </WhiteBox>
      <JsonContainer>
        <PerfectScrollbar style={{ background: "none" }}>
          <ReactJson
            name="data"
            displayDataTypes={false}
            displayObjectSize={false}
            src={json}
            theme={{
              base00: "#2A2B2D",
              base01: "#505050",
              base02: "#505050",
              base03: "#505050",
              base04: "#37507e",
              base05: "#cfcfcf",
              base06: "#cfcfcf",
              base07: "#cfcfcf",
              base08: "#cfcfcf",
              base09: props.theme?.primaryColor ?? "#1ED760",
              base0A: props.theme?.primaryColor ?? "#1ED760",
              base0B: props.theme?.primaryColor ?? "#1ED760",
              base0C: props.theme?.primaryColor ?? "#1ED760",
              base0D: props.theme?.primaryColor ?? "#1ED760",
              base0E: props.theme?.primaryColor ?? "#1ED760",
              base0F: props.theme?.primaryColor ?? "#1ED760",
            }}
            enableClipboard={true}
            // typeScript に対応してない https://github.com/mac-s-g/react-json-view/pull/348
          />
        </PerfectScrollbar>
      </JsonContainer>
    </Container>
  );
};
