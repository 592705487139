import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { sortFilterState } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { useMyRole } from "src/portal/hooks/useMyRole";

interface QueryString {
  [key: string]: string;
}

export const useInfrastractureViewer = () => {
  const { canCreateServer, canUpdateServer, canDeleteServer } = useMyRole();
  const [tabId, setTabId] = useState(0);
  const { search } = useLocation();
  const history = useNavigate();
  const setSortQuery = useSetRecoilState(sortFilterState("nt-dev-ops-page"));

  useEffect(() => {
    const query = search
      .slice(1)
      .split("&")
      .map((str) => [str.split("=")[0], str.split("=")[1]])
      .reduce((acc, a) => {
        acc[a[0]] = a[1];
        return acc;
      }, {} as QueryString);
    if (query.tab) setTabId(Number(query.tab));
  }, [search]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (tabId) {
      params.append("tab", String(tabId));
    } else {
      params.delete("tab");
    }
    history({ search: params.toString() });

    if (tabId === 0) {
      setSortQuery({
        // "contents.rawdata.non_numeric_contractor": -1,
        "contents.rawdata.non_numeric_server_name": -1,
      });
    }
  }, [history, setSortQuery, tabId]);

  return {
    tabId,
    canCreateServer,
    canUpdateServer,
    canDeleteServer,
  };
};
