import React from "react";
import { NZDataOpsViewTemplate } from "../templates/NZDataOpsViewTemplate";
import { KZSingleMonitorPageTemplate } from "../templates/KZSingleMonitorPageTemplate";

type Props = {};
export const NZSingleMonitorPage: React.FC<Props> = (props) => {
  return (
    <NZDataOpsViewTemplate title={"Monitor"} withBackButton>
      <KZSingleMonitorPageTemplate />
    </NZDataOpsViewTemplate>
  );
};
