import styled from "styled-components";

export const XRTextArea = styled.div`
  margin-top: 24px;
  line-height: 1.5;
  opacity: 0.7;
  font-size: 14px;
  min-height: 60px;
  max-height: 60px;
  overflow: scroll;
  white-space: pre-wrap;
`;
