import { dFurusatoAPIConfig } from "../config";

export const create = async (slug: string, data: any) => {
  const url = `/${slug}`;
  try {
    const res = await dFurusatoAPIConfig.post(url, data);
    return res;
  } catch (err) {
    console.error(err);
  }
};
