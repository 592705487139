import { dFurusatoAPIConfig } from "../config";

export const fetchMany = async (
  slug: string,
  limit?: number,
  skip?: number,
  params?: any,
  options?: any
) => {
  const url = `/${slug}/dataprovider`;
  const newtQuery = {
    limit: limit ?? 1,
    skip: skip ?? 0,
    primaryKey: "id",
    shape: {
      id: "any",
      "contents.rawdata": "any",
      _created: "any",
      _modified: "any",
    },
    order: {
      _modified: -1,
    },
  };
  try {
    const res = await dFurusatoAPIConfig.post(url, newtQuery, { params });
    return res.data.message;
  } catch (err) {
    console.error(err);
  }
};

export const fetchOne = async (
  slug: string,
  donationId: string,
  params?: any
) => {
  const url = `/${slug}/${donationId}`;
  try {
    const res = await dFurusatoAPIConfig.get(url, { params });
    return res.data.message;
  } catch (err) {
    console.error(err);
  }
};
