import React, { useState, Suspense } from "react";
import styled from "styled-components";
import { ASButton, ASModal } from "allegro-ui";

//
//components
//
import { XROptXBrowser } from "..";
import { XRCreateOptXForm } from "../organisms/XRCreateOptXForm";
import { LoadingBox } from "src/opt-editor/components/atoms/OELoddingBox";
import { XRPageTemplate } from "./XRPageTemplate";

const Root = styled.div`
  background: ${(props) => props.theme.bgPrimaryColor};
  min-height: 100vh;
  width: 100%;
  color: ${(props) => (props.theme.darkMode ? "#eee" : "#000")};
`;

const ButtonGroup = styled.div`
  > * {
    margin-left: 8px;
  }
`;

type Props = {};

export const XROptXBrowsePageTemplate: React.FC<Props> = (props) => {
  const [showCreate, setShowCreate] = useState(false);

  return (
    <Root>
      <XRPageTemplate
        title="OptX Store"
        headerComponents={
          <ButtonGroup>
            <ASButton kind="primary" onClick={() => setShowCreate(true)}>
              New OptX
            </ASButton>
          </ButtonGroup>
        }
      >
        <Suspense fallback={<LoadingBox />}>
          <XROptXBrowser />
        </Suspense>
        <ASModal
          title="New OptX"
          open={showCreate}
          onClose={() => setShowCreate(false)}
        >
          <XRCreateOptXForm />
        </ASModal>
      </XRPageTemplate>
    </Root>
  );
};
