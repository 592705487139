import React, { useState } from "react";
import { useRecoilValueLoadable, useRecoilValue } from "recoil";
import styled from "styled-components";
import { ASBarGraph, ASGraph, ASIconButton, GraphData } from "allegro-ui";
import { format } from "date-fns";

import {
  monthlyDataCountListState,
  currentMonitor,
  dailyThumbListSortState,
  fetchingDaysCountState,
  // useCursorMonitorUpdater,
} from "src/kinzaza/recoils";

import BarChartIcon from "@material-ui/icons/BarChart";
import TimelineIcon from "@material-ui/icons/Timeline";

const Container = styled.div`
  color: "#fff";
  width: 100%;
  height: 100%;
  /* padding: 8px; */
  position: relative;
`;

const Header = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  > *:last-child {
    margin-left: auto;
  }
`;

const ButtonContainer = styled.div`
  > * {
    margin-left: 4px;
  }
`;

const StyledIconButton = styled(ASIconButton)`
  border-radius: 8px;
  &.Mui-disabled {
    background-color: grey;
    color: ${(props) => props.theme.primaryOnText};
    opacity: 0.3;
  }
`;

const GraphContainer = styled.div`
  margin: 0px 12px;
`;

type GraphType = "bar" | "line";
type Props = {};

export const MonthlyDataGraph: React.FC<Props> = () => {
  const dailyThumbs = useRecoilValueLoadable(monthlyDataCountListState);
  const cursorMonitor = useRecoilValue(currentMonitor);
  // const count = cursorMonitor.totalCount;
  const tableId = useRecoilValue(dailyThumbListSortState);
  const fetchDays = useRecoilValue(fetchingDaysCountState);

  const [graphType, setGraphType] = useState<GraphType>("bar");

  const formatDateStr = (datetimeNum: any) => {
    const datetime = new Date(datetimeNum);
    let dateStr = "";
    dateStr = format(datetime, "M/d");

    return dateStr;
  };

  const predictions = () => {
    if (dailyThumbs.state === "hasValue") {
      const data = dailyThumbs.contents;
      const graphData = data
        .slice(0)
        .reverse()
        .map((dt, id) => {
          const time = tableId === "作成日" ? dt.created : dt.modified;
          const date: Date = new Date(time ?? 0);

          return {
            x: date,
            y: Number(dt.dataCount ?? 0),
          };
        });

      return graphData;
    }
    return [];
  };

  const graphData: GraphData = {
    id: 0,
    label: cursorMonitor?.name ?? "",
    color: "#1ED760",
    dataPoints: predictions(),
  };

  return (
    <Container>
      <Header>
        <></>
        <ButtonContainer>
          <StyledIconButton
            size="small"
            disabled={graphType === "bar"}
            onClick={() => {
              setGraphType("bar");
            }}
          >
            <BarChartIcon />
          </StyledIconButton>

          <StyledIconButton
            size="small"
            disabled={graphType === "line"}
            onClick={() => {
              setGraphType("line");
            }}
          >
            <TimelineIcon />
          </StyledIconButton>
        </ButtonContainer>
      </Header>
      <GraphContainer>
        {graphType === "bar" ? (
          <ASBarGraph
            xType="time"
            barWidth={fetchDays * 0.02}
            graphHeight={300}
            graphDatas={[graphData]}
            xAxisFormat={(tick: any) => formatDateStr(tick)}
          />
        ) : (
          <ASGraph
            xType="time"
            graphHeight={300}
            graphDatas={[graphData]}
            // xAxisFormat={(tick: any) => formatDateStr(tick)}
            xsteps={5}
          />
        )}
      </GraphContainer>
    </Container>
  );
};
