import React from "react";
import LCSinglePage from "src/fortress/components/libs/LowCodeSinglePage/components/pages/LCSinglePage";
import { AssetListPage } from "src/fortress/components/pages/AssetListPage";
import { CIVariablesListPage } from "src/fortress/components/pages/CIVariablesPage";
import { MetricListPage } from "src/cheetah/components/pages/SinglePages/CHMetricListPage";
import { ServerListPage } from "src/fortress/components/pages/ServerListPage";
import CHMetricSinglePage from "../components/pages/SinglePages/CHMetricSinglePage";
import CHAssetSinglePage from "../components/pages/SinglePages/CHAssetSinglePage";
import CHServerSinglePage from "../components/pages/SinglePages/CHServerSinglePage";
import CHCICDConfigSinglePage from "../components/pages/SinglePages/CHCICDConfigSinglePage";

type Props = {};

export type CheetahDefinition = {
  globalMenuComponent?: any;
  primaryMenu: {
    path: string;
    pageComponent: any;
    singlePageComponent: any;
    isHome?: boolean;
  }[];
};

export const appDefinition: CheetahDefinition = {
  primaryMenu: [
    {
      path: "/metrics",
      pageComponent: MetricListPage,
      singlePageComponent: CHMetricSinglePage,
    },
    {
      path: "/assets",
      pageComponent: AssetListPage,
      singlePageComponent: CHAssetSinglePage,
    },
    {
      path: "/servers",
      pageComponent: ServerListPage,
      singlePageComponent: CHServerSinglePage,
    },
    {
      path: "/cicdconfigs",
      pageComponent: CIVariablesListPage,
      singlePageComponent: CHCICDConfigSinglePage,
    },
    {
      path: "/",
      pageComponent: AssetListPage,
      singlePageComponent: LCSinglePage,
      isHome: true,
    },
  ],
};
