import { ASLowCodeFormDefinition } from "allegro-low-code-components";
import { fetchGroupMany } from "src/portal/api/fetchGroup";

export const editPipelineGroupPermissionFormDefinition: ASLowCodeFormDefinition =
  {
    sections: [
      {
        fields: [
          {
            label: "Readable Groups",
            name: "readableGroups",
            type: "chip",
            values: async (name) => {
              const groups = await fetchGroupMany({
                limit: 8,
                skip: 0,
                matchQ: {
                  "contents.rawdata.group_name": {
                    $regex: name,
                    $options: "i",
                  },
                },
              });
              return groups.map((group) => {
                return {
                  label: group.group_name,
                  value: group.group_id,
                };
              });
            },
          },
          {
            label: "Writable Groups",
            name: "writableGroups",
            type: "chip",
            values: async (name) => {
              const groups = await fetchGroupMany({
                limit: 8,
                skip: 0,
                matchQ: {
                  "contents.rawdata.group_name": {
                    $regex: name,
                    $options: "i",
                  },
                },
              });
              return groups.map((group) => {
                return {
                  label: group.group_name,
                  value: group.group_id,
                };
              });
            },
          },
        ],
      },
    ],
  };
