import React, { useState } from "react";
import styled from "styled-components";
import { ASButton, ASInputBase, ASPictureSelectButton } from "allegro-ui";
import { OptX } from "src/xerver-room/models";
import { useOptX } from "src/xerver-room/hooks";

const Label = styled.h3`
  margin-top: 24px;
  font-size: 12px;
  margin-bottom: 8px;
`;

const MenuArea = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 24px;
    > * {
      margin-right: 24px;
    }
  }
`;

const Block = styled.div`
  margin-top: 24px;
  padding-top: 12px;
  border-top: 1px solid #333;
  > p {
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

const MarginDiv = styled.div`
  text-align: right;
  margin-top: 12px;
`;

type Props = {
  optX: OptX;
};
export const XRUpdateOptXForm: React.FC<Props> = (props) => {
  const [form, setForm] = useState<any>({});
  const [deleteInputText, setDeleteInputText] = useState();
  const optX = useOptX(props.optX.optXId);

  const handleFormChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setForm({
      ...form,
      [name]: value,
    });
  };
  const handleDeleteInput = (e: any) => {
    setDeleteInputText(e.target.value);
  };
  const handleDelete = async () => {
    //入力したフィールドとOptXの名前が一致するなら削除する
    if (deleteInputText === optX.name) {
      //delete
      const ok = await optX.remove();
      if (ok) {
        alert(`${optX.name} is deleted.`);
      } else {
        alert(`Failed to delete ${optX.name}.`);
      }
    } else {
      alert(`Failed to delete ${optX.name}. Invalid OptX name.`);
    }
  };

  const handleSubmit = async () => {
    const ok = await optX.update(form);
    if (ok) {
      alert(`${optX.name} is renamed.`);
    } else {
      alert(`Failed to rename ${optX.name} .`);
    }
  };

  const handleThumb = async (file: any) => {
    const ok = await optX.update({ thumbnail: file });
    if (ok) {
      alert(`Thumbnail is updated.`);
    } else {
      alert(`Failed set thumbnail to  ${optX.name} .`);
    }
  };

  return (
    <MenuArea>
      <Block>
        <h1>OptXの基本設定</h1>
        <Label>Name</Label>
        <ASInputBase
          kind="secondary"
          name="name"
          onChange={handleFormChange}
          defaultValue={optX.name ?? ""}
          fullWidth
        ></ASInputBase>

        <Label>Description</Label>
        <ASInputBase
          name="description"
          kind="secondary"
          onChange={handleFormChange}
          defaultValue={optX.description}
          multiline
          fullWidth
          minRows={10}
        ></ASInputBase>
        <MarginDiv>
          <ASButton kind="primary" onClick={handleSubmit}>
            Update
          </ASButton>
        </MarginDiv>
      </Block>
      <Block>
        <h1>アイコン設定</h1>
        <p>画像は1MB以下にしてください。大きすぎるとアップロードできません。</p>
        <MarginDiv>
          <ASPictureSelectButton onChange={handleThumb} />
        </MarginDiv>
      </Block>

      <Block>
        <h1>OptXの削除</h1>
        <p>削除するには確認のため、OptXの名前を入力してください。</p>
        <ASInputBase
          kind="secondary"
          onChange={handleDeleteInput}
        ></ASInputBase>
        <ASButton kind="secondary" onClick={handleDelete}>
          DELETE
        </ASButton>
      </Block>
    </MenuArea>
  );
};
