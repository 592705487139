import React, { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { useDrag, useDragLayer } from "react-dnd";

import scienceFile from "../../recoils/ScienceFileState";
import { ScienceFile } from "src/opt-editor/models/ScienceFSItem";
import { useFileOpener } from "src/opt-editor/recoils/EditorPaneState";

import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

import { OEFileDocIcon } from "./OEFileDocIcon";
import { OEFilePdfIcon } from "./OEFilePdfIcon";
import { OEFileMdIcon } from "./OEFileMdIcon";
import { OEFileXlsxIcon } from "./OEFileXlsxIcon";
import { getEmptyImage } from "react-dnd-html5-backend";

const FileTile = styled.div`
  /* height: 115%; */
  width: 100%;

  color: #ffffff;
`;
const StyledListItem = styled(ListItem)`
  width: 100%;
  border-bottom: 1px solid #3a3a3a;
  font-size: 14px;
`;

const ItemContainer = styled.div`
  padding: 10px;
  width: 100%;
  border-bottom: 1px solid #3a3a3a;
  font-size: 14px;
`;

const StyledListItemText = styled(ListItemText)`
  display: -webkit-box;
  color: #999;
  > span {
    font-size: 12px;
  }
  font-weight: 300;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
`;

type StyledProps = {
  selected?: boolean;
};

const FileIconContainer = styled.div.attrs<StyledProps>((props) => ({
  color: props.selected ? props.theme.primaryColor : "white",
}))<StyledProps>`
  color: ${(props) => props.color};
  width: 24px;
  padding: 3px;
`;

// const StyledFileIcon = styled(InsertDriveFileIcon).attrs<StyledProps>(
//   (props) => ({
//     color: props.selected ? props.theme.primaryColor : "white",
//   })
// )<StyledProps>`
//   color: ${(props) => props.color};
// `;

const StyledFileName = styled(ListItemText).attrs<StyledProps>((props) => ({
  color: props.selected ? props.theme.primaryColor : "white",
}))<StyledProps>`
  color: ${(props) => props.color};
  margin-left: 4px;
`;

const overviewLimit = 50;

type Props = {
  fileId: number | string;
  activeFileId: number | string;
  onRightClick: (evt: any, fileId: number) => void;
  onClick: (fileId: number) => void;
  fileObj?: ScienceFile;
};

export const OEFileListItem: React.FC<Props> = ({
  fileId,
  activeFileId,
  onRightClick,
  onClick,
  fileObj,
}) => {
  const [fileToRender, setFile] = useRecoilState(scienceFile.item(fileId));

  const { openFile } = useFileOpener();

  const handleClick = () => {
    if (fileToRender) {
      onClick(fileToRender.itemId);
      openFile(fileToRender);
    }
  };

  useEffect(() => {
    // console.log(fileToRender);
  }, [fileToRender]);

  //ただの関数にすべき
  const overviewText = useCallback((fileName: string, data: any) => {
    const extension = fileName.split(".").pop();
    const ellipsis = "...";
    let text = "";

    if (!data) {
      return text;
    }

    if (extension === "md") {
      if (data?.blocks) {
        data?.blocks?.forEach((block: any) => {
          text += block.text;
        });
      } else {
        text = data;
      }
    } else if (typeof data === "string") {
      text = data;
    } else if (typeof data === "object") {
      text = JSON.stringify(data);
    }

    text = text.replace(/(?:\r\n|\r|\n|\t)/g, "");

    return text?.length > overviewLimit
      ? text?.slice(0, overviewLimit) + ellipsis
      : text;
  }, []);

  const fileIcon = () => {
    const extension = fileToRender.name.split(".").pop();

    switch (extension) {
      case "doc":
        return <OEFileDocIcon extension={extension} />;
      case "docx":
        return <OEFileDocIcon extension={extension} />;
      case "docs":
        return <OEFileDocIcon extension={extension} />;
      case "pdf":
        return <OEFilePdfIcon extension={extension} />;
      case "xlsx":
        return <OEFileXlsxIcon extension={extension} />;
      case "xls":
        return <OEFileXlsxIcon extension={extension} />;
      case "md":
        return <OEFileMdIcon extension={extension} />;
      default:
        return <InsertDriveFileIcon />;
    }
  };

  const [collected, drag, dragPreview] = useDrag({
    item: { ...fileToRender },
    canDrag: true,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0.1 : 1,
      canDrag: monitor.canDrag,
      highlighted: monitor.isDragging(),
    }),
  });

  if (fileToRender)
    return collected.isDragging ? (
      <div ref={dragPreview} />
    ) : (
      <ItemContainer
        // button
        ref={drag}
        key={fileToRender.itemId}
        onClick={() => handleClick()}
        onContextMenu={(evt: any) => {
          onRightClick(evt, fileToRender.itemId);
        }}
      >
        <div
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        >
          <FileTile
            onContextMenu={(evt) => {
              onRightClick(evt, fileToRender.itemId);
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FileIconContainer
                selected={activeFileId === fileToRender.itemId}
              >
                {fileIcon()}
              </FileIconContainer>

              <StyledFileName
                primary={fileToRender.name}
                selected={activeFileId === fileToRender.itemId}
              />
            </div>
            <div>
              <StyledListItemText
                primary={overviewText(fileToRender.name, fileToRender.contents)}
              />
            </div>
          </FileTile>
        </div>
      </ItemContainer>
    );
  else return <></>;
};
