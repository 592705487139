import { ASInputBase } from "allegro-ui";
import React from "react";
import { useRecoilState } from "recoil";
import { childListPageIndexState } from "src/fortress/recoils/childListState";
import styled from "styled-components";

const RowBox = styled.div`
  margin-top: 30px;
  height: 65px;
`;

const Flex = styled.div`
  padding-top: 10px;
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
`;

const Page = styled.div`
  padding-top: 16px;
  padding-left: 12px;
  padding-right: 40px;
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
`;

const Height = styled.div`
  height: 40px;
  margin-top: 3px;
`;

const PageInput = styled(ASInputBase)`
  min-width: 30px;
  > input {
    width: 30px;
  }
`;

type Props = {};

export const FTUsefulRow: React.FC<Props> = (props) => {
  const [pageIndex, setPageIndex] = useRecoilState(childListPageIndexState);

  //児童一覧ページにおいて、ページ番号を入力すると、そのページに飛ぶ処理を書く

  const handlePage = (e: any) => {
    console.log(e.target.value);
    const value = e.target.value;
    const nextValue = value === "" ? 1 : value;
    console.log(nextValue);
    setPageIndex(Number(nextValue));
  };

  return (
    <>
      <RowBox>
        <Flex>
          <Height>
            <PageInput
              key="page"
              defaultValue={pageIndex}
              onChange={handlePage}
              kind="secondary"
            ></PageInput>
          </Height>
          <Page>ページ目</Page>
        </Flex>
      </RowBox>
    </>
  );
};
