import { Slots } from ".";

export type PipelineDef = {
  className?: string;
  optimizerEntryNo?: string;
  contractorNo?: string;
  createdUserNo?: string;
  createdUserId?: string;
  name: string;
  created?: Date;
  updated?: Date;
  type?: string;
  enable?: Boolean;
  version?: any;
  contents: Slots;
  contentsForExport?: string;
  template?: string;
  fileObjs?: string;
  items?: Array<any>;
  createdBy?: string;
  updatedBy?: string;
  created_timestamp?: Date;
  id: string | null;
  _id: string | null;
};

export const initialPipelineDef: PipelineDef = {
  name: "",
  contents: {},
  id: "",
  _id: null,
};
