import { instance, apiVersion } from "../initialize";
import react, { useState, useCallback, useMemo } from "react";
import { AxiosInstance } from "axios";

export * from "./static";

/**
 *
 * @param f
 * @returns
 * @deprecated 削除予定
 */
export const useOptX = <T>(f?: (d: any) => T) => {
  const factory: (d: unknown) => T = useMemo(
    () => (f ? f : (d: any): T => d),
    []
  );
  const API_VERSION = apiVersion;

  const api = useMemo(() => {
    return instance();
  }, [instance]);
  /**
   * apiのイニシャライズ
   */

  /**
   * 一件取得
   */
  const getOne = useCallback(async (): Promise<T | null> => {
    if (!api) return null;
    const res = await api({});
    return factory(res.data);
  }, []);

  /**
   * n件取得
   */
  // const getMany = useCallback(async (limit: number, skip: number): Promise<
  //   T[] | null
  // > => {
  //   if (!api) {
  //     console.error("no api");
  //     return null;
  //   }
  //   const res = await api({
  //     method: "GET",
  //     url: `/api/${API_VERSION}/contractors/${sessionStorage.contractorNo}/topdata`,
  //     params: {
  //       apikey: sessionStorage.token,
  //       q: {
  //         where: {
  //           $and: tags.map((tag: string) => {
  //             return { "bases.tags": tag };
  //           }),
  //         },
  //         skip: skip,
  //         limit: limit,
  //       },
  //     },
  //     headers: { "Content-Type": "application/json" },
  //   });
  //   return res.data.map((d: unknown) => factory(d));
  // }, []);

  /**
   * 一件作成
   */
  const postOne = useCallback(async (name: string) => {
    if (!api) {
      console.error("no api");
      return null;
    }

    const res = await api({
      method: "POST",
      url: `/api/${API_VERSION}/contractors/${sessionStorage.contractorNo}/optx`,
      params: {
        apikey: sessionStorage.token,
      },
      headers: { "Content-Type": "application/json" },
      data: {
        name,
      },
    });
    if (res.status === 200) {
      return res.data;
    } else {
      console.error(res);
      return res.data;
    }
  }, []);

  /**
   * postOneのエイリアス
   */
  const post = postOne;

  /**
   * 一件更新
   */
  // const updateOne = useCallback(async (data: T, cb) => {
  //   if (!api) return false;
  //   const d = data as any;
  //   if (!d[primaryKey]) {
  //     console.log("invalid data were given.");
  //     return false;
  //   }

  //   const res = await api({
  //     method: "POST",
  //     url: `/api/${API_VERSION}/contractors/${sessionStorage.contractorNo}/datagate`,
  //     params: {
  //       apikey: sessionStorage.token,
  //       assetTags: tags,
  //       sendToTopData: "true",
  //       strict: true,
  //     },
  //     headers: { "Content-Type": "application/json" },
  //     data: data,
  //   });
  //   if (res.status === 200) {
  //     return true;
  //   } else {
  //     console.error(res);
  //     return false;
  //   }
  // }, []);

  /**
   * updateOneのエイリアス
   */
  // const update = updateOne;

  /**
   * n件更新
   */
  //@todo TODO

  /**
   * 一件削除
   */
  // const removeOne = useCallback(async (primaryValue: number | string) => {
  //   if (!api) return false;
  //   // const q = {
  //   //   where:return {`contents.rawdata.${primaryKey}`: primaryKey}
  //   // };
  //   console.log("delete");
  //   console.log(primaryValue);
  //   const rest = await api({
  //     method: "DELETE",
  //     url: `/api/${API_VERSION}/contractors/${sessionStorage.contractorNo}/users/0/topdatas`,
  //     params: {
  //       apikey: sessionStorage.token,
  //       q: {
  //         where: {
  //           // $and: tags.map((tag: string) => {
  //           //   return { "bases.tags": tag };
  //           // }),
  //           $and: tags.map((tag: string) => {
  //             return { "bases.tags": tag };
  //           }),
  //           ["contents.rawdata." + primaryKey]: primaryValue,
  //         },
  //       },
  //     },
  //     headers: { "Content-Type": "application/json" },
  //   });
  // }, []);

  /**
   * removeOneのエイリアス
   */
  // const remove = removeOne;

  return {
    // getOne,
    // getMany,
    post,
    // postOne,
    // update,
    // updateOne,
    // remove,
    // removeOne,
  };
};
