import { SettingNavConf } from "src/cheetah/models/SettingNavConf";
import routes from "src/newtzero-console/routes.json";

export const NZGlobalNavConf: SettingNavConf = {
  sections: [
    {
      navigations: [
        {
          title: "Knowledge",
          link: routes.KNOWLEDGE_ROOT,
        },
        {
          title: "Assets",
          link: routes.ASSET_LIST,
        },
        {
          title: "Metrics",
          link: routes.METRIC_LIST,
        },
        {
          title: "DataView",
          link: routes.LAKEHOUSE,
          isRoot: true,
        },
      ],
    },
  ],
};
