import { APIConfigProps, createAPIConfig } from "./http";

export const createNewtZeroConfig = (props: APIConfigProps) => {
  const { apikey, userNoText } = props;
  const baseUrl = props.baseUrl;
  return createAPIConfig({
    baseUrl,
    apikey,
    userNoText,
  });
};
