import { ASTabs } from "allegro-ui";
import * as React from "react";
import { useParams } from "react-router-dom";
import { LCKihonDataPanel } from "../organisms/LCKihonDataPanel";

interface ILCSinglePageProps {}

const LCSinglePage: React.FunctionComponent<ILCSinglePageProps> = ({
  ...props
}) => {
  const params = useParams();
  return (
    <>
      {params.dataId}
      <div>Data Config</div>
      <LCKihonDataPanel />
      <div></div>
    </>
  );
};

export default LCSinglePage;
