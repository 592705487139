import { api } from "src/api";

export const fetchSystemLogs = async ({
  contractorNo,
  pipelineName,
  nodeId,
  apikey,
  offset,
}: {
  contractorNo: string | undefined;
  pipelineName: string;
  nodeId: string;
  apikey: string;
  offset?: string | undefined;
}) => {
  try {
    const res = await api({
      method: "GET",
      url: `/contractors/${contractorNo}/pipelines/${pipelineName}/nodes/${nodeId}/syslog`,
      params: {
        apikey: apikey,
        offset,
      },
    });
    console.log(res);
    return res;
  } catch (err) {
    console.error(err);
    return null;
  }
};
