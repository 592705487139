import React from "react";
import styled from "styled-components";
import { darken } from "polished";

import { NewtData } from "../../models/newtDataModel";
import { useXYProps } from "../../hooks/useXYProps";

import { ASNoDataPlaceHolder } from "allegro-ui";

// Table Columns
import { TableComponents } from "../LCTableColumnComponents";

const Container = styled.div<{ offset: number; fontSize?: number }>`
  width: 100%;
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  border: 1px solid ${(props) => props.theme.primaryBorderColor};

  background-color: inherit;
  > * {
    font-size: ${(props) => props.fontSize ?? props.theme.regularFontSizeMd}px;
  }
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
    --bg-opacity: 1;
    background-color: ${(props) => darken(0.1, props.theme.bgPrimaryColor)};
  }
`;

const Table = styled.table`
  /* border-spacing: 0 12px; */
  border-collapse: initial;
  width: 100%;
  table-layout: auto;
  tr > th:first-child,
  tr > td:first-child {
    position: sticky;
    left: 0;
  }

  background-color: inherit;

  > thead {
    background-color: inherit;
  }
`;

const NoDataContainer = styled(ASNoDataPlaceHolder)`
  width: 100%;
  text-align: left;
`;

const Row = styled.tr`
  width: 100%;
  background-color: inherit;
  padding: 4px 8px;
  margin: 8px 0;
  overflow: hidden;
  border-radius: 12px;
`;

const Col = styled.td<{ isScrolling: boolean; minimal?: boolean }>`
  flex: 1;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
  background: ${(props) => (props.minimal ? "none" : props.theme.cardColor)};
  vertical-align: center;
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: ${(props) => props.theme.primaryBorderColor};
  &:first-child {
    /* border-radius: ${(props) => (props.minimal ? "0" : "8px 0 0 8px")}; */
    padding-left: 16px;
    padding-right: 4px;

    box-shadow: ${(props) =>
      props.isScrolling
        ? (props.theme.darkMode ? [0.35] : [0.1])
            .map((num, idx) => {
              return `rgba(10, 10, 10, ${num}) ${1}px 0px`;
            })
            .join(", ")
        : "none"};

    border: ${(props) =>
      props.isScrolling
        ? `0 1px 0 0 solid ${props.theme.secondaryBorderColor}`
        : "none"};
    overflow: hidden;
    max-width: 30vw;
    /* min-width: 10vw; */
    border: 0px;
    border-width: 1px 0px 0px 0px;
    border-style: solid;
    border-color: ${(props) => props.theme.primaryBorderColor};
  }
  &:last-child {
    /* border-radius: ${(props) => (props.minimal ? "0" : "0 8px 8px 0")}; */
    border-width: 1px 0px 0px 0px;
  }
  border-bottom: ${(props) => (props.minimal ? "1px" : "0px")} solid
    ${(props) => props.theme.secondaryBorderColor};
  /* padding: 14px 8px; */
  padding: 20px 16px;
  /* padding-bottom: 6px; */
  white-space: nowrap;
  overflow-x: scroll;
`;

const ColH = styled(Col)`
  background-color: inherit;
  color: ${(props) => props.theme.descriptionTextColor};
  font-size: ${(props) => props.theme.regularFontSizeMd};
  padding: 20px 16px;
  /* padding-bottom: 6px; */
  white-space: nowrap;
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: ${(props) => props.theme.primaryBorderColor};
  /* min-width: 190px; */
`;

export interface ILCListViewComponentProps {
  newtData: NewtData[];
  uiData: any;
  minimal?: boolean;
  fontSize?: number;
}

const findValueByMongoKey = (nd: NewtData, key: string) => {
  if (!nd) {
    return;
  }
  let result: { [key: string]: any } = {};
  try {
    const keys = key.split(".");

    result = nd;
    for (const k of keys) {
      if (result === undefined) {
        break;
      }
      result = result[k];
    }
  } catch (err) {
    console.error(err);
  }

  return result;
};

export const LCListViewComponent: React.FC<ILCListViewComponentProps> = (
  props
) => {
  const { elementRef, isHorizontalScroll, startY, scrollRef } = useXYProps();

  const renderTableHead = () => {
    return (
      <Row>
        {props.uiData.tables.map((tableUiData: any, id: number) => {
          const minWidth = [
            "LCDeleteButtonComponent",
            "LCIconLinkCellComponent",
            "LCUserIconComponent",
          ].includes(tableUiData.component)
            ? 60
            : 230;
          return (
            <ColH
              key={`lc-list-view-col-${id}`}
              minimal={props.minimal}
              isScrolling={isHorizontalScroll}
              style={{ minWidth: `${minWidth}px` }}
            >
              {tableUiData.label}
            </ColH>
          );
        })}
      </Row>
    );
  };

  const tableRow = React.useCallback(
    (nd: NewtData, idx: number) => {
      const cols = props.uiData.tables.map((tableUiData: any, idy: number) => {
        const Component = TableComponents[tableUiData.component];

        //dynamic import
        //pass tableUiData.data and tableUiData.options
        let mapped: { [key: string]: any } = {};
        if (typeof tableUiData.data === "function") {
          mapped = tableUiData.data(nd);
        } else {
          for (const [key, value] of Object.entries(tableUiData.data)) {
            mapped[key] = findValueByMongoKey(nd, value as string);
          }
        }

        return (
          <Col
            key={`table-row-low-code-${idx}-${idy}`}
            minimal={props.minimal}
            isScrolling={isHorizontalScroll}
          >
            <Component
              key={`lc-list-view-component-${idx}-${idy}`}
              data={{ ...mapped, ...tableUiData.statics }}
              options={tableUiData.options}
            />
          </Col>
        );
      });

      return <Row key={`row-${idx}`}>{cols}</Row>;
    },
    [isHorizontalScroll, props.minimal, props.uiData.tables]
  );

  return (
    <Container ref={elementRef} offset={startY} fontSize={props.fontSize}>
      <Table ref={scrollRef}>
        <thead>{renderTableHead()}</thead>

        <tbody>
          {props.newtData.map((nd: any, id) => {
            //@todo TODO fix bad implementation
            //統合データに合わせたデータの修正をしている
            return tableRow(nd, id);
          })}
        </tbody>
      </Table>
      {props.newtData.length > 0 ? (
        <></>
      ) : (
        <>
          <NoDataContainer>
            データの取得に失敗しました。
            <br />
            データが存在しないか、リロードをお願いします。
          </NoDataContainer>
        </>
      )}
    </Container>
  );
};
