//Libs
import { ASButton, ASEasyTable, ASClassicTabs } from "allegro-ui";
import React, { useState, useEffect, useCallback } from "react";
import { useSlotActions, useOptX } from "src/xerver-room/hooks";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import StorageIcon from "@material-ui/icons/Storage";

//Components
import {
  XROptXDetails,
  XROptXSelectModal,
  XRStartSlotButton,
  XRStopSlotButton,
  XRVolumeSettingForm,
} from "../";

import { XRSettingBlock } from "../molecules/XRSettingBlock";
import { Divider, Tab } from "@material-ui/core";
import { Box, Typography } from "@material-ui/core";

////icons
import SettingsApplicationsOutlinedIcon from "@material-ui/icons/SettingsApplicationsOutlined";
import AlbumOutlinedIcon from "@material-ui/icons/AlbumOutlined";
import SettingsInputComponentOutlinedIcon from "@material-ui/icons/SettingsInputComponentOutlined";
import LanguageIcon from "@material-ui/icons/Language";
import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined";
import DeleteIcon from "@material-ui/icons/Delete";

//States
import {
  cursorPipelineIdState,
  cursorSlotIdState,
  slotStatusState,
  slotState,
  cursorSlotTypeState,
} from "src/xerver-room/recoils";

//Types
import { OptX } from "../../models";
import { useMyRole } from "src/portal/hooks/useMyRole";

import { XREnvironmentSettingForm } from "./XREnvironmentSettingForm";
import { XRPortSettingForm } from "./XRPortSettingForm";
import { XRHardwareSetting } from "./XRHardwareSetting";
import { CHTabComponentProps } from "src/cheetah/components/molecules/CHTabs";
import { XRNameSettingForm } from "./XRNameSettinfForm";
import { NZDeleteButton } from "src/newtzero-console/components/molecules/NZDeleteButton";
import XRLinkObjectEditForm from "./XRLinkObjectEditForm";
import { cursorLinkObjectIdState } from "src/xerver-room/recoils/linkObjectState";

const Container = styled.div`
  width: 100%;
  background: ${(props) => props.theme.cardColor};
  > * {
    width: 100%;
  }
  max-height: calc(100vh - 70px);
  overflow-y: scroll;
`;

const Title = styled.h1`
  margin-top: 30px;
  font-size: 18px;
  margin-bottom: 24px;
  text-align: center;
`;

const SubTitle = styled.h1`
  margin-top: 18px;
  font-size: 11px;
  opacity: 0.4;
  text-align: center;
`;

const OptXSelectorArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  padding: 0px 24px;

  text-align: center;
  > * {
    box-shadow: 9px 8px 10px #1e1e1e, 0 0 5px 0 #2f2f2f, -4px 0 5px 0 #2f2f2f,
      -4px -6px 15px #2f2f2f;
  }
`;

const SelectOptXButton = styled(ASButton)`
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(-38deg, #2d2d2d, #262626);
`;

const Block = styled.section`
  width: 100%;
  padding: 24px;
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  border-bottom: 1px solid #333;
  border: 1px solid #525252;
  h3 {
    margin-bottom: 8px;
  }
`;

const NewmoBlock = styled(Block)`
  border: solid #363636;
  border-width: 1px 0 0 1px;
  position: relative;
  padding: 24px;
  background: linear-gradient(-38deg, #2d2d2d, #262626);
  background: ${(props) => props.theme.bgPrimaryColor};
  box-shadow: 9px 8px 10px #1e1e1e, 0 0 5px 0 #2f2f2f, -4px 0 5px 0 #2f2f2f,
    -4px -6px 15px #2f2f2f;
`;

const ALink = styled.a`
  color: ${(props) => props.theme.primaryColor};
  word-break: break-word;
`;

const ControlButtons = styled.div`
  position: absolute;
  left: 12px;
  top: 12px;

  > * {
    background: linear-gradient(145deg, #2d2d2d, #262626);
    box-shadow: 2px 2px 6px #151515, -2px -2px 6px #3f3f3f;
  }
`;

const PBox = styled.div`
  padding: 0 24px;
`;

const Warning = styled.div`
  text-align: center;
  margin-top: 8px;
  font-size: 12px;
  color: ${(props) => props.theme.negativeColor};
`;

const StatusArea = styled.div`
  border-radius: 8px;

  text-align: center;
  > span {
    margin-top: 8px;
    font-size: 11px;
  }
`;

const RunningBudge = styled.span`
  color: ${(props) => props.theme.positiveColor};
  font-weight: bold;
  font-size: 11px;
`;
const StoppingBudge = styled.span`
  color: ${(props) => props.theme.negativeColor};
  font-weight: bold;
  font-size: 11px;
`;

const TabWrapper = styled.div`
  margin: 24px 0px;
  padding: 0px 24px;
  padding-top: 24px;
  /* border-top: 1px solid ${(props) => props.theme.secondaryBorderColor}; */
  border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};
`;

const StyledBox = styled(Box)`
  padding: 8px 24px;
`;

const TabPanel: React.FC<any> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <StyledBox p={3}>
          <Typography>{children}</Typography>
        </StyledBox>
      )}
    </div>
  );
};

const StyledTabPanel = styled(TabPanel)`
  background: ${(props) => props.theme.cardColor};
`;

const TabContainer = styled.div`
  > * {
    margin-bottom: 20px;
  }
`;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type Props = {};
export const XRSlotInspector: React.FC<Props> = (props) => {
  const cursorSlotId = useRecoilValue(cursorSlotIdState);
  const cursorSlotType = useRecoilValue(cursorSlotTypeState);
  const cursorLinkObjectId = useRecoilValue(cursorLinkObjectIdState);
  const cursorSlot = useRecoilValue(slotState(cursorSlotId));
  const slotStatus = useRecoilValue(slotStatusState(cursorSlot.slotId));
  const cursorPipelineId = useRecoilValue(cursorPipelineIdState);

  //useReacoilValueはエラーが起きる
  //https://github.com/facebookexperimental/Recoil/issues/439
  const slot = useSlotActions(cursorSlot.slotId);
  const optX = useOptX(cursorSlot.optXId);
  const role = useMyRole();

  const [showOptXBrowser, setShowOptXBrowser] = useState(false);
  const [tabId, setTabId] = React.useState(0);
  const [canEditPipeline, setCanEditPipeline] = useState(false);

  const udpatePipelineEditableState = useCallback(async () => {
    const ok = await role.canUpdatePipeline(cursorPipelineId);
    setCanEditPipeline(ok);
  }, [cursorPipelineId, role]);

  const handleOptXSubmit = (ids: string[]) => {
    slot.setOptX(ids[0]);
    setShowOptXBrowser(false);
  };

  const handleChangeTab = (event: any, newValue: number) => {
    if (tabId !== newValue) {
      setTabId(newValue);
    }
  };

  useEffect(() => {
    udpatePipelineEditableState();
  }, [udpatePipelineEditableState]);

  useEffect(() => {
    slot.setSlot(cursorSlot);
  }, [cursorSlot, slot]);

  const NetworksTab: React.FC = () => {
    return (
      <TabContainer>
        <XRSettingBlock title={"Hardware"}>
          <XRHardwareSetting
            slotId={slot.slotId}
            disabled={inputDisabled}
            defaultValues={{ serverId: cursorSlot.serverId }}
          />
        </XRSettingBlock>

        <XRSettingBlock
          title={
            <>
              <LanguageOutlinedIcon />
              Network
            </>
          }
        >
          <XRPortSettingForm slotId={slot.slotId} disabled={inputDisabled} />
        </XRSettingBlock>
        <XRSettingBlock
          title={
            <>
              <StorageIcon /> Volumes
            </>
          }
        >
          <XRVolumeSettingForm slotId={slot.slotId} disabled={inputDisabled} />
        </XRSettingBlock>
      </TabContainer>
    );
  };

  const EnvTab: React.FC = () => {
    return (
      <TabContainer>
        <XRSettingBlock
          title={
            <>
              <SettingsApplicationsOutlinedIcon /> Environments
            </>
          }
        >
          <XREnvironmentSettingForm
            slotId={slot.slotId}
            disabled={inputDisabled}
          />
        </XRSettingBlock>
      </TabContainer>
    );
  };

  const InfoTab: React.FC = () => {
    return (
      <TabContainer>
        <XRSettingBlock title="Name">
          <XRNameSettingForm
            defaultValue={cursorSlot.name}
            disabled={!canEditPipeline}
            onSubmit={async (name) => {
              await slot.updateName(name);
            }}
          />
        </XRSettingBlock>
        <XRSettingBlock
          title={
            <>
              <LanguageIcon /> URL
            </>
          }
        >
          {slot.inputs.map((input) => {
            return (
              <>
                <div>{input.name}</div>
                <ALink href={`https://${input.url}`} target="_blank">
                  https://{input.url}
                </ALink>
              </>
            );
          })}
        </XRSettingBlock>

        <XRSettingBlock
          title={
            <>
              <SettingsInputComponentOutlinedIcon /> Inputs
            </>
          }
        >
          <div>
            <ASEasyTable
              tableData={slot.inputs.map((input) => {
                return {
                  colName: input.name,
                  colValue: `${input.portOutside}:${input.portInside}`,
                };
              })}
            ></ASEasyTable>
          </div>
        </XRSettingBlock>

        <XRSettingBlock
          title={
            <>
              <SettingsInputComponentOutlinedIcon /> Outputs
            </>
          }
        >
          {slot.outputs.map((output, id) => (
            <div key={`output-line-${id}`}>
              <span>{output.name}</span>
              <ASEasyTable
                tableData={
                  Array.isArray(output.targets)
                    ? output.targets.map((target) => {
                        return {
                          colName: target.name,
                          colValue: target.slotId,
                        };
                      })
                    : []
                }
              ></ASEasyTable>
            </div>
          ))}

          <ASButton
            disabled={inputDisabled}
            kind="secondary"
            onClick={() => slot.resetConnections()}
          >
            CLEAR ALL
          </ASButton>
        </XRSettingBlock>

        <NZDeleteButton
          kind="negative"
          disabled={
            slotStatus === "running" || inputDisabled || !canEditPipeline
          }
          onSubmit={() => {
            slot.deleteSlot(cursorSlot);
          }}
        >
          <DeleteIcon /> DELETE
        </NZDeleteButton>
      </TabContainer>
    );
  };

  const tabs: CHTabComponentProps[] = [
    {
      label: "Networks",
      component: <NetworksTab />,
    },
    {
      label: "Environment",
      component: <EnvTab />,
    },
    {
      label: "Info",
      component: <InfoTab />,
    },
  ];

  const inputDisabled = !canEditPipeline || slotStatus === "running";

  const renderContents = () => {
    switch (cursorSlotType) {
      case "slot":
        return (
          <>
            <PBox>
              <SubTitle>
                ID:{cursorSlot.id !== "" ? cursorSlot.id : "選択されていません"}
              </SubTitle>
              <Title>{cursorSlot.name ?? "No Name"}</Title>

              <Divider />
            </PBox>
            {cursorSlot.id ? (
              <PBox>
                {cursorSlot.optXId ? (
                  <NewmoBlock>
                    {canEditPipeline ? (
                      <ControlButtons>
                        {slotStatus === "running" ? (
                          <XRStopSlotButton slotId={cursorSlot.slotId} />
                        ) : (
                          <XRStartSlotButton slotId={cursorSlot.slotId} />
                        )}
                      </ControlButtons>
                    ) : null}

                    {cursorSlot.optXId && optX.buildHistory ? (
                      <XROptXDetails optX={optX as OptX} compact />
                    ) : (
                      <span>---</span>
                    )}

                    <StatusArea>
                      {slotStatus === "running" ? (
                        <>
                          <RunningBudge>● RUNNING</RunningBudge>
                          <span></span>
                        </>
                      ) : (
                        <StoppingBudge>□ STOPPED</StoppingBudge>
                      )}
                    </StatusArea>
                  </NewmoBlock>
                ) : (
                  <></>
                )}

                <OptXSelectorArea>
                  <SelectOptXButton
                    kind="secondary"
                    disabled={slotStatus === "running" || inputDisabled}
                    onClick={() => setShowOptXBrowser(true)}
                  >
                    <AlbumOutlinedIcon /> Select OptX
                  </SelectOptXButton>
                </OptXSelectorArea>
              </PBox>
            ) : (
              <></>
            )}
            {cursorSlot.id !== "" ? (
              <>
                <TabWrapper>
                  <ASClassicTabs value={tabId} onChange={handleChangeTab}>
                    {tabs.map((tab, id) => {
                      return <Tab label={tab.label} {...a11yProps(id)} />;
                    })}
                  </ASClassicTabs>
                </TabWrapper>

                {tabs.map((tab, idx) => {
                  return (
                    <StyledTabPanel value={tabId} index={idx}>
                      {tab.component}
                    </StyledTabPanel>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </>
        );
      case "linkObject":
        return (
          <>
            <PBox>
              <SubTitle>
                ID:
                {cursorLinkObjectId !== ""
                  ? cursorLinkObjectId
                  : "選択されていません"}
              </SubTitle>
              {/* <Title>{cursorSlot.name ?? "No Name"}</Title> */}

              <Divider />
            </PBox>
            <PBox>
              <XRLinkObjectEditForm linkObjectId={cursorSlotId} />
            </PBox>
          </>
        );
    }
  };

  return (
    <Container>
      {renderContents()}

      <Divider />
      <XROptXSelectModal
        show={showOptXBrowser}
        onClose={() => setShowOptXBrowser(false)}
        onSubmit={handleOptXSubmit}
      />
    </Container>
  );
};
