import { AxiosInstance } from "axios";

export type BundeleDataSourceProps = {
  bundleId: string;
  version: string;
  refName: string;
  newtQuery: any;
};

export const fetchData = async (
  instance: AxiosInstance,
  props: BundeleDataSourceProps
) => {
  const { bundleId, version, refName, newtQuery } = props;
  const url = `/bundles/${bundleId}/${version}/${refName}/datasource`;
  try {
    const res = await instance.post(url, newtQuery);
    return res.data;
  } catch (err) {
    console.error(err);
  }
};
