//Libs
import React from "react";
import styled from "styled-components";
//Components
import allegrobotIcon from "../../assets/img/allegrobotIcon.svg";
//States
//Types

const FlexBox = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
`;

const LogoImg = styled.img`
  width: 40px;
  margin-right: 8px;
`;

const AppName = styled.h1`
  font-size: 24px;
  font-weight: bold;
`;

const Caption = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.primaryColor};
`;

type Props = {};
export const OELogo: React.FC<Props> = (props) => {
  return (
    <FlexBox>
      {/* <LogoImg src={allegrobotIcon} alt="" /> */}
      <div>
        <AppName>Knowledge</AppName>
        <Caption>AllegroSmart, Inc.</Caption>
      </div>
    </FlexBox>
  );
};
