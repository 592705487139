import { useState, useRef, useEffect, useCallback, RefObject } from "react";

// 引数のtargetProperty をDOMRectのもつPropertyに限定する
type DOMRectProperty = keyof Omit<DOMRect, "toJSON">;

// RefObjectの型は div, span, p, input などのさまざまなHTML要素に対応できるようにextendsで制限をかけつつ抽象化
export const useGetElementProperty = <T extends HTMLElement>(
  elementRef: RefObject<T>
) => {
  const getElementProperty = useCallback(
    (targetProperty: DOMRectProperty): number => {
      const clientRect = elementRef.current?.getBoundingClientRect();
      if (clientRect) {
        return clientRect[targetProperty];
      }

      // clientRect が undefined のときはデフォルトで0を返すようにする
      return 0;
    },
    [elementRef]
  );

  return {
    getElementProperty,
  };
};

export const useXYProps = () => {
  const elementRef = useRef(null);
  const scrollRef = useRef(null);
  const [isHorizontalScroll, setIsHorizontalScroll] = useState(false);
  const [startY, setStartY] = useState(100);

  const { getElementProperty } =
    useGetElementProperty<HTMLDivElement>(scrollRef);

  /**
   * スクロール判定
   */
  useEffect(() => {
    const handleScroll = () => {
      const element: any = elementRef.current;
      if (element.scrollLeft > 0) {
        setIsHorizontalScroll(true);
      } else {
        setIsHorizontalScroll(false);
      }
    };

    const element: any = elementRef.current;
    element.addEventListener("scroll", handleScroll);

    return () => {
      element.removeEventListener("scroll", handleScroll);
    };
  }, [isHorizontalScroll]);

  useEffect(() => {
    const th = getElementProperty("y");
    setStartY(th);
  }, [getElementProperty]);

  return { elementRef, isHorizontalScroll, startY, scrollRef };
};
