import React from "react";
import { useNavigate } from "react-router-dom";
import { TableCell } from "@material-ui/core";
import { ASTableRow, ASChip, ASIconButton } from "allegro-ui";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { FTEvalChip } from "../atoms";
import styled from "styled-components";
import { RiskAssessmentFormData } from "src/fortress/models/RiskAssessment";
import { shapeToSeireki, urlDate } from "../../utils/time";
import { darken, lighten } from "polished";

type Props = {
  assessmentFormData: any;
};

export const DisabledChip = styled(ASChip)`
  color: #909090;
  background-color: ${(props) =>
    props.theme.darkMode ? darken(0.5, "#555") : lighten(0.52, "#555")};
`;

// const colors: { [key: string]: any } = {
//   問題なし: "positive",
//   気になる: "caution",
//   要経過観察: "negative",
//   虐待の危惧あり: "negative",
//   虐待あり: "negative",
//   身体的虐待: "negative",
//   精神的虐待: "negative",
//   要保護: "caution",
//   要支援: "negative",
//   特定妊婦: "negative",
//   登録なし: "positive",
// };
//全員分のアセスメント一覧リストの一つの部品を表している。

export const FTAllAssesmentListItem: React.FC<Props> = (props) => {
  const history = useNavigate();
  const { assessmentFormData } = props;

  //一つの部品（列）を押すと、そのアセスメント詳細画面に飛ぶことを表している。

  const handleRowClick = () => {
    history(
      `/child/${assessmentFormData.userId}/${assessmentFormData.created}/AssesmentDetail`
    );
  };

  return (
    <ASTableRow onClick={() => handleRowClick()}>
      <TableCell>{assessmentFormData.userId}</TableCell>
      <TableCell>{assessmentFormData.formName}</TableCell>
      <TableCell>
        {shapeToSeireki(new Date(assessmentFormData.created))}
      </TableCell>

      <TableCell
        align="left"
        padding="checkbox"
        onClick={() => handleRowClick()}
      >
        <ASIconButton kind="white">
          <ArrowForwardIosIcon fontSize="small" />
        </ASIconButton>
      </TableCell>
    </ASTableRow>
  );
};
