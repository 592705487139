import React, { useState } from "react";
import styled from "styled-components";
import { FormContainer } from "../styled";

import LCSortComponent from "../components/LCSortComponent";
import { ASBrowserUiConf } from "../../../types/LowCodeUiDefinition";
import LCFilterForm from "../components/LCFilterForm";

const Container = styled.div`
  height: 75vh;
  overflow-y: scroll;
  width: 300px;
  /* border-right: 1px solid ${(props) => props.theme.bgSecondaryColor}; */
`;

interface ILCFilterContainerProps {
  name: string;
  uiData: ASBrowserUiConf;
}

const LCFilterContainer: React.FunctionComponent<ILCFilterContainerProps> = (
  props
) => {
  const [showForm, setShowForm] = useState(true);

  // const toggleForm = useCallback(() => {
  //   setShowForm((current) => !current);
  // }, []);

  const renderExtFilter = () => {
    return showForm ? (
      <>
        {/* <FTAlertFilter /> */}
        <LCSortComponent name={props.name} />
      </>
    ) : (
      <></>
    );
  };

  return (props.uiData.filters ?? []).length > 0 ? (
    <Container>
      <FormContainer>
        {showForm ? (
          <LCFilterForm name={props.name} uiConf={props.uiData} />
        ) : (
          <></>
        )}
        {props.uiData.useDeprecated ? renderExtFilter() : <></>}
      </FormContainer>
    </Container>
  ) : (
    <></>
  );
};

export default LCFilterContainer;
