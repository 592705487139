import * as React from "react";
import { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styled from "styled-components";
import {
  ReferenceData,
  createReference,
  getReferenceValue,
} from "../../models/DynamicReference";

const StyledFormLabel = styled(FormControlLabel)`
  color: ${(props) => props.theme.secondaryOnText};
`;

const StyledCheckBox = styled(Checkbox)`
  color: ${(props) => props.theme.primaryColor};
  &.Mui-checked {
    color: ${(props) => props.theme.primaryColor};
  }

  &.MuiCheckbox-root {
    padding: 1px;
    margin-left: 8px;
  }
`;
interface ILCCheckboxFieldComponentProps {
  name: string;
  active: string[];
  values: any;
  editable?: boolean;
  onChange: (active: string[]) => void;
}

export const ASCheckboxFormFieldComponent: React.FunctionComponent<ILCCheckboxFieldComponentProps> =
  ({ active, onChange, ...props }) => {
    const [actives, setActives] = useState<string[]>(active ?? []);
    const [selections, setSelections] = useState<ReferenceData[]>([]);

    const values =
      typeof props.values === "string"
        ? createReference(props.values)
        : props.values;
    const editable = props.editable ?? true;
    const hanldeChange = (name: string, isChecked: boolean) => {
      if (editable) {
        if (isChecked && !actives.includes(name)) {
          setActives((prev) => [...prev, name]);
        } else if (!isChecked) {
          setActives((prev) => prev.filter((n) => n !== name));
        }
      }
    };

    const init = async () => {
      const selections = await getReferenceValue(values);
      if (selections.length > 0) {
        setSelections(selections);
      }
    };

    useEffect(() => {
      init();
      onChange(actives);
    }, [actives]);

    return (
      <>
        {/* <h1>{props.name}</h1> */}
        {selections.map((selection, id) => {
          return (
            <StyledFormLabel
              key={`checkbox-${selection}-${id}`}
              control={
                <StyledCheckBox
                  checked={actives.includes(selection.value)}
                  onChange={(e) => {
                    hanldeChange(selection.value, e.target.checked);
                  }}
                  disabled={!editable}
                  // readOnly={!editable}
                  name={selection.label}
                />
              }
              label={selection.label}
            />
          );
        })}
      </>
    );
  };
