//Libs
import React, { useState } from "react";
import { useCodeBoxMediator } from "src/opt-editor/hooks/useCodeBoxMediator";

//Components
import { ASButton, ASSelect, ASModal } from "allegro-ui";
//States
//Types

type Props = {};
export const OECommitCodeBoxButton: React.FC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const actions = useCodeBoxMediator();

  const handleCommit = () => {
    actions.commitCodeBox();
  };

  return (
    <>
      <ASButton
        style={{ flex: 1, margin: "3px" }}
        kind={"primary"}
        onClick={handleCommit}
      >
        COMMIT
      </ASButton>
      <ASModal open={showModal}>
        <div>コミットします</div>
      </ASModal>
    </>
  );
};
