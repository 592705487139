export const findValueByMongoKey = (nd: any, key: string) => {
  if (!nd) {
    return;
  }
  let result: { [key: string]: any } = {};
  try {
    const keys = key.split(".");

    result = nd;
    for (const k of keys) {
      if (result === undefined) {
        break;
      }
      result = result[k];
    }
  } catch (err) {
    console.error(err);
  }

  return result;
};
