//右下のはてなボタンのアイコン

import React, { useState } from "react";
import styled from "styled-components";
import { ASIconButton } from "allegro-ui";
import HelpIcon from "@material-ui/icons/Help";

const Ques = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 10;
`;

const QuesCon = styled.div`
  position: fixed;
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
  bottom: 120px;
  right: 150px;
  z-index: 10;
  height: 250px;
  width: 650px;
  padding-top: 50px;
  background-color: ${(props) => props.theme.bgPrimaryColor};
  border: 0.5px solid;
  border-radius: 30px;
`;

const CircleOne = styled.div`
  position: fixed;
  bottom: 110px;
  right: 110px;
  z-index: 10;
  height: 25px;
  width: 25px;
  background-color: ${(props) => props.theme.bgPrimaryColor};
  border: 0.5px solid;
  border-radius: 100%;
`;

const CircleTwo = styled.div`
  position: fixed;
  bottom: 85px;
  right: 80px;
  z-index: 10;
  height: 18px;
  width: 18px;
  background-color: ${(props) => props.theme.bgPrimaryColor};
  border: 0.5px solid;
  border-radius: 100%;
`;

const Table = styled.table`
  table-layout: fixed;
  font-size:14px;
  /* width: 100%; */
  margin-top:auto;
  /* color: ${(props) => props.theme.primaryColor}; */
`;

const Tr = styled.tr`
  /* padding: 8px; */
  text-align: center;
  border-bottom: 1px solid;
  border-color: ${(props) => (props.theme.darkMode ? "#4e4e4e" : "#ccc")};
`;

const Td = styled.td`
  padding: 8px 8px;
`;

const Thead = styled.thead`
  border-bottom: 2px solid;
  border-color: ${(props) => (props.theme.darkMode ? "#4e4e4e" : "#ccc")};
`;

const Caption = styled.h6`
  color: ${(props) => (props.theme.darkMode ? "#888" : "#999")};
  font-size: 14px;
  margin-top: 8px;
  padding-bottom: 30px;
`;

const Content = styled.div`
  padding-left: 40px;
`;

type Props = {};

//画面の右下にあるはてなボタンのコンポーネントを表している。

export const Question: React.FC<Props> = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const QuesContent = open ? (
    <>
      <QuesCon onClick={() => setOpen(false)}>
        <Content>
          <Table>
            <Thead>
              <Tr>
                <Td>検査１(見落とさないAI)</Td>
                <Td>検査２(精度が高いが見落とすAI)</Td>
                <Td>実際にリスクのある可能性</Td>
              </Tr>
            </Thead>
            <tbody>
              <Tr>
                <Td>50以上</Td>
                <Td>50以上</Td>
                <Td>高</Td>
              </Tr>

              <Tr>
                <Td>50以下</Td>
                <Td>50以上</Td>
                <Td>高</Td>
              </Tr>

              <Tr>
                <Td>50以上</Td>
                <Td>50以下</Td>
                <Td>低</Td>
              </Tr>
            </tbody>
          </Table>
          <Caption>
            ※数値が50以上だとAIはリスクありと判断したことを意味します。
          </Caption>
        </Content>
      </QuesCon>
    </>
  ) : (
    <></>
  );

  const RoundOne = open ? (
    <>
      <CircleOne onClick={() => setOpen(false)}></CircleOne>
    </>
  ) : (
    <></>
  );

  const RoundTwo = open ? (
    <>
      <CircleTwo onClick={() => setOpen(false)}></CircleTwo>
    </>
  ) : (
    <></>
  );

  return (
    <>
      <Ques>
        <ASIconButton kind="primary" onClick={handleOpen}>
          <HelpIcon />
        </ASIconButton>
      </Ques>
      {RoundOne}
      {RoundTwo}
      {QuesContent}
    </>
  );
};
