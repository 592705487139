import React from "react";
import {
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  ComposedChart,
  ReferenceDot,
  ResponsiveContainer,
} from "recharts";

export type ASLineVisProps = {
  data: any;
  referenceId: number;
};

export const ASLineVis: React.FC<ASLineVisProps> = ({ data, referenceId }) => {
  const keys = Object.keys(data[0]);
  referenceId = referenceId || data.length - 1 || 0;

  return data ? (
    <>
      <ResponsiveContainer width={"100%"} height={300}>
        <ComposedChart data={data}>
          <CartesianGrid stroke="#ddd" vertical={false} strokeWidth={1} />
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={"black"} stopOpacity={0.8} />
              <stop offset="70%" stopColor={"black"} stopOpacity={0} />
            </linearGradient>
          </defs>
          <Line
            type="natural"
            dataKey={keys[1]}
            stroke={"red"}
            strokeWidth={2}
            dot={{ r: 2 }}
            activeDot={{ r: 6 }}
          ></Line>
          <Area
            type="natural"
            dot={{ r: 2 }}
            dataKey={keys[2]}
            stroke={"black"}
            strokeWidth={2}
            activeDot={{ r: 6 }}
            fillOpacity={1}
            fill="url(#colorUv)"
          />
          <XAxis dataKey="name" axisLine={false} interval={7} tickMargin={8} />
          <YAxis axisLine={false} domain={[0, 1]} tickLine={false} />
          <ReferenceDot
            x={data[referenceId] ? data[referenceId].name : null}
            y={data[referenceId] ? data[referenceId][keys[1]] : null}
            r={6}
            fill={"red"}
            alwaysShow={true}
          />
          <ReferenceDot
            x={data[referenceId] ? data[referenceId].name : null}
            y={data[referenceId] ? data[referenceId][keys[2]] : null}
            r={6}
            fill={"black"}
            alwaysShow={true}
          />
          <Tooltip />
        </ComposedChart>
      </ResponsiveContainer>
      <div>
        <ul>
          <li>あり</li>
          <li>なし</li>
        </ul>
      </div>
    </>
  ) : (
    <>no data</>
  );
};
