import React, { useState, useCallback } from "react";
import styled from "styled-components";
import AddIcon from "@material-ui/icons/Add";
import { Grid } from "@material-ui/core";
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useRecoilState,
  useSetRecoilState,
} from "recoil";

import { api } from "src/api";

//za
//components
//
import { ASModal, ASIconButton } from "allegro-ui";
import {
  pipelineListRequestId,
  pipelineListState,
  pipelineSearchWordState,
} from "src/xerver-room/recoils";
import { PipelineCard } from "../molecules";
import { CreatePipelineModal } from "../organisms";
import { PipelineX } from "src/xerver-room/models";
import { usePipelineList } from "src/xerver-room/hooks";
import { userState } from "src/root/recoils/userState";
import FTSearchField from "src/fortress/components/atoms/FTSeachField";
import { LoadingBox } from "src/opt-editor/components/atoms/OELoddingBox";
import { useMyRole } from "src/portal/hooks/useMyRole";
import { MDNoDataPlaceHolder } from "src/medican/components/atoms";
import { DeleteConfirmModalContents } from "src/kinzaza/components";
import { OESearchTextField } from "src/opt-editor/components";
import { XRPageTemplate } from "./XRPageTemplate";

const Root = styled.div``;

const Body = styled.div`
  padding: 12px;
`;

const RightBottomContent = styled.div`
  position: fixed;

  right: 24px;
  bottom: 24px;
`;

type Props = {};

const PipelineAddButton = styled(ASIconButton)`
  border-radius: 100px;
`;

export const XRPipelineListPageTemplate: React.FC<Props> = (props) => {
  const auth = useRecoilValue(userState);
  const [reqId, setReqId] = useRecoilState(pipelineListRequestId);
  const pipelineList = usePipelineList();
  const pipelinesLoadable = useRecoilValueLoadable(pipelineListState);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchWord, setSearchWord] = useRecoilState(pipelineSearchWordState);
  const role = useMyRole();

  const [deletePipeline, setDelete] =
    useState<PipelineX | undefined>(undefined);

  const handleCreateModalOpen = () => {
    setShowCreateModal(true);
  };
  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };

  const handleDeleteModalOpen = useCallback((pipeline: PipelineX) => {
    setDelete(pipeline);
    setShowDeleteModal(true);
  }, []);

  const handleDeleteModalClose = () => {
    setDelete(undefined);
    setShowDeleteModal(false);
  };

  // パイプラインの新規作成
  const handleCreate = (formdata: { pipelineName: string }) => {
    pipelineList.add(formdata.pipelineName);
    handleCreateModalClose();
  };

  // パイプラインの削除
  const handleDelete = useCallback(
    async (pipeline: PipelineX | undefined) => {
      if (pipeline !== undefined) {
        try {
          const contractor: string =
            typeof auth.contractorNo === "string" ? auth.contractorNo : "";
          const apiKey: string =
            typeof auth.token === "string" ? auth.token : "";
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const res = await api({
            method: "DELETE",
            url: `/contractors/${contractor}/pipelinex/${pipeline.id}`,
            params: {
              apikey: apiKey,
            },
          });
          setReqId(reqId + 1);
        } catch (err) {
          console.error(err);
          // setError(err);
        }
      }
      handleDeleteModalClose();
    },

    [auth.contractorNo, auth.token, reqId, setReqId]
  );

  const PipelineGroup = () => {
    const pipelines =
      pipelinesLoadable.state === "hasValue" ? pipelinesLoadable.contents : [];
    if (pipelinesLoadable.state === "hasValue" && pipelines.length === 0) {
      return (
        <MDNoDataPlaceHolder>
          データがありません。リロードをお試しください。
        </MDNoDataPlaceHolder>
      );
    }

    return pipelinesLoadable.state === "loading" ? (
      <LoadingBox />
    ) : (
      pipelines.map((pipeline, id) => {
        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={`pcd-${pipeline.name}-${id}`}
          >
            <PipelineCard
              pipeline={pipeline}
              handleDeleteModal={handleDeleteModalOpen}
            ></PipelineCard>
          </Grid>
        );
      })
    );
  };
  return (
    <Root>
      <XRPageTemplate
        title="Xervice"
        headerComponents={
          <OESearchTextField
            defaultValue={searchWord}
            placeholder="Xervice Name"
            onSubmit={(event: any) => {
              setSearchWord(event.target.value);
            }}
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                setSearchWord(event.target.value);
              }
            }}
          />
        }
      >
        <Body>
          <Grid container spacing={2}>
            {PipelineGroup()}
          </Grid>
        </Body>
        <ASModal
          title="New Xervice"
          open={showCreateModal}
          onClose={setShowCreateModal}
        >
          <CreatePipelineModal onSubmit={handleCreate}></CreatePipelineModal>
        </ASModal>
        <ASModal open={showDeleteModal} onClose={setShowDeleteModal}>
          <DeleteConfirmModalContents
            onSubmit={() => handleDelete(deletePipeline)}
            onCancel={handleDeleteModalClose}
          />
        </ASModal>
        <RightBottomContent>
          {role.canCreatePipelineX ? (
            <PipelineAddButton kind={"primary"} onClick={handleCreateModalOpen}>
              <AddIcon />
            </PipelineAddButton>
          ) : null}
        </RightBottomContent>
      </XRPageTemplate>
    </Root>
  );
};
