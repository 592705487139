import React from "react";
import { NTGlobalNav } from "../organisms/NTGlobalNav";
type Props = {};
export const NTMainViewTemplate: React.FC<Props> = ({ children }) => {
  return (
    <div>
      <NTGlobalNav />
      {children}
    </div>
  );
};
