import LCPagerComponent from "../LCPagerComponent/component";
import LCFilterContainer from "../LCFilterComponent/containers/LCFilterContainer";
import { LCListViewComponent } from "../LCListViewComponent/LCListViewComponent";
import styled from "styled-components";
import React from "react";
import { useDataBrowser } from "../../hooks/useDataBrowser";
import { ASBrowserUiConf } from "../../types/LowCodeUiDefinition";
import { LCLoadingBoard } from "../LCLoadingBoardComponent";

export interface IASLowCodeDataBrowserContainerProps {
  name: string;
  uiConf: ASBrowserUiConf;
  fontSize?: number;
}

const FlexBox = styled.div<{ fontSize?: number }>`
  width: 100%;
  height: 100%;
  overflow: scroll;
  display: flex;

  background-color: inherit;
  > * {
    font-size: ${(props) =>
      props.fontSize ? props.fontSize : props.theme.regularFontSizeMd}px;
  }

  > *:nth-child(2) {
    margin-left: 24px;
    flex: 6;
  }
`;

const RelativeBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: inherit;

  overflow: hidden;
`;

const HorizontalScrollBox = styled.div`
  width: 100%;
  min-height: 100%;
  overflow: scroll;
  background-color: inherit;
  height: 100%;
`;

const PagerLayout = styled.div`
  /* position: fixed; */
  right: 24px;
  width: 100%;
  bottom: 0px;
  z-index: 10;
`;

const StaticPagerLayout = styled.div`
  z-index: 10;
  margin-top: 12px;
`;

export const ASLowCodeDataBrowserContainer: React.FC<IASLowCodeDataBrowserContainerProps> =
  (props) => {
    const { loadablePageCount, newtData, pageIndex, handlePage } =
      useDataBrowser({ key: props.name, ui: props.uiConf });
    const ui = props.uiConf;

    const renderPager = () => {
      if (!ui.pager) {
        return <></>;
      }
      const Component =
        ui.pager.position === "static" ? StaticPagerLayout : PagerLayout;
      return (
        <Component>
          <LCPagerComponent
            count={loadablePageCount}
            page={pageIndex}
            onChange={handlePage}
          />
        </Component>
      );
    };

    return (
      <>
        <FlexBox>
          <React.Suspense fallback={<LCLoadingBoard />}>
            <LCFilterContainer name={props.name} uiData={ui} />
          </React.Suspense>
          <RelativeBox>
            <HorizontalScrollBox>
              <React.Suspense fallback={<LCLoadingBoard />}>
                {newtData.state === "loading" ? (
                  <>
                    <LCLoadingBoard />
                  </>
                ) : (
                  <LCListViewComponent
                    uiData={ui}
                    newtData={newtData.contents}
                  />
                )}
              </React.Suspense>
            </HorizontalScrollBox>
          </RelativeBox>
        </FlexBox>
        {renderPager()}
      </>
    );
  };
