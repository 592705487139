import produce from "immer";
import { useCallback, useEffect, useState } from "react";

import {
  ContainerStats,
  createContainerStatsFromNewtData,
} from "../models/ContainerStats";
import { Slot } from "../models";
import { Coordinate } from "allegro-ui";
import { MONITOR_BASE_URL, MonitorAPI } from "../api/monitor-api";
import { useAuth } from "src/root";
import { FETCH_BEFORE_MILISECONDS } from "../recoils/serverState";

const defaultStatsNewtQuery = {
  asset: ["ContainerStats"],
  limit: 300,
  skip: 0,
  order: {
    "contents.rawdata.message.unixtime": -1,
  },
  search: {},
  shape: {
    "contents.rawdata": "any",
    _created: "any",
  },
};

const makeQuery = (slotIds: string[]) => {
  const dateBeforeHours = Date.now() - FETCH_BEFORE_MILISECONDS;

  return produce(defaultStatsNewtQuery, (draft) => {
    draft.search = {
      "contents.rawdata.message.container_name": { $in: slotIds },
      "contents.rawdata.message.unixtime": {
        $gt: dateBeforeHours,
      },
    };
  });
};

export const createContainerName = (slot: Slot) => {
  return `${slot.contractor}__${slot.pipelineId}__${slot.slotId}`;
};

export const useDockerStatsViewer = (slot?: Slot) => {
  const auth = useAuth();
  const [cursorSlotIds, setCursorSlotIds] = useState<string[]>([]);

  const [initialized, setInitalized] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [stats, setStats] = useState<ContainerStats[]>([]);

  const updateData = useCallback(async () => {
    if (!slot && cursorSlotIds.length === 0) {
      console.error("slotが指定されていない");
      return;
    }

    setIsLoading(true);

    try {
      const slotIds =
        cursorSlotIds.length > 0
          ? cursorSlotIds
          : slot
          ? [createContainerName(slot)]
          : [];

      const newtQuery = makeQuery(slotIds);

      const monitorAPI = MonitorAPI({
        baseUrl: MONITOR_BASE_URL,
        apikey: auth.token ?? "",
      });
      const resData = await monitorAPI.fetchContainerStatsByNewtQuery(
        newtQuery
      );
      if (Array.isArray(resData)) {
        const newStats: ContainerStats[] = resData.map((d) => {
          return createContainerStatsFromNewtData(d);
        });

        setStats([...newStats]);
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [slot, cursorSlotIds, stats]);

  useEffect(() => {
    if (!initialized) {
      if (slot) {
        setCursorSlotIds([createContainerName(slot)]);
      }

      updateData();
      setInitalized(true);
    }
  }, [initialized, slot, updateData]);

  const getCpuPercentages = useCallback((): Coordinate[] => {
    return stats.map((stat): Coordinate => {
      const dateStr = stat.created;
      return {
        x: dateStr,
        y: stat.cpuUsage,
      };
    });
  }, [stats]);

  const getMemoryPercentages = useCallback((): Coordinate[] => {
    return stats.map((stat): Coordinate => {
      const dateStr = stat.created;
      return {
        x: dateStr,
        y: stat.memoryUsage,
      };
    });
  }, [stats]);

  const getDiskUsages = useCallback((): Coordinate[] => {
    return stats.map((stat): Coordinate => {
      const dateStr = stat.created;
      return {
        x: dateStr,
        y: stat.diskUsage,
      };
    });
  }, [stats]);

  const dummyData: Coordinate[] = [...Array(300)].map((_, idx) => {
    return {
      x: idx.toString(),
      y: 0,
    };
  });

  const resyncStatsData = async () => {
    await updateData();
  };

  return {
    stats,
    isLoading,
    dummyData,
    getCpuPercentages,
    getMemoryPercentages,
    getDiskUsages,
    resyncStatsData,
  };
};
