import { useState, useCallback, useEffect, useMemo } from "react";
import {
  useNodesState,
  useEdgesState,
  applyNodeChanges,
  Viewport,
} from "react-flow-renderer";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import { useMyRole } from "src/portal/hooks/useMyRole";
import { XRCustomNode } from "../components";
import {
  cursorSlotIdState,
  cursorPipelineIdState,
  cursorSlotTypeState,
} from "../recoils";
import { useSlotList } from "./useSlotList";
import { edges as initialEdges } from "../components/atoms/ExampleNodes";
import { useGrid } from "./useGrid";
import { gridViewPortState } from "../recoils/gridViewPort";
import { XRCustomLinkNode } from "../components/atoms/XRCustomLinkNode";
import { cursorLinkObjectIdState } from "../recoils/linkObjectState";

export const useGridEditor = () => {
  const setCursorSlotId = useSetRecoilState(cursorSlotIdState);
  const [cursorSlotType, setCursorSlotType] =
    useRecoilState(cursorSlotTypeState);

  const setCursorLinkObjectId = useSetRecoilState(cursorLinkObjectIdState);

  const cursorPipelineId = useRecoilValue(cursorPipelineIdState);
  const [viewPort, setViewPort] = useRecoilState(
    gridViewPortState(cursorPipelineId)
  );

  const [needFit, setNeedFit] = useState(viewPort === undefined);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [canEditPipeline, setCanEditPipeline] = useState(false);

  const [nodes, setNodes] = useNodesState<any>([]);
  const [edges, setEdges] = useEdgesState(initialEdges);

  const role = useMyRole();
  const { add } = useSlotList();
  const grid = useGrid();

  const nodeTypes = useMemo(
    () => ({
      slot: XRCustomNode,
      linkObject: XRCustomLinkNode,
    }),
    []
  );

  const udpatePipelineEditableState = useCallback(async () => {
    const ok = await role.canUpdatePipeline(cursorPipelineId);
    setCanEditPipeline(ok);
  }, [cursorPipelineId, role]);

  useEffect(() => {
    udpatePipelineEditableState();
  }, [udpatePipelineEditableState]);

  const handleCreateModalOpen = () => {
    setShowCreateModal(true);
  };
  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };

  useEffect(() => {
    // if (flowComponent.current) setNeedFit(false);
    return () => {
      // setNeedFit(true);
    };
  }, []);

  const measuredRef = useCallback((node) => {
    if (node !== null) {
    }
  }, []);

  /**
   * Slotを作成します。
   * @param formdata
   * @TODO handleCreate -> handleCreateSlotにリネームする
   */
  const handleCreate = (formdata: { slotName: string }) => {
    add();
    handleCreateModalClose();
  };

  const onConnect = (params: any) => {};

  useEffect(() => {
    setNodes(grid.nodes);
  }, [grid.nodes, setNodes]);

  useEffect(() => {
    setEdges(grid.edges);
  }, [grid.edges, setEdges]);

  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  const onNodeDragStop = (event: any, node: any) => {
    switch (node.type) {
      case "slot":
        setCursorSlotId(node.id);
        break;
      case "linkObject":
        break;
    }
  };

  const onNodeDragStart = (event: any, node: any) => {
    switch (node.type) {
      case "slot":
        setCursorSlotId(node.id);
        break;
      case "linkObject":
        break;
    }
  };

  const onViewPortChange = useCallback(
    (newViewPort: Viewport) => {
      setViewPort(newViewPort);

      if (needFit) {
        setNeedFit(false);
      }
    },
    [needFit, setViewPort]
  );

  return {
    grid,
    nodes,
    // linkNodes: grid.linkNodes,
    edges,
    nodeTypes,
    viewPort,
    needFit,
    showCreateModal,
    canEditPipeline,
    measuredRef,
    setCursorSlotId,
    setCursorSlotType,
    setCursorLinkObjectId,
    handleCreateModalOpen,
    handleCreateModalClose,
    handleCreate,
    onConnect,
    onNodesChange,
    onNodeDragStart,
    onNodeDragStop,
    onViewPortChange,
  };
};
