/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState, useEffect } from "react";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { authState } from "src/root/recoils/userState";
import { pipelineListState } from "src/xerver-room/recoils";
import { Role } from "allegro-models";
import { permissions as permissionCfg } from "src/newtroid-console/config/permissions";

const canParseJson = (jsonStr: string) => {
  try {
    const data = JSON.parse(jsonStr);
    return typeof data === "object";
  } catch (err) {
    console.error(err);
  }

  return false;
};

export const useMyRole = () => {
  const auth = useRecoilValue(authState);

  const roles: Role[] = canParseJson(auth.roles ?? "")
    ? JSON.parse(auth.roles ?? "")
    : [];

  // ロールの最小値を計算する
  const minRoleLevel = roles.reduce((roleLevel, role) => {
    return Math.min(roleLevel, role.roleLevel ?? Infinity);
  }, Infinity);

  /**
   * ロールレベルを取得する
   * @param roleGroup 指定したロールグループのロールレベルを取得する
   */
  const getRoleLevel = (roleGroup?: string): number => {
    const role = roles.find((role) => {
      if (!roleGroup) {
        return true;
      } else {
        return role.roleGroup === roleGroup;
      }
    });

    return role?.roleLevel ?? 0;
  };

  const [canCreatePipelineX, setCanCreatePipelineX] = useState(false);
  const [canUpdateAnyPipelineX, setCanUpdateAnyPipelineX] = useState(false);
  const [canUpdateOwnPipelineX, setCanUpdateOwnPipelineX] = useState(false);
  const [canCreateMetric, setCanCreateMetric] = useState(false);
  const [canUpdateMetric, setCanUpdateMetric] = useState(false);
  const [canCreateServer, setCanCreateServer] = useState(false);
  const [canUpdateServer, setCanUpdateServer] = useState(false);
  const [canDeleteServer, setCanDeleteServer] = useState(false);

  const [canReadUsers, setCanReadUsers] = useState(false);
  const [canInviteUser, setCanInviteUser] = useState(false);
  const [canCreateUser, setCanCreateUser] = useState(false);

  const [canCreateGroup, setCanCreateGroup] = useState(false);
  const [canReadGroup, setCanReadGroup] = useState(false);
  const [canUpdateGroup, setCanUpdateGroup] = useState(false);
  const [canDeleteGroup, setCanDeleteGroup] = useState(false);
  const [canInviteGroup, setCanInviteGroup] = useState(false);
  const [canExcludeGroup, setCanExcludeGroup] = useState(false);

  const [permissions, setPermissions] = useState<string[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);

  const _eachRole = (roles: any[], callback: any) => {
    roles.forEach((role) => {
      callback(role);
    });
  };

  const getAllPermissions = useCallback((roles: any[]): string[] => {
    let result: string[] = [];
    _eachRole(roles, (role: any) => {
      result = [...result, ...role.permissions];
    });
    return result;
  }, []);

  const canUpdatePipeline = useRecoilCallback(
    ({ snapshot }) =>
      async (pipelineId: string) => {
        if (canUpdateAnyPipelineX) {
          return true;
        } else if (!canUpdateOwnPipelineX) {
          return false;
        }
        const pipelineList = await snapshot.getPromise(pipelineListState);
        const pipeline = pipelineList.find((p) => p.id === pipelineId);
        const auth = await snapshot.getPromise(authState);
        if (pipeline && auth) {
          return pipeline.createdBy === auth.userId;
        } else {
          return false;
        }
      },
    [canUpdateOwnPipelineX, canUpdateAnyPipelineX]
  );

  const hasPermission = (permissionId: string) => {
    return permissions.includes(permissionId);
  };

  useEffect(() => {
    const permissions = getAllPermissions(roles);
    setPermissions(permissions);

    permissions.includes("xtal.pipelineX.createInContractor") ||
    permissions.includes("xtal.pipelineX.createInOwn")
      ? setCanCreatePipelineX(true)
      : setCanCreatePipelineX(false);

    permissions.includes("xtal.pipelineX.updateOwn")
      ? setCanUpdateOwnPipelineX(true)
      : setCanUpdateOwnPipelineX(false);

    permissions.includes("xtal.pipelineX.updateInContractor")
      ? setCanUpdateAnyPipelineX(true)
      : setCanUpdateAnyPipelineX(false);

    permissions.includes("newtzero.metric.createInContractor")
      ? setCanCreateMetric(true)
      : setCanCreateMetric(false);

    permissions.includes("newtzero.metric.updateInContractor")
      ? setCanUpdateMetric(true)
      : setCanUpdateMetric(false);

    setCanInviteUser(permissions.includes("nauth.user.invite"));
    setCanCreateUser(permissions.includes("nauth.user.create"));
    setCanReadUsers(permissions.includes("nauth.user.readInContractor"));

    setCanCreateGroup(permissions.includes(permissionCfg.nauth.group.create));
    setCanReadGroup(permissions.includes(permissionCfg.nauth.group.read));
    setCanUpdateGroup(permissions.includes(permissionCfg.nauth.group.update));
    setCanDeleteGroup(permissions.includes(permissionCfg.nauth.group.delete));
    setCanInviteGroup(permissions.includes(permissionCfg.nauth.group.invite));
    setCanExcludeGroup(permissions.includes(permissionCfg.nauth.group.exclude));

    setCanCreateServer(permissions.includes(permissionCfg.xtal.server.create));
    setCanUpdateServer(
      permissions.includes(permissionCfg.xtal.server.updateInContractor)
    );
    setCanDeleteServer(
      permissions.includes(permissionCfg.xtal.server.deleteInContractor)
    );
  }, [getAllPermissions]);

  return {
    roles,
    minRoleLevel,
    permissions,

    canCreatePipelineX,
    canUpdateAnyPipelineX,
    canUpdatePipeline,
    canUpdateMetric,
    canCreateMetric,
    canCreateServer,
    canUpdateServer,
    canDeleteServer,

    canCreateUser,
    canInviteUser,
    canReadUsers,

    canCreateGroup,
    canReadGroup,
    canUpdateGroup,
    canDeleteGroup,
    canInviteGroup,
    canExcludeGroup,

    isAdmin,

    getRoleLevel,
    hasPermission,
  };
};
