import * as xlsx from "xlsx";

type XlsxFormField = {
  title?: string;
  titleDescription?: string;
  label: string;
  type:
    | "selection"
    | "checkboxes"
    | "radios"
    | "text"
    | "secret"
    | "chips"
    | "skelton"
    | "date"
    | "section";
  required?: boolean;
  errorText?: string;
  description?: string;
  active?: string | string[];
  values?: string | string[];
};

export const parseJSON = async (file: File) => {
  const buffer = await file.arrayBuffer();
  const fileStr = new TextDecoder().decode(buffer);
  return JSON.parse(fileStr);
};

export const parseXlsx = async (file: File) => {
  const buffer = await file.arrayBuffer();
  return xlsx.read(buffer, { type: "buffer", cellDates: true });
};

type Props = {
  replaceKeys?: any;
  headerRow?: number;
  startColumn?: string;
};

export const xlsx2Object = (workbook: xlsx.WorkBook, options?: Props) => {
  let replaceKeys: any = options?.replaceKeys && {};
  const headerRow = options?.headerRow;
  if (headerRow && headerRow < 1) {
    throw Error("headerRow must be greater than 0");
  }
  const startColumn = options?.startColumn && "A";
  return Object.entries(workbook.Sheets).map(([sheetName, sheet], idx) => {
    let recordsObj = xlsx.utils.sheet_to_json(sheet, { header: "A" });
    if (headerRow && headerRow > 0) {
      const header: any = recordsObj[headerRow - 1];
      replaceKeys = { ...header, ...options?.replaceKeys };
      recordsObj = recordsObj.slice(headerRow);
    }

    const records = recordsObj.map((record: any) => {
      return Object.fromEntries(
        Object.entries(record).map(([key, value], idx) => {
          return [
            replaceKeys && key in replaceKeys ? replaceKeys[key] : key,
            value,
          ];
        })
      );
    });

    return {
      name: sheetName,
      records: records,
    };
  });
};

export const parseFormDataFromXlsx = (workbook: xlsx.WorkBook) => {
  const sheetNames = workbook.SheetNames;
  const sheet = workbook.Sheets[sheetNames[0]];

  if (!sheet) {
    return;
  }

  const assetDataObj: XlsxFormField[] = xlsx.utils
    .sheet_to_json<XlsxFormField>(sheet, {
      header: [
        "title",
        "titleDescription",
        "label",
        "type",
        "active",
        "values",
        "required",
        "errorText",
        "description",
      ],
    })
    .slice(2);

  const ranged = sheet["!rows"];

  // ユーザ、先生用のフォームをタイトルカラムの有無で分ける
  const titleIndexes = assetDataObj
    .flatMap((formField, idx) => {
      return formField.title ? idx : [];
    })
    .reverse();

  const sectionBlocks: XlsxFormField[][] = [];
  titleIndexes.reduce((lastIndex, startIndex) => {
    sectionBlocks.push(assetDataObj.slice(startIndex, lastIndex));
    return startIndex;
  }, assetDataObj.length);

  const sections = sectionBlocks
    .map((formFields) => {
      const title = formFields[0].title;
      const description = formFields[0].titleDescription;
      const fields: XlsxFormField[] = formFields.map((formField) => {
        // valuesはカンマ、読点を分割して配列にする
        let values = formField.values;
        if (typeof values === "string") {
          values = values.replace(/[,|、]([ |　]+)/g, ",").split(",");
        }
        // activeはtypeがchipかcheckboxなら配列とする
        let active = formField.active;
        if (
          typeof active === "string" &&
          ["chip", "checkbox"].includes(formField.type)
        ) {
          // カンマ後の空白削除
          active = active.replace(/[,|、]([ |　]+)/g, ",").split(",");
        }

        return {
          label: formField.label,
          type: formField.type,
          required: formField.required,
          active: active,
          values: values,
          errorText: formField.errorText,
          description: formField.description,
        };
      });
      return {
        title: title,
        description: description,
        fields: fields,
      };
    })
    .reverse();

  return { sections: sections };
};
