import React, { useContext, useState, createContext } from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { authState, myProfileState } from "./recoils/userState";
import { createUser } from "src/root/models/user";
import { API_URL } from "src/utils/apiUrl";
import { createnAuthAPI } from "src/api";

const EXPIRED_TIME = 7 * 24 * 60 * 60 * 1000;

type ContextProps = {
  contractorNo?: string | null;
  userId: string | null;
  userNo: number | null;
  userNoText: string | null;
  token: string | null;
  roles: string | null;
  groups: string | null;
  userRealName: string | null;
  expired: number | null;
  login: (
    email: string,
    password: string,
    contractor: string
  ) => Promise<AxiosResponse>;
  logout: () => void;
  isAuth: () => boolean;
  isFetching: boolean;
  errorMsg: string;
};
const authContext = createContext<Partial<ContextProps>>({});

/**
 * @deprecated allegro-api-hooksへ移行
 */
export const AuthProvider: React.FC<{}> = ({ children }) => {
  const auth: ContextProps = useProvideAuth();
  return <authContext.Provider value={auth}> {children}</authContext.Provider>;
};

/**
 * @deprecated allegro-api-hooksへ移行
 */
const useProvideAuth = (): ContextProps => {
  //userIdはemailアドレス

  // const [userId, setUserId] = useState(
  //   sessionStorage.getItem("userId") || null
  // );

  const [auth, setAuth] = useRecoilState(authState);

  const [contractorNo, setContractorName] = useState(auth.contractorNo);
  const myProfile = useRecoilValueLoadable(myProfileState);

  const [isFetching, setIsFetching] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const isAuth = (): boolean => {
    return auth.token !== null;
  };

  const login = async (
    email: string,
    password: string,
    contractor: string
  ): Promise<AxiosResponse> => {
    const nauth = createnAuthAPI();
    const params = {
      password,
      email,
      contractor,
    };

    setErrorMsg("");
    setIsFetching(true);

    try {
      const res = await nauth.auth(params);

      const nextUser = createUser(res.data);
      setIsFetching(false);

      let expired = new Date().getTime() + EXPIRED_TIME;

      setAuth({
        token: nextUser.profile.apiKey,
        userNo: Number(nextUser.userNo),
        userNoText: nextUser.userNoText,
        username: nextUser.username,
        contractorNo: nextUser.contractorNo,
        userId: nextUser.userNoText, //下位互換のため.いずれ削除する
        userNameText: nextUser.nickName,
        roles: JSON.stringify(nextUser.roles),
        groups: JSON.stringify(nextUser.groups),
        userRealName: nextUser.profile.userRealName,
        avatar: nextUser.avatar,
        email: nextUser.email,
        phoneNumber: nextUser.phoneNumber,
        zipcode: nextUser.zip,
        address: nextUser.address,
        expired: expired,
      });

      setContractorName(nextUser.contractorNo);

      return res;
    } catch (err: any) {
      let message = `ログインに失敗しました。error:${err.response.data.message}`;
      if (err.response.status === 400) {
        message = `ログインに失敗しました。コントラクタかメールアドレスが間違っています。`;
      } else if (err.response.status === 404) {
        message = `ログインに失敗しました。パスワードが間違っています。`;
      }

      setErrorMsg(message);
      setIsFetching(false);

      return err.response;
    }
  };

  const logout = () => {
    setAuth({
      userNo: null,
      userNoText: null,
      username: null,
      token: null,
      contractorNo: null,
      userId: null,
      userNameText: null,
      roles: null,
      groups: null,
      userRealName: null,
      avatar: null,
      email: null,
      phoneNumber: null,
      zipcode: null,
      address: null,
      expired: null,
    });
  };

  const userId = auth.userNoText;
  const userNo = auth.userNo;
  const userNoText = auth.userNoText;
  const token = auth.token;
  const userRealName = "";
  const roles = auth.roles;
  const groups = auth.groups;
  const expired = auth.expired;

  return {
    contractorNo,
    userId,
    userNo,
    userNoText,
    token,
    roles,
    groups,
    userRealName,
    login,
    isAuth,
    isFetching,
    errorMsg,
    logout,
    expired,
  };
};

/**
 * @deprecated allegro-api-hooksへ移行
 */
export const useAuth = () => {
  return useContext(authContext);
};
