//Libs
import React, { useEffect, useState } from "react";
import styled from "styled-components";

//Components
import { ASButton } from "allegro-ui";

import { ASTextFormField, ASRadioFormField } from "allegro-low-code-components";
//States
//Types

import { useSlotActions } from "src/xerver-room/hooks";
import produce from "immer";
import { useForm } from "react-hook-form";

const Form = styled.form`
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 100%;
  width: 300px;
  display: flex;
  flex-direction: column;
  text-align: left;
  > * {
    margin-top: 24px;
  }
`;

const Caption = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.cautionColor};
`;

type Props = {
  slotId: string;
  disabled?: boolean;
};

const FormLabel = styled.span`
  display: inline-block;
  margin-bottom: 8px;
  font-weight: bold;
`;

export const XRPortSettingForm: React.FC<Props> = ({ slotId, disabled }) => {
  const slot = useSlotActions(slotId);
  const { saveInputs } = slot;

  const [formData, setFormData] = useState<string[]>([]);

  const { handleSubmit, control } = useForm();

  useEffect(() => {
    if (Array.isArray(slot.inputs) && slot.inputs.length > 0) {
      slot.inputs.forEach((input, id) => {
        const nextForm = produce((draft) => {
          draft[id] = input.portOutside;
        });
        setFormData(nextForm);
      });
    } else setFormData([]);
  }, [slot.inputs]);

  const onSubmit = async (
    data: any,
    options?: { isUpdate?: boolean; approval?: boolean }
  ) => {
    //から文字はundefinedにする。
    saveInputs(data);
  };

  return (
    <Form onSubmit={handleSubmit((values: any) => onSubmit(values))}>
      {slot.inputs.map((input, id) => {
        const inputPort = input.portOutside
          ? String(input.portOutside)
          : undefined;
        const customDomain = input.customDomain ?? "";
        const usePrivateGateway = input.usePrivateGateway ? "はい" : "いいえ";
        return (
          <>
            <FormLabel>{input.name}</FormLabel>

            <ASTextFormField
              error={""}
              supportText="空欄の場合、空きポートを自動で割り当てます。"
              active={inputPort}
              control={control}
              values={undefined}
              name={"port" + id}
              label={"Port"}
              editable={!disabled}
              type={"text"}
              design="digicho"
            />

            <ASTextFormField
              error={""}
              supportText="NewtroidのDNSに登録します。"
              active={customDomain}
              control={control}
              values={undefined}
              name={"customDomain" + id}
              label={"Domain"}
              editable={!disabled}
              type={"text"}
              design="digicho"
            />

            <ASRadioFormField
              name={"usePrivateGateway" + id}
              label={"Using private gateway"}
              error={""}
              active={usePrivateGateway}
              control={control}
              values={["はい", "いいえ"]}
              supportText="いいえを選んだ場合、インターネットからアクセスができます。"
              editable={!disabled}
              type={"radio"}
              design="digicho"
            />
          </>
        );
      })}

      <Caption>※inputが一つのOptXのみサポートしています。</Caption>

      <ASButton
        kind={"secondary"}
        type="submit"
        disabled={!formData || disabled}
      >
        SAVE
      </ASButton>
    </Form>
  );
};
