import React from "react";
import styled from "styled-components";
import { darken } from "polished";
type Props = {};

const colors: { [key: string]: string } = {
  RawData: "#ba51d4",
  TopData: "#7063ff",
  TxnData: "#dddd44",
};

const StyledDiv = styled.span`
  display: inline-block;
  background: ${(props) => darken(0.5, colors[String(props.children)])};
  border: 1px solid ${(props) => colors[String(props.children)]};
  padding: 4px 8px;
  min-height: 24px;
  display: inline-flex;
  align-items: center;
  text-align: center;

  color: ${(props) => colors[String(props.children)]};
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
`;

export const NZSchemaTypeChip: React.FC<Props> = ({ children }) => {
  return <StyledDiv>{children}</StyledDiv>;
};
