import MDStickyHeader from "src/medican/components/molecules/MDStickyHeader";
import { NAMainViewTemplate } from "../templates/NAMainViewTemplate";
import { NAAIListTemplate } from "../templates/NAAIListTemplate";

export const NAAIListPage: React.FC = () => {
  return (
    <NAMainViewTemplate>
      <MDStickyHeader title={"AI一覧"} />
      <NAAIListTemplate />
    </NAMainViewTemplate>
  );
};
