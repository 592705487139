export namespace queryUtils {
  export const toObject = (query: any): { where: any } | null => {
    try {
      const queryStr =
        typeof query === "string" ? query : JSON.stringify(query, null, 2);

      const queryObj = JSON.parse(queryStr);
      return queryObj;
    } catch (err) {
      console.error(err);
      return null;
    }
  };
}
