import * as React from "react";
import { useState } from "react";
import { ASInputBase, ASModal, ASButton } from "allegro-ui";
import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { metricCreatingFormDefinition } from "../../../cheetah/definitions/forms/metricCreatingFormDefinition";
import { metricBroweserUi } from "../../../cheetah/definitions/listPages/metricBrowserUi";
import CHListPageTemplate from "src/cheetah/components/templates/CHListPageTemplate";

interface IFTMetricListPageTemplateProps {}

const FTMetricListPageTemplate: React.FunctionComponent<IFTMetricListPageTemplateProps> =
  (props) => {
    return (
      <CHListPageTemplate
        title="メトリック一覧"
        formDefinition={metricCreatingFormDefinition}
        ui={metricBroweserUi}
      />
    );
  };

export default FTMetricListPageTemplate;
