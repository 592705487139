import React from "react";

import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import { ASIconButton } from "allegro-ui";
import { Slot } from "src/xerver-room/models";
import { useSlotActions } from "src/xerver-room/hooks";

type Props = { slotId: string; disabled?: boolean };

export const XRStartSlotButton: React.FC<Props> = ({ slotId, disabled }) => {
  const slot = useSlotActions(slotId);
  return (
    <ASIconButton
      kind="secondary"
      size="small"
      disabled={disabled}
      onClick={() => {
        alert(
          `起動します(ID:${slotId})起動までしばらく時間がかかる場合があります。`
        );
        slot.requestStart();
      }}
    >
      <PlayArrowIcon />
    </ASIconButton>
  );
};
