//アセスメント詳細画面の大部分を担うコンポーネント

//3rd
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Grid } from "@material-ui/core";
import {
  ASButton,
  ASIconButton,
  ASEasyTable,
  TableData,
  ASModal,
} from "allegro-ui";
import styled from "styled-components";
//us(libs)
import { FTDashBoardWidget } from "../organisms/FTDashBoardWidget";
import {
  cursorAssesmentDateState,
  cursorAssesmentState,
  isAllAssesmentState,
} from "src/fortress/recoils/assessmentState";
import {
  cursorChildIdState,
  tabIdState,
} from "src/fortress/recoils/singleChildPageState";
import * as api from "../../api";
import { useSnackbar } from "notistack";
import { CheckShowTable } from "../molecules";
import {
  defaultTwoFilterData,
  FilterCheckData,
} from "src/fortress/models/RiskAssessment";

const BackButton = styled(ASIconButton)`
  width: 45px;
  height: 45px;
  margin-right: 16px;
`;

const Flex = styled.div`
  display: flex;
`;

const FlexTitle = styled.div`
  display: flex;
`;

const PageTitle = styled.p`
  font-size: 24px;
  margin-top: 11px;
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
`;

const StyledContainer = styled.div`
  margin: 30px 0 30px;
  line-height: 50px;
`;

const Right = styled.div`
  text-align: right;
  margin-bottom: -45px;
`;

const Button = styled(ASButton)`
  margin-left: 15px;
  padding: 5px 50px;
`;

const Text = styled.div`
  white-space: pre-wrap;
  line-height: 1.5;
  width: 95%;
  margin: 30px auto 50px auto;
`;

const AssesmentContent = styled.div`
  width: 80%;
  margin: 30px auto;
`;

const Width = styled.div`
  /* width: 80%; */
  margin: 0 auto;
`;

const QOne = styled.div`
  /* margin-top: 40px;
  margin-bottom: 40px; */
  font-size: 20px;
  font-weight: bold;
`;

const ModalQues = styled.div`
  text-align: center;
  padding-top: 15px;
  font-size: 25px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  width: 400px;
  padding: 24px;
  h1 {
    font-size: 32px;
    font-weight: bold;
  }
`;
const ButtonGroup = styled.div`
  display: flex;
  padding-bottom: 40px;
  justify-content: space-around;
  > * {
    margin-left: 16px;
  }
`;

export type AssessmentData = {
  date: string;
  assesmentData: string;
};

type Props = {};

//アセスメント詳細画面の実装をする。

export const FTAssesmentDetailPanel: React.FC<Props> = () => {
  const history = useNavigate();
  const assessmentData = useRecoilValue(cursorAssesmentState);
  const cursorChildId = useRecoilValue(cursorChildIdState);
  const setTabId = useSetRecoilState(tabIdState("assesment-detail-panel"));
  const date = useRecoilValue(cursorAssesmentDateState);
  const [open, setOpen] = useState(false);
  const isAllAssesment = useRecoilValue(isAllAssesmentState);

  console.log(history);

  const { enqueueSnackbar } = useSnackbar();

  let OneAssesmentData: { [key: string]: string | number } = {};
  let TwoAssesmentData: FilterCheckData = defaultTwoFilterData;

  //assessmentDataにフィルターをかけて最適なオブジェクトを作成する
  Object.keys(assessmentData).forEach((asses) => {
    const dOne = [
      "フォーム作成者",
      "宛名番号",
      "評価方法",
      "職員評価",
      "今後の対応方針",
      "要対協登録状況",
    ];

    const dTwo = ["子どもの様子", "親の様子", "家族の様子", "その他所見"];

    if (dOne.includes(asses)) {
      OneAssesmentData[asses] = assessmentData[asses];
    }

    if (dTwo.includes(asses)) {
      TwoAssesmentData[asses] = assessmentData[asses];
    }
  });

  //OneAssesmentDataを、UIに表示できるように処理を書く。
  const tableData: TableData[] = Object.keys(OneAssesmentData).map(
    (key: string) => {
      const assessment = assessmentData as {
        [key: string]: any;
      };

      return {
        colName: key,
        type: typeof assessment[key],
        colValue: assessment[key],
      };
    }
  );

  //全員分のアセスメント一覧からきた場合と、児童詳細画面からきた場合で、戻るボタン押した時の戻り先を変える処理。
  const url = isAllAssesment ? `/allassesment` : `/child/${cursorChildId}`;
  console.log(url);

  const handlePush = () => {
    setTabId(1);
    history(-1);
  };

  //削除しますか？のモーダルを開く
  const handleOpen = async () => {
    setOpen(true);
  };

  //アセスメントデータ削除

  //setTabId(1)児童詳細画面で、tabidが0なら本人データ、１なら家族データ、２ならai、
  //３ならアセスメントのコンポーネントがuiとして現れる。つまりこれは、
  //アセスメント詳細画面で削除したと同時に児童詳細のアセスメント一覧に飛ぶことを表してる。

  const handleDelete = async () => {
    const res = await api.deleteAssessmentData(cursorChildId, date);

    if (res) {
      setOpen(false);
      setTabId(1);
      //削除しましたのバーが表示される
      enqueueSnackbar(`削除しました。`, {
        variant: "success",
      });
      history(-1);
    } else {
      enqueueSnackbar(`削除できませんでした。`, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Flex>
            <BackButton size="small" onClick={handlePush}>
              <ArrowBackIcon fontSize="inherit" />
            </BackButton>
            <PageTitle>リスクアセスメント詳細</PageTitle>
          </Flex>
          <Grid item xs={12}>
            <StyledContainer>
              <Right>
                <Button kind="primary" onClick={handleOpen}>
                  削除する
                </Button>
              </Right>
              <ASModal open={open} onClose={setOpen}>
                <Container>
                  <ModalQues>削除しますか？</ModalQues>
                  <ButtonGroup>
                    <ASButton kind={"primary"} onClick={handleDelete}>
                      はい
                    </ASButton>
                    <ASButton kind={"primary"} onClick={() => setOpen(false)}>
                      いいえ
                    </ASButton>
                  </ButtonGroup>
                </Container>
              </ASModal>
              <FTDashBoardWidget title={`作成日:${assessmentData.created}`}>
                <>
                  <FlexTitle>
                    <QOne>基本情報</QOne>
                  </FlexTitle>
                  <Width>
                    <ASEasyTable
                      key={`k-table-${assessmentData["フォームID"]}`}
                      tableData={tableData}
                    ></ASEasyTable>
                  </Width>
                  {Object.keys(TwoAssesmentData).map((data, id) => {
                    return (
                      <div key={String(id)}>
                        <FlexTitle>
                          <QOne>{data}</QOne>
                        </FlexTitle>
                        <Width>
                          <CheckShowTable
                            data={TwoAssesmentData[data]}
                          ></CheckShowTable>
                        </Width>
                      </div>
                    );
                  })}

                  <FlexTitle>
                    <QOne>アセスメント入力内容</QOne>
                  </FlexTitle>
                  <AssesmentContent>
                    <Text>{assessmentData["アセスメント内容"]}</Text>
                  </AssesmentContent>
                </>
              </FTDashBoardWidget>
            </StyledContainer>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
