import { ASLowCodeFormDefinition } from "allegro-low-code-components";
import { api, bundles } from "src/api";
import { streamGate } from "src/medican/api";
import { nAITemplate } from "src/nai-console/models";
import { nAIFilterTagAssetTags } from "src/nai-console/models/nAITagLabel";

export const createLabelFormUi: ASLowCodeFormDefinition = {
  sections: [
    {
      title: "",
      description: "",
      fields: [
        {
          label: "Label",
          name: "label",
          type: "text",
          required: true,
          errorText: "labelは必須です。",
        },
      ],
    },
  ],
  onSubmit: async (data, options) => {
    try {
      const postData = {
        label: data.label,
        value: data.label,
      };
      const res = await streamGate(nAIFilterTagAssetTags, postData);
      if (res?.status === 200) {
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  },
};
