//Libs
import { topDataStateFactory } from "src/opt-editor/utils/topDataBrowserFactory";
import apiConf from "src/opt-editor/apiConf.json";
//Components
//States
import { Activity, defaultActivity, createActivity } from "../models/Activity";
//Types

const activityUtils = topDataStateFactory<
  Activity,
  { ["contents.rawdata.userNoText"]: string }
>(
  "Activity",
  defaultActivity,
  apiConf.topdata.activity.tags,
  apiConf.topdata.activity.primaryKey,
  (d) => {
    return createActivity(d?.contents?.rawdata);
  }
);

/**
 * @deprecated 後方互換のためのアダプタにつき、削除予定
 */
export const cursorActivityIdState = activityUtils.cursorItemIdState;

/**
 * @deprecated 後方互換のためのアダプタにつき、削除予定
 */
export const activityState = activityUtils.item;

export default activityUtils;
