import React from "react";
import { Controller, Control, FieldValues } from "react-hook-form";

import { ASCheckBoxForm } from "../../models/ASLowCodeFormField";
import { ASFormLabel, ASErrorField } from "../atoms";
import { ASCheckboxFormFieldComponent } from "./ASCheckBoxFormFieldComponent";
import { ASRequiredMark } from "../atoms/ASRequiredMark";
import { ASFormFieldBase } from "./ASFormFieldBase";

type ASFormFieldProps = {
  id?: number | string;
  control: Control;
  defaultValue?: string;
  error: any;
  editable?: boolean;
  design?: "digicho" | "default";
  supportText?: string;
  requiredMarker?: string;
  optionalMarker?: string;
  isdesktop?: boolean;
  placeHolder?: string;
};

export const ASCheckBoxFormField = (
  props: ASFormFieldProps & ASCheckBoxForm
) => {
  const {
    id,
    label,
    name,
    required,
    control,
    defaultValue,
    active,
    values,
    error,
    errorText,
    editable,
    supportText,
    design,
    placeHolder,
  } = props;
  return (
    <div key={`lc-create-data-form-${id}-${name}`}>
      <ASFormFieldBase
        error={error}
        errorText={errorText}
        label={label}
        design={design}
        required={required}
        editable={editable}
        supportText={supportText}
        requiredMarker={props.requiredMarker}
        optionalMarker={props.optionalMarker}
      >
        <Controller
          key={`lc-create-checkbox-form-${id}-${name}-${active}-${values}`}
          name={name}
          control={control}
          rules={{ required: required }}
          defaultValue={defaultValue ?? active}
          render={({ field: { onChange } }) => (
            <>
              <ASCheckboxFormFieldComponent
                name={name}
                active={defaultValue ?? active}
                editable={editable}
                values={values}
                onChange={(active: string[]) => {
                  onChange(active);
                }}
              />
            </>
          )}
        />
      </ASFormFieldBase>
    </div>
  );
};
