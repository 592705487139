//Libs
import React, { useState } from "react";
import styled from "styled-components";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { useRecoilValue } from "recoil";
//Components
import { ASButton, ASModal, ASPictureIcon } from "allegro-ui";
//States
import { useAuth } from "src/root/UseAuth";
//Types

import { useNavigate } from "react-router-dom";
import { myProfileState } from "src/root/recoils/userState";
import { lighten } from "polished";
import { ConfirmModalContents } from "src/kinzaza/components";

type Props = {
  showName?: boolean;
};

const UserMenu = styled(Button)`
  background-color: ${(props) => props.theme.primaryColor};
  border: 1px solid ${(props) => props.theme.secondaryBorderColor};
  width: fit-content;
  max-width: 300px;
  height: 40px;
  /* min-width: 130px; */
  border-radius: 180px;
  color: white;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  padding-left: 5px;
  .MuiButton-label {
    margin: 0px;
    padding: 0px;
  }
  &:hover {
    background: ${(props) => lighten(0.1, props.theme.primaryColor)};
  }
`;

const CircleIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: green; // TODO
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DownTriangle = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
  margin: 0 auto;
  margin-right: 4px;
`;

const NameWrapper = styled.div`
  margin-left: 8px;
  margin-right: 8px;
`;

const StyledMenu = styled(Menu)``;

const Buttons = styled.div`
  position: relative;
  top: 150px;
  display: flex;
  justify-content: space-around;
  bottom: 48px;
`;

export const MDUserButton: React.FC<Props> = (props) => {
  let history = useNavigate();
  const myProfile = useRecoilValue(myProfileState);

  const initial = (() => {
    return myProfile && myProfile.profile.userRealName
      ? myProfile.profile.userRealName[0].toUpperCase()
      : "";
  })();

  const [modalOpen, setModalOpen] = useState(false);

  const UserIcon = () => {
    return myProfile?.avatar ? (
      <ASPictureIcon
        src={myProfile.avatar}
        defaultSrc={myProfile.avatar}
        size={30}
      />
    ) : (
      <CircleIcon>{initial}</CircleIcon>
    );
  };
  const UserName = () => {
    return myProfile ? (
      <NameWrapper>{myProfile.profile.userRealName}</NameWrapper>
    ) : (
      <></>
    );
  };
  const ArrowIcon = () => {
    return <DownTriangle />;
  };

  const auth = useAuth();

  const [userContextMenu, setUserContextMenu] = React.useState<{
    mouseX: number | null;
    mouseY: number | null;
  }>({ mouseX: null, mouseY: null });
  const handleUserContextMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    // 初期のメニュー非表示
    event.preventDefault();
    setUserContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };
  // メニューを閉じる
  const handleUserContextMenuClose = () => {
    setUserContextMenu({
      mouseX: null,
      mouseY: null,
    });
  };

  const handleClickManagement = () => {
    history("/settings/teams");
  };

  const logoutConfirm = (
    <ConfirmModalContents
      submitLabel="Logout"
      onSubmit={() => {
        if (auth.logout) {
          auth.logout();
          history("/login");
        }
      }}
      onCancel={() => setModalOpen(false)}
    >
      ログアウトしますか？
    </ConfirmModalContents>
  );

  const handleSettingPage = () => {
    history("/settings/profile");
  };

  return (
    <>
      <UserMenu
        onClick={(evt) => {
          handleUserContextMenu(evt);
        }}
      >
        <UserIcon />
        {props.showName ? (
          <>
            <UserName />
          </>
        ) : null}
        <ArrowIcon />
      </UserMenu>
      <StyledMenu
        keepMounted
        open={userContextMenu.mouseY !== null}
        onClose={handleUserContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          userContextMenu.mouseY !== null && userContextMenu.mouseX !== null
            ? {
                top: userContextMenu.mouseY,
                left: userContextMenu.mouseX,
              }
            : undefined
        }
      >
        {/* <MenuItem onClick={handleSettingPage}>Settings</MenuItem> */}
        <MenuItem onClick={handleClickManagement}>Account</MenuItem>
        <MenuItem
          onClick={() => {
            setModalOpen(true);
          }}
        >
          LogOut
        </MenuItem>
        <ASModal
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
        >
          {logoutConfirm}
        </ASModal>
      </StyledMenu>
    </>
  );
};
