import { ASLowCodeFormDefinition } from "allegro-low-code-components";

export const createUserFormDefinition: ASLowCodeFormDefinition = {
  sections: [
    {
      title: "ユーザ新規作成",
      description: "",
      fields: [
        {
          label: "名前",
          name: "userRealName",
          type: "text",
          required: true,
          active: "",
          values: undefined,
          errorText: "名前は必須です",
        },

        {
          label: "メールアドレス",
          name: "email",
          type: "text",
          required: true,
          active: "",
          values: undefined,
          errorText: "メールアドレスは必須です",
        },
        {
          label: "パスワード",
          name: "password",
          type: "text",
          required: true,
          active: "",
          values: undefined,
          errorText: "パスワードは必須です",
        },
        {
          label: "郵便番号",
          name: "zip",
          type: "text",
          required: false,
          active: "",
          values: undefined,
        },
        {
          label: "住所",
          name: "address",
          type: "text",
          required: false,
          active: "",
          values: undefined,
        },
        {
          label: "電話番号",
          name: "phoneNumber",
          type: "text",
          required: false,
          active: "",
          values: undefined,
        },
      ],
    },
  ],
};
