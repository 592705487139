import React, { useState } from "react";
import styled from "styled-components";
import { ASButton } from "allegro-ui";

import { useNavigate } from "react-router-dom";
import { PTRegisterProfileForm } from "src/portal/components/organisms/PTRegisterProfileForm";
import { useSignUpActions } from "src/portal/hooks/useSignUpActions";
import { PTUserAuthComponent } from "src/portal/components/organisms/PTUserAuthComponent";
import { CHUserAuthMainViewTemplate } from "../templates/CHUserAuthMainView";
import { UserAuthPath } from "../models/userAuthRoutes";

const SignUpNote = styled.div`
  color: ${(props) => props.theme.textColor};
  padding: 23px 0;
  &::before {
    top: 9px;
    /* border-bottom: 1px solid #fff; */
    position: absolute;
    left: 0;
    width: 100%;
    content: "";
  }
`;

const SignUpRegister = styled.div`
  padding: 0 64px;
`;

export type CHConfirmEmailPageProps = {
  logo: any;
  appName: string;
  copyRight?: string;
  routes: UserAuthPath;
};

export const CHConfirmEmailPage: React.FC<CHConfirmEmailPageProps> = (
  props
) => {
  const { logo } = props;

  const [confirmed, setConfirmed] = useState(false);
  const { signUpConfirm } = useSignUpActions();
  const history = useNavigate();

  const handleSubmit = async (formData: any) => {
    console.log("registering...");
    const ok = await signUpConfirm(formData);
    console.log(ok);
    if (ok) {
      setConfirmed(true);
    } else {
      alert("失敗しました。入力に誤りがないか確認してください。");
    }
  };

  return (
    <>
      <CHUserAuthMainViewTemplate logo={logo}>
        <PTUserAuthComponent
          formTitle={confirmed ? "本登録完了" : "本登録"}
          formContent={
            confirmed ? (
              <p>本登録が完了しました。</p>
            ) : (
              <PTRegisterProfileForm onSubmit={handleSubmit} />
            )
          }
        >
          {confirmed ? (
            <div>
              <SignUpNote></SignUpNote>
              <SignUpRegister>
                <ASButton
                  kind="secondary"
                  size="large"
                  fullWidth
                  onClick={() => {
                    history("/login");
                  }}
                >
                  ログインへ進む
                </ASButton>
              </SignUpRegister>
            </div>
          ) : (
            <></>
          )}
        </PTUserAuthComponent>
      </CHUserAuthMainViewTemplate>
    </>
  );
};
