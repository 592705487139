import React from "react";
import styled from "styled-components";
//Libs
import { Button } from "@material-ui/core";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";

type Props = {
  onChange: (file: any) => void;
};

const onLoad = (fileString: any) => {
  // console.log(fileString);
};

export const ASPictureSelectButton: React.FC<Props> = ({ onChange }) => {
  const getBase64 = (file: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
      onChange(reader.result);
    };
  };
  return (
    <div>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="contained-button-file"
        multiple
        type="file"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const files = e.target.files;
          if (files && files.length > 0) {
            const file = files[0];

            //サイズが300kb以下
            if (file.size < 300000) {
              getBase64(file);
            }
          }
        }}
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" component="span">
          <CameraAltOutlinedIcon /> Edit
        </Button>
      </label>
    </div>
  );
};
