import React, { useState } from "react";
import styled from "styled-components";
import { GraphData, ASTabs } from "../atoms";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Tab } from "@material-ui/core";

export type ASGraphAsTableProps = {
  data: GraphData[];
  maxHeight?: number;
  customTableLabels?: string[];
  loading?: boolean;
};

const StyledTable = styled(Table)``;

const HeadCell = styled(TableCell)`
  color: ${(props) => (props.theme.darkMode ? "#e9e9e9" : "#252525")};
  font-weight: bold;
  border-bottom: 1px solid
    ${(props) => (props.theme.darkMode ? "#434343" : "#dfdfdf")};
`;

const Cell = styled(TableCell)`
  color: ${(props) => (props.theme.darkMode ? "#e9e9e9" : "#252525")};
  border-bottom: 1px solid
    ${(props) => (props.theme.darkMode ? "#434343" : "#dfdfdf")};
`;

const EmptyBox = styled.div`
  min-height: 200px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const TableArea = styled.div<{ maxHeight: number }>`
  max-height: ${(props) => props.maxHeight}px;
  overflow: scroll;
`;

const NoDataText = styled.span`
  color: ${(props) => (props.theme.darkMode ? "#4a4a4a" : "#a3a3a3")};
`;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ASGraphAsTable: React.FC<ASGraphAsTableProps> = ({
  data,
  maxHeight = 300,
  customTableLabels,
  loading = false,
}) => {
  const [tabId, setTabId] = useState(0);

  const handleTab = (e: any, newValue: number) => {
    setTabId(newValue);
  };

  const renderTabs = () => {
    const tabs = data.map((d, id) => {
      return <Tab label={d.label} {...a11yProps(id)} />;
    });

    return (
      <ASTabs value={tabId} onChange={handleTab}>
        {tabs}
      </ASTabs>
    );
  };

  const tabContent = (id: number) => {
    const graphData = data[id];
    const headerLabels: string[] = Object.keys(graphData.dataPoints[0]);
    const rowData = graphData.dataPoints;
    return (
      <TableArea maxHeight={maxHeight}>
        <StyledTable>
          <TableHead>
            <TableRow>
              {customTableLabels
                ? customTableLabels.map((label) => <HeadCell>{label}</HeadCell>)
                : headerLabels.map((label) => <HeadCell>{label}</HeadCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.map((row) => (
              <TableRow>
                {Object.values(row).map((v) => (
                  <Cell>{v}</Cell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableArea>
    );
  };

  return loading ? (
    <EmptyBox>
      <NoDataText>Loading...</NoDataText>
    </EmptyBox>
  ) : data.length === 0 ? (
    <EmptyBox>
      <NoDataText>データがありません</NoDataText>
    </EmptyBox>
  ) : (
    <>
      {renderTabs()}
      {tabContent(tabId)}
    </>
  );
};
