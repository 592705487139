import { api, createNewtZeroAPI } from "src/api";
import * as xlsx from "xlsx";

import { parseJSON, xlsx2Object } from "src/medican/utils/parseFile";
import { useFileUpload } from "src/opt-editor/hooks/useFileUpload";
import { UploadLogStatus } from "../models";

const createMetric = async (
  metricName: string,
  data: {
    means: string[];
    tags: string[];
    shareLevel: "private" | "public";
  },
  params?: {
    upsert?: boolean;
    upsertTags?: boolean;
    mergeMeans?: boolean;
  }
) => {
  try {
    const res = await api.post(
      `/contractors/${sessionStorage.contractorNo}/metrics/${metricName}`,
      data,
      {
        params: params,
      }
    );

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

const excel2Json = async (workbook: xlsx.WorkBook) => {
  const xlsxObj = xlsx2Object(workbook, { headerRow: 1 });

  const meanPattern = new RegExp("意味[0-9]*");
  const tagPattern = new RegExp("タグ[0-9]*");

  let metrics: any[] = [];
  for (const { name, records } of xlsxObj) {
    for (const record of records) {
      const tagKeys = Object.keys(record).filter((key) => {
        return tagPattern.test(key);
      });

      const tags = tagKeys.map((key) => {
        return record[key];
      });

      const meansKeys = Object.keys(record).filter((key) => {
        return meanPattern.test(key);
      });

      const means = meansKeys.map((key) => {
        return record[key];
      });

      const metricName = record["メトリック名"];
      const dataType = record.dataType;

      metrics.push({
        value: metricName,
        dataType,
        means: means,
        tags: tags,
      });
    }
  }

  return metrics;
};

export const metricFileUpload = async (
  file: File
): Promise<UploadLogStatus[]> => {
  const logs: UploadLogStatus[] = [];
  const extensions = file.name.split(".").at(-1);
  let data: any[] | any = [];
  switch (extensions) {
    case "json":
      data = await parseJSON(file);
      break;
    case "xlsx":
      const buffer = await file.arrayBuffer();
      const workbook = xlsx.read(buffer, { type: "buffer" });
      data = await excel2Json(workbook);
  }

  const newtzero = createNewtZeroAPI();
  data = Array.isArray(data) ? data : [data];
  for (const d of data) {
    const metricName = d.value;

    const res = await newtzero.metric.save(
      metricName,
      {
        means: d.means,
        tags: d.tags,
        dataType: d.dataType,
        shareLevel: "private",
      },
      {
        upsert: true,
        upsertTags: true,
        mergeMeans: true,
      }
    );

    logs.push({
      status: res ? 200 : -1,
      name: d.value,
    });
  }

  return logs;
};
