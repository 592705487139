import React from "react";
import styled from "styled-components";

import { Checkbox } from "@material-ui/core";

export interface ILCCheckCellComponentProps {
  data: {
    value: boolean;
  };
}

const StyledCheckBox = styled(Checkbox)`
  color: ${(props) => props.theme.positiveColor};
  &.Mui-disabled {
    color: ${(props) => props.theme.positiveColor};
    /* background: ${(props) => props.theme.positiveColor}; */
  }
`;

export const LCCheckCellComponent: React.FC<ILCCheckCellComponentProps> = (
  props
) => {
  return (
    <>
      {props.data.value === true ? (
        <StyledCheckBox
          // value={true}
          checked={true}
          // readOnly={true}
          disabled={true}
        />
      ) : null}
    </>
  );
};
