import React, { useState, useCallback } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import { FilterUIConf } from "../../../types/LowCodeUiDefinition";
import styled from "styled-components";
import { useCheckBoxFields } from "../../../hooks/useCheckBoxFields";

interface ILCCheckBoxGroupComponentProps {
  name?: string;
  filter: FilterUIConf;
}

const StyledCheckBox = styled(Checkbox)`
  color: ${(props) => props.theme.primaryColor};
  &.Mui-checked {
    color: ${(props) => props.theme.primaryColor};
  }

  &.MuiCheckbox-root {
    padding: 1px;
    margin-left: 8px;
  }
`;

/**
 * Labelといくつかのチェックボックス
 * @param props
 * @returns
 */
const LCSelectFieldAsCheckBox: React.FunctionComponent<ILCCheckBoxGroupComponentProps> =
  (props) => {
    const { label, selections, callback } = props.filter;

    const filterId = label;
    const form = useCheckBoxFields(props.name);

    const [checkState, setCheckState] = useState<{ [key: string]: boolean }>(
      {}
    );

    const [isAll, setIsAll] = useState(false);

    const handleChange = (e: any) => {
      const cbLabel: string = e.target.name;

      setCheckState((prev) => {
        return { ...prev, [cbLabel]: e.target.checked };
      });

      form.handleCheckBoxField(filterId, {
        name: e.target.name,
        checked: e.target.checked,
        activeFilterContent: callback(e.target.name),
      });
    };

    const toggleAll = (e: any) => {
      // console.log(e.target.checked);
      // setIsAll(e.target.checked);
      // let nextCheckState: { [key: string]: boolean } = {};
      // for (const selection of selections) {
      //   nextCheckState[selection.label] = e.target.checked;
      //   form.handleCheckBoxField(filterId, {
      //     name: selection.label,
      //     checked: nextCheckState[selection.label],
      //     activeFilterContent: callback(selection.label),
      //   });
      // }
      // setCheckState((prev) => {
      //   return { ...prev, ...nextCheckState };
      // });
    };

    return (
      // <FormControl component="fieldset">
      //   <FormLabel component="legend">{label}</FormLabel>
      //   <FormGroup>
      //     {/* <FormControlLabel
      //       control={
      //         <StyledCheckBox
      //           checked={isAll}
      //           onChange={toggleAll}
      //           name="すべて"
      //         />
      //       }
      //       label={"すべて"}
      //     /> */}

      //     {selections.map((selection, id: number) => {
      //       const { label } = selection;

      //       return (
      //         <FormControlLabel
      //           name={`checkbox-${label}-${id}`}
      //           control={
      //             <StyledCheckBox
      //               checked={checkState[label] ?? false}
      //               onChange={handleChange}
      //               name={selection.label}
      //             />
      //           }
      //           label={label}
      //         />
      //       );
      //     })}
      //   </FormGroup>
      // </FormControl>
      <></>
    );
  };

export default LCSelectFieldAsCheckBox;
