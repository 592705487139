import { APIConfigProps, createAPIConfig } from "../config/http";
import { OptX } from "./optx";
import { PipelineX } from "./pipelinex";
import { Xervice } from "./xervice";

export const nXtal = (props: APIConfigProps) => {
  const instance = createAPIConfig(props);

  const xervice = Xervice(instance);
  const optx = OptX(instance);
  const pipelinex = PipelineX(instance);

  return {
    xervice,
    optx,
    pipelinex,
  };
};
