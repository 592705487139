import React, { useState, useCallback } from "react";
import { ASButton, ASEasyTable, ASSelect } from "allegro-ui";
// import { FTDashBoardWidget } from ".";
import styled from "styled-components";
import Masonry from "react-masonry-css";
import { PersonalData } from "src/fortress/models";

import { usePersonalThumbData } from "src/fortress/hooks/usePersonalThumbData";
import { FT3ColJsonCard } from "src/fortress/components/molecules/FT3ColJsonCard";
import { FTTopDataTableViewer } from "src/fortress/components/organisms/FTTopDataTableViewer";
import { FTDraggableJsonView } from "src/fortress/components/organisms/FTDraggableJsonView";
import { usePersonalDataDetail } from "src/fortress/hooks/usePersonalDataDetail";
import { Tab } from "@material-ui/core";

import { translateLabel } from "src/fortress/utils/dataShape";

const MasonryGrid = styled(Masonry)`
  display: flex;
  margin-left: -30px;
  margin-top: -30px;
`;

const MasonryItem = styled.div`
  margin-left: 30px;
  margin-top: 30px;
`;

const CaptionGroup = styled.div`
  margin-top: 14px;
  color: #aaa;
  font-size: 10px;
  padding: 8px 0 0 0;
  border-top: 1px solid #eee;
  span {
    display: block;
    line-height: 1;
    overflow-wrap: break-word;
  }
`;

const WindowContainer = styled.div`
  width: 100%;
  position: relative;
`;

const WindowControl = styled.div`
  padding-bottom: 8px;
  display: flex;
  justify-content: flex-end;
`;

const SelectBox = styled.div`
  /* text-align: right; */
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
`;

const Table = styled.table`
  width: 100%;
  overflow-x: scroll;
`;

const TBody = styled.tbody`
  width: 100%;
`;

type Props = {
  //   personalDatas: PersonalData[];
};

//児童詳細画面の本人データのコンポーネント。
//住民記録、生活保護、乳児健診、3歳児検診など、合計27個のデータが存在するが、そのデータは日々府中市職員の人びとがexcelで入力したものをsmartEdgeサーバーに登録されたものである。
//そのデータをallegrobotを介してこのUIに出力している。

export const LCKihonDataPanel: React.FC<Props> = () => {
  //27個のデータのうちどのデータを見たいかを選ぶことができるようにしている。
  const { data, setCategory, categories, cursorCategoryName } =
    usePersonalThumbData();
  const { fetch } = usePersonalDataDetail();

  const [detailWindows, setDetailWindows] = useState<
    { title: string; data: any; key: string }[]
  >([]);

  const handleCategory = (categoryName: string) => {
    setCategory(categoryName);
  };

  const handleDetail = useCallback(
    async (fileName: string, id: number = 0) => {
      const data = await fetch(fileName, id);
      setDetailWindows([
        ...detailWindows,
        {
          title: fileName,
          data: data,
          key: fileName + Math.random(),
        },
      ]);
    },
    [detailWindows, fetch]
  );

  const handleYobouSesshuDetail = useCallback(
    (data: any) => {
      setDetailWindows([
        ...detailWindows,
        {
          title: "定期予防接種業務",
          data: data,
          key: "定期予防接種業務" + Math.random(),
        },
      ]);
    },
    [detailWindows]
  );

  const renderTableRows = () => {
    const result = Object.keys(data)
      .filter((key) => key !== "contents" && key !== "_id")
      .map((key, id) => {
        if (data[key].length === 1) {
          return (
            <FT3ColJsonCard
              key={`ft3-col-json-parsonal-data-thumb-${id}`}
              title={key}
              rawdata={data[key][0].contents.rawdata}
              onClick={() => {
                handleDetail(key);
              }}
            />
          );
        } else if (data[key].length > 1) {
          return <></>;
        } else {
          return (
            <FT3ColJsonCard
              key={`ft3-col-json-parsonal-data-thumb-${id}`}
              title={key}
              onClick={() => {
                handleDetail(key);
              }}
            />
          );
        }
      });
    return result;
  };

  const renderMultipleData = () => {
    if (cursorCategoryName === "予防接種") return <></>;
    const result = Object.keys(data)
      .filter((key) => key !== "contents" && key !== "_id")
      .map((key, id) => {
        if (data[key].length > 1) {
          return (
            <div key={`t--b-le${id}`}>
              <FTTopDataTableViewer
                title={translateLabel(key)}
                topdataArray={data[key]}
                onClick={(dataHash: any) => {
                  handleDetail(key, dataHash.id);
                }}
              />
            </div>
          );
        } else {
          return null;
        }
      });
    return result;
  };

  return (
    <div>
      {detailWindows.length > 0 ? (
        <WindowControl>
          <ASButton
            kind="secondary"
            onClick={() => {
              setDetailWindows([]);
            }}
          >
            すべて閉じる
          </ASButton>
        </WindowControl>
      ) : null}
      <WindowContainer>
        {detailWindows.map((d, id) => {
          return (
            <FTDraggableJsonView
              title={d.title}
              data={d.data}
              key={`draggable-window-child-detail-${d.key}`}
              id={id}
              onClose={() => {
                setDetailWindows(detailWindows.filter((d, i) => id !== i));
              }}
            />
          );
        })}
      </WindowContainer>

      <Table>{data ? renderTableRows() : null}</Table>
      {data ? renderMultipleData() : null}
    </div>
  );
};
