import * as React from "react";
import styled from "styled-components";
import LinearProgress from "@material-ui/core/LinearProgress";
import { CircularProgress } from "@material-ui/core";

const Board = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  text-align: center;
  color: ${(props) => props.theme.primaryColor};
`;

const ASCircularProgress = styled(CircularProgress)`
  color: ${(props) => props.theme.primaryColor};
`;

const ASLinearProgress = styled(LinearProgress)`
  .MuiLinearProgress-barColorPrimary {
    background: ${(props) => props.theme.primaryColor};
  }
`;

interface ILCLoadingBoardcomponentProps {
  kind?: "circular" | "linear";
}

export const LCLoadingBoard: React.FunctionComponent<ILCLoadingBoardcomponentProps> =
  (props) => {
    const renderProgress = () => {
      switch (props.kind) {
        case "circular":
          return <ASCircularProgress />;
        case "linear":
          return <ASLinearProgress />;
        default:
          return <ASCircularProgress />;
      }
    };
    return <Board>{renderProgress()}</Board>;
  };
