import { atom } from "recoil";
import { HttpEdgeProps } from "../components/atoms/XRHttpEdge";

export const EdgesState = atom<HttpEdgeProps[]>({
  key: "EdgesState",
  default: [],
});

export const requestEdgesIdState = atom({
  key: "requestEdgesIdState",
  default: 0,
});
