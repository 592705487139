import { ASButton, ASCard } from "allegro-ui";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Root = styled.div`
  display: grid;
  place-items: center;
`;

const StyledCard = styled(ASCard)`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 20vh;
  width: 500px;
  height: 350px;
  padding: 32px;

  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 6px 6px rgba(0, 0, 0, 0.16);
`;
const Body = styled.div`
  height: 100%;
  margin: 0 auto;
  padding: 24px 0;
`;

const ButtonContainer = styled.div`
  margin-top: auto;
  width: 100%;
`;

const StyledButton = styled(ASButton)`
  width: 100%;
`;

const PageTitle = styled.h1`
  font-size: 32px;
  margin-bottom: 24px;
`;
export const DFNotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  const handleMain = () => {
    navigate("/");
  };
  return (
    <Root>
      <StyledCard>
        <PageTitle>Not Found.</PageTitle>
        <Body>
          <div>お探しのページが見つかりませんでした。</div>
          <div>削除されたか、入力したURLが間違っている可能性があります。</div>
        </Body>

        <ButtonContainer>
          <StyledButton kind="primary" onClick={handleMain}>
            トップページへ戻る
          </StyledButton>
        </ButtonContainer>
      </StyledCard>
    </Root>
  );
};
