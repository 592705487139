import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeView from "@material-ui/lab/TreeView";
import { useRecoilState, useSetRecoilState } from "recoil";
import { TreeModel } from "src/opt-editor/models";

import {
  OEDeleteRightClickMenu,
  OECreateFileRightClickMenu,
  OERenameFolderMenu,
} from "../atoms";
import Menu from "@material-ui/core/Menu";

import { OETreeItem } from "../atoms/OETreeItem";

import scienceFolder from "../../recoils/ScienceFolderState";
import scienceFile from "../../recoils/ScienceFileState";

import { useTreeList } from "src/opt-editor/hooks/useTreeList";
import { OEChangeItemPermissionMenu } from "../atoms/OEChangeItemPermissionMenu";
import { isScienceFolder } from "src/opt-editor/models/ScienceFSItem";

import { useFileOpener } from "src/opt-editor/recoils/EditorPaneState";

const PBox = styled.div`
  padding: 16px;
`;

const Container = styled.div`
  overflow: scroll;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Head = styled.h3`
  /* margin-top: 24px; */
  font-weight: 100;
  font-size: 13px;
  opacity: 0.5;
`;

type URLParams = {
  fileId: string | undefined;
};

export const OEFSView: React.FC = () => {
  const { treeList } = useTreeList();
  const { openFile } = useFileOpener();

  const history = useNavigate();
  const { fileId } = useParams<URLParams>();

  const setFolderFilter = useSetRecoilState(scienceFolder.filterState);
  const setCursorFileId = useSetRecoilState(scienceFile.cursorItemIdState);
  const setFileFilter = useSetRecoilState(scienceFile.filterState);
  const [cursorFolderId, setCursorFolderId] = useRecoilState(
    scienceFolder.cursorItemIdState
  );
  const [cursorFolder, setCursorFolder] = useRecoilState(
    scienceFolder.item(cursorFolderId)
  );
  const useFolder = scienceFolder.useItemList();
  const useFile = scienceFile.useItemList();

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [mouseXY, setMouseXY] = useState<{
    x: number | null;
    y: number | null;
  }>({ x: null, y: null });
  const [showContextMenu, setShowContextMenu] = useState(false);

  // アイコンをクリックした場合
  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
    return;
  };

  // TreeVewのをクリックした場合
  const handleSelect = (event: React.ChangeEvent<{}>, id: string) => {
    // event.preventDefault();
    // 選択したフォルダIDをセット
    setCursorFolderId(Number(id) ?? 0);
    if (expanded.includes(id)) {
      setExpanded(expanded.filter((t) => t !== id));
    } else {
      setExpanded([id, ...expanded]);
      const folderFilter = createFilterFromIds([id, ...expanded]);
      setFolderFilter(folderFilter);
    }

    return;
  };

  const createFilterFromIds = (ids: string[]) => {
    return {
      $or: [
        { "contents.rawdata.parent": 0 },
        ...ids.map((id) => {
          return { "contents.rawdata.parent": Number(id ?? 0) };
        }),
      ],
    };
  };

  const cursorAndOpenFIle = async () => {
    if (isInitialized === false) {
      setFileFilter({
        $or: [
          { "contents.rawdata.parent": 0 },
          { "contents.rawdata.itemId": fileId },
        ],
      });
      // setReqFileId(reqFileId + 1);
    }

    const tmpFiles = await useFile.getItems();
    const cursorFile = tmpFiles.find((file: any) => {
      return String(file.itemId) === fileId;
    });

    if (cursorFile) {
      const parentIds = ["0", String(cursorFile.parent)];
      const filter = createFilterFromIds(parentIds);

      setFolderFilter(filter);
      setFileFilter({ "contents.rawdata.parent": cursorFile.parent });
      setCursorFolderId(Number(cursorFile.parent) ?? 0);
      setCursorFileId(cursorFile.itemId);

      openFile(cursorFile);
      setIsInitialized(true);
    }
  };

  useEffect(() => {
    if (fileId) {
      cursorAndOpenFIle();
    } else {
      setFolderFilter({ "contents.rawdata.parent": 0 });
    }
  }, []);

  const handleRightClick = useCallback(
    (event: React.MouseEvent, itemId: number) => {
      event.stopPropagation();
      setCursorFolderId(Number(itemId) ?? 0);
      event.preventDefault();
      setMouseXY({
        x: event.clientX - 2,
        y: event.clientY - 4,
      });
      setShowContextMenu(true);
    },
    [setCursorFolderId]
  );

  // 右クリックメニューを閉じる
  const handleClose = () => {
    setMouseXY({
      x: null,
      y: null,
    });
    setShowContextMenu(false);
  };

  const renderTree = useCallback(
    (treeList: TreeModel[]) => {
      const sortedTreeList = treeList.sort((a, b) => {
        var nameA = a.name.toUpperCase(); // 大文字と小文字を無視する
        var nameB = b.name.toUpperCase(); // 大文字と小文字を無視する
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
      return sortedTreeList.map((treeItem: TreeModel, id: number) => {
        return (
          <OETreeItem
            treeItem={treeItem}
            onRightClick={handleRightClick}
            key={`sciencefolder-tree-item-${id}`}
          >
            {Array.isArray(treeItem.child) ? renderTree(treeItem.child) : null}
          </OETreeItem>
        );
      });
    },
    [handleRightClick]
  );

  return (
    <>
      <Container>
        <PBox>
          <Head>Projects</Head>
        </PBox>

        <TreeView
          // className={classes.root}
          area-label="myFolders"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpanded={[]}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultEndIcon={<ChevronRightIcon />}
          expanded={expanded}
          selected={String(cursorFolderId)}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
        >
          {renderTree(treeList ?? [])}
          {/* {TreeList} */}
        </TreeView>
      </Container>
      {/* contextMenuがnullなら画面閉じる */}

      {/* @todo このコンポーネントをfileとfolderで共有しないようにする。一緒だとファイルにリロードがかかったときにこのコンポーネントもリロードされる。 */}

      <Menu
        keepMounted
        open={showContextMenu}
        // onClose={contextMenu.mouseY === null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          mouseXY.y !== null && mouseXY.x !== null
            ? { top: mouseXY.y, left: mouseXY.x }
            : undefined
        }
      >
        <OECreateFileRightClickMenu onMenuClose={handleClose} />
        <OERenameFolderMenu
          showChild={setMouseXY}
          onMenuClose={handleClose}
          item={cursorFolder}
          onChangeName={(newItem) => {
            console.log("Changed Item Name");
            console.log(newItem);
            if (isScienceFolder(newItem)) {
              setCursorFolder(newItem);
            }
          }}
        />

        <OEChangeItemPermissionMenu
          showChild={setMouseXY}
          onMenuClose={handleClose}
          item={cursorFolder}
          onChangePermission={(newItem) => {
            console.log(newItem);
            if (isScienceFolder(newItem)) {
              setCursorFolder(newItem);

              alert("権限設定を変更しました。");
              // refreshPage();
            }
          }}
        />

        <OEDeleteRightClickMenu
          showChild={setMouseXY}
          onMenuClose={handleClose}
        />
      </Menu>
    </>
  );
};
