import React from "react";
import styled from "styled-components";
import { ASFormLabel } from "../atoms";

const Title = styled.div`
  font-size: 24px;
  /* padding-bottom: 12px; */
  border-bottom: 3px solid;
  padding-left: 0px;
  padding-bottom: 11px;
  color: ${(props) => props.theme.textColor};
  border-color: ${(props) => props.theme.primaryColor};
  width: 100%;
`;

const BorderLine = styled.div``;

type Props = {
  title: string;
};

export const ASSectionFormField: React.FC<Props> = (props: Props) => {
  return (
    <>
      <Title>{props.title}</Title>
      <BorderLine />
    </>
  );
};
