import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil";
// import { File, createFile, updateFile } from "../models/";
import { cursorFileIdState } from "../recoils/fileState";

import { useFileActions } from "./useFile";
import activityUtils from "../recoils/activityState";
import { Activity } from "../models";
import scienceFile from "../recoils/ScienceFileState";
import scienceFolder from "../recoils/ScienceFolderState";
import { createScienceFile, ScienceFile } from "../models/ScienceFSItem";
import { myProfileState } from "src/root/recoils/userState";

export const useFileMediator = () => {
  const [_cursorFileId, _setCursorFileId] = useRecoilState(cursorFileIdState);
  const [file, setFile] = useRecoilState(scienceFile.item(_cursorFileId));
  const fileActions = useFileActions();
  const currentFolderId = useRecoilValue(scienceFolder.cursorItemIdState);
  const { refresh } = scienceFile.useRefresh();
  const myProfile = useRecoilValue(myProfileState);

  const add = async (
    fileId: number | string,
    name: string,
    contents: any = null
  ) => {
    //Fileを追加する
    if (!currentFolderId) {
      alert("フォルダを選択してください。");
      return false;
    }
    try {
      const file = createScienceFile({
        name: name,
        itemId: fileId,
        parent: currentFolderId,
        contents: contents,
      });

      const oldFileTyped = { ...file, fileId: fileId };
      await scienceFile.addItem(oldFileTyped);

      console.log(file);
      //Activityを追加する。

      const activity: Activity = {
        activityId: activityUtils.getUniqueId(),
        type: "add",
        title: name,
        message: "ファイルを作成しました。",
        date: new Date().toDateString(),
        projectID: "",
        projectName: "",
        userNoText: sessionStorage.userNoText,
      };
      console.log(activity);
      await activityUtils.addItem(activity);
      refresh();
      return true;
    } catch {
      return false;
    }
  };

  const update = async (file: ScienceFile) => {
    try {
      console.log("[update file]", file);
      await setFile(file);
      const a: Activity = {
        activityId: activityUtils.getUniqueId(),
        type: "update",
        title: file.name,
        message: "ファイルを更新しました。",
        date: new Date().toDateString(),
        projectID: "",
        projectName: "",
        userNoText: sessionStorage.userNoText,
      };

      await activityUtils.addItem(a);
      setTimeout(() => {
        refresh();
      }, 500);
      // refresh();
      // window.location.reload();
      return true;
    } catch (err) {
      return false;
    }
  };

  const remove = async (fileId: number | string) => {
    //①リクエストをポスト
    await fileActions.remove(fileId);

    const a: Activity = {
      activityId: activityUtils.getUniqueId(),
      type: "delete",
      title: "削除されたファイル ID:" + fileId,
      message: "ファイルを削除しました。",
      date: new Date().toDateString(),
      projectID: "",
      projectName: "",
      userNoText: sessionStorage.userNoText,
    };
    await activityUtils.addItem(a);
    refresh();
  };

  const isWritable = (file: ScienceFile): boolean => {
    if (file.writers.includes(String(myProfile?.userNo ?? "-9999999"))) {
      console.log("editable");
      return true;
    } else if (file.writers.includes("*")) {
      console.log("editable");
      return true;
    } else {
      console.log(myProfile);
      console.log("not editable");
      return false;
    }
  };

  return { add, update, remove, isWritable };
};
