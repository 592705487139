import { api } from "src/api";
import { RiskAssessmentFormData } from "../models/RiskAssessment";
import { getConfig } from "src/fortress/config";

//x-total-countは、mongodbに保存されているデータの総数を返すものである。

//shapeFilterでフィルターを加工している。

const shapeFilter = (filter: any) => {
  let result: { [key: string]: any } = {};
  if (!filter) {
    return result;
  }
  if (Object.keys(filter).length > 0) {
    Object.keys(filter).forEach((key) => {
      if (filter[key] !== "" && filter[key] !== null) {
        result[key] = filter[key];
      }
    });
  }
  return result;
};

//全部のアセスメントデータを取得する

export const getAllAssesmentData = async (
  limit: number,
  page: number,
  word: string,
  filter?: any,
  sort?: any
): Promise<any> => {
  if (page <= 0) {
    console.error("０以下のページは無効です。");
  }
  const config = getConfig(sessionStorage.contractorNo);

  let whereQuery: Object = {
    ...config.defaultQueries.fetch.assesments.where,
    ...shapeFilter(filter),
  };

  if (word.length > 0) {
    whereQuery = {
      ...whereQuery,
      "contents.rawdata.フォーム作成者": { $regex: word },
    };
  }

  console.log(whereQuery);

  try {
    const res = await api({
      method: "GET",
      url: `/contractors/${sessionStorage.contractorNo}/users/${sessionStorage.userNoText}/topdatas`,
      params: {
        q: {
          select: ["*"],
          where: whereQuery,
          skip: (page - 1) * limit,
          limit: limit,
          orderby: sort ?? { _created: -1 },
        },
        options: { content: true },
      },
    });
    const rawdatas = res.data.map((d: any) => {
      return { ...d.contents.rawdata, ownerNo: d.bases.owner };
    });

    return [rawdatas ?? [], res.headers["x-total-count"] ?? 0];
  } catch (err) {
    console.error(err);
    return [[], 0];
  }
};

//ある特定個人のアセスメントデータを取得する。
//特定のアセスメントデータは、宛名番号とアセスメントが登録された日にちさえわかれば一意に絞って取得できるようになっている。

export const getAssessmentData = async (
  limit: number,
  page: number,
  atena: number | string,
  date?: string,
  filter?: any,
  sort?: any
): Promise<any> => {
  if (page <= 0) {
    console.error("０以下のページは無効です。");
  }
  const config = getConfig(sessionStorage.contractorNo);

  const whereQuery: Object = {
    ...config.defaultQueries.fetch.assesments.where,
    ...shapeFilter(filter),
    "contents.rawdata.宛名番号": String(atena),
    "contents.rawdata.フォームID": date,
  };

  try {
    const res = await api({
      method: "GET",
      url: `/contractors/${sessionStorage.contractorNo}/users/${sessionStorage.userNoText}/topdatas`,
      params: {
        q: {
          select: ["*"],
          where: whereQuery,
          skip: (page - 1) * limit,
          limit: limit,
          orderby: sort ?? { _created: -1 },
        },
        options: { content: true },
      },
    });
    const rawdatas = res.data.map((d: any) => {
      return d.contents.rawdata;
    });

    console.log(whereQuery);

    return [rawdatas ?? [], res.headers["x-total-count"] ?? 0];
  } catch (err) {
    console.error(err);
    return [[], 0];
  }
};

//アセスメントデータを投稿する

export const postAssessmentData = async (formData: RiskAssessmentFormData) => {
  const config = getConfig(sessionStorage.contractorNo);
  try {
    const res = await api({
      method: "POST",
      url: `/contractors/${sessionStorage.contractorNo}/datagate`,
      params: {
        apikey: sessionStorage.token,
        assetTags: config.defaultQueries.post.assesment.tags,
      },
      data: formData,
    });

    //TODO 統合データも更新する。
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

//アセスメントデータを削除する機能を提供する。
//宛名番号とフォームIDがわかればデータが消せるようになっている。

export const deleteAssessmentData = async (atena: string, date: string) => {
  const config = getConfig(sessionStorage.contractorNo);
  try {
    const res = await api({
      method: "DELETE",
      url: `/contractors/${sessionStorage.contractorNo}/datamanage/topdatas`,
      params: {
        q: {
          where: {
            ...config.defaultQueries.delete.assesment.where,
            "contents.rawdata.宛名番号": atena,
            "contents.rawdata.フォームID": date,
          },
        },
      },
    });
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};
