import {
  AssesObject,
  defaultRiskAssessmentFormData,
} from "src/fortress/models/RiskAssessment";
import { selector, atom } from "recoil";
import * as api from "../api";
import { cursorChildIdState } from "./singleChildPageState";

//基本的に複数のコンポーネントに跨って利用されているステートはrecoilを利用し、グローバルステートとして保持している。

type PageNavSettingsState = {
  pageTotalCount: number;
  perPage: number;
};

export const defaultPageNavSettingsState: PageNavSettingsState = {
  pageTotalCount: 1,
  perPage: 20,
};

//フォームId（アセスメントが登録された日）のデータをグローバルステートとして保持している。

export const cursorAssesmentDateState = atom<string>({
  key: "CursorAssesmentDate",
  default: "",
});

//全リスクアセスメントページから遷移した児童詳細画面か児童詳細画面から遷移した児童詳細画面かで、
//児童詳細画面のUIを変更したいのでこのようにグローバルステートを保持した。

export const isAllAssesmentState = atom<boolean>({
  key: "isAllAssesment",
  default: false,
});

//全部のアセスメントを表示しているページにおいて、総ページ数と１ページあたりのデータの数を保持しておく。

export const AssesmentPageNavState = atom<PageNavSettingsState>({
  key: "AssesmentPageNav",
  default: defaultPageNavSettingsState,
});

//全員のアセスメントを表示しているページにおいて、作成者を検索する際の言葉を状態管理する。

export const AssesmentSearchState = atom<string>({
  key: "AssesmentSearch",
  default: "",
});

//全員のアセスメントを表示しているページにおいて、ページ番号を保持する。

export const assesmentPageIndexState = atom<number>({
  key: "assesmentPageIndexState",
  default: 1,
});

//新規アセスメント作成画面において、チェックボックスのデータを保持するために持つ

export const cursorCheckedChildData = atom<AssesObject>({
  key: "CursorCheckedChildData",
  default: {},
});

export const cursorCheckedParentData = atom<AssesObject>({
  key: "CursorCheckedParentData",
  default: {},
});

export const cursorCheckedFamilyData = atom<AssesObject>({
  key: "CursorCheckedFamilyData",
  default: {},
});

export const cursorCheckedOtherData = atom<AssesObject>({
  key: "CursorCheckedOtherData",
  default: {},
});

//宛名番号とフォームIDから一意のアセスメントデータを取得する。

export const cursorAssesmentState = selector({
  key: "CursorAssesmentState",

  get: async ({ get }) => {
    const cursorChildId = get(cursorChildIdState);
    const AssesmentId = get(cursorAssesmentDateState);

    if (cursorChildId && AssesmentId) {
      const [data] = await api.getAssessmentData(
        1,
        1,
        cursorChildId,
        AssesmentId
      );
      const CursorAssesmentData = Array.isArray(data) ? data[0] : data;
      if (!CursorAssesmentData) return defaultRiskAssessmentFormData;
      return CursorAssesmentData;
    } else {
      return defaultRiskAssessmentFormData;
    }
  },
});
