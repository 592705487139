import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { FTChildListPageTemplate } from "../templates";
import * as api from "src/fortress/api";
import { useUIContext } from "src/fortress/contexts";
import { useNotify } from "allegro-ui";
import { childSearchWordState } from "src/fortress/recoils/childListState";
import { useSetRecoilState } from "recoil";
import FTServerListPageTemplate from "../templates/FTServerListPageTemplate";

const Root = styled.div``;

export const ServerListPage: React.FC = () => {
  const limit = 20;
  const setWord = useSetRecoilState(childSearchWordState);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const notify = useNotify();
  const ui = useUIContext();
  const { page } = ui.childList;

  //コンポーネントがレンダリングされた直後に、総ページ数をカウントしたいがために実装。

  const didMount = useCallback(async () => {
    notify.dispatch("loading", { isLoading: true });
    // const [count] = await api.getChildList(limit, page);
    const count = 10;
    notify.dispatch("loading", { isLoading: false });
    setTotalPageCount(Math.ceil(count / limit));
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    didMount();
  }, [didMount]);

  //ページ遷移したいがために実装

  const handlePage = (event: any, _page: number) => {
    ui.childList.handlePage(_page);
  };

  /**
   * 童一覧画面において検索したワードを状態として持たせるための処理
   * @param value
   */
  const handleSearch = async (value: string) => {
    console.log(value);
    if (value === "") {
      setWord(value);
      notify.dispatch("loading", { isLoading: true });
      notify.dispatch("loading", { isLoading: false });
      window.scrollTo(0, 0);

      handlePage(null, 1);
    } else {
      notify.dispatch("loading", { isLoading: true });
      setWord(value);
      notify.dispatch("loading", { isLoading: false });
    }
  };

  return (
    <Root>
      <React.Suspense fallback={<></>}>
        <FTServerListPageTemplate />
      </React.Suspense>
    </Root>
  );
};
