import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import routes from "./routes.json";
import { useAuth, PrivateRoute } from "src/root";

import {
  NZSingleMonitorPage,
  NZDataViewPage,
  // NTSingleMonitorPage,
  // NTAdminXerviceBrowserPage,
  NTManagementPage,
  NTActivityPage,
  NZSettingProfilePage,
  PTSettingTeamPage,
  NZSettingSecretsPage,
} from "./components/pages";

//
// portal
//
import { PTAppListPage } from "src/portal/components/pages/";
//
// smNTt science (opteditor)
//
import { NZAssetSinglePage } from "./components/lowcode/browser/asset/NZAssetSinglePage";
import { NZMetricSinglePage } from "./components/pages/NZMetricSinglePage";
import { LoadingCircleContainer } from "src/fortress/containers";
import { UIContextProvider, DataContextProvider } from "src/fortress/contexts";

import NewtZeroLogo from "./components/atoms/logo";
import { createUserAuthPath } from "src/cheetah/components/UserAuthPage/models/userAuthRoutes";
import { createUserAuthRoutes } from "src/cheetah/components/UserAuthPage/createUserAuthRoutes";
import { NTSciencePage } from "./components/pages/NZSciencePage";

import { NZAssetBrowserPage } from "./components/lowcode/browser/asset/NZAssetBrowserPage";
import { NZMetricListPage } from "./components/pages/NZMetricsListPage";
import { NZSettingGroupPage } from "./components/pages/NZSettingGroupPage";
import { NZSettingGroupSinglePage } from "./components/pages/NZSettingGroupSinglePage";

const Routes: React.FC = () => {
  const auth = useAuth();

  const authRoutes = createUserAuthPath({
    home: routes.HOME,
    login: routes.LOGIN,
    signUp: routes.SIGNUP,
    confirmEmail: routes.CONFIRMEMAIL,
    provisionEmail: routes.PROVISIONEMAIL,
    forgotPassword: routes.FORGOT_PASSWORD,
    rescuePassword: routes.RESCUE_PASSWORD,
  });
  //pathは最短マッチされるのでSCIENCEは一番下にする。
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {createUserAuthRoutes({
          logo: <NewtZeroLogo />,
          appName: "Newtroid",
          routes: authRoutes,
        })}
        <Route
          path={routes.APPS}
          element={
            <PrivateRoute>
              <PTAppListPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.SETTING_SECRETS}
          element={
            <PrivateRoute>
              <NZSettingSecretsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.SETTING_PROFILE}
          element={
            <PrivateRoute>
              <NZSettingProfilePage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.SETTING_GROUP}
          element={
            <PrivateRoute>
              <NZSettingGroupPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.SETTING_GROUP_SINGLE}
          element={
            <PrivateRoute>
              <NZSettingGroupSinglePage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.MONITOR}
          element={
            <PrivateRoute>
              <NZSingleMonitorPage />
            </PrivateRoute>
          }
        />

        {/* グローバルメニューのリンク */}
        <Route
          path={routes.LAKEHOUSE}
          element={
            <PrivateRoute>
              <NZDataViewPage />
            </PrivateRoute>
          }
        />

        <Route
          path={routes.KNOWLEDGE}
          element={
            <PrivateRoute>
              <NTSciencePage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.KNOWLEDGE_ROOT}
          element={
            <PrivateRoute>
              <NTSciencePage />
            </PrivateRoute>
          }
        />

        {/* その他のリンク */}
        <Route
          path={routes.SETTING_TEAMS}
          element={
            <PrivateRoute>
              <PTSettingTeamPage />
            </PrivateRoute>
          }
        />

        <Route
          path={routes.ASSET_LIST}
          element={
            <PrivateRoute>
              <NZAssetBrowserPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.METRIC_LIST}
          element={
            <PrivateRoute>
              <NZMetricListPage />
            </PrivateRoute>
          }
        />
        {/* cheetahの組み込み */}
        <Route
          path={routes.ASSET_SINGLE}
          element={
            <PrivateRoute>
              <NZAssetSinglePage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.METRIC_SINGLE}
          element={
            <PrivateRoute>
              <NZMetricSinglePage />
            </PrivateRoute>
          }
        />

        <Route
          path={routes.MANAGEMENT}
          element={
            <PrivateRoute>
              <NTManagementPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.ACTIVITIES}
          element={
            <PrivateRoute>
              <NTActivityPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.HOME}
          element={
            <PrivateRoute>
              <NZDataViewPage />
            </PrivateRoute>
          }
        />
      </>
    )
  );
  return (
    <UIContextProvider>
      <DataContextProvider>
        <LoadingCircleContainer />
        <RouterProvider router={router} />
      </DataContextProvider>
    </UIContextProvider>
  );
};

export default Routes;
