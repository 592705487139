import { ASRadioFormField } from "allegro-low-code-components";
import { ASButton, ASButtonGroup } from "allegro-ui";
import * as React from "react";
import { useForm } from "react-hook-form";
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
} from "recoil";
import { ManifestV1 } from "src/newtroid-console/models/ManifestV1";
import { downloadFileAs } from "src/utils/download";
import {
  LinkObject,
  useLinkObjectList,
} from "src/xerver-room/hooks/useLinkObjectList";
import { Slot } from "src/xerver-room/models";
import {
  cursorPipelineIdState,
  cursorPipelineState,
  slotIdsState,
  slotState,
} from "src/xerver-room/recoils";
import styled from "styled-components";

const Container = styled.div`
  width: 600px;
  padding: 24px;
  line-height: 1.7;
  > * {
    margin-bottom: 16px;
  }

  > form {
    > * {
      margin-bottom: 24px;
    }
  }
`;

const Title = styled.h1`
  font-size: 24px;
  line-height: 1.6;
`;

const ButtonContainer = styled.div`
  margin-top: 24px;
`;

const useSlotList = () => {
  const [cursorPipelineId, setCursorPipelineId] = useRecoilState(
    cursorPipelineIdState
  );
  const slotIds = useRecoilValue(slotIdsState(cursorPipelineId));

  let [slotList, setSlotList] = React.useState<Slot[]>([]);

  const slot = useRecoilCallback(({ snapshot }) => {
    return async (slotId: string) => {
      const r: Slot = await snapshot.getPromise(slotState(slotId));
      return r;
    };
  }, []);

  const initSlotList = React.useCallback(async () => {
    const result: Slot[] = [];
    for (let i = 0, len = slotIds.length; i < len; ++i) {
      result.push(await slot(slotIds[i]));
    }
    setSlotList(result);
  }, [slot, slotIds]);

  /**
   * Nodeを作成する
   */
  React.useEffect(() => {
    initSlotList();
  }, [initSlotList]);

  return { slotList };
};

const excludeUnnecessaryFields = (slot: Partial<Slot>): Partial<Slot> => {
  const { status, ...filtered } = slot;
  return filtered;
};

const excludeUiFields = (
  slot: Partial<Slot | LinkObject>
): Partial<Slot | LinkObject> => {
  const { ui, ...filtered } = slot;
  return filtered;
};

const excludeIpFields = (slot: Partial<Slot>): Partial<Slot> => {
  const { ip, serverId, ...filtered } = slot;
  return filtered;
};

export interface INTXerviceExportModalContentProps {
  onCancel: () => void;
}

export function NTXerviceExportModalContent(
  props: INTXerviceExportModalContentProps
) {
  const { handleSubmit, control } = useForm();

  const { slotList } = useSlotList();

  const { fetchList } = useLinkObjectList();

  const [previewData, setPreviewData] = React.useState("");

  const cursorPipeline = useRecoilValueLoadable(cursorPipelineState);

  //   const filtered = slotList && slotList.map((slot) => filterFields(slot));

  //   const manifest: ManifestV1 = {
  //     version: 1,
  //     xervices: filtered,
  //   };

  React.useEffect(() => {});

  const pipelineName =
    cursorPipeline.state === "hasValue"
      ? cursorPipeline.contents?.name
      : "xerviceManifest";

  const handleExport = React.useCallback(
    async (values: any) => {
      console.log(values);

      let filteredSlots =
        slotList && slotList.map((slot) => excludeUnnecessaryFields(slot));

      let filteredLinkObjects = await fetchList();

      if (values.includeUi === "含めない") {
        filteredSlots = filteredSlots.map((slot) => excludeUiFields(slot));
        filteredLinkObjects = filteredLinkObjects.map((lo) =>
          excludeUiFields(lo)
        ) as LinkObject[];
      }

      if (values.includeServerIp === "含めない") {
        filteredSlots = filteredSlots.map((slot) => excludeIpFields(slot));
      }

      const manifest: ManifestV1 = {
        version: 1,
        xervices: filteredSlots,
        linkObjects: filteredLinkObjects,
      };

      downloadFileAs(manifest, `${pipelineName}.manifest`, values.fileType);
      props.onCancel();
    },
    [fetchList, pipelineName, props, slotList]
  );

  console.log(slotList);
  return (
    <Container>
      <Title>{pipelineName}のExport</Title>
      <p>構築されたXerviceをマニフェストファイルとしてエクスポートします。</p>
      <form onSubmit={handleSubmit((values: any) => handleExport(values))}>
        <ASRadioFormField
          name={"fileType"}
          id={"0"}
          error={""}
          required
          label={"ファイルの種類"}
          control={control}
          defaultValue="yaml"
          values={["yaml", "json"]}
          supportText="出力するファイル形式を選択してください"
          type={"radio"}
          design="digicho"
        />

        <ASRadioFormField
          name={"includeUi"}
          id={"0"}
          required
          error={""}
          label={"UIデータのエクスポート"}
          control={control}
          defaultValue="含めない"
          values={["含める", "含めない"]}
          supportText="UIデータを含むか選択してください。"
          type={"radio"}
          design="digicho"
        />

        <ASRadioFormField
          name={"includeServerIp"}
          id={"0"}
          required
          error={""}
          label={"ServerIPのエクスポート"}
          control={control}
          defaultValue="含めない"
          values={["含める", "含めない"]}
          supportText="UIデータを含むか選択してください。"
          type={"radio"}
          design="digicho"
        />

        {/* <ASRadioFormField
          name={"manifestCategory"}
          id={"0"}
          required
          error={""}
          label={"フォーマット"}
          control={control}
          defaultValue="newtroid(v1)"
          values={[
            "newtroid(v1)",
            "kubernetes manifest(v1)",
            "docker-compose(v3)",
          ]}
          supportText="UIデータを含むか選択してください。docker-composeファイルの場合、シングルサーバー用に変換されます。"
          type={"radio"}
          design="digicho"
        /> */}

        {/* <ASMultilineTextFormField
          inputOnly
          defaultValue={previewData}
          type="text"
          label="Preview"
          name="preview"
          error={""}
          editable={false}
          design="digicho"
          supportText=""
          control={control}
          required={false}
        /> */}

        <ButtonContainer>
          <ASButtonGroup>
            <ASButton kind="secondary" onClick={() => props.onCancel()}>
              Cancel
            </ASButton>
            <ASButton kind="primary" type="submit">
              Export
            </ASButton>
          </ASButtonGroup>
        </ButtonContainer>
      </form>
    </Container>
  );
}
