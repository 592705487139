import { useRecoilValue } from "recoil";
import { cursorChildIdState } from "../recoils/singleChildPageState";
import * as api from "src/fortress/api";
import { useCallback, useEffect } from "react";

export const usePersonalDataDetail = () => {
  const cursorChildId = useRecoilValue(cursorChildIdState);

  const fetch = useCallback(
    async (fileName: string, id: number = 0) => {
      const res = await api.personalDetailData(cursorChildId, fileName, id);
      return res;
    },
    [cursorChildId]
  );

  const fetchByHash = useCallback(
    async (fileName: string, hash: string) => {
      const res = await api.personalDetailDataByHash(
        cursorChildId,
        fileName,
        hash
      );
      return res;
    },
    [cursorChildId]
  );

  return { fetch, fetchByHash };
};
