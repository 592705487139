import React, { useState } from "react";
import styled from "styled-components";
import { ASButton, defaultTheme } from "allegro-ui";
import { useRecoilValueLoadable } from "recoil";
import { slotListState } from "src/xerver-room/recoils";
import { Slot } from "src/xerver-room/models";
import { TextField, withStyles } from "@material-ui/core";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  width: 400px;
  padding: 24px;
  h1 {
    font-size: 32px;
    font-weight: bold;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FormInput = styled.div`
  > * {
    margin-left: 30px;
  }
`;

const StyledTextField = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      color: defaultTheme.secondaryOnText,
    },
  },
}))(TextField);

type Props = {
  IOType: "inputs" | "outputs" | undefined;
  cursorSlot: Slot;
  onSubmit: (formdata: { name: string; port: string }) => void;
};

export const CreateIOModal: React.FC<Props> = (props) => {
  const [name, setName] = useState<string>("");
  const [port, setPort] = useState<string>("");
  const { IOType, cursorSlot, onSubmit } = props;
  const slotsLoadable = useRecoilValueLoadable(slotListState);
  const [err, setErr] = useState<boolean>(true);
  const [errMsg, setErrMsg] = useState<string>("名前を入力してください");

  const IONameValidation = (name: string) => {
    if (name.length === 0) {
      setErr(true);
      setErrMsg("名前を入力してください");
    } else if (IOType === "inputs") {
      inputsNameValidation(name);
    } else {
      outputNameValidation(name);
    }
  };

  const inputsNameValidation = (name: string) => {
    const slots =
      slotsLoadable.state === "hasValue" ? slotsLoadable.contents : [];
    const existsInputs =
      slots.filter(
        (slot) => slot.inputs.filter((input) => input.name === name).length > 0
      ).length > 0;

    if (existsInputs) {
      setErr(true);
      setErrMsg("その名前は既に使用されています");
    } else {
      setErr(false);
      setErrMsg("");
    }
  };

  const outputNameValidation = (name: string) => {
    const slots =
      slotsLoadable.state === "hasValue" ? slotsLoadable.contents : [];

    const existsSelfInput =
      cursorSlot.inputs.filter((input) => input.name === name).length > 0;

    const exsistsInputs =
      slots.filter(
        (slot) => slot.inputs.filter((input) => input.name === name).length > 0
      ).length > 0;

    if (existsSelfInput) {
      setErr(true);
      setErrMsg("自身のInputへ割り当てることはできません");
    } else if (!exsistsInputs) {
      setErr(true);
      setErrMsg("Inputsに存在する名前を入力してください");
    } else {
      setErr(false);
      setErrMsg("");
    }
  };

  return (
    <Container>
      <h1>{IOType} 新規作成</h1>
      <FormInput>
        名前
        <StyledTextField
          error={err}
          helperText={errMsg}
          onChange={(e) => {
            setName(e.target.value);
            IONameValidation(e.target.value);
          }}
        ></StyledTextField>
      </FormInput>
      <FormInput>
        Port
        <StyledTextField
          onChange={(e) => {
            setPort(e.target.value);
          }}
        ></StyledTextField>
      </FormInput>

      <ButtonGroup>
        <ASButton
          kind={"primary"}
          onClick={() => onSubmit({ name: name, port: port })}
          disabled={err}
        >
          作成する
        </ASButton>
      </ButtonGroup>
    </Container>
  );
};
