import React from "react";

import { RiskAssessmentFormData } from "src/medican/models/RiskAssessment";
import styled from "styled-components";
import { useTheme } from "styled-components";
import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { ASLowCodeDataBrowserContainer } from "src/cheetah/components/libs/ASLowCodeBrowser";

type Props = {
  userNo: string;
  assessmentList: RiskAssessmentFormData[];
};

const No = styled.div`
  text-align: center;
  margin-top: 150px;
  font-size: 40px;
  color: #dddddd;
`;

const extractFormName = (data: any) => {
  if (data) {
    const asset: string[] = data.bases?.tags ?? [];
    const extAsset = asset.filter((tag) => {
      return new RegExp(`PHR:Record:(.*)`).test(tag);
    });

    return {
      value: (extAsset[0] ?? "").replace(`PHR:Record:`, ""),
    };
  } else return { value: "" };
};

export const MDAssesmentList: React.FC<Props> = (props) => {
  const { assessmentList } = props;

  const theme = useTheme();
  const color = theme.darkMode ? "#dddddd" : "#dddddd";

  const ui: ASBrowserUiConf = {
    newtQ: {
      asset: ["症例登録システム"],
      primaryKey: "bases.tags",
      search: {
        $and: [
          { "contents.optdata.non_numeric_answered_by": props.userNo },
          {
            "bases.tags": {
              $elemMatch: {
                $regex: "PHR:Record:(.*)",
              },
            },
          },
        ],
      },
      shape: {
        "contents.optdata.non_numeric_answered_by": "any",
        "bases.tags": "any",
        "contents.optdata.non_numeric_reviewed_by": "any",

        _created: "any",
        _modified: "any",
      },
    },
    filters: [],
    tables: [
      {
        label: "フォーム",
        component: "LCSimpleColComponent",
        data: extractFormName,
        options: {},
      },
      {
        label: "レビュー済",
        component: "LCCheckCellComponent",
        data: (data: any) => {
          const checker =
            data.contents?.optdata?.non_numeric_reviewed_by !== undefined;
          return { value: checker };
        },
        options: {},
      },
      {
        label: "回答日",
        component: "LCUnixTimeCellComponent",
        data: {
          value: "_created",
        },
        options: {
          // dateFormat: "wareki",
        },
        statics: {},
      },
      {
        label: "レビュー日",
        component: "LCUnixTimeCellComponent",
        data: (data: any) => {
          return {
            value: data?.contents?.optdata?.non_numeric_reviewed_by
              ? data._modified
              : null,
          };
        },
        options: {},
      },

      {
        label: "",
        component: "LCArrowLinkCellComponent",
        data: {
          userNo: "contents.optdata.non_numeric_answered_by",
          formId: "_id",
        },
        options: {},
        statics: { path: "/subject/{userNo}/form/{formId}" },
      },
    ],
    pager: {
      perPage: 8,
    },
  };

  return <ASLowCodeDataBrowserContainer name="user-forms-page" uiConf={ui} />;
};
