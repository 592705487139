import styled from "styled-components";
import { OEOptSelector, OELogAndMetric } from ".";
import { OEPipelineSelect } from "src/opt-editor/components";

const Root = styled.div`
  padding: 12px;
  margin: 0px;
  width: 25%;
`;

const FlexContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FullWidthBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  > * {
    margin-left: 8px;
  }
  .MuiInputBase-root {
    flex: 1;
  }
`;

const BorderedBox = styled.div`
  padding: 24px;
  border: 1px solid #777;
  border-radius: 2px;
`;

const FlexBorderedBox = styled.div`
  flex: 1;
  padding: 24px;
  margin-top: 30px;
  border: 1px solid #777;
  border-radius: 2px;
`;

type Props = {
  show: boolean;
};

export const OERightPanel: React.FC<Props> = (props) => {
  const { show } = props;

  if (show) {
    return (
      <Root>
        <FlexContainer>
          {/* パイプラインの選択 */}
          <FullWidthBox>
            <OEPipelineSelect />
          </FullWidthBox>

          <BorderedBox>
            {/* オプティマイザースロットの選択 */}
            <OEOptSelector />
          </BorderedBox>

          {/* システムログとメトリック一覧 */}
          <FlexBorderedBox>
            <OELogAndMetric />
          </FlexBorderedBox>
        </FlexContainer>
      </Root>
    );
  } else {
    return <></>;
  }
};
