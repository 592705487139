import { api } from "../../api/http";
/**
 * 児童を検索する
 * @param {string} word 検索ワード
 * @return {object} レスポンスデータ
 */
export const newtOut = async (
  query: any,
  options?: any
): Promise<[res: any, count: number | undefined]> => {
  try {
    const res = await api(`/newtout/aggr`, {
      method: "post",

      params: {
        newtQuery: { data: "useing body" },
        count: options?.count,
      },
      data: {
        ...query,
      },
    });
    //TODO エラーハンドル
    // console.log("/newtout/aggr", res.status);
    // console.log(res.data);
    console.log(res.data);
    if (options && options.count === true) {
      return [
        [],
        Array.isArray(res.data.message.doc)
          ? res.data.message.doc[0].dataTotalCount
          : undefined,
      ];
    } else {
      return [
        Array.isArray(res.data.message.doc) ? res.data.message.doc : [],
        res.headers["x-total-count"] ?? undefined,
      ];
    }
  } catch (err) {
    console.error(err);
    return [[], 0];
  }
};
