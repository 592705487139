import { useState, useEffect, useCallback } from "react";
import { createNewtZeroAPI } from "src/api";

export const useMetrics = (values: string[]) => {
  const [isLoading, setIsLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [metrics, setMetrics] = useState<any[]>([]);
  const [searchWord, setNewSearchWord] = useState<string>("");
  const newtzero = createNewtZeroAPI();

  const fetchMetrics = useCallback(async () => {
    const newtQuery = {
      collection: "Metric",
      search: {
        $and: [
          {
            value: {
              $in: values.map((value) => {
                return value;
              }),
            },
          },

          {
            value:
              !searchWord && searchWord === ""
                ? undefined
                : { $regex: searchWord, $options: "i" },
          },
        ],
      },
      shape: {
        _id: "any",
        value: "any",
        means: "any",
      },
      limit: 100,
    };
    const _metrics = await newtzero.dataProvider(newtQuery);
    setMetrics(_metrics);
  }, [values, searchWord, newtzero]);

  const updateSearchWord = (newMetricValue: string) => {
    if (newMetricValue !== "") {
      setNewSearchWord(newMetricValue);
    } else {
      setNewSearchWord("");
    }
  };

  const initalize = useCallback(async () => {
    if (!initialized && values.length > 0) {
      await fetchMetrics();
      setInitialized(true);
    }
  }, [fetchMetrics, initialized, values.length]);

  useEffect(() => {
    initalize();
  }, [initalize]);

  return {
    metrics,
    updateSearchWord,
  };
};
