import { atom, useRecoilState } from "recoil";
import { useEffect } from "react";
import { useCursorOptLogic } from ".";

export const scriptState = atom<string>({
  key: "script",
  default: "",
});

export const useTextEditor = () => {
  const [script, setScript] = useRecoilState(scriptState);
  const cursorOptLogic = useCursorOptLogic();

  useEffect(() => {
    setScript(cursorOptLogic.contents);
  }, [cursorOptLogic.contents, setScript]);

  return { script, setScript };
};
