import { createProject } from "src/medican/models/Project";
import { medicanAPIConfig } from "./config";

// 回答アセット一覧取得API
export const getAssets = async (
  limit?: number,
  skip?: number,
  options?: any
) => {
  const url = `/asset`;
  const params = {
    apikey: sessionStorage.token,
    userNoText: sessionStorage.userNoText,
    limit: limit ?? 1,
    skip: skip ?? 0,
    contractorNo: sessionStorage.contractorNo,
    nameFilter: options?.name,
  };

  const res = await medicanAPIConfig.get(url, { params: params });
  return res;
};

// 回答アセット作成API
export const createAnswerAssets = async (data: any) => {
  const url = `/asset`;
  const params = {
    apikey: sessionStorage.token,
    userNoText: sessionStorage.userNoText,
    contractorNo: sessionStorage.contractorNo,
  };

  const res = await medicanAPIConfig.post(url, data, { params: params });
  return res;
};

export const saveProject = async (data: any) => {
  const url = "/asset";
  const params = {
    apikey: sessionStorage.token,
    userNoText: sessionStorage.userNoText,
  };

  const res = await medicanAPIConfig.put(url, data, { params });
  return res;
};

export const getAssetOne = async (formId: string) => {
  const url = `/asset/${formId}`;
  const params = {
    apikey: sessionStorage.token,
    userNoText: sessionStorage.userNoText,
  };

  const res = await medicanAPIConfig.get(url, { params });
  const resData = res.data.message;

  if (resData && resData.length > 0) {
    return createProject(resData[0]);
  }
};

// 回答アセット削除API
// TODO 未実装
export const deleteAnswerAsset = async () => {
  const params = {
    apikey: sessionStorage.token,
    userNoText: sessionStorage.userNoText,
  };
};
