import { PTSettingGroupTemplate } from "src/portal/components/templates/PTSettingGroupTemplate";
import { NZSettingPageTemplate } from "../templates/NZSettingsPageTemplate";

export const NZSettingGroupPage: React.FC = () => {
  return (
    <NZSettingPageTemplate>
      <PTSettingGroupTemplate />
    </NZSettingPageTemplate>
  );
};
