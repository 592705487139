import { ASLowCodeFormDefinition } from "allegro-low-code-components";
import { createNewtZeroAPI } from "src/api";

export const metricCreatingFormDefinition: ASLowCodeFormDefinition = {
  sections: [
    {
      title: "Setting Metrics",
      fields: [
        {
          label: "Metric name",
          name: "name",
          type: "text",
          active: "",
          values: undefined,
          required: true,
          errorText: "アセット名は必須です。",
        },
        {
          label: "Data Type",
          name: "dataType",
          type: "selection",
          active: "any",
          required: true,
          values: [
            { label: "数値", value: "number" },
            { label: "文字", value: "string" },
            { label: "日付", value: "date" },
            { label: "その他", value: "any" },
          ],
        },
        {
          label: "Means",
          name: "means",
          type: "chip",
          active: [],
          values: undefined,
          required: true,
          errorText: "意味は必須です。",
        },
        {
          label: "Share Level",
          name: "shareLevel",
          type: "selection",
          active: "public",
          values: ["public", "private"],
        },
        {
          label: "Tags",
          name: "tags",
          type: "chip",
          active: [],
          values: undefined,
          required: true,
          errorText: "タグは必須です。",
        },
      ],
    },
  ],
  onSubmit: async (d: any) => {
    const metricName = d.name;
    const body = {
      name: metricName,
      dataType: d.dataType,
      tags: d.tags,
      shareLevel: d.shareLevel,
      means: d.means,
    };

    try {
      const newtzero = createNewtZeroAPI();
      const canSaved = await newtzero.metric.save(metricName, body, {
        upsert: true,
        upsertTags: true,
      });

      if (canSaved) {
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  },
};
