import { useSetRecoilState } from "recoil";
import favyx from "src/api";
import { MonitorThumb } from "src/kinzaza/models";
import { monitorListRequestId } from "src/kinzaza/recoils";

export const useDeleteMonitor = () => {
  const setReqId = useSetRecoilState(monitorListRequestId);

  const handleDelete = async (monitor: MonitorThumb | undefined) => {
    if (monitor !== undefined) {
      try {
        const deletequery = {
          select: ["*"],
          where: {
            id: monitor.id,
            "bases.tags": "DataViewMonitors",
          },
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const res = await favyx.dataManage.remove(
          sessionStorage.contractorNo,
          "topdatas",
          deletequery
        );

        //TODO increment reqId
        setReqId((reqId) => reqId + 1);
      } catch (err) {
        console.error(err);
      }
    }
  };

  return {
    handleDelete,
  };
};
