import React, { Component, ReactElement, useState } from "react";
import styled from "styled-components";
import {
  NTPrivacyPolicy,
  NTNewtroidTermsOfUse,
} from "src/newtroid/components/molecules";
import { ASModal } from "allegro-ui";
import { CardContent } from "@material-ui/core";
import { Link } from "react-router-dom";

const Caption = styled.div`
  padding-bottom: 12px;
  padding: 30px;
  border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};
  font-size: 12px;
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.primaryColor};
  text-align: center;
`;

const StyledCardContent = styled.div`
  width: 50vw;
  height: 75vh;
`;

type Props = {
  privacyPolicyComponent?: ReactElement;
  termsOfSeviceComponent?: ReactElement;
};

export default function CHTermsAndPrivacyCaption(props: Props) {
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  return (
    <>
      <Caption>
        <StyledLink to="" onClick={() => setShowTermsOfUse(true)}>
          利用規約
        </StyledLink>
        及び
        <StyledLink to="" onClick={() => setShowPrivacyPolicy(true)}>
          プライバシーポリシー
        </StyledLink>
        に同意の上、登録又はログインへお進みください。
      </Caption>

      <ASModal
        open={showTermsOfUse}
        onClose={setShowTermsOfUse}
        title="利用規約"
      >
        <StyledCardContent>{props.termsOfSeviceComponent}</StyledCardContent>
      </ASModal>
      <ASModal
        open={showPrivacyPolicy}
        onClose={setShowPrivacyPolicy}
        title="プライバシーポリシー"
      >
        <StyledCardContent>{props.privacyPolicyComponent}</StyledCardContent>
      </ASModal>
    </>
  );
}
