import { AxiosInstance } from "axios";

export const save = async (
  instance: AxiosInstance,
  metricName: string,
  data: {
    means: string[];
    tags: string[];
    shareLevel: "private" | "public";
  },
  params?: {
    upsert?: boolean;
    upsertTags?: boolean;
    mergeMeans?: boolean;
  }
) => {
  try {
    const res = await instance.post(
      `/contractors/${sessionStorage.contractorNo}/metrics/${metricName}`,
      data,
      {
        params: params,
      }
    );

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};
