import {
  atom,
  useRecoilValue,
  useRecoilState,
  useRecoilValueLoadable,
} from "recoil";

import * as api from "../api";
import { useCallback, useEffect } from "react";

import { FamilyPersonalData } from "src/fortress/models";
import { produce } from "immer";
import queryConf from "src/fortress/api/familyDataQueryConf.json";
import {
  cursorChildIdState,
  cursorChildThumbState,
} from "src/fortress/recoils/singleChildPageState";

//基本的に複数のコンポーネントに跨って利用されているステートはrecoilを利用し、グローバルステートとして保持している。

//世帯データで家族一覧を表示するところで、20歳以上の人のところをクリックするとその人の詳しいデータが表示されるようになっているが、
//そのモーダルを表示するかしないかをグローバルステートとして保持している。

export const ModalState = atom<boolean>({
  key: "ModalState",
  default: false,
});

export const familyPersonalNameState = atom<string>({
  key: "FamilyPersonalNameState",
  default: "",
});

export const familyPersonalAgeState = atom<number>({
  key: "FamilyPersonalAgeState",
  default: 0,
});

//20歳以上のデータを保持するグローバルステート

export const ModalDataState = atom<Array<FamilyPersonalData>>({
  key: "ModalDataState",
  default: [],
});

//児童詳細ページの世帯詳細データをグローバルステートとして保持するように設計している。

export const familyPersonalDatasState = atom<Array<FamilyPersonalData>>({
  key: "FamilyPersonalDatasState",
  default: [],
});

//児童詳細ページの世帯データ詳細のデータを取得するためのhooks。
//componentsから切り離して描くことで、componentsフォルダ内のファイルが肥大化しないようにしている。

export const useFamilyPersonalDatas = () => {
  const [familyPersonalDatas, setFamilyPersonalDatas] = useRecoilState(
    familyPersonalDatasState
  );
  const cursorChildId = useRecoilValue(cursorChildIdState);
  const cursorChildThumbLoadable = useRecoilValueLoadable(
    cursorChildThumbState
  );

  const init = useCallback(async () => {
    let nextFamilyPersonalDatas: FamilyPersonalData[] = [];

    //早期リターン
    if (cursorChildId === "" || cursorChildId === null) return;
    if (cursorChildThumbLoadable.state === "loading") return;

    //宛名番号と世帯番号のどちらかでクエリを指定できるようにしている。
    queryConf.forEach(async (conf) => {
      let primaryKeyVal: { [key: string]: string } = {};
      const obj: { [key: string]: "setai" | "atena" } = {
        "contents.optdata.numeric_resident_register_code": "atena",
        "contents.optdata.numeric_household_number": "setai",
      };

      //主キーのクエリーを作る
      const primaryKey: string = conf.primaryKey ?? "id";
      primaryKeyVal[primaryKey] =
        cursorChildThumbLoadable.state === "hasValue"
          ? cursorChildThumbLoadable.contents[obj[primaryKey]]
          : "";

      const personalDataArr: FamilyPersonalData[] = await api.familyPersonalData(
        conf.kind,
        conf.tags,
        primaryKeyVal
      );

      nextFamilyPersonalDatas = produce(nextFamilyPersonalDatas, (draft) => {
        personalDataArr.forEach((pd: FamilyPersonalData) => {
          draft.push(pd);
        });
      });

      setFamilyPersonalDatas(nextFamilyPersonalDatas);
    });
  }, [
    cursorChildId,
    cursorChildThumbLoadable.contents,
    cursorChildThumbLoadable.state,
    setFamilyPersonalDatas,
  ]);

  useEffect(() => {
    init();
  }, [init]);

  return { familyPersonalDatas };
};
