import { CHTabs } from "src/cheetah/components/molecules/CHTabs";
import { NZMonitorBrowserTemplate } from "./NZMonitorBrowserTemplate";

import { Header } from "../organisms";
import styled from "styled-components";
import { NZAlertJobBrowserTemplate } from "./NZAlertJobBrowserTemplate";

const Root = styled.div`
  position: relative;
  background: ${(props) => props.theme.bgPrimaryColor};
  min-height: 100%;
  width: 100%;
  color: ${(props) => (props.theme.darkMode ? "#eee" : "#000")};
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

export const NZDataViewPageTemplate = () => {
  const tabs = [
    {
      label: "Monitor",
      component: <NZMonitorBrowserTemplate />,
    },
    {
      label: "Alert",
      component: <NZAlertJobBrowserTemplate />,
    },
    // {
    //   label: "Trail",
    //   component: (
    //     <NZDataOpsListTemplate
    //       name="Trail"
    //       browserUi={trailBrowserUi}
    //       formUi={metricCreatingFormDefinition}
    //     />
    //   ),
    // },
  ];

  return (
    <Root>
      <Header />
      <CHTabs tabs={tabs} disableBack />
    </Root>
  );
};
