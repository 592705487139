import React, { useRef, useState, useEffect } from "react";

import { OEEditorPane } from ".";

import { useRecoilState, useRecoilValue } from "recoil";

import { headerOffset } from "src/allegrobot/components/organisms/ARGlobalNav";
import styled from "styled-components";
import {
  cursorPaneIdState,
  paneContentsSelector,
  usePaneLayoutSwitcher,
} from "src/opt-editor/recoils/EditorPaneState";

const FlexContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  background: ${(props) => props.theme.bgPrimaryColor};
  flex-direction: column;
`;

const RowFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const RightWrapper = styled.div`
  border-left: 1px solid #636363;
`;

const ToggleLayoutButtonGroup = styled.div`
  background: ${(props) => props.theme.bgPrimaryColor};
  padding-left: 8px;
  position: absolute;
  right: 0;
  top: 0;
  height: 48px;
  display: flex;
  align-items: center;
`;

const SplitScreenIconWrapper = styled.div`
  display: flex;
  margin: 0 0 0 auto;
  margin-right: 8px;
  cursor: pointer;
`;

const PaneContainer = styled.div`
  height: calc(100vh - ${headerOffset}px);
  overflow: scroll;
  flex: 1;
`;

const OneScreenIcon = styled.div.attrs<Props>(
  (props): Attrs => ({
    borderColor: props.selected
      ? props.theme.primaryColor
      : props.theme.secondaryColor,
  })
)<Props & Attrs>`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border: ${(props) => `1px solid ${props.borderColor}`};
  border-radius: 2px;
  cursor: pointer;
`;

const SplitScreenIcon = styled.div.attrs<Props>(
  (props): Attrs => ({
    borderColor: props.selected
      ? props.theme.primaryColor
      : props.theme.secondaryColor,
  })
)<Props & Attrs>`
  width: 10px;
  height: 20px;
  border: ${(props) => `1px solid ${props.borderColor}`};
  border-radius: 2px;
`;

type Props = {
  selected?: boolean;
};
type Attrs = {
  borderColor?: string | null;
  borderTop?: string | null;
};

export const OEPanesView: React.FC = () => {
  const [editorHeight, setEditorHeight] = useState(0);

  // const currentSecondFileId = useRecoilValue(cursorSecondFileIdState);
  // const secondFile = useRecoilState(fileState(currentSecondFileId));
  // const [optName, setOptName] = useState("");
  // const [file, setFile] = useState<Json[]>([]);
  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);

  const paneContents = useRecoilValue(paneContentsSelector);

  //
  const { visiblePaneCount, toOneScreen, splitVertical, layoutType } =
    usePaneLayoutSwitcher();
  const [cursorPaneId, setCursorPaneId] = useRecoilState(cursorPaneIdState);
  //初期化
  useEffect(() => {
    const current = leftRef.current;
    // getOptName();
    if (current !== null) {
      setEditorHeight(current.clientHeight);
    }
  }, [leftRef]);

  // OptXのfetch
  // const getOptName = async () => {
  //   const res = await axios({
  //     method: "GET",
  //     url:
  //       "http://153.246.99.59/api/6/contractors/lab/optx/opt_210930LvcQPEyBnDOgjGmP?apikey=E970F68C268FF4D5D50568FC5BC383AFD5529303B50222BE386F25512BBE3D2D",
  //   });

  //   setOptName(res.data.name);
  //   const optId = res.data.optXId;
  //   getFile(optId);
  // };

  // 上記のOptXの中に存在するFileXをfetch
  // const getFile = async (optId: string) => {
  //   const res = await axios({
  //     method: "GET",
  //     url: `http://153.246.99.59/api/6/contractors/lab/optx/${optId}/file?apikey=E970F68C268FF4D5D50568FC5BC383AFD5529303B50222BE386F25512BBE3D2D`,
  //   });
  //   const json: Json[] = res.data;
  //   setFile(json);
  // };

  //リサイズ時の挙動
  // useEffect(() => {
  //   window.addEventListener("resize", () => {
  //     //CodeMirrorの高さを0にして画面の高さを縮小させたときにも高さが正しく更新されるようにする。
  //     setEditorHeight(0);
  //     const { current } = leftRef;
  //     if (current !== null) {
  //       setEditorHeight(current.clientHeight);
  //     }
  //   });
  // }, []);

  const onSplitScreen = () => {
    console.log("split");
    splitVertical();
    // rightRef?.current?.focus();
    // onActive("right");
    // setSplitScreen(true);

    // if (!isShowedSplitPanel) {
    //   file && setCursorSplitFileId(Number(file.fileId)); // 初回表示時は左パネルと同じファイルを開く
    //   setShowedSplitPanel(true); // アプリ起動後一度でも split ボタンを押下したらアプリ終了までフラグは true
    // }
  };

  const onOneScreen = () => {
    console.log("to one screen");
    toOneScreen();
    // leftRef?.current?.focus();
    // onActive("left");
    // setSplitScreen(false);
  };

  const handleClickPane = (paneId: number) => {
    setCursorPaneId(paneId);
  };

  const renderPanes = () => {
    let panes = [];
    for (let i = 0; i < visiblePaneCount; i++) {
      panes.push(
        <PaneContainer
          onClick={() => {
            handleClickPane(i);
          }}
          key={`editor-pane-${i}`}
        >
          <OEEditorPane active={cursorPaneId === i} author={""} paneId={i}>
            <span>{paneContents[i]}</span>
          </OEEditorPane>
        </PaneContainer>
      );
    }
    return panes;
  };

  return (
    <FlexContainer>
      <ToggleLayoutButtonGroup style={{ marginBottom: "5px" }}>
        <SplitScreenIconWrapper onClick={onSplitScreen}>
          <SplitScreenIcon selected={visiblePaneCount === 2} />
          <SplitScreenIcon selected={visiblePaneCount === 2} />
        </SplitScreenIconWrapper>
        <OneScreenIcon
          onClick={onOneScreen}
          selected={visiblePaneCount === 1}
        />
      </ToggleLayoutButtonGroup>
      <React.Suspense fallback={<>loading</>}>
        <RowFlexContainer>{renderPanes()}</RowFlexContainer>
      </React.Suspense>
    </FlexContainer>
  );
};
