import { defaultUser } from "allegro-models";
import { useRecoilValueLoadable } from "recoil";
import { myProfileState } from "src/root/recoils/userState";
import { useProfileActions } from "./useProfileActions";

export const useSettingProfile = () => {
  const myProfile = useRecoilValueLoadable(myProfileState);
  const { updateAvatar, updateProfile } = useProfileActions(
    myProfile.contents ?? defaultUser
  );

  const refreshPage = () => {
    window.location.reload();
  };

  const handleUpdateAvatar = async (file: any) => {
    const updated = await updateAvatar(file);
    if (updated) {
      refreshPage();
    }
  };

  const handleUpdateProfile = async (data: { [key: string]: any }) => {
    const res = await updateProfile(data);
    if (res) {
      refreshPage();
    }
  };

  return {
    myProfile,
    refreshPage,
    handleUpdateAvatar,
    handleUpdateProfile,
  };
};
