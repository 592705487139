//kinzazaは、データの中身をモニター単位で確認する管理用ツールである。
//虐待予防的支援業務モニターなら、虐待予防的支援業務のデータを見えるとか、そんな感じ。
//なんのデータを見たいかは、mongodbのクエリを叩くと見えるようになる。

import { newtOut, streamGate } from "src/medican/api";
import { api, url } from "../../api";
import { MONITOR_ASSET_TAGS, MonitorThumb } from "../models";
import { v4 as uuidv4 } from "uuid";

export const getMonitors = async (
  page: number,
  limit: number,
  orderby?: any,
  search?: any
): Promise<[MonitorThumb[], number]> => {
  const skip = limit * (page - 1);

  try {
    const [data, _c] = await newtOut({
      asset: MONITOR_ASSET_TAGS, //["kinzaza", "monitor"]
      order: { "contents.rawdata.name": -1 },
      primaryKey: "contents.rawdata.name",
      search,
      limit,
      skip,
      shape: {
        id: "any",
        _created: "any",
        _modified: "any",
        "contents.rawdata": "any",
      },
    });

    const [_d, count] = await newtOut(
      {
        asset: MONITOR_ASSET_TAGS, //["kinzaza", "monitor"]
        order: { _created: -1 },
        primaryKey: "contents.rawdata.uuid",
        shape: {
          id: "any",
        },
      },
      { count: true }
    );

    //TODO エラーハンドル

    return [data, count ?? 0];
  } catch (err) {
    console.error(err);
    return [[], 0];
  }
};

//モニター一覧を取得している。
//export const getData = async (page: number, limit: number) => {
/**
 *
 * @param page
 * @param limit
 * @param orderby
 * @deprecated
 */
export const getMonitors__DEPRECATED = async (
  page: number,
  limit: number,
  orderby?: any
) => {
  try {
    const res = await api.get(
      `/contractors/${sessionStorage.contractorNo}/topdata`,
      {
        params: {
          q: {
            select: ["*"],
            where: { "contents.tags": MONITOR_ASSET_TAGS },
            skip: (page - 1) * limit,
            orderby: orderby,
            limit: limit,
          },
          options: { count: true },
        },
      }
    );
    //TODO エラーハンドル

    return [res.data, res.headers["x-total-count"] ?? 0];
  } catch (err) {
    console.error(err);
    return [[], 0];
  }
};

//特定のモニターを検索するためのapi通信を書いている。

export const searchData__DEPRECATED = async (
  word: string,
  page: number,
  limit: number
) => {
  try {
    const res = await api.get(
      `/contractors/${sessionStorage.contractorNo}/topdata`,
      {
        params: {
          q: {
            select: ["*"],
            where: {
              $and: [
                { "contents.tags": MONITOR_ASSET_TAGS },
                { "contents.name": { $regex: word } },
              ],
            },
            skip: (page - 1) * limit,
            limit: limit,
          },
          options: { count: true },
        },
      }
    );

    return [res.data, res.headers["x-total-count"] ?? 0];
  } catch (err) {
    console.error(err);
    return [[], 0];
  }
};

//モニターを複製するために、同じ名前のデータを取得している。
//例えば、opteditor , opteditor --copy1のモニターがあって、
//opteditor --copy2を複製したいときに、まずはopteditor,opteditor --copy1のモニターを取得するイメージである。

export const getDuplicate = async (
  monitor: MonitorThumb | undefined,
  monitorName: string | undefined
) => {
  try {
    const newtQuery = {
      asset: monitor?.tags,
      search: {
        "contents.rawdata.name": {
          $regex: `${monitorName}(-Copy[0-9]$)*`,
        },
      },
      shape: {
        "contents.rawdata.name": "any",
      },
    };

    const [res, _] = await newtOut(newtQuery);
    return res;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const postMonitor = async (data: any) => {
  try {
    const res = await streamGate(MONITOR_ASSET_TAGS, data);
    return res?.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

//新しいモニターを作成するときの処理を書いている。
export const postMonitor__DEPRECATED = async (data: any) => {
  try {
    const res = await api({
      method: "POST",
      url: url.topData(
        sessionStorage.contractorNo ?? "",
        sessionStorage.userNoText ?? ""
      ),
      data: data,
    });
    return res.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const duplicateMonitor = async (monitor: MonitorThumb | undefined) => {
  const monitorName = monitor?.name.match(/.*-Copy[0-9]/g)
    ? monitor?.name.replace(/-Copy[0-9]/g, "")
    : monitor?.name;

  if (typeof monitor !== "undefined") {
    const getMonitors = await getDuplicate(monitor, monitorName);

    const Array: number[] = [];

    if (getMonitors.length === 0) {
      return undefined;
    }

    getMonitors.forEach((d: any) => {
      const name = d.contents.rawdata.name;
      if (name.match(/.*-Copy[0-9]/g)) {
        const number = Number(name.slice(-1));
        Array.push(number);
      } else {
        Array.push(0);
      }
    });

    const maxnumber = Math.max(...Array);
    const copynumber = maxnumber + 1;
    const duplicateMonitorName = `${monitorName}-Copy${copynumber}`;

    const sendData = {
      ...monitor,
      name: duplicateMonitorName,
      uuid: uuidv4(),
    };

    const res = await postMonitor(sendData);
    return res;
  }
};
