import favy from "allegro-api";
import axios from "axios";
import { configure } from "axios-hooks";
import { API_URL } from "src/utils/apiUrl";

const apiUrl = API_URL;

if (!apiUrl) {
  throw new Error("API_URL is undefined.");
}

export const api = axios.create({
  baseURL: `${apiUrl}`,
  headers: {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${sessionStorage.token}`,
    "User-Id": `${sessionStorage.userId}`,
  },
  timeout: 1200000,
  // timeout: 5000,
});

configure({ axios: api });

export const createNewtZeroAPI = (version: number = 1) =>
  favy.newtzero({
    baseUrl: `${apiUrl}`.replace("/api/1", `/api/${version}`),
    apikey: sessionStorage.token,
    userNoText: sessionStorage.userId,
  });

export const createnAuthAPI = () =>
  favy.nauth({
    baseUrl: `${apiUrl + "/nauth"}`,
    apikey: sessionStorage.token,
    userNoText: sessionStorage.userId,
  });

export const createnXtalAPI = () =>
  favy.nxtal({
    baseUrl: `${apiUrl}`,
    apikey: sessionStorage.token,
    userNoText: sessionStorage.userId,
  });
