/* eslint-disable react-hooks/exhaustive-deps */
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import {
  optXCountState,
  optXListPageState,
  optXListSelector,
  optXListState,
  optXSearchWordState,
  optxFetchCountOfARequest,
} from "../recoils";
import { useEffect, useState } from "react";

/**
 * デフォルト値設定
 */
export const MAX_SELECTABLE_OPTX = 1;

type Props = {
  selectable?: boolean;
  maxSelectable?: number;
  onChangeSelections?: (optXIds: string[]) => void;
};

export const useOptXList = (props: Props) => {
  const { onChangeSelections } = props;
  const maxSelectable = props.maxSelectable ?? MAX_SELECTABLE_OPTX;

  const optXListLoadable = useRecoilValueLoadable(optXListState);
  const [fetchedOptXInfo, storeOptXInfo] = useRecoilState(optXListState);
  const optXList = useRecoilValue(optXListSelector);
  const optXCountLoadable = useRecoilValueLoadable(optXCountState);
  const [optXSearchWord, setOptXSerachWord] =
    useRecoilState(optXSearchWordState);
  const [currentPage, setPage] = useRecoilState(optXListPageState);

  const [selectedOptXIds, setSelectedOptXIds] = useState<string[]>([]);

  const isLoading = optXListLoadable.state === "loading";
  const totalPage =
    optXCountLoadable.state === "hasValue" && optXCountLoadable.contents > 0
      ? Math.ceil(optXCountLoadable.contents / optxFetchCountOfARequest)
      : 1;

  useEffect(() => {
    // refresh();

    const [fetchedOptXList, count] = fetchedOptXInfo;
    const newOptXList = fetchedOptXList.map((fetchedOptX, idx) => {
      const optXCollection = optXList.find((optX) => {
        return optX.optXId === fetchedOptX.optXId;
      });
      return optXCollection ?? fetchedOptX;
    });
    storeOptXInfo([newOptXList, count]);
  }, [fetchedOptXInfo]);

  useEffect(() => {
    if (onChangeSelections) onChangeSelections(selectedOptXIds);
  }, [onChangeSelections, selectedOptXIds, optXCountLoadable]);

  // OptXのカードがクリックされた際の処理
  const handleCardClick = (optXId: string) => {
    const nextSelected = selectedOptXIds;
    if (!selectedOptXIds.includes(optXId)) nextSelected.push(optXId);
    while (selectedOptXIds.length > maxSelectable) {
      nextSelected.shift();
    }
    setSelectedOptXIds([...nextSelected]);
  };

  // OptX名でOptXを検索する
  const handleSearchOptXName = (name: string) => {
    // setIsCachedOptXList(false);
    setOptXSerachWord(name);
    // storeOptXList(fetchedOptXList);
  };

  // ページング処理
  const handleNavigatePage = (newPage: number) => {
    // setIsCachedOptXList(false);
    setPage(newPage);
    // storeOptXList(fetchedOptXList);
  };

  return {
    optXList,
    totalPage,
    optXSearchWord,
    selectedOptXIds,
    isLoading,
    currentPage,

    handleCardClick,
    handleSearchOptXName,
    handleNavigatePage,
  };
};
