//Libs
import React from "react";
import styled from "styled-components";

import { CHHeaderNav } from "src/cheetah/components/organisms/CHHeaderNav";
import { NAGlobalNavConf } from "src/nai-console/config/globalNavConf";

//Types

export const headerOffset = 56;

const Title = styled.h1`
  /* margin-left: 8px; */
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.7;
  }
  font-size: 18px;
  > img {
    margin-top: -3px;
    width: 110px;
  }

  font-weight: 400;
  letter-spacing: 1px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
`;

const Skelton = styled.div`
  clear: both;
  position: relative;
  /* width:100%: */
  /* height: ${headerOffset}px; */
`;

type Props = {};
export const NAGlobalNav: React.FC<Props> = (props) => {
  return (
    <>
      <CHHeaderNav logo={<Title>nAI Console</Title>} conf={NAGlobalNavConf} />
      <Skelton></Skelton>
    </>
  );
};
