import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";

import Menu from "@material-ui/core/Menu";
import { OEFileRenameMenu } from "../atoms/OEFileRenameMenu";
import { OECreateFileRightClickMenu, OEDeleteRightClickMenu } from "../atoms";
import scienceFolder from "../../recoils/ScienceFolderState";
import { useNavigate } from "react-router-dom";
import { OEChangeItemPermissionMenu } from "../atoms/OEChangeItemPermissionMenu";
import { OEFileDownloadMenu } from "../atoms";

import { List } from "@material-ui/core";
import scienceFile from "../../recoils/ScienceFileState";

import {
  isScienceFile,
  ScienceFile,
  ScienceItem,
} from "src/opt-editor/models/ScienceFSItem";
import { OEFileListItem } from "../atoms/OEFileListItem";

import { OEFileDropArea } from "../organisms";
import FTSearchField from "src/fortress/components/atoms/FTSeachField";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: column;
  width: 100%;
  height: 100%;
  min-height: 300px;
  white-space: pre-wrap;
  color: ${(props) => props.theme.primaryColor};
`;

const TopArea = styled.div`
  padding: 16px;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};

  > * {
    margin-bottom: 12px;
  }

  > *:last-child {
    margin-bottom: 0px;
  }
`;

const Title = styled.div`
  color: ${(props) => props.theme.textColor};
`;
const SupportText = styled.div`
  color: ${(props) => props.theme.descriptionTextColor};
  font-size: ${(props) => props.theme.supportFontSizeLg}px;
`;

const MiddleArea = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  flex: 1;
  width: 100%;
  height: 100%;

  > *:last-child {
    margin-top: auto;
  }
`;

const NotFound = styled.div`
  padding: 16px;
`;

const FileDropArea = styled.div`
  display: inline-block;
  width: 100%;
  height: 200px;
`;

type URLParams = {
  fileId: string | undefined;
};

export const OEFileListPerformanced: React.FC = () => {
  const [currentFolderId, setCurrentFolderId] = useRecoilState(
    scienceFolder.cursorItemIdState
  );
  const [fileList, setFileList] = useState<ScienceFile[]>([]);
  const requestId = useRecoilValue(scienceFile.requestIdState);
  const items = useRecoilValue(scienceFile.itemList);
  const { getItems } = scienceFile.useItemList();
  const setFilter = useSetRecoilState(scienceFile.filterState);

  const folder = useRecoilState(scienceFolder.item(currentFolderId));
  const [cursorFileId, setCursorFileId] = useRecoilState<number | string>(
    scienceFile.cursorItemIdState
  );

  const [showContextMenu, setShowContextMenu] = useState(false);
  const [isSearchMode, setIsSearchMode] = useState(false);

  const [shouldOpenFileContext, setShouldOpenFileContext] = useState(false);

  const history = useNavigate();

  const fetchItems = useCallback(async () => {
    const files = await getItems();

    setFileList(files);

    return files;
  }, [getItems]);

  useEffect(() => {
    if (isSearchMode === false) {
      setFilter({ "contents.rawdata.parent": currentFolderId });
      fetchItems();
    } else {
      setIsSearchMode(false);
    }
    // setFileList(items);
  }, [items, setFilter, fetchItems, currentFolderId, isSearchMode]);

  // 右クリックフォルダ選択
  // const handleContextMenu = (event: React.MouseEvent) => {
  //   // 初期のメニュー非表示
  //   event.preventDefault();
  //   setMouseXY({
  //     x: event.clientX - 2,
  //     y: event.clientY - 4,
  //   });
  // };

  const [mouseXY, setMouseXY] = React.useState({
    x: 0,
    y: 0,
  });

  // 右クリックメニューを閉じる
  const handleClose = () => {
    setMouseXY({
      x: 0,
      y: 0,
    });
    setShowContextMenu(false);
  };

  // 右クリックファイル選択
  const handleFileContextMenu = useCallback(
    (event: React.MouseEvent, fileId: number | null) => {
      if (fileId) {
        setCursorFileId(fileId);
        history(`/knowledge/${fileId}`);

        setShouldOpenFileContext(true);
      } else {
        setShouldOpenFileContext(false);
      }
      // handleSelect(id);
      event.stopPropagation();
      // 初期のメニュー非表示
      event.preventDefault();
      setMouseXY({
        x: event.clientX - 2,
        y: event.clientY - 4,
      });
      setShowContextMenu(true);
    },
    [setCursorFileId, history]
  );

  // const handleFileRightClick = (
  //   event: React.MouseEvent<HTMLElement, MouseEvent>
  // ) => {
  //   event.preventDefault();
  // };

  const handleSelect = useCallback(
    (id: number | string) => {
      const fileId = id;
      setCursorFileId(fileId);

      history(`/knowledge/${fileId}`);

      return;
    },
    [setCursorFileId, history]
  );

  const handleSearchSubmit = (text: string) => {
    if (text.length > 0) {
      setIsSearchMode(true);
      setFilter({
        "contents.rawdata.name": { $regex: `${text}`, $options: "i" },
      });
      fetchItems();
    }
  };
  const renderFileList = useCallback(() => {
    return fileList.length > 0 ? (
      fileList
        .sort((a: any, b: any) => {
          var nameA = a.name.toUpperCase(); // 大文字と小文字を無視する
          var nameB = b.name.toUpperCase(); // 大文字と小文字を無視する
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        })
        .map((file: ScienceFile, id) => {
          return (
            <OEFileListItem
              fileId={file.itemId}
              activeFileId={cursorFileId}
              onClick={handleSelect}
              onRightClick={handleFileContextMenu}
              key={`oe-file-list-item-${id}`}
              fileObj={fileList[id]}
            />
          );
        })
    ) : (
      <NotFound>File not found.</NotFound>
    );
  }, [fileList, cursorFileId, handleSelect, handleFileContextMenu]);

  return (
    <>
      <Container>
        {/* TODO: folder info component(atoms) */}
        {/* TODO: style => use allegro-ui component */}
        <React.Suspense fallback={<>...</>}>
          <TopArea>
            <Title>{folder[0]?.name}</Title>
            <FTSearchField
              onChange={() => {}}
              onSubmit={(text: string) => {
                handleSearchSubmit(text);
              }}
            ></FTSearchField>
            <SupportText>
              <p>{`${fileList?.length ?? 0}個のドキュメント`}</p>
              <p>以下のすべて/</p>
            </SupportText>
          </TopArea>
        </React.Suspense>

        <MiddleArea onContextMenu={(e) => handleFileContextMenu(e, null)}>
          <List>
            <React.Suspense fallback={<>...</>}>
              {renderFileList()}
            </React.Suspense>
          </List>
          <FileDropArea onContextMenu={(e) => handleFileContextMenu(e, null)}>
            <OEFileDropArea />
          </FileDropArea>
        </MiddleArea>
      </Container>

      <React.Suspense fallback={<>...</>}>
        <FileViewContextMenu
          itemId={cursorFileId}
          onClose={handleClose}
          show={showContextMenu}
          mouseX={mouseXY.x}
          mouseY={mouseXY.y}
          shouldOpenFileContext={shouldOpenFileContext}
        />
      </React.Suspense>
    </>
  );
};

type ContextMenuProps = {
  itemId: number | string | null;
  item?: ScienceItem;
  onClose: any;
  show: boolean;
  mouseX: number;
  mouseY: number;
  shouldOpenFileContext: boolean;
};

/**
 * コンテキストメニュー
 * 右クリック時に表示する
 */
export const FileViewContextMenu: React.FC<ContextMenuProps> = ({
  itemId,
  onClose,
  show,
  mouseX,
  mouseY,

  shouldOpenFileContext,
}) => {
  const [cursorFile, setCursorItem] = useRecoilState(
    scienceFile.item(itemId ?? 0)
  );
  const setRequestId = useSetRecoilState(scienceFile.requestIdState);

  //showChildのためにつくっているがなんでイルカわからない。
  const [mouseXY, setMouseXY] = useState<{
    x: number | null;
    y: number | null;
  }>({ x: null, y: null });

  // console.log({ itemId });

  return shouldOpenFileContext && cursorFile ? (
    <Menu
      keepMounted
      open={show}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={
        mouseY !== null && mouseX !== null
          ? {
              top: mouseY,
              left: mouseX,
            }
          : undefined
      }
    >
      <OEFileRenameMenu onMenuClose={onClose} />

      <OEChangeItemPermissionMenu
        showChild={setMouseXY}
        onMenuClose={onClose}
        item={cursorFile}
        onChangePermission={(newItem) => {
          if (isScienceFile(newItem)) {
            setCursorItem(newItem);
            setRequestId((id) => id++);
            alert("権限設定を変更しました。リロードしてください。");
            // refreshPage();
          }
        }}
      />
      <OEFileDownloadMenu onMenuClose={onClose} />
      <OEDeleteRightClickMenu
        showChild={setMouseXY}
        isDeleteFile
        onMenuClose={onClose}
      />
    </Menu>
  ) : (
    <Menu
      keepMounted
      open={show}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={
        mouseY !== null && mouseX !== null
          ? { top: mouseY, left: mouseX }
          : undefined
      }
    >
      <OECreateFileRightClickMenu onMenuClose={onClose} />
    </Menu>
  );
};
