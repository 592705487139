import React from "react";
import styled from "styled-components";
import { CheckMini } from ".";

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

type Props = {
  data: any;
};

export const CheckBlock: React.FC<Props> = (props) => {
  const { data } = props;

  return (
    <>
      {Object.keys(data).map((d, id) => {
        return (
          <div key={String(id)}>
            <Flex>
              <CheckMini value={data[d]}></CheckMini>
              <div>{d}</div>
            </Flex>
          </div>
        );
      })}
    </>
  );
};
