import { shapeToSeireki } from "./time";

export const translateLabel = (label: string) => {
  if (label === "子育て支援") {
    return "保育園等";
  } else if (label === "幼児教育無償化") {
    return "幼稚園等";
  }
  return label;
};

export const parsePersonalDataVelue = (value: any, key?: any) => {
  let result = value;

  if (value === 0) {
    result = "無回答";
  } else if (
    (typeof value === "string" || typeof value === "number") &&
    key !== "世帯番号" &&
    key !== "個人番号" &&
    key !== "宛名番号"
  ) {
    let str = String(value);

    const parsed = new RegExp(/[0-9]{8}/).test(str)
      ? shapeToSeireki(Number(str))
      : value;

    result = parsed ?? "---";
  } else {
    result = value ?? "---";
  }

  return result;
};
