import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import scienceFile from "src/opt-editor/recoils/ScienceFileState";
import { useRecoilValue } from "recoil";
import { ScienceFile } from "src/opt-editor/models/ScienceFSItem";
import { saveAs } from "file-saver";

type Props = {
  onMenuClose: () => void;
};

export const OEFileDownloadMenu: React.FC<Props> = (props) => {
  const { onMenuClose } = props;
  const cursorFileId = useRecoilValue(scienceFile.cursorItemIdState);
  const file = useRecoilValue(scienceFile.item(cursorFileId));

  const base64ToArray = (base64: string) => {
    // base64のデコード
    var bin = atob(base64.replace(/^.*,/, ""));
    // バイナリデータ化
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
    }
    // ファイルオブジェクト生成(この例ではjpegファイル)
    return buffer;
  };

  const createFile = (file: ScienceFile): File => {
    const extension = file.name.split(".").at(-1) ?? "";
    if (["txt", "py", "ts", "tsx", "js", "jsx"].includes(extension)) {
      const byteArray = new TextEncoder().encode(file.contents);
      return new File([byteArray.buffer], file.name);
    } else if (["md"].includes(extension)) {
      return new File([JSON.stringify(file.contents ?? {})], file.name);
    } else {
      return new File([base64ToArray(file.contents ?? "")], file.name);
    }
  };

  const downloadFile = () => {
    // 親コンポーネントのmouseX,mouseYにnullセット
    const downloadingFile = createFile(file);
    if (downloadingFile) {
      saveAs(downloadingFile);
    }
    onMenuClose();
  };

  return (
    <>
      <MenuItem onClick={downloadFile}>Download</MenuItem>
    </>
  );
};
