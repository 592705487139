import React from "react";
import styled from "styled-components";
import { ASCard } from "allegro-ui";
import { CardContent } from "@material-ui/core";

type Props = {
  title: string;
  inputs?: React.ReactNode;
};

const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
  line-height: 2.4;
  margin-left: 10px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
`;

//ASCardとタイトルを組み合わせた汎用的コンポーネント。４つ角が丸い白い四角形がたくさんあると思うが、あれの素材となるコンポーネントである。

export const FTDashBoardWidget: React.FC<Props> = (props) => {
  const { children, title } = props;
  return (
    <>
      <Title>{title}</Title>
      <ASCard corner={24}>
        <CardContent>{children}</CardContent>
      </ASCard>
    </>
  );
};
