import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { createNewtZeroAPI } from "src/api";
import { produce } from "immer";
import { useAuth } from "src/root";
import {
  currentMonitor,
  cursorDailyThumbState,
  dailyThumbListSortState,
  makeTimeQuery,
} from "src/kinzaza/recoils";
import { JsonViewState } from "../recoils/jsonViewState";
import { DailyThumb, MonitorThumb } from "../models";
import { NewtQuery } from "allegro-api";

const parseMonitorQuery = (monitorThumb: MonitorThumb) => {
  try {
    return typeof monitorThumb.query === "string"
      ? JSON.parse(monitorThumb.query)
      : monitorThumb.query;
  } catch (err) {
    console.error(err);
  }
  return {};
};

export const useJsonView = () => {
  const auth = useAuth();
  console.log("USE JSON VIEW");
  const [jsonView, setJsonView] = useRecoilState(JsonViewState);
  const [isInitialized, setIsInitialized] = useState(false);

  const cursorMonitor = useRecoilValue(currentMonitor);
  const cursorDailyThumb = useRecoilValue(cursorDailyThumbState);
  const sortFilter = useRecoilValue(dailyThumbListSortState);

  const newtzero = createNewtZeroAPI();

  const monitorBaseQuery: NewtQuery = parseMonitorQuery(cursorMonitor);

  const update = useCallback(
    async ({
      unixTime = jsonView.date,
      offset = jsonView.offset,
      limit = jsonView.limit,
    }) => {
      console.log("UPDATE");
      const timeQuery = makeTimeQuery(
        cursorDailyThumb.startDate,
        cursorDailyThumb.endDate,
        sortFilter
      );

      const searchQuery = {
        $and: [monitorBaseQuery.search ?? {}, timeQuery],
      };

      const monitorQuery = {
        ...monitorBaseQuery,
        search: searchQuery,
      };

      try {
        const res = await newtzero.dataProvider({
          ...monitorQuery,
          limit: limit,
          skip: offset,
        });

        const nextJsonView = produce(jsonView, (draft) => {
          draft.json = res;
          draft.date = unixTime;
          draft.offset = offset;
          draft.limit = limit;
          draft.count = cursorDailyThumb?.dataCount ?? 0;
        });
        setJsonView(nextJsonView);
      } catch (err) {
        console.error(err);
      }
    },
    [cursorDailyThumb, jsonView, monitorBaseQuery, newtzero, setJsonView]
  );

  useEffect(() => {
    try {
      update({});
    } catch (err) {
      console.error(err);
    }
  }, [cursorDailyThumb, isInitialized]);

  const setBaseTime = useCallback(
    (unixTime: number) => {
      update({ unixTime });
    },
    [update]
  );

  const setLimit = useCallback(
    (limit: number) => {
      console.log(limit);
      update({ limit });
    },
    [update]
  );

  const setOffset = useCallback(
    (offset: number) => {
      update({ offset });
    },
    [update]
  );

  return { jsonView, update, setLimit, setOffset, setBaseTime };
};
