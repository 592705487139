import * as React from "react";
import styled from "styled-components";

import { Tooltip } from "@material-ui/core";

import { ASChip } from "allegro-ui";

const StyledChip = styled(ASChip)`
  margin: 2px;
  /* display: -webkit-box; */
  /* -webkit-box-orient: vertical; */
  /* -webkit-line-clamp: 1; */
  font-size: 12px;
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  max-width: 160px;
`;

interface ILCBudgeCollectionComponentProps {
  data: { values?: string[] | string };
  options?: { color?: "disabled" | "negative" | "caution" | "positive" };
}

const LCBudgeCollectionComponent: React.FunctionComponent<ILCBudgeCollectionComponentProps> =
  (props) => {
    const values = props.data.values
      ? Array.isArray(props.data.values)
        ? props.data.values
        : [props.data.values]
      : [];

    // const period = useRecoilValue(filterPeriodState);

    return (
      <>
        {values.map((label, idx) => {
          return (
            <Tooltip
              key={`lc-budge-collection-tooltip-${idx}-${label}`}
              title={label}
            >
              <StyledChip
                key={`lc-budge-collection-${idx}-${label}`}
                design="onecolor"
                label={label}
                kind={props.options?.color ?? "negative"}
                onDelete={undefined}
                small="true"
              ></StyledChip>
            </Tooltip>
          );
        })}
      </>
    );
  };

export default LCBudgeCollectionComponent;
