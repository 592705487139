import apiConf from "../apiConf.json";
import favy from "allegro-api";
import { ScienceFile } from "../models/ScienceFSItem";

/**
 * @deprecated
 */
export const useFileActions = () => {
  // const [_cursorFileId, _setCursorFileId] = useRecoilState(cursorFileIdState);
  // const currentFolderId = useRecoilValue(scienceFolder.cursorItemIdState);
  const td = favy.useTopData<ScienceFile>(
    apiConf.topdata.file.tags,
    apiConf.topdata.file.primaryKey
  );
  // const [fileIdList, setFileIdList] = useRecoilState(fileIdListState);
  // const add = async (fileId: number, name: string) => {
  //   //①データをポスト
  //   const file = createFile({
  //     fileId,
  //     parent: Number(currentFolderId),
  //     name,
  //   });
  //   // ****** debug ******
  //   const success = await td.post(file);
  //   if (success) {
  //     return success;
  //   } else {
  //     return false;
  //   }
  //   // ****** debug *******
  //   // td.getOne();
  //   //②データをフェッチ
  //   //③レスポンスからモデル作成
  //   //④atomを保存
  //   //todo
  // };
  // const update = async (file: File) => {
  //   const fileObj = updateFile({ ...file });
  //   console.log("file obj", fileObj);
  //   console.log(fileObj);
  //   return await td.update(fileObj, null, fileObj.readers, fileObj.writers);
  // };
  const remove = async (fileId: number | string) => {
    //①リクエストをポスト

    const success = await td.remove(fileId);
    if (success) {
      return success;
    } else {
      console.error("failed delete file", fileId);
    }

    //②レスポンスが正常かチェック
    //③atomを削除
    //todo
  };
  // const rename = (fileId: number, nextName: string) => {
  //   //①リクエストをポスト
  //   // td.update();
  //   //②データをフェッチ
  //   // td.getOne();
  //   //③レスポンスからモデル作成
  //   //④atomを更新
  //   //todo
  // };
  // const select = (fileId: number) => {
  //   _setCursorFileId(fileId);
  // };
  // const updateFileState = useRecoilCallback(({ set }) => {
  //   return (file: File) => {
  //     set(fileState(Number(file.fileId)), file);
  //   };
  // }, []);
  // const getList = async () => {
  //   const resData = await td.getMany(100, 0);
  //   if (Array.isArray(resData)) {
  //     const nextFileIds = resData.map((topdata: any) => {
  //       const file = topdata?.contents?.rawdata;
  //       const f = createFileFromRes(topdata);
  //       console.log(file);
  //       console.log("f", f);
  //       console.error("ajjjjjjjjjjjjjj");
  //       updateFileState(file);
  //       return Number(file.fileId);
  //     });
  //     setFileIdList(nextFileIds);
  //   }
  // };
  // return { add, update, remove, rename, select, getList };
  return { remove };
};
