import axios, { AxiosRequestConfig } from "axios";
import { findValueByMongoKey } from "../utils/findData";

export type References =
  | string[]
  | ReferenceData[]
  | ((name?: string) => Promise<ReferenceData[]>)
  | ((name?: string) => ReferenceData[])
  | APIReference;

export type ReferenceData = {
  label: string;
  value: string;
};

export type APIReference = {
  api?: AxiosRequestConfig;
  newtQ?: any;
  data: ReferenceData;
  refKey?: string;
};

const apiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_BASE_URL
    : window.location.protocol +
      "//" +
      window.location.host +
      process.env.REACT_APP_API_BASE_URL;

const newtAPIReference: APIReference = {
  api: {
    method: "POST",
    url: "https://newtroid.io/api/1/newtout/aggr",
    params: {
      apikey: sessionStorage.token,
    },
    data: {
      asset: [],
      primaryKey: "id",
      shape: {
        id: "any",
      },
    },
  },
  refKey: "message.doc",
  data: {
    label: "contents.rawdata.label",
    value: "contents.rawdata.value",
  },
};

export const createReference = (values: any): References | undefined => {
  if (typeof values === "string") {
    try {
      return JSON.parse(values);
    } catch (err) {
      // console.error(err);
      return values.replace(/[,|、]([ |　]+)/g, ",").split(",");
    }
  }
  return values;
};

export const getReferenceValue = async (
  reference: References,
  text?: string
): Promise<ReferenceData[]> => {
  let newOptions: ReferenceData[] = [];
  if (typeof reference === "function") {
    // function
    newOptions = await reference(text);
  } else if (Array.isArray(reference)) {
    // string[] or referenceData[]
    newOptions = getReferenceStatic(reference);
  } else if (reference?.newtQ) {
    newOptions = await getReferenceByNewtQuery(reference);
    newOptions = extractLabelValue(newOptions, reference);
  } else if (reference?.api) {
    newOptions = await getReferenceByAPI(reference);
    newOptions = extractLabelValue(newOptions, reference);
  }
  return newOptions;
};

export const getReferenceStatic = (reference: any[]): ReferenceData[] => {
  return reference.map((refData) => {
    if (typeof refData === "string") {
      return {
        label: refData,
        value: refData,
      };
    } else {
      return refData;
    }
  });
};

export const getReferenceByAPI = async (
  reference: any
): Promise<ReferenceData[]> => {
  // APIReference
  const res = await axios.request(reference.api);
  const arrayData = reference.refKey
    ? findValueByMongoKey(res.data, reference.refKey)
    : res.data;
  return arrayData ?? [];
};

const getReferenceByNewtQuery = async (
  reference: any
): Promise<ReferenceData[]> => {
  const url = `${apiUrl}/newtout/aggr`;
  try {
    const res = await axios.post(url, reference.newtQ, {
      params: { apikey: sessionStorage.token, newtQuery: false },
    });

    return res.data.message.doc ?? [];
  } catch (err) {
    return [];
  }
};

const extractLabelValue = (
  arrayData: any[],
  reference: APIReference
): ReferenceData[] => {
  if (Array.isArray(arrayData)) {
    return arrayData.map((data: any) => {
      if (typeof data === "string") {
        return {
          label: data,
          value: data,
        };
      }
      const labelObj = findValueByMongoKey(data, reference.data.label);
      const label = typeof labelObj === "string" ? labelObj : "";
      const value = reference.data.value
        ? findValueByMongoKey(data, reference.data.value)
        : undefined;
      return {
        label: label,
        value: typeof value === "string" ? value : label,
      };
    });
  }
  return [];
};
