import { useEffect, useState } from "react";
import * as React from "react";
import styled from "styled-components";
import { ASChip } from "allegro-ui";
import { darken, lighten } from "polished";

export const DisabledChip = styled(ASChip)`
  color: #909090;
  background-color: ${(props) =>
    props.theme.darkMode ? darken(0.5, "#555") : lighten(0.52, "#555")};
`;

const FlexBox = styled.div`
  display: flex;
  > * {
    margin-top: 8px;
  }
`;

interface ILCApprovalChipCellComponentProps {
  data: {
    value?: boolean;
  };
}

const colors: { [key: string]: any } = {
  問題なし: "positive",
  気になる: "caution",
  虐待あり: "negative",
};

const getColor = (num: number) => {
  if (num > 80) return colors["問題なし"];
  if (num > 0) return colors["気になる"];
  else return colors["虐待あり"];
};

type ChipType = "disabled" | "negative" | "caution" | "positive";
const chipInfo: { [key: string]: { kind: ChipType; label: string } } = {
  positive: {
    kind: "positive",
    label: "承認",
  },
  negative: {
    kind: "negative",
    label: "否認",
  },
  nonapproval: {
    kind: "disabled",
    label: "未承認",
  },
};

const getChipInfo = (
  approval?: boolean
):
  | {
      kind: ChipType;
      label: string;
    }
  | undefined => {
  if (approval === true) {
    return chipInfo.positive;
  } else if (approval === false) {
    return chipInfo.negative;
  } else {
    // return chipInfo.nonapproval;
    return undefined;
  }
};

const LCApprovalChipCellComponent: React.FC<ILCApprovalChipCellComponentProps> =
  (props) => {
    const value = getChipInfo(props.data.value);

    return (
      <>
        <FlexBox>
          {value ? (
            <ASChip
              design="onecolor"
              kind={value.kind}
              label={value.label}
              small
            ></ASChip>
          ) : (
            <>-</>
          )}
        </FlexBox>

        {/* <Title>日付</Title>
      <span></span> */}
      </>
    );
  };

export default LCApprovalChipCellComponent;
