import React, { useState } from "react";
import { useRecoilValue } from "recoil";

import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";

import scienceFolder from "../../recoils/ScienceFolderState";
import * as scienceFolderActions from "src/opt-editor/models/ScienceFSItem";
import { ASInputBase, ASModal } from "allegro-ui";
import produce from "immer";
import { ConfirmModalContents } from "src/kinzaza/components";
import { ASErrorField, ASFormLabel } from "allegro-low-code-components";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 100%;
`;

const Header = styled("div")`
  padding: 5px;
  width: 100%;
`;

const Body = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 100%;
  height: 150px;
  justify-content: center;
  align-items: center;
`;

const Title = styled("h2")`
  padding: 8px;
  font-size: 24px;
`;

const ConfigText = styled("div")`
  padding: 8px;
  color: ${(props) => props.theme.primaryColor};
`;

type Props = {
  showChild: any;
  item: scienceFolderActions.ScienceItem;
  onChangeName: (newItem: scienceFolderActions.ScienceItem) => void;
  onMenuClose: () => void;
};

export const OERenameFolderMenu: React.FC<Props> = ({
  item,
  onMenuClose,
  onChangeName,
  showChild,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [nameText, setNameText] = useState("");
  const [nameError, setNameError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const folders = useRecoilValue(scienceFolder.itemList);
  const brothers = folders.filter((folder) => folder.parent === item.parent);

  const handleShowMenu = () => {
    setShowModal(true);
    showChild({ mouseX: null, mouseY: null });
    setNameText("");
    setNameError(false);
    setErrorMessage("");
    onMenuClose();
  };

  const handleTextChange = (e: any) => {
    const text = e.target.value;
    setNameText(text);

    if (text === "") {
      setNameError(true);
      setErrorMessage("名前を入力してください");
    } else if (text === item.name) {
      setNameError(true);
      setErrorMessage("名前を変更してください");
    } else if (brothers.find((bro) => bro.name === text)) {
      setNameError(true);
      setErrorMessage("その名前は既に存在しています");
    } else {
      setNameError(false);
      setErrorMessage("");
    }
  };

  const handleCancel = () => {
    setNameText("");
    setNameError(false);
    setErrorMessage("");
    setShowModal(false);
  };

  const handleSave = () => {
    const newItem = renameFolderItem(nameText);
    if (newItem !== undefined) {
      onChangeName(newItem);
    }
    setNameText("");
    setNameError(false);
    setErrorMessage("");
    setShowModal(false);
  };

  const renameFolderItem = (newName: string) => {
    if (nameError === false) {
      return produce(item, (draft) => {
        draft.name = newName;
      });
    }
  };

  return (
    <>
      <MenuItem onClick={handleShowMenu}>名前の変更</MenuItem>
      <ASModal
        title="名前の変更"
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <ConfirmModalContents
          cancelLabel="Cancel"
          submitLabel="Rename"
          submitDisabled={nameError || nameText === ""}
          onCancel={handleCancel}
          onSubmit={() => handleSave()}
        >
          <Container>
            <Header>
              <ConfigText> Configuration: {item.name}</ConfigText>
            </Header>
            <Body>
              <ASFormLabel>フォルダ名</ASFormLabel>
              <ASInputBase
                kind="digicho"
                fullWidth
                onChange={handleTextChange}
                error={nameError}
              />
              <ASErrorField>{errorMessage}</ASErrorField>
            </Body>
          </Container>
        </ConfirmModalContents>
      </ASModal>
    </>
  );
};
