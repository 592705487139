import { createnAuthAPI } from "src/api";

export const fetchGroupMemberByGroupId = async (
  contractorNo: string,
  groupId: string,
  options?: { searchQ?: { [key: string]: any }; limit?: number; skip?: number }
) => {
  const newtQuery = {
    limit: options?.limit ?? 500,
    skip: options?.skip ?? 0,
    shape: {
      userNoText: "any",
      "profile.userRealName": "any",
      userNo: "any",
      email: "any",
    },
    search: {
      contractorNo: contractorNo,
      groups: groupId,
      ...options?.searchQ,
    },
  };

  const nauth = createnAuthAPI();
  return await nauth.getUsersByDataProvider(newtQuery);
};
