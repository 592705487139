import { atom, atomFamily, selector } from "recoil";
import { File, defaultFileData } from "../models";
import scienceFolder from "./ScienceFolderState";

export const cursorFileIdState = atom<number>({
  key: "cursorFileState",
  default: 0,
});

export const cursorSplitFileIdState = atom<number>({
  key: "cursorSplitFileIdState",
  default: 0,
});

export const nextFileIdState = selector<number>({
  key: "nextFileIdState",
  get: ({ get }) => {
    const ids = get(fileIdListState);
    const highestId = ids?.length > 0 ? Math.max(...ids) : -1;
    return highestId + 1;
  },
});

export const fileIdListState = atom<number[]>({
  key: "fileIdListState",
  default: [],
});

export const fileState = atomFamily<File, number>({
  key: "fileState",
  default: (fileId) => {
    return defaultFileData;
  },
});

export const fileListState = selector<File[]>({
  key: "fileListState",
  get: ({ get }) => {
    const ids = get(fileIdListState);
    return ids.map((id) => {
      const file = get(fileState(id));
      return file;
    });
  },
});

export const filterdFileListState = selector<File[]>({
  key: "filterdFileListState",
  get: ({ get }) => {
    const fileList: File[] = get(fileListState);
    const cursorFolderId = get(scienceFolder.cursorItemIdState);
    const result: File[] = fileList.filter((file: File) => {
      return file.parent === cursorFolderId;
    });
    return result;
  },
});
