import styled, { DefaultTheme } from "styled-components";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@material-ui/core";
import { darken } from "polished";

export type ASTableRowProps = {
  theme: DefaultTheme;
  kind?: "primary" | "secondary" | "tertiary";
};

export const ASTableRow = styled(TableRow).attrs<ASTableRowProps>(
  (props) => {}
)<ASTableRowProps>`
  color: ${(props) => props.theme.primaryOnText};
  background-color: ${(props) => props.theme.cardColor};
  /* &:nth-of-type(even) {
    background: ${(props) => props.theme.primaryColor};
    &:hover {
      background: ${(props) => darken(0.03, props.theme.primaryColor)};
    }
  } */

  /* &:nth-of-type(odd) {
    background: ${(props) => props.theme.secondaryColor};
    &:hover {
      background: ${(props) => darken(0.03, props.theme.secondaryColor)};
    }
  } */
  &:last-child {
  }
`;

export type ASTableProps = {
  theme: DefaultTheme;
  borderRadius?: number;
  kind?: "primary" | "secondary" | "tertiary";
};

type Attrs = {
  corner: number;
  bgColor: string;
};

type StyledProps = ASTableProps & Attrs;

//@todo ソート

export const ASTable = styled.div.attrs<ASTableProps>((props) => {
  const corner = props.borderRadius
    ? props.borderRadius
    : props.theme.borderRadius;

  return { corner };
})<ASTableProps & Attrs>`
  table {
    border-spacing: 0 22px;
    border-collapse: separate;
  }

  tbody {
    tr {
    }
  }

  tr {
    border-radius: ${(props) => props.theme.borderRadius}px;
    &:hover {
      /* box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1); */
      transition: 0.2s;
    }
  }

  th {
    color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
    font-size: ${(props: ASTableProps) => props.theme?.regularFontSizeMd}px;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
  }

  td {
    color: ${(props) => (props.theme.darkMode ? "#FFF" : "#000")};
    font-size: ${(props: ASTableProps) => props.theme?.regularFontSizeMd}px;
    border: none;
    padding: 16px;
    &:first-child {
      border-radius: ${(props) => props.corner}px 0 0
        ${(props) => props.corner}px;
      padding-left: 23px;
    }
    &:last-child {
      border-radius: 0 ${(props) => props.corner}px ${(props) => props.corner}px
        0;
      padding-right: 23px;
    }
  }
`;
