import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import { createNewtZeroAPI } from "src/api";

const defaultAssetNewtQuery = {
  collection: "Asset",
  limit: 1,
  skip: 0,
  shape: {
    keywords: "any",
    className: "any",
    value: "any",
    name: "any",
    userNoText: "any",
    contractorNo: "any",
    tags: "any",
    sharings: "any",
    assetNo: "any",
    id: "any",
    uniqueKeys: "any",
    rawQuePartitions: "any",
    txnQuePartitions: "any",
    topQuePartitions: "any",
    timeFieldMetric: "any",
    collectionName: "any",
    shareLevel: "any",
    readablePublicAssetData: "any",
    // readers: "any",
    // writers: "any",
    _id: "any",
    assetType: "any",
    cmsConfig: "any",
    allowReadUsers: "any",
    allowReadGroups: "any",
    allowWriteUsers: "any",
    allowWriteGroups: "any",

    providerAPIConfig: "any",
    streamGateAPIConfig: "any",
    deleteDataAPIConfig: "any",
  },
  search: {},
};

export const fetchAssetOne = async (assetId: string) => {
  const newtzero = createNewtZeroAPI();
  const newtQuery = produce(defaultAssetNewtQuery, (draft) => {
    draft.search = {
      id: assetId,
    };
  });

  try {
    const resData = await newtzero.dataProvider(newtQuery, {
      filterByContractor: false,
    });
    return resData[0];
  } catch (err) {
    console.error(err);
  }
};

const formatAssetFormData = (assetRes: any) => {
  return produce(assetRes, (draft: any) => {
    const pc = assetRes.providerAPIConfig;
    draft["provider-enable"] = pc.enable ? "Enable" : "Disable";
    draft["provider-freeAccess"] = pc.freeAccess ? "Enable" : "Disable";
    draft["provider-enableAccessToken"] = pc.enableAccessToken
      ? "Enable"
      : "Disable";
    draft["provider-accessToken"] = pc.accessToken;
    draft["provider-newtQueryModifier"] = pc.newtQueryModifier;
    draft["provider-allowUsers"] = pc.allowUsers;
    draft["provider-allowGroups"] = pc.allowGroups;
    draft["provider-disableAssetIdProtection"] = pc.disableAssetIdProtection
      ? "Enable"
      : "Disable";

    const sg = assetRes.streamGateAPIConfig;
    draft["streamgate-enable"] = sg.enable ? "Enable" : "Disable";
    draft["streamgate-freeAccess"] = sg.freeAccess ? "Enable" : "Disable";
    draft["streamgate-enableAccessToken"] = sg.enableAccessToken
      ? "Enable"
      : "Disable";
    draft["streamgate-accessToken"] = sg.accessToken;
    draft["streamgate-allowUsers"] = sg.allowUsers;
    draft["streamgate-allowGroups"] = sg.allowGroups;
    draft["streamgate-disableAssetIdProtection"] = sg.disableAssetIdProtection
      ? "Enable"
      : "Disable";

    const dd = assetRes.deleteDataAPIConfig;
    draft["deletedata-enable"] = dd.enable ? "Enable" : "Disable";
    draft["deletedata-freeAccess"] = dd.freeAccess ? "Enable" : "Disable";
    draft["deletedata-enableAccessToken"] = dd.enableAccessToken
      ? "Enable"
      : "Disable";
    draft["deletedata-accessToken"] = dd.accessToken;
    draft["deletedata-allowUsers"] = dd.allowUsers;
    draft["deletedata-allowGroups"] = dd.allowGroups;
    draft["deletedata-disableAssetIdProtection"] = dd.disableAssetIdProtection
      ? "Enable"
      : "Disable";
  });
};

export const useAssetDetail = (assetId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [assetDetail, setAssetDetail] = useState<any>(undefined);

  const initialize = useCallback(async () => {
    if (initialized) {
      return;
    }
    setIsLoading(true);

    try {
      const asset = await fetchAssetOne(assetId);
      const formed = formatAssetFormData(asset);
      setAssetDetail({
        ...formed,
      });
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
    setInitialized(true);
  }, [assetId, initialized]);
  useEffect(() => {
    initialize();
  }, [initialize]);

  return {
    assetDetail,
    isLoading,
  };
};
