import React from "react";
import styled from "styled-components";

type Props = {
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  button: React.ReactNode;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > *:last-child {
    margin-left: auto;
  }
`;

const Title = styled.div`
  /* font-size: 14px; */
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
`;

const Description = styled.p`
  /* font-size: 14px; */
  /* color: ${(props) => props.theme.disabledColor}; */
  color: ${(props) => props.theme.descriptionTextColor};
`;

const TextContainer = styled.div``;

const ButtonContainer = styled.div``;

export const MDSettingContainer = styled.div`
  border: 1px solid ${(props) => props.theme.secondaryBorderColor};
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  background: ${(props) => props.theme.cardColor};
  > * {
    font-size: ${(props) => props.theme.regularFontSizeMd};
    padding: 16px;
    border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};
  }

  > *:last-child {
    padding: 16px;
    border-bottom: 0px;
  }
`;

export const MDSettingItem: React.FC<Props> = (props) => {
  const Button = props.button;
  return (
    <Container>
      <TextContainer>
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
      </TextContainer>
      <ButtonContainer>{Button}</ButtonContainer>
    </Container>
  );
};
