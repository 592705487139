import React from "react";
import styled from "styled-components";
import {
  OESlotSelect,
  OEStartLogicButton,
  OEStopLogicButton,
} from "src/opt-editor/components";
import { OEStatusChip } from "../atoms/OEStatusChip";
import { cursorSlotState } from "src/opt-editor/recoils/slotState";
import { useRecoilValue } from "recoil";

const OptSelection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Playback = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  > * {
    margin-right: 8px;
  }
`;

const LogicInfo = styled.div`
  width: 100%;
  h4 {
    font-weight: normal;
    font-size: 12px;
    float: right;
    width: 100%;

    text-align: right;
  }
`;

const FullWidthBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  > *:last-child {
    margin-left: 8px;
  }
  .MuiInputBase-root {
    flex: 1;
  }
`;

export const OEOptSelector: React.FC = () => {
  const cursorSlot = useRecoilValue(cursorSlotState);

  return (
    <OptSelection>
      <FullWidthBox>
        <OESlotSelect />
      </FullWidthBox>

      <Playback>
        {cursorSlot?.type === "Optimizer" ? (
          <>
            <OEStartLogicButton />
            <OEStopLogicButton />
            <LogicInfo>
              {/* <h4>Logic: {cursorSlot?.logic ? cursorSlot.logic : "no logic."}</h4>
          <h4>Col: {cursorSlot?.col}</h4> */}
              <OEStatusChip />
            </LogicInfo>
          </>
        ) : (
          <></>
        )}
      </Playback>
    </OptSelection>
  );
};
