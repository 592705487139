import { ASLowCodeFormDefinition } from "allegro-low-code-components";
import { createnAuthAPI } from "src/api";

export const changePasswordFormUi: ASLowCodeFormDefinition = {
  sections: [
    {
      fields: [
        {
          name: "oldPassword",
          label: "Old password",
          type: "secret",
          required: true,
          errorText: "このフィールドを入力してください。",
        },
        {
          name: "newPassword",
          label: "New password",
          type: "secret",
          required: true,
          errorText: "このフィールドを入力してください。",
        },
        {
          name: "confirmNewPassword",
          label: "Confirm new password",
          type: "secret",
          required: true,
          errorText: "このフィールドを入力してください。",
        },
      ],
    },
  ],
  buttonOptions: {
    submitText: "Update password",
  },
  onSubmit: async (data, options) => {
    const { newPassword } = data;
    const nauth = createnAuthAPI();
    await nauth.updateProfile({ password: newPassword });
  },
};
