import React from "react";
import TripOrigin from "@material-ui/icons/TripOrigin";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

const Label = styled.strong`
  verticalalign: middle;
  fontsize: 16px;
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
`;

const LabelNumber = styled.b`
  verticalalign: middle;
  fontsize: 24px;
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
`;

const LabelPer = styled.b`
  verticalalign: middle;
  fontsize: 18px;
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
`;

export type ASGraphLabelProps = {
  label: string;
  point: number;
  color?: string;
};

export const ASGraphLabel: React.FC<ASGraphLabelProps> = (props) => {
  const { label, point, color } = props;
  return (
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid item style={{ verticalAlign: "middle", display: "inline-flex" }}>
        <TripOrigin
          style={{
            fontSize: "32px",
            width: "0.7em",
            height: "0.7em",
            color: color,
          }}
        />
      </Grid>
      <Grid item>
        <Label>{label}</Label>
      </Grid>
      <Grid item>
        <LabelNumber>{point * 100}</LabelNumber>
        <LabelPer>%</LabelPer>
      </Grid>
    </Grid>
  );
};
