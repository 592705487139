import { useCallback, useEffect, useState } from "react";
import { RoleSelection } from "src/newtroid-console/models/RoleSelection";
import { useProfileList } from "src/portal/hooks/useProfileList";
import { useRecoilValueLoadable } from "recoil";
import { useInviteActions } from "src/portal/hooks/useInviteActions";
//States
import { myProfileState } from "src/root/recoils/userState";
import { defaultUser } from "src/opt-editor/models";
import { useMyRole } from "./useMyRole";
import { useAuth } from "src/root";
import { createnAuthAPI } from "src/api";
import { User } from "allegro-api";
import { Role } from "allegro-models";

export type UserListProps = {
  roleGroupName?: string;
  roleSelections?: RoleSelection[];
};

export const useUserList = (props: UserListProps) => {
  const { roleGroupName } = props;

  const auth = useAuth();
  const myRole = useMyRole();
  const { userList, isLoading, validateEmail } = useProfileList();
  const myProfile = useRecoilValueLoadable(myProfileState);

  const { inviteNewMember } = useInviteActions(
    myProfile.contents ?? defaultUser
  );

  const nauth = createnAuthAPI();

  const [showCreateModal, setShowCreateModal] = useState(false);

  const [showInviteModal, setShowInviteModal] = useState(false);
  const [emailToInvite, setEmailToInvite] = useState<string>("");
  const [inviteError, setInviteError] = useState<string | undefined>(undefined);
  const [inviteStep, setInviteStep] = useState(0);

  const [cursorUserId, setCursorUserId] = useState("");

  const myRoleLevel = myRole.getRoleLevel(roleGroupName);

  const roleSelections: RoleSelection[] = (props.roleSelections ?? []).map(
    (roleSelection) => {
      return {
        label: roleSelection.label,
        value: roleSelection.value,
        disabled: myRoleLevel >= (roleSelection.roleLevel ?? 0),
      };
    }
  );

  const handleRoleEdit = (userId: string) => {
    setCursorUserId(userId);
  };

  // Emailのテキスト入力された際のコールバック関数
  const handleChangeInviteEmail = (email: string) => {
    setEmailToInvite(email);
    if (email) {
      setInviteError(undefined);
    }
  };

  // Emailを送信ボタンが押された際のコールバック関数
  // nauthの招待APIをリクエストする
  const handleSubmitInviteEmail = useCallback(async () => {
    const validation = validateEmail(emailToInvite);

    if (validation.error && validation.message) {
      setInviteError(validation.message);
      return;
    }

    const invited = await inviteNewMember(emailToInvite);
    if (!invited) {
      alert("招待に失敗しました。");
    }
    if (invited) {
      setInviteStep(1);
    }
  }, [emailToInvite, inviteNewMember, validateEmail]);

  // 招待のモーダルを表示する
  const handleOpenInviteModal = () => {
    setShowInviteModal(true);
  };

  // 招待のモーダルを非表示にする
  const handleCloseInviteModal = () => {
    setShowInviteModal(false);
    setInviteError(undefined);
    setInviteStep(0);
  };

  // user新規作成モーダルを表示する
  const handleOpenCreateModal = () => {
    setShowCreateModal(true);
  };

  // user新規作成モーダルを非表示にする
  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  // user新規作成ボタンが押された際のコールバック
  // ユーザー新規登録APIをリクエストする
  const handleSubmitCreateUser = async (newUser: any) => {
    const res = await nauth.createUser(newUser);
    if (res.status === 200) {
      setShowCreateModal(false);
      window.location.reload();
    } else {
      console.error("failed api request.");
    }
  };

  // ユーザー一覧を描画するために各フィールドの計算処理を行う
  const createUserListItemAttributes = (): {
    user: User;
    userRole?: Role;
    userRoleName: string;
    canEditRole?: boolean;
  }[] => {
    return userList.map((user) => {
      const userRole = user.roles.find((role) => {
        if (!roleGroupName) {
          return true;
        }
        return role.roleGroup === roleGroupName;
      });
      const userRoleName =
        userRole?.roleDisplayNameEN ?? userRole?.name ?? "None";

      let canEditRole = false;
      if (userRole) {
        // const isRoleExists =
        //   roleSelections.findIndex((roleSelection) => {
        //     return roleSelection.value === (userRole.roleId || userRole.id);
        //   }) >= 0;

        // 自分のロールレベルが操作対象より低いか
        const permittedEditRoleLevel =
          (userRole.roleLevel ?? 99999) > myRole.getRoleLevel(roleGroupName);

        /**
         * ロールの設定権限
         * - ユーザーがアクティベート状態
         * - ユーザーが自分のアカウントでない
         * - ユーザーのロールがroleSelectionsに存在する
         * - 自分のロールレベルがユーザーのロールレベルより低い
         */
        canEditRole =
          user.enable &&
          user.userNoText !== auth.userId &&
          // isRoleExists &&
          permittedEditRoleLevel;
      } else {
        canEditRole = true;
      }

      return {
        user,
        userRole,
        userRoleName,
        canEditRole,
      };
    });
  };

  useEffect(() => {
    if (!showInviteModal) setInviteStep(0);
  }, [showInviteModal]);

  return {
    myRole,
    userList,
    isLoading,
    cursorUserId,
    inviteStep,
    emailToInvite,
    showCreateModal,
    showInviteModal,
    roleSelections,
    inviteError,
    createUserListItemAttributes,
    handleRoleEdit,
    handleChangeInviteEmail,
    handleSubmitInviteEmail,
    handleOpenInviteModal,
    handleCloseInviteModal,
    handleOpenCreateModal,
    handleCloseCreateModal,
    validateEmail,

    handleSubmitCreateUser,
    inviteNewMember,
  };
};
