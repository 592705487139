import { assetCreatingFormDefinition } from "src/newtzero-console/components/lowcode/browser/asset/assetCreatingFormDefinition";
import { assetBroweserUi } from "src/newtzero-console/components/lowcode/browser/asset/assetBrowserUi";
import { NZDataOpsListTemplate } from "../../../templates/NZDataOpsListTemplate";
import { NZDataOpsViewTemplate } from "../../../templates/NZDataOpsViewTemplate";

export const NZAssetBrowserPage: React.FC = () => {
  return (
    <NZDataOpsViewTemplate title="Assets">
      <NZDataOpsListTemplate
        name="Assets"
        browserUi={assetBroweserUi}
        formUi={assetCreatingFormDefinition}
      />
    </NZDataOpsViewTemplate>
  );
};
