import { AxiosResponse } from "axios";
import { api } from "src/api";

export const fetchLogicStatus = async (
  contractorNo: string,
  pipelineName: string,
  nodeId: string,
  apikey: string
): Promise<AxiosResponse | any> => {
  try {
    const res = await api({
      method: "GET",
      url: `/contractors/${contractorNo}/pipelines/${pipelineName}/nodes/${nodeId}/status`,
      params: {
        apikey: apikey,
      },
    });
    return res;
  } catch (err) {
    console.error(err);
  }
};
