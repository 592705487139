import React, { useState } from "react";

import DescriptionIcon from "@material-ui/icons/Description";

import { ASIconButton, ASModal } from "allegro-ui";
import { useSlotActions } from "src/xerver-room/hooks";
import styled from "styled-components";
import { XRLogViewer } from "./XRLogViewer";

const Container = styled.div`
  padding: 25px;
  width: 88vw;
  height: 75vh;
  font-size: 12px;
`;

type Props = { slotId: string; disabled?: boolean };

export const XRViewSlotLogButton: React.FC<Props> = ({ slotId, disabled }) => {
  const slot = useSlotActions(slotId);

  const [show, setShow] = useState(false);

  return (
    <>
      <ASIconButton
        kind="secondary"
        size="small"
        disabled={disabled}
        onClick={() => {
          setShow(true);
        }}
      >
        <DescriptionIcon />
      </ASIconButton>
      <ASModal
        title={`Log ${slot.name}`}
        open={show}
        onClose={() => setShow(false)}
      >
        <Container>
          <XRLogViewer slot={slot} />
        </Container>
      </ASModal>
    </>
  );
};
