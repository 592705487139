//Libs
import React from "react";
import { useRecoilValueLoadable } from "recoil";
import { myProfileState } from "src/root/recoils/userState";

//Components
import { NTSettingsPageTemplate } from "../templates/NTSettingsPageTemplate";
import { PTSettingProfileTemplate } from "src/portal/components/templates/PTSettingProfileTemplate";

//States
//Types

type Props = {};
export const NTSettingProfilePage: React.FC<Props> = (props) => {
  const myProfile = useRecoilValueLoadable(myProfileState);

  switch (myProfile.state) {
    case "hasValue":
      if (myProfile && myProfile.contents)
        return (
          <NTSettingsPageTemplate>
            <PTSettingProfileTemplate />
          </NTSettingsPageTemplate>
        );
      console.log(myProfile);
      return <>error</>;
    case "loading":
      return <>loading</>;
    case "hasError":
      throw myProfile.contents;
  }
};
