import { instance, apiVersion } from "../initialize";

export const deActivateUser = async (
  email: string,
  userNum: number
): Promise<[boolean, string | undefined]> => {
  if (!email || !userNum) {
    console.error("arg is null.");
  }
  const api = instance();
  if (!api) {
    console.error("no api");
    return [false, "something occured."];
  }
  const res = await api.request({
    method: "DELETE",
    url: `/api/1/nauth/users/${userNum}/activate`,
    data: { email },
  });

  if (res.status === 200) {
    return [true, undefined];
  } else {
    return [false, "something occured."];
  }
};

export const reActivateUser = async (
  email: string,
  userNum: number
): Promise<[boolean, string | undefined]> => {
  if (!email || !userNum) {
    console.error("arg is null.");
  }
  const api = instance();
  if (!api) {
    console.error("no api");
    return [false, "something occured."];
  }

  const res = await api.request({
    method: "PUT",
    url: `/api/1/nauth/users/${userNum}/activate`,
    data: { email },
  });

  if (res.status === 200) {
    return [true, undefined];
  } else {
    return [false, "something occured."];
  }
};
