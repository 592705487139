import { CardContent } from "@material-ui/core";
import { ASEasyTable, TableData, createEasyTableProps } from "allegro-ui";
import React from "react";
import { FormValues } from "src/medican/models/FormAnswer";
import styled from "styled-components";

const StyledCardContent = styled(CardContent)`
  max-height: 60vh;
  overflow-y: scroll;
  > * {
    margin-bottom: 8px;
  }
  > *:last-child {
    margin-bottom: 0px;
  }
`;

const StyledEasyTable = styled(ASEasyTable)`
  padding: 12px;
  .row {
    padding: 4px 2px;
    border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};
  }
`;

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 18px;
`;

const ResponsiveBox = styled.div`
  display: flex;
  > :last-child {
    margin-left: 40px;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    > :last-child {
      margin: 0;
    }
  }
`;

type MDJsonCardProps = {
  formValues: FormValues[];
  columns?: number;
};

export const MDJsonCard: React.FC<MDJsonCardProps> = (props) => {
  const columns = props.columns ?? 2;

  const renderTwoColumnsTable = (tableData: TableData[]) => {
    const half = Math.ceil(tableData.length / 2);
    const leftData = tableData.splice(0, half);
    const rightData = tableData.splice(-half);
    return (
      <>
        <StyledEasyTable tableData={leftData} />
        <StyledEasyTable tableData={rightData} />
      </>
    );
  };
  return (
    <StyledCardContent>
      {props.formValues.map((value) => {
        const easyTableProps = createEasyTableProps(value.values);
        return (
          <Container>
            <Header>{value.title}</Header>
            {/* {easyTableProps.length > 0 ? ( */}
            <ResponsiveBox>
              {columns === 2 ? (
                renderTwoColumnsTable(easyTableProps)
              ) : (
                <StyledEasyTable tableData={easyTableProps} />
              )}
            </ResponsiveBox>
            {/* ) : (
              <>
                <NoDataPlaceHolder>データがありません</NoDataPlaceHolder>
              </>
            )} */}
          </Container>
        );
      })}
    </StyledCardContent>
  );
};
