import { AxiosInstance } from "axios";
import { wrapAsync } from "../../libs/wrapper";
import { commit } from "./commit";
import { fetchData } from "./fetchData";
import { save } from "./save";

export const Bundle = (instance: AxiosInstance) => {
  return {
    save: wrapAsync(instance, save),
    fetchData: wrapAsync(instance, fetchData),
    commit: wrapAsync(instance, commit),
  };
};
