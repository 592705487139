import { atom, atomFamily, selector, DefaultValue, RecoilState } from "recoil";
import { MonitorThumb, defaultMonitorThumb } from "src/kinzaza/models";
import * as kinzazaApi from "src/kinzaza/api";

export const guardRecoilDefaultValue = (
  candidate: any
): candidate is DefaultValue => {
  if (candidate instanceof DefaultValue) return true;
  return false;
};

export const cursorMonitorThumbIdState = atom<string>({
  key: "CursorMonitorThumbIdState",
  default: "",
});

const monitorAtom = atomFamily({
  key: "MonitorAtom",
  default: async (param: string) => {
    const result = await kinzazaApi.fetchSingleMonitor(param);
    return result;
  },
});

export const currentMonitor: RecoilState<MonitorThumb> = selector<MonitorThumb>(
  {
    key: "CurrentMonitor",
    get: async ({ get }) => {
      const monitorId = get(cursorMonitorThumbIdState);
      return get(monitorAtom(monitorId)) ?? defaultMonitorThumb;
    },
    set: ({ get, set }, monitor) => {
      if (guardRecoilDefaultValue(monitor)) return;

      const monitorId = get(cursorMonitorThumbIdState);

      kinzazaApi.saveMonitor(monitor).then((d) => {});
      set(monitorAtom(monitorId), monitor);
    },
  }
);
