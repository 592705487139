import React from "react";

import { NZMainViewTemplate } from "../templates/NZMainViewTemplate";
import { OEOptEditorPageTemplate } from "src/opt-editor/components/templates/OEOptEditorPageTemplate";

type Props = {};

export const NTSciencePage: React.FC<Props> = (props) => {
  return (
    <NZMainViewTemplate>
      <OEOptEditorPageTemplate />
    </NZMainViewTemplate>
  );
};
