import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { ASInputBase } from "allegro-ui";
import { IconButton } from "@material-ui/core";
import styled from "styled-components";

const Search = styled.div`
  background: ${(props) => props.theme.secondaryColor};
  margin-left: auto;
  position: relative;
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  padding: 0 8px;
  min-height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${(props) => props.theme.primaryBorderColor};
`;

const StyledSearchIcon = styled(SearchIcon)`
  color: #888;
`;

type Props = {};
export const OEFilesSearchBar: React.FC<Props> = (props) => {
  return (
    <Search>
      <ASInputBase kind="secondary" placeholder="フォルダを検索" />
      <IconButton size="small" type="submit" aria-label="search">
        <StyledSearchIcon />
      </IconButton>
    </Search>
  );
};
