import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  vertical-align: center;
`;

const Name = styled.span`
  font-weight: bold;
  font-size: 18px;
  margin-left: 24px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  position: relative;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
  cursor: pointer;
  :hover {
    opacity: 0.8;
    .dot {
      /* left: -26px; */
      /* width: 12px; */
      /* height: 18px; */
      transition: all 0.1s 0s ease-in-out;
    }
  }
`;

const ChildName = styled.div`
  max-width: 250px; /* 省略せずに表示するサイズを指定 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
  letter-spacing: 1.4px;
`;

const Caption = styled.div`
  color: ${(props) => props.theme.descriptionTextColor};
  font-size: ${(props) => props.theme.regularFontSize};
  margin-left: 24px;
`;

const PrimaryIcon = styled.div`
  width: 6px;
  height: 36px;
  background-color: ${(props) => props.theme.primaryColor};
  border-radius: 4px;
  margin-left: 10px;
  position: absolute;
  left: -32px;
  top: 0px;
`;

export interface ILCNameThumbComponentProps {
  data: {
    userNo: string | number;
    title: string;
    subTitle: string;
  };
  options: {
    path: string;
  };
}

const parsePath = (path: string, data: { [key: string]: any }): string => {
  const regex = /{([^}]+)}/g;
  return path.replace(regex, (_, match) => data[match]);
};

export default function LCNameThumbComponent(
  props: ILCNameThumbComponentProps
) {
  const history = useNavigate();

  const handleClick = () => {
    const path = parsePath(props.options.path, props.data);
    history(path);
  };

  return (
    <Container onClick={() => handleClick()}>
      <Name>
        <PrimaryIcon className="dot" />

        <ChildName>{props.data.title}</ChildName>
      </Name>
      <Caption>{props.data.subTitle}</Caption>
      {/* <h2>{props.data.atena}</h2> */}
    </Container>
  );
}
