import { produce } from "immer";
import { AbstractTreeItem } from "./AbstractTreeItem";
import { createScienceFile } from "./ScienceFSItem";

export type File = AbstractTreeItem & {
  fileId: number;
  data: any;
  createdDate: string; //ISO;
  createdDateUnix: number; //unixtime
  updatedDate: string; //ISO;
  updatedDateUnix: number; //unixtime
  author?: string; //作者 userId
  readers: string[];
  writers: string[];
};

export const defaultFileData: File = {
  name: "",
  fileId: 0,
  data: undefined,
  parent: 0, // folderId
  createdDate: new Date().toISOString(), //ISO;
  createdDateUnix: 0, //unixtime
  updatedDate: new Date().toISOString(), //ISO;
  type: "File",
  updatedDateUnix: 0, //unixtime
  author: "", //作者 userId
  readers: ["*"],
  writers: ["*"],
};

export const scienceFileAdaptor = (resdata: any) => {
  const rawdata = resdata.contents.rawdata;
  const file = rawdata as File;

  const result = createScienceFile({
    ...rawdata,
    name: rawdata.name ?? file.name,
    itemId: rawdata.itemId ?? file.fileId,
    contents: rawdata.contents ?? file.data,
    readers: resdata.readers ?? ["*"],
    writers: resdata.writers ?? ["*"],
  });
  return result;
};

export const createFileFromRes = (topdata: any): File => {
  const props = topdata.contents.rawdata;

  return produce(defaultFileData, (draft: any) => {
    draft.name =
      typeof props?.name === "string" && props?.name !== ""
        ? props?.name
        : draft.name;
    draft.fileId = props?.fileId ?? 0;
    draft.parent = props?.parent ?? 0;
    draft.data = props?.data ?? undefined;
    draft.createdDate = new Date().toISOString(); //ISO;
    draft.createdDateUnix = Date.now(); //unixtime
    draft.updatedDate = new Date().toISOString(); //ISO;
    draft.updatedDateUnix = Date.now(); //unixtime
    draft.author = topdata.bases?.owner;

    draft.readers = topdata.readers ?? props.readers ?? draft.readers ?? ["*"];
    draft.writers = topdata.writers ?? props.writers ?? draft.writers ?? ["*"];
  });
};

/**
 * ファイルの値をセット
 * 表示用→DBに格納
 * fileId：String(表示用) → Number（DB格納）
 * @deperecated ScienceFSItem.ts を使用する。
 * @param callback 操作結果
 */
export const createFile = (
  props: Partial<File>,
  created?: number,
  count?: number,
  bases?: any,
  readers?: string[],
  writers?: string[]
): File => {
  console.log(props);

  // const props = props.props.rawdata;
  return produce(defaultFileData, (draft: any) => {
    draft.name =
      typeof props?.name === "string" && props?.name !== ""
        ? props?.name
        : draft.name;
    draft.fileId = props?.fileId ?? 0;
    draft.parent = props?.parent ?? 0;
    draft.data = props?.data ?? undefined;
    draft.createdDate = new Date().toISOString(); //ISO;
    draft.createdDateUnix = Date.now(); //unixtime
    draft.updatedDate = new Date().toISOString(); //ISO;
    draft.updatedDateUnix = Date.now(); //unixtime
    draft.author = bases?.owner;
    draft.readers = readers ?? draft.readers ?? ["*"];
    draft.writers = writers ?? draft.writers ?? ["*"];
  });
};

/**
 * @deperecated ScienceFSItem.ts を使用する。
 */
export const updateFile = (
  props: Partial<File>,
  created?: number,
  count?: number
): File => {
  return produce(defaultFileData, (draft: any) => {
    draft.name =
      typeof props.name === "string" && props.name !== ""
        ? props.name
        : draft.name;
    draft.fileId = props.fileId ?? 0;
    draft.parent = props.parent ?? 0;
    draft.data = props.data ?? undefined;
    draft.createdDate =
      typeof props.createdDate === "string"
        ? props.createdDate
        : draft.createdDate;
    draft.createdDateUnix =
      typeof props.createdDateUnix === "number"
        ? props.createdDateUnix
        : draft.createdDateUnix;
    draft.updatedDate = new Date().toISOString(); //ISO;
    draft.updatedDateUnix = Date.now(); //unixtime
    draft.readers = props.readers ?? draft.readers;
    draft.writers = props.writers ?? draft.writers;
  });
};

export const permissionToPublic = (item: File): File => {
  return produce<File>(item, (draft) => {
    draft.writers = ["*"];
    draft.readers = ["*"];
  });
};

export const permissionToClose = (item: File): File => {
  return produce<File>(item, (draft) => {
    draft.writers = [];
    draft.readers = [];
  });
};

/**
 *
 * @deperecated ScienceFSItem.ts を使用する。
 */
export const chmodAdd = (
  props: File,
  type: "Reader" | "Writer",
  userId: string
): File => {
  const result = produce<File>(props, (draft) => {
    switch (type) {
      case "Reader":
        if (draft.readers.includes(userId)) {
          console.error("already included.");
        } else {
          draft.readers = [...draft.readers, userId];
        }
        break;
      case "Writer":
        if (draft.writers.includes(userId)) {
          console.error("already included.");
        } else {
          draft.writers = [...draft.writers, userId];
        }
        break;
      default:
        console.error("chmod::不正な値");
    }
  });
  console.log(result);
  return result;
};

/**
 * @deperecated ScienceFSItem.ts を使用する。
 */
export const chmodRemove = (
  props: File,
  type: "Reader" | "Writer",
  userNo: string
): File => {
  return produce<File>(props, (draft) => {
    switch (type) {
      case "Reader":
        draft.readers = draft.readers.filter((reader) => {
          return reader !== userNo;
        });

        break;
      case "Writer":
        draft.writers = draft.writers.filter((writer) => {
          return writer !== userNo;
        });

        break;
      default:
        console.error("chmod::不正な値");
    }
  });
};
