import React from "react";
import styled from "styled-components";

import { XRSlotInspector } from "./XRSlotInspector";
import { lighten, darken } from "polished";
import { useRecoilValue } from "recoil";
import { showRightPaneState } from "src/xerver-room/recoils/uiState";
import ResizePanel from "react-resize-panel-ts";
import "../../css/customHandle.css";

type Props = {};

const Pane = styled(ResizePanel)`
  /* min-height: 100vh; */
  flex-grow: 1;

  width: 100%;
  /* padding: 24px; */

  /* background: ${(props) => "#282828"}; */

  /* border-left: 3px solid ${(props) =>
    lighten(0.1, props.theme.bgPrimaryColor)}; */
`;

const ContentWrapper = styled.div`
  min-width: 400px;
  /* border-left: 2px solid ${(props) =>
    lighten(0.2, props.theme.bgPrimaryColor)}; */
  width: 100%;
  /* background: ${(props) => props.theme.bgPrimaryColor}; */
  /* background: #282828; */
`;

export const XRRightPane: React.FC<Props> = (props) => {
  const showRightPane = useRecoilValue(showRightPaneState);
  return (
    <>
      {showRightPane ? (
        <Pane direction="w" handleClass="customHandleV">
          <ContentWrapper>
            <React.Suspense fallback={<></>}>
              <XRSlotInspector></XRSlotInspector>
            </React.Suspense>
          </ContentWrapper>
        </Pane>
      ) : null}
    </>
  );
};
