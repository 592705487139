import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChildListItem } from "../../models";
import styled from "styled-components";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import VisibilityIcon from "@material-ui/icons/Visibility";
import * as api from "../../api";
import { useDataContext } from "../../contexts";
import { FTStatusChip, FTEvalChip, FTDataGroupChip, FTAIArrow } from "../atoms";
import FlagIcon from "@material-ui/icons/Flag";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { ASIconButton } from "allegro-ui";

type Props = {
  child: ChildListItem;
};

const Name = styled.span`
  font-weight: bold;
  font-size: 20px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  position: relative;
`;

const ChildName = styled.div`
  max-width: 250px; /* 省略せずに表示するサイズを指定 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Age = styled.span`
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: 6px;
`;

const Birth = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

const Caption = styled.div`
  color: #999;
  font-size: 14px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  /* padding-left: 13px; */
`;

const Num = styled.span`
  font-size: 20px;
  margin-left: 24px;
`;

const RiskScore = styled.div`
  margin-top: -5px;
`;

const AIscore = styled.div`
  display: flex;
`;

const Blueicon = styled.div`
  width: 8px;
  height: 8px;
  background-color: #4f6fff;
  border-radius: 50%;
  margin-left: 10px;
  position: absolute;
  left: -24px;
  top: 4px;
`;

const Pinkicon = styled.div`
  width: 8px;
  height: 8px;
  background-color: #f84fa4;
  border-radius: 50%;
  margin-left: 10px;
  position: absolute;
  left: -24px;
  top: 4px;
`;

const LowData = styled.p`
  display: block;
  font-size: 14px;
  margin-top: 10px;
  color: ${(props) => props.theme.negativeColor};
`;

const TableBody = styled.div`
  background-color: ${(props) => props.theme.cardColor};
  border-radius: 20px;
  width: 100%;
  color: ${(props) => (props.theme.darkMode ? "#eee" : "#222")};
  /* height: 170px; */
  margin-bottom: 6px;
`;

const TableTop = styled.div`
  border-bottom: solid 1px
    ${(props) => (props.theme.darkMode ? "#333" : "#eee")};
  height: 115px;
  display: flex;
`;

const TableBottom = styled.div`
  display: flex;
`;

const ToggleButton = styled.div`
  opacity: 0.3;
  &.enable {
    opacity: 1;
  }
`;

const CellOne = styled.div`
  flex: 0.7;
  text-align: center;
  padding-top: 45px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
`;

const CellTwo = styled.div`
  flex: 2;
  padding-top: 30px;
  margin-right: 30px;
  width: 24px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
`;

const CellThree = styled.div`
  flex: 2;
  text-align: center;
  padding-top: 42px;
`;

const CellFour = styled.div`
  flex: 2;
  padding-top: 30px;
  text-align: center;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
`;

const CellFive = styled.div`
  flex: 1;
  text-align: right;
  padding-top: 39px;
`;

const CellSix = styled.div`
  flex: 1;
  text-align: right;
  padding-top: 39px;
  margin-right: 15px;
`;

const CellSeven = styled.div`
  flex: 4;
  margin-right: 15px;
  padding-top: 7px;
  text-align: center;
`;

const CellEight = styled.div`
  flex: 2;
  padding-top: 18px;
  text-align: center;
`;

const CellNine = styled.div`
  flex: 2;
  padding-top: 18px;
  margin-right: 30px;
`;

const F = styled.div`
  flex: 1;
`;

//全児童一覧のテーブルの一つの列を表している。

export const FTChildListItem: React.FC<Props> = ({ child }) => {
  const history = useNavigate();
  const store = useDataContext();
  const [watch, setWatch] = useState(child.isWatch);
  const [unread, setUnread] = useState(child.unreadDataUpdate);

  //リンク先の児童詳細画面に飛ぶ動作と、虐待予防的支援業務データをstoreで児童詳細に伝えるためにこの関数を実装

  const handleLink = (path: string, data: ChildListItem) => {
    store.handleCursorChild(data);
    history(path);
  };

  //ボタンを押すと児童詳細画面に飛ぶと同時に、未読状態を既読状態に変える処理を書く。

  const onRowClick = async (data: ChildListItem) => {
    console.log("click");
    console.log(data);
    handleLink(`/child/${data.atena}`, data);
    if (unread) {
      const res = await api.childListPost(data, "未読更新");
      if (res) setUnread(!unread);
    }
  };

  //ウォッチ（要観察）かそうでないかの処理を書くための関数。

  const handleWatch = async (data: ChildListItem) => {
    const res = await api.childListPost(data, "ウォッチ");
    if (res) setWatch(!watch);
  };

  const showGender = child.sex === "男性" ? <Blueicon /> : <Pinkicon />;

  const showEnoughData = child.hasDataShortage ? (
    <LowData>※データが不足しています</LowData>
  ) : (
    <LowData />
  );

  //未読か既読か

  const showNewlyDataAdded =
    child.hasNewlyAddedData === true ? <FlagIcon /> : null;

  const AIOneArrow =
    child.predictionOneChange === "上昇" ? (
      <FTAIArrow kind="positive">
        <ArrowUpwardIcon />
      </FTAIArrow>
    ) : child.predictionOneChange === "下降" ? (
      <FTAIArrow kind="negative">
        <ArrowDownwardIcon />
      </FTAIArrow>
    ) : (
      <FTAIArrow kind="stay">
        <ArrowForwardIcon />
      </FTAIArrow>
    );

  const AITwoArrow =
    child.predictionTwoChange === "上昇" ? (
      <FTAIArrow kind="positive">
        <ArrowUpwardIcon />
      </FTAIArrow>
    ) : child.predictionTwoChange === "下降" ? (
      <FTAIArrow kind="negative">
        <ArrowDownwardIcon />
      </FTAIArrow>
    ) : (
      <FTAIArrow kind="stay">
        <ArrowForwardIcon />
      </FTAIArrow>
    );

  return (
    <>
      <TableBody>
        <TableTop>
          <CellOne onClick={() => onRowClick(child)}>
            {showNewlyDataAdded}
          </CellOne>
          <CellTwo onClick={() => onRowClick(child)}>
            <Flex>
              <div>
                <Name>
                  <ChildName>
                    {process.env.REACT_APP_KAMEI_MODE
                      ? "非表示"
                      : child.name ||
                        child.foreignerName ||
                        child.kana ||
                        "No Name"}
                  </ChildName>

                  <Age>({child.age})</Age>
                  {showGender}
                </Name>

                <Birth>{child.birthday}</Birth>
              </div>
            </Flex>
          </CellTwo>
          <CellThree onClick={() => onRowClick(child)}>
            <FTStatusChip value={child.statusNum} />
          </CellThree>
          <CellThree onClick={() => onRowClick(child)}>
            <FTEvalChip value={child.evaluationNum} />
          </CellThree>
          <CellThree onClick={() => onRowClick(child)}>
            <FTDataGroupChip value={child.dataGroupNum} />
          </CellThree>
          <CellFour onClick={() => onRowClick(child)}>
            <AIscore>
              <F>
                <RiskScore>
                  <p>検査１</p>
                </RiskScore>
                <Flex>
                  <Num>{child.predictionOneNum}</Num>
                  {AIOneArrow}
                </Flex>
              </F>

              <F>
                <RiskScore>
                  <p>検査２</p>
                </RiskScore>
                <Flex>
                  <Num>{child.predictionTwoNum}</Num>
                  {AITwoArrow}
                </Flex>
              </F>
            </AIscore>
          </CellFour>
          <CellFive>
            <ToggleButton className={watch ? "enable" : ""}>
              <ASIconButton kind="white" onClick={() => handleWatch(child)}>
                <VisibilityIcon fontSize="small" />
              </ASIconButton>
            </ToggleButton>
          </CellFive>
          <CellSix onClick={() => onRowClick(child)}>
            <ASIconButton kind="white">
              <ArrowForwardIosIcon fontSize="small" />
            </ASIconButton>
          </CellSix>
        </TableTop>
        <TableBottom>
          <CellOne onClick={() => onRowClick(child)}></CellOne>
          <CellNine onClick={() => onRowClick(child)}>
            <Caption>{child.atena}</Caption>
          </CellNine>
          <CellEight onClick={() => onRowClick(child)}>
            <Caption>{child.lastEvaluationDate}</Caption>
          </CellEight>
          <CellEight onClick={() => onRowClick(child)}>
            <Caption>{child.lastEvaluationDate}</Caption>
          </CellEight>
          <CellEight onClick={() => onRowClick(child)}>
            <Caption>{child.lastPredictionDate}</Caption>
          </CellEight>
          <CellSeven onClick={() => onRowClick(child)}>
            {showEnoughData}
          </CellSeven>
        </TableBottom>
      </TableBody>
    </>
  );
};
