import { Tab, Tabs } from "@material-ui/core";
import { ASSwitchButton } from "allegro-ui";
import { useState } from "react";

import styled from "styled-components";
import { NZJsonViewer } from "../molecules/NZJsonViewer";
import { createAutoBrowserUi } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { LCListViewComponent } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { NZDocumentViewer } from "./NZDocumentViewer";
import { NZLoadingContainer } from "../molecules/NZLoadingContainer";
import { MDNoDataPlaceHolder } from "src/medican/components/atoms";

import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@material-ui/icons/GetApp";
import { NZDeleteIconButton } from "../molecules/NZDeleteButton";
import { NZDownloadIconButton } from "../molecules/NZDownloadButton";

const Container = styled.div``;

const Header = styled.div`
  /* height: 40px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  > *:last-child {
    margin-left: auto;
  }
`;

const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-left: 12px;
  }
`;

const StyledClassicTabs = styled(Tabs)`
  padding: 0px 12px;
  .MuiButtonBase-root {
  }

  .MuiTab-root {
    color: ${(props) => props.theme.descriptionTextColor};
    background-color: ${(props) => props.theme.bgPrimaryColor};
    min-width: 100px;
    width: 100px;
    min-height: 40px;
    height: 40px;
  }
  .MuiTab-wrapper {
    text-transform: none;
  }
  min-height: 40px;
  height: 40px;
  border-radius: 0px;
  .Mui-selected {
    color: ${(props) => props.theme.textColor};
    background: ${(props) => props.theme.bgTertiaryColor};
    border-radius: ${(props) => props.theme.borderRadiusMd}px
      ${(props) => props.theme.borderRadiusMd}px 0px 0px;
    border-top: 1px solid ${(props) => props.theme.secondaryBorderColor};
    border-left: 1px solid ${(props) => props.theme.secondaryBorderColor};
    border-right: 1px solid ${(props) => props.theme.secondaryBorderColor};
  }
  z-index: 3;

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabs-scroller {
  }
`;

const StyledTab = styled(Tab)``;

const StyledLabel = styled.div`
  color: ${(props) => props.theme.textColor};
  font-size: ${(props) => props.theme.labelFontSizeMd}px;
`;

const DataCounter = styled.div`
  color: ${(props) => props.theme.textColor};
  font-size: ${(props) => props.theme.regularFontSizeMd};
  min-width: 120px;
  text-align: right;
`;

const MainArea = styled.div`
  background: ${(props) => props.theme.bgTertiaryColor};
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  border: 1px solid ${(props) => props.theme.secondaryBorderColor};

  /* min-height: 300px; */
  min-height: 60vh;
  height: calc(100vh - 600px);
  overflow: scroll;
  padding: 12px;
`;

type Props = {
  data: any;
  count?: number;
  hasValue?: boolean;
  columns?: string[];
  isLoading?: boolean;
  onDelete?: () => void;
  onDownload?: (options: {
    dataType: "rawdata" | "optdata";
    extension: "csv" | "json";
  }) => Promise<void>;
};

function a11yProps(index: number) {
  return {
    key: `nz-data-viewer-${index}`,
    id: `nz-data-viewer-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const NZDataViewer: React.FC<Props> = (props) => {
  const { data, count, columns, isLoading } = props;
  const [tabId, setTabId] = useState(0);
  const [dataType, setDataType] = useState<"rawdata" | "optdata">("optdata");

  const [_columns, setColumns] = useState(columns);

  const tabMenu = ["Document", "Table", "Json"];

  const handleChange = (event: any, newValue: number) => {
    setTabId(newValue);
  };

  const handleUseRawData = (checked: boolean) => {
    const newDataType = checked ? "rawdata" : "optdata";
    setDataType(newDataType);
    if (checked && data.length > 0) {
      const cols = Object.keys(data[0].contents[newDataType]);
      setColumns(cols);
    }
  };

  const renderViewer = () => {
    switch (tabId) {
      case 0:
        return (
          <>
            <NZDocumentViewer
              data={data?.map((d: any) => d.contents[dataType]) ?? []}
            />
          </>
        );
      case 1:
        return (
          <>
            <LCListViewComponent
              fontSize={13}
              newtData={data?.map((d: any) => d.contents[dataType]) ?? []}
              uiData={createAutoBrowserUi(
                (dataType === "rawdata" ? _columns : columns) ?? []
              )}
            />
          </>
        );
      case 2:
        return <NZJsonViewer data={data} />;
    }
  };

  return (
    <Container>
      <Header>
        <StyledClassicTabs value={tabId} onChange={handleChange}>
          {tabMenu.map((tab, id) => {
            return <StyledTab label={tab} {...a11yProps(id)} />;
          })}
        </StyledClassicTabs>
        <HeaderRight>
          <StyledLabel>View Rawdata</StyledLabel>
          <ASSwitchButton
            onChange={(event, checked) => handleUseRawData(checked)}
          />
          <DataCounter>{count} data</DataCounter>
          <NZDownloadIconButton
            onSubmit={async (args) => {
              if (props.onDownload)
                props.onDownload({ dataType, extension: args.extensions });
            }}
          >
            <DownloadIcon style={{ width: "20px", height: "20px" }} />
          </NZDownloadIconButton>

          <NZDeleteIconButton
            onSubmit={async () => {
              if (props.onDelete) props.onDelete();
            }}
          >
            <DeleteIcon style={{ width: "20px", height: "20px" }} />
          </NZDeleteIconButton>
        </HeaderRight>
      </Header>
      <MainArea>
        {!isLoading ? (
          <>
            {data && data.length > 0 ? (
              renderViewer()
            ) : (
              <MDNoDataPlaceHolder>
                データを取得できませんでした
              </MDNoDataPlaceHolder>
            )}
          </>
        ) : (
          <>
            <NZLoadingContainer />
          </>
        )}
      </MainArea>
    </Container>
  );
};
