//Libs
import styled from "styled-components";
import React from "react";

//Components
import { ASButton, ASInputBase, ASModal } from "allegro-ui";
//States
import { FormControl } from "@material-ui/core";
import { XRPageTemplate } from "src/xerver-room/components/templates/XRPageTemplate";
import { ConfirmModalContents } from "src/kinzaza/components";
import {
  ASFormLabel,
  ASLowCodeFormContainer,
} from "allegro-low-code-components";
import { changePasswordFormUi } from "src/portal/ui/forms/changePasswordFormUi";
import { useSettingSecrets } from "src/portal/hooks/useSettingSecrets";
//Types

const Container = styled.div`
  color: ${(props) => (props.theme.darkMode ? "white" : "#222")};
`;

const Title = styled.div`
  font-weight: bold;
  font-size: ${(props) => props.theme.regularFontSizeLg}px;
  margin-bottom: 12px;
  padding: 0 2px;
`;
const ModalContainer = styled.div`
  width: 400px;
  padding: 24px 8px;
  line-height: 1.5;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const FormContainer = styled(FormControl)`
  padding: 0 2px;
`;

const ButtonLayout = styled.div`
  margin-top: 16px;
  text-align: right;
`;

const AlertArea = styled.div`
  color: ${(props) => props.theme.cautionColor};
  margin-bottom: 16px;
`;

type Props = {};
export const PTSettingSecretsTemplate: React.FC<Props> = () => {
  const {
    showRefreshApiKeyModal,
    currentApiKey,
    handleValidatePassword,
    handleUpdatePassword,
    handleRefreshApiKey,
    handleOpenRefreshApiKeyModal,
    handleCloseRefreshApiKeyModal,
  } = useSettingSecrets();
  return (
    <>
      <XRPageTemplate title="Security">
        <Container>
          <Form>
            <Title>Change Password</Title>
            <ASLowCodeFormContainer
              key="pt-change-password-sform"
              kind="digicho"
              formDefinition={changePasswordFormUi}
              buttonsOptions={{ updateText: "Update password" }}
              fullWidth
              isUpdate
              onValid={handleValidatePassword}
              onSubmit={async (data, options) => {
                await handleUpdatePassword(data, options);
              }}
            />
          </Form>
          <Form>
            <Title>Refresh API Key</Title>
            <FormContainer>
              <ASFormLabel>API Key</ASFormLabel>
              <ASInputBase disabled value={currentApiKey} kind="digicho" />
              <ButtonLayout>
                <ASButton
                  kind="secondary"
                  onClick={handleOpenRefreshApiKeyModal}
                >
                  Refresh API Key
                </ASButton>
                <ASModal
                  title="Refresh API Key"
                  open={showRefreshApiKeyModal}
                  onClose={handleCloseRefreshApiKeyModal}
                >
                  <ConfirmModalContents
                    submitLabel="Refresh"
                    cancelLabel="Cancel"
                    onSubmit={handleRefreshApiKey}
                    onCancel={handleCloseRefreshApiKeyModal}
                  >
                    <ModalContainer>
                      <AlertArea>警告: APIキーのリフレッシュ</AlertArea>
                      この操作は慎重に行ってください。APIキーのリフレッシュは、セキュリティとアクセスに影響を与える可能性があります。
                    </ModalContainer>
                  </ConfirmModalContents>
                </ASModal>
              </ButtonLayout>
            </FormContainer>
          </Form>
        </Container>
      </XRPageTemplate>
    </>
  );
};
