import ReactJson from "react-json-view";
import { json } from "react-router-dom";
import { DefaultTheme } from "styled-components";
import PerfectScrollbar from "react-perfect-scrollbar";

type Props = {
  data: any;
  theme?: DefaultTheme;
};

export const NZJsonViewer: React.FC<Props> = (props) => {
  return (
    <PerfectScrollbar style={{ background: "none" }}>
      <ReactJson
        name={null}
        displayDataTypes={false}
        displayObjectSize={false}
        src={props.data}
        theme={{
          base00: "#2A2B2D",
          base01: "#505050",
          base02: "#505050",
          base03: "#505050",
          base04: "#37507e",
          base05: "#cfcfcf",
          base06: "#cfcfcf",
          base07: "#cfcfcf",
          base08: "#cfcfcf",
          base09: props.theme?.primaryColor ?? "#1ED760",
          base0A: props.theme?.primaryColor ?? "#1ED760",
          base0B: props.theme?.primaryColor ?? "#1ED760",
          base0C: props.theme?.primaryColor ?? "#1ED760",
          base0D: props.theme?.primaryColor ?? "#1ED760",
          base0E: props.theme?.primaryColor ?? "#1ED760",
          base0F: props.theme?.primaryColor ?? "#1ED760",
        }}
        enableClipboard={true}
        // typeScript に対応してない https://github.com/mac-s-g/react-json-view/pull/348
      />
    </PerfectScrollbar>
  );
};
