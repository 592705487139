import axios from "axios";
import { API_URL } from "src/utils/apiUrl";

const apiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_MEDICAN_API_BASE_URL
    : API_URL + "/medican";

export const medicanAPIConfig = axios.create({
  baseURL: `${apiUrl}`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: sessionStorage.token,
  },
  timeout: 10000,
});
