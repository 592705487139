//Libs
import React, { useState, useCallback } from "react";
import { useRecoilValue, useRecoilRefresher_UNSTABLE } from "recoil";
//Components
import { ASInputBase, ASModal } from "allegro-ui";
import { ASIconButton } from "allegro-ui";
import AddIcon from "@material-ui/icons/Add";

//States
import scienceFolder from "../../recoils/ScienceFolderState";
//Types
import { createScienceFolder } from "src/opt-editor/models/ScienceFSItem";
import { ConfirmModalContents } from "src/kinzaza/components";
import { ASFormLabel } from "allegro-low-code-components";
import styled from "styled-components";

const Container = styled.div`
  width: 400px;
  > * {
    margin-bottom: 16px;
  }
`;

type Props = {};
export const OECreateFolderButton: React.FC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [inputFolderName, setInputFolderName] = useState("");
  const [enableCreateButton, setEnableCreateButton] = useState(true);
  const cursorFolderId = useRecoilValue(scienceFolder.cursorItemIdState);
  const nextFolderId = useRecoilValue(scienceFolder.nextAutoIncrementalId);
  const refreshTreeList = useRecoilRefresher_UNSTABLE(scienceFolder.idList);
  const cursorFolderOfProject = useRecoilValue(
    scienceFolder.item(cursorFolderId)
  );

  /**
   * フォルダを作成する
   */
  const handleCreateFolder = useCallback(async () => {
    // モーダル画面閉じる
    setShowModal(false);

    await scienceFolder.addItem(
      createScienceFolder({
        itemId: nextFolderId,
        name: inputFolderName,
        parent: cursorFolderId,
        type: "Folder",
      })
    );
    refreshTreeList();
    // setRequestId((v) => v + 1);
  }, [cursorFolderId, inputFolderName, nextFolderId, refreshTreeList]);

  /**
   * HTML部
   */
  return (
    <>
      <ASIconButton size="small" onClick={() => setShowModal(true)}>
        <AddIcon fontSize="small" />
      </ASIconButton>
      <ASModal open={showModal} onClose={setShowModal}>
        <ConfirmModalContents
          cancelLabel="Cancel"
          submitLabel="Create"
          submitDisabled={!enableCreateButton}
          onSubmit={() => handleCreateFolder()}
          onCancel={() => setShowModal(false)}
        >
          <Container>
            <p>
              {cursorFolderOfProject.name}(ID:{cursorFolderId}
              )にフォルダを作成します。
            </p>
            <div>
              <ASFormLabel>Name</ASFormLabel>
              <ASInputBase
                key="projectName"
                kind="digicho"
                fullWidth
                onChange={(e: any) => {
                  setInputFolderName(() => e.target.value);
                  e.target.value.length > 0
                    ? setEnableCreateButton(true)
                    : setEnableCreateButton(false);
                }}
              />
            </div>
          </Container>
        </ConfirmModalContents>
      </ASModal>
    </>
  );
};
