import { api, bundles } from "..";

type Args = {
  bundleId: string;
  version: string;
  refName: string;
  newtQuery: any;
};

export const fetchData = async (props: Args) => {
  const { bundleId, version, refName, newtQuery } = props;
  const url = `/bundles/${bundleId}/${version}/${refName}/datasource`;
  const res = await api.post(url, newtQuery);
  return res.data;
};
