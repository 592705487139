import { atom, selector } from "recoil";
import { User, createUser } from "allegro-api";
import Cookies from "js-cookie";
import { AuthState } from "../models/AuthState";

export const authState = atom<AuthState>({
  key: "authState",
  default: {
    userNoText: null,
    username: null,
    token: null,
    contractorNo: null,
    userId: null,
    userNameText: null,
    roles: null,
    userRealName: null,
    avatar: null,
    email: null,
    phoneNumber: null,
    zipcode: null,
    address: null,
  },
  effects: [
    ({ onSet, trigger, setSelf }) => {
      if (trigger === "get") {
        const newValue = {
          userNoText: Cookies.get("userNoText") ?? null,
          username: Cookies.get("username") ?? null,
          token: Cookies.get("token") ?? null,
          contractorNo: Cookies.get("contractorNo") ?? null,
          userId: Cookies.get("userNoText") ?? null,
          userNameText: Cookies.get("userNameText") ?? null,
          roles: Cookies.get("roles") ?? null,
          userRealName: Cookies.get("userRealName") ?? null,
          avatar: window.localStorage.getItem("avatar") ?? null,
          email: Cookies.get("email") ?? null,
          phoneNumber: Cookies.get("phoneNumber") ?? null,
          zipcode: Cookies.get("zipcode") ?? null,
          address: Cookies.get("address") ?? null,
        };

        setSelf(newValue);

        for (const [key, value] of Object.entries(newValue)) {
          if (value && key !== "avatar") sessionStorage.setItem(key, value);
        }
      }

      onSet((newValue) => {
        for (const [key, value] of Object.entries(newValue)) {
          if (value) {
            // TODO localStrageでアバターを保存しているが、実装的に良くないためAPIを使う予定
            if (key === "avatar") {
              window.localStorage.setItem(key, value);
            } else {
              Cookies.set(key, value, { expires: 30 });
            }
          } else {
            if (key === "avatar") {
              window.localStorage.removeItem(key);
            } else {
              Cookies.remove(key);
            }
          }
        }
      });

      /**
       * @deprecated
       * 下位互換 - sessionStorageはCookieに置き換え
       */
      onSet((newValue) => {
        for (const [key, value] of Object.entries(newValue)) {
          if (value) sessionStorage.setItem(key, value);
        }
      });
    },
  ],
});

/**
 * @deprecated authStateをかわりに使用してください。
 */
export const userState = authState;

export const myProfileState = selector<User>({
  key: "myProfileState",
  get: async ({ get }) => {
    const auth = get(authState);

    return createUser({
      userNoText: auth.userNoText,
      token: auth.token ?? undefined,
      username: auth.username ?? undefined,
      profile: {
        userRealName: auth.userRealName,
        apiKey: auth.token ?? "",
      },
      contractorNo: auth.contractorNo ?? undefined,
      avatar: auth.avatar ?? undefined,
      email: auth.email ?? undefined,
      phoneNumber: auth.phoneNumber ?? undefined,
      zip: auth.zipcode ?? undefined,
      address: auth.address ?? undefined,
    });
  },
});
