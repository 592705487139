import { AxiosResponse } from "axios";
import { APIConfigProps, createAPIConfig } from "../config/http";
import { createnAuthConfig } from "../config/nauth";
import { wrapAsync } from "../libs/wrapper";

import { reActivateUser, deActivateUser } from "./activate";
import { AuthProps, auth } from "./auth";
import { invite } from "./invite";
import { updateAvatar, updateProfile } from "./myaccount";
import { confirmRescuePassword, rescuePassword } from "./password";
import { updateRole } from "./role";
import { confirmSignup, signup } from "./signup";
import { createUser, getUsers, getUsersByDataProvider } from "./users";
import group from "./group";

export const nAuth = (props: APIConfigProps) => {
  const instance = createAPIConfig(props);

  return {
    auth: wrapAsync<[AuthProps], Promise<AxiosResponse<any>>, any>(
      instance,
      auth
    ),
    signup: wrapAsync(instance, signup),
    confirmSignup: wrapAsync(instance, confirmSignup),
    rescuePassword: wrapAsync(instance, rescuePassword),
    confirmRescuePassword: wrapAsync(instance, confirmRescuePassword),
    invite: wrapAsync(instance, invite),
    getUsers: wrapAsync(instance, getUsers),
    getUsersByDataProvider: wrapAsync(instance, getUsersByDataProvider),
    createUser: wrapAsync(instance, createUser),
    updateProfile: wrapAsync(instance, updateProfile),
    updateAvatar: wrapAsync(instance, updateAvatar),
    updateRole: wrapAsync(instance, updateRole),
    reActivateUser: wrapAsync(instance, reActivateUser),
    deactivateUser: wrapAsync(instance, deActivateUser),
    group: group(instance),
  };
};
