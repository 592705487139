import { User } from "../models";
import { ScienceItem } from "../models/ScienceFSItem";
import * as scienceFolderActions from "src/opt-editor/models/ScienceFSItem";
import produce from "immer";

export const usePermissionEdit = (item: ScienceItem) => {
  /**
   * ユーザーをアイテムの閲覧者または編集者に追加する。
   */
  const addUser = async (user: User) => {
    // モーダル画面閉じる
    // const nextItem = scienceFolderActions.chmodAdd(
    //   item,
    //   "Reader",
    //   String(user.userNo)
    // );
    // console.log(nextItem);
    // return nextItem;
  };

  const toWriter = (userId: string): ScienceItem => {
    return produce(item, (draft) => {
      if (draft.readers.includes(userId)) {
        console.error("already included.");
      } else {
        draft.readers = [...draft.readers, userId];
      }
      if (draft.writers.includes(userId)) {
        console.error("already included.");
      } else {
        draft.writers = [...draft.writers, userId];
      }
    });
  };

  const toReader = (userId: string): ScienceItem => {
    return produce(item, (draft) => {
      if (draft.readers.includes(userId)) {
        console.error("already included.");
      } else {
        draft.readers = [...draft.readers, userId];
      }
      draft.writers = draft.writers.filter((writer) => {
        return writer !== userId;
      });
    });
    // switch (itemType) {
    //   case "folder":
    //     let nextFolder = scienceFolderActions.chmodRemove(
    //       folder,
    //       "Writer",
    //       userId
    //     );
    //     // nextFolder = chmodAdd(folder, "Reader", userId);
    //     console.log(nextFolder);
    //     if (isScienceFolder(nextFolder)) {
    //       setFolder(nextFolder);
    //     }
    //     break;
    //   case "file":
    //     let nextFile = fileModelActions.chmodRemove(file, "Writer", userId);
    //     // nextFolder = chmodAdd(folder, "Reader", userId);
    //     console.log(nextFile);
    //     if (nextFile) {
    //       await fileActions.update(nextFile);
    //       refreshPage();
    //     }
    //     break;
    // }
  };

  const toPrivate = (): ScienceItem => {
    // switch (itemType) {
    //   case "folder":
    const nextReaders = item.readers.filter((d) => d !== "*");
    const nextWriters = item.writers.filter((d) => d !== "*");
    //     break;
    //   case "file":
    //     const nextFile = fileModelActions.permissionToClose(file);
    //     if (nextFile) {
    //       await fileActions.update(nextFile);
    //       refreshPage();
    //     }
    //     break;
    // }
    return { ...item, readers: nextReaders, writers: nextWriters };
  };

  const toPublic = (): ScienceItem => {
    const nextItem = scienceFolderActions.permissionToPublic(item);

    // if (isScienceFolder(nextFolder)) {
    //   setFolder(nextFolder);
    // }

    return nextItem;
  };

  const removeUser = (
    item: ScienceItem,
    userNo: string | number
  ): ScienceItem => {
    return produce(item, (draft) => {
      draft.readers = draft.readers.filter((reader) => {
        return reader !== userNo;
      });
      draft.writers = draft.writers.filter((writer) => {
        return writer !== userNo;
      });
    });

    // const strUserId = String(userId);
    // console.log({ userId });
    // let nextFolder = scienceFolderActions.chmodRemove(
    //   folder,
    //   "Reader",
    //   strUserId
    // );
    // nextFolder = scienceFolderActions.chmodRemove(
    //   nextFolder,
    //   "Writer",
    //   strUserId
    // );
    // console.log(nextFolder);
    // if (isScienceFolder(nextFolder)) {
    //   setFolder(nextFolder);
    // }
  };

  return {
    addUser,
    removeUser,
    toReader,
    toWriter,
    toPublic,
    toPrivate,
  };
};
