import { dFurusatoAPIConfig } from "../config";

// 回答アセット一覧取得API
export const getProjectMany = async (
  limit?: number,
  skip?: number,
  options?: any
) => {
  const url = `/app/projects`;
  const params = {
    contractorNo: sessionStorage.contractorNo,
    userNoText: sessionStorage.userNoText,
    limit: limit ?? 1,
    skip: skip ?? 0,
    nameFilter: options?.name,
  };

  const res = await dFurusatoAPIConfig.get(url, { params: params });
  return res;
};

export const getProjectOne = async (formId: string) => {
  const url = `/app/projects/${formId}`;
  const params = {
    userNoText: sessionStorage.userNoText,
  };

  const res = await dFurusatoAPIConfig.get(url, { params });
  if (Array.isArray(res.data.message)) {
    return res.data.message[0];
  }
};
