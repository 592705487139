import { dFurusatoAPIConfig } from "../config";

export const updateOne = async (
  slug: string,
  objectId: string,
  data: any,
  params?: any
) => {
  const url = `/${slug}/${objectId}`;
  try {
    const res = await dFurusatoAPIConfig.put(url, data, { params });
    return res;
  } catch (err) {
    console.error(err);
  }
};
