import React from "react";
import { Box, Typography } from "@material-ui/core/";

export type MDTabPanelProps = {
  index: number;
  value: number;
};

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const MDTabPanel: React.FC<MDTabPanelProps> = ({
  children,
  value,
  index,
}) => {
  return (
    <TabPanel value={value} index={index}>
      {children}
    </TabPanel>
  );
};
