import { ASLoadingCircle } from "allegro-ui";
import React from "react";
import { useNotify } from "allegro-ui";
import styled from "styled-components";

const Layout = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const LoadingCircleContainer: React.FC = () => {
  const notify = useNotify();
  return (
    <Layout>
      <ASLoadingCircle isLoading={notify.isLoading} />
    </Layout>
  );
};
