import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "src/root/recoils/userState";

export const useHeaderNav = () => {
  const auth = useRecoilValue(authState);
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };
  return { auth, navigate, handleLogoClick };
};
