import { AxiosInstance } from "axios";
import { getMany, getOne } from "./select";
import { wrapAsync } from "../../libs/wrapper";

export const Xervice = (instance: AxiosInstance) => {
  return {
    getSlots: wrapAsync(instance, getMany),
    getSlot: wrapAsync(instance, getOne),
  };
};
