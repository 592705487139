import { useCallback } from "react";

import { Coordinate, GraphData } from "allegro-ui";
import {
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import {
  FETCH_BEFORE_MILISECONDS,
  serverListState,
  serverRequestIdState,
  serverStatsState,
} from "../recoils/serverState";
import { useNavigate } from "react-router-dom";
import { tabIdState } from "src/fortress/recoils/singleChildPageState";
import { slotSearchFieldTextState } from "../recoils";

export const INSPECTOR_PAGE_KEY = "inspector-page";
const JET_LAG_JAPAN = 9 * 60 * 60 * 1000;

export const useServerStatsViewer = () => {
  const [requestId, setRequestId] = useRecoilState(serverRequestIdState);
  const serversLoadable = useRecoilValueLoadable(serverListState);
  const serverStatsLoadable = useRecoilValueLoadable(serverStatsState);
  const setTabId = useSetRecoilState(tabIdState(INSPECTOR_PAGE_KEY));
  const setSlotSerchText = useSetRecoilState(slotSearchFieldTextState);

  const navigate = useNavigate();

  const isLoading = useCallback((): boolean => {
    return (
      serversLoadable.state === "loading" ||
      serverStatsLoadable.state === "loading"
    );
  }, [serverStatsLoadable.state, serversLoadable.state]);

  const dummyData: Coordinate[] = [...Array(300)].map((_, idx) => {
    return {
      x: idx.toString(),
      y: 0,
    };
  });

  const dummyGraphData: GraphData[] = [
    {
      id: 1,
      label: "",
      dataPoints: dummyData,
    },
  ];

  const getGraphDatas = useCallback((): {
    [key: string]: {
      cpu: Coordinate[];
      memory: Coordinate[];
      disk: Coordinate[];
    };
  } => {
    let results: {
      [key: string]: {
        cpu: Coordinate[];
        memory: Coordinate[];
        disk: Coordinate[];
      };
    } = {};

    if (!isLoading()) {
      results = Object.fromEntries(
        serversLoadable.contents.map((serverObj: any) => {
          const serverName = serverObj.contents.rawdata.non_numeric_server_name;
          return [serverName, { cpu: [], memory: [], disk: [] }];
        })
      );
      const stats = serverStatsLoadable.contents;
      for (const stat of stats) {
        const name = stat.name;

        const created = new Date(stat.created + JET_LAG_JAPAN);
        if (results[name]) {
          results[name].cpu.push({ x: created, y: stat.cpuUsage });
          results[name].memory.push({ x: created, y: stat.memoryUsage });
          results[name].disk.push({ x: created, y: stat.diskUsage });
        }
      }
    }
    return results;
  }, [isLoading, serverStatsLoadable.contents, serversLoadable.contents]);

  const allGraphData: {
    [key: string]: { cpu: GraphData; memory: GraphData; disk: GraphData };
  } = Object.fromEntries(
    Object.entries(getGraphDatas()).map(([serverName, allCoordinates], idx) => {
      const graph = {
        cpu: {
          id: 0,
          label: serverName,
          color: "primary",
          dataPoints:
            allCoordinates.cpu.length > 0 ? allCoordinates.cpu : dummyData,
        },
        memory: {
          id: 0,
          label: serverName,
          color: "primary",
          dataPoints:
            allCoordinates.memory.length > 0
              ? allCoordinates.memory
              : dummyData,
        },
        disk: {
          id: 0,
          label: serverName,
          color: "primary",
          dataPoints:
            allCoordinates.disk.length > 0 ? allCoordinates.disk : dummyData,
        },
      };
      return [serverName, graph];
    })
  );

  const getCpuPercentages = useCallback((): { [key: string]: Coordinate[] } => {
    let results: { [key: string]: Coordinate[] } = {};

    if (!isLoading()) {
      const stats = serverStatsLoadable.contents;

      for (const stat of stats) {
        const name = stat.name;
        if (results[name]) {
          results[name].push({ x: stat.created, y: stat.cpuUsage });
        } else {
          results[name] = [{ x: stat.created, y: stat.cpuUsage }];
        }
      }
    }

    return results;
  }, [isLoading, serverStatsLoadable.contents]);

  const cpuGraphData: GraphData[] = Object.entries(getCpuPercentages()).map(
    ([serverName, coordinates], idx) => {
      return {
        id: idx,
        label: serverName,
        dataPoints: coordinates.length > 0 ? coordinates : dummyData,
      };
    }
  );

  const getMemoryPercentages = useCallback((): {
    [key: string]: Coordinate[];
  } => {
    let results: { [key: string]: Coordinate[] } = {};

    if (!isLoading()) {
      const stats = serverStatsLoadable.contents;
      for (const stat of stats) {
        const name = stat.name;
        if (results[name]) {
          results[name].push({ x: stat.created, y: stat.memoryUsage });
        } else {
          results[name] = [{ x: stat.created, y: stat.memoryUsage }];
        }
      }
    }

    return results;
  }, [isLoading, serverStatsLoadable.contents]);

  const memoryGraphData: GraphData[] = Object.entries(
    getMemoryPercentages()
  ).map(([serverName, coordinates], idx) => {
    return {
      id: idx,
      label: serverName,
      dataPoints: coordinates.length > 0 ? coordinates : dummyData,
    };
  });

  const getDiskUsages = useCallback((): { [key: string]: Coordinate[] } => {
    let results: { [key: string]: Coordinate[] } = {};

    if (!isLoading()) {
      const stats = serverStatsLoadable.contents;
      for (const stat of stats) {
        const name = stat.name;
        if (results[name]) {
          results[name].push({ x: stat.created, y: stat.diskUsage });
        } else {
          results[name] = [{ x: stat.created, y: stat.diskUsage }];
        }
      }
    }

    return results;
  }, [isLoading, serverStatsLoadable.contents]);

  const diskGraphData: GraphData[] = Object.entries(getDiskUsages()).map(
    ([serverName, coordinates], idx) => {
      return {
        id: idx,
        label: serverName,
        dataPoints: coordinates.length > 0 ? coordinates : dummyData,
      };
    }
  );

  const resyncStatsData = async () => {
    setRequestId(requestId + 1);
  };

  const navigateToContainersPage = (serverName: string) => {
    const ip = serversLoadable.contents.find((server: any) => {
      return server.contents.rawdata.non_numeric_server_name === serverName;
    }).contents.rawdata.non_numeric_ip1;
    const path = `/admin/xervice?tab=0`;
    navigate(path);
    setSlotSerchText(ip);
    setTabId(0);
  };

  const maxDate = new Date();
  const minDate = new Date(maxDate.getTime() - FETCH_BEFORE_MILISECONDS);

  return {
    serversLoadable,
    serverStatsLoadable,
    isLoading,
    dummyData,
    allGraphData,
    getCpuPercentages,
    getMemoryPercentages,
    getDiskUsages,
    cpuGraphData,
    memoryGraphData,
    diskGraphData,
    dummyGraphData,
    resyncStatsData,
    minDate,
    maxDate,
    navigateToContainersPage,
  };
};
