//
//モニター詳細ページのコンポーネントである。
//ここでデータの詳細を見ることができる。
//dayビューと、直近一ヶ月分のデータを見ることができる。
//左下のテーブルを押すと一日あたりのデータを見ることができる。一番上のグラフで1日あたりどれだけのデータが入っているのかがわかる。
//

import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import {
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";

import { Grid, Input, Tab } from "@material-ui/core";
import styled from "styled-components";

import { format } from "date-fns";
import {
  unixTimePickerState,
  cursorMonitorThumbIdState,
  searchContainerState,
  fetchingDaysCountState,
  dataFetchCountState,
  cursorDailyThumbState,
} from "src/kinzaza/recoils";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { KZDailyThumbList } from "src/kinzaza/components";
import { MonthlyDataGraph, JsonViewer } from "src/kinzaza/components";
import { DailyThumb } from "src/kinzaza/models";
import { JsonViewState } from "src/kinzaza/recoils/jsonViewState";
import produce from "immer";
import { DataCounter, Header, KZQueryForm } from "src/kinzaza/components";
import { ASClassicTabs, ASLoadingCircle } from "allegro-ui";
import NZAlertDetailView from "src/newtzero-console/components/organisms/NZAlertDetialView";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Root = styled.div`
  flex-grow: 1;
  width: 100%;
  padding: 30px;
  flex-direction: row;
`;

const TabsWrapper = styled.div`
  padding: 0 38px;
`;

const TabBodyContainer = styled.div`
  border: 1px solid ${(props) => props.theme.secondaryBorderColor};
  border-radius: ${(props) => props.theme.borderRadiusLg}px;
  padding: 24px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
`;

const SettingContainer = styled.div`
  width: 30%;
  margin-right: 2%;
`;

const ViewContainer = styled.div`
  width: 68%;
`;

const ToolsNavBar = styled.div`
  display: flex;
  align-items: center;
  /* background: #333; */
  /* width: 100%; */
  padding: 8px;
  border-radius: 8px;
  > *:last-child {
    margin-left: auto;
  }
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledButtonGroup = styled(ToggleButtonGroup)`
  background: ${(props) => props.theme.cardColor};
  margin-left: 24px;
`;

const DayPicker = styled.div`
  margin-left: auto;
`;

const StyledToggleButton = styled(ToggleButton)`
  color: ${(props) => (props.theme.darkMode ? "#FFF" : "#000")};
  :hover {
    background: ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.bgPrimaryColor};
  }
  &.Mui-selected {
    background: ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.bgPrimaryColor};
    :hover {
      background: ${(props) => props.theme.primaryColor};
      color: ${(props) => props.theme.bgPrimaryColor};
    }
  }
  &.Mui-disabled {
    color: ${(props) => (props.theme.darkMode ? "#777" : "#555")};
  }
`;

const StyledInput = styled(Input)`
  color: ${(props) => (props.theme.darkMode ? "#eee" : "#000")};
  background-color: ${(props) => props.theme.bgPrimaryColor};
  input::-webkit-calendar-picker-indicator {
    filter: invert(${(props) => (props.theme.darkMode ? 1 : 0)});
  }
`;

const formatYYYYMMDD = (date: Date | number) => {
  try {
    return format(date, "yyyy-MM-dd");
  } catch (err) {
    console.error(err);
  }
};

type Params = {
  monitorId: string;
};

type Props = {};

const periodInfo = [
  {
    label: "DAY",
    value: 1,
  },
  {
    label: "WEEK",
    value: 7,
  },
  {
    label: "MONTH",
    value: 30,
  },
];

export const KZSingleMonitorPageTemplate: React.FC<Props> = (props) => {
  const [cusorMonitorId, setCursorMonitorId] = useRecoilState(
    cursorMonitorThumbIdState
  );

  const dataCount = useRecoilValueLoadable(dataFetchCountState);
  // const newtQueryChecker = useRecoilValueLoadable(successNewtQueryState);
  const [tabId, setTabId] = React.useState(0);

  const [date, setDate] = useRecoilState(unixTimePickerState);
  const [days, setDays] = useRecoilState(fetchingDaysCountState);
  const setSearch = useSetRecoilState(searchContainerState);
  let { monitorId } = useParams<Params>();

  const [cursorDailyThumb, setCursorDailyThumb] = useRecoilState(
    cursorDailyThumbState
  );

  const [jsonView, setJsonView] = useRecoilState(JsonViewState);

  //検索コンポーネントはモニター一覧ページだけしか表示しないので、モニター詳細ページに遷移した瞬間にsetSearch(false)にして検索コンポーネントの表示を止める必要がある。
  //また、urlにmonitor名を持たせ、そのurlからモニター名を取得して状態を持たせている。
  useEffect(() => {
    setSearch(false);
    if (monitorId) setCursorMonitorId(monitorId);
  }, [cusorMonitorId, monitorId, setCursorMonitorId, setSearch]);

  //日にちを変更する処理

  const handleDateChange = (date: any) => {
    const ut = new Date(date).getTime();
    setDate(ut);
  };

  //dayビューかmonthlyビューかを切り替えることができる。より多角的な視点でデータを観れるようにこのようにしている。
  const handleMonitorViewtype = (_: any, newValue: number) => {
    if (newValue && days !== newValue) {
      setDays(newValue);
    }
  };

  const handleChangeDailyThumb = (dailyThumb: DailyThumb) => {
    console.log({ dailyThumb });
    if (dailyThumb) {
      setCursorDailyThumb(dailyThumb);
      const nextJsonView = produce(jsonView, (draft) => {
        const dateTime =
          dailyThumb.modified ?? dailyThumb.created ?? new Date();
        draft.date = dateTime.getTime();
        draft.limit = 1;
        draft.offset = 0;
      });
      setJsonView(nextJsonView);
    }
  };
  const handleChangeTab = (event: any, newValue: number) => {
    if (tabId !== newValue) {
      setTabId(newValue);
    }
  };

  const renderViewer = () => {
    return (
      <ViewContainer>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ToolsNavBar>
              <DateContainer>
                <DayPicker>
                  <StyledInput
                    type="date"
                    name="monitoring-base-date"
                    defaultValue={formatYYYYMMDD(date)}
                    onChange={(e) => {
                      handleDateChange(e.target.value);
                    }}
                  />
                </DayPicker>
                <StyledButtonGroup
                  value={days}
                  exclusive
                  onChange={handleMonitorViewtype}
                  aria-label="text alignment"
                  size="small"
                >
                  {periodInfo.map((period, id) => {
                    return (
                      <StyledToggleButton
                        key={`period-toggle-button-${period.label}-${id}`}
                        value={period.value}
                        aria-label={`${period.label} monitor`}
                      >
                        {period.label}
                      </StyledToggleButton>
                    );
                  })}
                </StyledButtonGroup>
              </DateContainer>
              <React.Suspense fallback={<div>Loading...</div>}>
                <DataCounter />
              </React.Suspense>
            </ToolsNavBar>
          </Grid>

          <Grid item xs={12}>
            <React.Suspense fallback={<div>Loading...</div>}>
              <MonthlyDataGraph />
            </React.Suspense>
          </Grid>

          <Grid item xs={4}>
            <React.Suspense fallback={<div>Loading...</div>}>
              <KZDailyThumbList onClick={(dt) => handleChangeDailyThumb(dt)} />
            </React.Suspense>
          </Grid>
          <Grid item xs={8}>
            <React.Suspense fallback={<div>Loading...</div>}>
              <JsonViewer dailyThumb={cursorDailyThumb} />
            </React.Suspense>
          </Grid>
        </Grid>
      </ViewContainer>
    );
  };

  return (
    <Root>
      <Header />
      <TabsWrapper>
        <ASClassicTabs value={tabId} onChange={handleChangeTab}>
          <Tab label={"Search"} {...a11yProps(0)} />
          <Tab label="Alert Settings" {...a11yProps(1)} />
          {/* <Tab label="Alert Logs" {...a11yProps(2)} /> */}
        </ASClassicTabs>
      </TabsWrapper>

      <TabBodyContainer>
        {(() => {
          switch (tabId) {
            case 0:
              return (
                <Body>
                  <SettingContainer>
                    <React.Suspense fallback={<div>Loading...</div>}>
                      <KZQueryForm show={true} />
                    </React.Suspense>
                  </SettingContainer>

                  {dataCount.state === "hasValue" ? (
                    renderViewer()
                  ) : (
                    <ASLoadingCircle isLoading />
                  )}
                </Body>
              );
            case 1:
              return (
                <>
                  <NZAlertDetailView />
                </>
              );
            case 2:
              return <>wip</>;
          }
        })()}
      </TabBodyContainer>
    </Root>
  );
};
