import React, { useContext, useState, createContext, useEffect } from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useRecoilState, useRecoilValueLoadable } from "recoil";

import favy, { createUser } from "allegro-api";

import { authState, myProfileState } from "../recoils/userState";

export type ContextProps = {
  contractorName: string | null;
  userId: string | null;
  userNoText: string | null;
  token: string | null;
  roles: string | null;
  userRealName: string | null;
  avatar: string | null;
  login: (
    email: string,
    password: string,
    contractor: string
  ) => Promise<AxiosResponse>;
  logout: () => void;
  isAuth: () => boolean;
  isFetching: boolean;
  errorMsg: string;
};

const API_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_BASE_URL
    : window.location.protocol +
      "//" +
      window.location.host +
      process.env.REACT_APP_API_BASE_URL;

export const useProvideAuth = (): ContextProps => {
  const nauth = favy.nauth({
    baseUrl: API_URL + "/nauth" ?? "",
  });

  const [contractorName, setContractorName] = useState(
    sessionStorage.contractorNo
  );

  const [auth, setAuth] = useRecoilState(authState);
  const myProfile = useRecoilValueLoadable(myProfileState);

  const [isFetching, setIsFetching] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const isAuth = (): boolean => {
    return auth.token !== null;
  };

  const login = async (
    email: string,
    password: string,
    contractor: string
  ): Promise<AxiosResponse> => {
    const params = {
      password,
      email,
      contractor,
    };

    setErrorMsg("");
    setIsFetching(true);

    try {
      const res = await nauth.auth(params);

      const nextUser = createUser(res.data);
      setIsFetching(false);

      console.log("set auth ", nextUser);
      setAuth({
        token: nextUser.profile.apiKey,
        userNoText: nextUser.userNoText,
        username: nextUser.username,
        contractorNo: nextUser.contractorNo,
        userId: nextUser.userNoText, //下位互換のため.いずれ削除する
        userNameText: nextUser.nickName,
        roles: JSON.stringify(nextUser.roles),
        userRealName: nextUser.profile.userRealName,
        avatar: nextUser.avatar,
        email: nextUser.email,
        phoneNumber: nextUser.phoneNumber,
        zipcode: nextUser.zip,
        address: nextUser.address,
      });

      return res;
    } catch (err: any) {
      console.error(err);
      setErrorMsg(err.message);

      // setUser({
      //   ...defaultUser,
      //   contractorNo: user.contractorNo,
      // });

      setIsFetching(false);
      return err;
    }
  };

  const logout = () => {
    setAuth({
      userNoText: null,
      username: null,
      token: null,
      contractorNo: null,
      userId: null,
      userNameText: null,
      roles: null,
      userRealName: null,
      avatar: null,
      email: null,
      phoneNumber: null,
      zipcode: null,
      address: null,
    });
  };

  const userId = auth.userNoText;
  const userNoText = auth.userNoText;
  const token = auth.token;
  const userRealName = "";
  const roles = auth.roles;
  const avatar = auth.avatar;
  return {
    contractorName,
    userId,
    userNoText,
    token,
    roles,
    userRealName,
    avatar,
    login,
    isAuth,
    isFetching,
    errorMsg,
    logout,
  };
};

export const authContext = createContext<Partial<ContextProps>>({});

export const useAuth = () => {
  return useContext(authContext);
};
