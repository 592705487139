import {
  ASFormFieldBase,
  ASLowCodeFormDefinition,
} from "allegro-low-code-components";

import { produce } from "immer";
import dokodemoFAPI from "src/dfurusato-pf/api";

export type Form = {
  id: string;
  name: string;
  sections: any[];
  parents: string[];
  readers?: string[];
  writers?: string[];
};

export type FormDefinition = {
  formId: string;
  title: string;
  forms: Form[];
};

const defaultForm: Form = {
  id: "",
  name: "",
  sections: [],
  parents: [],
  readers: ["*"],
  writers: ["*"],
};

const defaultFormDefinition: FormDefinition = {
  formId: "",
  title: "",
  forms: [],
};

const createFormField = (data: any): ASFormFieldBase => {
  const name = data.name ?? (data.type === "target" ? "targetId" : data.label);
  const type = data.type === "target" ? "selection" : data.type;
  return {
    ...data,
    label: data.label,
    name,
    type,
  };
};

export const createForm = (data: any): Form => {
  return produce(defaultForm, (draft) => {
    draft.id = data.id ?? draft.id;
    draft.name = data.name ?? draft.name;
    draft.sections = (data.sections ?? draft.sections).map((section: any) => {
      return {
        ...section,
        fields: section.fields.map((field: any) =>
          createFormField(field ?? [])
        ),
      };
    });
    draft.parents = data.parents ?? draft.parents;
    draft.writers = data.writers ?? draft.writers;
    draft.readers = data.readers ?? draft.readers;
  });
};

export const createFormDefinition = (data: any): FormDefinition => {
  if (!data.formId && !data.projectId) {
    throw new Error("formIdを指定してください");
  }
  if (!data.forms || data.forms.length === 0) {
    throw new Error("formsを指定してください");
  }
  return produce(defaultFormDefinition, (draft) => {
    draft.formId = data.formId ?? data.projectId;
    draft.title = data.title ?? draft.title;
    draft.forms = (data.forms ?? draft.forms).map((formObj: any) => {
      return createForm(formObj);
    });
  });
};

export const createFormData = (
  form: Form,
  isUpdate?: boolean
): ASLowCodeFormDefinition => {
  const sections = form.sections ?? [];
  return {
    sections: sections.map((section: any) => {
      return {
        title: section.title ?? "",
        titleDescription: section.description,
        fields: section.fields.map((field: any) => {
          return {
            ...field,
          };
        }),
      };
    }),
    isUpdate,
  };
};

export const createFormDefaultValue = (formData: ASLowCodeFormDefinition) => {
  let values: any = {};
  const sections = formData.sections ?? [];
  sections.forEach((section) => {
    const fields = section.fields ?? [];
    fields.forEach((field) => {
      if (field.type !== "section" && field.name) {
        values[field.name] = field.active ?? undefined;
      }
    });
  });

  return values;
};

export const convertDefaultValueKey2Label = (defaultValue: any, form: Form) => {
  const fieldNames: { label: string; name: string }[] = form.sections
    .map((section) => {
      return section.fields.map((field: any) => {
        return {
          label: field.label,
          name: field.name,
        };
      });
    })
    .flat();

  let result: any = {};
  for (const [key, value] of Object.entries(defaultValue)) {
    const label = fieldNames.find((field) => key === field.name)?.label ?? key;
    result[label] = value;
  }

  return result;
};

export type SectionValues = {
  title?: string;
  description?: string;
  values: any;
};

export const splitFormValueByFieldSections = (
  formData: ASLowCodeFormDefinition,
  data: any
): SectionValues[] => {
  const formSections = formData.sections ?? [];
  return formSections.map((section) => {
    const fieldsData = section.fields ?? [];

    // let fields: SectionValues[] = [];
    let field: any = {};
    fieldsData.forEach((fieldData, idx) => {
      // if (fieldData.type === "section" || idx === 0) {
      //   if (idx > 0) {
      //     fields.push(field);
      //     console.log("       fields", Object.keys(field.values));
      //   }

      //   const title =
      //     fieldData.type === "section" ? fieldData.label : "No title";
      //   field = { title: title, values: {} };
      //   console.log("   subsection", field.title);
      // } else {
      //   field.values[fieldData.name] = data[fieldData.name];
      // }
      if (!["section", "skelton"].includes(fieldData.type)) {
        field[fieldData.name] = data[fieldData.name];
      }
    });

    // fields.push(field);

    return {
      title: section.title,
      description: section.description,
      values: field,
    };
  });
};
