import { ASButton, useNotify } from "allegro-ui";
import React, { Component, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { PTUserAuthComponent } from "src/portal/components/organisms/PTUserAuthComponent";
import styled from "styled-components";
import { PTLoginForm } from "src/portal/components/molecules/PTLoginForm";
import { useSnackbar } from "notistack";
import { useAuth } from "src/root";
import { CHUserAuthMainViewTemplate } from "../templates/CHUserAuthMainView";
import { UserAuthPath } from "../models/userAuthRoutes";
import CHTermsAndPrivacyCaption from "../../molecules/CHTermsAndPrivacyCaption";

const SignUpNote = styled.div`
  color: ${(props) => props.theme.textColor};
  padding: 23px 0;
  &::before {
    top: 9px;
    /* border-bottom: 1px solid #fff; */
    position: absolute;
    left: 0;
    width: 100%;
    content: "";
  }
`;

const SignUpRegister = styled.div`
  padding: 0 64px;
`;

export type CHLoginPageProps = {
  logo: any;
  appName: string;
  copyRight?: string;
  routes: UserAuthPath;
  privacyPolicyComponent?: ReactElement;
  termsOfSeviceCompoennt?: ReactElement;
};

export const CHLoginPage: React.FC<CHLoginPageProps> = (props) => {
  const navigate = useNavigate();
  const notify = useNotify();
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();

  const handleSubmit = async (
    email: string,
    password: string,
    contractor: string
  ) => {
    notify.dispatch("loading", { isLoading: true });
    const res = auth.login
      ? await auth.login(email, password, contractor)
      : null;
    notify.dispatch("loading", {
      isLoading: false,
    });
    if (!res || res.status >= 400) {
      let message = `ログインに失敗しました。error:${res?.data.message}`;
      if (res?.status === 400) {
        message = `ログインに失敗しました。ログイン情報が間違っています。`;
      } else if (res?.status === 404) {
        message = `ログインに失敗しました。ログイン情報が間違っています。`;
      }

      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <CHUserAuthMainViewTemplate logo={props.logo}>
        <PTUserAuthComponent
          formTitle={"ログイン"}
          formContent={<PTLoginForm onSubmit={handleSubmit} />}
        >
          <CHTermsAndPrivacyCaption
            termsOfSeviceComponent={props.termsOfSeviceCompoennt}
            privacyPolicyComponent={props.privacyPolicyComponent}
          />
          <div>
            <SignUpNote>{props.appName}への登録がまだの方は</SignUpNote>
            <SignUpRegister>
              <ASButton
                kind="secondary"
                size="large"
                fullWidth
                onClick={() => {
                  navigate(props.routes.signUp);
                }}
              >
                会員登録
              </ASButton>
            </SignUpRegister>
          </div>
        </PTUserAuthComponent>
      </CHUserAuthMainViewTemplate>
    </>
  );
};
