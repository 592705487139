import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ASIconButton } from "allegro-ui";

// icons
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import EditIcon from "@material-ui/icons/Edit";

const StyledIconButton = styled(ASIconButton)`
  /* color: ${(props) => props.theme.primaryColor}; */
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

type IconButtonType =
  | "inherit"
  | "primary"
  | "secondary"
  | "white"
  | "yellow"
  | "blue"
  | "logout"
  | undefined;

export interface ILCIconLinkCellComponentProps {
  data: { [key: string]: any };
  options: {
    path: string;
    icon: string;
    kind?: IconButtonType;
    disabled?: boolean;
  };
}

const parsePath = (path: string, data: { [key: string]: any }): string => {
  const regex = /{([^}]+)}/g;
  return path.replace(regex, (_, match) => data[match]);
};

const icons: { [key: string]: any } = {
  arrow: ChevronRightIcon,
  edit: EditIcon,
};

export default function LCIconLinkCellComponent(
  props: ILCIconLinkCellComponentProps
) {
  const history = useNavigate();
  const handleLink = () => {
    const path = parsePath(props.options.path, props.data);
    history(path);
  };

  const kind = props.options.kind ?? "secondary";
  const Icon = icons[props.options.icon] ?? icons.arrow;

  return (
    <div>
      <StyledIconButton
        kind={kind}
        disabled={props.options.disabled}
        onClick={handleLink}
      >
        <Icon />
      </StyledIconButton>
    </div>
  );
}
