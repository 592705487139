import { Immutable } from "immer";
import { OptLogic } from "../models/OptLogic";

export type Slot = Immutable<{
  className: string;
  name: string;
  type: "Puddle" | "Optimizer" | "OptimizerLite";
  icon: string;
  createdby: string;
  col?: number;
  row?: number;
  sharedby?: string | null;
  description: string;
  //@非推奨 linesは削除予定
  lines: Array<{ from: string; to: string }>;
  connections: Array<{ from: string; to: string }>;
  logic: "";
  //Liteのみ
  opt?: OptLogic;
  logicType: "REALTIME" | "TRIGGER";
}>;

export type Slots = {
  [key: string]: Slot;
};

export const initialSlot: Slot = {
  className: "",
  name: "emptyslot",
  type: "OptimizerLite",
  icon: "",
  createdby: "",
  description: "",
  lines: [],
  connections: [],
  logic: "",
  logicType: "REALTIME",
};
