import React, { useState, useEffect, useCallback } from "react";
import { ASIconButton } from "allegro-ui";
import VisibilityIcon from "@material-ui/icons/Visibility";
import styled from "styled-components";
import useFuchucoData from "../../hooks/useFuchucoData";

interface ILCWatchCellComponentProps {
  data: {
    id: string;
    watch?: boolean;
  };
  options?: {
    onToggle?: (data: any) => Promise<void>;
    initialize?: () => Promise<any>;
  };
}
const ToggleButton = styled.div`
  opacity: 0.3;
  &.enable {
    opacity: 1;
  }
`;

/**
 * WIP - 汎用化
 * @param props
 * @returns
 */
const LCWatchCellComponent: React.FunctionComponent<ILCWatchCellComponentProps> =
  (props) => {
    // const [watch, setWatch] = useState(child.isWatch);
    const { self, toggleWatch } = useFuchucoData({
      childId: props.data.id,
      watch: props.data.watch,
      onToggle: props.options?.onToggle,
      initialize: props.options?.initialize,
    });

    const [watch, setWatch] = useState(props.data.watch === true);

    //@TODO
    const handleWatch = useCallback(
      async () => {
        setWatch(!watch);
        await toggleWatch();
      },
      // [toggleWatch]
      [toggleWatch, watch]
    );

    useEffect(() => {
      if (self.watch !== watch) {
        setWatch(self.watch === true);
      }
    }, [self.watch, watch]);

    return (
      <>
        <ToggleButton className={watch ? "enable" : ""}>
          <ASIconButton kind="white" onClick={() => handleWatch()}>
            <VisibilityIcon fontSize="small" />
          </ASIconButton>
        </ToggleButton>
      </>
    );
  };

export default LCWatchCellComponent;
