import React, { useState } from "react";
import styled from "styled-components";

import { ASFileDnD, ASModal } from "allegro-ui";
import scienceFile from "../../recoils/ScienceFileState";
import { useFileMediator } from "src/opt-editor/hooks/useFileMediator";

const Container = styled.div`
  flex: 1;
  padding: 3px;
  border-radius: ${(props) => props.theme.borderRadius};
  color: #777777;
  width: 100%;
  height: 100%;
`;

const StyledFileDnD = styled(ASFileDnD)`
  width: 100%;
  height: 100%;
  min-height: 150px;
  border: 2px dashed #777777;
`;

const MAX_BYTE_SIZE = 15 * 1000 ** 2;

// defaultはbase64
const encodingDict = {
  text: ["py", "ts", "js", "txt"],
};

const base64Encode = (bytes: Uint8Array) => {
  var binary = "";
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return window.btoa(binary);
};

const convertByteData = (extension: string, bytes: Uint8Array) => {
  if (encodingDict.text.includes(extension)) {
    return new TextDecoder().decode(bytes);
  } else {
    return base64Encode(bytes);
  }
};

export const OEFileDropArea: React.FC = () => {
  const [showFileErrorModal, SetShowFileErrorModal] = useState(false);

  const fileMediator = useFileMediator();

  const uploadFiles = async (files: File[]) => {
    for (const file of files) {
      const fileName = file.name;
      const extension = fileName.split(".").at(-1) ?? "";
      const fileId = scienceFile.getUniqueId();
      file.arrayBuffer().then(async (buf) => {
        if (buf.byteLength <= MAX_BYTE_SIZE) {
          var bytes = new Uint8Array(buf);
          const content = convertByteData(extension, bytes);
          const ret = await fileMediator.add(fileId, fileName, content);
        } else {
          SetShowFileErrorModal(true);
        }
      });
    }
  };

  return (
    <Container>
      <StyledFileDnD
        onDrop={(item: { files: File[] }) => {
          uploadFiles(item.files);
        }}
      />

      <ASModal
        open={showFileErrorModal}
        onClose={() => {
          SetShowFileErrorModal(false);
        }}
      >
        <>ファイルサイズが15MB超えています</>
      </ASModal>
    </Container>
  );
};
