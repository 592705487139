import { ASButton, ASGraph } from "allegro-ui";
import React from "react";
import { LCLoadingBoard } from "src/cheetah/components/libs/ASLowCodeBrowser";

import { useDockerStatsViewer } from "src/xerver-room/hooks/useDockerStatsViewer";
import { Slot } from "src/xerver-room/models";
import styled from "styled-components";

import SyncIcon from "@material-ui/icons/Sync";
import {
  numToPercentage,
  numToBytesSI,
} from "src/xerver-room/utils/parseStats";

const Container = styled.div`
  position: relative;
  padding: 25px;
  width: 70vw;
  height: 75vh;
  overflow-y: scroll;
  > * {
    margin-bottom: 25px;
  }
`;

const ResyncButton = styled(ASButton)`
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  position: absolute;
  right: 25px;
  top: 8px;
`;

type Props = {
  slot?: Slot;
};

export const XRStatsViewer: React.FC<Props> = (props) => {
  const statsViewer = useDockerStatsViewer(props.slot);

  const renderCpuGraph = () => {
    const graphData = statsViewer.getCpuPercentages();
    return (
      <>
        <ASGraph
          title="CPU"
          xType={graphData.length > 0 ? "time" : "linear"}
          disabledXAxis={graphData.length === 0}
          disabledLegend
          yAxisFormat={numToPercentage}
          yAxisMargin={80}
          graphDatas={[
            {
              label: "CPU",
              id: 2,
              color: "primary",
              dataPoints:
                graphData.length > 0 ? graphData : statsViewer.dummyData,
            },
          ]}
          graphHeight={300}
          yRange={[0, 100]}
        />
      </>
    );
  };

  const renderMemoryGraph = () => {
    const graphData = statsViewer.getMemoryPercentages();

    return (
      <>
        <ASGraph
          title="MEM"
          xType={graphData.length > 0 ? "time" : "linear"}
          disabledXAxis={graphData.length === 0}
          disabledLegend
          yAxisMargin={80}
          yAxisFormat={numToPercentage}
          graphDatas={[
            {
              label: "MEM",
              id: 2,
              color: "primary",
              dataPoints:
                graphData.length > 0 ? graphData : statsViewer.dummyData,
            },
          ]}
          graphHeight={300}
          yRange={[0, 100]}
        />
      </>
    );
  };

  const renderDiskGraph = () => {
    const graphData = statsViewer.getDiskUsages();

    return (
      <>
        <ASGraph
          title="DISK IO"
          xType={graphData.length > 0 ? "time" : "linear"}
          disabledXAxis={graphData.length === 0}
          disabledLegend
          yAxisFormat={numToBytesSI}
          yAxisMargin={80}
          graphDatas={[
            {
              label: "Disk",
              id: 2,
              color: "primary",
              dataPoints:
                graphData.length > 0 ? graphData : statsViewer.dummyData,
            },
          ]}
          graphHeight={300}
          yRange={[0, null]}
        />
      </>
    );
  };

  return (
    <Container>
      <ResyncButton
        kind="secondary"
        onClick={() => statsViewer.resyncStatsData()}
      >
        <SyncIcon />
      </ResyncButton>
      {statsViewer.isLoading === false ? (
        <>
          {renderCpuGraph()}
          {renderMemoryGraph()}
          {renderDiskGraph()}
        </>
      ) : (
        <LCLoadingBoard />
      )}
    </Container>
  );
};
