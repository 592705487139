import { NewtQuery, createGroupFromOptimizedData } from "allegro-api";
import { createnAuthAPI } from "src/api";

export const fetchGroupMany = async (props: {
  limit?: number;
  skip?: number;
  matchQ?: { [key: string]: any };
}) => {
  const { limit, skip, matchQ } = props;
  const nauth = createnAuthAPI();

  let newtQuery: NewtQuery = {
    limit: limit,
    skip: skip,
    shape: {
      contents: "any",
      bases: "any",
    },
  };

  if (matchQ) {
    newtQuery.match = matchQ;
  }

  const resData = await nauth.group.dataProvider(newtQuery);
  const groups = resData.map((data) => createGroupFromOptimizedData(data));
  return groups;
};
