import { Chip, FormLabel, Tooltip } from "@material-ui/core";

import { ASInputBase, ASFormLabel } from "allegro-ui";
import { darken, lighten, opacify } from "polished";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  ReferenceData,
  createReference,
  getReferenceValue,
} from "../../models/DynamicReference";
import { ReferenceMenu } from "./ReferenceMenu";

const ChipsContainer = styled.div`
  padding: 8px 0;
  min-height: 50px;
  width: 100%;
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px dotted ${(props) => props.theme.primaryBorderColor};
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  margin-bottom: 8px;
  > * {
    margin-left: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  > *:first-child {
    margin-left: 0;
  }
`;

const PlaceHolder = styled.div`
  padding-left: 8px;
  opacity: 0.6;
`;

interface ILCChipFieldsComponentProps {
  label: string;
  active: string[];
  values: any;
  editable?: boolean;
  onChange: (active: string[]) => void;
}

const StyledChip = styled(Chip)<{ fontSize?: number }>`
  margin: 4px 3px;
  font-size: ${(props) => props.fontSize ?? props.theme.regularFontSizeMd}px;
  background-color: ${(props) =>
    props.theme.darkMode
      ? darken(0.35, props.theme.primaryColor)
      : props.theme.primaryColor};
  color: ${(props) =>
    props.theme.darkMode
      ? props.theme.primaryColor
      : props.theme.primaryOnText};
  border: 1px solid ${(props) => props.theme.primaryColor};
  .MuiChip-deleteIcon {
    color: ${(props) =>
      props.theme.darkMode
        ? opacify(0.9, lighten(0.2, props.theme.primaryColor))
        : props.theme.primaryOnText};
    opacity: 0.3;
  }

  max-width: 250px;
`;

export const ASChipsFormFieldComponent: React.FunctionComponent<ILCChipFieldsComponentProps> =
  ({ onChange, active, ...props }) => {
    const [chips, setChips] = useState<string[]>([]);
    const [selections, setSelections] = useState<ReferenceData[]>([]);
    const anchorRef = useRef(null);

    const editable = props.editable ?? true;
    const values =
      typeof props.values === "string"
        ? createReference(props.values)
        : props.values;

    const [inputText, setInputText] = useState("");

    const [openOptions, setOpenOptions] = useState<boolean>(false);

    //日本語の変換中のエンター判定
    const [composing, setComposition] = useState(false);
    const startComposition = () => setComposition(true);
    const endComposition = () => setComposition(false);

    const handleAddChip = (value: string) => {
      const nextChips = [...chips, value];
      onChange(nextChips);
      setChips(nextChips);
      setInputText("");
    };

    const handleTextChange = async (event: any) => {
      const newText: string = event.target.value;
      if (values) {
        const newOptions = await getReferenceValue(values, newText);

        if (newOptions && newOptions.length > 0) {
          setSelections(newOptions);
          setOpenOptions(true);
        }
      }
    };

    const handleDelete = (chip: string) => {
      if (editable) {
        const filtered = chips.filter((c) => c !== chip);
        setChips(filtered);
        onChange(filtered);
      }
    };

    const init = async () => {
      const selections = await getReferenceValue(values);
      if (selections && selections.length > 0) {
        setSelections(selections);
      }
    };

    useEffect(() => {
      init();
      setChips(active ?? []);
    }, [active]);

    const renderChips = () => {
      return chips.map((chip, id) => {
        return (
          <Tooltip title={chip} placement="bottom" key={`chip-field-${id}`}>
            <StyledChip
              key={`chip-field-${id}`}
              variant="default"
              label={chip}
              aria-readonly={!editable}
              onDelete={() => handleDelete(chip)}
            />
          </Tooltip>
        );
      });
    };

    return (
      <>
        <ChipsContainer>
          <PlaceHolder>{chips.length === 0 ? <>-</> : <></>}</PlaceHolder>
          {renderChips()}
        </ChipsContainer>
        <div ref={anchorRef}>
          <ASInputBase
            placeholder="入力してください"
            kind="secondary"
            fullWidth
            onCompositionStart={startComposition}
            onCompositionEnd={endComposition}
            value={inputText}
            readOnly={!editable}
            onChange={(e) => {
              setInputText(e.target.value);
              handleTextChange(e);
            }}
            onFocus={() => {
              setOpenOptions(true);
            }}
            onBlur={() => {
              setOpenOptions(false);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && inputText !== "") {
                if (composing) return;
                handleAddChip(inputText);
              }
            }}
          />
          <ReferenceMenu
            open={openOptions}
            anchorEl={anchorRef.current}
            options={selections}
            onClick={(value) => {
              handleAddChip(value);
              setOpenOptions(false);
            }}
          />
        </div>
      </>
    );
  };
