import produce from "immer";
import { api } from "src/api/http";
import { getConfig } from "src/fortress/config";
import {
  createFamilyPersonalDataFromJSON,
  initialFamilyPersonalData,
  FamilyPersonalData,
} from "../models/FamilyPersonalData";

//児童詳細の家族ページの家族リストのデータ取得する。

export const getFamilyList = async (setai: string | number): Promise<any> => {
  const config = getConfig(sessionStorage.contractorNo);

  try {
    const res = await api.get(
      `/contractors/${sessionStorage.contractorNo}/users/${sessionStorage.userNoText}/topdatas`,
      {
        params: {
          q: {
            select: ["*"],
            where: {
              ...config.defaultQueries.fetch.familyDatas.where,
              "contents.optdata.numeric_household_number": Number(setai),
            },
            orderby: { "contents.optdata.numeric_birth_date": -1 },
          },
          options: { count: true },
        },
      }
    );
    console.log(res);

    return [
      Array.isArray(res.data) ? res.data : [],
      res.headers["x-total-count"] ?? 0,
    ];
  } catch (err) {
    console.error(err);
    return [[], 0];
  }
};

//児童一覧の家族ページの詳細データを取得する。

export const familyPersonalData = async (
  kind: string,
  assetTags: string[],
  primaryKeyVal: any
): Promise<FamilyPersonalData[]> => {
  try {
    const tagsQuery: any[] = assetTags.map((str) => {
      return { "bases.tags": str };
    });
    const res = await api.get(
      `/contractors/${sessionStorage.contractorNo}/users/${sessionStorage.userNoText}/topdatas`,
      {
        params: {
          q: {
            select: ["*"],
            where: {
              $and: tagsQuery.concat([primaryKeyVal]),
            },
          },
        },
      }
    );

    let result: FamilyPersonalData[] = [];
    let i: number = 1;

    if (res.data.length === 1) {
      result = res.data.map((d: any) => {
        const personalData = createFamilyPersonalDataFromJSON(d);
        // personalData.fileName = kind;

        return produce(personalData, (draft) => {
          draft.label = kind;
        });
      });
    } else if (res.data.length > 1) {
      res.data.forEach((d: any) => {
        const personalData = createFamilyPersonalDataFromJSON(d);

        const ps = produce(personalData, (draft) => {
          draft.label = `${kind}(${i})`;
        });

        result.push(ps);

        i++;
      });
    } else {
      result = [{ ...initialFamilyPersonalData, label: kind }];
    }
    return result;
  } catch (err) {
    console.error(err);
    return [{ ...initialFamilyPersonalData, label: kind }];
  }
};
