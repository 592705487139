import * as React from "react";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { ASButton } from "allegro-ui";
import { shapeToSeireki } from "src/fortress/utils/time";
import { parsePersonalDataVelue } from "src/fortress/utils/dataShape";

const Tr = styled.tr`
  /* padding: 24px; */
`;

const Td = styled.td`
  padding: 12px 24px;
  background: ${(props) => props.theme.cardColor};

  &:first-child {
    padding-left: 24px;
    border-top-left-radius: ${(props) => props.theme.borderRadius}px;
    border-bottom-left-radius: ${(props) => props.theme.borderRadius}px;
  }
  &:last-child {
    padding-right: 24px;
    border-top-right-radius: ${(props) => props.theme.borderRadius}px;
    border-bottom-right-radius: ${(props) => props.theme.borderRadius}px;
  }
`;

const TdCenter = styled(Td)`
  text-align: center;
`;

const TdIcon = styled(Td)`
  text-align: right;
`;

const TdBold = styled(Td)`
  font-weight: 700;
`;

const ChildTd = styled.td`
  padding: 8px 8px;
`;

const ChildTdBold = styled(ChildTd)`
  font-weight: 700;
`;

const ChildTr = styled.tr`
  border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};
  &:last-child {
    border: none;
  }
`;

const TdRight = styled(ChildTd)`
  text-align: right;
`;

const Table = styled.table`
  width: 100%;
`;

const TBody = styled.tbody`
  width: 100%;

  &::before {
    content: "";
    display: block;
    height: 8px;
  }
`;

const DisabledTB = styled(TBody)`
  opacity: 0.4;
`;

export interface IFT3ColJsonCardProps {
  title: string;
  rawdata?: { [key: string]: string | number };
  onClick?: any;
}

/**
 *
 * @param props
 * @returns
 */
export function FT3ColJsonCard(props: IFT3ColJsonCardProps) {
  const { rawdata } = props;
  if (rawdata) {
    const middleColData = Object.keys(rawdata)
      .filter((data, id) => {
        return id % 2 === 0;
      })
      .map((key) => {
        return { key: key, value: rawdata[key] };
      });
    const rightColData = Object.keys(rawdata)
      .filter((data, id) => {
        return id % 2 !== 0;
      })
      .map((key) => {
        return { key: key, value: rawdata[key] };
      });

    return (
      <TBody>
        <Tr>
          <TdBold>{props.title}</TdBold>
          <Td>
            <Table>
              <tbody>
                {middleColData.map((col, id) => {
                  return (
                    <ChildTr key={`ft3coljsoncard-childtr-1-${id}`}>
                      <ChildTdBold>{col.key}</ChildTdBold>
                      <TdRight>
                        {parsePersonalDataVelue(col.value, props.title)}
                      </TdRight>
                    </ChildTr>
                  );
                })}
              </tbody>
            </Table>
          </Td>
          <Td>
            <Table>
              <tbody>
                {rightColData.map((col, id) => {
                  return (
                    <ChildTr key={`ft3coljsoncard-childtr-2-${id}`}>
                      <ChildTdBold>{col.key}</ChildTdBold>
                      <TdRight>
                        {parsePersonalDataVelue(col.value, props.title)}
                      </TdRight>
                    </ChildTr>
                  );
                })}
              </tbody>
            </Table>
          </Td>
          {props.onClick ? (
            <>
              <TdIcon>
                <ASButton onClick={() => props.onClick()}>
                  <OpenInNewIcon />
                </ASButton>
              </TdIcon>
            </>
          ) : (
            <></>
          )}
        </Tr>
      </TBody>
    );
  } else {
    return (
      <DisabledTB>
        <Tr>
          <TdBold>{props.title}</TdBold>

          <TdCenter colSpan={2}>データがありません</TdCenter>
          <TdIcon>
            <ASButton disabled>
              <OpenInNewIcon />
            </ASButton>
          </TdIcon>
        </Tr>
      </DisabledTB>
    );
  }
}
