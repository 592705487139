import { AxiosInstance } from "axios";
import { wrapAsync } from "../../libs/wrapper";

type AlertJob = {
  alertJobName: string;
  collectionType: "Cold" | "Hot" | "Warm";
  collection: string;
  tags: string[];
  script: string;
  executer: string;
  cron: string;
  enable: boolean;
  alertJobId: string;
  monitorId?: string;
};

type IAlertJob = Omit<AlertJob, "alertJobId">;

export const AlertJob = (instance: AxiosInstance, version?: number) => {
  const create = async (input: IAlertJob) => {
    await instance.post("/newtzero/alert/job", input);
  };

  const update = async (input: AlertJob) => {
    await instance.put(`/newtzero/alert/job/${input.alertJobId}`, input);
  };

  const remove = async () => {};

  const fetchOne = async () => {
    try {
      const res = await instance.post(`/newtzero/alert/job/dataprovider`, {});
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMany = async () => {
    try {
    } catch (error) {}
  };

  const fetchByMonitorId = async (montiorId: string) => {
    try {
      const res = await instance.post(`/newtzero/alert/job/dataprovider`, {
        shape: {
          contents: "any",
        },
        search: {
          "contents.rawdata.monitorId": montiorId,
        },
        order: {
          "bases.modified": -1,
        },
      });
      if (res.data.message.length >= 2) {
        console.warn("monitorIDに紐づくアラートジョブが複数見つかりました。");
      }
      if (res.status === 200 && res.data.message.length >= 0) {
        return res.data.message[0];
      } else {
        console.error("not foud alertJob.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    create,
    update,
    remove,
    fetchOne,
    fetchMany,
    fetchByMonitorId,
  };
};
