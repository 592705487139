import { medicanAPIConfig } from "./config";

export const registApproval = async (data: {
  answerId: string;
  subjectId: string;
  formId: string;
  approval: boolean;
}) => {
  const res = await medicanAPIConfig.post(
    `/subject/${data.subjectId}/approval`,
    data,
    {
      params: {
        apikey: sessionStorage.token,
      },
    }
  );

  return res;
};
