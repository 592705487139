import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import styled from "styled-components";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useRecoilState } from "recoil";
import { sortFilterState } from "../../../recoils/atoms/filterState";
import { useForm } from "react-hook-form";

interface ILCSortComponentProps {
  name: string;
}
const CategoryLabel = styled.h2`
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 6px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  > * {
    line-height: 1.2;
  }

  .MuiFormControlLabel-label {
    margin-left: 8px;
    font-size: 14px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
  }

  margin-bottom: 4px;
`;

const StyledCheckBox = styled(Radio)`
  color: ${(props) => props.theme.tertiaryBorderColor};
  &.Mui-checked {
    color: ${(props) => props.theme.primaryColor};
  }
  &.MuiRadio-root {
    padding: 1px;
    margin-left: 8px;
  }
`;

// const sorts: SortUIConf[] = [
//   {
//     label: "生年月日:昇順",
//     design: "radio",
//     callback: (value: string) => {
//       return {
//         "contents.optdata.numeric_birth_date": value === "昇順" ? 1 : -1,
//       };
//     },
//   },
// ];

const sortSettings = ["生年月日:昇順", "生年月日:降順"];
const LCSortComponent: React.FunctionComponent<ILCSortComponentProps> = (
  props
) => {
  const [cursorSortId, setCursorSortId] = useState(sortSettings[0]);
  const [sortQuery, setSortQuery] = useRecoilState(sortFilterState(props.name));

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const handleChange = (e: any) => {
    setCursorSortId(e.target.name);
  };

  useEffect(() => {
    switch (cursorSortId) {
      case "生年月日:降順":
        setSortQuery({
          "contents.rawdata.birth": 1,
          // ...sortQuery,
        });
        break;
      case "生年月日:昇順":
        setSortQuery({
          "contents.rawdata.birth": -1,
          // ...sortQuery,
        });
        break;
    }

    // setSortQuery({...sortQuery, })
  }, [cursorSortId, setSortQuery]);

  return (
    <FormControl component="fieldset">
      <FormGroup>
        <CategoryLabel>並び順</CategoryLabel>
        <StyledFormControlLabel
          control={
            <StyledCheckBox
              checked={sortSettings[0] === cursorSortId}
              onChange={handleChange}
              name={sortSettings[0]}
            />
          }
          label={sortSettings[0]}
        />
        <StyledFormControlLabel
          control={
            <StyledCheckBox
              checked={sortSettings[1] === cursorSortId}
              onChange={handleChange}
              name={sortSettings[1]}
            />
          }
          label={sortSettings[1]}
        />
      </FormGroup>
    </FormControl>
  );
};

export default LCSortComponent;
