import React from "react";
import styled from "styled-components";
import { ASButton } from "allegro-ui";
import { useNavigate } from "react-router-dom";
import { PTSignUpPageTemplate } from "src/portal/components/templates/PTSignUpPageTemplate";
import { PTLoginForm } from "src/portal/components/molecules/PTLoginForm";

import CHTermsAndPrivacyCaption from "src/cheetah/components/molecules/CHTermsAndPrivacyCaption";

const SignUpNote = styled.div`
  color: ${(props) => props.theme.textColor};
  padding: 23px 0;
  &::before {
    top: 9px;
    /* border-bottom: 1px solid #fff; */
    position: absolute;
    left: 0;
    width: 100%;
    content: "";
  }
`;

const SignUpRegister = styled.div`
  padding: 0 64px;
`;

type Props = {
  onSubmit: (email: string, password: string, contractor: string) => void;
};

/**
 *
 * @deprecated this component will be removed. use CHLoginPage component instead of this.
 */
export const FTLoginPageTemplate: React.FC<Props> = ({
  children,
  onSubmit,
}) => {
  // const [email, setEmail] = useState(sessionStorage.getItem("userId") || "");
  // const [password, setPassword] = useState("");
  const history = useNavigate();

  return (
    <>
      <PTSignUpPageTemplate
        formTitle="ログイン"
        formContent={<PTLoginForm onSubmit={onSubmit} />}
      >
        <CHTermsAndPrivacyCaption />

        <div>
          <SignUpNote>Newtroidへの登録がまだの方は</SignUpNote>
          <SignUpRegister>
            <ASButton
              kind="secondary"
              size="large"
              fullWidth
              onClick={() => {
                history("/signup");
              }}
            >
              会員登録
            </ASButton>
          </SignUpRegister>
        </div>
      </PTSignUpPageTemplate>
    </>
  );
};
