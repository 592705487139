import React from "react";
import styled from "styled-components";

const Block = styled.div`
  max-height: 80vh;
  overflow: scroll;
  padding: 16px;
  line-height: 1.5;
`;

const H1 = styled.h1`
  font-size: 24px;
  font-weight: bold;
  line-height: 2.5;
`;

const H2 = styled.h2`
  margin-top: 24px;
  font-size: 18px;
  font-weight: bold;
  line-height: 2.2;
`;

type Props = {};

export function NTNewtroidTermsOfUse(props: Props) {
  return (
    <Block>
      <H1>Newtroidサービス利用規約</H1>
      アレグロスマート株式会社（以下「弊社」といいます）は、Newtroidサービス利用規約（以下「本規約」といいます）を以下の通り定めます。
      <H2>第１条 (定義)</H2>
      <p>
        本規約における用語を以下の通り定義します。
        <br />
        「本サービス」とは、弊社が提供するマイクロサービスオーケストレイションサービスをいいます。
      </p>
      <H2>第２条 （本規約）</H2>
      <p>
        利用者は、本規約、並びに、その他本サービスに関する諸規定（本則以外の規定を総称して以下「その他個別規定」といいます）に従って本サービスを利用するものとします。
      </p>
      <H2>第３条 (本サービス)</H2>
      <p>
        弊社は、契約者に対してコンテナ構築ならびにマイクロサービス構築および管理機能、マイクロサービスオーケストレイション機能、ネットワーク管理機能、等を
        SaaS（ソフトウェア　アズ　ア　サービス）として提供します。本サービスの内容は別紙に定める通りとします。
        本サービスの利用契約は、弊社が定める手続に従い本サービスへの申し込みをなし、弊社が当該申込者（以下「申込者」といいます）を本サービスの契約者として登録したうえで、申し込みを受け付けた旨を何れかの連絡方法（電子メール、FAX、電話等)にて申込者に通知した時点をもって成立するものとします。
        弊社は、次の場合には、本サービスの申し込みを承諾しないことがございます。
      </p>
      <ul>
        <li>（1） 申込書に虚偽の事実の記載があったとき</li>
        <li>
          （2）申込者が、申し込み以前に、本規約、その他個別規定の違反等により、本サービス利用の一時停止、強制解約処分、その他の利用資格の取り消しを受けている場合
        </li>
        <li>
          （3）申込者が過去に、本サービスの利用に際し、料金等の未納または滞納をした場合
        </li>
        <li>
          （4）申込者が利用料金、本サービスの利用に必要な費用の支払いを現に怠り、または怠るおそれがあると弊社が判断したとき
        </li>
        <li>（5） 申込者が申し込みにあたり提出した申込書に不備があるとき</li>
        <li>
          （6）第２１条（利用者の義務等）の規定に違反するおそれがあると弊社が判断したとき
        </li>
        <li>（7） 本サービスの提供が技術上または経済上著しく困難なとき</li>
        <li>
          （8）その他本サービスに関する弊社の業務の遂行上支障があるとき、またはそのおそれがあると弊社が判断したとき
        </li>
        <li>
          （9）申込者が連絡用に登録したメールアドレス等の連絡先に、弊社からの通知が到達しなかったことが判明した場合
        </li>
        <li>（10） その他弊社が不適当と判断したとき</li>
      </ul>
      <p>
        申込者は、本サービスの提供内容を変更しようとするときは、あらかじめ本サービスの取扱パートナーに弊社所定の方法により申し出ていただきます。
        申込者は、本サービスを解約しようとするときは、あらかじめ本サービスの取扱パートナーに弊社所定の方法により申し出ていただきます。
        前項の規定により申し出た解約希望日をもって本サービスの解約日とします。
      </p>
      <H2>第４条 （利用者の地位の承継）</H2>
      <p>
        法人の合併若しくは分割により、契約者の地位の承継があったときは、契約者の地位を承継した法人は、弊社所定の書面にこれを証明する書類を添えて、弊社に届け出ていただきます。
        前項の場合に、地位を承継した者が２人以上あるときは、そのうちの１人を弊社に対する代表者と定め、これを届け出ていただきます。これを変更したときも同様とします。
        弊社は、前項の規定による代表者の届出があるまでの間、その地位を承継した者のうちの１人を代表者として取り扱います。
      </p>
      <H2>第５条 （利用者の氏名等の変更）</H2>
      <p>
        契約者は、その氏名、名称または住所若しくは居所に変更があったときは、そのことを速やかに弊社に届け出ていただきます。
        前項の届出があったときは、弊社は、その届出があった事実を証明する書類を添付していただくことがございます。
      </p>
      <H2>第６条 （本サービスの利用権の譲渡）</H2>
      <p>
        本サービスの提供を受ける権利（以下「本サービス利用権」といいます）を譲渡する場合は、弊社または、弊社および弊社提携先の承認が必要となります。
        本サービス利用権の譲渡の承認を受けようとするときは、当事者が連署した弊社所定の書面により、弊社に請求していただきます。但し、譲渡があったことを証明できる書類の添付をもって連署に代えることができます。
        本サービス利用権の譲渡があったときは、譲受人は、譲渡人の有していた本サービスの利用契約に係る一切の権利及び義務を、当該譲渡の効力が生じた日以降承継します。
        第７条 （その他の契約内容の変更）
        弊社は、契約者から請求があり（前三条に定める変更を含みます）、弊社が承諾したときは、本サービスの申込内容の変更を行います。
        弊社は、前項の請求があったときは、本利用規約に準じて取り扱います。
      </p>
      <H2>第８条（料金）</H2>
      <p>
        契約者は、本サービスにかかる構築費用（以下「構築費用」といいます）および本サービスのライセンス料金（以下「本サービス利用料金」といいます。詳細は別紙に定めます。）を別途弊社が定める方法により請求書記載の支払期限までに弊社に支払うものとします。なお、料金は消費税相当額を含まない額(以下「税抜額」といいます。)
        で料金を定めます。
        弊社は、構築費用および本サービスの利用料金について諸般の事情により変更することができるものとします。
        契約者は、以下に定める手続きに関する事務手数料を別途弊社が定める方法により弊社に支払うものとします。なお、料金は税抜額で料金を定めるものとします。なお、料金は税抜額で料金を定めます。
      </p>
      <H2>第９条 (責任の制限)</H2>
      <p>
        弊社の責に帰すべき事由により、その提供をしなかったときは、本サービスが全く利用できない状態にあることを当社が知った時から起算して、24時間以上その状態が連続したときに限り、その契約者の損害を本項及び次項に定める範囲内で賠償します。また、当社は、本サービスの提供により契約者に損害が生じた場合、当該損害発生の直接の原因である本サービスに係る料金を上限として、契約者に損害賠償責任
        を負うものとします。また、以下の各号に該当する損害については、当社は一切責任を負いません。
      </p>
      <ul>
        <li>（1） 契約者が本サービスの利用により第三者に対して与えた損害。</li>
        <li>（2）当社の責めに帰することのできない事由から生じた損害。</li>
        <li>（3）当社の予見の有無を問わず特別の事情から生じた損害。</li>
        <li>
          （4）逸失利益及び第三者からの損害賠償請求に基づいて発生した契約者の損害。
        </li>
      </ul>
      <p>
        弊社の故意又は重大な過失により本サービスの提供をしなかったときには、前2項の規定は適用しません。
      </p>
      <H2>第10条 （ソフトウェアの複製等禁止）</H2>
      <p>
        利用者は本サービスの全部、または一部を構成するソフトウェア製品（以下「ソフトウェア」といいます）に関し、当該ソフトウェアメーカー指定のライセンス契約書の規定を遵守するものとします。
        利用者はソフトウェアに関し、次の行為を行うことはできません。
      </p>
      <ul>
        <li>
          （1）有償、無償を問わずソフトウェアを第三者へ譲渡し、または、その再使用権設定を行うこと
        </li>
        <li>（2）ソフトウェアを本サービス以外に利用すること</li>
        <li>（3）ソフトウェアを複製すること</li>
        <li>（4）ソフトウェアを改変すること</li>
      </ul>
      <H2>第11条（利用中止）</H2>
      <p>
        弊社は、次のいずれかに該当する場合には、何らの責任も負うことなく、本サービスの全部または一部の利用を中止することがございます。
      </p>
      <ul>
        <li>
          （1）弊社または提携先のシステム、データセンター、クラウドサービス等の保守または工事を行う場合
        </li>
        <li>
          （2）天災、事変その他非常事態が発生しまたは発生するおそれがあり、本サービス提供のための運営ができなくなった場合
        </li>
        <li>
          （3）弊社が、運営上、技術上その他の理由で本サービスの利用中止が必要と判断した場合弊社は、前項の規定により本サービスの利用を中止するときは、予めその旨を利用者に通知するものとします。但し、緊急でやむを得ない場合は、この限りではございません。
        </li>
      </ul>
      <H2>第12条 （利用停止）</H2>
      <p>
        弊社は、利用者が次のいずれかに該当する場合には、何らの責任も負うことなく、当該利用者による本サービスの全部または一部の利用を停止することがございます。
      </p>
      <ul>
        <li>（1）弊社に虚偽の事項を通知したことが判明した場合</li>
        <li>
          （2）利用者が支払方法を弊社所定の期間内に弊社に通知しなかった場合
        </li>
        <li>（3）利用者が利用料金等を支払わない場合</li>
        <li>（4）第17条（利用者の義務等）に違反した場合</li>
        <li>
          （5）弊社および弊社の提携先の問い合わせ窓口等へ、正当な事由もなく長時間の電話をしたり、同様の繰り返し電話を過度に行ったり、または不当な義務等を強要したり、威嚇等をもって嫌がらせ、恐喝もしくは脅迫に類する行為をしたりすることで、弊社または弊社の提携先の業務に著しく支障をきたした場合
        </li>
        <li>
          （6）前各号のほか本規約上の義務を現に怠りまたは怠るおそれがある場合
        </li>
        <li>（7）弊社および弊社提携先の名誉若しくは信用を毀損した場合</li>
        <li>（8）弊社および弊社提携先に損害を与えた場合</li>
      </ul>
      <p>
        利用者が複数の本サービスの利用契約を締結している場合において、締結している利用契約のうちのいずれかについて本条第1項の規定により本サービスの利用を停止されたときは、弊社は、当該利用者が締結する他のすべての本サービスの利用契約においても利用停止することができるものとします。
        前二項の場合において、本サービスの利用が停止された時点で発生していた債務および発生することが確定していた債務について、本サービスの利用停止があった後においてもその債務が履行されるまで消滅しないものとします。
      </p>
      <H2>第13条 （契約の解除）</H2>
      <p>
        弊社は、第12条（利用停止）の規定により本サービスの利用停止を受けた利用者が弊社から期間を定めた催告を受けたにもかかわらず、なおその事由が解消されない場合または利用契約成立後に、利用者が第３条(本サービス)第4項に該当することが判明した場合に弊社所定の方法により通知することにより、当該利用契約を解除することができるものとします。
        弊社は、利用者が第12条（利用停止）第1項各号所定の事由に該当し、弊社の業務の遂行に支障があると判断した場合、当該利用契約を解除することができるものとします。
        前四項の規定により利用契約が解除された場合、利用者は本サービスの利用に係わる一切の債務につき当然に期限の利益を喪失し、弊社に対し残存債務の全額を直ちに支払わなければならないものとします。
        弊社は、弊社と利用者が合意の上決定した、本サービスの利用開始予定日の属する月の翌月末日を過ぎても、利用者の都合によって本サービスの利用が開始されない場合、当該利用契約を解除することができるものとします。
        前六項の規定により利用契約が解除された場合、初年度のサービス利用料金および設置料金を弊社が定める期日までに一括して支払うものとします。
      </p>
      <H2>第14条 （サービスの休廃止）</H2>
      <p>
        弊社は、本サービスの全部または一部を休廃止することがございます。
        弊社は、前項の規定により本サービスの全てを廃止するときは、相当な期間を定めて事前に利用者に告知するものとします。
      </p>
      <H2>第15条 （サービスの追加・変更）</H2>
      <p>
        弊社は、本サービスの全部または一部を変更、追加することがございます。この場合、第16条（規約の変更）の規定を準用します。
        弊社は、前項による本サービスの全部または一部の変更、追加につき、何ら責任を負うものではありません。
      </p>
      <H2>第16条 （規約の変更）</H2>
      <p>
        弊社は、本規約を随時変更または廃止することがございます。その場合には、利用者の利用条件その他利用契約の内容は、変更後の新たな本規約の内容が適用されます。
        弊社は、前項の変更を行う場合には、WEBサイト上での掲載またはその他の弊社が適切と判断する方法にて変更後の本規約の内容を利用者に通知します。
      </p>
      <H2>第17条 （利用者の義務等）</H2>
      <p>
        利用者は、本サービスを利用するにあたり、次の各号に定める行為をしないものと致します。
      </p>
      <ul>
        <li>（1）本サービスにより利用し得る情報を改ざんしまたは消去する行為</li>
        <li>
          （2）他の利用者のID等を不正に取得もしくは使用し、または他の利用者もしくは自己のID等を不正に他の利用者もしくは第三者に使用させる行為
        </li>
        <li>（3）事前の弊社の承諾なく、本サービスを第三者に利用させる行為</li>
        <li>（4）本規約に違反する不正使用行為</li>
        <li>
          （5）利用者もしくは第三者の設備等または本サービス用設備に過大な負荷を生じさせる行為、その他その使用または運営に支障を与える行為、または与えるおそれのある行為
        </li>
        <li>（6）その他本サービスの運営を妨げるような行為</li>
        <li>
          （7）その他前各号に該当するおそれのある行為、またはこれに類する行為
        </li>
      </ul>
      <p>
        利用者は、本サービスの利用およびその結果につき、自ら一切の責任を負うものとします。
      </p>
      <H2>第18条 （遅延利息）</H2>
      <p>
        利用者は、金銭債務の履行を遅延した場合、支払期日の翌日より完済に至るまで年率14.6％の遅延利息を弊社に支払うものとします。
      </p>
      <H2>第19条 （不可抗力）</H2>
      <p>
        天災地変、戦争、内乱、法令制度改廃、公権力による命令処分、労働争議、交通機関の事故、その他弊社の責に帰することのできない事由に起因する弊社の履行遅延または履行不能については、弊社は何らの責任も負わないものとします。
        前項の場合、弊社は利用者に対し通知のうえ、本規約の全部または一部を変更または解除することができるものとします。
      </p>
      <H2>第20条 （利用者の通知義務）</H2>
      <p>
        利用者は、本サービスの利用について権利を主張する者があるときは、遅滞なく、これを弊社に通知しなければならないものとします。
      </p>
      <H2>第21条 （反社会的勢力の排除）</H2>
      <p>
        利用者は、現在、自ら(役員および従業員を含みます。以下本条において同じ)が、暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者（以下「暴力団員等」といいます）に該当しないこと、および次の各号のいずれにも該当しないことを表明し、かつ、将来にわたっても該当しないことを確約するものとします。
      </p>
      <ul>
        <li>（1）暴力団員等が経営を支配していると認められる関係を有すること</li>
        <li>
          （2）暴力団員等が経営に実質的に関与していると認められる関係を有すること
        </li>
        <li>
          （3）自己、自社もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること
        </li>
        <li>
          （4）暴力団員等に対して資金等を提供し、または便宜を供与するなどの関与をしていると認められる関係を有すること
        </li>
        <li>
          （5）役員または経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること
        </li>
      </ul>
      <p>
        利用者は、自らまたは第三者を利用して次の各号の一つにでも該当する行為を行わないことを確約するものとします。
      </p>
      <ul>
        <li>（1）暴力的な要求行為</li>
        <li>（2）法的な責任を超えた不当な要求行為</li>
        <li>（3）取引に関して、脅迫的な言動をし、または暴力を用いる行為 </li>
        <li>
          （4）風説を流布し、偽計を用いまたは威力を用いて弊社の信用を毀損し、または弊社の業務を妨害する行為
        </li>
        <li>（5）その他、前各号に準ずる行為 第22条 （機密保持義務）</li>
      </ul>
      <p>
        利用者と弊社は、本規約に関連して知り得た相手方の技術上、営業上その他の業務上の既知の事実ではない情報（以下「機密情報」といいます）を、個別契約期間中、および個別契約終了後３年間（但し、個人情報は無期限とします）、相手方の書面による承諾がない限り、第三者に開示もしくは漏洩してはならないものとします。
        利用者と弊社は、前項の機密情報を本規約の目的の範囲内でのみ使用するものとします。
      </p>
      <H2>第23条 （準拠法及び合意管轄）</H2>
      <p>
        本規約は日本国法を準拠法とし、本規約に関する訴訟については広島地方裁判所または広島簡易裁判所を第一審の専属的合意管轄裁判所とものとします。
      </p>
      <H2>第24条 （協議）</H2>
      <p>
        本規約において疑義が生じた場合、利用者と弊社の双方にて協議の上、合意したところに従って解決するものとします。
      </p>
      <H2>第25条 （消費税等の負担）</H2>
      <p>
        本規約に定める料金の支払いを要するものとされている額は、別紙に定める項目毎の税抜額に契約期間の消費税相当額を加算した金額を支払うものとします。なお、消費税相当額が変更された場合には、利用者はその差額分を弊社に支払うものとします。
      </p>
      <H2>第26条 （存続条項）</H2>
      <p>
        本規約がいかなる事由により終了した場合においても、第９条(責任の制限)、第17条（利用者の義務等）、第19条（不可抗力）、第22条（機密保持義務）、第23条（準拠法及び合意管轄）及び本条は引き続き有効とします。
      </p>
    </Block>
  );
}
