import { produce } from "immer";

export type DailyThumb = {
  startDate: Date;
  endDate: Date;
  userId: string;
  pipelineId?: string;
  nodeId?: string;
  dataCount: number | null;
  created: Date | null;
  modified: Date | null;
};

export const InitialDailyThumb: DailyThumb = {
  startDate: new Date(),
  endDate: new Date(),
  userId: "undefined",
  pipelineId: "undefined",
  nodeId: "undefined",
  dataCount: null,
  created: null,
  modified: null,
};

export const createEmptyDailyThumb = (timestamp: number): DailyThumb => {
  return produce(InitialDailyThumb, (draft) => {
    draft.created = new Date(timestamp);
    draft.modified = new Date(timestamp);
  });
};

export const createDailyThumbFromTopDataRes = (props: {
  startDate: Date;
  endDate: Date;
  resData: any;
  count: number;
}): DailyThumb => {
  const { startDate, endDate, resData, count } = props;
  const result: DailyThumb = produce(InitialDailyThumb, (draft) => {
    draft.startDate = startDate;
    draft.endDate = endDate;
    draft.dataCount = count;
    draft.created = new Date(resData._created);
    draft.modified = new Date(resData._modified);
  });
  return result;
};
