import React from "react";
import styled from "styled-components";

// icons
import HelpIcon from "@material-ui/icons/Help";

type Props = {};

const Layout = styled.div`
  height: 50px;
  width: 180px;
  color: white;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  /* border-radius: 180px; */
  padding: 1px 8px 1px 8px;
  align-items: center;
`;

const IconDiv = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* background-color: green; // TODO */
  margin-left: 4px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OEHelpButton: React.FC<Props> = (props) => {
  return (
    <Layout>
      <IconDiv>
        <HelpIcon />
      </IconDiv>
      ヘルプと始め方
    </Layout>
  );
};
