import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { ASButton } from "allegro-ui";
import { ASRadioFormField } from "allegro-low-code-components";
import { useForm } from "react-hook-form";
import { useLinkObjectList } from "src/xerver-room/hooks/useLinkObjectList";
import { useRecoilValue } from "recoil";
import { cursorPipelineIdState } from "src/xerver-room/recoils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  width: 400px;
  padding: 24px;
  h1 {
    font-size: 32px;
    font-weight: bold;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  onSubmit: (formdata: { slotName: string }) => void;
};

export const XRCreateSlotModal: React.FC<Props> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [slotName, setslotName] = useState("");
  const { onSubmit: addSlot } = props;

  const cursorPipelineId = useRecoilValue(cursorPipelineIdState);

  const { handleSubmit, control } = useForm();
  const linkObjectHandler = useLinkObjectList();

  const handleCreate = useCallback(
    async (values: any) => {
      console.log({ values });
      switch (values.createType) {
        case "ContainerSlot":
          await addSlot({ slotName: slotName });
          break;
        case "LinkObject":
          await linkObjectHandler.add(cursorPipelineId);
          break;
      }
      window.location.reload();
    },
    [cursorPipelineId, linkObjectHandler, addSlot, slotName]
  );

  return (
    <Container>
      <h1>Create New</h1>

      <form
        onSubmit={handleSubmit(async (values: any) => {
          handleCreate(values);
        })}
      >
        <ASRadioFormField
          name={"createType"}
          id={"0"}
          required
          error={""}
          label={"追加する要素"}
          control={control}
          defaultValue="ContainerSlot"
          values={["ContainerSlot", "LinkObject"]}
          supportText="ContainerSlot...コンテナを起動する領域を追加します。 LinkObject...接続したContainerSlotにURL等を供給するオブジェクトを追加します。"
          type={"radio"}
          design="digicho"
        />

        <ButtonGroup>
          <ASButton
            kind={"primary"}
            type="submit"
            // onClick={() => onSubmit({ slotName: slotName })}
          >
            Create
          </ASButton>
        </ButtonGroup>
      </form>
    </Container>
  );
};
