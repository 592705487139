import { atom, selector, atomFamily } from "recoil";
import { api } from "src/api";
import { authState } from "src/root/recoils/userState";
import { createPipeline, defaultPipeline, PipelineX } from "../models";
import { syncDBEffect } from "../effects/pipelineEffect";

export const cursorPipelineIdState = atom({
  key: "CursorPipelineId",
  default: "",
});

export const pipelineState = atomFamily<PipelineX, string>({
  key: "pipelineState",
  default: defaultPipeline,
  effects: (pipelineId: string) => [syncDBEffect(pipelineId)],
});

export const pipelineSearchWordState = atom({
  key: "pipelineSearchWordState",
  default: "",
});

export const pipelineListState = selector<Array<PipelineX>>({
  key: "PipelineList",
  get: async ({ get }) => {
    const auth = get(authState);
    get(pipelineListRequestId);
    const searchWord = get(pipelineSearchWordState);
    const contractor: string =
      typeof auth.contractorNo === "string" ? auth.contractorNo : "";
    const apiKey: string = typeof auth.token === "string" ? auth.token : "";
    const res = await api({
      method: "GET",
      url: `/contractors/${contractor}/pipelinex?apikey=${apiKey}`,
      params: {
        apikey: apiKey,
        q: {
          where: {
            name: { $regex: searchWord, $options: "i" },
          },
          orderby: { name: 1 },
        },
      },
    });
    const nextList: Array<PipelineX> = res.data.map((data: any) => {
      return createPipeline(data);
    });
    return nextList;
  },
});

export const cursorPipelineState = selector<PipelineX | null>({
  key: "CursorPipeline",
  get: async ({ get }) => {
    const auth = get(authState);
    const pipelineId = get(cursorPipelineIdState);
    const contractor: string =
      typeof auth.contractorNo === "string" ? auth.contractorNo : "";
    const apiKey: string = typeof auth.token === "string" ? auth.token : "";

    const res = await api({
      method: "GET",
      url: `/contractors/${contractor}/pipelinex?apikey=${apiKey}`,
      params: {
        apikey: apiKey,
        q: {
          where: {
            id: pipelineId,
          },
        },
      },
    });
    const nextPipeline = createPipeline(res.data[0]);
    return nextPipeline;
  },
});

export const pipelineListRequestId = atom({
  key: "PipelineListRequestId",
  default: 0,
});
