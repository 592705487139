//Libs
import React from "react";
import styled from "styled-components";

//Components
import { OERightPanel, OELeftPanel } from "../organisms";
import { ASLoadingCircle } from "allegro-ui";
import { LoadingBox } from "../atoms/OELoddingBox";
import { headerOffset } from "src/allegrobot/components/organisms/ARGlobalNav";

//States
import { useNotify } from "src/opt-editor/contexts";

const Body = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.bgPrimaryColor};
  min-height: calc(100vh - ${() => headerOffset}px);
  color: #fff;
`;

const Section = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const CenterBox = styled.div`
  margin: 0px;
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

//Types
type Props = {
  leftPanelShow?: boolean;
  rightPanelShow?: boolean;
};

export const OEPageTemplate: React.FC<Props> = (props) => {
  const { leftPanelShow, rightPanelShow, children } = props;
  const notify = useNotify();

  return (
    <>
      <Body>
        <ASLoadingCircle isLoading={notify.isLoading} />
        <Section>
          <React.Suspense fallback={<LoadingBox />}>
            <OELeftPanel show={leftPanelShow ?? false} />
          </React.Suspense>
          <CenterBox>
            <React.Suspense fallback={<LoadingBox />}>
              {children}
            </React.Suspense>
          </CenterBox>
          <React.Suspense fallback={<LoadingBox />}>
            <OERightPanel show={rightPanelShow ?? false} />
          </React.Suspense>
        </Section>
      </Body>
    </>
  );
};
