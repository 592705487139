import { instance, apiVersion } from "../initialize";

import { AxiosInstance } from "axios";

export const actions = <T>(f?: (d: any) => T) => {
  const factory: (d: unknown) => T = f ? f : (d: any): T => d;

  const API_VERSION = apiVersion;

  // const api = instance();

  // const getInstance = instance();

  /**
   * apiのイニシャライズ
   */

  /**
   * OptXのビルドリクエスト
   */
  const build = async (optXId: string): Promise<boolean> => {
    const api = instance();
    if (!api) {
      console.error("no api");
      return false;
    }
    try {
      const res = await api({
        method: "POST",
        url: `/api/${API_VERSION}/tet/optx/${optXId}/build`,
        params: {
          apikey: sessionStorage.token,
        },
        headers: { "Content-Type": "application/json" },
      });
      if (res.status === 200) return true;
      else return false;
    } catch (err) {
      return false;
    }
  };

  return {
    build,
  };
};
