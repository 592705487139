//Libs
import React from "react";

import { NTSettingsPageTemplate } from "../templates/NTSettingsPageTemplate";
import { PTSettingSecretsTemplate } from "src/portal/components/templates/PTSettingSecretsTemplate";
//Types

type Props = {};
export const NTSettingSecretsPage: React.FC<Props> = (props) => {
  return (
    <>
      <NTSettingsPageTemplate>
        <PTSettingSecretsTemplate />
      </NTSettingsPageTemplate>
    </>
  );
};
