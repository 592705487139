import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";
import { createnAuthAPI } from "src/api";
import { useState } from "react";
import { useMyRole } from "./useMyRole";
import { useAuth } from "src/root";
import { fetchUserByUserNo, fetchUsersByEmail } from "../api/fetchUser";
import { createGroupMembersBrowserUi } from "../ui/browser/createGroupMembersBrowserUi";

export const useGroupMembers = (groupId: string) => {
  const auth = useAuth();
  const myRole = useMyRole();
  const nauth = createnAuthAPI();

  const [showInviteMemberModal, setShowInviteMemberModal] = useState(false);

  // ユーザーをグループに招待する
  const addMemberToGroup = async (user: any) => {
    const email = user.email ?? "";
    try {
      const isSuccess = await nauth.group.inviteMember({
        groupId,
        email,
        userNumber: user.userNo,
      });
      if (isSuccess) {
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  };

  // グループからメンバーを削除する
  const removeMemberFromGroup = async (user: any) => {
    const email = user.email ?? "";

    try {
      const isSuccess = await nauth.group.excludeMember({
        groupId,
        email,
        userNumber: user.userNo,
      });
      if (isSuccess) {
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  };

  // ユーザーのグループ招待ボタンが押された際のコールバック関数
  // 入力されたメールアドレスからユーザーを探して、そのユーザーをグループに招待する
  const handleAddMemberToGroupByEmail = async (email: string) => {
    const users = await fetchUsersByEmail(email);
    if (users.length > 0) {
      const user = users[0];
      await addMemberToGroup(user);
    }
  };

  // ユーザーの除名ボタンが押された際のコールバック関数
  const handleDeleteGroupMember = async (value: any) => {
    try {
      const user = await fetchUserByUserNo(value.userNo);

      await removeMemberFromGroup(user);
    } catch (err) {
      console.error(err);
    }
  };

  const browserUi: ASBrowserUiConf = createGroupMembersBrowserUi({
    contractorNo: auth.contractorNo ?? "",
    groupId,
    onDelete: handleDeleteGroupMember,
    canDelete: myRole.canExcludeGroup,
  });

  //メンバー招待モーダルを表示する
  const handleOpenInviteModal = () => {
    setShowInviteMemberModal(true);
  };

  // メンバー招待モーダルを非表示にする
  const handleCloseInviteModal = () => {
    setShowInviteMemberModal(false);
  };

  return {
    browserUi,
    showInviteMemberModal,

    handleOpenInviteModal,
    handleCloseInviteModal,
    handleAddMemberToGroupByEmail,
  };
};
