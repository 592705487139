import React from "react";
import styled from "styled-components";
type Props = { name: string };

const CircleIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: green; // TODO
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ASInitialIcon: React.FC<Props> = ({ name }) => {
  return (
    <CircleIcon>
      {name && name.length > 0 ? name[0].toUpperCase() : ""}
    </CircleIcon>
  );
};
