import { useEffect, useState } from "react";
import * as React from "react";
import styled from "styled-components";
import { ASChip } from "allegro-ui";
import { darken, lighten } from "polished";

const Title = styled.h2`
  font-size: 13px;
  color: #656565;
  line-height: 1.56;
  width: 100px;
`;

const FlexBox = styled.div`
  display: flex;
  > * {
    margin-top: 8px;
  }
`;

const colors: { [key: string]: any } = {
  問題なし: "positive",
  気になる: "caution",
  要経過観察: "negative",
  虐待の危惧あり: "negative",
  虐待あり: "negative",
  身体的虐待: "negative",
  精神的虐待: "negative",
  要保護: "caution",
  要支援: "negative",
  特定妊婦: "negative",
  登録なし: "positive",
};

export const DisabledChip = styled(ASChip)`
  color: #909090;
  background-color: ${(props) =>
    props.theme.darkMode ? darken(0.5, "#555") : lighten(0.52, "#555")};
`;

interface ILCAssessmentCellComponentProps {
  data: {
    assessments: any[];
  };
}

const LCAssessmentCellComponent: React.FC<ILCAssessmentCellComponentProps> = (
  props
) => {
  const [evaluation, setEvaluation] = useState("なし");
  const [abuseCat, setAbuseCat] = useState("未入力");
  const assessments = props.data.assessments;
  useEffect(() => {
    //アセスメントデータが存在する場合
    if (Array.isArray(assessments) && assessments.length > 0) {
      if (assessments[0].contents) {
        //統合によって作られたデータの場合
        setEvaluation(assessments[0].contents.rawdata["職員評価"]);
        setAbuseCat(
          assessments[0].contents.rawdata["要対協登録状況"] ?? "未入力"
        );
      } else {
        //みどるうぇあによって一時的統合をされている場合
        setEvaluation(assessments[0]["職員評価"]);
        setAbuseCat(assessments[0]["要対協登録状況"] ?? "未入力");
      }
    }
    return () => {
      setEvaluation("なし");
      setAbuseCat("未入力");
    };
  }, [assessments]);

  return (
    <>
      <FlexBox>
        <Title>判定</Title>
        {colors[evaluation] ? (
          <ASChip
            design="onecolor"
            kind={colors[evaluation]}
            label={evaluation}
            onDelete={undefined}
            small
          ></ASChip>
        ) : (
          <DisabledChip
            design="onecolor"
            kind={colors[evaluation]}
            label={evaluation}
            onDelete={undefined}
            small
          ></DisabledChip>
        )}
      </FlexBox>
      <FlexBox>
        <Title>要対協登録状況</Title>
        {colors[abuseCat] ? (
          <ASChip
            design="onecolor"
            kind={colors[abuseCat]}
            label={abuseCat}
            onDelete={undefined}
            small
          ></ASChip>
        ) : (
          <DisabledChip
            design="onecolor"
            kind={colors[abuseCat]}
            label={abuseCat}
            onDelete={undefined}
            small
          ></DisabledChip>
        )}
      </FlexBox>

      {/* <Title>日付</Title>
      <span></span> */}
    </>
  );
};

export default LCAssessmentCellComponent;
