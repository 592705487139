import { ASEasyTable, ASIconButton } from "allegro-ui";
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { FamilyPersonalData } from "src/fortress/models";
import {
  familyPersonalAgeState,
  familyPersonalNameState,
  ModalState,
} from "src/fortress/recoils/familyPersonalState";
import styled, { useTheme } from "styled-components";
import { FTDashBoardWidget } from "../organisms";
import Masonry from "react-masonry-css";
import CancelIcon from "@material-ui/icons/Cancel";
import { HiroshimaLogo } from "../atoms/logo";

const CaptionGroup = styled.div`
  margin-top: 14px;
  color: #aaa;
  font-size: 10px;
  padding: 8px 0 0 0;
  border-top: 1px solid #eee;
  span {
    display: block;
    line-height: 1;
    overflow-wrap: break-word;
  }
`;

const MasonryGrid = styled(Masonry)`
  display: flex;
  margin-left: -30px;
`;

const MasonryItem = styled.div`
  margin-left: 30px;
  margin-top: 30px;
`;

const StyledButton = styled.div`
  text-align: right;
  margin-left: auto;
`;

const No = styled.div`
  text-align: center;
  margin-top: 150px;
  font-size: 40px;
  color: #dddddd;
`;

const Title = styled.div`
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
  font-size: 20px;
  padding-top: 9px;
`;

const Flex = styled.div`
  display: flex;
`;

//家族ページから遷移して、２０歳以上の人の個人データを表示するためのコンポーネント

type Props = {
  personalData: FamilyPersonalData[];
};

export const WelfareModal: React.FC<Props> = (props) => {
  const { personalData } = props;
  const cursorPersonName = useRecoilValue(familyPersonalNameState);
  const cursorPersonAge = useRecoilValue(familyPersonalAgeState);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = useRecoilState(ModalState);
  const theme = useTheme();
  const color = theme.darkMode ? "#dddddd" : "#dddddd";

  const handleClose = () => {
    setOpen(false);
  };

  const familyPersonalDatas =
    personalData.length > 0 ? (
      personalData.map((p: FamilyPersonalData, id) => {
        return (
          <>
            <MasonryItem key={String(id)}>
              <FTDashBoardWidget
                title={p.label}
                children={
                  <>
                    <ASEasyTable tableData={p.data}></ASEasyTable>
                    <CaptionGroup>
                      <span>{p.lastUpdate}</span>
                      <span>{p.tags}</span>
                    </CaptionGroup>
                  </>
                }
              ></FTDashBoardWidget>
            </MasonryItem>
          </>
        );
      })
    ) : (
      <>
        <No>
          <HiroshimaLogo size={150} color={color} />
          <p>データが存在しません</p>
        </No>
      </>
    );

  return (
    <>
      <Flex>
        <Title>
          {cursorPersonName}({cursorPersonAge})さんの個人データ
        </Title>
        <StyledButton>
          <ASIconButton kind="yellow" onClick={handleClose}>
            <CancelIcon></CancelIcon>
          </ASIconButton>
        </StyledButton>
      </Flex>
      <MasonryGrid
        breakpointCols={1}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
        onClick={handleClose}
      >
        {familyPersonalDatas}
      </MasonryGrid>
    </>
  );
};

//`${cursorPersonName}(${cursorPersonAge})さんの生活保護に関するデータ`
