import React from "react";
import { NTSettingsPageTemplate } from "../templates/NTSettingsPageTemplate";
import { PTSettingGroupSingleTemplate } from "src/portal/components/templates/PTSettingGroupSingleTemplate";
import { useParams } from "react-router-dom";

export const NTSettingGroupSinglePage: React.FC = () => {
  const params = useParams();
  const groupId = params.groupId ?? "";
  return (
    <NTSettingsPageTemplate>
      <PTSettingGroupSingleTemplate groupId={groupId} />
    </NTSettingsPageTemplate>
  );
};
