import { ASLowCodeFormDefinition } from "allegro-low-code-components";
import { fetchUsersByEmail } from "src/portal/api/fetchUser";

export const inviteMemberToGroupDefinition: ASLowCodeFormDefinition = {
  buttonOptions: {
    submitText: "Invite",
  },
  sections: [
    {
      // title: "Invite Member",
      description: "",
      fields: [
        {
          label: "New Members",
          name: "email",
          type: "text",
          required: true,
          active: "",
          values: async (text?: string) => {
            const users = await fetchUsersByEmail(text ?? "");

            return users.map((user) => {
              return {
                label: user.email,
                value: user.email,
              };
            });
          },
          errorText: "Input this field.",
        },
      ],
    },
  ],
};
