//Libs
import React, { useState, useEffect, useCallback } from "react";
// import { useCodeBoxActions } from "./useCodeBox";
import apiConf from "src/opt-editor/apiConf.json";
import favy from "allegro-api";
import {
  useRecoilValue,
  useRecoilState,
  useRecoilRefresher_UNSTABLE,
} from "recoil";
import scienceFolder from "../recoils/ScienceFolderState";
//Components
import { FormData } from "../components/organisms/OECreateCodeBoxModal";
import { createFile, File } from "../models";
import {
  CodeBox,
  createScienceFolder,
  ScienceFile,
} from "../models/ScienceFSItem";
import { fileListState, filterdFileListState } from "../recoils";
import { useFileXActions } from "./useFileXActions";
import scienceFile from "../recoils/ScienceFileState";
//States
//Types

type Props = {};

const createDockerfile = (type: string) => {
  if (type === "ts") {
    return "FROM ts";
  } else if (type === "py") {
    return "FROM python";
  }
};

/**
 * todo
 */
const useCodeBoxActions = () => {
  const add = (hoge: any, fuga: any, hogu: any, id: any, cb: any) => {};
  return { add };
};

const { tags, primaryKey } = apiConf.topdata.file;
const factory = (resData: any) => {
  console.log(resData.contents.rawdata);
  return createFile(resData.contents.rawdata);
};

export const useCodeBoxMediator = () => {
  const codeBoxActions = useCodeBoxActions();
  const nextFolderId = useRecoilValue(scienceFolder.nextAutoIncrementalId);
  const refreshTreeList = useRecoilRefresher_UNSTABLE(scienceFolder.idList);

  const cursorFolderId = useRecoilValue(scienceFolder.cursorItemIdState);
  const [cursorCodeBox, setCursorCodeBox] = useRecoilState(
    scienceFolder.item(cursorFolderId)
  );
  const xFileActions = useFileXActions();

  const { getItems } = scienceFile.useItemList();
  const [files, setFiles] = useState<ScienceFile[]>([]);

  const optx = favy.useOptX();
  const { build } = favy.optx();
  const file = favy.useTopData<File>(tags, primaryKey, factory);

  const updateFiles = useCallback(async () => {
    const files = await getItems();
    setFiles(files);
  }, [getItems]);

  useEffect(() => {
    updateFiles();
  }, [updateFiles]);

  /**
   * コードボックスを作成するメディエータ。
   * 1. OptXを登録
   * 2. CodeBoxを登録
   * 3. Dockerfileを登録
   */
  const createCodeBox = async (
    folderName: string,
    slotId: string,
    optXId: string | null,
    dockerfileContent: string
  ) => {
    //CodeBoxと同じ名前のOptXを作成する
    const res = await optx.post(folderName);
    if (res.id) {
      //CodeBoxを作成
      console.log("CodeBoxフォルダを作成中");
      await scienceFolder.addItem(
        createScienceFolder({
          itemId: nextFolderId,
          name: folderName,
          type: "CodeBox",
          // parent: cursorFolderId,//TODO
          contents: {
            shouldInstant: true,
            xerviceId: slotId,
            optxId: res.id,
          },
        })
      );
      console.log("CodeBoxフォルダを作成中 .... done");
    }

    //@todo
    //CodeBoxの中にDockerfileを作成
    const dockerfile = createFile({
      name: "Dockerfile",
      parent: nextFolderId,
      data: dockerfileContent,
    });
    console.log("dockerfile", dockerfile);
    await file.postOne(dockerfile);
    refreshTreeList();
  };

  /**
   * コードボックスをコミットします。
   * 1. コードボックスの中にあるファイルを取得する。
   * 2. コードボックスの中にあるファイルをOptXのFileXに登録する
   */
  const commitCodeBox = async () => {
    //関連ファイルを取得
    // const files = await folderActions.getList();
    //関連ファイルをFileXに登録

    console.log("codebox", cursorCodeBox);
    console.log("files", files);

    let successFileCount = 0;
    /**
     * ファイルをupsert
     */
    for (const file of files) {
      const ok = await xFileActions.upsert(cursorCodeBox.contents?.optXId, {
        name: file.name,
        text: file.contents,
      });
      if (!ok) {
        console.log("failed save,", file);
        alert(
          file.name +
            ":ファイルのコミットに失敗しました。しばらく経ってからお試しください。"
        );
      } else {
        successFileCount += 1;
      }
    }
    if (successFileCount > 0) {
      alert(`${successFileCount}ファイルのコミットに成功しました。`);
    }

    //build
    setTimeout(async () => {
      const ok = await build(cursorCodeBox.contents?.optXId);
      console.log(ok);
      if (ok) {
        alert("ビルドリクエスト成功");
      } else {
        alert("ビルドリクエストに失敗しました。");
      }

      console.log("success commit codebox to optx");
    }, 3000);
  };

  /**
   * コードボックスを起動します。
   * 1. Xerviceの割り当て
   * 2. ビルド
   * 3. 起動リクエスト
   */
  const startCodeBox = () => {};

  const updateCodeBox = (updates: Partial<CodeBox>) => {
    console.log(updates);
    setCursorCodeBox({ ...cursorCodeBox, ...updates });
  };

  /**
   * コードボックスを停止します。
   * 1. 停止リクエスト
   */
  const stopCodeBox = () => {};

  const deleteCodeBox = () => {};

  return {
    createCodeBox,
    commitCodeBox,
    startCodeBox,
    updateCodeBox,
    stopCodeBox,
  };
};
