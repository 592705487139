//Libs
import { User } from "allegro-api";
import { ASValidationError } from "allegro-low-code-components";
import { useState, useCallback, useEffect } from "react";
import { useRecoilValueLoadable } from "recoil";
import { createnAuthAPI } from "src/api";

//Components
//States

//Types
import { myProfileState } from "src/root/recoils/userState";

/**
 * @deperecated useProfileListに移行する
 */
export const useProfileList = () => {
  const [userList, setUserList] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const myProfile = useRecoilValueLoadable(myProfileState);
  const [showDisabledUser, setShowDisabledUser] = useState(false);
  const nauth = createnAuthAPI();

  const init = useCallback(async () => {
    if (!isLoading && myProfile.state === "hasValue" && myProfile.contents) {
      try {
        setIsLoading(true);

        const newUsers = await nauth.getUsers({
          limit: 500,
          skip: 0,
          withDisabled: showDisabledUser,
        });

        setUserList(newUsers);
      } catch (err) {
        console.error(err);
      }
      setInitialized(true);
      setIsLoading(false);
    }
  }, [myProfile.contents, myProfile.state, nauth, showDisabledUser]);

  const getUserById = (userNoText: string): User | null => {
    const filterd = userList.filter((u: User) => {
      return u.userNoText === userNoText;
    });
    if (filterd.length > 0) {
      return filterd[0];
    } else return null;
  };

  const getUserByEmail = (email: string): User | null => {
    const filterd = userList.filter((u: User) => {
      return u.username === email;
    });

    if (filterd.length > 0) {
      return filterd[0];
    } else return null;
  };

  const validateEmail = (email?: string): ASValidationError => {
    if (!email) {
      return {
        error: "no email",
        message: "メールアドレスを指定してください",
      };
    }

    const user = getUserByEmail(email);
    if (user) {
      return {
        error: "user already exists",
        message: "メールアドレスは既に存在しています",
      };
    }

    return {
      error: undefined,
      message: undefined,
    };
  };

  const reload = useCallback(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (!initialized) {
      init();
    }
  }, [init, initialized]);

  return {
    userList,
    isLoading,
    getUserById,
    getUserByEmail,
    validateEmail,
    reload,
    setShowDisabledUser,
  };
};
