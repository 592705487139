import styled, { DefaultTheme } from "styled-components";
import { Tabs } from "@material-ui/core";

export type ASTabsProps = {
  theme: DefaultTheme;
  type?: "dot" | "outline__TBD" | "box" | "mui__TBD";
  tiny?: boolean;
  noround?: boolean;
};

const tabParams = {
  height: 32,
  padding: 8,
};
const tinyTabParams = {
  height: 30,
  padding: 6,
};

const calcHeight = (props: { height: number; padding: number }) => {
  return props.height + props.padding * 2;
};

export const ASTabs = styled(Tabs)<ASTabsProps>`
  color: ${(props: ASTabsProps) => props.theme?.secondaryOnText};
  .MuiTab-wrapper {
    text-transform: none;
  }
  display: inline-block;
  width: auto;
  background-color: ${(props: ASTabsProps) => props.theme?.secondaryColor};
  /* border: 2px solid white; */
  padding: ${({ type = "dot" }) => {
    switch (type) {
      case "box":
        return "8px";
      default:
        return "0px 8px";
    }
  }};
  border-radius: ${(props: ASTabsProps) =>
    props.noround ? 0 : props.theme?.borderRadiusLg}px;
  .MuiTab-root {
    font-size: ${(props: ASTabsProps) => props.theme?.regularFontSizeMd}px;
    border-radius: ${({ theme, type = "dot" }) => {
      switch (type) {
        case "box":
          return theme.borderRadiusLg;
        default:
          return 0;
      }
    }}px;

    min-height: ${(props: ASTabsProps) =>
      props.tiny ? calcHeight(tinyTabParams) : calcHeight(tabParams)}px;
    height: ${(props: ASTabsProps) =>
      props.tiny ? calcHeight(tinyTabParams) : calcHeight(tabParams)}px;
  }

  min-height: ${(props: ASTabsProps) =>
    props.tiny ? calcHeight(tinyTabParams) : calcHeight(tabParams)}px;
  height: ${(props: ASTabsProps) =>
    props.tiny ? calcHeight(tinyTabParams) : calcHeight(tabParams)}px;

  .Mui-selected {
    color: ${(props: ASTabsProps) => props.theme?.selectedOnText};
    border-radius: ${(props) => props.theme?.borderRadiusLg * 0.7}px;
    min-height: ${(props: ASTabsProps) =>
      props.tiny ? calcHeight(tinyTabParams) : calcHeight(tabParams)}px;
    height: ${(props: ASTabsProps) =>
      props.tiny ? calcHeight(tinyTabParams) : calcHeight(tabParams)}px;

    position: relative;
    z-index: 1;
  }
  .MuiTabs-indicator {
    background: none;
    &::after {
      /* display: none; */
      background-color: ${(props: ASTabsProps) => props.theme?.selectedColor};
      content: "";
      position: absolute;
      left: 50%;
      bottom: ${(props) =>
        props.tiny ? tinyTabParams.padding : tabParams.padding}px;
      width: 100%;
      height: ${(props: ASTabsProps) =>
        props.tiny ? tinyTabParams.height : tabParams.height}px;
      /* background: ${(props: ASTabsProps) => props.theme?.cardColor}; */
      transform: translateX(-50%);
      border-radius: ${(props) => props.theme?.borderRadiusLg * 0.7}px;
      transition: 0.3s;
    }
  }
`;
