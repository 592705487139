import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useSetRecoilState,
  useRecoilState,
  useRecoilValueLoadable,
} from "recoil";
import query from "src/fortress/components/libs/LowCodeDataBrowser/components/LCDataBrowserComponent/query";
import { defaultPipeline } from "../models";
import {
  slotBrowserFilterState,
  cursorSlotIdState,
  cursorPipelineIdState,
  cursorPipelineState,
  slotListState,
  slotRequestId,
  showRightPaneState,
} from "../recoils";
import { usePane } from "./usePane";
import { usePipelineXPermissionControl } from "./usePipelinePermissionControl";

/**
 * Pipeline(Xervice)を管理するhooks
 * @params pipelineId Pipeline(Xervice) ID
 * @params defaultSlotId Slot ID, 初期化時にインスペクタに指定したスロットを表示します。
 */
export const usePipelineEditor = (
  pipelineId: string,
  options?: { defaultSlotId?: string | null }
) => {
  const history = useNavigate();

  const setSlotBrowserFilterState = useSetRecoilState(slotBrowserFilterState);
  const setCursorSlotId = useSetRecoilState(cursorSlotIdState);
  const setSlotReqId = useSetRecoilState(slotRequestId);

  const [cursorPipelineId, setCursorPipelineId] = useRecoilState(
    cursorPipelineIdState
  );
  const cursorPipeline = useRecoilValueLoadable(cursorPipelineState);
  const slotsLoadable = useRecoilValueLoadable(slotListState);

  const { canEditPipeline } = usePipelineXPermissionControl(
    cursorPipeline.state === "hasValue" && cursorPipeline.contents
      ? cursorPipeline.contents
      : defaultPipeline
  );

  const { showRightPane: showInspector, toggleRightPane } = usePane();

  console.log({ pipelineId });

  //init
  useEffect(() => {
    setSlotBrowserFilterState("ByPipeline");
    setSlotReqId((id) => id + 1);
  }, [setSlotBrowserFilterState, setSlotReqId]);

  useEffect(() => {
    if (options?.defaultSlotId) setCursorSlotId(options.defaultSlotId);
    if (pipelineId) setCursorPipelineId(pipelineId);
  }, [
    pipelineId,
    options?.defaultSlotId,
    cursorPipelineId,
    setCursorPipelineId,
    setCursorSlotId,
  ]);

  const isLoadingPipeline = cursorPipeline.state === "loading";
  const isLoadingSlots = slotsLoadable.state === "loading";

  const cursorPipelineName =
    cursorPipeline.state === "hasValue" ? cursorPipeline.contents?.name : "---";

  const pipelineX =
    cursorPipeline.state === "hasValue" ? cursorPipeline.contents : null;

  // 一つ前のページに戻る
  const handleBackPage = () => {
    history(-1);
  };

  // インスペクタの表示・非表示のスイッチ
  const handleToggleShowInspector = () => {
    toggleRightPane();
  };

  return {
    isLoadingPipeline,
    isLoadingSlots,
    cursorPipelineName,
    pipelineX,
    showInspector,
    canEditPipeline,
    handleBackPage,
    handleToggleShowInspector,
  };
};
