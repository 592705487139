import { ASCard, ASEasyTable, ASIconButton } from "allegro-ui";
import * as React from "react";
import Draggable, { DraggableCore } from "react-draggable"; // Both at the same time
import styled from "styled-components";
import { CardContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { shapeToSeireki } from "src/fortress/utils/time";
import { translateLabel } from "src/fortress/utils/dataShape";
import { FormAnswer, FormValues } from "src/medican/models/FormAnswer";
import { MDJsonCard } from "./MDJsonCard";

const Window = styled(ASCard)`
  z-index: 99999;
  position: absolute;
  width: 70vw;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 6px 6px rgba(0, 0, 0, 0.12);
`;

const Header = styled.strong`
  width: 100%;
  display: flex;
  padding: 16px;
  cursor: pointer;

  border-bottom: 2px solid ${(props) => props.theme.secondaryBorderColor};
  > h1 {
    font-size: 20px;
    font-weight: bold;
  }
  > *:last-child {
    margin-left: auto;
  }
`;

export interface IFTDraggableJsonViewProps {
  title: string;
  id: number;
  data: FormValues[];
  onClose: any;
}

export const MDDraggableJsonView = (props: IFTDraggableJsonViewProps) => {
  return (
    <Draggable handle="strong">
      <Window>
        <Header>
          <h1>{translateLabel(props.title)}</h1>

          <div>
            <ASIconButton
              size="small"
              onClick={() => {
                props.onClose(props.id);
              }}
            >
              <CloseIcon />
            </ASIconButton>
            {/* <ASIconButton>min</ASIconButton> */}
          </div>
        </Header>
        <MDJsonCard formValues={props.data} />
      </Window>
    </Draggable>
  );
};
