import { AxiosInstance } from "axios";
import { AssetInputProps } from "../../models/AssetInput";

export const save = async (
  instance: AxiosInstance,
  assetInput: AssetInputProps,
  upsert: boolean = false
): Promise<any> => {
  const {
    name,
    tags,
    id,
    uniqueKeys,
    collectionNames,
    rawQuePartitions,
    topQuePartitions,
    txnQuePartitions,
    timeFieldMetric,
  } = assetInput;

  const sendToTags =
    collectionNames?.map(
      (collectionName: string) => `SendTo${collectionName}`
    ) ?? [];

  let assetTags = tags;

  if (uniqueKeys) {
    const parsedUniqueKeys = uniqueKeys.map(
      (keyName: string) => `key:${keyName}`
    );
    assetTags = [...assetTags, ...parsedUniqueKeys];
  }

  //sendToTagsを追加する。
  for (const sendToTag of sendToTags) {
    if (!assetTags.includes(sendToTag)) {
      assetTags.push(sendToTag);
    }
  }

  const body = {
    ...assetInput,
    name: name,
    id: id,
    tags,
    userNoText: sessionStorage.userNoText,
    pipelineName: "fortress",
    rawQuePartitions: rawQuePartitions,
    txnQuePartitions: txnQuePartitions,
    topQuePartitions: topQuePartitions,
    timeFieldMetric: timeFieldMetric,
  };
  return await instance({
    method: "POST",
    url: `/contractors/${sessionStorage.contractorNo}/assets`,
    params: {
      upsert: upsert,
    },
    data: body,
  });
};
