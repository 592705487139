import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import { tabIdState } from "src/fortress/recoils/singleChildPageState";
import { useNavigate } from "react-router-dom";
import { ModalState } from "src/fortress/recoils/familyPersonalState";

type Args = {
  id?: string | number;
  title?: string;
  defaultId?: number;
  handleChange?: (value: number) => void;
};

export const useTabs = (props: Args) => {
  const [tabId, setTabId] = useRecoilState(tabIdState(props.id ?? ""));
  const setOpen = useSetRecoilState(ModalState);
  const history = useNavigate();

  const handleChange = (event: any, newValue: number) => {
    setTabId(newValue);
    if (props.handleChange) {
      props.handleChange(newValue);
    }
  };

  useEffect(() => {
    if (props.defaultId && props.defaultId >= 0) {
      setTabId(props.defaultId);
    }
  }, [props.defaultId, setTabId]);

  const back = () => {
    history(-1);
    setOpen(false);
  };

  return {
    tabId,
    handleChange,
    back,
  };
};
