import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ASTableRow } from "allegro-ui";
import { darken, TableCell } from "@material-ui/core";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { User } from "src/root/models/user";
import { OEInitialIcon } from "../atoms/OEInitialIcon";

type Props = {
  user: User;
};

const StyledTableRow = styled(ASTableRow)`
  background-color: ${(props) => props.theme.secondaryColor};
  height: 60px;
  border-radius: 20%;
`;

const Project = styled.div`
  font-size: 8px;
  color: ${darken("#fff", 0.3)};
`;

const UserIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  > * {
    width: 100%;
  }
`;

export const OEUserListItem: React.FC<Props> = (props) => {
  const { userNoText, nickName, email } = props.user;
  const history = useNavigate();
  const handleRowClick = () => {
    history(`/activities/${userNoText}`);
  };

  return (
    <StyledTableRow onClick={() => handleRowClick()}>
      <TableCell>
        {/* <UserIcon>
          <img src={allegrobotIcon} alt="U" />
        </UserIcon> */}
        <OEInitialIcon name={nickName} />
      </TableCell>
      <TableCell>{nickName}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{userNoText}</TableCell>

      {/* <TableCell>{lastUpdated}</TableCell> */}
      <TableCell>
        <ArrowForwardIos />
      </TableCell>
    </StyledTableRow>
  );
};
