import produce from "immer";
import { createNewtZeroAPI } from "src/api";
import {
  ContainerStats,
  createContainerStatsFromNewtData,
} from "../models/ContainerStats";
import { MONITOR_BASE_URL, MonitorAPI } from "./monitor-api";
import { Server } from "allegro-api/lib/models/Server";

const defaultStatsNewtQuery = {
  asset: ["ContainerStats"],
  limit: 1,
  skip: 0,
  order: {
    "contents.rawdata.message.unixtime": -1,
  },
  search: {},
  shape: {
    "contents.rawdata": "any",
    _created: "any",
  },
};

export const getCurrentContainerStats = async (
  slotId: string,
  apikey: string
): Promise<ContainerStats | null> => {
  const newtQuery = produce(defaultStatsNewtQuery, (draft) => {
    draft.search = {
      "contents.rawdata.message.container_name": slotId,
    };
  });

  try {
    const monitorAPI = MonitorAPI({
      baseUrl: MONITOR_BASE_URL,
      apikey: apikey,
    });
    const dataRes = await monitorAPI.fetchContainerStatsByNewtQuery(newtQuery);
    if (Array.isArray(dataRes) && dataRes.length > 0) {
      return createContainerStatsFromNewtData(dataRes[0]);
    }
  } catch (err) {
    console.error(err);
  }

  return null;
};

const defaultServerNewtQuery = {
  asset: ["Server"],
  limit: 1,
  skip: 0,
  // primaryKey: "contents.rawdata.non_numeric_server_name",
  match: {},
  search: {},
  shape: {
    "contents.rawdata": "any",
    _id: "any",
  },
};

export const getServers = async (contractorNo: string) => {
  const newtzero = createNewtZeroAPI(2);
  const newtQuery = produce(defaultServerNewtQuery, (draft) => {
    draft.search = {
      "bases.contractorNo": contractorNo,
    };
    draft.limit = 100;
  });

  try {
    const resData = await newtzero.server.dataProvider(newtQuery);
    return resData;
  } catch (err) {
    console.error(err);
  }
  return [];
};

export const getServer = async (serverId: string): Promise<Server | null> => {
  const newtzero = createNewtZeroAPI(2);

  try {
    const server = await newtzero.server.findOne(serverId);
    return server;
  } catch (err) {
    console.error(err);
  }

  return null;
};
