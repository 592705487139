//Libs
import { api } from "src/api";
//Components
//States
import { userState } from "src/root/recoils/userState";
//Types

import { AtomEffect, DefaultValue } from "recoil";
import { defaultPipeline, PipelineX } from "../models";
import { createPipeline } from "../models/PipelineX";

type Options = {
  contractor: string;
  apiKey: string;
};

const getPipeline = async (
  pipelineId: string,
  { contractor, apiKey }: Options
): Promise<PipelineX | DefaultValue> => {
  const res = await api({
    method: "GET",
    url: `/contractors/${contractor}/pipelinex?apikey=${apiKey}`,
    params: {
      apikey: apiKey,
      q: {
        where: {
          id: pipelineId,
        },
        limit: 1,
      },
    },
  });
  if (res.status === 200) {
    if (res.data.length > 0) return createPipeline(res.data[0]);
    else return defaultPipeline;
  } else {
    console.error("failed fatching pipeline ids.");
    return defaultPipeline;
  }
};

export const updatePipeline = async (
  newPipeline: PipelineX,
  { contractor, apiKey }: Options
) => {
  const res = await api({
    method: "PUT",
    url: `/contractors/${contractor}/pipelinex/${newPipeline.id}?apikey=${apiKey}`,
    params: {
      apikey: apiKey,
    },
    data: { name: newPipeline.name },
  });
  return res;
};

export const syncDBEffect: (pipelineId: string) => AtomEffect<PipelineX> =
  (pipelineId) =>
  ({ setSelf, trigger, onSet, getPromise }) => {
    if (trigger === "get") {
      getPromise(userState).then((auth) => {
        const contractor: string =
          typeof auth.contractorNo === "string" ? auth.contractorNo : "";
        const apiKey: string = typeof auth.token === "string" ? auth.token : "";

        getPipeline(pipelineId, { contractor, apiKey }).then((pipeline) =>
          setSelf(pipeline)
        );
      });
    }
  };
