import { NewtQuery } from "allegro-api";
import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import { createNewtZeroAPI } from "src/api";
import { useCsv } from "src/general/hooks/useCsv";
import { dataDelete } from "src/medican/api";
import { parseXlsx, parseJSON, xlsx2Object } from "src/medican/utils/parseFile";
import { Asset, UploadLogStatus } from "src/newtroid/models";
import { useFileUpload } from "src/opt-editor/hooks/useFileUpload";

const assetDataDefaultNewtQuery: NewtQuery = {
  primaryKey: "id",
  limit: 1,
  skip: 0,
  search: {} as any,
  order: {
    _created: -1,
  },
  shape: {
    id: "any",
    bases: "any",
    contents: "any",
    _modified: "any",
    _created: "any",
    readers: "any",
    writers: "any",
  },
};

export const useAssetData = (asset: Asset) => {
  const [initialized, setInitialized] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(4);
  const [metrics, setMetrics] = useState<string[]>([]);
  const [search, setSearch] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterByContractor] = useState<boolean>(true);

  const newtzero = createNewtZeroAPI();
  const csv = useCsv();

  const newtQueryBase = produce(assetDataDefaultNewtQuery, (draft) => {
    // draft.collection = asset.collectionName ?? "TopData";
    draft.assetId = asset.id;
    draft.limit = limit;
    draft.search = search;
  });

  const getTotalNewtQuery = () =>
    produce(assetDataDefaultNewtQuery, (draft) => {
      draft.assetId = asset.id;
      draft.collection = asset.collectionName ?? "TopData";
      draft.limit = count;
      draft.search = search;
    });

  const assetTags = asset?.tags ?? [];
  const uniqueKeys = assetTags
    .filter((tag: string) => tag.startsWith("key:contents.optdata"))
    .map((tag) => tag.replace("key:contents.optdata.", ""));

  const totalPage = Math.ceil(count / limit);

  const updateSearch = async (newSearch: any) => {
    setSearch(newSearch);
  };

  const updatePage = async (newPage: number) => {
    setPage(newPage);
  };

  const updateLimit = async (newLimit: number) => {
    setLimit(newLimit);
  };

  const extractMetrics = (data: any[]): string[] => {
    return data.length > 0 ? Object.keys(data[0]) : [];
  };

  const updateData = useCallback(async () => {
    setIsLoading(true);
    const assetDataNewtQuery = produce(newtQueryBase, (draft: any) => {
      draft.skip = page * limit;
    });
    const _data = await newtzero.dataProvider(assetDataNewtQuery, {
      filterByContractor,
    });
    const countObj = await newtzero.dataProvider(
      { ...newtQueryBase, count: "true" },
      {
        filterByContractor,
      }
    );

    const _count = countObj[0]?.totalDataCount;

    const _metrics = extractMetrics(
      _data.map((d: any) => {
        return Object.fromEntries(
          Object.entries(d.contents.optdata)
            .map(([key, value]) => {
              // if (
              //   !key.startsWith("numeric_") &&
              //   !key.startsWith("non_numeric_")
              // ) {
              //   return [];
              // }
              if (value === "metric not found.") {
                return [];
              } else {
                return [key, value];
              }
            })
            .filter((d) => d.length > 0)
        );
      })
    ).sort((x, y) =>
      uniqueKeys.includes(x) ? -1 : uniqueKeys.includes(y) ? 1 : 0
    );

    console.log({ _metrics });
    setMetrics(_metrics);

    setData(_data);
    setCount(_count ?? 0);
    setIsLoading(false);
  }, [page, limit, search]);

  const sendAssetData = async (data: any) => {
    const res = await newtzero.streamGate(asset.id, data, {
      upsert: true,
      upsertTags: true,
      sendToTopData: true,
    });
    return res;
  };

  /**
   * アセットに任意のデータを書き込みます。
   */
  const { logs, uploadFiles } = useFileUpload({
    onUpload: async (file: File): Promise<UploadLogStatus[]> => {
      let formObj = null;

      const extension = file.name.split(".").at(-1) ?? "";
      switch (extension) {
        case "json":
          formObj = await parseJSON(file);
          break;
        case "xlsx":
          const workbook = await parseXlsx(file);
          const sheetsData = xlsx2Object(workbook, { headerRow: 1 });
          formObj = sheetsData.map((sheetData) => sheetData.records).flat();
          break;
        case "csv":
          const fileStr = await csv.parseFile(file);
          formObj = csv.toObject(fileStr);
      }

      if (formObj) {
        const res = await sendAssetData(formObj);
        const log: UploadLogStatus = {
          name: file.name,
          status: res?.status ?? -1,
          message: res?.status === 200 ? "完了" : "失敗",
        };
        return [log];
      }

      return [];
    },
  });

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
    updateData();
  }, [initialized, updateData, page]);

  const deleteData = async () => {
    const resDelete = await dataDelete(asset.id, search);

    if (resDelete) {
      window.location.reload();
    }
  };

  return {
    newtData: data,
    loadablePageCount: count,
    getTotalNewtQuery,
    metrics,
    page,
    updatePage,
    totalPage,
    limit,
    updateLimit,
    updateSearch,
    logs,
    uploadFiles,
    deleteData,
    isLoading,
  };
};;;;;
