import { createAPIConfig } from "allegro-api/lib/config/http";
import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { createNewtZeroAPI } from "src/api";
import { createServerBroweserUi } from "src/cheetah/definitions/listPages/serverBrowserUi";
import { useMyRole } from "src/portal/hooks/useMyRole";
import { useAuth } from "src/root";
import { getServer } from "src/xerver-room/api/server";

export const useServerDetail = () => {
  const auth = useAuth();
  const [serverDetail, setMetricDetail] = useState<any>();
  const [initialized, setInitialized] = useState(false);
  const { canDeleteServer, canUpdateServer } = useMyRole();
  const params = useParams();

  const newtzero = createNewtZeroAPI();

  const serverBrowserUi = createServerBroweserUi(canDeleteServer);

  const initialize = useCallback(async () => {
    const server = await getServer(params.itemId ?? "");
    setMetricDetail(server);
    setInitialized(true);
  }, [params.itemId]);

  useEffect(() => {
    if (!initialized) initialize();
  }, [initialize, initialized, newtzero, params.itemId, serverBrowserUi.newtQ]);

  return {
    serverDetail,
    canUpdateServer,
    initialized,
  };
};
