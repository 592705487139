import { ASFormLabel } from "allegro-low-code-components";
import { ASInputBase, ASButton } from "allegro-ui";
import React, { Component, ReactElement } from "react";
import styled from "styled-components";

const Block = styled.div`
  margin-bottom: 16px;
  > *:last-child {
    margin-bottom: 0px;
  }
`;

const BlockBody = styled.section`
  width: 100%;
  margin-top: 6px;
  /* margin-bottom: 24px; */
  /* background: ${(props) => props.theme.bgPrimaryColor}; */
  padding: 24px;
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  border-bottom: 1px solid #333;
  /* background: linear-gradient(145deg, #2d2d2d, #262626);
  box-shadow: 5px 5px 10px #1e1e1e, -5px -5px 10px #363636; */
  border: 1px solid #525252;
  h3 {
    margin-bottom: 8px;
  }
`;

const BlockTitle = styled.h2`
  font-size: 16px;
  font-weight: normal;
  margin-left: 12px;
  /* border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor}; */
  /* margin: 30px 0 16px 0; */
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.textColor};
  > svg {
    margin-right: 6px;
  }
  /* border-top: 1px solid #535353; */
`;

type Props = {
  title: string | ReactElement;
};

export const XRSettingBlock: React.FC<Props> = (props) => {
  const { title } = props;

  return (
    <Block>
      <BlockTitle>{title}</BlockTitle>
      <BlockBody>{props.children}</BlockBody>
    </Block>
  );
};
