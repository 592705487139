import React from "react";
import { OEActivityListPageTemplate } from "src/opt-editor/components/templates/OEActivitiyListPageTemplate";
import { NZGlobalNav } from "../organisms/NZGlobalNav";

type Props = {};
export const NTActivityPage: React.FC<Props> = (props) => {
  return (
    <div>
      <NZGlobalNav />
      <OEActivityListPageTemplate />
    </div>
  );
};
