//Libs
import React from "react";
import styled from "styled-components";
//Components
import PeopleIcon from "@material-ui/icons/People";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import {
  MDGlobalNavDesktopItem,
  Nav,
} from "src/medican/components/organisms/MDGrobalNavDesktop";

//States
//Types

const CenterContents = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
  max-width: 1400px;
`;
const LeftMenu = styled.div`
  padding: 24px 24px;
  color: ${(props) => (props.theme.darkMode ? "white" : "#222")};
  background: ${(props) => props.theme.bgPrimaryColor};
  width: 30%;
  /* padding-right: 30px; */
  max-width: 350px;
  /* margin-right: 30px; */
`;

const MenuGroup = styled.ul`
  > * {
    margin-bottom: 10px;
  }
  margin-bottom: 24px;
  border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};
`;

const Contents = styled.div`
  /* padding: 24px; */
  width: 70%;
`;

const MenuHeader = styled.h2`
  font-size: 14px;
  opacity: 1;
  font-weight: bold;
  margin-left: 8px;
  margin-bottom: 12px;
`;

type Props = {};

export const PTSettingNav: React.FC<Props> = (props) => {
  return (
    <CenterContents>
      <LeftMenu>
        <MenuGroup>
          <MenuHeader>Account Management</MenuHeader>
          <Nav>
            <MDGlobalNavDesktopItem
              title="Users"
              link="/settings/teams"
              icons={PeopleIcon}
            />
            <MDGlobalNavDesktopItem
              title="Group"
              link="/settings/groups"
              icons={BusinessOutlinedIcon}
            />
            <MDGlobalNavDesktopItem
              title={"Profile"}
              icons={PersonOutlineIcon}
              link="/settings/profile"
            />
            <MDGlobalNavDesktopItem
              title={"Security"}
              link={"/settings/security"}
              icons={VpnKeyOutlinedIcon}
            />
          </Nav>
        </MenuGroup>
      </LeftMenu>
      <Contents>{props.children}</Contents>
    </CenterContents>
  );
};
