import {
  atom,
  atomFamily,
  selector,
  useRecoilValue,
  useRecoilState,
  useRecoilCallback,
} from "recoil";
import React, { useState } from "react";
import { defaultScienceFile, ScienceFile } from "../models/ScienceFSItem";

import { OECodeEditor, OEFileEditor } from "../components/organisms";

/**
 * Returns an iterator that iterates integers in [start, end).
 */
export const range = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (v, k) => k + start);

export const editorPaneState = atomFamily<ScienceFile, number>({
  key: "editorPaneState",
  default: (paneId) => {
    return defaultScienceFile;
  },
});

export const cursorPaneIdState = atom({
  key: "cursorPaneIdState",
  default: 0,
});

export const visiblePaneCountState = atom({
  key: "visiblePaeCount",
  default: 1,
});

export const usePane = (paneId: number) => {
  const cursorPaneId = useRecoilValue(cursorPaneIdState);
  const [pane, _setPane] = useRecoilState(editorPaneState(paneId));
  const isActive = () => [paneId === cursorPaneId];

  const setFile = (file: ScienceFile) => {
    _setPane(file);
  };

  return {
    isActive,
    pane,
    setFile,
  };
};

export const usePaneLayoutSwitcher = () => {
  const [visiblePaneCount, setVisiblePaneCount] = useRecoilState(
    visiblePaneCountState
  );
  const [cursorPaneId, setCursorPaneId] = useRecoilState(cursorPaneIdState);
  const [layoutType, _setLayoutType] =
    useState<"vertical" | "horizontal">("vertical");

  const toOneScreen = () => {
    setVisiblePaneCount(1);
    console.log("set", 1);
    setCursorPaneId(0);
  };

  const splitVertical = () => {
    setVisiblePaneCount(2);
    console.log("set", 2);
    _setLayoutType("vertical");
  };

  return { toOneScreen, splitVertical, layoutType, visiblePaneCount };
};

export const paneFileState = atomFamily<ScienceFile | null, number>({
  key: "paneFileState",
  default: null,
});

export const paneContentState = atomFamily<any, number>({
  key: "paneContentsState",
  default: <></>,
});

export const paneContentsSelector = selector({
  key: "paneContentsSelector",
  get: ({ get }) => {
    const count = get(visiblePaneCountState);
    const ids = [...range(0, count)];
    return ids.map((id) => {
      return get(paneContentState(id));
    });
  },
});

const _getFileExtention = (fileName: string = "") => {
  const position = fileName.lastIndexOf(".");
  return fileName.slice(position + 1);
};

export const useFileOpener = () => {
  // const cursorPaneId = useRecoilValue(cursorPaneIdState);

  const defaultEditor = OEFileEditor;
  const mdEditor = OEFileEditor;
  const jspyEditor = OECodeEditor;

  const openFile = useRecoilCallback(
    ({ set, snapshot }) =>
      async (file: ScienceFile) => {
        const fileName = file.name;
        let EditorComponent = defaultEditor;
        const cursorPaneId = await snapshot.getPromise(cursorPaneIdState);
        if (fileName) {
          const ext = _getFileExtention(fileName).toLowerCase();
          switch (ext) {
            case "md":
              EditorComponent = mdEditor;
              break;
            case "txt":
            case "js":
            case "py":
            case "json":
              EditorComponent = jspyEditor;
              break;
            default:
              EditorComponent = jspyEditor;
          }
        }
        set(paneFileState(cursorPaneId), file);
        set(paneContentState(cursorPaneId), <EditorComponent file={file} />);
      },
    []
  );

  return { openFile };
};
