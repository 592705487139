import styled from "styled-components";
import { ChipProps } from "@material-ui/core";
import { ASChip } from "allegro-ui";
import { lighten, darken } from "polished";

//Omit< T, 'propA' | 'propsB' ,,,>Tから特定のプロパティを除外した型
export type FTStatusChipProps = Omit<ChipProps, "kind"> & {
  value: number;
};

const defaultProps: FTStatusChipProps = {
  value: 0,
};
type Attrs = {
  text_color: string;
  bg_color: string;
};

type StyledProps = FTStatusChipProps & Attrs;

export const S = styled(ASChip).attrs(
  (props: FTStatusChipProps = defaultProps): Attrs => {
    //使用する色を決定する
    const color: string = (() => {
      if (props.value === 1) return "#fc912d";
      else if (props.value === 2) return "#6e26f3";
      else if (props.value === 3) return "#999";
      else return "#55ca72";
    })();

    //文字の色、背景色を指定されたデザインに設定する
    const text_color: string = (() => {
      return color;
    })();
    const bg_color: string = (() => {
      return color;
    })();
    return { text_color, bg_color };
  }
)<StyledProps>`
  color: ${(props) =>
    props.theme.darkMode ? props.bg_color : props.text_color};
  background-color: ${(props) =>
    props.theme.darkMode
      ? darken(0.5, props.bg_color)
      : lighten(0.32, props.bg_color)};
  border: 1px solid
    ${(props) =>
      props.theme.darkMode ? props.bg_color : lighten(0.32, props.bg_color)};
`;

type Props = {
  value: number;
};

const statuses = ["支援中", "虐待対応業務", "終結", "未評価"];

export const FTStatusChip: React.FC<Props> = ({ value, children }) => {
  return (
    <S
      value={Number(value)}
      label={statuses[value]}
      design="onecolor"
      kind={"positive"}
    ></S>
  );
};
