import axios from "axios";

export type APIConfigProps = {
  baseUrl: string;
  apikey?: string;
  userNoText?: string;
};

export const createAPIConfig = (props: APIConfigProps) => {
  return axios.create({
    baseURL: `${props.baseUrl}`,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `${props.apikey}`,
      "User-Id": `${props.userNoText}`,
    },
    timeout: 1200000,
    // timeout: 5000,
  });
};
