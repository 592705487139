import React from "react";
import styled from "styled-components";
import { ToggleButton, ToggleButtonProps } from "@material-ui/lab";
import { darken, lighten } from "@material-ui/core";

export type ASToggleButtonProps = {} & ToggleButtonProps;

const StyledToggleButton = styled(ToggleButton)`
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
  &.Mui-selected {
    color: ${(props) => props.theme.primaryOnText};
    background: ${(props) => props.theme.primaryColor};
  }
  &.MuiToggleButton-root:hover {
    color: ${(props) => props.theme.primaryOnText};
    background: ${(props) => darken(props.theme.primaryColor, 0.2)};
  }
  border-color: ${(props) =>
    lighten(props.theme.darkMode ? "#000000" : "#7d7d7d", 0.4)};
`;

export const ASToggleButton: React.FC<ASToggleButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <>
      <StyledToggleButton {...props}>{children}</StyledToggleButton>
    </>
  );
};
