import React from "react";
import { NegativeLog, PositiveLog, WarnLog } from "../styles";

export const useLogArea = () => {
  const [logs, setLogs] = React.useState<JSX.Element[]>([]);

  const addLogLine = (
    str: string,
    logType?: "positive" | "negative" | "warn"
  ) => {
    switch (logType) {
      case "positive":
        setLogs((logs) => [...logs, <PositiveLog>{str}</PositiveLog>]);

        break;
      case "negative":
        setLogs((logs) => [...logs, <NegativeLog>{str}</NegativeLog>]);

        break;
      case "warn":
        setLogs((logs) => [...logs, <WarnLog>{str}</WarnLog>]);

        break;

      default:
        setLogs((logs) => [...logs, <p>{str}</p>]);
    }
  };

  const clearLogs = () => {
    setLogs([]);
  };

  return { logs, clearLogs, addLogLine };
};
