import React from "react";
import { XRInsightPageTemplate } from "src/xerver-room/components/templates/XRInsightPageTemplate";
import { NTGlobalNav } from "../organisms/NTGlobalNav";

type Props = {};
export const NTAdminXerviceBrowserPage: React.FC<Props> = (props) => {
  return (
    <div>
      <NTGlobalNav />
      <XRInsightPageTemplate />
    </div>
  );
};
