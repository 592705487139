import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import TreeItem from "@material-ui/lab/TreeItem";
import { TreeModel } from "src/opt-editor/models";
import { lighten, darken } from "@material-ui/core/styles/colorManipulator";
import { useRecoilValue, useRecoilState, useRecoilCallback } from "recoil";
import scienceFolder from "../../recoils/ScienceFolderState";
import scienceFile from "../../recoils/ScienceFileState";

import { OECreateFolderButton } from "./OECreateFolderButton";
import { useNavigate } from "react-router-dom";
import { useDrag, useDrop } from "react-dnd";
import { getTheme } from "allegro-ui";

const Container = styled.div<{ isProject: boolean; isOver: boolean }>`
  margin-top: ${(props) => (props.isProject ? "12px" : 0)};
  position: relative;
  background: ${(props) =>
    props.isOver ? props.theme.primaryColor : "rgba(0,0,0,0)"};
`;

const StyledTreeItem = styled(TreeItem)`
  /* display: flex;
  alignitems: center;
  fontsize: 15px; */

  padding: 4px;
  &:hover {
    background: ${(props) => darken(props.theme.bgPrimaryColor, 0.1)};
  }

  &.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: ${(props) => darken(props.theme.bgPrimaryColor, 0.3)};
  }

  /* https://v4.mui.com/components/tree-view/#tree-view */
  /* &:focus > $content, &$selected > $content: {
    backgroundcolor: none;
  }
  &:focus > $content $label, &:hover > $content $label, &$selected > $content $label: {
    backgroundcolor: none;
  } */
`;

const CodeBoxBadge = styled.div`
  margin: 3px;
  padding: 0px 4px;
  height: 20px;
  background-color: #e02a2a;
  border-radius: 5%;
  color: #ffffff;
  font-size: 12px;
`;

const ProjectMark = styled.div`
  width: 13px;
  position: relative;
  /* background: ${(props) => props.theme.primaryColor}; */
  /* display: flex;
  align-items: center; */
  ::after {
    content: " ";
    position: absolute;
    border-radius: 2px;
    left: 0;
    top: 8px;
    width: 8px;

    height: 8px;
    background: ${(props) => props.theme.primaryColor};
  }
`;

const TreeLabel = styled.div`
  display: flex;
  alignitems: center;
  font-size: 14px;
  font-weight: 300;
`;

const ButtonLayout = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

type Props = {
  // treeItem: Folder | CodeBox | Project;
  treeItem: TreeModel;
  onRightClick: (evt: React.MouseEvent, itemId: number) => void;
};

export const OETreeItem: React.FC<Props> = ({
  treeItem,
  onRightClick,
  children,
}) => {
  const [showCreateFolderButton, setShowCreateFolderButton] = useState(false);
  const renderBadge =
    treeItem.type === "CodeBox" ? <CodeBoxBadge>Code</CodeBoxBadge> : "";
  const cursorFolderId = useRecoilValue(scienceFolder.cursorItemIdState);

  const [folder, setFolder] = useRecoilState(
    scienceFolder.item(treeItem.itemId)
  );
  let history = useNavigate();

  const handleDrop = (item: any) => {
    moveItem(item, treeItem.itemId);
  };

  const moveItem = useRecoilCallback(
    ({ set, snapshot }) =>
      (item: any, parentId: number) => {
        const newItem = {
          ...item,
          parent: parentId,
        };
        switch (item.type) {
          case "File":
            set(scienceFile.item(item.itemId), newItem);
            break;
          case "Folder":
            set(scienceFolder.item(item.itemId), newItem);
        }
      }
  );

  const isAncestors = useRecoilCallback(
    ({ snapshot }) =>
      async (source: any, target: TreeModel) => {
        let cnt = 0;
        let pid = target.parent;
        while (pid && pid > 0 && cnt < 1000) {
          if (source.itemId === pid) {
            return true;
          }
          pid = (await snapshot.getPromise(scienceFolder.item(pid))).parent;
          cnt += 1;
        }
        return false;
      }
  );

  const [dragCollected, drag, preview] = useDrag(() => ({
    item: {
      ...folder,
    },
    canDrag: folder.type !== "Project",
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0.1 : 1,
      canDrag: monitor.canDrag,
      highlighted: monitor.isDragging(),
    }),
  }));

  const [collected, drop] = useDrop(() => ({
    accept: ["Project", "Folder", "File"],
    drop: async (_item: unknown, monitor) => {
      // const didDrop = monitor.didDrop();
      // if (didDrop) {
      const monitorItem = monitor.getItem();
      const checker = await isAncestors(monitorItem, treeItem);
      if (!checker) {
        handleDrop(monitorItem);
      }
      //   return;
      // }
    },

    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
    canDrop: (item, monitor) => {
      return folder.itemId !== monitor.getItem().itemId;
    },
  }));

  useEffect(() => {
    if (cursorFolderId === treeItem.itemId) {
      setShowCreateFolderButton(true);
    } else setShowCreateFolderButton(false);
  }, [cursorFolderId, treeItem.itemId]);
  // const self = useRecoilValue(scienceFolder.item(treeItem.folderId));

  return dragCollected.isDragging ? (
    <div></div>
  ) : (
    <Container
      ref={drag}
      isProject={treeItem.type === "Project"}
      isOver={collected.isOver}
    >
      <StyledTreeItem
        key={treeItem.itemId}
        nodeId={String(treeItem.itemId)}
        label={
          <TreeLabel ref={drop}>
            {treeItem.type === "Project" ? <ProjectMark /> : <></>}
            {treeItem.name}
            {renderBadge}
          </TreeLabel>
        }
        onContextMenu={(evt) => {
          onRightClick(evt, treeItem.itemId);
        }}
        onLabelClick={(evt) => {
          evt.preventDefault();
          history("/knowledge");
        }}
      >
        {children}
        {/* {Array.isArray(treeItem.child) ? renderTree(treeItem.child) : null} */}
      </StyledTreeItem>
      {showCreateFolderButton ? (
        <ButtonLayout>
          <OECreateFolderButton />
        </ButtonLayout>
      ) : null}
    </Container>
  );
};
