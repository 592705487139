import React from "react";
import { NTGlobalNav } from "../organisms/NTGlobalNav";
import { XROptXBrowsePageTemplate } from "src/xerver-room/components/templates/XROptXBrowserPageTemplate";

type Props = {};
export const NTOptXBrowsePage: React.FC<Props> = (props) => {
  return (
    <div>
      <NTGlobalNav />

      <XROptXBrowsePageTemplate />
    </div>
  );
};
