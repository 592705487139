import styled from "styled-components";
import { Card } from "@material-ui/core";

export type ASCardProps = {
  bg_color?: string;
  corner?: number;
};
export const ASCard = styled(Card)<ASCardProps>`
  border-radius: ${(props) =>
    props.corner ? props.corner : props.theme.borderRadius}px;
  box-shadow: none;
  background-color: ${(props) => props.theme.cardColor};
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
`;
