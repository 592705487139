import { ASInputBase, ASButton } from "allegro-ui";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  > * {
    margin-top: 24px;
  }
  > *:first-child {
    margin-top: 0px;
  }
  > p {
    line-height: 1.5;
    text-align: left;
  }
`;

const ErrorText = styled.p`
  color: ${(props) => props.theme.negativeColor};
`;

const SubmitButton = styled(ASButton)`
  width: 100%;
`;

type Props = {
  onSubmit: (email: string, contractor: string) => void;
};

export const PTPrepareResetPasswordForm: React.FC<Props> = (props) => {
  const [email, setEmail] = React.useState("");
  const [contractor, setContractor] = React.useState("");
  const [errMsg, setErrMsg] = React.useState("");

  return (
    <Container>
      <p>
        ご登録されているメールアドレスをご入力ください。
        <br />
        パスワードの再設定手続きのメールをお送りいたします。
      </p>
      <ASInputBase
        fullWidth
        id="contractor"
        kind="digicho"
        placeholder="コントラクタを入力してください"
        defaultValue=""
        onChange={(e) => {
          setContractor(e.target.value);
        }}
      ></ASInputBase>
      <ASInputBase
        fullWidth
        id="email"
        kind="digicho"
        placeholder="メールアドレスを入力してください"
        defaultValue=""
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      ></ASInputBase>
      <SubmitButton
        kind="primary"
        onClick={() => props.onSubmit(email, contractor)}
      >
        送信する
      </SubmitButton>

      {errMsg ? <ErrorText>{errMsg}</ErrorText> : null}
    </Container>
  );
};
