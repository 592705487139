//Libs
import React from "react";
import { paneFileState } from "src/opt-editor/recoils/EditorPaneState";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

//Components
//States
//Types

const tabHeight = 48;
const footerHeight = 36;

type Props = {
  active: boolean;
  author: string | undefined;
  children: React.ReactNode;
  paneId: number;
};

const Container = styled.div`
  height: 100%;
  border-left: 1px solid ${(props) => props.theme.secondaryBorderColor};
`;

const Tab = styled.div<{ active: boolean }>`
  background: ${(props) => props.theme.bgSecondaryColor};
  display: inline-block;
  margin-left: 10px;
  min-width: 150px;
  margin-top: 10px;
  border-radius: 8px 8px 0 0;
  height: calc(${tabHeight}px - 10px);
  line-height: calc(${tabHeight}px - 10px);
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  border-bottom: 2px solid
    ${(props) => (props.active ? props.theme.primaryColor : "#444")};
  /* width: 100%; */
`;

const ContentArea = styled.div`
  padding-top: 8px;

  background: ${(props) => props.theme.bgSecondaryColor};
  height: calc(100% - ${tabHeight}px - ${footerHeight}px);
  overflow: scroll;
`;

const Footer = styled.div`
  width: 100%;
  background: ${(props) => props.theme.bgSecondaryColor};
  height: ${footerHeight}px;
  display: flex;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  color: #6f6f6f;
`;

const Author = styled.div`
  margin-left: auto;
`;

export const OEEditorPane: React.VFC<Props> = ({
  active,

  author,
  children,
  paneId,
}) => {
  const file = useRecoilValue(paneFileState(paneId));

  return (
    <Container>
      <Tab active={active}>{file?.name}</Tab>
      <ContentArea>{children}</ContentArea>

      <Footer>
        Edit Mode
        <Author>Author:{!author || author === "" ? "Unknown" : author}</Author>
      </Footer>
    </Container>
  );
};
