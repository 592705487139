import { api } from "../../api/http";

export const dataGate = async (
  assetTags: string[],
  data: any,
  upsert: boolean = true,
  upsertTags: boolean = true,
  sendToTopData: boolean = true,
  sendToRawData: boolean = false,
  sendToTxnData: boolean = false
) => {
  try {
    const res = await api({
      method: "POST",
      url: `/contractors/${sessionStorage.contractorNo}/datagate`,
      params: {
        apikey: sessionStorage.token,
        upsert: upsert,
        upsertTags: upsertTags,
        assetTags: assetTags,
        sendToTopData: sendToTopData,
        sendToRawData: sendToRawData,
        sendToTxnData: sendToTxnData,
      },
      data: data,
    });
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const streamGate = async (
  assetTags: string[],
  data: any,
  upsert: boolean = true,
  upsertTags: boolean = true,
  sendToTopData: boolean = true,
  sendToRawData: boolean = false,
  sendToTxnData: boolean = false
) => {
  try {
    const res = await api({
      method: "POST",
      url: `/streamgate`,
      params: {
        apikey: sessionStorage.token,
        upsert: upsert,
        upsertTags: upsertTags,
        assetTags: assetTags,
        sendToTopData: sendToTopData,
        sendToRawData: sendToRawData,
        sendToTxnData: sendToTxnData,
      },
      data: data,
    });
    return res;
  } catch (err) {
    console.error(err);
  }
};
