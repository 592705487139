import { ASLowCodeFormDefinition } from "allegro-low-code-components";
import { api } from "src/api";

export const ciVariablesCreatingFormDefinition: ASLowCodeFormDefinition = {
  sections: [
    {
      title: "CI/CD 変数登録",
      description: "サーバー情報の登録をします。",
      fields: [
        {
          label: "コントラクタ",
          name: "contractor",
          type: "text",
          active: "",
          values: undefined,
          required: true,
          errorText: "コントラクタ名は必須です。",
        },
        {
          label: "サーバー名",
          name: "serverName",
          type: "text",
          active: "",
          values: undefined,
          required: true,
          errorText: "サーバー名は必須です。",
        },
        {
          label: "カテゴリ",
          name: "category",
          type: "selection",
          active: "Jenkins",
          values: ["Jenkins", "Ansible", "OpenResty"],
        },

        {
          label: "キー",
          name: "key",
          type: "text",
          active: "",
          values: undefined,
          required: true,
          errorText: "キーは必須です。",
        },
        {
          label: "値",
          name: "value",
          type: "text",
          active: "",
          values: undefined,
          required: true,
          errorText: "値は必須です。",
        },
      ],
    },
  ],
  onSubmit: async (d: any) => {
    //互換性のあるアセットを作成する。
    console.log(d);

    console.log(d);
    const body = {
      non_numeric_contractor: d["contractor"],
      non_numeric_server_name: d["serverName"],
      non_numeric_category: d["category"],
      non_numeric_key: d["key"],
      non_numeric_value: d["value"],
    };

    try {
      const res = await api({
        method: "POST",
        url: `/contractors/${sessionStorage.contractorNo}/datagate`,
        params: {
          apikey: sessionStorage.token,
          upsert: true,
          upsertTags: true,
          assetTags: ["CICDVariables"],
          sendToTopData: true,
        },
        data: body,
      });
      console.log(res);
      if (res.status === 200) {
        window.location.reload();
      }
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
};
