import { immerable, produce } from "immer";

//typescriptにおいて、厳密に型定義をすることによってより保守性が高まるので、以下厳密な型定義を行っている。
const hiddenCodes = [2201, 2202, "死亡", "死亡 全部", "死亡 一部"];

type Evaluation =
  | "問題なし"
  | "気になる"
  | "要経過観察"
  | "虐待の危惧あり"
  | "虐待あり"
  | "未評価";

type Status = "支援中" | "虐待対応業務" | "終結" | "未評価";

type UpdateReason = "転入" | "転入通知" | "死亡" | "転出" | "不明";

type DataGroup = string;

type AIArrow = "上昇" | "下降" | "現状維持";

const evaluations: Array<Evaluation> = [
  "問題なし",
  "気になる",
  "要経過観察",
  "虐待の危惧あり",
  "虐待あり",
  "未評価",
];

const statuses: Array<Status> = ["支援中", "虐待対応業務", "終結", "未評価"];

export const dataGroupLabels: Array<DataGroup> = [
  "0:ラベル未作成",
  "底-A",
  "高-A",
  "中-A",
  "4:ラベル未作成",
  "高-B",
  "6:ラベル未作成",
  "7:ラベル未作成",
  "8:ラベル未作成",
  "9:ラベル未作成",
  "10:ラベル未作成",
  "11:ラベル未作成",
];

// const aiarrow: Array<AIArrow> = ["down", "up", "stay", "未評価"];

//保守性を虐待予防的支援業務データ（児童一覧に表示するデータ群）のフロントエンドにおける型定義をここで行っている。
//以後この型をtypescriptとして使用していく。

interface IChildListItem {
  atena: string;
  setai: string;
  kana: string;
  name: string;
  foreignerName: string;
  foreignerNameKana: string;
  hidden: boolean;
  birthday: string;
  relationship: string;
  sex: string;
  address: {
    town: string;
    banchi: string;
    edaban: string;
    edaban3: string;
    koeda: string;
    fullName: string;
    zip: string;
  };
  age: number;
  lastEvaluationDate: string;
  evaluationNum: number;
  evaluationLabel: Evaluation;
  statusNum: number;
  statusLabel: Status;
  updateReason: UpdateReason;
  predictionOneNum: number;
  deleteCode: number;
  predictionTwoNum: number;
  predictionOneChange: AIArrow;
  predictionTwoChange: AIArrow;
  lastPredictionDate: string;

  dataGroupNum: number;
  dataGroupLabel: DataGroup;
  dataShortage: string[];
  hasDataShortage: boolean;
  xplot: number;
  yplot: number;
  unreadDataUpdate: boolean;
  isWatch: boolean;
  created: string;
  kenkan: string;
  rawdata: any;
  hasNewlyAddedData: boolean;
  newlyAddedDatas: string[];

  // groupPlotX: number;
  // groupPlotY: number;
  // lastAssesmentDate: string;
  // actionStatus: string;
}

//虐待予防的支援業務データの初期値をここに定める。

export const initialChildListItem: IChildListItem = {
  atena: "",
  setai: "",
  kana: "",
  name: "",
  foreignerName: "",
  foreignerNameKana: "",
  birthday: "",
  relationship: "夫",
  hidden: false,
  sex: "",
  address: {
    town: "",
    banchi: "",
    edaban: "",
    edaban3: "",
    koeda: "",
    fullName: "",
    zip: "",
  },
  age: 0,
  lastEvaluationDate: "",
  evaluationNum: 0,
  evaluationLabel: "未評価",
  predictionOneNum: 0,
  predictionTwoNum: 0,
  predictionOneChange: "上昇",
  predictionTwoChange: "下降",
  statusNum: 0,
  statusLabel: "支援中",
  updateReason: "不明",
  dataGroupLabel: "未評価",
  deleteCode: 0,
  dataGroupNum: 0,
  dataShortage: [],
  hasDataShortage: false,
  unreadDataUpdate: true,
  lastPredictionDate: "",
  xplot: -100,
  yplot: -100,
  isWatch: false,
  created: "",
  kenkan: "",
  rawdata: {},
  hasNewlyAddedData: false,
  newlyAddedDatas: [],
};

//年齢情報を取得するために、その人の誕生日から年齢を計算するアルゴリズムを書く。

var calcAge = function (birthdayStr: string) {
  if (birthdayStr.length !== 8) {
    return -1;
  }
  var d = new Date();
  var dStr =
    "" +
    d.getFullYear() +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    ("0" + d.getDate()).slice(-2);
  return Math.floor((parseInt(dStr) - parseInt(birthdayStr)) / 10000);
};

//実際に取得してきたクエリのデータをchildListItem型に変換するための設計図（クラス）を実装する。
//ChildList.tsファイルにおいて利用される。

export class ChildListItem implements IChildListItem {
  [immerable] = true;
  atena: string;
  setai: string;
  kana: string;
  name: string;
  hidden: boolean;
  foreignerName: string;
  foreignerNameKana: string;
  birthday: string;
  relationship: string;
  sex: string;
  address: {
    town: string;
    banchi: string;
    edaban: string;
    edaban3: string;
    koeda: string;
    fullName: string;
    zip: string;
  };
  age: number;
  lastEvaluationDate: string;
  evaluationNum: number;
  evaluationLabel: Evaluation;
  statusNum: number;
  statusLabel: Status;
  updateReason: UpdateReason;
  predictionOneNum: number;
  predictionTwoNum: number;
  predictionOneChange: AIArrow;
  predictionTwoChange: AIArrow;
  lastPredictionDate: string;
  dataGroupNum: number;
  deleteCode: number;
  dataGroupLabel: DataGroup;
  dataShortage: string[];
  hasDataShortage: boolean;
  xplot: number;
  yplot: number;
  unreadDataUpdate: boolean;
  isWatch: boolean;
  created: string;
  kenkan: string;
  rawdata: any;
  hasNewlyAddedData: boolean;
  newlyAddedDatas: string[];

  constructor(resData: {
    contents: {
      optdata: {
        non_numeric_address: string;
        non_numeric_address_lot_number: string;
        non_numeric_foreigner_easy_name_kana: string;
        non_numeric_foreigner_easy_name_kanji: string;
        non_numeric_foreigner_name: string;
        non_numeric_foreigner_name_kana: string;
        non_numeric_gender: string;
        non_numeric_gender_classification: string;
        non_numeric_name_kana: string;
        non_numeric_name_kanji: string;
        non_numeric_relation_code: number;
        non_numeric_relationship: string;
        non_numeric_resident_register_address_facility: string;
        non_numeric_resident_register_delete_code: number;
        non_numeric_resident_register_translation: string;
        non_numeric_resident_register_translation_code: number;
        non_numeric_resident_register_zip_code: number;
        non_numeric_resident_type: string;
        non_numeric_resident_type_code: number;
        non_numeric_translation_date: number;
        non_numeric_translation_dateofreport: number;
        non_numeric_translation_resident_register: any;
        non_numeric_translation_resident_register_code: number;
        numeric_birth_date: number;
        numeric_household_number: number;
        numeric_resident_register_code: number;
      };
    };
  }) {
    this.hidden = hiddenCodes.includes(
      resData.contents.optdata.non_numeric_translation_resident_register_code
    );
    this.setai =
      String(resData.contents.optdata.numeric_household_number) ??
      initialChildListItem.setai;
    this.atena =
      String(resData.contents.optdata.numeric_resident_register_code) ??
      initialChildListItem.atena;
    this.kana =
      resData.contents.optdata.non_numeric_name_kana ??
      initialChildListItem.kana;
    this.name =
      typeof resData.contents.optdata.non_numeric_name_kanji === "string"
        ? resData.contents.optdata.non_numeric_name_kanji
        : resData.contents.optdata.non_numeric_foreigner_name ??
          initialChildListItem.name;
    this.foreignerNameKana = initialChildListItem.foreignerNameKana;
    this.birthday =
      String(resData.contents.optdata.numeric_birth_date) ??
      initialChildListItem.birthday;
    this.relationship =
      resData.contents.optdata.non_numeric_relationship ??
      initialChildListItem.relationship;
    this.sex = initialChildListItem.sex;
    this.address = initialChildListItem.address;
    this.age =
      calcAge(String(resData.contents.optdata.numeric_birth_date)) ??
      initialChildListItem.age;
    this.lastEvaluationDate = initialChildListItem.lastEvaluationDate;
    this.evaluationNum = initialChildListItem.evaluationNum;
    this.foreignerName = initialChildListItem.foreignerName;
    this.evaluationLabel = initialChildListItem.evaluationLabel;
    this.deleteCode =
      resData.contents.optdata.non_numeric_resident_register_delete_code ??
      initialChildListItem.deleteCode;
    this.updateReason =
      resData.contents.optdata.non_numeric_translation_resident_register ??
      "不明";
    this.statusNum = initialChildListItem.statusNum;
    this.statusLabel =
      resData.contents.optdata.non_numeric_translation_resident_register ??
      initialChildListItem.statusLabel;
    this.predictionTwoNum = initialChildListItem.predictionTwoNum;
    this.predictionOneChange = initialChildListItem.predictionOneChange;
    this.predictionTwoChange = initialChildListItem.predictionTwoChange;
    this.lastPredictionDate = initialChildListItem.lastPredictionDate;
    this.dataGroupNum = initialChildListItem.dataGroupNum;
    this.dataGroupLabel = initialChildListItem.dataGroupLabel;
    this.dataShortage = initialChildListItem.dataShortage;
    this.hasDataShortage = initialChildListItem.hasDataShortage;
    this.xplot = initialChildListItem.xplot;
    this.yplot = initialChildListItem.yplot;
    this.unreadDataUpdate = initialChildListItem.unreadDataUpdate;
    this.isWatch = initialChildListItem.isWatch;
    this.created = initialChildListItem.created;
    this.kenkan = initialChildListItem.kenkan;
    this.hasNewlyAddedData = initialChildListItem.hasNewlyAddedData;
    this.predictionOneNum = initialChildListItem.predictionOneNum;
    this.newlyAddedDatas = initialChildListItem.newlyAddedDatas;
  }

  // constructor(resData: any) {
  //   const optdata = resData.contents.optdata;
  //   const rawdata = resData.contents.rawdata;

  //   try {
  //     this.rawdata = resData.contents.rawdata;
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   this.atena =
  //     optdata.numeric_resident_register_code ?? rawdata["宛名番号"] ?? "";
  //   this.setai = optdata.numeric_household_number ?? rawdata["世帯番号"] ?? "";
  //   this.kana = optdata.non_numeric_kana_name || "";
  //   this.name = optdata.non_numeric_name || optdata.non_numeric_nickname_kanji;
  //   this.foreignerName =
  //     optdata.non_numeric_foreigner_name ||
  //     optdata.non_numeric_foreigner_easy_name_kanji;
  //   this.foreignerNameKana =
  //     optdata.non_numeric_foreigner_name_kana ||
  //     optdata.non_numeric_foreigner_easy_name_kana ||
  //     "";

  //   this.sex = optdata.non_numeric_sex ?? "";
  //   this.age = optdata.numeric_birth_date
  //     ? calcAge(String(optdata.numeric_birth_date))
  //     : -1;
  //   this.birthday = shapeDateToWareki(optdata.numeric_birth_date) ?? "";
  //   this.relationship = optdata.non_numeric_relationship;
  //   this.lastEvaluationDate =
  //     shapeDateToWareki(optdata.non_numeric_risk_assesment_last_date) ?? "";
  //   this.evaluationNum =
  //     optdata.non_numeric_abuse_risk_evaluation_by_staff_id ?? 5;
  //   this.evaluationLabel = evaluations[this.evaluationNum] ?? "";
  //   this.statusNum = optdata.non_numeric_action_status_id ?? 3;
  //   this.statusLabel = statuses[this.statusNum] ?? "";
  //   this.predictionOneNum =
  //     Math.floor(optdata.numeric_prediction1_value * 100) ?? "s";
  //   this.predictionOneChange =
  //     optdata.non_numeric_prediction1_comparision_date ?? "現状維持";
  //   this.predictionTwoNum =
  //     Math.floor(optdata.numeric_prediction2_value * 100) ?? "s";
  //   this.predictionTwoChange =
  //     optdata.non_numeric_prediction2_comparision_date ?? "現状維持";
  //   this.lastPredictionDate =
  //     shapeDateToWareki(optdata.non_numeric_prediction1_last_predict_date) ??
  //     "";
  //   this.dataGroupNum = optdata.non_numeric_datagroup_id ?? 6;
  //   this.dataGroupNum =
  //     typeof this.dataGroupNum === "string" ? 6 : this.dataGroupNum;
  //   this.dataGroupLabel = dataGroupLabels[this.dataGroupNum] ?? "NO LABEL";
  //   this.dataShortage = rawdata["データ不足数"] ?? [];
  //   this.hasDataShortage =
  //     Boolean(optdata.non_numeric_has_data_shortage) ?? false;
  //   this.unreadDataUpdate =
  //     Boolean(optdata.non_numeric_has_unread_data_updates) ?? true;
  //   this.xplot = optdata.numeric_group_coord_x ?? -100;
  //   this.yplot = optdata.numeric_group_coord_y ?? -100;
  //   this.isWatch =
  //     Boolean(optdata.non_numeric_is_watch) ??
  //     Boolean(rawdata["ウォッチ"]) ??
  //     false;
  //   this.created = optdata["システム連動日"] ?? "";
  //   this.kenkan = optdata["健管番号"] ?? "";
  //   this.hasNewlyAddedData =
  //     optdata.non_numeric_has_newly_added_data ??
  //     rawdata["新着データあり"] ??
  //     false;
  //   this.newlyAddedDatas =
  //     optdata.non_numeric_newly_added_datas ??
  //     rawdata["新着データリスト"] ??
  //     [];
  //   this.address = {
  //     town:
  //       optdata.non_numeric_Current_address_Town_name_large_letter_code ||
  //       optdata.non_numeric_resident_register_address ||
  //       "",
  //     banchi:
  //       optdata.non_numeric_Current_address_address_code ||
  //       optdata.non_numeric_resident_register_address_lot_number ||
  //       "",
  //     edaban: optdata.non_numeric_Current_address_Branch_number_code || "",
  //     edaban3: optdata.non_numeric_Current_address_Branch_number_3_code ?? "",
  //     koeda: optdata.non_numeric_Current_address_twig_code ?? "",
  //     fullName: optdata.non_numeric_Current_address ?? "",
  //     zip:
  //       optdata.non_numeric_Current_address_Zip_code ||
  //       optdata.numeric_zip_code ||
  //       "",
  //   };

  // }

  toggleWatch() {
    return produce(this, (draft) => {
      draft.isWatch = !draft.isWatch;
    });
  }
}
