import React from "react";
import { ASButton } from "allegro-ui";
import styled from "styled-components";
import { useAuth } from "src/root/UseAuth";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
`;

const Right = styled.div`
  margin-left: auto;
`;

const AppTitle = styled.h1`
  font-size: 20px;
`;

const Caption = styled.h3`
  font-size: 14px;
  margin-left: 12px;
  font-weight: normal;
`;

export const OEHeader: React.FC = () => {
  const auth = useAuth();
  return (
    <Container>
      <AppTitle>OPTIMIZE EDITOR</AppTitle>
      <Caption>powered by AllegroSmart</Caption>
      <Right>
        <ASButton
          kind="secondary"
          textcolor="#fff"
          onClick={() => {
            if (auth?.logout) auth.logout();
          }}
        >
          LOGOUT
        </ASButton>
      </Right>
    </Container>
  );
};
