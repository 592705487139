import React from "react";
import { ASEasyTable } from "allegro-ui";
import { FTDashBoardWidget } from ".";
import styled from "styled-components";
import Masonry from "react-masonry-css";
import { PersonalData } from "src/fortress/models";

const MasonryGrid = styled(Masonry)`
  display: flex;
  margin-left: -30px;
  margin-top: -30px;
`;

const MasonryItem = styled.div`
  margin-left: 30px;
  margin-top: 30px;
`;

const CaptionGroup = styled.div`
  margin-top: 14px;
  color: #aaa;
  font-size: 10px;
  padding: 8px 0 0 0;
  border-top: 1px solid #eee;
  max-width: 430px;
  span {
    display: block;
    line-height: 1;
    overflow-wrap: break-word;
  }
`;

const StyledTitle = styled.div`
  margin-top: 55px;
  margin-bottom: 55px;
  font-size: 20px;
  font-weight: bold;
  margin-left: 7px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
`;

type Props = {
  familyPersonalDatas: PersonalData[];
};

//児童詳細画面の家族データのui。
//住民記録、生活保護、乳児健診、3歳児検診など、合計27個のデータが存在するが、そのデータは日々府中市職員の人びとがexcelで入力したものをsmartEdgeサーバーに登録されたものである。
//そのデータをallegrobotを介してこのUIに出力している。

export const FTFamilyKihonDataPanel: React.FC<Props> = ({
  familyPersonalDatas,
}) => {
  const widgets =
    familyPersonalDatas.length > 0 ? (
      familyPersonalDatas.map((personalData: PersonalData, id) => {
        return (
          <MasonryItem key={String(id)}>
            <FTDashBoardWidget
              title={personalData.label}
              children={
                <>
                  <ASEasyTable
                    key={`k-table-${personalData.label}${id}`}
                    tableData={personalData.data}
                  ></ASEasyTable>
                  <CaptionGroup>
                    <span>{personalData.lastUpdate}</span>
                    <span>{personalData.tags}</span>
                  </CaptionGroup>
                </>
              }
            ></FTDashBoardWidget>
          </MasonryItem>
        );
      })
    ) : (
      <></>
    );

  return (
    <>
      <StyledTitle>世帯データ詳細</StyledTitle>
      <MasonryGrid
        breakpointCols={2}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {widgets}
      </MasonryGrid>
    </>
  );
};
