import React from "react";
import { ConfirmModalContents } from "../molecules";

type Props = {
  onCancel: () => void | Promise<void>;
  onSubmit: () => void | Promise<void>;
};

//モニター一覧において、モニターを削除する用のコンポーネント
export const DeleteConfirmModalContents: React.FC<Props> = (props) => {
  return (
    <ConfirmModalContents
      cancelLabel="Cancel"
      submitLabel="Delete"
      onCancel={props.onCancel}
      onSubmit={props.onSubmit}
    >
      {props.children ?? "削除しますか？"}
    </ConfirmModalContents>
  );
};
