import React from "react";
import { ASButton, ASIconButton, ASInputBase } from "allegro-ui";
import styled from "styled-components";
import { useAuth } from "src/root/UseAuth";
import { IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {
  searchContainerState,
  monitorSearchWordState,
} from "src/kinzaza/recoils";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import ArrowBack from "@material-ui/icons/ArrowBackIosOutlined";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 16px;
`;

const StyledButton = styled(ASIconButton)`
  margin-right: 8px;
`;

const Right = styled.div`
  margin-left: 30px;
`;

const AppTitle = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #fff;
`;

const Caption = styled.h3`
  font-size: 14px;
  margin-left: 12px;
  font-weight: normal;
  color: #fff;
`;

const Search = styled.div`
  background: ${(props) => props.theme.secondaryColor};
  margin-left: auto;
  position: relative;
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  padding: 0 8px;
  min-height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Width = styled.div`
  margin-left: auto;
`;

export const Header: React.FC = () => {
  const history = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const setSearchWord = useSetRecoilState(monitorSearchWordState);
  const search = useRecoilValue(searchContainerState);
  const Backbutton = () => {
    history(-1);
  };

  const searchContent = search ? (
    <Search>
      <ASInputBase
        kind="secondary"
        placeholder="Monitor name"
        onChange={(event) => {
          setSearchWord(event.target.value);
        }}
      />
      <IconButton size="small" type="submit" aria-label="search">
        <SearchIcon />
      </IconButton>
    </Search>
  ) : (
    <>
      <Width></Width>
    </>
  );

  return location ? (
    <Container>
      {location.pathname === "/monitor" || location.pathname === "/" ? null : (
        <StyledButton
          size="small"
          kind="white"
          type="submit"
          onClick={() => Backbutton()}
        >
          <ArrowBack />
        </StyledButton>
      )}
      <AppTitle>DataView</AppTitle>
      {/* <Caption>powered by AllegroSmart</Caption> */}
      {/* {searchContent} */}
      {/* <Right>
        <ASButton
          kind="secondary"
          textcolor="#fff"
          onClick={() => {
            if (auth?.logout) auth.logout();
          }}
        >
          LOGOUT
        </ASButton>
      </Right> */}
    </Container>
  ) : (
    <>no location</>
  );
};
