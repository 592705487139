import { AxiosInstance } from "axios";
import { ConfirmSignupInput } from "../models/SignupInput";

type Args = {
  contractor: string;
  email: string;
  customSignupUrl?: string;
};

export const signup = async (instance: AxiosInstance, data: Args) => {
  const res = await instance({
    method: "POST",
    url: "/signup",
    headers: { "Content-Type": "application/json" },
    data: data,
  });

  return res;
};

export const confirmSignup = async (
  instance: AxiosInstance,
  data: ConfirmSignupInput
) => {
  const res = await instance({
    method: "POST",
    url: `/signup/confirm`,
    data: data,
  });

  return res;
};
