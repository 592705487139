import React from "react";
import styled from "styled-components";
import CHServerSinglePageTemplate from "src/cheetah/components/templates/CHServerSinglePageTemplate";
import { useParams } from "react-router-dom";
import { NTMainViewTemplate } from "../templates/NTMainViewTemplate";
import { Page } from "@react-pdf/renderer";

const Container = styled.div`
  padding: 25px;
`;

const PageTitle = styled.h1`
  font-size: 24px;
  font-weight: bold;
  padding: 24px 0;
`;

type Props = {};

export const NTServerSinglePage: React.FunctionComponent<Props> = ({
  ...props
}) => {
  const params = useParams();

  return params.itemId ? (
    <NTMainViewTemplate>
      <Container>
        <CHServerSinglePageTemplate itemId={params.itemId} />
      </Container>
    </NTMainViewTemplate>
  ) : (
    <>ERROR</>
  );
};
