import React, { useState } from "react";
import {
  MDSettingContainer,
  MDSettingItem,
} from "src/medican/components/molecules";
import { useMetrics } from "src/newtzero-console/hooks/useMetric";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { NZLoadingContainer } from "../molecules/NZLoadingContainer";
import { MDNoDataPlaceHolder } from "src/medican/components/atoms";
import FTSearchField from "src/medican/components/atoms/FTSeachField";

const Container = styled.div`
  font-size: 13px;
  min-height: 60vh;
  height: calc(100vh - 600px);
  background-color: ${(props) => props.theme.bgTertiaryColor};
  padding: 24px 16px;
  border-radius: ${(props) => props.theme.borderRadiusMd}px;
  border: 1px solid ${(props) => props.theme.secondaryBorderColor};
  overflow-y: scroll;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;

  > *:last-child {
    margin-left: auto;
  }
`;

const SearchContainer = styled.div`
  width: 140px;
  height: 35px;
`;

const Title = styled.div`
  color: ${(props) => props.theme.textColor};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MetricContainer = styled(MDSettingContainer)`
  > * {
    font-size: ${(props) => props.theme.supportFontSizeMd};
  }

  > div {
    color: ${(props) => props.theme.primaryColor};
  }
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.primaryColor};
  text-decoration: none;
`;

type Props = { metrics: string[]; isLoading?: boolean };

export const NZMetricViewer: React.FC<Props> = (props) => {
  const { metrics, updateSearchWord } = useMetrics(props.metrics);

  const handleChange = (event: any) => {};

  const handleSubmit = (searchName: string) => {
    updateSearchWord(searchName);
  };

  return (
    <>
      <Header>
        <Title>Metrics</Title>
        <FTSearchField
          size="small"
          onChange={handleChange}
          onSubmit={handleSubmit}
        />
      </Header>

      <Container>
        {!props.isLoading ? (
          <>
            {metrics && metrics.length > 0 ? (
              <MetricContainer>
                {metrics.map((metric) => {
                  const means = metric.means[sessionStorage.contractorNo] ?? [];
                  return (
                    <>
                      <MDSettingItem
                        key={`item-metrics-${metric}`}
                        title={
                          <StyledLink to={`/dataops/metrics/${metric._id}`}>
                            {metric.value}
                          </StyledLink>
                        }
                        description={means.join(", ")}
                        button={<></>}
                      ></MDSettingItem>
                    </>
                  );
                })}
              </MetricContainer>
            ) : (
              <MDNoDataPlaceHolder>メトリックがありません</MDNoDataPlaceHolder>
            )}
          </>
        ) : (
          <>
            <NZLoadingContainer />
          </>
        )}
      </Container>
    </>
  );
};
