import { AxiosInstance } from "axios";

export type RemoveDataProps = { assetTags: string[]; searchOptions?: any };

export const removeData = async (
  instance: AxiosInstance,
  props: RemoveDataProps
) => {
  const res = await instance({
    method: "DELETE",
    url: `/contractors/${sessionStorage.contractorNo}/datamanage/topdatas`,
    params: {
      apikey: sessionStorage.token,
      q: {
        where: {
          // $and: tags.map((tag: string) => {
          //   return { "bases.tags": tag };
          // }),

          $and: props.assetTags.map((tag: string) => {
            return { "bases.tags": tag };
          }),
          ...props.searchOptions,
        },
      },
    },
    headers: { "Content-Type": "application/json" },
  });

  console.log({ status: res.status });

  return res.status >= 200 && res.status < 300;
};

export const removeDataByIds = async (
  instance: AxiosInstance,
  collection: string,
  ids: string[]
): Promise<[boolean, string | undefined]> => {
  try {
    const res = await instance(`/streamgate`, {
      method: "delete",
      params: {},
      data: {
        collection,
        dataIds: ids,
      },
    });

    return [true, undefined];
  } catch (err) {
    console.error(err);
    return [false, "failed delete."];
  }
};
