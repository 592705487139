import * as React from "react";
import { ASButton, ASModal } from "allegro-ui";
import { NTXerviceImportModalContent } from "../molecules/NTXerviceImportModalContent";
import { NTXerviceExportModalContent } from "../molecules/NTXerviceExportModalContent";

interface INTXerviceImportAndExporterProps {}

export const NTXerviceImportAndExporter: React.FunctionComponent<INTXerviceImportAndExporterProps> =
  (props) => {
    const [showImport, setShowImport] = React.useState(false);
    const [showExport, setShowExport] = React.useState(false);
    return (
      <>
        <ASButton
          size="small"
          kind="secondary"
          onClick={() => setShowImport(true)}
        >
          Import
        </ASButton>
        <ASButton
          size="small"
          kind="secondary"
          onClick={() => setShowExport(true)}
        >
          Export
        </ASButton>
        <ASModal open={showImport} onClose={setShowImport}>
          <NTXerviceImportModalContent onCancel={() => setShowImport(false)} />
        </ASModal>
        <ASModal open={showExport} onClose={setShowExport}>
          <NTXerviceExportModalContent onCancel={() => setShowExport(false)} />
        </ASModal>
      </>
    );
  };
