import { ASCard, ASIconButton, ASModal } from "allegro-ui";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { api, url } from "src/api";
import { dataRemove } from "src/fortress/api";
import { useTotalDataCount } from "src/kinzaza/hooks";
import { currentMonitor } from "src/kinzaza/recoils";
import { queryUtils } from "src/kinzaza/utils/queryUtils";
import styled from "styled-components";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { DeleteConfirmModalContents } from "../organisms";

const DataCounterContainer = styled(ASCard)`
  display: flex;
  flex-direction: row;
  /* flex: 1; */
  position: relative;
  padding: 4px 16px 4px 16px;

  height: 44px;
`;

const NumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TotalTextContainer = styled.div`
  color: #fff;
  position: relative;
  top: 0;
  font-size: 12px;
  margin-right: 2px;
`;

const DataCount = styled.h1`
  font-size: 24px;
  /* font-weight: bold; */
  color: #fff;
  padding: 5px;
  margin-right: 8px;
`;

export const DataCounter = () => {
  const cursorMonitor = useRecoilValue(currentMonitor);

  const queryObj = queryUtils.toObject(cursorMonitor.query);
  const [count] = useTotalDataCount(cursorMonitor.schemaType, queryObj);
  const [show, setShow] = useState(false);

  const deleteConfirm = (
    <DeleteConfirmModalContents
      onSubmit={async () => {
        if (cursorMonitor.schemaType) {
          const res = await api({
            url: url.dataProvider(),
            method: "POST",
            params: {
              apikey: sessionStorage.token,
              newtQuery: false,
            },
            data: {
              ...queryObj,
              limit: 100,

              shape: { _id: "any" },
            },
          });
          if (res && res.data.message.doc.length > 0) {
            const ids = res.data.message.doc.map((data: any) => {
              return data._id;
            });

            await dataRemove(cursorMonitor.schemaType, ids);
          }

          setShow(false);
        }
      }}
      onCancel={() => {
        setShow(false);
      }}
    />
  );

  return (
    <DataCounterContainer>
      <TotalTextContainer>Total</TotalTextContainer>
      <NumberContainer>
        <DataCount>{Number.isNaN(count) ? 0 : count}</DataCount>
        <ASIconButton
          size="small"
          kind={"primary"}
          onClick={() => {
            setShow(true);
          }}
          style={{ height: "30px", width: "30px" }}
        >
          <DeleteForeverIcon />
        </ASIconButton>
      </NumberContainer>

      <ASModal open={show} onClose={setShow}>
        {deleteConfirm}
      </ASModal>
    </DataCounterContainer>
  );
};
