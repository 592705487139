import React from "react";

import CHMetricSinglePageTemplate from "src/cheetah/components/templates/CHMetricSinglePageTemplate";
import { useParams } from "react-router-dom";
import { NZDataOpsViewTemplate } from "../templates/NZDataOpsViewTemplate";

type Props = {};

export const NZMetricSinglePage: React.FunctionComponent<Props> = ({
  ...props
}) => {
  const params = useParams();
  return params.itemId ? (
    <NZDataOpsViewTemplate>
      <CHMetricSinglePageTemplate itemId={params.itemId} />
    </NZDataOpsViewTemplate>
  ) : (
    <>ERROR</>
  );
};
