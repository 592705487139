import { FormControl } from "@material-ui/core";
import { ASButton, ASInputBase } from "allegro-ui";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 100%;
  width: 400px;
  display: flex;
  flex-direction: column;
  text-align: left;
  > * {
    margin-top: 24px;
  }
  /* background: red; */
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.primaryColor};
  text-align: center;
`;

const StyledButton = styled(ASButton)`
  margin-top: 36px;
`;

const StyledSpan = styled.div`
  color: ${(props) => (props.theme.darkMode ? "white" : "black")};
  margin-bottom: 8px;
`;

type Props = {
  onSubmit: (email: string, contractor: string) => void;
};

export const PTSignUpForm: React.FC<Props> = (props) => {
  const [email, setEmail] = useState("");
  const [contractor, setContractor] = useState("");

  return (
    <Container>
      <FormControl>
        <StyledSpan>コントラクタ</StyledSpan>
        <ASInputBase
          id="id"
          kind="outline"
          placeholder="入力してください"
          defaultValue=""
          onChange={(e) => {
            setContractor(e.target.value);
          }}
        />
      </FormControl>

      <FormControl>
        <StyledSpan>メールアドレス</StyledSpan>
        <ASInputBase
          id="email"
          kind="outline"
          placeholder="入力してください"
          defaultValue=""
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </FormControl>

      <StyledButton
        kind="primary"
        onClick={(e: any) => {
          props.onSubmit(email, contractor);
        }}
        size="large"
      >
        登録
      </StyledButton>
    </Container>
  );
};
