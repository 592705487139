import { ChildListItem } from "./ChildListItem";

export type ChildList = Array<ChildListItem>;

//トランザクションデータから作成する
//クエリからとってきたデータをChildListの型に変換して扱いやすくするための関数を書く。
export const createChildList = (json: any): ChildList => {
  const arr: Array<ChildListItem> = json.map((txnData: any) => {
    return new ChildListItem(txnData);
  });
  return arr;
};
