import { useCallback } from "react";
import { User } from "src/root/models/user";
import { api } from "src/api";
import { AxiosError } from "axios";
import { BROWSER_URL } from "src/utils/apiUrl";

type ConfirFormInputs = {
  contractor: string;
  email: string;
  confirmNo: string | number;
  phoneNumber: string;
  address: string;
  userRealName: string;
  password: string;
};

type ApiCallReturns = {
  success: boolean;
  error: any;
};

const CUSTOM_SIGNUP_URL = `${BROWSER_URL}/confirm-signup`; //@todo TODO 置き換える。

export const useSignUpActions = () => {
  const signUp = useCallback(
    async (contractor: string, email: string): Promise<ApiCallReturns> => {
      try {
        const res = await api({
          method: "POST",
          url: "/nauth/signup",
          headers: { "Content-Type": "application/json" },
          data: { email, customSignUpUrl: CUSTOM_SIGNUP_URL, contractor },
        });
        console.log(res);
        return {
          success: true,
          error: null,
        };
      } catch (error: any) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          return {
            success: false,
            error: error.response.data.message,
          };
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        return {
          success: false,
          error: "unknown error",
        };
      }
    },
    []
  );

  const signUpConfirm = useCallback(async (formData: ConfirFormInputs) => {
    console.log(formData);
    try {
      const res = await api({
        method: "POST",
        url: `/nauth/signup/confirm`,
        data: { ...formData },
      });
      return res.status === 200;
    } catch (err) {
      console.log(err);
      return false;
    }
  }, []);

  return { signUp, signUpConfirm };
};
