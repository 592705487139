import { useState, useEffect, useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { toTree, TreeModel } from "src/opt-editor/models";
import { ScienceFolder } from "src/opt-editor/models/ScienceFSItem";
import scienceFolder from "src/opt-editor/recoils/ScienceFolderState";

export const useTreeList = () => {
  const [treeList, setTreeList] = useState<TreeModel[]>([]);

  const idList = useRecoilValue(scienceFolder.idList);
  const itemList = useRecoilValue(scienceFolder.itemList);

  useEffect(() => {
    if (itemList && itemList.length > 0) {
      const nextTreeList = toTree(itemList);
      if (nextTreeList) {
        setTreeList([...nextTreeList]);
      } else {
        console.error("no nextTreeList");
      }
    }
  }, [idList, itemList]);

  return { treeList };
};
