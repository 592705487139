import { AxiosInstance } from "axios";

export const fetchServerStatsByNewtQuery = async (
  instance: AxiosInstance,
  newtQuery: any,
  options?: any
) => {
  const res = await instance.post("/server-stats/dataprovider", newtQuery, {
    params: options,
  });

  return res.data.message;
};
