//Libs
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilRefresher_UNSTABLE,
} from "recoil";
//Components
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from "@material-ui/core";
import { ASTable, ASPageNav } from "allegro-ui";
import { OEProfileCard } from "../organisms";
import { User } from "src/root/models/user";

//States
//Types
import { OEActivityListItem } from "../organisms/OEActivityListItem";

import activityUtils from "src/opt-editor/recoils/activityState";
import { Activity } from "src/opt-editor/models";
import { OEPageTemplate } from "../templates";
import { useProfileList } from "src/portal/hooks/useProfileList";

const Body = styled.div`
  display: flex;
  margin: 0px;
  width: 100%;
  padding: 50px 30px 50px 30px;
`;

const Head = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 30px 0px 30px;
`;

const rightWidth = 350;

const ActivityArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ProfileArea = styled.div`
  margin-left: 30px;
  width: ${rightWidth}px;
  height: 100%;
`;

const Title = styled.h1`
  font-size: 30px;
`;

const ActivityView = styled(Paper)`
  width: 100%;
  padding: 20px;
  background-color: ${(props) => props.theme.bgPrimaryColor};
`;

type Params = {
  userNoText: string;
};

export const OEActivityListPageTemplate: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const { userNoText } = useParams<Params>();

  const { getItems } = activityUtils.useItemList();
  const [filter, setFilter] = useRecoilState(activityUtils.filterState);
  const [activities, setActivities] = useState<Activity[]>([]);
  const refreshActivityList = useRecoilRefresher_UNSTABLE(activityUtils.idList);

  const setReqId = useSetRecoilState(activityUtils.requestIdState);

  const { getUserById } = useProfileList();

  const [profile, setProfile] = useState<User | null>();

  useEffect(() => {
    if (userNoText) {
      const p = getUserById(userNoText);
      // setProfile(p)
      if (p) setProfile(p);
    }
  }, [getUserById, userNoText]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const updateActivityList = useCallback(async () => {
    if (filter) {
      const items = await getItems();
      setActivities(items as Activity[]);
    }
  }, [filter]);

  useEffect(() => {
    updateActivityList();
  }, [updateActivityList]);

  /**
   * userNoTextをフィルターにセットする
   */
  useEffect(() => {
    if (userNoText) {
      const nextFilter = {
        "contents.rawdata.userNoText": userNoText,
      };
      setFilter(nextFilter);
    }

    refreshActivityList();
    // setReqId((p) => p + 1);
  }, [refreshActivityList, setFilter, setReqId, userNoText]);

  const rowsPerPage = 8;

  return (
    <OEPageTemplate>
      <Body>
        <ActivityArea>
          <Head>
            <Title>アクティビティ</Title>
            {/* <OESearchTextField /> */}
          </Head>

          <ActivityView>
            <TableContainer
              component={ASTable}
              borderRadius={18}
              kind="tertiary"
            >
              <Table>
                <TableHead>
                  <TableCell>Type</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Message</TableCell>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? activities.slice(
                        (page - 1) * rowsPerPage,
                        (page - 1) * rowsPerPage + rowsPerPage
                      )
                    : activities
                  ).map((activity, id) => (
                    <OEActivityListItem
                      activity={activity}
                      key={`${id}-activity-item`}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ActivityView>

          {activities.length > 0 ? (
            <ASPageNav
              count={
                Math.floor(activities.length / rowsPerPage) +
                Number(activities.length % rowsPerPage > 0)
              }
              page={page}
              onChange={handlePageChange}
            />
          ) : (
            <></>
          )}
        </ActivityArea>

        <ProfileArea>
          {profile ? <OEProfileCard user={profile} /> : <></>}
        </ProfileArea>
      </Body>
    </OEPageTemplate>
  );
};
