import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";

export const alertJobBrowserUi: ASBrowserUiConf = {
  newtQ: {
    collection: "TopData",
    asset: ["CICDVariables"],
    limit: 10,
    primaryKey: "value",
    match: {
      "contents.rawdata.non_numeric_sub_category": "domain",
    },
    shape: {
      "contents.rawdata": "any",

      _id: "any",
    },
  },
  filters: [],
  verticalFilters: [],
  tables: [
    {
      label: "Contractor",
      component: "LCSimpleColComponent",
      data: {
        value: "non_numeric_contractor",
      },
      options: {
        dateFormat: "wareki",
        editable: false,
      },
    },
    {
      label: "Server name",
      component: "LCSimpleColComponent",
      data: {
        value: "non_numeric_server_name",
      },
      options: {
        editable: false,
      },
    },
    {
      label: "Slot",
      component: "LCSimpleColComponent",
      data: {
        value: "non_numeric_slot_name",
      },
      options: {
        editable: false,
      },
    },
    {
      label: "IP",
      component: "LCSimpleColComponent",
      data: {
        value: "non_numeric_ip",
      },
      options: {
        editable: false,
      },
    },
    {
      label: "Domain name",
      component: "LCTextLinkCellComponent",
      data: {
        label: "non_numeric_domain_name",
        id: "_id",
      },
      statics: {
        path: "/devops/ci-vars",
      },
      options: {
        editable: false,
      },
    },
    {
      label: "Host name",
      component: "LCSimpleColComponent",
      data: {
        value: "non_numeric_host",
      },
      options: {
        editable: false,
      },
    },
    {
      label: "Listen port",
      component: "LCSimpleColComponent",
      data: {
        value: "numeric_listen_port",
      },
      options: {
        editable: false,
      },
    },
    // {
    //   label: "Use SSL",
    //   component: "LCSimpleColComponent",
    //   data: {
    //     value: "non_numeric_ssl",
    //   },
    //   options: {
    //     editable: false,
    //   },
    // },
    {
      label: "SSL certificate",
      component: "LCSecretCellComponent",
      data: {
        value: "non_numeric_ssl_certificate",
      },

      options: {
        editable: false,
      },
    },
    {
      label: "SSL certificate_key",
      component: "LCSecretCellComponent",
      data: {
        value: "non_numeric_ssl_certificate_key",
      },

      options: {
        editable: false,
      },
    },
    {
      label: "",
      component: "LCDeleteButtonComponent",
      data: {
        name: "name",
        documentId: "_id",
        serverVariableId: "_id",
      },
      options: {
        editable: false,
      },
    },
  ],
  pager: { perPage: 50 },
};
