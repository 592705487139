import React from "react";
import { NZSettingPageTemplate } from "../templates/NZSettingsPageTemplate";
import { PTSettingSecretsTemplate } from "src/portal/components/templates/PTSettingSecretsTemplate";

type Props = {};
export const NZSettingSecretsPage: React.FC<Props> = (props) => {
  return (
    <>
      <NZSettingPageTemplate>
        <PTSettingSecretsTemplate />
      </NZSettingPageTemplate>
    </>
  );
};
