import React from "react";
import { ASButton } from "../atoms";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  min-height: 150px;
  padding: 12px 25px;
  h1 {
    font-size: 32px;
    font-weight: bold;
  }
  > *:last-child {
    margin-top: auto;
  }
`;

const Body = styled.div`
  display: flex;
`;
const ButtonGroup = styled.div`
  margin-top: auto;
  margin-left: auto;
  height: 40px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: flex-end;
  > * {
    margin-left: 16px;
  }
`;

type Props = {
  cancelLabel?: string;
  submitLabel?: string;
  submitDisabled?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
};

//モニター一覧において、モニターを削除する用のコンポーネント

export const ASConfirmModalContents: React.FC<Props> = (props) => {
  const { submitLabel, cancelLabel, submitDisabled, onSubmit, onCancel } =
    props;

  return (
    <Container>
      <Body>{props.children}</Body>
      <ButtonGroup>
        <ASButton
          kind={"secondary"}
          onClick={() => {
            if (onCancel) {
              onCancel();
            }
          }}
        >
          {cancelLabel ?? "Cancel"}
        </ASButton>
        <ASButton
          kind={"primary"}
          disabled={submitDisabled}
          onClick={() => {
            if (onSubmit) {
              onSubmit();
            }
          }}
        >
          {submitLabel ?? "Submit"}
        </ASButton>
      </ButtonGroup>
    </Container>
  );
};
