import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ASIconButton } from "allegro-ui";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const StyledIconButton = styled(ASIconButton)`
  /* color: ${(props) => props.theme.primaryColor}; */
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

export interface ILCArrowLinkCellComponentProps {
  data: { [key: string]: any };
  options: { path: string };
}

const parsePath = (path: string, data: { [key: string]: any }): string => {
  const regex = /{([^}]+)}/g;
  return path.replace(regex, (_, match) => data[match]);
};

export default function LCArrowLinkCellComponent(
  props: ILCArrowLinkCellComponentProps
) {
  const history = useNavigate();
  const handleLink = () => {
    const path = parsePath(props.options.path, props.data);
    console.log(path);
    history(path);
  };

  return (
    <div>
      <StyledIconButton kind="secondary" onClick={handleLink}>
        <ChevronRightIcon></ChevronRightIcon>
      </StyledIconButton>
    </div>
  );
}
