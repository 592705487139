import { AxiosInstance } from "axios";

export const deleteOne = async (
  instance: AxiosInstance,
  contractor: string,
  optXId: string
) => {
  try {
    const res = await instance({
      method: "delete",
      url: `/contractors/${contractor}/optx/${optXId}`,
    });
    return res;
  } catch (err) {
    console.error(err);
  }
};
