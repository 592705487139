//Libs
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import routes from "../../routes.json";

//Components
import { ASButton } from "allegro-ui";
import { Link } from "react-router-dom";

import { OEUserButton } from "src/opt-editor/components";

//States
import { useAuth } from "src/root/UseAuth";
import { useRecoilValue } from "recoil";
import { authState } from "src/root/recoils/userState";
import logo from "src/assets/newtroid-logo-small-capital-ver.svg";

//Types

const APP = process.env.REACT_APP_APP;
export const headerOffset = 56;

const Container = styled.div`
  /* width: 100%; */
  height: ${headerOffset}px;
  background: ${(props) =>
    props.theme.darkMode ? props.theme.bgPrimaryColor : "#222"};
  /* padding: 0 30px; */
  /* border-bottom: 1px solid #37383a; */
  /* position: fixed; */
  display: flex;
  align-items: center;
`;

const AppBar = styled.div`
  width: 100%;
  padding: 0 16px;
  position: fixed;
  z-index: 999;
  height: ${headerOffset}px;
  display: flex;
  align-items: center;
  background: ${(props) =>
    props.theme.darkMode ? props.theme.bgPrimaryColor : "#222"};
  border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};
`;

const LogoAndTitle = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  margin-left: 8px;
  font-size: 18px;

  > img {
    margin-top: 4px;
    width: 128px;
    fill: #000;
  }

  font-weight: 400;
  letter-spacing: 1px;
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
`;

const MenuItem = styled.li`
  color: #fff;
  list-style-type: none;
  > a {
    color: #fff;
    text-decoration: none;
    margin-right: 36px;
    font-size: 14px;
    font-weight: 500;

    /* font-weight: bold; */
    &:hover {
      opacity: 0.8;
    }
  }
`;

const MenuGroup = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const Skelton = styled.div`
  clear: both;
  position: relative;
  /* width:100%: */
  /* height: ${headerOffset}px; */
`;

type Props = {};

/**
 * @deprecated use intead ARGlobalNav
 */
export const PTGlobalNav: React.FC<Props> = (props) => {
  const auth = useRecoilValue(authState);
  const history = useNavigate();
  // const color = theme.darkMode ? "white" : "black";

  const handleLogoClick = () => {
    history("/");
  };

  const https = "http";
  const domain = process.env.REACT_APP_HOST;

  return (
    <>
      <Container>
        <AppBar>
          <LogoAndTitle onClick={handleLogoClick}>
            <Title>
              <img src={logo} alt="Newtroid" />
            </Title>
          </LogoAndTitle>
          {auth.token ? (
            <MenuGroup>
              <MenuItem>
                <a
                  href={`${https}://${auth.contractorNo}.${domain}/smartscience`}
                >
                  SmartScience
                </a>
              </MenuItem>

              <MenuItem>
                <a href={`${https}://${auth.contractorNo}.${domain}/newtroid`}>
                  Newtroid
                </a>
              </MenuItem>

              <MenuItem>
                <a
                  href={`${https}://${auth.contractorNo}.${domain}/annotation`}
                >
                  Annotation
                </a>
              </MenuItem>

              <MenuItem>
                <a href={`${https}://${auth.contractorNo}.${domain}/lakehouse`}>
                  Lakehouse
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  href={`${https}://${auth.contractorNo}.${domain}/newtroid/xervice`}
                >
                  Xervice
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  href={`${https}://${auth.contractorNo}.${domain}/newtroid/optx`}
                >
                  OptX
                </a>
              </MenuItem>
              <React.Suspense fallback={<>...</>}>
                {auth.token ? <OEUserButton /> : <></>}
              </React.Suspense>
            </MenuGroup>
          ) : (
            <></>
          )}
        </AppBar>
      </Container>
      <Skelton></Skelton>
    </>
  );
};
