import React from "react";
import { NZGlobalNav } from "../organisms/NZGlobalNav";
import { NZMonitorBrowserTemplate } from "src/newtzero-console/components/templates/NZMonitorBrowserTemplate";
import { NZDataViewPageTemplate } from "src/newtzero-console/components/templates/NZDataViewPageTemplate";

type Props = {};
export const NZDataViewPage: React.FC<Props> = (props) => {
  return (
    <div>
      <NZGlobalNav />
      {/* <NZMonitorBrowserTemplate /> */}
      <NZDataViewPageTemplate />
    </div>
  );
};
