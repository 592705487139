import React, { createContext, useContext, useState } from "react";

type MsgType = "negative" | "positive";

type ContextProps = {
  isLoading: boolean;
  snackMsg: string;
  msgType: MsgType;
  dispatch: (
    type: "loading" | "msg",
    option: { msg?: string; msgType?: MsgType; isLoading?: boolean }
  ) => void;
};

const defaultValue: ContextProps = {
  isLoading: false,
  snackMsg: "",
  msgType: "positive",
  dispatch: (type, option) => {},
};

const NotifyContext = createContext<ContextProps>(defaultValue);

export const NotifyProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [snackMsg, setSnackMsg] = useState<string>("");
  const [msgType, setMsgType] = useState<MsgType>("negative");

  const dispatch = (
    type: "loading" | "msg",
    option: { msg?: string; msgType?: MsgType; isLoading?: boolean }
  ) => {
    switch (type) {
      case "loading":
        if (option.isLoading !== undefined) setIsLoading(option.isLoading);
        break;
      case "msg":
        if (option.msg !== undefined) setSnackMsg(option.msg);
        if (option.msgType !== undefined) setMsgType(option.msgType);
        break;
      default:
    }
  };

  const value = {
    isLoading,
    snackMsg,
    msgType,
    dispatch,
  };
  return (
    <NotifyContext.Provider value={value}>{children}</NotifyContext.Provider>
  );
};

export const useNotify = () => {
  return useContext(NotifyContext);
};
