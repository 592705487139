import { instance, apiVersion, init } from "../initialize";

import { AxiosInstance } from "axios";

export const actions = <T>(
  tags: string[],
  primaryKey: string,
  f?: (d: any) => T
) => {
  const factory: (d: unknown) => T = f ? f : (d: any): T => d;

  const API_VERSION = apiVersion;

  // const api = instance();
  // const getInstance = instance();

  /**
   * apiのイニシャライズ
   */
  const initialize = () => {
    init({
      apikey: sessionStorage.token,
      contractor: sessionStorage.contractorNo,
      version: 1,
    });
  };

  /**
   * 一件取得
   */
  const getOne = async (partOfWhere: any, orderby?: any): Promise<T | null> => {
    const api = instance();
    if (!api) {
      console.error("no api");
      return null;
    }

    const newtQuery = {
      asset: tags,
      limit: 1,
      skip: 0,
      shape: {
        "contents.rawdata": "any",
      },
      search: {
        ...partOfWhere,
      },
      order: orderby,
    };

    const res = await api({
      method: "POST",
      url: `/api/${API_VERSION}/newtout/aggr`,
      params: {
        apikey: sessionStorage.token,
        newtQuery: false,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.token,
        "User-Id": sessionStorage.userNoText,
      },
      data: newtQuery,
      timeout: 3000,
    });

    if (res.data && res.data.message.doc.length > 0) {
      return factory(res.data.message.doc[0]);
    } else {
      return null;
    }
  };

  /**
   * n件取得
   */
  const getMany = async (
    limit: number,
    skip: number,
    filter?: { [key: string]: any }
  ): Promise<T[] | null> => {
    const api = instance();
    if (!api) {
      console.error("no api");
      return null;
    }

    const f = filter ?? {};

    try {
      const res = await api({
        method: "POST",
        url: `/api/${API_VERSION}/newtout/aggr`,
        params: {
          apikey: sessionStorage.token,
          newtQuery: false,
        },
        data: {
          asset: tags,
          search: {
            ...f,
          },
          skip: skip,
          limit: limit,
          shape: {
            "contents.rawdata": "any",
          },
        },

        headers: {
          "Content-Type": "application/json",
          Authorization: sessionStorage.token,
          "User-Id": sessionStorage.userNoText,
        },
      });
      return res.data.message.doc.map((d: any) => {
        return factory(d);
      });
    } catch {
      return [];
    }
  };
  /**
   * 一件作成
   */
  const postOne = async (data: T, readers?: string[], writers?: string[]) => {
    const api = instance();

    if (!api) {
      console.error("no api");
      return null;
    }
    const d = data as any;
    if (d[primaryKey] == null) {
      console.error(
        "invalid data were given. incorrect primary key.",
        { primaryKey },
        data
      );
      return false;
    }

    const res = await api({
      method: "POST",
      url: `/api/${API_VERSION}/streamgate`,
      params: {
        assetTags: tags,
        sendToTopData: "true",
        sendToRawData: "true",
        strict: true,
        readers: JSON.stringify(readers),
        writers: JSON.stringify(writers),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.token,
        "User-Id": sessionStorage.userNoText,
      },
      data: data,
    });

    if (res.status === 200) {
      return true;
    } else {
      console.error(res);
      return false;
    }
  };

  /**
   * postOneのエイリアス
   */
  const post = postOne;

  /**
   * 一件更新
   */
  const updateOne = async (data: T, cb: () => void) => {
    const api = instance();
    if (!api) return false;
    const d = data as any;
    if (!d[primaryKey]) {
      console.error("invalid data were given.");
      return false;
    }

    const res = await api({
      method: "POST",
      url: `/api/${API_VERSION}/streamgate`,
      params: {
        apikey: sessionStorage.token,
        assetTags: tags,
        sendToTopData: "true",
        sendToRawData: "true",
        strict: true,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.token,
        "User-Id": sessionStorage.userNoText,
      },
      data: data,
    });
    if (res.status === 200) {
      return true;
    } else {
      console.error(res);
      return false;
    }
  };

  /**
   * updateOneのエイリアス
   */
  const update = updateOne;

  /**
   * n件更新
   */
  //@todo TODO

  /**
   * 一件削除
   */
  const removeOne = async (primaryValue: number | string) => {
    const api = instance();
    if (!api) return null;
    // const q = {
    //   where:return {`contents.rawdata.${primaryKey}`: primaryKey}
    // };

    const res = await api({
      method: "DELETE",
      url: `/api/${API_VERSION}/contractors/${sessionStorage.contractorNo}/datamanage/topdatas`,
      params: {
        apikey: sessionStorage.token,
        q: {
          where: {
            // $and: tags.map((tag: string) => {
            //   return { "bases.tags": tag };
            // }),

            $and: tags.map((tag: string) => {
              return { "bases.tags": tag };
            }),
            ["contents.rawdata." + primaryKey]: primaryValue,
          },
        },
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.token,
        "User-Id": sessionStorage.userNoText,
      },
    });
    return res;
  };

  /**
   * removeOneのエイリアス
   */
  const remove = removeOne;

  return {
    getOne,
    getMany,
    post,
    postOne,
    update,
    updateOne,
    remove,
    removeOne,
  };
};
