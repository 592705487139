import { atom, DefaultValue, selector } from "recoil";

export const datePickerState = atom<Date>({
  key: "datePickerState",
  default: new Date(),
});

export const unixTimePickerState = selector<number>({
  key: "unixDatePickerState",
  get: ({ get }) => {
    const date = get(datePickerState);
    return date.getTime();
  },

  set: ({ set }, newDate) => {
    const date = newDate instanceof DefaultValue ? newDate : new Date(newDate);
    set(datePickerState, date);
  },
});
