import React from "react";
import { PTUserAuthComponent } from "src/portal/components/organisms/PTUserAuthComponent";
import { CHUserAuthMainViewTemplate } from "../templates/CHUserAuthMainView";
import { UserAuthPath } from "../models/userAuthRoutes";

interface ICHProvisionalRegisteredPageProps {
  logo: React.Component;
  appName: string;
  routes: UserAuthPath;
}

export const CHProvisionalRegisteredPage: React.FC<ICHProvisionalRegisteredPageProps> =
  (props) => {
    const { logo } = props;

    return (
      <>
        <CHUserAuthMainViewTemplate logo={logo}>
          <PTUserAuthComponent
            formTitle={"仮登録完了"}
            formContent={<p>仮登録が完了しました。メールをご確認ください。</p>}
          ></PTUserAuthComponent>
        </CHUserAuthMainViewTemplate>
      </>
    );
  };
