import React from "react";
import { FTCheckForm } from "../atoms";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import {
  cursorChildThumbState,
  cursorConfigState,
} from "src/fortress/recoils/singleChildPageState";

type Props = {};

const Q1 = styled.div`
  margin-top: 30px;
  margin-bottom: 50px;
  font-size: 25px;
  font-weight: bold;
`;

//新規アセスメント作成画面の、チェックボックスの質問コンポーネント。

export const FTAddCheckAssesment: React.FC<Props> = (props) => {
  const config = useRecoilValue(cursorConfigState);
  const cursorChildThumb = useRecoilValue(cursorChildThumbState);

  //反社会的な素振りの質問は、7歳以上の人にしかしない質問なので、こういう処理を書きました。

  const hansya =
    cursorChildThumb.age >= 7 ? (
      <>
        <FTCheckForm
          answers={config.antiSocietyBehavior}
          question="子どもに反社会的な素振りとかを確認することができますか？"
          category={1}
        ></FTCheckForm>
      </>
    ) : (
      <></>
    );

  return (
    <>
      <Q1>
        <p>1.子どものことについて質問します。</p>
      </Q1>
      <FTCheckForm
        answers={config.healthState}
        question="子どもの健康状態はどうですか？"
        category={1}
      ></FTCheckForm>
      <FTCheckForm
        answers={config.unstableMental}
        question="子どもの精神状態は安定していますか？"
        category={1}
      ></FTCheckForm>
      <FTCheckForm
        answers={config.indifference}
        question="子どもの普段の様子から気力を感じることはできますか？"
        category={1}
      ></FTCheckForm>
      <FTCheckForm
        answers={config.aggressive}
        question="割と攻撃的になることがありますか？"
        category={1}
      ></FTCheckForm>
      <FTCheckForm
        answers={config.isolation}
        question="子どもは割と孤立しがちですか？"
        category={1}
      ></FTCheckForm>
      <FTCheckForm
        answers={config.worryBehavior}
        question="気になる、または心配になる行動とかはありますか？"
        category={1}
      ></FTCheckForm>
      {hansya}
      <FTCheckForm
        answers={config.parentBehavior}
        question="子どもの保護者への態度はどのようなものですか？"
        category={1}
      ></FTCheckForm>
      <FTCheckForm
        answers={config.hygieneAppearance}
        question="子どもの身なりや精神状態はどうですか？"
        category={1}
      ></FTCheckForm>
      <FTCheckForm
        answers={config.eatSituation}
        question="子どもの食事の様子はどうですか？"
        category={1}
      ></FTCheckForm>
      <FTCheckForm
        answers={config.goKinder}
        question="子どもの登校状況等とかはどうですか？"
        category={1}
      ></FTCheckForm>

      <Q1>
        <p>2.保護者のことについて質問します。</p>
      </Q1>

      <FTCheckForm
        answers={config.dealChild}
        question="子どもと上手に関わっていますか？"
        category={2}
      ></FTCheckForm>
      <FTCheckForm
        answers={config.differenceSibling}
        question="保護者は兄弟間で差別をしていないですか？"
        category={2}
      ></FTCheckForm>
      <FTCheckForm
        answers={config.stateHeart}
        question="保護者の心身の状態はどうですか？"
        category={2}
      ></FTCheckForm>
      <FTCheckForm
        answers={config.parentWorryBehavior}
        question="保護者に関して何か気になる行動がありますか？"
        category={2}
      ></FTCheckForm>
      <FTCheckForm
        answers={config.kinderInvolvment}
        question="保護者は普段どのように学校と関わっていますか？"
        category={2}
      ></FTCheckForm>

      <Q1>
        <p>3.家族、家庭のことについて質問します。</p>
      </Q1>

      <FTCheckForm
        answers={config.notGoodParent}
        question="家族の間で暴力や不和がありますか？"
        category={3}
      ></FTCheckForm>
      <FTCheckForm
        answers={config.livingState}
        question="住居の状態はどうですか？"
        category={3}
      ></FTCheckForm>
      <FTCheckForm
        answers={config.supportState}
        question="周りからの助けとかを拒否したりしますか？"
        category={3}
      ></FTCheckForm>

      <Q1>
        <p>4.その他気になることを質問します。</p>
      </Q1>

      <FTCheckForm
        answers={config.other}
        question="以下、当てはまるものを全て選択してください。"
        category={4}
      ></FTCheckForm>
    </>
  );
};
