//Libs
import React from "react";
import styled from "styled-components";

import { CHHeaderNav } from "src/cheetah/components/organisms/CHHeaderNav";
import { NZGlobalNavConf } from "src/newtzero-console/config/globalNavConf";
import NewtZeroLogo from "../atoms/logo";
import { useAuth } from "src/root";

//Types

export const headerOffset = 56;

const Skelton = styled.div`
  clear: both;
  position: relative;
  /* width:100%: */
  /* height: ${headerOffset}px; */
`;

type Props = {};
export const NZGlobalNav: React.FC<Props> = (props) => {
  const auth = useAuth();

  return (
    <>
      <CHHeaderNav
        logo={
          auth.contractorNo ? (
            <NewtZeroLogo contractorNo={auth.contractorNo ?? ""} />
          ) : (
            <NewtZeroLogo />
          )
        }
        conf={NZGlobalNavConf}
      />
      <Skelton></Skelton>
    </>
  );
};
