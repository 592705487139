//Libs
import { useCallback } from "react";
import produce from "immer";
import { api, createnXtalAPI } from "src/api";
import {
  useRecoilValue,
  useRecoilState,
  useSetRecoilState,
  useRecoilValueLoadable,
} from "recoil";

//Components

//States
import { userState } from "src/root/recoils/userState";
import { Edge } from "react-flow-renderer";
import { requestEdgesIdState } from "../recoils/edges";
import { slotState, slotRequestId, cursorPipelineState } from "../recoils";

//Types
import { OptXConfFile } from "../models/OptXConfFile";
import {
  Environments,
  Slot,
  EnvironmentVariables,
  Input,
  Output,
  defaultInput,
  defaultOutput,
  Target,
} from "../models";

export const useSlotActions = (slotId: string) => {
  const [self, setSelf] = useRecoilState(slotState(slotId));
  const auth = useRecoilValue(userState);
  const [slotReqId, setSlotReqId] = useRecoilState(slotRequestId);
  const setEdgesRequestId = useSetRecoilState(requestEdgesIdState);
  const apiKey: string = typeof auth.token === "string" ? auth.token : "";
  const cursorPipeline = useRecoilValueLoadable(cursorPipelineState);

  const nxtal = createnXtalAPI();
  const notifySlotUpdates = () => {
    setSlotReqId((n) => n + 1);
  };

  const setOptX = async (optXId: string) => {
    let conf: OptXConfFile | null = null;
    //ファイル名がopt-xのファイルを取得する。
    try {
      conf = await nxtal.optx.fetchOptXConf({
        contractorNo: sessionStorage.contractorNo,
        optXId,
      });
    } catch (err) {
      console.error(err);
    }
    //環境変数をkvペアにする。
    const newEnvVals = conf?.environments ?? [];
    // const envKV: Environments = {};
    // newEnvVals.map((value) => {
    //   return (envKV[value] = null);
    // });

    const inputs: Input[] =
      conf?.io.inputs &&
      Array.isArray(conf?.io.inputs) &&
      conf?.io.inputs.length > 0
        ? conf.io.inputs
        : [defaultInput];
    const outputs: Output[] =
      conf?.io.inputs &&
      Array.isArray(conf?.io.inputs) &&
      conf?.io.inputs.length > 0
        ? conf?.io.outputs
        : [defaultOutput];

    setSelf((s) =>
      produce(s, (draft) => {
        draft.optXId = optXId;

        const prevEnvKV = Object.fromEntries(
          Object.entries(draft.environments).filter(([key, value]) => {
            newEnvVals.includes(key);
          })
        );

        let nextEnvKV = prevEnvKV;
        // draft.environments = { ...environments, ...draft.environments };
        newEnvVals.forEach((envValName) => {
          nextEnvKV[envValName] = draft.environments[envValName] ?? null;
        });

        draft.environments = nextEnvKV;

        draft.inputs = inputs;
        draft.outputs = outputs;
      })
    );

    notifySlotUpdates();
  };

  const updatePosition = useCallback(
    (x: number, y: number) => {
      setSelf((currentSelf) =>
        produce(currentSelf, (draft) => {
          draft.ui = {
            ...currentSelf.ui,
            x,
            y,
          };
        })
      );
    },
    [setSelf]
  );

  const addConnection = useCallback(
    async (edge: Edge) => {
      setSelf((s) =>
        produce(s, (draft) => {
          const myIOName = edge.sourceHandle ?? "";

          const myIO = s.outputs.find((o) => o.name === myIOName);
          if (!myIO) {
            console.error("ERROR:　addConnectionプログラムが不正です。");
            return;
          }
          const newTarget: Target = {
            slotId: edge.target,
            name: edge.targetHandle ?? "",
          };

          const evenTarget = (t1: Target, t2: Target) =>
            t1.name === t2.name && t1.slotId === t2.slotId;

          const newTargets = (() => {
            let result: Target[] = Array.isArray(myIO.targets)
              ? myIO.targets
              : [];

            //すでに存在するか確認する。
            const existAlready: boolean = result.some((t) =>
              evenTarget(t, newTarget)
            );
            //ない場合は新しくターゲットを追加する
            if (!existAlready) {
              result = [...result, newTarget];
            }
            return result;
          })();

          const newOutput: Output = {
            name: myIOName,
            targets: newTargets,
          };

          //@todo outputの複数対応
          draft.outputs = draft.outputs.map((output) =>
            output.name === myIOName ? newOutput : output
          );
        })
      );

      // await _reload();
      setEdgesRequestId((n) => n + 1);
      // setSlotReqId(slotReqId + 1);
      return true;
    },
    [setEdgesRequestId, setSelf]
  );

  const removeConnection = useCallback(
    async (
      outputIOName: string,
      targetIOName: string,
      targetSlotId: string
    ) => {
      setSelf((slot) =>
        produce(slot, (draft) => {
          const evenTarget = (t1: Target, t2: Target) =>
            t1.name === t2.name && t1.slotId === t2.slotId;

          //inputNameからこのスロットのアウトプットを取得する

          //アウトプットに繰り返す
          let io = slot.outputs.find((output) => {
            return output.name === outputIOName;
          });

          if (!io) {
            console.error("削除するIOが見つかりません。");
            return;
          }

          const targetToRemove: Target = {
            name: targetIOName,
            slotId: targetSlotId,
          };

          const filterd = io?.targets.filter(
            (target) => !evenTarget(targetToRemove, target)
          );

          draft.outputs = draft.outputs.map((output) =>
            output.name === outputIOName
              ? { name: outputIOName, targets: filterd }
              : output
          );
        })
      );

      // await _reload();
      setEdgesRequestId((n) => n + 1);
    },
    [setEdgesRequestId, setSelf]
  );

  const resetConnections = useCallback(async () => {
    setSelf((s) =>
      produce(s, (draft) => {
        draft.environments = {
          ...self.environments,
          ...{ ALLEFAVO_TARGET_URL: "" },
        };
        draft.outputs[0].targets = [];
      })
    );
    // await _reload();
    setSlotReqId(slotReqId + 1);
  }, [self.environments, setSelf, setSlotReqId, slotReqId]);

  const updateName = useCallback(
    async (name: string) => {
      setSelf((s) =>
        produce(s, (draft) => {
          draft.name = name;
          // draft.pipelineName =
          //   cursorPipeline.state === "hasValue"
          //     ? cursorPipeline.contents?.name ?? "unknown"
          //     : "unknown";
        })
      );
      setSlotReqId(slotReqId + 1);
    },
    [
      // cursorPipeline.contents.name,
      // cursorPipeline.state,
      setSelf,
      setSlotReqId,
      slotReqId,
    ]
  );

  const updateServerId = useCallback(
    async (serverId: string) => {
      setSelf((s) =>
        produce(s, (draft) => {
          draft.serverId = serverId;
        })
      );
      setSlotReqId(slotReqId + 1);
    },
    [setSelf, setSlotReqId, slotReqId]
  );

  const requestStart = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const updateRes = await api({
      method: "PUT",
      url: `/xervice/${self.id}`,
      params: {
        apikey: apiKey,
      },
      data: {
        status: {
          desire: "run",
          current: "stop",
        },
      },
    });
    // await _reload();
    // setSlotReqId(slotReqId + 1);
  };

  const requestStop = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const updateRes = await api({
      method: "PUT",
      url: `/xervice/${self.id}`,
      params: {
        apikey: apiKey,
      },
      data: {
        status: {
          desire: "stop",
          current: "run",
        },
      },
    });
    // await _reload();
    // setSlotReqId(slotReqId + 1);
  };

  const setSlot = (slot: Slot) => {
    setSelf(slot);
  };

  const loadEnvironmentVariables = () => {
    const vars: EnvironmentVariables = [];
  };

  const saveEnvironments = (environments: Environments) => {
    setSelf((s) =>
      produce(s, (draft) => {
        draft.environments = { ...draft.environments, ...environments };
      })
    );
    // await _reload();
    setSlotReqId(slotReqId + 1);
  };

  const saveVolume = (volume: string) => {
    setSelf((s) =>
      produce(s, (draft) => {
        draft.volume = volume;
      })
    );
    // await _reload();
    setSlotReqId(slotReqId + 1);
  };

  const savePort = (port: string) => {
    const portNum = Number(port);
    if (isNaN(portNum)) {
      return;
    }
    setSelf((s) =>
      produce(s, (draft) => {
        draft.inputs[0].portOutside = portNum;
      })
    );
    // await _reload();
    setSlotReqId(slotReqId + 1);
  };

  const savePorts = (ports: string[]) => {
    ports.forEach((port, id) => {
      const portNum = Number(port);
      if (isNaN(portNum)) {
      } else {
        setSelf((s) =>
          produce(s, (draft) => {
            draft.inputs[id].portOutside = portNum;
          })
        );
      }
    });

    // await _reload();
    setSlotReqId(slotReqId + 1);
  };

  const saveInputs = (formData: { [key: string]: string }) => {
    setSelf((s) =>
      produce(s, (draft) => {
        draft.inputs.forEach((input, id) => {
          draft.inputs[id].portOutside =
            formData[`port${id}`] !== ""
              ? Number(formData[`port${id}`])
              : undefined;
          draft.inputs[id].customDomain =
            formData[`customDomain${id}`] !== ""
              ? formData[`customDomain${id}`]
              : undefined;
          draft.inputs[id].usePrivateGateway =
            formData[`usePrivateGateway${id}`] === "はい"; //@TODO はいで判定するとi8n対応時にエラーとなる。
        });
      })
    );
  };

  const deleteSlot = async (slot: Slot) => {
    // hooksからの操作だと更新されないのでページに直書きした
    // slotList.deleteSlot(slot);
    if (slot !== undefined) {
      try {
        const contractor: string =
          typeof auth.contractorNo === "string" ? auth.contractorNo : "";

        const apiKey: string = typeof auth.token === "string" ? auth.token : "";
        //@todo リファァクタリング
        const res = await api({
          method: "DELETE",
          url: `/xervice/${slot.id}`,
          params: {
            apikey: apiKey,
          },
        });
        setSlotReqId(slotReqId + 1);
      } catch (err) {
        console.error(err);
      }
    }
    setSlotReqId(slotReqId + 1);
  };

  return {
    ...self,
    setOptX,
    requestStart,
    requestStop,
    setSlot,
    deleteSlot,
    addConnection,
    removeConnection,
    updatePosition,
    resetConnections,
    loadEnvironmentVariables,
    saveEnvironments,
    saveVolume,
    savePort,
    savePorts,
    saveInputs,
    updateName,
    updateServerId,
  };
};
