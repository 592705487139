import { useRecoilState } from "recoil";
import { showRightPaneState } from "../recoils/uiState";

export const usePane = () => {
  const [showRightPane, setShowRightPane] = useRecoilState(showRightPaneState);

  const toggleRightPane = () => {
    setShowRightPane(!showRightPane);
  };

  return { showRightPane, toggleRightPane };
};
