import { ASCard, ASEasyTable, ASIconButton } from "allegro-ui";
import * as React from "react";
import Draggable, { DraggableCore } from "react-draggable"; // Both at the same time
import styled from "styled-components";
import { CardContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { shapeToSeireki } from "src/fortress/utils/time";
import { translateLabel } from "src/fortress/utils/dataShape";

const Window = styled(ASCard)`
  z-index: 99999;
  position: absolute;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 6px 6px rgba(0, 0, 0, 0.12);
`;

const Header = styled.strong`
  width: 100%;
  display: flex;
  padding: 16px;
  cursor: pointer;

  border-bottom: 2px solid ${(props) => props.theme.secondaryBorderColor};
  > h1 {
    font-size: 24px;
    font-weight: bold;
  }
  > *:last-child {
    margin-left: auto;
  }
`;

const StyledEasyTable = styled(ASEasyTable)`
  padding: 12px;
  .row {
    padding: 4px 2px;
    border-bottom: 1px solid ${(props) => props.theme.secondaryBorderColor};
  }
`;

const ResponsiveBox = styled.div`
  display: flex;
  > :last-child {
    margin-left: 40px;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    > :last-child {
      margin: 0;
    }
  }
`;

export interface IFTDraggableJsonViewProps {
  title: string;
  id: number;
  data: any;
  onClose: any;
}

export function FTDraggableJsonView(props: IFTDraggableJsonViewProps) {
  const easyTableProps: { colName: string; colValue: string }[] =
    Object.entries(props.data.contents.rawdata).map(([col, val]) => {
      const ignoreDateParseColNames = [
        "個人番号",
        "世帯番号",
        "宛名郵便番号",
        "受給者個人番号",
        "保護者個人番号",
        "要件児童個人番号",
      ];

      /**
       * 健康カルテで 年齢、カ月、日にちの連続した数字であわらされる項目 例）届出日年齢:340830
       */
      const kenkaruAgeSpecificShapes = ["接種日年齢"];
      const kenkaruAgeSpecificShapesWithoutMMDD = ["届出日年齢"];

      let parsed: any = String(val);

      //除外項目以外は日付パースできる場合、パースする。

      if (kenkaruAgeSpecificShapes.includes(col)) {
        /**
         * 予防接種の接種日年齢
         */
        //6桁に統一する。
        const ret = ("000000" + val).slice(-6);
        const m = ret.match(/([0-9]{2})([0-9]{2})([0-9]{2})/);
        console.log(m);
        if (m) parsed = `${Number(m[1])}歳${Number(m[2])}か月${Number(m[3])}日`;
      }
      if (kenkaruAgeSpecificShapesWithoutMMDD.includes(col)) {
        const ret = ("000000" + val).slice(-6);
        const m = ret.match(/([0-9]{2})([0-9]{2})([0-9]{2})/);
        console.log(m);
        if (m) parsed = `${Number(m[1])}歳`;
      } else if (!ignoreDateParseColNames.includes(col)) {
        parsed = new RegExp(/[0-9]{8}/).test(String(val))
          ? shapeToSeireki(Number(val))
          : val;
      }

      if (parsed === 0) {
        parsed = "無回答";
      }

      return {
        colName: col,
        colValue: String(parsed) ?? "---",
      };
    });

  const half = Math.ceil(easyTableProps.length / 2);
  return (
    <Draggable handle="strong">
      <Window>
        <Header>
          <h1>{translateLabel(props.title)}</h1>

          <div>
            <ASIconButton
              size="small"
              onClick={() => {
                props.onClose(props.id);
              }}
            >
              <CloseIcon />
            </ASIconButton>
            {/* <ASIconButton>min</ASIconButton> */}
          </div>
        </Header>
        <CardContent>
          <ResponsiveBox>
            <StyledEasyTable
              tableData={easyTableProps.splice(0, half)}
            ></StyledEasyTable>
            <StyledEasyTable
              tableData={easyTableProps.splice(-half)}
            ></StyledEasyTable>
          </ResponsiveBox>
        </CardContent>
      </Window>
    </Draggable>
  );
}
