import { ASFormLabel, ASErrorField } from "../atoms";
import { ASRequiredMark } from "../atoms/ASRequiredMark";
import styled from "styled-components";

export const ASSupportText = styled.h6<{ disabled?: boolean }>`
  font-weight: normal;
  font-size: 12px;
  line-height: 1.6;
  opacity: 0.5;
  margin-top: 0px;
  margin-bottom: 8px;
  color: ${(props) =>
    props.disabled ? props.theme.disabledColor : props.theme.textColor};
`;

export type ASFormFieldBaseProps = {
  error?: boolean;
  editable?: boolean;
  design?: "digicho" | "default";
  supportText?: string;
  isdesktop?: boolean;
  label: string;
  required?: boolean;
  errorText?: string;
  requiredMarker?: string;
  optionalMarker?: string;
};

export const ASFormFieldBase: React.FunctionComponent<ASFormFieldBaseProps> = ({
  children,
  label,
  required = false,
  editable = false,
  error = false,
  errorText,
  supportText,
  requiredMarker,
  optionalMarker,
  isdesktop = true,
}) => {
  return (
    <div>
      <ASFormLabel error={error} disabled={!editable}>
        {label}
        {
          <ASRequiredMark
            required={required}
            requiredMarker={requiredMarker}
            oprionalMarker={optionalMarker}
          />
        }
      </ASFormLabel>
      {supportText ? (
        <ASSupportText disabled={!editable}>{supportText}</ASSupportText>
      ) : null}
      {children}
      {error && <ASErrorField>{errorText}</ASErrorField>}
    </div>
  );
};
