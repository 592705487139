import React, { useEffect, useRef } from "react";
import { useLogTail } from "../../hooks/useLogViewer";
import styled from "styled-components";
import { ASButton, ASIconButton, ASNoDataPlaceHolder } from "allegro-ui";
import moment from "moment";
import { Slot } from "../../models";

import SyncIcon from "@material-ui/icons/Sync";
import { LCLoadingBoard } from "src/cheetah/components/libs/ASLowCodeBrowser";

const Container = styled.div`
  overflow: scroll;
  width: 100%;
  height: 100%;
`;

const LogRow = styled.div`
  line-height: 1.5;
  font-weight: normal;
`;

const LogTime = styled.div`
  display: inline-block;
  margin: 0 8px;
  opacity: 0.8;
`;

const RedText = styled.span`
  color: ${(props) => props.theme.negativeColor};
`;

const GreenText = styled.span`
  color: ${(props) => props.theme.positiveColor};
`;

const XerviceLabel = styled.div`
  background: #000;
  display: inline-block;
`;

const ButtonGroup = styled.div`
  position: absolute;
  top: 48px;
  right: 8px;
  > * {
    margin-left: 8px;
    height: 35px;
  }
`;

const LoadingBox = styled.div`
  /* height: 300px; */
  height: 100%;
`;

const ClearButton = styled(ASButton)``;

const Warn = styled.div`
  color: ${(props) => props.theme.cautionColor};
`;
type Props = {
  slot?: Slot;
};

export const XRLogViewer: React.FC<Props> = (props) => {
  const maxLogsNum = 1000;
  const { logs, isLoading, clearLogs, resyncLog } = useLogTail(props.slot);

  const anchorRef = useRef<HTMLDivElement>(null);

  // このコールバックを呼び出して ref.current.scrollIntoView() を呼び出してスクロール
  const scrollToBottomOfList = React.useCallback(() => {
    if (anchorRef.current) {
      anchorRef!.current!.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    } else {
      console.log("erro");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorRef]);

  const handleClickSyncButton = async () => {
    await resyncLog();
  };

  useEffect(() => {
    scrollToBottomOfList();
  }, [logs, scrollToBottomOfList]);

  const renderLogs = () => {
    if (logs.length > 0)
      return [...logs].reverse().map((log, id) => {
        if (!log) return null;

        return (
          <LogRow key={`log-row-content-${id}`}>
            <XerviceLabel>
              [{log.xerviceName ?? log.xerviceId ?? ""}]
            </XerviceLabel>
            <LogTime>
              {moment(log.time).format("YYYY/MM/DD-HH:mm:ss") ?? ""}
            </LogTime>
            {log.stream === "error" ? (
              <RedText>{log.log}</RedText>
            ) : log.stream === "docker" ? (
              <GreenText>{log.log}</GreenText>
            ) : (
              <span>{log.log ?? ""}</span>
            )}
          </LogRow>
        );
      });
  };

  return (
    <Container>
      <ButtonGroup>
        <ClearButton
          kind="secondary"
          onClick={() => {
            clearLogs();
          }}
        >
          clear
        </ClearButton>
        <ASIconButton kind="secondary" onClick={handleClickSyncButton}>
          <SyncIcon />
        </ASIconButton>
      </ButtonGroup>
      <div>
        {isLoading ? (
          <LoadingBox>
            <LCLoadingBoard />
          </LoadingBox>
        ) : (
          <>
            {logs.length > 0 ? (
              renderLogs()
            ) : (
              <ASNoDataPlaceHolder>データがありません</ASNoDataPlaceHolder>
            )}
          </>
        )}
        {logs.length >= maxLogsNum ? (
          <Warn>[log is full] Please clear logs.</Warn>
        ) : null}

        <div id="bottom-of-list" ref={anchorRef}></div>
      </div>
    </Container>
  );
};
