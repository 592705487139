import { MenuItem, Popover, Paper, Popper } from "@material-ui/core";
import styled from "styled-components";
import { ReferenceData } from "../../models/DynamicReference";
import React from "react";

const MenuContainer = styled.div`
  margin-top: 4px;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: ${(props) => props.theme.borderRadiusMd};
  background: ${(props) => props.theme.cardColor};
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 6px 6px rgba(0, 0, 0, 0.16); */
  z-index: 1500;
`;

const StyledPaper = styled(Paper)<{ width: number }>`
  width: ${(props) => props.width}px;
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  width: 100%;
  /* background: ${(props) => props.theme.cardColor}; */
`;

export const ReferenceMenu: React.FC<{
  options: ReferenceData[];
  open: boolean;
  anchorEl?: any | ((element: any) => Element) | null | undefined;
  onClick?: (value: string) => void;
}> = (props) => {
  const { open, anchorEl, options, onClick } = props;
  const handleClick = (option: ReferenceData) => {
    if (onClick) {
      onClick(option.label);
    }
  };

  return (
    <MenuContainer>
      {open && options.length > 0 ? (
        <Popper
          key={`lowcode-search-menu-${Math.random()}`}
          open={true}
          anchorEl={anchorEl}
          style={{ zIndex: 100000 }}
        >
          <StyledPaper width={anchorEl?.clientWidth}>
            {options.map((option) => {
              return (
                <StyledMenuItem
                  key={option.label}
                  onMouseDown={() => {
                    if (onClick) {
                      onClick(option.value);
                    }
                  }}
                >
                  {option.label}
                </StyledMenuItem>
              );
            })}
          </StyledPaper>
        </Popper>
      ) : (
        <></>
      )}
    </MenuContainer>
  );
};
