//Libs
import styled from "styled-components";
//Components

import { OEHelpButton, OEUserButton, OELogo } from "../atoms";
import { OEFilesSearchBar } from "../atoms/OEFilesSearchBar";
import { Link } from "react-router-dom";

const MainPanel = styled.div`
  height: 100%;
  width: 250px;
  display: flex;
  background: #2a2b2d;
  flex-direction: column;
  padding: 16px;
`;

const BottomBox = styled.div`
  margin-top: auto;
`;

export const OELeftMainPanel: React.FC = () => {
  return (
    <MainPanel>
      <OELogo />
      <OEFilesSearchBar />

      <BottomBox>
        <OEUserButton />
        <OEHelpButton />
        <Link to="/activities">activity</Link>
      </BottomBox>
    </MainPanel>
  );
};
