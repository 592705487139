import { useCallback, useState } from "react";
import { api } from "src/api";
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "src/root/recoils/userState";
import { cursorPipelineIdState, slotRequestId } from "../recoils";
import { Input, Output } from "../models";

export type LinkObject = {
  linkObjectId: string;

  pipelineId: string;
  name: string;
  url: string;
  description: string;
  inputs: Array<Input>;
  outputs: Array<Output>;
  ui: {
    x: number;
    y: number;
  };
};

export const defaultLinkObject = {
  pipelineId: "",
  name: "",
  url: "",
  description: "",
  linkObjectId: "",
  inputs: [
    {
      name: "link",
      // url?: 'string';
      portInside: -1,
      // portOutside?: number;
      // type?: "http" | "tcp";
      usePrivateGateway: false,
      // customDomain?: string;
    },
  ],
  outputs: [],
  ui: {
    x: 0,
    y: 0,
  },
};

export const useLinkObjectList = () => {
  const [list, setList] = useState<LinkObject[]>([]);

  const auth = useRecoilValue(userState);
  const [slotReqId, setSlotReqId] = useRecoilState(slotRequestId);
  const cursorPipelineId = useRecoilValue(cursorPipelineIdState);
  // const cursorPipeline = useRecoilValueLoadable(cursorPipelineState);

  const contractor: string =
    typeof auth.contractorNo === "string" ? auth.contractorNo : "";
  const apiKey: string = typeof auth.token === "string" ? auth.token : "";

  const fetchOne = useCallback(
    async (linkObjectId?: string) => {
      if (!linkObjectId) {
        console.warn("linkObjectId is undefined");
      }
      const res = await api({
        method: "GET",
        url: `/nxtal/linkobject/${linkObjectId}`,
        params: {
          pipelineId: cursorPipelineId,
        },
      });
      const linkObjects: LinkObject = res.data.message.contents.rawdata;
      return linkObjects;
    },
    [cursorPipelineId]
  );

  /**
   * @deprecated fetchListを使ってください
   */
  const syncList = useCallback(async () => {
    const res = await api({
      method: "GET",
      url: `/nxtal/linkobject`,
      params: {
        pipelineId: cursorPipelineId,
      },
    });

    //@TODO エラーハンドリング
    const resData: LinkObject[] = res.data.message.map(
      (d: any) => d.contents.rawdata
    );

    setList((cur) => [...resData]);
    return resData;
  }, [cursorPipelineId]);

  const fetchList = useCallback(
    async (pipelineId?: string): Promise<LinkObject[]> => {
      const res = await api({
        method: "GET",
        url: `/nxtal/linkobject`,
        params: {
          pipelineId: pipelineId ?? cursorPipelineId,
        },
      });

      //@TODO エラーハンドリング
      const resData: LinkObject[] = res.data.message.map(
        (d: any) => d.contents.rawdata
      );

      setList((cur) => [...resData]);
      return resData;
    },
    [cursorPipelineId]
  );

  /**
   * @deprecated fetchListを使用してください。
   */
  const getList = useCallback(async () => {
    //add
    return await syncList();

    //set list
    // return list;
  }, [list, syncList]);

  const add = async (pipelineId: string) => {
    //add
    const res = await api({
      method: "POST",
      url: `/nxtal/linkobject/`,
      params: {},
      data: {
        pipelineId: pipelineId,
        name: "LinkObject",
        url: "",
        description: "",
        ui: {
          x: 0,
          y: 0,
        },
      },
    });
    await setList(res.data);
    //set list
  };

  /**
   * インポーター専用の関数
   * @param body
   * @returns linkObjectId : string
   */
  const addForImporter = async (body: any): Promise<string | undefined> => {
    const res = await api({
      method: "POST",
      url: `/nxtal/linkobject/`,
      params: {},
      data: body,
    });

    console.log({ res });
    if (res.status === 200)
      return res.data.message.contents.rawdata.linkObjectId;

  };

  const updateOne = useCallback(
    async (linkObjectId: string, newProps: LinkObject) => {
      //update
      const res = await api({
        method: "PUT",
        url: `/nxtal/linkobject/${linkObjectId}`,
        params: {},
        data: newProps,
      });
      await syncList();
      setList(res.data);
      //set list
    },
    [syncList]
  );

  const remove = async (linkObjectId: string) => {
    //update
    const res = await api({
      method: "DELETE",
      url: `/nxtal/linkobject/${linkObjectId}`,
    });
    await syncList();
    setList(res.data);
    //set list
  };

  const updateIO = useCallback(
    async (slotId, inputs, outputs) => {
      const res = await api({
        method: "PUT",
        url: `/contractors/${contractor}/slot/${slotId}`,
        params: {
          apikey: apiKey,
        },
        data: {
          parentPipelineId: cursorPipelineId,
          inputs: inputs,
          outputs: outputs,
        },
      });
      setSlotReqId(slotReqId + 1);
    },
    [apiKey, contractor, cursorPipelineId, setSlotReqId, slotReqId]
  );

  const reloadList = () => {
    setSlotReqId(slotReqId + 1);
  };

  // スロットリストが更新されないのでページに直書き
  // const deleteSlot = useCallback(
  //   async (slot: Slot | undefined) => {
  //     if (slot !== undefined) {
  //       try {
  //         const contractor: string =
  //           typeof auth.contractorNo === "string" ? auth.contractorNo : "";

  //         const apiKey: string =
  //           typeof auth.token === "string" ? auth.token : "";
  //         const res = await api({
  //           method: "DELETE",
  //           url: `/contractors/${contractor}/slot/${slot.id}`,
  //           params: {
  //             apikey: apiKey,
  //           },
  //         });
  //         setSlotReqId(slotReqId + 1);
  //       } catch (err) {
  //         console.error(err);
  //       }
  //     }
  //     setSlotReqId(slotReqId + 1);
  //   },
  //   [auth.contractorNo, auth.token, setSlotReqId, slotReqId]
  // );
  return {
    getList,
    fetchOne,
    fetchList,
    updateOne,
    remove,
    add,
    addForImporter,
    updateIO,
    reloadList,
  };
};
