const baseQueries = [
  {
    fileName: "妊娠届出業務",
    query: {
      asset: ["妊娠届出業務"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.親子ＳＥＱ": "any",
        "contents.rawdata.届出日": "any",
        "contents.rawdata.妊娠週数": "any",
        "contents.rawdata.届出日年齢": "any",
        "contents.rawdata.スクリーニング項目": "any",
        "contents.rawdata.スクリーニング項目-2": "any",
        "contents.rawdata.スクリーニング項目-3": "any",
        "contents.rawdata.スクリーニング項目-4": "any",
        "contents.rawdata.スクリーニング項目-5": "any",
        "contents.rawdata.スクリーニング項目-6": "any",
        "contents.rawdata.スクリーニング項目-7": "any",
        "contents.rawdata.スクリーニング項目-8": "any",
        "contents.rawdata.スクリーニング項目-9": "any",
        "contents.rawdata.スクリーニング項目-10": "any",
        "contents.rawdata.※12週以降の届出": "any",
        "contents.rawdata.20歳以下・妊婦/第1子を10": "any",
        "contents.rawdata.20歳以下・夫（パートナー）": "any",
        "contents.rawdata.パートナーの就労なし": "any",
        "contents.rawdata.流産・死産回数が多い": "any",
        "contents.rawdata.①計画外の妊娠（戸惑った・困る": "any",
        "contents.rawdata.②計画外の妊娠・夫（戸惑った・": "any",
        "contents.rawdata.④⑥喫煙・飲酒をやめることがで": "any",
        "contents.rawdata.⑧⑫既往歴": "any",
        "contents.rawdata.こころの病気・カウ ⑧既往歴（身体）": "any",
        "contents.rawdata.健康問題あり": "any",
        "contents.rawdata.⑧手帳（身体・精神・療育）・自": "any",
        "contents.rawdata.⑪悩みを話せる人が身近にいない": "any",
        "contents.rawdata.⑬妊娠中から産後援助してくれる": "any",
        "contents.rawdata.⑭産後の生活が楽しみでない、わ": "any",
        "contents.rawdata.⑮愛情を受けて育った実感がない": "any",
        "contents.rawdata.不安が強い": "any",
        "contents.rawdata.表情が硬い・乏しい": "any",
        "contents.rawdata.コミュニケーションに問題があり": "any",
        "contents.rawdata.理解力・支援発信力等に気になる": "any",
        "contents.rawdata.容姿・整容": "any",
        "contents.rawdata.被虐待歴あり": "any",
        "contents.rawdata.養育能力に問題がある": "any",
        "contents.rawdata.DV": "any",
        "contents.rawdata.連れ子がある再婚（ステップファ": "any",
        "contents.rawdata.近所や地域から孤立している": "any",
        "contents.rawdata.虐待行為がある・育児状況に問題": "any",
        "contents.rawdata.発育・発達に課題がある": "any",
        "contents.rawdata.総合判定（妊娠届）": "any",
      },
    },
  },
  {
    fileName: "妊娠健診業務",
    query: {
      asset: ["妊娠健診業務"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.西暦年度": "any",
        "contents.rawdata.親子ＳＥＱ": "any",
        "contents.rawdata.受診日": "any",
        "contents.rawdata.所見": "any",
        "contents.rawdata.判定": "any",
        "contents.rawdata.高血圧": "any",
        "contents.rawdata.浮腫": "any",
        "contents.rawdata.尿たん白": "any",
        "contents.rawdata.貧血": "any",
        "contents.rawdata.血糖": "any",
        "contents.rawdata.ＨＢｓ抗原検査": "any",
        "contents.rawdata.ＨＴＬＶ－１抗体検査": "any",
        "contents.rawdata.総合判定": "any",
        "contents.rawdata.特記事項": "any",
        "contents.rawdata.妊娠週数": "any",
      },
    },
  },
  {
    fileName: "妊婦健診業務",
    query: {
      asset: ["妊婦健診業務"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.rawdata.状態区分列": "any",
        "contents.rawdata.西暦年度": "any",
        "contents.rawdata.受診日": "any",
        "contents.rawdata.所見": "any",
        "contents.rawdata.判定": "any",
        "contents.rawdata.高血圧": "any",
        "contents.rawdata.浮腫": "any",
        "contents.rawdata.尿たん白": "any",
        "contents.rawdata.貧血": "any",
        "contents.rawdata.血糖": "any",
        "contents.rawdata.ＨＢｓ抗原検査": "any",
        "contents.rawdata.ＨＴＬＶ－１抗体検査": "any",
        "contents.rawdata.総合判定": "any",
        "contents.rawdata.特記事項": "any",
        "contents.rawdata.妊娠週数": "any",
        "contents.rawdata.更新年月日(西暦)": "any",
      },
    },
  },
  {
    fileName: "産婦健診業務",
    query: {
      asset: ["産婦健診業務"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.西暦年度": "any",
        "contents.rawdata.親子ＳＥＱ": "any",
        "contents.rawdata.受診日": "any",
        "contents.rawdata.受診結果": "any",
        "contents.rawdata.EPDS": "any",
        "contents.rawdata.項目10が1点以上": "any",
        "contents.rawdata.医師等の判断により支援が必要": "any",
        "contents.rawdata.市による支援": "any",
      },
    },
  },
  {
    fileName: "幼児教育無償化",
    query: {
      asset: ["幼児教育無償化"],
      primaryKey: "contents.optdata.numeric_resident_register_code_child",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.rawdata.無償化事業所名称": "any",
      },
    },
  },
  {
    fileName: "子育て支援",
    query: {
      asset: ["子育て支援"],
      primaryKey: "contents.optdata.numeric_resident_register_code_child",
      limit: 100,
      order: { "contents.rawdata.実施期間開始年月日": -1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code_child": "any",
        "contents.rawdata.対象年度": "any",
        "contents.rawdata.事業所名称": "any",
        "contents.rawdata.実施期間開始年月日": "any",
        "contents.rawdata.実施期間終了年月日": "any",
      },
    },
  },
  {
    fileName: "こんにちは赤ちゃん訪問業務",
    query: {
      asset: ["こんにちは赤ちゃん訪問業務"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.西暦年度": "any",
        "contents.rawdata.訪問日": "any",
        "contents.rawdata.総合判定（赤ちゃん訪問）": "any",
        "contents.rawdata.EPDS": "any",
        "contents.rawdata.EPDS（内容）": "any",
        "contents.rawdata.ボンディング": "any",
        "contents.rawdata.ボンディング（内容）": "any",
        "contents.rawdata.母の年齢": "any",
        "contents.rawdata.在胎週間": "any",
        "contents.rawdata.体重（訪問時）": "any",
        "contents.rawdata.身長（訪問時）": "any",
        "contents.rawdata.育児状況": "any",
        "contents.rawdata.叩きたくなる": "any",
        "contents.rawdata.⑩自分を傷つける": "any",
        "contents.rawdata.③腹立たしく嫌になる ": "any",
        "contents.rawdata.⑤怒りがこみ上げる": "any",
        "contents.rawdata.家庭環境フォロー有無": "any",
      },
    },
  },
  {
    fileName: "母子保健基本業務",
    query: {
      asset: ["母子保健基本業務"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.家族構成": "any",
        "contents.rawdata.家族構成-1": "any",
        "contents.rawdata.家族構成-2": "any",
        "contents.rawdata.家族構成-3": "any",
        "contents.rawdata.家族構成-4": "any",
        "contents.rawdata.家族構成-5": "any",
        "contents.rawdata.家族構成-6": "any",
        "contents.rawdata.家族構成-7": "any",
        "contents.rawdata.家族構成-8": "any",
        "contents.rawdata.家族構成-9": "any",
        "contents.rawdata.家族人数": "any",
        "contents.rawdata.妊娠経過": "any",
        "contents.rawdata.妊娠経過-1": "any",
        "contents.rawdata.妊娠経過-2": "any",
        "contents.rawdata.妊娠経過-3": "any",
        "contents.rawdata.妊娠経過-4": "any",
        "contents.rawdata.妊娠経過-5": "any",
        "contents.rawdata.妊娠経過その他内容": "any",
        "contents.rawdata.喫煙": "any",
        "contents.rawdata.服薬": "any",
        "contents.rawdata.服薬期間": "any",
        "contents.rawdata.服薬内容": "any",
        "contents.rawdata.在胎週間": "any",
        "contents.rawdata.母の年齢": "any",
        "contents.rawdata.分娩方法": "any",
        "contents.rawdata.児の胎位": "any",
        "contents.rawdata.児の胎位（その他）": "any",
        "contents.rawdata.分娩場所": "any",
        "contents.rawdata.分娩場所（その他）": "any",
        "contents.rawdata.分娩時の外傷": "any",
        "contents.rawdata.分娩時の外傷（内容）": "any",
        "contents.rawdata.先天性異常": "any",
        "contents.rawdata.先天性異常（内容）": "any",
        "contents.rawdata.四肢の機能障害": "any",
        "contents.rawdata.四肢の機能障害（内容）": "any",
        "contents.rawdata.臍の異常": "any",
        "contents.rawdata.臍の異常（内容）": "any",
        "contents.rawdata.体重（出生時）": "any",
        "contents.rawdata.身長（出生時）": "any",
        "contents.rawdata.胸囲（出生時）": "any",
        "contents.rawdata.頭囲（出生時）": "any",
        "contents.rawdata.仮死": "any",
        "contents.rawdata.アプガール": "any",
        "contents.rawdata.保育器の使用": "any",
        "contents.rawdata.保育器の使用（内容）": "any",
        "contents.rawdata.酸素吸入": "any",
        "contents.rawdata.酸素吸入（内容）": "any",
        "contents.rawdata.黄疸": "any",
        "contents.rawdata.黄疸治療": "any",
        "contents.rawdata.ほ乳力（内容）": "any",
        "contents.rawdata.先天性代謝異常検査": "any",
        "contents.rawdata.先天性代謝異常検査（内容）": "any",
        "contents.rawdata.ＡＢＲ": "any",
        "contents.rawdata.ＡＢＲ（内容）": "any",
        "contents.rawdata.追視": "any",
        "contents.rawdata.首のすわり": "any",
        "contents.rawdata.ねがえり": "any",
        "contents.rawdata.おすわり": "any",
        "contents.rawdata.つかまりだち": "any",
        "contents.rawdata.つたい歩き": "any",
        "contents.rawdata.ひとりだち": "any",
        "contents.rawdata.ひとり歩き（歳）": "any",
        "contents.rawdata.ひとり歩き（月）": "any",
        "contents.rawdata.片言のはじまり（歳）": "any",
        "contents.rawdata.片言のはじまり（月）": "any",
        "contents.rawdata.栄養方法": "any",
      },
    },
  },
  {
    fileName: "４か月児健診業務",
    query: {
      asset: ["４か月児健診業務"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.西暦年度": "any",
        "contents.rawdata.受診日": "any",
        "contents.rawdata.総合判定（小児科）": "any",
        "contents.rawdata.総合判定（外科）": "any",
        "contents.rawdata.総合判定": "any",
        "contents.rawdata.妊娠中の喫煙（母親）": "any",
        "contents.rawdata.妊娠中の喫煙本数（母親）": "any",
        "contents.rawdata.妊娠中の飲酒（母親）": "any",
        "contents.rawdata.現在、喫煙していますか（母親）": "any",
        "contents.rawdata.現在の喫煙本数（母親）": "any",
        "contents.rawdata.現在の喫煙本数（父親）": "any",
        "contents.rawdata.その他の家族の中で喫煙する人": "any",
        "contents.rawdata.生後１か月時の栄養法": "any",
        "contents.rawdata.この地域で子育てをしたい": "any",
        "contents.rawdata.ゆったりした気分で過ごせる時間": "any",
        "contents.rawdata.育てにくさを感じていますか": "any",
        "contents.rawdata.育てにくさを感じた時の解決方法": "any",
        "contents.rawdata.親を後追いすることを知っている": "any",
        "contents.rawdata.数か月の間に家庭であったこと": "any",
        "contents.rawdata.数か月の間に家庭であったこと-1": "any",
        "contents.rawdata.数か月の間に家庭であったこと-2": "any",
        "contents.rawdata.数か月の間に家庭であったこと-3": "any",
        "contents.rawdata.数か月の間に家庭であったこと-4": "any",
        "contents.rawdata.数か月の間に家庭であったこと-5": "any",
        "contents.rawdata.数か月の間に家庭であったこと-6": "any",
        "contents.rawdata.数か月の間に家庭であったこと-7": "any",
        "contents.rawdata.揺さぶられ症候群を知っている": "any",
        "contents.rawdata.小児救急電話相談を知っている": "any",
        "contents.rawdata.かかりつけの医師を持つ": "any",
        "contents.rawdata.妊娠中働いていましたか（母親）": "any",
        "contents.rawdata.妊娠中の職場からの配慮": "any",
        "contents.rawdata.マタニティマークを知っていた": "any",
        "contents.rawdata.マタニティマークを利用したこと": "any",
        "contents.rawdata.産後、退院後の指導・ケア": "any",
        "contents.rawdata.家庭環境フォローの有無": "any",
        "contents.rawdata.体重": "any",
        "contents.rawdata.身長": "any",
        "contents.rawdata.胸囲": "any",
        "contents.rawdata.頭囲": "any",
        "contents.rawdata.カウプ指数": "any",
        "contents.rawdata.アンケート": "any",
        "contents.rawdata.昼の保護者": "any",
        "contents.rawdata.所見": "any",
        "contents.rawdata.所見-1": "any",
        "contents.rawdata.所見-2": "any",
        "contents.rawdata.所見-3": "any",
        "contents.rawdata.所見-4": "any",
        "contents.rawdata.集計用　所見": "any",
        "contents.rawdata.集計用　所見-1": "any",
        "contents.rawdata.集計用　所見-2": "any",
        "contents.rawdata.集計用　所見-3": "any",
      },
    },
  },
  {
    fileName: "乳児健診業務",
    query: {
      asset: ["乳児健診業務"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.西暦年度": "any",
        "contents.rawdata.受診日": "any",
        "contents.rawdata.総合判定": "any",
        "contents.rawdata.体重": "any",
        "contents.rawdata.身長": "any",
        "contents.rawdata.胸囲": "any",
        "contents.rawdata.頭囲": "any",
        "contents.rawdata.カウプ指数": "any",
      },
    },
  },
  {
    fileName: "１０か月児健診業務",
    query: {
      asset: ["１０か月児健診業務"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.西暦年度": "any",
        "contents.rawdata.受診日": "any",
        "contents.rawdata.総合判定": "any",
        "contents.rawdata.家庭環境フォローの有無": "any",
        "contents.rawdata.体重": "any",
        "contents.rawdata.身長": "any",
        "contents.rawdata.胸囲": "any",
        "contents.rawdata.頭囲": "any",
        "contents.rawdata.カウプ指数": "any",
        "contents.rawdata.アンケート": "any",
        "contents.rawdata.昼の保護者": "any",
        "contents.rawdata.所見": "any",
        "contents.rawdata.所見-1": "any",
        "contents.rawdata.所見-2": "any",
        "contents.rawdata.所見-3": "any",
        "contents.rawdata.所見-4": "any",
        "contents.rawdata.集計用　所見": "any",
        "contents.rawdata.集計用　所見-1": "any",
        "contents.rawdata.集計用　所見-2": "any",
        "contents.rawdata.集計用　所見-3": "any",
      },
    },
  },
  {
    fileName: "１歳６か月児健診業務",
    query: {
      asset: ["１歳６か月児健診業務"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.受診日": "any",
        "contents.rawdata.総合判定": "any",
        "contents.rawdata.西暦年度": "any",
        "contents.rawdata.宛名番号": "any",
        "contents.rawdata.世帯番号": "any",
        "contents.rawdata.う歯": "any",
        "contents.rawdata.現在、喫煙していますか（母親）": "any",
        "contents.rawdata.現在の喫煙本数（母親）": "any",
        "contents.rawdata.現在の喫煙本数（父親）": "any",
        "contents.rawdata.四種混合（１期初回３回）の完了": "any",
        "contents.rawdata.麻しん・風しん予防接種の完了": "any",
        "contents.rawdata.この地域で子育てをしたい": "any",
        "contents.rawdata.浴室のドアを開けられない工夫": "any",
        "contents.rawdata.ゆったりした気分で過ごせる時間": "any",
        "contents.rawdata.育てにくさを感じていますか": "any",
        "contents.rawdata.育てにくさを感じた時の解決方法": "any",
        "contents.rawdata.指さしするのを知っている": "any",
        "contents.rawdata.家庭環境フォローの有無": "any",
        "contents.rawdata.体重": "any",
        "contents.rawdata.身長": "any",
        "contents.rawdata.胸囲": "any",
        "contents.rawdata.頭囲": "any",
        "contents.rawdata.カウプ指数": "any",
        "contents.rawdata.気づき（ア）有無 う歯罹患型": "any",
        "contents.rawdata.お父さんは育児をしていますか": "any",
        "contents.rawdata.数か月の間に家庭であったこと": "any",
        "contents.rawdata.数か月の間に家庭であったこと-1": "any",
        "contents.rawdata.数か月の間に家庭であったこと-2": "any",
        "contents.rawdata.数か月の間に家庭であったこと-3": "any",
        "contents.rawdata.数か月の間に家庭であったこと-4": "any",
        "contents.rawdata.数か月の間に家庭であったこと-5": "any",
        "contents.rawdata.数か月の間に家庭であったこと-6": "any",
        "contents.rawdata.数か月の間に家庭であったこと-7": "any",
        "contents.rawdata.主な保護者（昼）": "any",
        "contents.rawdata.診察所見": "any",
        "contents.rawdata.診察所見-1": "any",
        "contents.rawdata.診察所見-2": "any",
        "contents.rawdata.診察所見-3": "any",
        "contents.rawdata.診察所見-4": "any",
        "contents.rawdata.集計用　所見": "any",
        "contents.rawdata.集計用　所見-1": "any",
        "contents.rawdata.集計用　所見-2": "any",
        "contents.rawdata.集計用　所見-3": "any",
        "contents.rawdata.更新年月日(西暦)": "any",
      },
    },
  },
  {
    fileName: "２歳児歯科健診業務",
    query: {
      asset: ["２歳児歯科健診業務"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.西暦年度": "any",
        "contents.rawdata.受診日": "any",
        "contents.rawdata.う歯": "any",
        "contents.rawdata.指導事項（歯科）": "any",
        "contents.rawdata.家庭環境フォローの有無": "any",
        "contents.rawdata.気づき（ア）有無": "any",
        "contents.rawdata.う歯罹患型": "any",
        "contents.rawdata.総合判定（精神発達）": "any",
        "contents.rawdata.主な保護者（昼）": "any",
      },
    },
  },
  {
    fileName: "３歳児健診業務",
    query: {
      asset: ["３歳児健診業務"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.西暦年度": "any",
        "contents.rawdata.受診日": "any",
        "contents.rawdata.未処置歯": "any",
        "contents.rawdata.処置歯": "any",
        "contents.rawdata.総合判定": "any",
        "contents.rawdata.保護者による仕上げ磨き": "any",
        "contents.rawdata.現在、喫煙していますか（母親）": "any",
        "contents.rawdata.現在の喫煙本数（母親）": "any",
        "contents.rawdata.現在、喫煙していますか（父親）": "any",
        "contents.rawdata.現在の喫煙本数（父親）": "any",
        "contents.rawdata.この地域で子育てをしたい": "any",
        "contents.rawdata.お父さんは育児をしていますか": "any",
        "contents.rawdata.ゆったりした気分で過ごせる時間": "any",
        "contents.rawdata.育てにくさを感じていますか": "any",
        "contents.rawdata.育てにくさを感じた時の解決方法": "any",
        "contents.rawdata.遊びに加わろうとするのを知って": "any",
        "contents.rawdata.数か月の間に家庭であったこと": "any",
        "contents.rawdata.かかりつけの医師を持つ": "any",
        "contents.rawdata.かかりつけの歯科医師を持つ": "any",
        "contents.rawdata.家庭環境フォローの有無": "any",
        "contents.rawdata.体重": "any",
        "contents.rawdata.身長": "any",
        "contents.rawdata.胸囲": "any",
        "contents.rawdata.頭囲": "any",
        "contents.rawdata.カウプ指数": "any",
        "contents.rawdata.気づき（ア）有無": "any",
        "contents.rawdata.う歯罹患型": "any",
        "contents.rawdata.主な保護者（昼）": "any",
        "contents.rawdata.診査所見-1": "any",
        "contents.rawdata.診査所見-2": "any",
        "contents.rawdata.診査所見-3": "any",
        "contents.rawdata.診査所見-4": "any",
        "contents.rawdata.集計用　所見-1": "any",
        "contents.rawdata.集計用　所見-2": "any",
        "contents.rawdata.集計用　所見-3": "any",
      },
    },
  },
  {
    fileName: "すくすく相談業務",
    query: {
      asset: ["すくすく相談業務"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { "contents.rawdata.相談日": -1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.西暦年度": "any",
        "contents.rawdata.相談日": "any",
        "contents.rawdata.判定": "any",
      },
    },
  },
  {
    fileName: "幼児相談業務",
    query: {
      asset: ["幼児相談業務"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.西暦年度": "any",
        "contents.rawdata.相談日": "any",
        "contents.rawdata.判定": "any",
      },
    },
  },
  {
    fileName: "妊娠期支援業務",
    query: {
      asset: ["妊娠期支援業務"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.西暦年度": "any",
        "contents.rawdata.親子ＳＥＱ": "any",
        "contents.rawdata.相談日": "any",
        "contents.rawdata.総合判定": "any",
        "contents.rawdata.内容": "any",
      },
    },
  },
  {
    fileName: "定期予防接種業務",
    query: {
      asset: ["定期予防接種業務"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.接種コード": "any",
        "contents.rawdata.接種回数": "any",
        "contents.rawdata.接種・予診日": "any",
        "contents.rawdata.接種日年齢": "any",
        "contents.rawdata.実施医療機関": "any",
      },
    },
  },
  {
    fileName: "宛名",
    query: {
      asset: ["宛名"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.個人番号": "any",
        "contents.rawdata.世帯番号": "any",
        "contents.rawdata.宛名郵便番号": "any",
        "contents.rawdata.性別区分ｺｰﾄﾞ短名称": "any",
        "contents.rawdata.生年月日": "any",
        "contents.rawdata.続柄名称漢字": "any",
        "contents.rawdata.住民区分ｺｰﾄﾞ短名称": "any",
        "contents.rawdata.氏名ｶﾅ": "any",
        "contents.rawdata.氏名漢字": "any",
        "contents.rawdata.外国人通称氏名ｶﾅ": "any",
        "contents.rawdata.外国人通称氏名漢字": "any",
        "contents.rawdata.外国人本名ｶﾅ": "any",
        "contents.rawdata.外国人本名": "any",
        "contents.rawdata.異動事由ｺｰﾄﾞｺｰﾄﾞ短名称": "any",
        "contents.rawdata.異動日": "any",
        "contents.rawdata.異動届出日": "any",
        "contents.rawdata.増減事由ｺｰﾄﾞｺｰﾄﾞ短名称": "any",
        "contents.rawdata.増減異動日": "any",
        "contents.rawdata.宛名住所": "any",
        "contents.rawdata.宛名地番": "any",
        "contents.rawdata.宛名方書漢字": "any",
      },
    },
  },
  {
    fileName: "ひとり親医療費助成",
    query: {
      asset: ["ひとり親医療費助成"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: {
        "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.代表受給者個人番号": "any",
        "contents.rawdata.受給者個人番号": "any",
        "contents.rawdata.資格認定年月日": "any",
        "contents.rawdata.資格取得年月日": "any",
        "contents.rawdata.資格喪失年月日": "any",
      },
    },
  },
  {
    fileName: "児童手当",
    query: {
      asset: ["児童手当"],
      primaryKey: "contents.optdata.numeric_resident_register_code_child",
      limit: 100,
      order: { _created: 1 },
      shape: {
        "id":"any",
        "contents.optdata.numeric_resident_register_code_child": "any",
        "contents.rawdata.要件児童個人番号": "any",
        "contents.rawdata.受給者個人番号": "any",
        "contents.rawdata.認定年月日": "any",
        "contents.rawdata.年度": "any",
        "contents.rawdata.消滅年月日": "any",
      },
    },
  },
  {
    fileName: "児童扶養手当",
    query: {
      asset: ["児童扶養手当"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.要件児童個人番号": "any",
        "contents.rawdata.受給者個人番号": "any",
        "contents.rawdata.認定日": "any",
        "contents.rawdata.喪失日": "any",
        "contents.rawdata.非該当年月日": "any",
      },
    },
  },
  {
    fileName: "乳幼児医療費助成",
    query: {
      asset: ["乳幼児医療費助成"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.受給者個人番号": "any",
        "contents.rawdata.資格認定届出日": "any",
        "contents.rawdata.異動事由略称": "any",
        "contents.rawdata.事由発生年月日": "any",
        // "contents.rawdata.認定却下区分": "any",
        "contents.rawdata.認定却下区分略称": "any",
        // "contents.rawdata.資格判定結果保護者": "any",
        "contents.rawdata.資格判定結果略称保護者": "any",
        "contents.rawdata.保護者個人番号": "any",
        // "contents.rawdata.資格状態区分ｺｰﾄﾞ": "any",
        "contents.rawdata.資格状態区分略称": "any",
        // "contents.rawdata.資格取得理由ｺｰﾄﾞ": "any",
        "contents.rawdata.資格取得理由略称": "any",
        "contents.rawdata.資格取得年月日": "any",
        "contents.rawdata.資格認定年月日": "any",
        // "contents.rawdata.資格認定理由ｺｰﾄﾞ": "any",
        "contents.rawdata.認定却下理由略称": "any",
        "contents.rawdata.資格満了年月日入院": "any",
        "contents.rawdata.資格満了年月日外来": "any",
        "contents.rawdata.資格喪失年月日": "any",
        // "contents.rawdata.資格喪失理由ｺｰﾄﾞ": "any",
        "contents.rawdata.資格喪失理由略称": "any",
        "contents.rawdata.証交付年月日": "any",
        // "contents.rawdata.受給者続柄ｺｰﾄﾞ": "any",
        "contents.rawdata.受給者関係ｺｰﾄﾞ略称": "any",
        "contents.rawdata.証有効期間開始日入院": "any",
        "contents.rawdata.証有効期間終了日入院": "any",
        "contents.rawdata.証有効期間開始日外来": "any",
        "contents.rawdata.証有効期間終了日外来  ": "any",
      },
    },
  },
  {
    fileName: "生活保護関係業務",
    query: {
      asset: ["生活保護関係業務"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.開始日": "any",
        "contents.rawdata.廃止日": "any",
        "contents.rawdata.増員日": "any",
        "contents.rawdata.減員日": "any",
      },
    },
  },
  {
    fileName: "自立支援医療（精神通院）",
    query: {
      asset: ["自立支援医療（精神通院）業務"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.有効期間開始": "any",
        "contents.rawdata.有効期間終了": "any",
      },
    },
  },
  {
    fileName: "障がい者福祉（身障手帳）",
    query: {
      asset: ["身体障害者手帳台帳"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.交付年月日": "any",
        "contents.rawdata.等級": "any",
        "contents.rawdata.部位名": "any",
        "contents.rawdata.障害名": "any",
        "contents.rawdata.原因名": "any",
      },
    },
  },
  {
    fileName: "障がい者福祉（療育手帳）",
    query: {
      asset: ["療育手帳交付台帳"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.交付年月日": "any",
        "contents.rawdata.障害程度": "any",
      },
    },
  },
  {
    fileName: "障がい者福祉（精神手帳）",
    query: {
      asset: ["精神保健福祉手帳台帳"],
      primaryKey: "contents.optdata.numeric_resident_register_code",
      limit: 100,
      order: { _created: 1 },
      shape: { "id":"any",
        "contents.optdata.numeric_resident_register_code": "any",
        "contents.rawdata.交付年月日": "any",
        "contents.rawdata.等級": "any",
        "contents.rawdata.有効期間終了": "any",
      },
    },
  },
];

const byName = (fileName: string) => {
  const find = baseQueries.find((obj) => obj.fileName === fileName);
  return find?.query;
};

const setChildId = (query: any, atena: string | number) => {
  return {
    ...query,
    search: {
      $or: [
        { "contents.optdata.numeric_resident_register_code": atena },
        { "contents.optdata.numeric_resident_register_code_child": atena },
      ],
    },
  };
};

export const personalDetailDataQueryFinder = {
  byName,
  setChildId,
};
