import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ASButton, ASChip, ASTableRow } from "allegro-ui";
import { darken, TableCell, TableRow } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import EditIcon from "@material-ui/icons/Edit";
import { D } from "src/fortress/components/atoms";
import { format } from "date-fns";

type Props = {
  title: string;
  lastUpdated: string;
  approval?: boolean;
  approvalLabel?: string;
  editable?: boolean;
  onDetailClick: () => void;
  onEditClick: () => void;
};

const StyledTableRow = styled(TableRow)`
  /* padding: 3px 24px; */
  padding: 0px;
  background: ${(props) => props.theme.cardColor};
  color: ${(props) => (props.theme.darkMode ? "#fff" : "#222")};
  height: 42px;
  /* border-radius: 20%; */
  width: 100%;
  margin-bottom: 12px;
  > *:first-child {
    padding-left: 24px;
    border-top-left-radius: ${(props) =>
      Math.min(24, props.theme.borderRadius)}px;
    border-bottom-left-radius: ${(props) =>
      Math.min(24, props.theme.borderRadius)}px;
  }
  > *:last-child {
    padding-right: 24px;
    border-top-right-radius: ${(props) =>
      Math.min(24, props.theme.borderRadius)}px;
    border-bottom-right-radius: ${(props) =>
      Math.min(24, props.theme.borderRadius)}px;
    /* margin-left: auto; */
  }
`;

const TimeContainer = styled.div`
  text-align: left;
  /* width: 12em; */
`;

const ChipContainer = styled.div`
  /* width: 8vw; */
  width: 80px;
`;

const StyledTableCell = styled(TableCell)`
  width: 100%;
  padding: 2px;
  margin-bottom: 12px;
`;

const Project = styled.div`
  font-size: 8px;
  color: ${darken("#fff", 0.3)};
`;

const renderApprovalChip = (approval?: boolean, approveLabel?: string) => {
  if (approval === true)
    return (
      <ASChip
        kind="positive"
        label={`${approveLabel ?? "承認"}`}
        design="onecolor"
      />
    );
  else if (approval === false)
    return <ASChip kind="negative" label="否認" design="onecolor" />;
  else return <></>;
};

export const MDSectionListItem: React.FC<Props> = (props) => {
  const { title, approval, approvalLabel, editable } = props;

  return (
    <StyledTableRow>
      {/* <StyledTableCell>{title}</StyledTableCell> */}
      <StyledTableCell>
        <TimeContainer>{`${props.lastUpdated ?? "-"}`}</TimeContainer>
      </StyledTableCell>
      <StyledTableCell>
        <ChipContainer>
          {renderApprovalChip(approval, approvalLabel)}
        </ChipContainer>
      </StyledTableCell>
      <StyledTableCell>
        <ASButton
          kind="secondary"
          onClick={() => {
            if (editable) props.onEditClick();
          }}
          disabled={!editable}
        >
          <EditIcon />
        </ASButton>
      </StyledTableCell>
      <StyledTableCell>
        <ASButton onClick={() => props.onDetailClick()}>
          <OpenInNewIcon />
        </ASButton>
      </StyledTableCell>
    </StyledTableRow>
  );
};
