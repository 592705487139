import React, { useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ASPageNav, ASTable } from "allegro-ui";
import { RiskAssessmentFormData } from "src/fortress/models/RiskAssessment";
import styled, { useTheme } from "styled-components";
import { HiroshimaLogo } from "../atoms/logo";
import { useRecoilState } from "recoil";
import { FTAllAssesmentListItem } from ".";
import { Question } from "../atoms";
import {
  assesmentPageIndexState,
  AssesmentPageNavState,
} from "src/fortress/recoils/assessmentState";

type Props = {
  assessmentList: any[];
};

const No = styled.div`
  text-align: center;
  padding-top: 150px;
  font-size: 32px;
  color: #dddddd;
`;

const Page = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

//全員のアセスメント一覧画面のコンポーネント

export const FTAllAssesmentList: React.FC<Props> = (props) => {
  const { assessmentList } = props;
  const [pageSettings] = useRecoilState(AssesmentPageNavState);
  const [pageIndex, setPageIndex] = useRecoilState(assesmentPageIndexState);

  //ページを遷移させる動作をしている。
  const handlePage = useCallback(
    (e: any, page: number) => {
      setPageIndex(page);
    },
    [setPageIndex]
  );

  //テーマを取ってきている。

  const theme = useTheme();
  const color = theme.darkMode ? "#dddddd" : "#dddddd";

  const tableBody =
    assessmentList.length > 0 ? (
      <>
        <TableContainer component={ASTable} borderRadius={18} kind="tertiary">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>回答者氏名</TableCell>
                <TableCell>フォーム</TableCell>
                <TableCell>作成日時</TableCell>
                {/* <TableCell align="left">職員評価</TableCell>
                <TableCell align="left">要対協登録状況</TableCell>
                <TableCell align="left">作成者</TableCell> */}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assessmentList.map((assessment: any, id) => (
                <FTAllAssesmentListItem
                  assessmentFormData={assessment}
                  key={id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Page>
          <ASPageNav
            count={pageSettings.pageTotalCount}
            page={pageIndex}
            onChange={handlePage}
          />
        </Page>
        <Question />
      </>
    ) : (
      <>
        <No>
          {/* <HiroshimaLogo size={150} color={color} /> */}
          <p>アセスメントデータが存在しません</p>
        </No>
      </>
    );

  return <>{tableBody}</>;
};
