import { SettingNavConf } from "src/cheetah/models/SettingNavConf";

export const createNAISettingNavConf = (
  bundleId: string,
  version: string
): SettingNavConf => {
  return {
    sections: [
      {
        navigations: [
          {
            title: "Management",
            link: `/ai/${bundleId}/management?version=${version}`,
          },
          {
            title: "Operation",
            link: `/ai/${bundleId}/operation?version=${version}`,
          },
        ],
      },
    ],
  };
};
