import {
  CHTabComponentProps,
  CHTabs,
} from "src/cheetah/components/molecules/CHTabs";
import { NAAISingleTemplate } from "../templates";
import { NABundleResources } from "./NABundleResources";
import { NABundleSpec } from "./NABundleSpec";

export const NAAIManagement: React.FC = () => {
  const tabs: CHTabComponentProps[] = [
    {
      label: "Spec",
      component: <NABundleSpec />,
    },
    {
      label: "Resources",
      component: <NABundleResources />,
    },
  ];
  return (
    <>
      <NAAISingleTemplate>
        <CHTabs tabs={tabs} disableBack />
      </NAAISingleTemplate>
    </>
  );
};
