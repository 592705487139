import { atom, selector, useRecoilValue } from "recoil";
import * as api from "src/utils";
import { PipelineDef, initialPipelineDef } from "../models";

export const pipelineListState = atom<PipelineDef[]>({
  key: "pipelineList",
  default: [],
});

//pipelineNameでパイプラインを指定している。
export const selectPipelineState = atom<string>({
  key: "selectPipeleine",
  default: "",
});

export const cursorPipelineState = selector<PipelineDef>({
  key: "curosrPipeline",
  get: ({ get }) => {
    const list: PipelineDef[] = get(pipelineListState);
    const pipelineName = get(selectPipelineState);

    return list.find((p) => p.name === pipelineName) ?? initialPipelineDef;
  },
});

export const fetchPipelineList = async () => {
  console.log("start fetch pipelines");
  try {
    const res = await api.fetchPipelines({
      contractorNo: sessionStorage.contractorNo,
    });

    if (res) return res.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const useCursorPipeline = (): PipelineDef =>
  useRecoilValue(cursorPipelineState);
