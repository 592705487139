import React, { useCallback, useEffect, useState } from "react";
import { Controller, Control, FieldValues } from "react-hook-form";

import { ASFormDesign, ASSelectionForm } from "../../models/ASLowCodeFormField";
import { ASSelect } from "allegro-ui";
import { ASFormLabel, ASErrorField } from "../atoms";

import { ASRequiredMark } from "../atoms/ASRequiredMark";
import {
  ReferenceData,
  createReference,
  getReferenceValue,
} from "../../models/DynamicReference";
import { ASFormFieldBase } from "./ASFormFieldBase";

type ASFormFieldProps = {
  id?: number | string;
  control: Control;
  defaultValue?: string;
  error: any;
  editable?: boolean;
  design?: ASFormDesign;
  supportText?: string;
  isdesktop?: boolean;
  placeHolder?: string;
  requiredMarker?: string;
  optionalMarker?: string;
};

export const ASSelectionFormField = (
  props: ASFormFieldProps & ASSelectionForm
) => {
  const {
    id,
    label,
    name,
    required,
    control,
    defaultValue,
    active,
    values,
    error,
    errorText,
    editable,
    supportText,
    design,
    placeHolder,
  } = props;

  const [selections, setSelections] = useState<ReferenceData[]>([]);

  const _values = typeof values === "string" ? createReference(values) : values;

  const init = useCallback(async () => {
    if (_values) {
      const selections = await getReferenceValue(_values);
      if (selections.length > 0) {
        setSelections(selections);
      }
    }
  }, [selections]);

  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      <ASFormFieldBase
        error={error}
        errorText={errorText}
        label={label}
        design={design}
        required={required}
        editable={editable}
        supportText={supportText}
        requiredMarker={props.requiredMarker}
        optionalMarker={props.optionalMarker}
      >
        <Controller
          key={`lc-create-data-form-${id}-${name}-${defaultValue}`}
          name={name}
          control={control}
          defaultValue={defaultValue ?? active}
          rules={{ required: required }}
          render={({ field: { onChange } }) => (
            <>
              <ASSelect
                kind={props.design === "digicho" ? "digicho" : "secondary"}
                fullWidth
                defaultValue={defaultValue ?? active}
                readOnly={!(editable ?? true)}
                label=""
                primary={true}
                data={selections}
                placeHolder={placeHolder}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
              />
            </>
          )}
        />
      </ASFormFieldBase>
    </div>
  );
};
