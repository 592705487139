import { AxiosInstance } from "axios";
import { User, createUser as _createUser } from "../models/User";
import { NewtQuery } from "../models";

type Args = {
  limit?: number;
  skip?: number;
  withDisabled?: boolean;
};

export const getUsers = async (instance: AxiosInstance, props: Args) => {
  const params = {
    limit: props.limit ?? 500,
    skip: props.skip ?? 0,
    withDisabled: props.withDisabled ?? false,
  };
  const res = await instance({
    method: "GET",
    url: `/users`,
    params: params,
  });
  const users: User[] = res.data.map((d: any) => {
    return _createUser(d);
  });

  return users;
};

export const getUsersByDataProvider = async (
  instance: AxiosInstance,
  newtQuery: NewtQuery,
  options?: { count?: boolean }
): Promise<any[]> => {
  const path = "/users/dataprovider";
  const res = await instance.post(path, newtQuery, { params: options });
  return res.data.message ?? [];
};

type CreateUserProps = {
  email: string;
  password: string;
  userRealName?: string;
  zip?: string;
  address?: string;
  phoneNumber?: string;
};

export const createUser = async (
  instance: AxiosInstance,
  data: CreateUserProps
) => {
  const res = await instance({
    method: "POST",
    url: "/users",
    data: data,
  });

  return res;
};
