import {
  ASLowCodeFormContainer,
  ASTextFormField,
} from "allegro-low-code-components";
import { ASButton } from "allegro-ui";
import React, { useCallback, useEffect, useState } from "react";
import { alertCreatingFormDefinition } from "./formDefinition";
import { createNewtZeroAPI } from "src/api";
import { useParams } from "react-router-dom";
import produce from "immer";
import { LoadingBox } from "src/opt-editor/components/atoms/OELoddingBox";

type Props = {};

export default function NZAlertDetailView({}: Props) {
  const [defaultValue, setDefaultValue] = useState<{ [key: string]: any }>({});
  const [isUpdate, setIsUpdate] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);

  const urlParams = useParams<{ monitorId: string }>();

  /**
   *  画面ロード
   */

  const didMount = useCallback(async () => {
    const nz = createNewtZeroAPI(2);

    if (urlParams.monitorId) {
      const resData = await nz.alertJob.fetchByMonitorId(urlParams.monitorId);

      if (resData) {
        const formatted: any = produce(
          resData.contents.rawdata,
          (draft: any) => {
            draft.enable = draft.enable ? "Enabled" : "Disabled";
          }
        );
        setDefaultValue(formatted);

        if (resData) setIsUpdate(true);
      }

      setIsLoaded(true);
    }
  }, [urlParams.monitorId]);

  useEffect(() => {
    didMount();
  }, [didMount]);

  /**
   * デストラクタ
   */
  useEffect(() => {
    return () => {
      setIsUpdate(false);
      // setDefaultValue({});
    };
  }, []);

  return isLoaded ? (
    <div>
      <React.Suspense fallback={<>loading</>}>
        <ASLowCodeFormContainer
          formDefinition={alertCreatingFormDefinition}
          fullWidth
          isUpdate={isUpdate}
          defaultValues={defaultValue}
          // assetDetail
          //   ? {
          //       name: assetDetail?.name,
          //       sharings: assetDetail?.sharings,
          //       tags: assetDetail?.tags,
          //     }
          //   : undefined
          // }
        />
      </React.Suspense>
    </div>
  ) : (
    <LoadingBox />
  );
}
