import { useRecoilValueLoadable } from "recoil";
import { groupSingleState } from "../recoils/groupState";
import { useMyRole } from "./useMyRole";
import { useState } from "react";
import { createnAuthAPI } from "src/api";
import { GroupInput } from "allegro-api";

export const useGroupSingle = (groupId: string) => {
  const nauth = createnAuthAPI();
  const myRole = useMyRole();
  const groupLoadable = useRecoilValueLoadable(groupSingleState(groupId));
  const group =
    groupLoadable.state === "hasValue" ? groupLoadable.contents : null;

  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const handleOpenUpdateModal = () => {
    setShowUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
  };

  const updateGroup = async (groupInput: GroupInput) => {
    const res = await nauth.group.update(groupId, groupInput);
    if (res) {
      window.location.reload();
    }
  };

  return {
    myRole,
    groupLoadable,
    group,
    showUpdateModal,
    updateGroup,
    handleOpenUpdateModal,
    handleCloseUpdateModal,
  };
};
