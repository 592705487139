import parse from "html-react-parser";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

type Props = {
  html: string;
};

export const HTMLParser: React.FC<Props> = (props) => {
  return <Container>{parse(props.html)}</Container>;
};
