import { darken } from "polished";
import styled from "styled-components";

export const ASNav = styled.nav`
  display: flex;
  flex-direction: column;

  a {
    margin-bottom: 2px;
    padding: 8px;
    display: inline-block;
    color: ${(props) => (props.theme.darkMode ? "#fff" : "#000")};
    text-decoration: none;
    width: 100%;
    align-items: center;
    font-weight: 500;
    vertical-align: middle; /* 上下中央寄せ */
    > * {
      vertical-align: middle; /* 上下中央寄せ */
    }
  }
  a:hover {
    color: ${(props) => darken(0.2, props.theme.primaryColor)};
    transition-property: all;
    transition-duration: 0.3s;
    /* イージングを指定 */
    transition-timing-function: linear;
  }
  a.active {
    color: ${(props) => props.theme.primaryOnText};
    background: ${(props) => props.theme.primaryColor};
    border-radius: ${(props) => props.theme.borderRadiusSm}px;
    transition-property: all;
    transition-duration: 0.3s;
    /* イージングを指定 */
    transition-timing-function: linear;
  }
`;
