import React, { useCallback, useEffect } from "react";
import styled from "styled-components";

import { FileIcon, defaultStyles } from "react-file-icon";

export type FileIconProps = {
  extension: string;
};

export const OEFileDocIcon: React.FC<FileIconProps> = (props) => {
  return (
    <FileIcon
      extension={props.extension}
      type="document"
      labelColor={defaultStyles.doc.labelColor}
    />
  );
};
