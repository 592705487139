import * as React from "react";
import { NTXerviceImportFormStep1 } from "./NTXerviceImportFormStep1";
import { NTXerviceImportFormStep2 } from "./NTXerviceImportFormStep2";
import { ManifestV1 } from "src/newtroid-console/models/ManifestV1";

export interface INTXerviceImportModalContentProps {
  onCancel: () => void;
}

export function NTXerviceImportModalContent(
  props: INTXerviceImportModalContentProps
) {
  const [stepId, setStepId] = React.useState(0);
  const [manifest, setManifest] = React.useState<ManifestV1>();

  const handleStep = (data: any) => {
    console.log(data);
    setManifest(data);
    setStepId(1);
  };

  return (() => {
    switch (stepId) {
      case 0:
        return (
          <NTXerviceImportFormStep1
            onCancel={props.onCancel}
            onImport={handleStep}
          />
        );
      case 1:
        return <NTXerviceImportFormStep2 manifest={manifest} />;

      default:
        return <div>ERROR</div>;
    }
  })();
}
