import React, { Suspense } from "react";
import { XRGlobalNav } from "../organisms";
import styled from "styled-components";
import { PTGlobalNav } from "src/portal/components/organisms/PTGlobalNav";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
`;

type Props = {
  useOffsetBottom?: boolean;
};

const Content = styled.div`
  flex-grow: 1;
`;

const BottomOffset = styled.div`
  width: 100%;
  height: 24px;
`;

export const XRMainViewTemplate: React.FC<Props> = ({
  children,
  useOffsetBottom = false,
}) => {
  return (
    <Root>
      {/* <PTGlobalNav /> */}
      <XRGlobalNav />
      <React.Suspense fallback={<></>}>
        <Content>
          {children}
          {useOffsetBottom ? <BottomOffset /> : null}
        </Content>
      </React.Suspense>
    </Root>
  );
};
