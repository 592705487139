import * as React from "react";
import { useState } from "react";
import { ASModal, ASButton } from "allegro-ui";

import { ASLowCodeFormContainer } from "allegro-low-code-components";
import { serverCreatingFormDefinition } from "../../../cheetah/definitions/forms/serverCreatingFormDefinition";

import { ASLowCodeDataBrowserContainer } from "src/cheetah/components/libs/ASLowCodeBrowser";

import styled from "styled-components";
import { createServerBroweserUi } from "../../../cheetah/definitions/listPages/serverBrowserUi";
import FTSystemPageTemplate from "./FTSystemPageTemplate";

const Header = styled.div`
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.textColor};
  > *:last-child {
    margin-left: auto;
  }
`;

interface IFTServerListPageTemplateProps {}

const FTServerListPageTemplate: React.FunctionComponent<IFTServerListPageTemplateProps> =
  (props) => {
    const [open, setOpen] = useState(false);

    const handleModalOpen = () => {
      setOpen(true);
    };

    const handleModalClose = () => {
      setOpen(false);
    };
    return (
      <>
        <FTSystemPageTemplate>
          <Header>
            <h2>サーバー一覧</h2>
            <ASButton kind="primary" onClick={handleModalOpen}>
              新規作成
            </ASButton>
          </Header>

          <ASLowCodeDataBrowserContainer
            name="ft-server-list-page"
            uiConf={createServerBroweserUi()}
          />
        </FTSystemPageTemplate>

        <ASModal open={open} onClose={handleModalClose}>
          <ASLowCodeFormContainer
            formDefinition={serverCreatingFormDefinition}
            onClose={handleModalClose}
          />
        </ASModal>
      </>
    );
  };

export default FTServerListPageTemplate;
