import { atomFamily, selectorFamily } from "recoil";
import { ASBrowserUiConf } from "../../types/LowCodeUiDefinition";

export const sortFilterState = atomFamily<{ [key: string]: any }, string>({
  key: "sortFilterState",
  default: { _created: -1 },
});

export const newtQueryBase = atomFamily<ASBrowserUiConf | undefined, string>({
  key: "newtQueryBaseState",
  default: undefined,
});

export const lowCodeVerticalFilterState = atomFamily<any, string>({
  key: "lowCodeVerticalFilterState",
  default: {},
});

// ! TODO どのカラムを使うかを汎用化する
export const searchFilterState = selectorFamily<{ [key: string]: any }, string>(
  {
    key: "searchFilterState",
    get:
      (key) =>
      ({ get }) => {
        const currentSearchWord = get(userNameSearchWordState(key));
        if (currentSearchWord && currentSearchWord !== "") {
        } else {
          return {};
        }

        const searchQuery = [
          {
            "contents.rawdata.name": {
              $regex: currentSearchWord,
            },
          },
        ];

        return searchQuery;
      },
  }
);

export const userNameSearchWordState = atomFamily<string | null, string>({
  key: "userNameSerachWordState",
  default: null,
});

export const userListPageIndexState = atomFamily<number, string>({
  key: "UserListPageIndexState",
  default: 1,
});

export const matchFilterState = atomFamily<{ [key: string]: any }, string>({
  key: "matchFilterState",
  default: {},
});

/**
 * 外部拡張用。ライブラリ外からフィルターを設定するために使う。
 * ライブラリ外で使用する。
 */
export const extendMatchFilterState = atomFamily<
  { [key: string]: any },
  string
>({
  key: "extendMatchFilterState",
  default: {},
});
