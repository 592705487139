import { ASFormLabel, ASGraph, GraphData } from "allegro-ui";
import styled from "styled-components";

const GraphContainer = styled.div`
  width: 90%;
  padding: 12px 0px;
`;

const GraphHeader = styled.div`
  margin-bottom: 12px;
`;

const XLabel = styled.div`
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.textColor};
  font-size: ${(props) => props.theme.labelFontSizeMd}px;
`;

const YLabel = styled.div`
  color: ${(props) => props.theme.textColor};
  font-size: ${(props) => props.theme.labelFontSizeMd}px;
`;

export type NAGraphContainerProps = {
  title?: string;
  xlabel?: string;
  ylabel?: string;
  graphData: GraphData[];
};

export const NAGraphContainer: React.FC<NAGraphContainerProps> = (props) => {
  const { title, xlabel, ylabel, graphData } = props;

  return (
    <GraphContainer>
      <GraphHeader>
        <ASFormLabel>{title}</ASFormLabel>
      </GraphHeader>
      <YLabel>{ylabel}</YLabel>
      <ASGraph graphDatas={graphData} graphHeight={250} />
      <XLabel>{xlabel}</XLabel>
    </GraphContainer>
  );
};
