import { ASButton, ASModal } from "allegro-ui";
import * as React from "react";

import styled from "styled-components";
import { ImportButton } from "src/newtroid/components/molecules";
import { assetFileUpload } from "src/newtroid/hooks/useAssetUpload";
import { useFileUpload } from "src/opt-editor/hooks/useFileUpload";
import { metricFileUpload } from "src/newtroid/hooks/useMetricUpload";

import { ASBrowserUiConf } from "src/cheetah/components/libs/ASLowCodeBrowser";

import {
  ASLowCodeFormDefinition,
  ASLowCodeFormContainer,
} from "allegro-low-code-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-left: 12px;
  }
`;

const ModalContainer = styled.div`
  width: 60vw;
  max-height: 85vh;
  padding: 20px;
  overflow-y: scroll;
`;

interface ICHLowCodeButtonsGroupProps {
  uiConf: ASBrowserUiConf;
  role?: any;
  createForm: ASLowCodeFormDefinition;
}

const CHLowCodeButtonsGroup: React.FunctionComponent<ICHLowCodeButtonsGroupProps> =
  (props) => {
    const [open, setOpen] = React.useState(false);
    const { logs: assetLogs, uploadFiles: assetUpload } = useFileUpload({
      onUpload: async (file: File) => {
        const logs = await assetFileUpload(file);
        return logs;
      },
    });

    const { logs: metricLogs, uploadFiles: metricUpload } = useFileUpload({
      onUpload: async (file: File) => {
        const logs = await metricFileUpload(file);
        return logs;
      },
    });

    const handleModalOpen = () => {
      setOpen(true);
    };

    const handleModalClose = () => {
      setOpen(false);
    };
    return (
      <Container>
        {props.uiConf.actions ? (
          props.uiConf.actions.map((actionUi, id) => {
            const ok = actionUi.onValid(props.role);
            const key = `low-code-button-${actionUi.type}-${id}`;
            switch (actionUi.type) {
              case "create":
                return (
                  <ASButton
                    key={key}
                    kind="primary"
                    onClick={handleModalOpen}
                    disabled={!ok}
                  >
                    {actionUi.label}
                  </ASButton>
                );
              case "assetUpload":
                return (
                  <ImportButton
                    key={key}
                    title="Upload Asset File"
                    logs={assetLogs}
                    onSubmit={(files: File[]) => {
                      assetUpload(files);
                    }}
                    onClose={() => {
                      if (assetLogs.length > 0) {
                        window.location.reload();
                      }
                    }}
                    disabled={!ok}
                  >
                    {actionUi.label}
                  </ImportButton>
                );
              case "metricUpload":
                return (
                  <ImportButton
                    key={key}
                    title="Upload Metric File"
                    logs={metricLogs}
                    onSubmit={(files: File[]) => {
                      metricUpload(files);
                    }}
                    onClose={() => {
                      if (metricLogs.length > 0) {
                        window.location.reload();
                      }
                    }}
                    disabled={!ok}
                  >
                    {actionUi.label}
                  </ImportButton>
                );

              default:
                return <>invalid ui.</>;
            }
          })
        ) : (
          <></>
        )}

        <ASModal
          open={open}
          onClose={handleModalClose}
          // hidden={!(0 === tabId)}
        >
          <ModalContainer>
            <ASLowCodeFormContainer
              formDefinition={props.createForm}
              onClose={handleModalClose}
            />
          </ModalContainer>
        </ASModal>
      </Container>
    );
  };

export default CHLowCodeButtonsGroup;
