//
// third party libs
//
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
//
//components
//
import { ASCard, ASIconButton, ASModal } from "allegro-ui";
import { NZSchemaTypeChip } from "src/newtzero-console/components/atoms/NZSchemaTypeChip";
import { MonitorThumb } from "src/kinzaza/models";
import { CardContent, Menu, MenuItem } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

//
//our libs
//
import {
  DeleteConfirmModalContents,
  DuplicateMonitorModalContents,
} from "../../../kinzaza/components";
import { useDeleteMonitor } from "src/kinzaza/hooks";
import * as api from "src/kinzaza/api";
import { useRecoilState } from "recoil";
import { monitorListRequestId } from "src/kinzaza/recoils";

const StyledCard = styled(CardContent)`
  min-height: 150px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  text-decoration: none;
`;

const CardHead = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
    text-decoration: none;
    color: #ffffff;
    :hover {
      color: #afafaf;
      transition: all 0.2s 0s ease;
    }
  }
`;

const CardBottom = styled.div`
  display: flex;
  justify-content: space-between;
`;

type Props = {
  url: string;
  monitor: MonitorThumb;
};
export const KZMonitorWideCardLight: React.FC<Props> = (props) => {
  const { monitor, url } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const history = useNavigate();
  const { handleDelete } = useDeleteMonitor();

  const [showModal, setShowModal] = React.useState(false);
  const [ModalContents, setModalContents] = React.useState(<></>);
  const [req, setReq] = useRecoilState(monitorListRequestId);

  const handleMenuOpen = (event: React.ChangeEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteModal = (monitor: MonitorThumb) => {
    setModalContents(
      <DeleteConfirmModalContents
        onSubmit={() => {
          handleDelete(monitor);
        }}
        onCancel={() => {
          setShowModal(false);
          handleMenuClose();
        }}
      />
    );
    setShowModal(true);
    console.log(monitor);
    console.log(ModalContents);
    console.log(showModal);
  };

  const handleDuplicateModal = (monitor: MonitorThumb) => {
    setModalContents(
      <DuplicateMonitorModalContents
        onSubmit={async () => {
          const resData = await api.duplicateMonitor(monitor);
          if (resData.status === "success") {
            setShowModal(false);
            setReq(req + 1);
            handleMenuClose();
          }
        }}
        onCancel={() => {
          setShowModal(false);
          handleMenuClose();
        }}
      />
    );
    setShowModal(true);
  };

  return (
    <>
      <ASCard corner={8}>
        <StyledCard>
          <CardHead>
            <div>
              <Title
                onClick={() => {
                  history(`/${url}/${monitor.id}`);
                }}
              >
                <h1>{monitor.name}</h1>
              </Title>
            </div>
            <div>
              <ASIconButton kind="secondary" onClick={handleMenuOpen}>
                <MoreHorizIcon />
              </ASIconButton>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem onClick={() => handleDuplicateModal(monitor)}>
                  Duplicate
                </MenuItem>
                <MenuItem
                  onClick={() => handleDeleteModal(monitor)}
                  style={{ color: "red" }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </div>
          </CardHead>

          <CardBottom>
            <NZSchemaTypeChip>{monitor.schemaType}</NZSchemaTypeChip>
          </CardBottom>
        </StyledCard>
      </ASCard>

      <ASModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        {ModalContents}
      </ASModal>
    </>
  );
};
