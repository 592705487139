//Libs
import { api } from "src/api";

//Components
//States
//Types

export const useInstantSlot = () => {
  const apiKey = sessionStorage.token;
  const requestStart = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const res = await api({
      method: "POST",
      url: `/xervice/${sessionStorage.userNoText}/instant`,
      params: {
        apikey: apiKey,
      },
      data: {
        contractor: sessionStorage.contractorNo,
        userId: sessionStorage.userNoText,
      },
    });
    if (res.status === 200) alert("開始リクエストを送信しました。");
    else alert("ERROR:リクエスト失敗");
    // await _reload();
    // setSlotReqId(slotReqId + 1);
  };

  const requestStop = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const res = await api({
      method: "DELETE",
      url: `/xervice/${sessionStorage.userNoText}/instant`,
      params: {
        apikey: apiKey,
      },
      data: {
        contractor: sessionStorage.contractorNo,
        userId: sessionStorage.userNoText,
      },
    });

    if (res.status === 200) alert("停止リクエストを送信しました。");
    else alert("ERROR:リクエスト失敗");
    // await _reload();
    // setSlotReqId(slotReqId + 1);
  };

  return { requestStart, requestStop };
};
