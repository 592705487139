import styled, { DefaultTheme } from "styled-components";
import { IconButton } from "@material-ui/core";
import { darken, rgba } from "polished";

export type ASIconButtonProps = {
  kind?:
    | "primary"
    | "secondary"
    | "inherit"
    | "white"
    | "yellow"
    | "blue"
    | "logout";
  background?: string;
};

export const ASIconButton = styled(IconButton).attrs<ASIconButtonProps>((props) => {
  const fillcolor = (() => {
    switch (props.kind) {
      case "primary":
        return props.theme?.primaryColor;
      case "secondary":
        return props.theme?.secondaryColor;
      case "inherit":
        return props.background ?? rgba(0, 0, 0, 0);
      case "white":
        return rgba(0, 0, 0, 0);
      case "yellow":
        return rgba(0, 0, 0, 0);
      case "blue":
        return rgba(0, 0, 0, 0);
      case "logout":
        return props.theme?.darkMode ? "#CCCCCC" : "#EEEEEE";

      default:
        return props.theme?.primaryColor;
    }
  })();
  const iconcolor = (() => {
    switch (props.kind) {
      case "primary":
        return props.theme?.primaryOnText;
      case "secondary":
        return props.theme?.secondaryOnText;
      case "inherit":
        return props.theme?.secondaryOnText;
      case "white":
        return props.theme?.darkMode ? "white" : "black";
      case "yellow":
        return props.theme?.darkMode ? "yellow" : "red";
      case "blue":
        return props.theme?.darkMode ? "yellow" : "blue";
      case "logout":
        return props.theme?.darkMode ? "#111111" : "#777777";

      default:
        return props.theme?.primaryOnText;
    }
  })();

  return { fillcolor, iconcolor };
})<ASIconButtonProps & { fillcolor?: string; iconcolor?: string }>`
  background: ${(props) => props.fillcolor};
  color: ${(props) => props.iconcolor};
  &:disabled {
    color: ${(props) => props.theme.disabledColor};
  }

  &:hover {
    background: ${(props) => darken(0.1, props.fillcolor!)};
  }
  /* border-radius: 0; */
  border-radius: ${(props) =>
    props.size === "small"
      ? props.theme.borderRadiusSm
      : props.theme.borderRadiusLg}px;
`;
