import { FormLabel } from "@material-ui/core";
import styled from "styled-components";

export const ASFormLabel = styled(FormLabel)`
  margin-bottom: 2px;
  color: ${(props) => props.theme.secondaryOnText};
  display: inline-block;
  font-size: ${(props) => props.theme.regularFontSizeMd ?? 14}px;
  width: 100%;
  line-height: 1.5em;
`;
