import React, { useState } from "react";
import { ASCard, ASButton, ASIconButton } from "allegro-ui";
import styled from "styled-components";
import { FTStatusChip, FTEvalChip, FTDataGroupChip, FTAIArrow } from "../atoms";
import { useRecoilValue } from "recoil";
import { cursorChildThumbState } from "../../recoils/singleChildPageState";
import { Box } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";

const PrimaryCard = styled(ASCard)`
  border-radius: 24px;
  padding: 24px;
  position: sticky;
  top: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 6px 6px rgba(0, 0, 0, 0.12);
`;

const Sticky = styled.div`
  position: fixed;
  padding-left: 10px;
  padding-top: 20px;
`;

const FlexName = styled.div`
  display: flex;
  margin-top: 18px;
  justify-content: center;
  align-items: center;
`;

const Flex = styled.div`
  display: flex;
  margin-top: 18px;
  justify-content: center;
  margin-bottom: 30px;
`;

const FlexAI = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 6px;
`;

const FlexSub = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: -10px;
`;

const Names = styled.div`
  flex: 3;
  padding-left: 30px;
`;

const Kana = styled.div`
  padding-bottom: 10px;
  font-size: 15px;
`;

const Name = styled.span`
  font-size: 24px;
  font-weight: bold;
`;

const Sex = styled.div`
  flex: 1;
  align-items: center;
  margin-right: 10px;
`;

const Blueicon = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${(props) => (props.theme.darkMode ? "yellow" : "blue")};
  border-radius: 50%;
  margin-left: 10px;
`;

const Pinkicon = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${(props) => (props.theme.darkMode ? "white" : "#f71f8b")};
  border-radius: 50%;
  margin-left: 10px;
`;

const AIRaw = styled.div`
  flex: 1;
  text-align: center;
  margin-left: 10px;
`;

const TagRaw = styled.div``;

const Prediction = styled.span`
  font-weight: bold;
  font-size: 35px;
  color: ${(props) => props.theme?.primaryColor};
`;

const LowData = styled.div`
  padding-top: 30px;
  text-align: center;
  color: ${(props) =>
    props.theme.darkMode ? "yellow" : props.theme.negativeColor};
`;

const AITitle = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: ${(props) => props.theme?.regularFontSizeMd}px;
`;

const TH = styled.div`
  font-weight: bold;
  font-size: ${(props) => props.theme?.regularFontSizeMd}px;
`;

const THH = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: ${(props) => props.theme?.regularFontSizeMd}px;
  text-align: center;
`;

const TD = styled.div`
  font-size: ${(props) => props.theme?.regularFontSizeMd}px;
  margin-left: 25px;
`;

const TA = styled.div`
  font-size: ${(props) => props.theme?.regularFontSizeMd}px;
`;

const Change = styled.div`
  margin-top: 35px;
  text-align: right;
`;

const SubView = styled.div`
  margin-top: 50px;
  padding-bottom: 30px;
  max-height: 412px;
  overflow: scroll;
`;

type Props = {};

export const FTChildSingleThumb: React.FC<Props> = (props) => {
  const cursorChildThumb = useRecoilValue(cursorChildThumbState);

  const {
    name,
    foreignerName,
    kana,
    foreignerNameKana,
    age,
    predictionOneNum,
    predictionTwoNum,
    predictionOneChange,
    predictionTwoChange,
    evaluationNum,
    lastEvaluationDate,
    lastPredictionDate,
    atena,
    setai,
    birthday,
    sex,
    address,
    statusNum,
    dataGroupNum,
    hasDataShortage,
  } = cursorChildThumb;

  const showEnoughData =
    hasDataShortage === true ? (
      <LowData>
        <p>※データが不足しています</p>
      </LowData>
    ) : (
      <></>
    );

  const AIOneArrow =
    predictionOneChange === "上昇" ? (
      <FTAIArrow kind="positive">
        <ArrowUpwardIcon />
      </FTAIArrow>
    ) : predictionOneChange === "下降" ? (
      <FTAIArrow kind="negative">
        <ArrowDownwardIcon />
      </FTAIArrow>
    ) : (
      <FTAIArrow kind="stay">
        <ArrowForwardIcon />
      </FTAIArrow>
    );

  const AITwoArrow =
    predictionTwoChange === "上昇" ? (
      <FTAIArrow kind="positive">
        <ArrowUpwardIcon />
      </FTAIArrow>
    ) : predictionTwoChange === "下降" ? (
      <FTAIArrow kind="negative">
        <ArrowDownwardIcon />
      </FTAIArrow>
    ) : (
      <FTAIArrow kind="stay">
        <ArrowForwardIcon />
      </FTAIArrow>
    );

  const mainView = (
    <>
      <Flex>
        <AIRaw>
          <AITitle>検査1</AITitle>
          <FlexAI>
            <TA>
              <Prediction>{`${predictionOneNum}`}</Prediction>
            </TA>
            {AIOneArrow}
          </FlexAI>
        </AIRaw>
        <AIRaw>
          <AITitle>検査2</AITitle>
          <FlexAI>
            <TA>
              <Prediction>{`${predictionTwoNum}`}</Prediction>
            </TA>
            {AITwoArrow}
          </FlexAI>
        </AIRaw>
      </Flex>
      <TagRaw>
        <THH>介入状況</THH>
        <THH>
          <FTStatusChip value={statusNum} />
        </THH>
      </TagRaw>
      <TagRaw>
        <THH>職員評価</THH>
        <THH>
          <FTEvalChip value={evaluationNum} />
        </THH>
      </TagRaw>
      <TagRaw>
        <THH>データグループ</THH>
        <THH>
          <FTDataGroupChip value={dataGroupNum} />
        </THH>
      </TagRaw>
      {showEnoughData}
    </>
  );

  const subView = (
    <SubView>
      <Box>
        <FlexSub>
          <ASIconButton kind="blue">
            <RadioButtonCheckedIcon />
          </ASIconButton>
          <TH>宛名コード</TH>
        </FlexSub>
        <TD>{atena ?? "不明"}</TD>
      </Box>
      <Box>
        <FlexSub>
          <ASIconButton kind="blue">
            <RadioButtonCheckedIcon />
          </ASIconButton>
          <TH>世帯コード</TH>
        </FlexSub>
        <TD>{setai ?? "不明"}</TD>
      </Box>
      <Box>
        <FlexSub>
          <ASIconButton kind="blue">
            <RadioButtonCheckedIcon />
          </ASIconButton>
          <TH>生年月日</TH>
        </FlexSub>
        <TD>{birthday ?? "不明"}</TD>
      </Box>
      <Box>
        <FlexSub>
          <ASIconButton kind="blue">
            <RadioButtonCheckedIcon />
          </ASIconButton>
          <TH>性別</TH>
        </FlexSub>
        <TD>{sex ?? "不明"}</TD>
      </Box>
      <Box>
        <FlexSub>
          <ASIconButton kind="blue">
            <RadioButtonCheckedIcon />
          </ASIconButton>
          <TH>住所</TH>
        </FlexSub>
        <TD>
          {address?.zip ?? "不明"}
          <br />
          {`${address?.town} ${address?.banchi}-${Number(address?.edaban)}`}
        </TD>
      </Box>
      <Box>
        <FlexSub>
          <ASIconButton kind="blue">
            <RadioButtonCheckedIcon />
          </ASIconButton>
          <TH>最終アセスメント日</TH>
        </FlexSub>
        <TD>{lastEvaluationDate ?? "不明"}</TD>
      </Box>
      <Box>
        <FlexSub>
          <ASIconButton kind="blue">
            <RadioButtonCheckedIcon />
          </ASIconButton>
          <TH>最終AI結果算出日</TH>
        </FlexSub>
        <TD>{lastPredictionDate ?? "不明"}</TD>
      </Box>
    </SubView>
  );

  const [ui, setUi] = useState(true);

  const view = ui ? mainView : subView;

  const handleClick = () => {
    setUi(!ui);
  };

  return (
    <>
      <PrimaryCard onClick={handleClick}>
        <FlexName>
          <Names>
            <Kana>
              {process.env.REACT_APP_KAMEI_MODE
                ? "ヒヒョウジ"
                : kana || foreignerNameKana || "---"}
            </Kana>
            <Name style={{ fontFamily: "NotoSansCJK" }}>
              {process.env.REACT_APP_KAMEI_MODE
                ? "非表示"
                : name || foreignerName || "No Name"}
              ({age ?? "-1"})
            </Name>
          </Names>
          <Sex>{sex === "男性" ? <Blueicon /> : <Pinkicon /> ?? ""}</Sex>
        </FlexName>
        {view}
        <Change>
          <ASButton kind="primary" onClick={handleClick}>
            切替
          </ASButton>
        </Change>
      </PrimaryCard>
      <Sticky>
        {/* <FTChildPDFDownloadButton /> */}
        PDFをダウンロード(Beta)
      </Sticky>
    </>
  );
};

//pdfの権限管理ができるまではdisabledにする
