import {
  atom,
  useRecoilValue,
  useRecoilState,
  useRecoilValueLoadable,
} from "recoil";
import * as api from "../api";
import { useCallback, useEffect } from "react";
import { PersonalData } from "src/fortress/models";
import {
  cursorChildIdState,
  cursorChildThumbState,
} from "src/fortress/recoils/singleChildPageState";

//基本的に複数のコンポーネントに跨って利用されているステートはrecoilを利用し、グローバルステートとして保持している。

//児童詳細ページの本人データを持つグローバルステート

export const personalDatasState = atom<Array<PersonalData>>({
  key: "PersonalDatasState",
  default: [],
});

//児童詳細ページに画面遷移したときに本人データを取得して状態を保持するhooks。
//hooksにすることで一つのファイルが肥大化しない狙いがある。
//統合行政データから取得している。

export const usePersonalDatas = () => {
  const [personalDatas, setPersonalDatas] = useRecoilState(personalDatasState);
  const cursorChildId = useRecoilValue(cursorChildIdState);
  const cursorChildThumbLoadable = useRecoilValueLoadable(
    cursorChildThumbState
  );

  const init = useCallback(async () => {
    if (cursorChildId === "" || cursorChildId === null) return;
    if (cursorChildThumbLoadable.state === "loading") return;
    const data: PersonalData[] = await api.personalData(cursorChildId);
    setPersonalDatas(data);
  }, [cursorChildId, cursorChildThumbLoadable.state, setPersonalDatas]);

  useEffect(() => {
    init();
  }, [init]);

  return { personalDatas };
};
